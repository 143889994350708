import React from 'react';
import { Link } from 'react-router-dom';

import { iframeLink } from 'core/iframe';
import * as Styled from './StyledHomeListBanners';

export function HomeListBannersItem(props) {
    const data = props.data || props.action || undefined;
    const { mobile, desktop } = data.imageUrl;

    return !data ? null : (
        <Styled.HomeListBannersItem as={Link} to={iframeLink(data.redirectUrl)}>
            <Styled.Figure>
                <img className="mobi" src={mobile} alt={data.imageAlt} />
                <img className="desk" src={desktop} alt={data.imageAlt} />
            </Styled.Figure>
        </Styled.HomeListBannersItem>
    );
}
export default HomeListBannersItem;
