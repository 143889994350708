import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIG } from 'config';

import { AppTemplate } from './AppTemplate';
import * as Styled from './StyledAppTemplate';

export const AppTemplateLogin = props => {
    const { t } = useTranslation('app');
    return (
        <AppTemplate name="AppTemplateLogin">
            <Styled.Icons>
                <span className="icon fad fa-portal-enter" aria-hidden="true" />
            </Styled.Icons>
            <Styled.Descr>{t('AppTemplateLogin.title')}</Styled.Descr>

            {props.error && (
                <Styled.Buttn as="a" disabled={!props.error} href={CONFIG.baseUrl}>
                    <span className="text">{t('AppTemplateLogin.button')}</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </Styled.Buttn>
            )}
        </AppTemplate>
    );
};
export default AppTemplateLogin;
