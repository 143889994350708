import React from 'react';

import * as Form from 'components/form';

export function LoginPasswordForm(props) {
    const { form, onChange, onSubmit } = props;

    // Create form fields object
    const fields = {
        email: {
            autoComplete: 'email',
            disabled: true,
            error: form.errors.email,
            label: props.t('glossary:workEmail'),
            name: 'email',
            type: 'email',
            value: form.values.email,
            onChange: form.handleChange,
        },
        newpassword: {
            autoComplete: 'new-password',
            error: form.errors.newpassword,
            label: props.t('glossary:newpassword'),
            name: 'newpassword',
            type: 'password',
            value: form.values.newpassword,
            warning: form.warnings.newpassword,
            onChange: ev => form.handleChange(ev, onChange.newpassword),
        },
        newpasswordCheck: {
            autoComplete: 'new-password',
            error: form.errors.newpasswordCheck,
            label: props.t('glossary:newpasswordCheck'),
            name: 'newpasswordCheck',
            type: 'password',
            value: form.values.newpasswordCheck,
            onChange: ev => form.handleChange(ev, onChange.newpasswordCheck),
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="LoginPasswordForm" onSubmit={evSubmit}>
            <Form.Field {...fields.email} />
            <Form.Field {...fields.newpassword} />
            <Form.Field {...fields.newpasswordCheck} />
            <Form.Submt {...form} text={props.t('glossary:continue')} />
        </Form.Form>
    );
}
export default LoginPasswordForm;
