import React from 'react';
import logo100os from 'assets/images/logo-100os.svg';
import { useCopyToClipboard } from 'react-use';
import shareIcon from '../../../../assets/share-icon.png';
import * as Styled from './StyledUpperSection';

export const UpperSection = ({
    back = false,
    button,
    ButtonComponent,
    children,
    title,
    tooltip,
    shareLink,
    ...props
}) => {
    const [copy, setCopy] = useCopyToClipboard('');
    return (
        <Styled.Header {...props}>
            <Styled.HeaderHead style={!back && !title ? { display: 'none' } : {}}>
                {!!title && (
                    <>
                        <span className="text">{title} </span>{' '}
                        <p>em Open Innovation com Startups</p>
                    </>
                )}

                {!!tooltip && (
                    <span className="ttip">
                        <i className="ttip-icon far fa-question-circle" aria-hidden="true" />
                        <i className="ttip-text">{tooltip}</i>
                    </span>
                )}

                <Styled.PoweredByContainer>
                    <Styled.PoweredBy>
                        Powered by{' '}
                        <Styled.PoweredByLogo>
                            <img src={logo100os} alt="100 Open Startups" />
                        </Styled.PoweredByLogo>
                    </Styled.PoweredBy>
                </Styled.PoweredByContainer>
                <Styled.ShareButton>
                    <Styled.CopyButton
                        type="dark"
                        variant="neutral"
                        onClick={() => setCopy(shareLink)}
                    >
                        <img src={shareIcon} alt="Share Link Icon" />
                        {!copy.value && <span className="text">Compartilhar</span>}
                        {!!copy.value && (
                            <span className="text-gray" style={{ color: 'gray' }}>
                                Link copiado
                            </span>
                        )}
                    </Styled.CopyButton>
                </Styled.ShareButton>
                {!!button && !button.label ? button : null}
            </Styled.HeaderHead>

            {!!children && <Styled.HeaderBody>{children}</Styled.HeaderBody>}
        </Styled.Header>
    );
};
