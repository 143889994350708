import React from 'react';

import useCXI from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';
import { ChecklistFixed } from './checklist-fixed/ChecklistFixed';

import { ChecklistStage1 } from './checklist/ChecklistStage1';
import { ChecklistStage2 } from './checklist/ChecklistStage2';
import { ChecklistStage3 } from './checklist/ChecklistStage3';

import * as Styled from './StyledProgressSteps';

function toFixed(num) {
    return Number(num).toFixed(2);
}

export const ProgressSteps = () => {
    const { t, vars, setModal } = useCXI();
    const { cxi } = vars;

    const { currentStage, metrics } = cxi;

    const calculatedProgress = React.useMemo(() => {
        function calcStage0() {
            let m1 = toFixed(Number(metrics?.matches || 0) / 100);
            if (m1 > 1) m1 = 1;
            return toFixed((m1 * 100) / 1);
        }
        function calcStage1() {
            let m1 = toFixed(Number(metrics?.stage1.feedbacksPositive || 0) / 10);
            if (m1 > 1) m1 = 1;
            return toFixed((m1 * 100) / 1);
        }
        function calcStage2() {
            let m1 = toFixed(Number(metrics?.stage2.feedbacksPositive || 0) / 10);
            if (m1 > 1) m1 = 1;
            return toFixed((m1 * 100) / 1);
        }
        function calcStage3() {
            let m1 = toFixed(Number(metrics?.stage3.feedbacksPositive || 0) / 10);
            let m2 = toFixed(Number(metrics?.stage3.mentoringSessions || 0) / 5);
            if (m1 > 1) m1 = 1;
            if (m2 > 1) m2 = 1;
            return toFixed((m1 * 100 + m2 * 100) / 2);
        }

        const calculated = {
            0: calcStage0(),
            1: calcStage1(),
            2: calcStage2(),
            3: calcStage3(),
        };

        const total =
            (Number(calculated[0]) +
                Number(calculated[1]) +
                Number(calculated[2]) +
                Number(calculated[3])) /
            4;
        calculated.total = total;

        return calculated;
    }, [currentStage, metrics]);

    const modalName = 'Guide';

    return (
        <Styled.ProgressSteps className={`stage-${cxi.currentStage}`}>
            <Styled.ProgressStepsHead>
                <span className="text">{t('home.ProgressSteps.title')}</span>
                {!!modalName && (
                    <Button variant="link" onClick={() => setModal({ modal: modalName })}>
                        <i className="ttip-icon far fa-info-circle" aria-hidden="true" />
                        {t('home.ProgressSteps.about')}
                    </Button>
                )}
            </Styled.ProgressStepsHead>

            <Styled.ProgressStepsProg>
                <Styled.ProgressStepsBars>
                    <Styled.ProgressStepsBar width={`${calculatedProgress.total}%`} />
                </Styled.ProgressStepsBars>
                <span className="perc">{`${Math.round(calculatedProgress.total, 0)}%`}</span>
            </Styled.ProgressStepsProg>

            <Styled.ProgressStepsBody>
                <ChecklistFixed />
            </Styled.ProgressStepsBody>

            <Styled.ProgressStepsBody>
                <ChecklistStage1 />
            </Styled.ProgressStepsBody>

            <Styled.ProgressStepsBody>
                <ChecklistStage2 />
            </Styled.ProgressStepsBody>

            <Styled.ProgressStepsBody>
                <ChecklistStage3 />
            </Styled.ProgressStepsBody>
        </Styled.ProgressSteps>
    );
};
