import React from 'react';
import { useParams } from 'react-router';

import * as Form from 'components/form';
import * as Page from 'components/page';

import * as Styled from './StyledSubscriptionPayPerUse';

export function SubscriptionPayPerUseForm(props) {
    const { eventId } = useParams();
    const { form, onBlur, onChange, onSubmit, useSubscription } = props;
    const { eventPayPerUseTerm } = useSubscription.vars;

    // Manage show/hide form state
    const [show, setShow] = React.useState(false);

    // Create form fields object
    const fields = {
        email: {
            autoComplete: 'email',
            disabled: true,
            error: form.errors.email,
            label: props.t('EventPayPerUse.email'),
            name: 'email',
            type: 'email',
            value: form.values.email,
            onChange: form.handleChange,
        },
        name: {
            error: form.errors.name,
            label: props.t('EventPayPerUse.name'),
            name: 'name',
            type: 'text',
            value: form.values.name,
            onChange: form.handleChange,
        },
        taxIdType: {
            error: form.errors.taxIdType,
            label: props.t('EventPayPerUse.taxIdType'),
            name: 'taxIdType',
            options: [
                { name: 'CPF', value: 'CPF' },
                { name: 'CNPJ', value: 'CNPJ' },
                { name: 'Outro', value: 'Outro' },
            ],
            type: 'radio',
            value: form.values.taxIdType,
            onChange: ev => form.handleChange(ev, onChange.taxIdType),
        },
        taxId: {
            error: form.errors.taxId,
            label: props.t('EventPayPerUse.taxId'),
            name: 'taxId',
            type: 'text',
            helper: '',
            maxLength: 15,
            value: form.values.taxId,
            onChange: form.handleChange,
        },
    };

    // Check if helper should be shown
    if (form.values.taxIdType !== 'Outro') {
        fields.taxId.helper = props.t('EventPayPerUse.taxIdHelper', {
            type: form.values.taxIdType,
        });
    }
    // Change maxLength based on selected choice
    if (form.values.taxIdType === 'CPF') {
        fields.taxId.displayFormat = '000.000.000-00';
        fields.taxId.maxLength = 15;
        fields.taxId.onBlur = ev => form.handleBlur(ev, onBlur.isValidCPF);
    }
    if (form.values.taxIdType === 'CNPJ') {
        fields.taxId.displayFormat = '00.000.000/0000-00';
        fields.taxId.maxLength = 19;
        fields.taxId.onBlur = ev => form.handleBlur(ev, onBlur.isValidCNPJ);
    }

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Styled.SubscriptionPayPerUseForm>
            {show && (
                <Form.Form name="eventPayPerUseForm" onSubmit={evSubmit}>
                    <Page.Texts>{eventPayPerUseTerm.name}</Page.Texts>
                    <Page.BoxedHTML html={eventPayPerUseTerm.htmlDescription} />

                    <Form.Field {...fields.email} />
                    <Form.Field {...fields.name} />
                    <Form.Field {...fields.taxIdType} />
                    <Form.Field {...fields.taxId} />

                    <Form.Submt {...form} text={props.t('EventPayPerUse.submit')} />
                </Form.Form>
            )}

            {!show && (
                <Page.Buttn onClick={() => setShow(true)}>
                    <span className="text">Entendo e quero prosseguir</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </Page.Buttn>
            )}

            <Page.Buttn
                to={`/events/${eventId}/subscription/success`}
                style={{
                    background: 'var( --os-startups-bgc)',
                    color: 'var(--os-startups-shade)',
                    marginTop: '1rem',
                }}
            >
                {/* <span className="link"> */}
                <span className="icon far fa-long-arrow-left mr-2" aria-hidden="true" />
                <span className="text">Quero rever os pacotes</span>
                {/* </span> */}
            </Page.Buttn>
        </Styled.SubscriptionPayPerUseForm>
    );
}
export default SubscriptionPayPerUseForm;
