import React from 'react';
import { useTranslation } from 'react-i18next';

import logo100oa from 'assets/images/logo-100oa.svg';

import * as Alert from 'components/alert';

export function InvestorInvite() {
    const { t } = useTranslation('home');

    // Create the alert element props
    const propsAlert = {
        // Set the image to be shown
        image: {
            src: logo100oa,
            alt: t('glossary:100 Open Angels'),
        },
        // Show texts informations
        title: t('InvestorInvite.title'),
        descr: t('InvestorInvite.descr'),
        // Build the button
        buttn: {
            href: '/investor',
            text: t('InvestorInvite.button'),
        },
        // Setup color and presentation
        type: 'success',
    };
    return <Alert.Alert {...propsAlert} />;
}
export default InvestorInvite;
