import React from 'react';
import logo from 'assets/images/logo-100os.svg';
import { Button } from 'modules/cxi/components/button';
import useCXI from 'modules/cxi/useCXI';

export default function QRCode(props) {
    const { t } = useCXI();
    const { qrCode, setQrCode, link } = props;

    function generateQRCodeLink(url, size = 300, logo) {
        const qrCodeUrl = `https://chart.googleapis.com/chart?chs=${size}x${size}&cht=qr&chl=${encodeURIComponent(
            url
        )}&choe=UTF-8&chld=H|4&chl=${encodeURIComponent(url)}&chf=bg,s,ffffff`;

        if (logo) {
            const logoUrl = encodeURIComponent(`|${logo}`);
            return `${qrCodeUrl}${logoUrl}`;
        }

        return qrCodeUrl;
    }

    const handleGenerateQRCode = async () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const qrCodeUrl = generateQRCodeLink(link, 300, logo);
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = qrCodeUrl;

        image.onload = () => {
            canvas.width = 300;
            canvas.height = 300;
            context.drawImage(image, 0, 0, 300, 300);
            const logoImage = new Image();
            logoImage.crossOrigin = 'anonymous';
            logoImage.src = logo;
            logoImage.onload = () => {
                const logoSize = 70;
                const logoPosition = (300 - logoSize) / 2;
                context.drawImage(logoImage, logoPosition, logoPosition, logoSize, logoSize);
                setQrCode(canvas.toDataURL());
            };
        };
    };

    return (
        <Button
            onClick={handleGenerateQRCode}
            style={{ display: 'flex', margin: '2rem auto auto auto' }}
        >
            {!qrCode && <span className="text"> {t('qrCode')} </span>}
            {!!qrCode && <img src={qrCode} alt="QR Code" />}
        </Button>
    );
}
