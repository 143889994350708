import React from 'react';

import * as Styled from './StyledMetrics';

export const MetricItem = ({ icon, numb, text, tooltip }) => (
    <Styled.MetricsItem>
        <span className={`icon fal ${icon || 'fa-hashtag'}`} aria-hidden="true" />
        <span className="cont">
            <span className="text">{text}</span>
            <span className="numb">{numb}</span>
        </span>
        <span className="ttip" style={!tooltip ? { display: 'none' } : {}}>
            <span className="ttip-icon far fa-question-circle" aria-hidden="true" />
            <span className="ttip-text">{tooltip}</span>
        </span>
    </Styled.MetricsItem>
);
