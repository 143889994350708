import React from 'react';
import * as Styled from './StyledFormInput';

export const FormInput = props =>
    // Don't show this element if it is empty
    !props.input ? null : (
        <Styled.FormInput name="text">
            {props.children}
            <input className="form-control" {...props.input} />
        </Styled.FormInput>
    );
export default FormInput;
