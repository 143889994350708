import { useContext } from 'react';
import { CXIDashboardUserContext } from './CXIDashboardUserContext';

export const useCXIDashboardUser = () => {
    const context = useContext(CXIDashboardUserContext);
    if (context === undefined) {
        throw new Error('useCXISetup must be used within a CXISetupContextProvider');
    }
    return context;
};
