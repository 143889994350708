import React, { createContext } from 'react';

import { Loading } from 'modules/cxi/components/loading';
import { useCXIDashboard } from '../useCXIDashboard';

export const CXIDashboardStartupContext = createContext();
export const CXIDashboardStartupContextProvider = ({ children, startupId }) => {
    const { t, getStartupById } = useCXIDashboard();

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState({ loading: true, startupId });
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    React.useEffect(() => {
        if (startupId) {
            Promise.all([getStartupById(startupId)]).then(([startup]) => {
                addVars({ loading: false, startup });
            });
        }
    }, [startupId]);

    const stateValue = { t, vars, addVars, setVars };
    return vars.loading ? (
        <Loading>Carregando Startup...</Loading>
    ) : (
        <CXIDashboardStartupContext.Provider value={stateValue}>
            {children}
        </CXIDashboardStartupContext.Provider>
    );
};
