import styled from 'styled-components';

export const Evaluations = styled.table`
    td {
        padding: 1.6rem;
        text-align: center;

        &:first-child,
        &:last-child {
            text-align: left;
        }
    }
`;

export const EvaluationsThead = styled.thead``;
export const EvaluationsTbody = styled.tbody`
    tr {
        background: white;
        border-top: 1px solid ${({ theme }) => theme.colors.whiteDarker};

        &:first-child {
            border-top: 0;

            td:first-child {
                border-top-left-radius: 0.8rem;
            }
            td:last-child {
                border-top-right-radius: 0.8rem;
            }
        }

        &:last-child {
            td:first-child {
                border-bottom-left-radius: 0.8rem;
            }
            td:last-child {
                border-bottom-right-radius: 0.8rem;
            }
        }

        &:nth-child(even) td {
            background: rgba(0, 0, 0, 0.01);
        }
    }
`;

export const EvaluationsTd = styled.td`
    background: white;
    padding: 1.6rem;
    text-align: center;

    &:first-child {
        text-align: left;
    }
`;

export const EvaluationsTh = styled.th`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    padding: 0 0 0.6rem 1.2rem;
    text-align: center;
    text-transform: uppercase;
    vertical-align: bottom;
    white-space: wrap;
    width: auto;

    @media (min-width: 992px) {
        padding: 0 0 1.2rem 1.2rem;
    }

    &:first-child {
        padding-left: 0 !important;
        text-align: left;
        width: 200px;
        white-space: nowrap;
    }
    &:last-child {
        padding-left: 1.2rem;
        padding-right: 0;
        text-align: start;
        width: 100%;
    }
`;

export const EvaluationsTr = styled.tr`
    border-top: 1px solid ${({ theme }) => theme.colors.whiteDarker};

    &:first-child {
        border-top: 0;

        td:first-child {
            border-top-left-radius: 0.8rem;
        }
        td:last-child {
            border-top-right-radius: 0.8rem;
        }
    }

    &:last-child {
        td:first-child {
            border-bottom-left-radius: 0.8rem;
        }
        td:last-child {
            border-bottom-right-radius: 0.8rem;
        }
    }
`;
