import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Header } from 'modules/cxi/components/header/Header';

import { Matches } from '../components/matches/Matches';
import * as Styled from '../components/StyledNetworkPage';

export const CXINetworksIndex = () => {
    const { t, vars } = useCXI();
    const { matches } = vars;

    const [filter, setFilter] = React.useState('');

    const handleChange = e => {
        return setFilter(e.target.value);
    };

    const filtered = matches.filter(i =>
        i.startup.name.toLowerCase().includes(filter.toLowerCase())
    );

    const field = {
        className: 'c-header-input',
        placeholder: t('networks.index.search'),
        type: 'search',
        value: filter,
        onChange: handleChange
    };

    return (
        <Styled.NetworkPage>
            <Styled.NetworkPageHead>
                <h2>{t('networks.index.title')}</h2>
                <h3>({matches.length} Startups)</h3>
            </Styled.NetworkPageHead>

            <Header className="filter">
                <input name="search" {...field} />
            </Header>

            <Styled.NetworkPageBody>
                <Matches matches={filtered} t={t} />
            </Styled.NetworkPageBody>
        </Styled.NetworkPage>
    );
};
