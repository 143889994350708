import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';

import { Matches } from '../components/matches/Matches';
import * as Styled from '../components/StyledNetworkPage';

export const CXINetworksMentoring = () => {
    const { t, vars } = useCXI();
    const matches = vars.matches.filter(
        match =>
            match.calculatedStage === 'mentoring' ||
            (match.calculatedStage === 'speed-dating' && ['held'].includes(match.speedDating))
    );

    const accepted = matches.filter(match => match.mentoring === 'accepted');
    const filtered = matches.filter(match => match.mentoring !== 'accepted');

    return (
        <Styled.NetworkPage>
            {!!accepted.length && (
                <Styled.NetworkPageHighlight>
                    <Styled.NetworkPageHead>
                        <h2>{t('networks.mentoring.title')}</h2>
                    </Styled.NetworkPageHead>

                    <Styled.NetworkPageBody>
                        <Matches matches={accepted} t={t} />
                    </Styled.NetworkPageBody>
                </Styled.NetworkPageHighlight>
            )}

            <Styled.NetworkPageHead>
                <h2>{t('networks.mentoring.availableTitle')}</h2>
                <h3>({filtered.length} Startups)</h3>
            </Styled.NetworkPageHead>

            <Styled.NetworkPageBody>
                <Matches matches={filtered} t={t} />
            </Styled.NetworkPageBody>
        </Styled.NetworkPage>
    );
};
