import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const Message = styled.div.attrs(props => ({
    className: `${props.from} ${props.slim ? 'slim' : ''}`,
}))`
    align-items: flex-start;
    display: flex;
    gap: 1.6rem;
    text-align: left;
    padding-right: 2.4rem;
    position: relative;

    @media (min-width: 768px) {
        padding-right: 20%;
    }

    .user-avatar,
    .message-image {
        flex: 0 0 4.4rem;
        height: 4.4rem;
        width: 4.4rem;
    }

    &.me {
        align-self: flex-end;
        padding-right: 0;
        padding-left: 2.4rem;

        @media (min-width: 768px) {
            padding-right: 0;
            padding-left: 20%;
        }

        .user-avatar {
            order: 3;
        }
        .message-texts {
            background: ${({ theme }) => theme.colors.lightGray};
            color: ${({ theme }) => theme.colors.black};
            text-align: right;
        }
        .message-texts .caret {
            color: ${({ theme }) => theme.colors.lightGray};
            ${positions('1rem', 'auto', 'auto', '100%')}
            transform: scaleX(-1);
        }
    }

    &.slim {
        margin-top: -1.6rem;
    }
`;

export const MessageImage = styled.div.attrs({
    className: 'message-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 0.4rem;
    position: relative;
`;

export const MessageTexts = styled.div.attrs({
    className: 'message-texts',
})`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border: 0 solid ${({ theme }) => theme.colors.energyBlue};
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.black};
    display: inline-flex;
    // flex: 1 1 auto;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    position: relative;

    .name {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.3;
        ${truncate(1, 1.3)}
    }
    .text {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
        white-space: pre-line;
    }
    .time {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }

    .caret {
        color: ${({ theme }) => theme.colors.white};
        font-size: 2rem;
        position: absolute;
        ${positions('1rem', '100%', 'auto', 'auto')}
    }

    .optionsIcon {
        display: flex;
        position: absolute;
        top: 12px;
        right: 14px;
        flex-direction: column;
        gap: 1px;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    .dot {
        width: 5px;
        background-color: #ccc5c5;
        height: 5px;
        border-radius: 50%;
        &:hover {
            background-color: ${({ theme }) => theme.colors.energyBlue};
        }
    }
`;
export const Options = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 5rem;
    flex-direction: column;
    background: #ffffff;
    border: 0.5px solid rgba(102, 102, 102, 0.25);
    border-radius: 3px;
    padding: 9px;
    width: 150px;
    transition: all ease 0.5s;
    &:hover {
        border: 1.5px solid ${({ theme }) => theme.colors.energyBlue};
    }
`;
export const Option = styled.span`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    padding: 5px 0%;
    color: rgba(102, 102, 102, 0.55);
    cursor: pointer;
    gap: 1rem;
    transition: all ease 0.5s;
    &:hover {
        color: ${({ theme }) => theme.colors.energyBlue};
    }
`;
