import styled from 'styled-components';

export const FormWrapper = styled.div`
    font-family: 'Kanit', sans-serif;
    p {
        margin-bottom: 0px;
        margin-top: 2rem;
    }
`;
export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: none;
`;

export const DateFieldWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: self-end;

    .component-form-field {
        flex: 1 0 30%;
    }

    .wrapper {
        display: flex;
        gap: 1px;
        align-items: flex-end;
        justify-content: center;
    }
`;

export const DisplaySelect = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 500;
    margin: 2rem 0;
    gap: 20px;

    .component-form-field {
        margin-top: 1rem;
        flex: 0 0 50%;
    }
`;

export const SwitchContainer = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 300;
    align-items: center;
`;

export const SelectFieldGroup = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 500;

    .component-form-field {
        margin-top: 1rem;
    }
`;

export const LinkContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 1rem auto;
    gap: 10px;
    align-items: center;
    justify-content: center;
    p {
        width: 100%;
        margin: 0;
    }
`;

export const FieldWrapper = styled.div`
    display: flex;

    .component-form-field {
        flex: 0 0 60%;
    }
`;
