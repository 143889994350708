import React from 'react';

import { ComponentTableRow } from './ComponentTableRow';

export const ComponentTableRows = props => {
    if (!props.items || !props.itemKey || !props.name) {
        return false;
    }

    return props.items.map((i, k) => {
        const kk = i[props.itemKey] || `${props.name}-${props.itemKey}-${k}`;
        return <ComponentTableRow key={kk} data={i} {...props} />;
    });
};
export const TableRows = ComponentTableRows;
export default ComponentTableRows;
