import styled from 'styled-components';
import { positions } from 'utils/styles';

export const ComponentTabs = styled.div.attrs({
    className: 'component-tabs',
})`
    background: ${props => props.bg || '#f2f2f2'};
    margin: 3rem 0;
    padding-bottom: 3rem;
    position: relative;

    &::before {
        background: inherit;
        content: '';
        position: absolute;
        ${positions(0, 0, 0, '50%')}
        transform: translateX(-50%);
        width: 100vw;
    }

    & > * {
        position: relative;
    }

    .component-list {
        margin-top: 0;
    }
`;
