import React from 'react';
import * as Styled from './StyledFormInput';

export function FormInputMoney(props) {
    const { input, related } = props;
    const [format, setFormat] = React.useState(input.value !== '');

    const rel = related ? { ...related } : {};
    if (!rel.currency) rel.currency = 'USD';

    // New input with control functions
    const newInput = {
        ...input,
        inputMode: 'numeric',
        min: 1,
        step: 'any',
        type: 'text',
    };

    newInput.onBlur = ev => {
        ev.persist();
        setFormat(true);
        if (input.onBlur !== undefined) {
            return input.onBlur(ev);
        }
        return true;
    };
    newInput.onChange = ev => {
        ev.persist();
        // eslint-disable-next-line no-param-reassign
        ev.target.value = Number(String(ev.target.value).replace(/[^0-9.-]+/g, ''));
        if (input.onChange !== undefined) {
            return input.onChange(ev);
        }
        return true;
    };
    newInput.onFocus = ev => {
        ev.persist();
        setFormat(false);
        if (input.onFocus !== undefined) {
            return input.onFocus(ev);
        }
        return true;
    };

    if (format) {
        const currency = {
            currency: rel.currency,
            currencyDisplay: 'symbol',
            style: 'currency',
        };
        newInput.value = Intl.NumberFormat(undefined, currency).format(input.value);
    }

    return !props.input ? null : (
        <Styled.FormInput name="money">
            {props.children}
            <input className="form-control" {...newInput} />
        </Styled.FormInput>
    );
}
export const money = FormInputMoney;
export default FormInputMoney;
