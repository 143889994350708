import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
// import { Header } from 'modules/cxi/components/header/Header';

// import { FilterTag } from '../components/filter-tag/FilterTag';
import { Matches } from '../components/matches/Matches';
import * as Styled from '../components/StyledNetworkPage';

export const CXINetworksSpeedDating = () => {
    const { t, vars } = useCXI();
    const matches = vars.matches.filter(match => match.calculatedStage === 'speed-dating');
    // const matches = vars.matches.filter(match => ['requested', 'held'].includes(match.speedDating));

    // const [filter, setFilter] = React.useState('');
    // const hasFilter = value => filter === value;

    // const updFilter = value => {
    //     if (hasFilter(value)) {
    //         return setFilter('');
    //     }
    //     return setFilter(value);
    // };

    const filtered = matches;

    return (
        <Styled.NetworkPage>
            <Styled.NetworkPageHead>
                <h2>{t('networks.speed-dating.title')}</h2>
                <h3>({matches.length} Startups)</h3>
            </Styled.NetworkPageHead>

            {/* <Header>
                {t('networks.filter')}
                <FilterTag active={hasFilter('available')} onClick={() => updFilter('available')}>
                    <span className="text">{t('networks.speed-dating.filters.available')}</span>
                </FilterTag>
                <FilterTag active={hasFilter('requested')} onClick={() => updFilter('requested')}>
                    <span className="text">{t('networks.speed-dating.filters.requested')}</span>
                </FilterTag>
                <FilterTag active={hasFilter('rejected')} onClick={() => updFilter('rejected')}>
                    <span className="text">{t('networks.speed-dating.filters.rejected')}</span>
                </FilterTag>
                <FilterTag active={hasFilter('accepted')} onClick={() => updFilter('accepted')}>
                    <span className="text">{t('networks.speed-dating.filters.accepted')}</span>
                </FilterTag>
            </Header> */}

            <Styled.NetworkPageBody>
                <Matches matches={filtered} t={t} />
            </Styled.NetworkPageBody>
        </Styled.NetworkPage>
    );
};
