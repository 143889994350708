import styled from 'styled-components';

export const ProgressBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;

    @media (max-width: 1200px) {
        gap: 3rem;
    }

    @media (max-width: 768px) {
        gap: 2rem;
    }

    @media (max-width: 500px) {
        gap: 1rem;
    }
`;

export const DashedDivider = styled.div`
    border-bottom: 1px;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: black;
    width: 10%;
`;

export const ProgressItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    background-color: #0094f9;
    border-radius: 100px;
    color: #fff;
    font-size: 10px;

    &.active {
        background-color: #56c1d6;
        width: 140px;
        span {
            display: none;
        }
    }

    @media (max-width: 768px) {
        height: 15px;
        width: 15px;
        font-size: 8px;

        &.active {
            width: 100px;
        }
    }
`;
