import React from 'react';
import { Button } from 'modules/cxi/components/button';
import * as Styled from './StyleCXIChallengeReminder';

const challengeUrl = 'https://100os.net/cxi_challenge';

const openChallengerPage = () => {
    window.open(challengeUrl, '_blank');
};

// Definir a regra que isso vai aparecer
export const CXIChallengeReminder = () => {
    return (
        <Styled.ChallengeReminder>
            <Styled.Description>
                <p>
                    A corporação da qual você faz parte tem alguma necessidade que poderia ser
                    resolvida com o auxílio de uma startup? Busque um parceiro de negócio ideal
                    através da nossa plataforma.
                </p>
            </Styled.Description>
            <Button variant="blue" size="medium" onClick={() => openChallengerPage()}>
                <span className="text"> Lançar um Desafio </span>
            </Button>
        </Styled.ChallengeReminder>
    );
};
