import React from 'react';

import { LinksWithText } from 'components/page';
import * as Form from 'components/form';

export function ProfileCompanySelectForm(props) {
    const { form, onSubmit } = props;

    // Create form fields object
    const fields = {
        company: {
            disabled: !!form.values.nocompany,
            error: form.errors.company,
            label: props.t('glossary:company'),
            name: 'company',
            type: 'Company',
            value: form.values.company,
            onChange: form.handleChange,
            children: (
                <LinksWithText to="/register/company/create">
                    <span className="link">{props.t('glossary:companyNew')}</span>
                </LinksWithText>
            ),
        },
        nocompany: {
            label: props.t('glossary:nocompany'),
            name: 'nocompany',
            required: false,
            type: 'checkbox',
            value: form.values.nocompany,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="ProfileCompanySelectForm" onSubmit={evSubmit}>
            <Form.Field {...fields.company} />
            <Form.Field {...fields.nocompany} />
            <Form.Submt {...form} text={props.t('glossary:continue')} />
        </Form.Form>
    );
}
export default ProfileCompanySelectForm;
