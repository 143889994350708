import { useData } from 'modules/corporatehub/context/dataContext';
import React from 'react';
import * as Styled from './StyledCorporateHubRelationship';
import LeftSession from './components/LeftSession';
import RightSession from './components/RightSession';

export default function CorporateHubRelationshipView(props) {
    const dataContext = useData();
    const { relationshipsPageVars } = dataContext.state;

    const { company } = dataContext.state;

    const companyName = company.isCxiGroup ? `o grupo ${company.name}` : company.name;
    return (
        <Styled.SessionContainer>
            <LeftSession data={relationshipsPageVars} companyName={companyName} />
            <RightSession data={relationshipsPageVars} />
        </Styled.SessionContainer>
    );
}
