import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { CA, CG } from 'contexts';
import * as Styled from './StyledMenuListModules';
// import { iframeLink } from 'core/iframe';

export function MenuListModulesItem(props) {
    const data = props.data || props.item || undefined;
    const { state } = React.useContext(CA);
    const { cxi } = state;

    // Get the dispatch function to close menu
    const { dispatch } = React.useContext(CG);

    const isCxi = cxi !== false;

    // Function to change the language
    const click = ev => {
        if (data.name === '100-10-1') {
            if (!isCxi) {
                window.location.href = 'https://www.openstartups.net/site/produtos/100-10-1.html';
            }
        }
        dispatch({ type: 'MENU_OPEN', menu: false });
    };

    return (
        <Styled.MenuListModulesItem as={Link} to={data.path} onClick={click}>
            <span className={`icon fal fa-fw ${data.icon}`} aria-hidden="true" />
            {/* i18next-extract-disable-next-line */}
            <Trans className="text" i18nKey={`menu:menu.${data.name}`}>{`menu.${data.name}`}</Trans>
        </Styled.MenuListModulesItem>
    );
}
export default MenuListModulesItem;
