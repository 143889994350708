import Button from 'components/button';
import { useCXI } from 'modules/cxi/useCXI';
import React from 'react';
import { useParams } from 'react-router';
import { ButtonAction } from '../actionButton/Button';
import * as Styled from './StyledStartupCard';

export const StartupCard = props => {
    const { postMatchChallenge, setModal, vars } = useCXI();
    const { matches } = vars;
    const { startup, t } = props;
    const { startupId, challengeId } = useParams();

    const handlePostMatch = async e => {
        e.preventDefault();
        const match = await postMatchChallenge(startupId, challengeId);
        if (match) {
            setModal({
                modal: 'ChallengeMatchSuccess'
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    };
    function formatUrl(url) {
        // Regular expression pattern to validate URL
        const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{1,5})?(\/.*)?$/i;

        // Test the URL against the pattern
        if (!urlPattern.test(url)) {
            // Add 'https://' prefix if missing
            url = `https://${url}`;
        } else if (!url.startsWith('http://') && !url.startsWith('https://')) {
            // Add 'https://' prefix if missing http:// or https://
            url = `https://${url}`;
        }

        // Add 'www.' prefix if missing
        if (!url.includes('www.')) {
            const domain = url.replace(/^(https?:\/\/)?/, '');
            url = `https://www.${domain}`;
        }

        return url;
    }
    const hasMatch = matches.some(m => m?.startup.startupId === startupId);

    const matchId = matches.reduce(
        (acc, m) => (m?.startup.startupId === startupId ? m.matchid : acc),
        null
    );

    return (
        <Styled.StartupCard>
            <Styled.StartupCardColumn className="image">
                <Styled.Image img={startup?.logoUrl} />
            </Styled.StartupCardColumn>
            <Styled.StartupCardColumn className="texts">
                <Styled.Title>
                    <div className="header">
                        {startup?.name}
                        <Styled.Level>
                            {t('matches.StartupCard.level')} : {startup?.level}/5
                        </Styled.Level>
                    </div>
                </Styled.Title>
                <Styled.Descr>{startup?.description}</Styled.Descr>
                {!!startup?.location?.city && startup?.location?.state ? (
                    <Styled.IconWithText>
                        <span className="icon far fa-map-marker-alt" aria-hidden="true" />
                        <span className="text">
                            {`${startup?.location?.city?.name} `} | {startup?.location?.state?.name}
                        </span>
                    </Styled.IconWithText>
                ) : (
                    startup?.location?.state && (
                        <Styled.IconWithText>
                            <span className="icon far fa-map-marker-alt" aria-hidden="true" />
                            <span className="text">{startup?.location?.state?.name}</span>
                        </Styled.IconWithText>
                    )
                )}
                {!!startup?.links?.website && (
                    <Styled.IconWithText>
                        <a
                            href={formatUrl(startup.links.website)}
                            title="Website"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="icon fas fa-globe" aria-hidden="true" />
                            <span className="text"> {startup.links.website || ' - '}</span>
                        </a>
                    </Styled.IconWithText>
                )}
            </Styled.StartupCardColumn>
            <Styled.StartupCardColumn>
                {hasMatch ? (
                    <>
                        <Button
                            variant="purple"
                            style={{
                                display: 'block',
                                width: '265px',
                                borderRadius: '4px',
                                marginLeft: '16px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                window.location.href = `/100-10-1/startup/${matchId}`;
                            }}
                        >
                            Ver minha rede
                        </Button>

                        <Styled.ButtonAction>
                            <ButtonAction startup={startup} />
                        </Styled.ButtonAction>
                    </>
                ) : (
                    <>
                        <Button
                            variant="purple"
                            onClick={e => handlePostMatch(e)}
                            style={{
                                display: 'block',
                                width: '265px',
                                borderRadius: '4px',
                                marginLeft: '16px',
                                textAlign: 'center'
                            }}
                        >
                            {t('challenge.addMatch')}
                        </Button>

                        <Styled.ButtonAction>
                            <ButtonAction startup={startup} />
                        </Styled.ButtonAction>
                    </>
                )}
            </Styled.StartupCardColumn>
        </Styled.StartupCard>
    );
};
