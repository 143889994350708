import styled from 'styled-components';
import { positions } from 'utils/styles';

export const StartupIdModule = styled.div.attrs({
    className: 'styled-startupid-module',
})`
    background: #fff;
    position: fixed;
    ${positions(0, 0, 0, 0)}

    ion-content {
        --background: transparent;
        --color: #444;
    }
`;
