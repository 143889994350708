import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIG } from 'config';
import { iframeLink } from 'core/iframe';

import * as Page from 'components/page';

export const OiweekBack = ({ oiweek }) => {
    const { t } = useTranslation('oiweek');

    const props = {
        icon: 'long-arrow-left',
        href: '/home',
        text: t('OiweekBack.back'),
    };
    if (oiweek && oiweek.stages && oiweek.stages.app) {
        props.icon = 'long-arrow-right';
        props.href = iframeLink(`${CONFIG.appLinks.events}?autocheckin=${oiweek._id}`);
        props.text = t('OiweekBack.toEvent');
    }
    return (
        <Page.LWTXT to={props.href}>
            <span className="link">
                {props.href === '/home' && (
                    <span className="ico far fa-long-arrow-left mr-2" aria-hidden="true" />
                )}

                <span className="txt">{props.text}</span>

                {props.href !== '/home' && (
                    <span className="ico far fa-long-arrow-right ml-2" aria-hidden="true" />
                )}
            </span>
        </Page.LWTXT>
    );
};
export default OiweekBack;
