import React from 'react';

import { useCXIDashboardStartup } from '../../useCXIDashboardStartup';
import { StartupCardSocials } from './StartupCardSocials';
import * as Styled from './StyledStartupCard';

export const StartupCard = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    return (
        <Styled.StartupCard>
            <Styled.StartupCardColumn className="image">
                <Styled.Image img={startup.logoUrl} />
            </Styled.StartupCardColumn>

            <Styled.StartupCardColumn className="texts">
                <Styled.Title>{startup.name}</Styled.Title>
                <Styled.Descr>{startup.description}</Styled.Descr>

                <Styled.IconWithText>
                    <span className="icon far fa-badge-check" aria-hidden="true" />
                    <span className="text">
                        {t('matches.StartupCard.level')}: {startup.level}/5
                    </span>
                </Styled.IconWithText>
                <Styled.IconWithText>
                    <span className="icon far fa-map-marker-alt" aria-hidden="true" />
                    <span className="text">{startup.fullLocation || '-'}</span>
                </Styled.IconWithText>

                <StartupCardSocials />
            </Styled.StartupCardColumn>
        </Styled.StartupCard>
    );
};
