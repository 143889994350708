import * as Alert from 'components/alert';
import * as List from 'components/list';
import * as Modal from 'components/modal';
import * as Page from 'components/page';
import * as Tabs from 'components/tabs';
import { Button } from 'modules/cxi/components/button';
import { RHP } from 'providers';
import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Contract, ContractCard } from '../components';
import { StartupPosition } from '../components/contract/startupContractsPosition/StartupPosition';

export function StartupStep8(props) {
    const { vars, updateSteps } = props.useRankingStartup;

    const {
        startupEstimatedPosition,
        startupDetails,
        isControlledByCorp,
        ranking,
        application,
        contracts
    } = vars;

    const { steps } = application;

    const [displayCalculator, setDisplayCalculator] = useState(true);
    const [openCorp, setOpenCorpWarning] = useState(false);
    const [openScaleUp, setOpenScaleUpWarning] = useState(false);
    const [modalData, setModalData] = useState(false);

    const { investmentRange, revenuesRange } = startupDetails.rankingData[ranking.id];

    const addContractHrefPage = '/ranking/latam/startup/step8/add';

    useEffectOnce(() => {
        if (isControlledByCorp || investmentRange[0] >= 2500000 || revenuesRange[0] >= 2500000) {
            setOpenScaleUpWarning(true);
            setDisplayCalculator(false);
        }
        if (investmentRange[1] >= 20000000 || revenuesRange[1] >= 20000000) {
            setDisplayCalculator(false);
            setOpenCorpWarning(true);
        }
    });

    useEffect(() => {
        if (revenuesRange[1] >= 20000000) {
            setOpenScaleUpWarning(true);
        }
    }, [revenuesRange]);

    const contractsWithOnClick = contracts.map(i => {
        const item = { ...i };
        item.onClick = () => setModalData(i);
        return item;
    });

    const invalidContracts = contractsWithOnClick.filter(
        i => i.status !== 'deleted' && !i.eligibility?.[ranking.year]?.eligible
    );

    const validContracts = contractsWithOnClick.filter(
        i => i.status !== 'deleted' && i.eligibility?.[ranking.year]?.eligible
    );

    const hasDisplayedProperties =
        Object.prototype.hasOwnProperty.call(startupEstimatedPosition, 'audited') ||
        Object.prototype.hasOwnProperty.call(startupEstimatedPosition, 'confirmed') ||
        Object.prototype.hasOwnProperty.call(startupEstimatedPosition, 'declared');

    // Update the step has completed if contracts exist
    useEffect(() => {
        if (contracts && !!contracts.length && steps.step7 && !steps.step8) {
            updateSteps({ step8: true });
        }
    }, []);

    useEffect(() => {
        if (modalData) {
            const filteredContracts = contracts.filter(i => i._id === modalData._id);
            setModalData(filteredContracts[0]);
        }
    }, [contracts, modalData]);

    // Check for previous step conclusion
    if (!steps || !steps.step5) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    const pending = validContracts
        .filter(i => i.status === 'pending' && i?.eligibility[ranking.year]?.eligible)
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const rejected = validContracts
        .filter(i => i.status === 'rejected' && i?.eligibility[ranking.year]?.eligible)
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const confirmed = validContracts
        .filter(i => i.status === 'confirmed' && i?.eligibility[ranking.year]?.eligible)
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const invalid = invalidContracts.sort((a, b) => {
        return new Date(b.regDate) - new Date(a.regDate);
    });

    const propsAlert = {
        title: props.t('StartupStep8.noContracts'),
        descr: props.t('StartupStep8.noContractsDescr'),
        icons: 'far fa-exclamation-circle',
        buttn: {
            href: addContractHrefPage,
            text: props.t('StartupStep8.addContract')
        },
        type: 'warning'
    };

    const propsScaleUpWarning = {
        icons: 'far fa-info-circle',
        type: 'warning',
        bgColor: 'rgba(69, 170, 242, 0.55)',
        descr: props.t('StartupStep8.openScaleUp')
    };

    const propsOpenCorpWarning = {
        icons: 'far fa-info-circle',
        type: 'warning',
        bgColor: 'rgba(69, 170, 242, 0.55);',
        color: '#003256',
        descr: props.t('StartupStep8.openCorp')
    };

    return (
        <Page.Page
            name="StartupStep8"
            back="/ranking/latam/startup"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Page.Title>{props.t('StartupStep8.title')}</Page.Title>
            <Page.Descr>{props.t('StartupStep8.descr')}</Page.Descr>

            <Button onClick={() => RHP.push(addContractHrefPage)} type="linkd btn-block">
                <span className="text">{props.t('StartupStep8.addContract')}</span>
            </Button>

            <Page.Descr>{props.t('StartupStep8.descr2')}</Page.Descr>

            {validContracts
                .filter(i => i.startupStatus === 'pending')
                .slice(0, 1)
                .map(i => {
                    const item = { ...i };
                    item.onClick = undefined;

                    return (
                        <Contract
                            key={`pending-${i._id}`}
                            data={item}
                            useRankingStartup={props.useRankingStartup}
                        />
                    );
                })}

            {!validContracts.length && <Alert.Alert {...propsAlert} />}

            {displayCalculator && hasDisplayedProperties && (
                <StartupPosition data={startupEstimatedPosition} {...props} />
            )}

            {openScaleUp && <Alert.AlertRow {...propsScaleUpWarning} />}

            {openCorp && <Alert.AlertRow {...propsOpenCorpWarning} />}

            <Page.Buttn to={addContractHrefPage} type="linkd btn-block">
                <span className="icon far fa-plus-circle" aria-hidden="true" />
                <span className="text">{props.t('StartupStep8.addContract')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Tabs.TabsContainer>
                <Tabs.TabsButtons>
                    <Tabs.Tab name="TAB_CONFIRMED">
                        {props.t('glossary:confirmedBy.corporation')}
                        {confirmed.length && <span>({confirmed.length})</span>}
                    </Tabs.Tab>

                    <Tabs.Tab name="TAB_PENDING">
                        {props.t('glossary:pending')}
                        {pending.length && <span>({pending.length})</span>}
                    </Tabs.Tab>

                    <Tabs.Tab name="TAB_REJECTED">
                        {props.t('glossary:rejected')}
                        {rejected.length && <span>({rejected.length})</span>}
                    </Tabs.Tab>

                    <Tabs.Tab name="TAB_INVALID">
                        {props.t('glossary:invalidForRanking')}
                        {invalid.length && <span>({invalid.length})</span>}
                    </Tabs.Tab>
                </Tabs.TabsButtons>

                <List.List name="TAB_CONFIRMED">
                    <List.Vertical items={confirmed} itemKey="_id" itemComponent={ContractCard} />
                </List.List>

                <List.List name="TAB_PENDING">
                    <List.Vertical items={pending} itemKey="_id" itemComponent={ContractCard} />
                </List.List>

                <List.List name="TAB_REJECTED">
                    <List.Vertical items={rejected} itemKey="_id" itemComponent={ContractCard} />
                </List.List>

                <List.List name="TAB_INVALID">
                    <List.Vertical items={invalid} itemKey="_id" itemComponent={ContractCard} />
                </List.List>
            </Tabs.TabsContainer>

            {/* <CsvButton hook={props.useRankingStartup} vars={vars} origin={'startups'} {...props} /> */}

            <div style={{ display: 'flex', margin: '10px 0' }}>
                <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/startup/step7">
                    <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                    <span className="text" style={{ margin: '0 0 0 auto' }}>
                        {props.t('glossary:back')}
                    </span>
                </Page.Buttn>

                <Page.Buttn style={{ marginLeft: '10px' }} to="/ranking/latam/startup/">
                    <span className="text">{props.t('glossary:continue')}</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </Page.Buttn>
            </div>

            <Modal.Modal
                close={() => setModalData(false)}
                title={props.t('StartupStep8.contractDetails')}
                isOpen={!!modalData}
            >
                {!!modalData && (
                    <Contract
                        closeModal={() => setModalData(false)}
                        data={modalData}
                        style={{ marginTop: '1rem' }}
                        useRankingStartup={props.useRankingStartup}
                    />
                )}
            </Modal.Modal>
        </Page.Page>
    );
}
