import React from 'react';
import { useTranslation } from 'react-i18next';

import { MatchesRoutes } from './MatchesRoutes';

export function Matches(props) {
    const { t } = useTranslation('matches');
    return <MatchesRoutes t={t} />;
}
export default Matches;
