import React from 'react';
import { useEffectOnce } from 'react-use';
import { Device } from '@capacitor/device';

import { CONFIG } from 'config';
import * as List from 'components/list';
import * as Page from 'components/page';

import * as Styled from './StyledPagesSupport';

const initial = {
    device: '',
    vrsion: false,
};
export function PagesSupport(props) {
    // Manage the state for app loading control
    const [state, setState] = React.useState(initial);

    // Check the app version on the api
    async function checkAppVersion() {
        // Get the device information
        const device = await Device.getInfo();
        setState({ device });
    }
    useEffectOnce(() => {
        checkAppVersion();
    });

    // Create the secondary actions options
    const actions = [
        {
            as: 'a',
            icon: 'fab fa-whatsapp',
            href: 'https://wa.me/551135005001',
            text: props.t('PagesSupport.whatsapp'),
        },
        {
            as: 'a',
            icon: 'fab fa-facebook-messenger',
            href: 'http://www.facebook.com/messages/t/1616610695217236',
            text: props.t('PagesSupport.messenger'),
        },
        {
            as: 'a',
            icon: `far fa-envelope`,
            href: 'mailto:helpme@openstartups.net',
            text: props.t('PagesSupport.support'),
        },
    ];

    return (
        <Styled.PagesSupport as={Page.Page} name="PagesSupport">
            <Page.Title>{props.t('PagesSupport.title')}</Page.Title>
            <Page.Descr>{props.t('PagesSupport.descr')}</Page.Descr>

            <List.List name="PagesSupportList">
                <List.Vertical items={actions} itemKey="icon" />
            </List.List>

            <Page.Discl>{`Code. ${CONFIG.appVersion}`}</Page.Discl>
            {state.device.appVersion && (
                <Page.Discl
                    style={{ marginTop: 0 }}
                >{`Native. ${state.device.appVersion}`}</Page.Discl>
            )}
        </Styled.PagesSupport>
    );
}
export default PagesSupport;
