import styled from 'styled-components';

export const HourDate = styled.div.attrs({
    className: 'hour-date',
})`
    display: flex;
    align-items: center;

    padding: 6px 12px;
    background-color: ${props => (props.blocked ? '#e0e0e0' : 'white')};
    color: ${props => (props.blocked ? 'black' : '#a8aebc')};
    margin-top: 1.6rem;
    min-height: 5.8rem;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    justify-content: space-between;

    .available-text {
        flex-grow: 4;
        color: #a8aebc;
        font-size: 1.4rem;
    }

    .ddmm {
        font-size: 1.8rem;
        margin: 0 20px;
    }

    .lock-button {
        padding: 6px 12px;
        border-radius: 0.25rem;
        background-color: ${props => (props.blocked ? 'white' : '#f8f9fa')};
        font-size: 1rem;
        color: black;
    }

    p {
        margin: 0;
        padding: 0;
    }
`;
