import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionMentoringSession = ({ match }) => {
    const { t, setModal } = useCXI();

    return (
        <Styled.MatchActionInline
            className="s3 action"
            onClick={() => setModal({ match, modal: 'MentoringSession' })}
        >
            <span className="text">{t('match.action.mentoringSession')}</span>
        </Styled.MatchActionInline>
    );
};
