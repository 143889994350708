import React, { useEffect } from 'react';
import { ForceGraph, ForceGraphNode, ForceGraphLink } from 'react-vis-force';

export function NetworkChart(props) {
    const { data } = props;
    const { groupNetwork } = data;
    const { nodes } = groupNetwork;

    // HAD TO CHANGE THE LIB  FROM  labelAttr: 'id', TO: labelAttr: 'name',
    const formattedNodes = nodes
        ?.filter(node => node.name !== 'N/A')
        .map(node => ({
            id: node.name,
            name: node.name.length > 15 ? `${node.name.substring(0, 15)}...` : node.name,
            contracts: node.contracts,
            radius: node.points + 10,
            type: node.type
        }));

    const formattedNodeLinks = groupNetwork?.nodeLinks
        .filter(GraphNodeLink => {
            const sourceNode = formattedNodes.find(node => node.id === GraphNodeLink.source);
            const targetNode = formattedNodes.find(node => node.id === GraphNodeLink.target);
            return sourceNode && targetNode; // Check if both source and target nodes exist
        })
        .map(GraphNodeLink => {
            return { source: GraphNodeLink.source, target: GraphNodeLink.target };
        });

    useEffect(() => {
        const mySVG = document.getElementsByClassName('rv-force__svg');
        function resetViewbox(e) {
            const { contentBoxSize } = e[0];
            const { inlineSize } = contentBoxSize[0];
            const { blockSize } = contentBoxSize[0];

            let viewBox;
            if (window.innerWidth > 1200) {
                viewBox = [
                    -inlineSize * 1.6,
                    -inlineSize * 0.6,
                    inlineSize * 3.2,
                    blockSize * 1.8
                ].join(' ');
            } else {
                viewBox = [
                    -inlineSize * 1.6,
                    -inlineSize * 0.7,
                    inlineSize * 3.6,
                    blockSize * 2.7
                ].join(' ');
            }

            if (mySVG[0] !== undefined) mySVG[0].setAttribute('viewBox', viewBox);
        }
        const fluid = document.getElementById('fluid');
        if (fluid) {
            new ResizeObserver(resetViewbox).observe(fluid);
        }
    }, [document]);

    function getFontSize(radius) {
        const minFontSize = 25;
        const maxFontSize = 60;

        // Scale the radius from 0 to 1
        const scaledRadius = (radius - 0) / (300 - 0);

        // Calculate the font size within the min and max range
        const fontSize = scaledRadius * (maxFontSize - minFontSize) + minFontSize;

        // Round the font size to the nearest integer
        return Math.round(fontSize);
    }

    return formattedNodes === undefined ? null : (
        <ForceGraph
            simulationOptions={{
                // velocityDecay: 0.9,
                // alpha: 0.8,
                // alphaMin: 0.001,
                // alphaDecay: 1,
                radiusMargin: 20,
                animate: false,
                width: '100%',
                height: '100%'
            }}
        >
            {formattedNodes.map(formattedNode => {
                return (
                    <ForceGraphNode
                        key={formattedNode.id}
                        node={formattedNode}
                        showLabel
                        fill={formattedNode.type === 'company' ? '#80d6e9e8' : '#62ab62ec'}
                        r={formattedNode.radius}
                        label={formattedNode.name} // Set the label to the node's name
                        labelStyle={{
                            fontWeight: `${getFontSize(formattedNode.radius) > 30 ? 700 : 400}`,
                            fontSize: `${
                                getFontSize(formattedNode.radius) < 30
                                    ? 0
                                    : getFontSize(formattedNode.radius)
                            } `,
                            textAnchor: 'middle', // Center the text horizontally
                            dominantBaseline: 'central', // Center the text vertically
                            transform: `translate(${formattedNode.radius *
                                -0.5}px, ${formattedNode.radius * 0.3}px)` // Adjust the multiplier to vertically center the label
                        }}
                    />
                );
            })}

            {formattedNodeLinks.map(GraphNodeLink => {
                const sourceNode = formattedNodes.find(node => node.id === GraphNodeLink.source);
                const targetNode = formattedNodes.find(node => node.id === GraphNodeLink.target);
                const connections = targetNode.contracts.length; // Assuming contracts represent connections

                const strokeWidth = Math.max(1, Math.sqrt(connections)); // Calculate the strokeWidth based on connections

                return (
                    <ForceGraphLink
                        key={Math.random()}
                        link={GraphNodeLink}
                        fill="green"
                        strokeWidth={strokeWidth}
                    />
                );
            })}
        </ForceGraph>
    );
}
