import React from 'react';

import { Area } from '../components/area/Area';
import { useCXIDashboardStartup } from '../useCXIDashboardStartup';

import { CXIDashboardStartupSummariesTechnology } from './Technology';
import { CXIDashboardStartupSummariesResearcher } from './Researcher';

import * as Styled from './StyledCXIDashboardStartupSummaries';

export const CXIDashboardStartupSummaries = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    return (
        <Styled.CXIDashboardStartupSummaries>
            {!!startup.demographics?.techStatus && (
                <Area className="technology" title={t('startup.technology')}>
                    <CXIDashboardStartupSummariesTechnology />
                </Area>
            )}

            {!!startup.demographics?.researcher && (
                <Area className="researcher" title={t('startup.researcher')}>
                    <CXIDashboardStartupSummariesResearcher />
                </Area>
            )}
        </Styled.CXIDashboardStartupSummaries>
    );
};
