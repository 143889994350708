import React from 'react';

import { Header } from 'modules/cxi/components/header/Header';

import { useCXIMentoring } from '../../useCXIMentoring';

import { SessionsTable } from './SessionsTable';
import * as Styled from './StyledSessions';

export const Sessions = ({ match }) => {
    const { t, vars } = useCXIMentoring();
    const { sessions } = vars;

    // for (let i = sessions.length; i < 5; i++) {
    //     sessions.push({ _id: `fake_mentoring_session_id_${i}`, fake: true });
    // }

    return (
        <Styled.Sessions>
            <Header
                title={t('mentoring.Sessions.title')}
                tooltip={t('mentoring.Sessions.tooltip')}
                button={
                    <span style={{ fontSize: '1.4rem', marginLeft: 'auto' }}>
                        {sessions.length}/5 {t('mentoring.Sessions.completed')}
                    </span>
                }
            >
                <SessionsTable match={match} sessions={sessions} t={t} />
            </Header>
        </Styled.Sessions>
    );
};
