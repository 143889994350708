import React from 'react';

import { Header } from 'modules/cxi/components/header/Header';
import Button from 'components/button';
import { useCXIDashboard } from '../useCXIDashboard';
import * as Styled from '../components/StyledDashboardPage';
import { Users } from './components/users/Users';
import { FilterTag } from './components/filter-tag/FilterTag';

export const CXIDashboardUsers = () => {
    const { t, vars, downloadDashboardUsers } = useCXIDashboard();
    const { users, company } = vars;

    const [filter, setFilter] = React.useState('');
    const [filterStage, setFilterStage] = React.useState([]);

    const hasFilterStage = value => {
        return !!filterStage.includes(value);
    };

    const updFilterStage = value => {
        if (hasFilterStage(value)) {
            return setFilterStage(f => f.filter(i => i !== value));
        }
        return setFilterStage(f => [...f, value]);
    };

    const handleChange = e => {
        return setFilter(e.target.value);
    };

    const filtered =
        !filterStage.length && !filter
            ? users
            : users.filter(i => {
                  let shouldShow = true;

                  const hasMatches = `${i.firstName} ${i.lastName}`
                      .toLowerCase()
                      .includes(filter.toLowerCase());
                  if (filter && !hasMatches) shouldShow = false;

                  if (!!filterStage.length && !hasFilterStage(i.cxiStats?.currentStage)) {
                      shouldShow = false;
                  }
                  return shouldShow;
              });

    const field = {
        className: 'c-header-input',
        placeholder: t('dashboard.users.search'),
        type: 'search',
        value: filter,
        onChange: handleChange
    };

    return (
        <Styled.DashboardPage>
            <Styled.DashboardPageHead>
                <div className="wrapper">
                    <h2>
                        {t('dashboard.users.title')}{' '}
                        <span className="blue-text">{company.name}</span>{' '}
                        {t('dashboard.users.title2')}
                    </h2>
                </div>

                <Button style={{ marginBottom: '0.5rem' }} onClick={() => downloadDashboardUsers()}>
                    Baixar em CSV
                </Button>
            </Styled.DashboardPageHead>

            <Header className="filter">
                <input name="search" {...field} />
            </Header>

            <Header className="filter">
                {t('dashboard.users.filterStage')}
                <FilterTag active={hasFilterStage(1)} onClick={() => updFilterStage(1)}>
                    <span className="text">Avaliações Online</span>
                </FilterTag>
                <FilterTag active={hasFilterStage(2)} onClick={() => updFilterStage(2)}>
                    <span className="text">Speed-Dating</span>
                </FilterTag>
                <FilterTag active={hasFilterStage(3)} onClick={() => updFilterStage(3)}>
                    <span className="text">Mentoria</span>
                </FilterTag>
            </Header>
            <Styled.DashboardPageBody>
                <Users users={filtered} t={t} />
            </Styled.DashboardPageBody>
        </Styled.DashboardPage>
    );
};
