import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './StyledModalNavbar';

const StyledModalNavbar = props => (
    <Styled.ModalNavbar>
        <Styled.ModalNavbarContainer>{props.children}</Styled.ModalNavbarContainer>
    </Styled.ModalNavbar>
);

export function ModalNavbar(props) {
    const { t } = useTranslation('glossary');
    return (
        <StyledModalNavbar>
            {/* Create the element based on 'title' props. */}
            {props.title && (
                <Styled.ModalNavbarTxt>
                    <span className="text">{props.title}</span>
                </Styled.ModalNavbarTxt>
            )}
            {/* Create the close button. */}
            <Styled.ModalNavbarBtn onClick={props.close}>
                <span className="text">{t('close')}</span>
                <span className="icon far fa-times" aria-hidden="true" />
            </Styled.ModalNavbarBtn>
        </StyledModalNavbar>
    );
}
export default ModalNavbar;
