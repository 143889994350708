import React from 'react';
import { useClickAway } from 'react-use';

import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledChallengeGuide';

export const ChallengeGuide = () => {
    const { t } = useCXI();
    const { setModal } = useCXI();
    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
        setModal(null);
    });

    return (
        <Styled.Guide ref={containerRef}>
            <Styled.Close onClick={() => setModal(null)}>
                <span className="icon far fa-times" aria-hidden="true" />
            </Styled.Close>
            <Container.Head>
                <span className="text">{t('challenge.guide')}?</span>
            </Container.Head>

            <Container.Body>
                <iframe
                    src="https://www.youtube-nocookie.com/embed/_Tlp8FWGC9M?controls=0"
                    title="Como começar a contribuir com Startups"
                />
            </Container.Body>
        </Styled.Guide>
    );
};
