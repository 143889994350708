import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Iframe = styled.div.attrs({
    className: 'styled-iframe',
})`
    height: 100%;
    position: fixed;
    ${positions(0, 0, 0, 0)}
    iframe,
    .iframe {
        border: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
`;
