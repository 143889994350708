import styled from 'styled-components';
import { positions } from 'utils/styles';

export const CXIMessages = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(1.6rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 992px) {
        padding: 3.2rem;
        padding-bottom: calc(3.2rem + var(--ion-safe-area-bottom));
    }

    &.desktop {
        display: flex;
        gap: 3.2rem;
        height: calc(100vh - 80px);
    }
`;

export const CXIMessagesLayout = styled.div`
    // background: red;
    height: 100%;
    position: relative;
`;
export const CXIMessagesWrapper = styled.div`
    // background: blue;
    display: flex;
    position: absolute;
    ${positions(0, 0, 0, 0)}
`;

export const CXIMessagesIndex = styled.div`
    border: 3.2rem solid ${({ theme }) => theme.colors.whiteDarker};
    flex: 0 0 40rem;
    height: 100%;
    overflow-y: scroll;
    position: relative;

    ::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
`;

export const CXIMessagesMatch = styled.div.attrs({
    className: 'container',
})`
    flex: 1 1 auto;
    height: 100%;
    padding: 3.2rem;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
`;
