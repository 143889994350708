import React, { useEffect } from 'react';

import { useEffectOnce } from 'react-use';

import * as Page from 'components/page';
import * as Form from 'components/form';
import { useForm } from 'components/form';
import { useParams } from 'react-router';
import { RHP } from 'providers';

import * as parseQuestion from '../../utils/parseQuestion';

export function SubscriptionIndex(props) {
    const { eventId } = useParams();

    const { vars, getCustomForm, putEventProfile, getEventById, getUser } = props.useSubscription;

    const { customForm } = vars;

    const form = useForm({
        ...vars.fields,
    });

    const { addValues } = form;

    useEffect(() => {
        const customFormAnswerQuestion = customForm.attendeeForm?.reduce((acc, customQuestion) => {
            acc[`question-${customQuestion._id}`] = customForm.answers.find(
                customAnswer => customAnswer.questionId === customQuestion._id
            )?.answer;
            return acc;
        }, {});

        addValues({ ...customFormAnswerQuestion, speeddating: customForm.speeddating });
    }, [customForm, addValues]);

    useEffectOnce(() => {
        getCustomForm(eventId);
        getEventById(eventId);
        getUser();
    });

    const {
        parseQuestionType,
        parseQuestionAnswers,
        parseUserAnswers,
        parseRadioAnswer,
    } = parseQuestion;

    const customFormFields = customForm.attendeeForm?.map(customQuestion => {
        const formattedQuestion = { ...customQuestion };
        formattedQuestion.type = parseQuestionType(customQuestion.type);
        formattedQuestion.options = parseQuestionAnswers(customQuestion.answers);

        const { type, options, required, question, _id } = formattedQuestion;

        // TODO options com mesmo value, speeddating nao atualizado no form

        return {
            error: form.errors[`question-${_id}`],
            label: question,
            name: `question-${_id}`,
            type,
            options: options.length ? options : undefined,
            value:
                type === 'radio'
                    ? parseRadioAnswer(form.values[`question-${_id}`])
                    : form.values[`question-${_id}`],
            onChange: form.handleChange,
            required,
            id: _id,
        };
    });

    const fields = {
        speeddating: {
            error: form.errors.speeddating,
            label: props.t('SubscriptionIndex.speeddating'),
            name: 'speeddating',
            type: 'YesNo',
            value: form.values.speeddating,
            onChange: form.handleChange,
            required: true,
        },
    };

    const onSubmit = values => {
        const payload = {
            answers: parseUserAnswers(customFormFields, values),
            speeddating: values.speeddating === 'true',
        };

        putEventProfile(payload, eventId).then(res => {
            let url = `/events/${eventId}/subscription/speeddating`;

            if (!payload.speeddating) {
                url = `/events/${eventId}/subscription/success`;
            }

            RHP.push(url);
            return res;
        });
        return true;
    };

    return (
        <Page.Page name="SubscriptionIndex">
            <Form.Form
                name="SubscriptionIndexForm"
                onSubmit={ev => form.handleSubmit(ev, onSubmit)}
            >
                <Page.Title>{props.t('Subscription.title')}</Page.Title>

                {customFormFields?.map(field => (
                    <Form.Field key={field.id} {...field} />
                ))}

                <Form.Field {...fields.speeddating} />

                <Form.Submt {...form} text={props.t('Subscription.next')} />
            </Form.Form>
        </Page.Page>
    );
}
export default SubscriptionIndex;
