import React from 'react';
import { RHP } from 'providers';

import { useCXIDashboard } from 'modules/cxi/dashboard/useCXIDashboard';
import * as Styled from './StyledStartupsStartup';

export const StartupsStartup = ({ startup }) => {
    const { companyId } = useCXIDashboard();
    const url = `/100-10-1/dashboard/${companyId}/startups/${startup._id}`;
    return (
        <Styled.StartupsStartup onClick={() => RHP.push(url)}>
            <Styled.Image img={startup.logoUrl} />
            <Styled.Title>{startup.name}</Styled.Title>
        </Styled.StartupsStartup>
    );
};
