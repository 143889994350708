import React from 'react';
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';

import { Header } from 'modules/cxi/components/header/Header';

import { useCXIDashboard } from '../useCXIDashboard';
import { DashboardArea } from '../components/area/DashboardArea';
import * as Styled from '../components/StyledDashboardPage';

import { FilterTag } from './components/filter-tag/FilterTag';
import * as StyledStages from './StyledCXIDashboardStages';
import { DashboardNumber } from '../components/number/DashboardNumber';

const today = moment(new Date());

export const CXIDashboardStages = () => {
    const { t, vars, getStages } = useCXIDashboard();
    const { stages, stats, company } = vars;

    const [filterDate, setFilterDateState] = React.useState();

    const isFilterDate = value => {
        return filterDate === value;
    };

    const setFilterDate = value => {
        if (filterDate === value) {
            return setFilterDateState();
        }
        return setFilterDateState(value);
    };

    React.useEffect(() => {
        if (filterDate) {
            const startDate = today.subtract(filterDate, 'days').format('YYYY-MM-DD');
            const endDate = today.format('YYYY-MM-DD');
            getStages(startDate, endDate);
        } else {
            getStages();
        }
    }, [filterDate]);

    const graphStage1Data = {
        labels: [
            t('dashboard.stages.Stage1.queroAvaliar'),
            t('dashboard.stages.Stage1.naoQueroAvaliar')
        ],
        datasets: [
            {
                data: [
                    stages.startupsAccepted ?? 0,
                    (stages.startupsSuggestions ?? 0) - (stages.startupsAccepted ?? 0)
                ],
                backgroundColor: ['#0094F9', '#dadada'],
                borderColor: ['#0094F9', '#dadada']
            }
        ]
    };
    const graphStage1 = <Doughnut data={graphStage1Data} width={200} />;

    const graphStage1FeedbacksData = {
        labels: [t('dashboard.stages.Stage1.positive'), t('dashboard.stages.Stage1.negative')],
        datasets: [
            {
                data: [
                    stages.feedbacksPositive ?? 0,
                    (stages.feedbacks ?? 0) - (stages.feedbacksPositive ?? 0)
                ],
                backgroundColor: ['#0094F9', '#dadada'],
                borderColor: ['#0094F9', '#dadada']
            }
        ]
    };
    const graphStage1Feedbacks = <Doughnut data={graphStage1FeedbacksData} width={200} />;

    const graphStage2Data = {
        labels: [t('dashboard.stages.Stage1.positive'), t('dashboard.stages.Stage1.negative')],
        datasets: [
            {
                data: [
                    stages.speedDatingsHeld ?? 0,
                    (stages.speedDatingsRequested ?? 0) - (stages.speedDatingsHeld ?? 0)
                ],
                backgroundColor: ['#0094F9', '#dadada'],
                borderColor: ['#0094F9', '#dadada']
            }
        ]
    };
    const graphStage2 = <Doughnut data={graphStage2Data} width={200} />;

    const graphStage2FeedbacksData = {
        labels: [t('dashboard.stages.Stage1.positive'), t('dashboard.stages.Stage1.negative')],
        datasets: [
            {
                data: [
                    stages.speedDatingFeedbacksPositive ?? 0,
                    stats.totalPointsStage3 ?? 0,
                    stages.mentoredStartups ?? 0
                ],
                backgroundColor: ['#0094F9', '#dadada'],
                borderColor: ['#0094F9', '#dadada']
            }
        ]
    };
    const graphStage2Feedbacks = <Doughnut data={graphStage2FeedbacksData} width={200} />;

    const graphStage3Data = {
        labels: [t('dashboard.stages.Stage3.positive'), t('dashboard.stages.Stage3.negative')],
        datasets: [
            {
                data: [
                    stages.stage3Points ?? 0,
                    (stages.speedDatingsRequested ?? 0) - (stages.speedDatingsHeld ?? 0)
                ],
                backgroundColor: ['#0094F9', '#dadada'],
                borderColor: ['#0094F9', '#dadada']
            }
        ]
    };
    const graphStage3 = <Doughnut data={graphStage3Data} width={200} />;

    const graphStage3MentoringData = {
        labels: [t('dashboard.stages.Stage1.positive'), t('dashboard.stages.Stage1.negative')],
        datasets: [
            {
                data: [stages.speedDatingsRequested, stages.speedDatingsHeld],
                backgroundColor: ['#0094F9', '#dadada'],
                borderColor: ['#0094F9', '#dadada']
            }
        ]
    };
    const graphStage3Mentoring = <Doughnut data={graphStage3MentoringData} width={200} />;

    return (
        <Styled.DashboardPage>
            <Styled.DashboardPageHead>
                <h2>
                    {t('dashboard.stages.title')} <span className="blue-text">{company.name}</span>{' '}
                    {t('dashboard.stages.title2')}
                </h2>
            </Styled.DashboardPageHead>

            <Header className="filter">
                {t('dashboard.stages.filterStage')}
                <FilterTag
                    active={isFilterDate(undefined)}
                    onClick={() => setFilterDate(undefined)}
                >
                    <span className="text">{t('all')}</span>
                </FilterTag>
                <FilterTag active={isFilterDate(90)} onClick={() => setFilterDate(90)}>
                    <span className="text">90 {t('days')}</span>
                </FilterTag>
                <FilterTag active={isFilterDate(30)} onClick={() => setFilterDate(30)}>
                    <span className="text">30 {t('days')}</span>
                </FilterTag>
                <FilterTag active={isFilterDate(7)} onClick={() => setFilterDate(7)}>
                    <span className="text">7 {t('days')}</span>
                </FilterTag>
            </Header>

            <Styled.DashboardPageSubHead>
                <h3>{t('dashboard.stages.stage1')}</h3>
                {/* <h4>({stats.usersStage1} pessoas)</h4> */}
            </Styled.DashboardPageSubHead>

            <StyledStages.DashboardPageAreas>
                <DashboardArea graph={graphStage1} title="Startups">
                    <DashboardNumber number={stages.startupsSuggestions ?? 0}>
                        {t('dashboard.stages.Stage1.startupsSuggestions')}
                    </DashboardNumber>
                    <DashboardNumber number={stages.startupsAccepted ?? 0} active>
                        {t('dashboard.stages.Stage1.startupsAccepted')}
                    </DashboardNumber>
                </DashboardArea>
                <DashboardArea
                    graph={graphStage1Feedbacks}
                    title="Feedbacks a partir de Avaliação Online"
                >
                    <DashboardNumber number={stages.feedbacks ?? 0}>
                        {t('dashboard.stages.Stage1.feedbacks')}
                    </DashboardNumber>
                    <DashboardNumber number={stages.feedbacksPositive ?? 0} active>
                        {t('dashboard.stages.Stage1.feedbacksPositive')}
                    </DashboardNumber>
                </DashboardArea>
            </StyledStages.DashboardPageAreas>

            <Styled.DashboardPageSubHead>
                <h3>{t('dashboard.stages.stage2')}</h3>
                {/* <h4>({stats.usersStage2} pessoas)</h4> */}
            </Styled.DashboardPageSubHead>

            <StyledStages.DashboardPageAreas>
                <DashboardArea graph={graphStage2} title="Speed-Datings">
                    <DashboardNumber number={stages.speedDatingsRequested ?? 0}>
                        {t('dashboard.stages.Stage2.speedDatingsRequested')}
                    </DashboardNumber>
                    <DashboardNumber number={stages.speedDatingsHeld ?? 0} active>
                        {t('dashboard.stages.Stage2.speedDatingsHeld')}
                    </DashboardNumber>
                </DashboardArea>
                <DashboardArea
                    graph={graphStage2Feedbacks}
                    title="Feedbacks a partir de Speed-Datings"
                >
                    <DashboardNumber number={stages.speedDatingFeedbacks ?? 0}>
                        {t('dashboard.stages.Stage2.speedDatingFeedbacks')}
                    </DashboardNumber>
                    <DashboardNumber number={stages.speedDatingFeedbacksPositive ?? 0} active>
                        {t('dashboard.stages.Stage2.speedDatingFeedbacksPositive')}
                    </DashboardNumber>
                </DashboardArea>
            </StyledStages.DashboardPageAreas>

            <Styled.DashboardPageSubHead>
                <h3>{t('dashboard.stages.stage3')}</h3>
                {/* <h4>({stats.usersStage3} pessoas)</h4> */}
            </Styled.DashboardPageSubHead>

            <StyledStages.DashboardPageAreas>
                <DashboardArea>
                    {/* graph={graphStage3} */}
                    <DashboardNumber number={stages.stage3Users ?? '0'}>
                        {t('dashboard.stages.Stage3.peoples')}
                    </DashboardNumber>

                    <DashboardNumber number={stages.stage3Points ?? '0'}>
                        {t('dashboard.stages.Stage3.points')}
                    </DashboardNumber>

                    <DashboardNumber number={stages.mentoredStartups ?? '0'}>
                        {t('dashboard.stages.Stage3.startups')}
                    </DashboardNumber>
                </DashboardArea>
                {/* <DashboardArea graph={graphStage3Mentoring}>
                    <DashboardNumber number={stages.speedDatingsRequested ?? '0'}>
                        {t('dashboard.stages.Stage3.speedDatingsRequested')}
                    </DashboardNumber>

                    <DashboardNumber number={stages.speedDatingsHeld ?? '0'}>
                        {t('dashboard.stages.Stage3.speedDatingsHeld')}
                    </DashboardNumber>
                </DashboardArea> */}
            </StyledStages.DashboardPageAreas>
        </Styled.DashboardPage>
    );
};
