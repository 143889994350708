import React from 'react';
import { useApi } from 'hooks';
import { Button } from 'modules/cxi/components/button';

export const CSVButton = ({ startupId, t }) => {
    const api = useApi();
    async function getContacts() {
        return api
            .get(`/startups/${startupId}/contacts`, {
                responseType: 'blob',
                headers: {
                    Accept: 'text/csv'
                }
            })
            .then(response => {
                const url = window.URL.createObjectURL(response);
                console.log(url, 'button url');
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'contacts.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <Button onClick={getContacts}>
            <i className="fa fa-download" aria-hidden="true" /> {t('GrowUpContacts.downloadButton')}
        </Button>
    );
};

export default CSVButton;
