import React from 'react';

import { useConversionTable } from 'hooks';
import { formatDate } from 'utils/date';

import { useCXIStartup } from '../useCXIStartup';

import * as Styled from './StyledCXIStartupFinancial';

export const CXIStartupFinancialFinances = () => {
    const { t, vars } = useCXIStartup();
    const { startup } = vars;

    const conv = useConversionTable(['investedHowMuch', 'revenues', 'seekingHowMuch', 'yesNo']);
    const data = startup.demographics.financial;

    const dateOfInvestment = data?.dateOfInvestment
        ? formatDate(new Date(data?.dateOfInvestment), 'dd/MM/yyyy')
        : '-';

    return (
        <>
            <Styled.CXIStartupFinancialFinances>
                {!!data.revenues && (
                    <>
                        <dt>{t('startup.anualRevenue')}</dt>
                        <dd>{conv.revenues[data.revenues]}</dd>
                    </>
                )}

                {!!data.invested && (
                    <>
                        <dt>{t('startup.isInvested')}</dt>
                        <dd>{conv.yesNo[data.invested === 1]}</dd>
                    </>
                )}

                {!!data.dateOfInvestment && (
                    <>
                        <dt>{t('startup.dateOfInvestment')}</dt>
                        <dd>{dateOfInvestment}</dd>
                    </>
                )}

                {!!data.beneficiary && (
                    <>
                        <dt>{t('startup.beneficiary')}</dt>
                        <dd>{data.beneficiary}</dd>
                    </>
                )}
            </Styled.CXIStartupFinancialFinances>

            <Styled.CXIStartupFinancialFinances>
                {!!data.investedHowMuch && (
                    <>
                        <dt>{t('startup.investedHowMuch')}</dt>
                        <dd>{conv.investedHowMuch[data.investedHowMuch]}</dd>
                    </>
                )}

                {!!data.seekingInvestment && (
                    <>
                        <dt>{t('startup.seekingInvestment')}</dt>
                        <dd>{conv.yesNo[data.seekingInvestment === 1]}</dd>
                    </>
                )}

                {!!data.seekingHowMuch && (
                    <>
                        <dt>{t('startup.seekingHowMuch')}</dt>
                        <dd>{conv.seekingHowMuch[data.seekingHowMuch]}</dd>
                    </>
                )}
            </Styled.CXIStartupFinancialFinances>
        </>
    );
};
