import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const FormAutocomplete = styled.div.attrs(props => {
    const shows = props.shows ? 'shows' : '';
    return { className: `form-autocomplete ${shows}` };
})`
    border: 0.3rem solid transparent;
    margin: -0.3rem -0.3rem 0;
    position: relative;

    .reqs {
        display: none;
    }

    &.shows {
        border-color: #ddd;
    }
`;

export const FormAutocompleteList = styled.div.attrs({
    className: 'form-autocomplete-list',
})`
    background: #fff;
    padding: 0 1rem 1rem;
    /* position: absolute; */

    .list-vertical {
        margin: 0;
        padding-top: 0.5rem;
    }
    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444;
    }
    .list-vertical-item-link .text {
        ${truncate(1)}
    }

    .c-page-discl,
    .c-page-links-with-text {
        margin: 0;
        padding-top: 1rem;
    }
`;

export const FormAutocompleteModal = styled.div.attrs({
    className: 'form-autocomplete-modal',
})``;
