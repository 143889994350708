import { useForm } from 'components/form';
import * as Page from 'components/page';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import RegisterContactForm from '../form/RegisterContactForm';
import * as Styled from '../form/StyledRegisterContactForm';

export const RegisterContactIndex = props => {
    const { i18n } = useTranslation();
    const { language } = i18n;
    const { t } = useTranslation('startup');
    const { getStartupDataByStartupSecret, postContact } = props.useRegisterContact;
    const form = useForm({});
    const [startup, setStartup] = useState();
    const [loading, setLoading] = useState(true);
    const [registerStatus, setRegisterStatus] = useState('');

    const params = useParams();
    const { startupSecret } = params;

    useEffect(() => {
        async function getStartupData() {
            const startupResponse = await getStartupDataByStartupSecret(startupSecret);
            setStartup(startupResponse);
            setLoading(false);
        }
        if (!startup) {
            getStartupData();
        }
    }, [startup]);

    const onSubmit = values => {
        const contactPayload = {
            startupId: startup._id,
            fullName: values.fullName,
            email: values.email,
            company: values.company,
            phone: values.phone,
            sendEmail: values.sendEmail,
            startupSecret,
            language
        };

        postContact(contactPayload).then(res => {
            if (res._id) {
                setRegisterStatus('success');
                form.clearValues();
                return form.setSubmit(false);
            }
            setRegisterStatus('failure');
            return form.setSubmit(false);
        });
    };

    const propsForm = { ...props, t, form, onSubmit };

    return startup && !loading ? (
        <Page.Page name="RegisterContact" loading={loading} loadingback="/home">
            <Page.Title>{t('RegisterContact.title')}</Page.Title>
            <Page.Descr>{t('RegisterContact.descr')}</Page.Descr>
            <Styled.Startup>
                <img src={startup?.logoUrl} alt={`logo da ${startup?.name}`} />
                <span>{startup?.name}</span>
            </Styled.Startup>
            <>
                {registerStatus === 'failure' && (
                    <Styled.Error>{t('RegisterContact.error')}</Styled.Error>
                )}
                {registerStatus === 'success' && (
                    <Styled.Success>{t('RegisterContact.success')}</Styled.Success>
                )}
                <p>{t('RegisterContact.basicInf')}</p>
                <RegisterContactForm {...propsForm} startup={startup} />
            </>
        </Page.Page>
    ) : (
        <Page.Page name="RegisterContact" loading={loading} back="/home">
            <Page.Title>{t('RegisterContact.notFound')}</Page.Title>
        </Page.Page>
    );
};

export default RegisterContactIndex;
