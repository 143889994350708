import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as StartupId from './index';

// Verify htis item on StartupId
import GrowUpContacts from './contacts/GrowUpContacts';

export const routes = [
    {
        exact: true,
        path: '/startup/:startupId',
        component: StartupId.StartupIdIndex
    },
    {
        exact: true,
        path: '/startup/:startupId/advanced-edit',
        component: StartupId.AdvancedEdit
    },
    {
        exact: true,
        path: '/startup/:startupId/basic-edit',
        component: StartupId.BasicEdit
    },

    {
        exact: false,
        path: '/startup/:startupId/100-10-1',
        component: StartupId.StartupCXI
    },
    {
        exact: true,
        path: '/startup/:startupId/cxi/feedback',
        component: StartupId.StartupCXI
    },
    {
        exact: true,
        path: '/startup/:startupId/cxi/speeddating',
        component: StartupId.CxiSpeedDating
    },

    {
        exact: true,
        path: '/startup/:startupId/founder',
        component: StartupId.FounderAdd
    },
    {
        exact: true,
        path: '/startup/:startupId/investment',
        component: StartupId.InvestmentAdd
    },
    {
        exact: true,
        path: '/startup/:startupId/merger',
        component: StartupId.MergerAdd
    },
    {
        exact: true,
        path: '/startup/:startupId/profile',
        component: StartupId.StartupIdProfile
    },
    {
        exact: true,
        path: '/startup/:startupId/contacts',
        component: GrowUpContacts
    }
];

export const StartupIdRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default StartupIdRoutes;
