import React from 'react';

import { useConversionTable } from 'hooks';

import * as Styled from './StyledCorporationCard';

export function CorporationCard(props) {
    const data = props.data || props.company;
    const conv = useConversionTable(['companyType', 'companySize']);
    const info = {};
    if (data.logourl) {
        info.image = { img: encodeURI(data.logourl), alt: data.name };
    }
    info.title = data.name;
    info.size = conv.companySize[data.size];
    info.type = conv.companyType[data.type];

    return !data ? null : (
        <Styled.CorporationCard>
            <Styled.CardImage {...info.image} />
            <Styled.CardInfos>
                <span className="name">{info.title}</span>
                <span className="text">{info.size}</span>
                {info.type && <span className="text">{info.type}</span>}
            </Styled.CardInfos>
        </Styled.CorporationCard>
    );
}
export default CorporationCard;
