import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Oiweek from './index';

export const routes = [
    {
        exact: true,
        path: '/oiweek',
        component: Oiweek.OiweekIndex,
    },
    {
        exact: true,
        path: '/oiweek/payperuse',
        component: Oiweek.OiweekPayPerUse,
    },
    {
        exact: true,
        path: '/oiweek/subscribe',
        component: Oiweek.OiweekSubscribe,
    },
    {
        exact: true,
        path: '/oiweek/success',
        component: Oiweek.OiweekSuccess,
    },
];

export const OiweekRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default OiweekRoutes;
