import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

import bg from 'assets/images/bg.png';

export const LoginIndex = styled.div.attrs({
    className: 'styled-login-index',
})`
    background: var(--secondary);

    &::before {
        background: transparent no-repeat 50% 50%;
        background-image: url(${bg});
        background-size: cover;
        content: '';
        opacity: 0.2;
        position: absolute;
        ${positions(0, 0, 0, 0)}
    }

    ion-content {
        --background: transparent;
        --color: #fff;
    }

    .component-page-container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        min-height: 100%;
        padding: 3rem;
        padding-top: calc(3rem + var(--ion-safe-area-top));
        padding-bottom: calc(3rem + var(--ion-safe-area-bottom));
        position: relative;

        .menu-lang {
            position: absolute;
            top: calc(3rem + var(--ion-safe-area-top));
            right: 3rem;
        }
        .c-page-title {
            margin-top: 3rem;
            .text {
                ${truncate(4, 1.25)}
                width: 100%;
            }
        }
        .c-page-descr {
            margin-top: 1rem;
        }
        .c-page-buttn {
            margin-top: 3rem;
        }
    }

    .component-list {
        margin-top: auto;
        padding-top: 4rem;
    }
    .component-list .list-vertical-item-link {
        border-color: rgba(255, 255, 255, 0.2);
        color: #fff;
    }
`;
