import React from 'react';
import { Button } from 'modules/cxi/components/button';
import { RHP } from 'providers';
import { getDateXDaysAgo } from 'utils/date';

import useStartupCXI from '../../../useStartupCXI';
import { Matches } from '../matches/Matches';
import * as Styled from './StyledMentoringRequested';

export const MentoringRequested = () => {
    const { t, vars } = useStartupCXI();
    const { matches } = vars;

    const daysAgo = getDateXDaysAgo(5, new Date());

    const mentories = matches.filter(m => m.mentoring && m.mentoring === 'requested');
    const filtered = mentories.filter(m => {
        const dateMatch = m.history?.mentoringRequestedDate
            ? new Date(m.history.mentoringRequestedDate)
            : new Date(m.regDate);
        return !(m.mentoring === 'requested' && (!dateMatch || dateMatch < daysAgo));
    });

    return !filtered.length ? null : (
        <Styled.MentoringRequested>
            <p className="title">{t('mentoring.texts1')}</p>

            <Styled.MentoringRequestedList>
                <Matches matches={filtered.slice(0, 3)} t={t} />
            </Styled.MentoringRequestedList>

            {filtered.length > 3 && (
                <Button
                    variant="link"
                    onClick={() => RHP.push(`/startup/${vars.startupId}/100-10-1/mentoring`)}
                >
                    <span className="text">{t('mentoring.goToMentoring')}</span>
                    <span className="icon fa fa-fw fa-arrow-right" />
                </Button>
            )}
        </Styled.MentoringRequested>
    );
};
