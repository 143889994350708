import styled from 'styled-components';

export const RankingTerm = styled.div.attrs({
    className: 'styled-ranking-term',
})`
    .component-page-container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        min-height: calc(100% - 6rem);
    }

    .form-submit-holder {
        padding-top: 2rem;
    }
`;
