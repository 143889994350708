import React from 'react';
import { useEffectOnce } from 'react-use';
import { IonContent, IonPage } from '@ionic/react';

import { ContextGlobal as CG } from 'contexts';

import { PageNavbar } from './navbar/PageNavbar';
import * as Styled from './StyledComponentPage';

const PageStructure = props => (
    <Styled.ComponentPage as={IonPage} className={props.className} name={props.name}>
        <Styled.PageContent as={IonContent}>
            {!props.loading ? props.children : <div>loading...</div>}
        </Styled.PageContent>
    </Styled.ComponentPage>
);

export function ComponentPage(props) {
    const { dispatch } = React.useContext(CG);

    // Run some structural changes
    // If the menu props is set to false
    useEffectOnce(() => {
        if (props.menu === false) {
            dispatch({ type: 'MENU_SHOW', menu: false });
            return () => dispatch({ type: 'MENU_SHOW', menu: true });
        }
        return undefined;
    });

    return (
        <PageStructure {...props}>
            <Styled.PageNavbar as={PageNavbar} {...props} />
            <Styled.Container>{props.children}</Styled.Container>
        </PageStructure>
    );
}
export const Page = ComponentPage;
export default ComponentPage;
