import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Page from 'components/page';

import { RHP } from 'providers';
import { UserStartupsList } from 'core/components/user';
import { useEffectOnce } from 'react-use';

export const RankingStartup = props => {
    const { t } = useTranslation('ranking');
    const { vars, redirectToStartup, getStartups } = props.useRanking;
    const { startups, startupId } = vars;

    useEffectOnce(() => {
        getStartups().then(res => {
            if (res.length === 0) {
                RHP.push('/startup?rankingRedirect=true');
            }
        });
    });

    const handleClick = startup => {
        redirectToStartup(startup);
    };

    return (
        <Page.Page name="RankingStartup" loading={!startups.length}>
            <Page.Title>{props.t('RankingStartup.title')}</Page.Title>
            <Page.Descr>{props.t('RankingStartup.descr')}</Page.Descr>

            <UserStartupsList
                data={startups.map(startup => ({
                    ...startup,
                    name: startupId === startup._id ? t('loadingStartupData') : startup.name,
                    onClick: () => handleClick(startup),
                }))}
            />
        </Page.Page>
    );
};

export default RankingStartup;
