import React from 'react';
import Select from 'react-select';

export const SelectMultiple = ({ options, input, children }) => {
    const handleSelectChange = e => {
        input.onChange(prev => ({
            ...prev,
            contractExecutionCountries: e.map(item => item.value)
        }));
    };

    return (
        <div style={{ zIndex: 999999 }}>
            {children}
            <Select
                isMulti
                options={options.map(o => ({ label: o.name, value: o.value }))}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: '#ddd'
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        zIndex: 10
                    })
                }}
                onChange={handleSelectChange}
                // name="test"
            />
        </div>
    );
};
export const multipleSelect = SelectMultiple;
export default SelectMultiple;
