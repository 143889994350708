import styled, { css } from 'styled-components';
import { positions } from 'utils/styles';

export const Container = styled.div`
    background: ${({ theme }) => theme.colors.whiteDarker};
    /* Defina as propriedades comuns primeiro */
    display: grid;
    grid-template-areas:
        'header header'
        'aside main';
    grid-template-rows: 8rem 1fr;
    grid-template-columns: 32rem minmax(900px, 1fr);
    height: 100%;

    /* Defina as propriedades que mudam com base na classe */
    ${props =>
        !props.menuOpen &&
        css`
            grid-template-areas:
                'header header'
                'main main';
            grid-template-columns: 1fr;
        `}

    @media (max-width: 991px) {
        main {
            overflow-x: hidden;
        }
        grid-template-areas:
            'header header'
            'main main';
        grid-template-columns: 1fr;
    }

    /* Defina propriedades para ion-content */
    ion-content {
        --background: transparent;
        --color: #fff;
    }

    &.stage-1 {
    }
    &.stage-2 {
    }
`;

export const SimpleContainer = styled.main`
    background: ${({ theme }) => theme.colors.whiteDarker};
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    position: fixed;

    ion-content {
        --background: transparent;
        --color: #fff;
    }
`;

export const Content = styled.div`
    align-items: stretch;
    flex: 1 1 auto;
    display: flex;
`;

export const Main = styled.main.attrs({
    className: 'cxi-main'
})`
    position: relative;
    width: 100%;
    z-index: 300;
    grid-area: main;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    ion-content {
        --background: #f2f2f2;
        --color: #444;
    }

    .component-page-container {
        padding: 2.4rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
        @media (min-width: 992px) {
            padding: 3.2rem;
        }
    }
    .cp-CXINetworks .component-page-container {
        max-width: 100% !important;
        width: 100% !important;
    }
`;

export const Menu = styled.nav`
    grid-area: aside;
    background: #fff;
    box-shadow: 0px 2px 12px #34286812;
    display: none;
    overflow-y: auto;
    ${positions('0', 'auto', 0, 0)}
    transform: translateX(-100%);
    width: 32rem;
    z-index: 1100;

    ${props =>
        props.menuOpen &&
        css`
            z-index: 350;
            display: block;
            transform: translateX(0);
        `}

    @media (max-width: 992px) {
        grid-area: main;
    }
`;
