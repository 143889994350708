import { useContext } from 'react';
import { TabsContext } from './TabsContext';

export const useTabs = () => {
    const context = useContext(TabsContext);
    if (context === undefined) {
        throw new Error('useTabs must be used within a TabsContextProvider');
    }
    return context;
};
