import { useEffectOnce } from 'react-use';
import { App } from '@capacitor/app';

import { RouterHistoryProvider as RHP } from 'providers';

async function redirectToRoute(href) {
    try {
        const launch = new URL(href);
        const redirs = launch.href.substr(launch.origin.length);
        // console.log('RoutesDeeplinks', 'Redirecting to route ${redirectURL}');
        return RHP.push(redirs);
    } catch (err) {
        // console.error('RoutesDeeplinks', err);
        return null;
    }
}

async function createListeners() {
    // Get the launch url
    const ret = await App.getLaunchUrl();
    if (ret && ret.url) redirectToRoute(ret.url);

    App.addListener('appUrlOpen', data => {
        // console.log(`appUrlOpen at Routes.jsx: App opened with URL: ${data.url}`);
        if (data && data.url) redirectToRoute(data.url);
    });
    App.addListener('appRestoredResult', data => {
        // console.log('Restored state:', data);
        // TODO: Restore state for android apps
    });
}

export function RoutesDeeplinks(props) {
    // Create the listeners to reroute the app
    useEffectOnce(() => {
        createListeners();
    });
    // Don't return nothing on this case
    return null;
}
export default RoutesDeeplinks;
