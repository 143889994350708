import styled from 'styled-components';

export const OiweekCalendar = styled.div.attrs({
    className: 'oiweek-calendar',
})`
    background: rgba(0, 0, 0, 0.4) !important;
    color: #fff;
    padding: 2rem 2rem 1rem;

    .c-page-subtitle {
        color: inherit;
        text-align: center;
    }
    .list-vertical-item + .list-vertical-item {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
`;

export const OiweekCalendarEvent = styled.div.attrs({
    className: 'oiweek-calendar-event',
})`
    color: #fff;
    display: flex;
    font-size: 1.4rem;
    justify-content: space-between;
    padding: 1rem 0;

    .date {
        color: var(--primary);
        font-weight: bold;
        @media (max-width: 575px) {
            display: none;
        }
    }
`;
