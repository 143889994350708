import React, { useEffect, useState } from 'react';

import { ContextAuth as CA } from 'contexts';
import { useForm } from 'components/form';
import { useConversionTable } from 'hooks';
import { RHP } from 'providers';

import * as Alert from 'components/alert';
import * as Page from 'components/page';
import * as Table from 'components/table';

import { EcosystemStep1Form } from './EcosystemStep1Form';

export function EcosystemStep1(props) {
    const conv = useConversionTable(['ecosystemRole']);
    const { state } = React.useContext(CA);
    const { vars, putUserRankingApplication } = props.useRankingEcosystem;
    const [disabled, setDisabled] = useState(false);
    // Initialize a new form handler
    const form = useForm({
        ...vars.application,
        applied: vars.application.applied !== false ? true : vars.application.applied,
        isUpToDate: vars.application.applied
    });

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            ...vars.application,
            ...values
        };

        // Set the current step to true;
        if (!payload.steps) {
            payload.steps = {};
        }

        payload.steps.step1 = true;

        return putUserRankingApplication(payload)
            .then(() => RHP.push('/ranking/latam/ecosystem/step2'))
            .catch(() => form.submited());
    };

    // Properties for the table
    const tableAbout = {
        href: '/profile/edit',
        subtitle: {
            text: props.t('EcosystemStep1.aboutYou'),
            link: props.t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                fullName: state.user?.fullName,
                email: state.user?.email,
                personalEmail: state.user.personalEmail,
                phone: state.user?.phone,
                gender: state.user?.gender,
                ecosystemRole: state.user?.role
            },
            name: 'aboutYou',
            dataConv: { ...conv, role: conv.ecosystemRole }
        }
    };
    const tableLocation = {
        href: '/profile/location',
        subtitle: {
            text: props.t('InvestorStep1.aboutLocation'),
            link: props.t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                location: state.user?.locationString
            },
            name: 'aboutLocation'
        }
    };
    const tableWork = {
        href: '/profile/company',
        subtitle: {
            text: props.t('EcosystemStep1.aboutWork'),
            link: props.t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                companyName: state.user.company?.name,
                position: state.user?.position,
                department: state.user?.department
            },
            name: 'aboutWork',
            dataConv: { ...conv, role: conv.ecosystemRole }
        }
    };

    useEffect(() => {
        if (tableWork.object.data.companyName === undefined) {
            setDisabled(true);
        }
    }, [tableWork]);

    const propsMissingCompanyWarning = {
        descr: props.t('EcosystemStep1.missingCompanyWarning'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };
    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page
            name="EcosystemStep1"
            back="/ranking/latam/ecosystem"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Page.Title>{props.t('EcosystemStep1.title')}</Page.Title>
            <Page.Descr>{props.t('EcosystemStep1.descr')}</Page.Descr>
            <Table.Table {...tableAbout} />
            <Table.Table {...tableLocation} />
            <Table.Table {...tableWork} />
            {disabled && <Alert.AlertRow {...propsMissingCompanyWarning} />}
            <EcosystemStep1Form {...propsForm} disabled={disabled} />
        </Page.Page>
    );
}
export default EcosystemStep1;
