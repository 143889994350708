import styled from 'styled-components';

export const MenuUserProfile = styled.div.attrs({
    className: 'menu-user-profile',
})`
    text-align: center;
    .user-profile {
        color: #fff;
        margin-top: -7rem;
    }
`;

export const EditProfile = styled.a.attrs({
    className: 'menu-user-profile-edit btn btn-sm btn-round btn-primary',
})`
    margin-top: 1.5rem;
`;

export const UserProfile = styled.div.attrs({
    className: 'menu-user-profile-user-profile',
})``;
