import React from 'react';

import blackBelt from 'assets/images/belts/selo-black-belt.png';
import brownBelt from 'assets/images/belts/selo-brown-belt.png';
import greenBelt from 'assets/images/belts/selo-green-belt.png';
import whiteBelt from 'assets/images/belts/selo-white-belt.png';
import * as Styled from './StyledUsers';
import { UsersUser } from '../user/UsersUser';

export const Users = ({ users, t }) => (
    <Styled.Users>
        <Styled.UsersThead>
            <Styled.UsersTr>
                <Styled.UsersTh style={{ width: '380px' }}>
                    {t('dashboard.Users.user')}
                    {/* ({users.length}) */}
                </Styled.UsersTh>
                <Styled.UsersTh>{t('dashboard.Users.rank')}</Styled.UsersTh>
                <Styled.UsersTh>{t('dashboard.Users.stage')}</Styled.UsersTh>
                <Styled.UsersTh>{t('dashboard.Users.points')}</Styled.UsersTh>
                <Styled.UsersTh>{t('dashboard.Users.pointsOnline')}</Styled.UsersTh>
                <Styled.UsersTh>{t('dashboard.Users.pointsSpeedDatings')}</Styled.UsersTh>
                <Styled.UsersTh>{t('dashboard.Users.pointsMentorship')}</Styled.UsersTh>
                <Styled.UsersTh>{t('dashboard.Users.matches')}</Styled.UsersTh>
                <Styled.UsersTh>{t('dashboard.Users.positive')}</Styled.UsersTh>
            </Styled.UsersTr>
        </Styled.UsersThead>

        <Styled.UsersTbody>
            {users.map(user => {
                // const feedbacksPositive =
                //     (user.cxiStats?.metrics?.stage1?.feedbacksPositive ?? 0) +
                //     (user.cxiStats?.metrics?.stage2?.feedbacksPositive ?? 0) +
                //     (user.cxiStats?.metrics?.stage3?.feedbacksPositive ?? 0);
                return (
                    <tr key={user._id}>
                        <td>
                            <UsersUser user={user} />
                        </td>
                        <td>{user.rank ? `${user.rank}º` : 'N/A'}</td>
                        <td>
                            {(user.cxiStats.currentBelt === 'white' ||
                                !user.cxiStats.currentBelt) && (
                                <img src={whiteBelt} alt="White Belt" style={{ width: '40px' }} />
                            )}
                            {user.cxiStats.currentBelt === 'green' && (
                                <img src={greenBelt} alt="Green Belt" style={{ width: '40px' }} />
                            )}
                            {user.cxiStats.currentBelt === 'brown' && (
                                <img src={brownBelt} alt="Brown Belt" style={{ width: '40px' }} />
                            )}
                            {user.cxiStats.currentBelt === 'black' && (
                                <img src={blackBelt} alt="Black Belt" style={{ width: '40px' }} />
                            )}
                        </td>
                        <td>{user.cxiStats?.metrics?.points ?? 0}</td>
                        <td>{user.cxiStats?.metrics?.stage1?.feedbacksPositive ?? 0}</td>
                        <td>{user.cxiStats?.metrics?.stage2?.feedbacksPositive ?? 0}</td>
                        <td>{user.cxiStats?.metrics?.stage3?.feedbacksPositive ?? 0}</td>
                        <td>{user.cxiStats?.metrics?.matches ?? 0}</td>
                        <td>{user.cxiStats?.metrics?.positive ?? 0}</td>
                    </tr>
                );
            })}
        </Styled.UsersTbody>
    </Styled.Users>
);
