import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from './container';
import * as Styled from './StyledCXIModal';

export const CXIModalError = () => {
    const { t, modal, setModal } = useCXI();
    const { modal: name } = modal;

    console.warn('Tried to call non-existing modal', name);

    return (
        <Container.Slim>
            <Container.Body>
                <Styled.CXIModalErrorTitle>
                    {t('modal.CXIModalError.title')}
                </Styled.CXIModalErrorTitle>
                <Styled.CXIModalErrorDescr>
                    {t('modal.CXIModalError.description')}
                </Styled.CXIModalErrorDescr>
            </Container.Body>

            <Container.Feet>
                <Button variant="link" onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Container.Slim>
    );
};
