import styled from 'styled-components';

export const MentoringRequested = styled.div`
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.yellowDarker};
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 0 3.2rem;
    padding: 1.6rem;

    @media (min-width: 768px) {
        padding: 3.2rem;
    }

    p {
        color: ${({ theme }) => theme.colors.neutralDark};
        margin: 0;
    }

    .title {
        color: ${({ theme }) => theme.colors.yellowDarker};
        font-size: 1.8rem;
        font-weight: 700;
    }

    table > thead {
        display: none;
    }
    .cxi-c-button {
        margin: 1.2rem 0 0 auto;
    }
`;

export const MentoringRequestedList = styled.div``;
