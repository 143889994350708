import React from 'react';
import { useClickAway } from 'react-use';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionChevron = ({ children, match }) => {
    const { setModal } = useCXI();

    const [isOpen, setOpen] = React.useState(false);
    const dropdownRef = React.useRef(null);

    const toggleOpen = () => {
        setOpen(o => !o);
    };
    const closeAndAction = action => {
        setOpen(false);
        action();
    };

    useClickAway(dropdownRef, () => {
        setOpen(false);
    });

    const icon = isOpen ? 'up' : 'down';

    return (
        <Styled.MatchActionDropdown ref={dropdownRef}>
            <Styled.MatchAction className="icon-only action chevron" onClick={toggleOpen}>
                <span className={`icon fas fa-fw fa-chevron-${icon}`} aria-hidden="true" />
            </Styled.MatchAction>

            <Styled.MatchActionDropdownList isOpen={isOpen}>
                {children}
            </Styled.MatchActionDropdownList>
        </Styled.MatchActionDropdown>
    );
};
