import styled, { css } from 'styled-components';

export const ForumCard = styled.div`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease;

    .logo {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 1.6rem;
    }
    .forum-etapa-100 {
        ${({ theme }) => css`
            background: ${theme.colors.stage1};
        `}
    }
    .forum-etapa-10 {
        ${({ theme }) => css`
            background: ${theme.colors.stage2};
        `}
    }
    .forum-etapa-1 {
        ${({ theme }) => css`
            background: ${theme.colors.stage3};
        `}
    }
`;
export const ForumCardHeadWrapper = styled.div`
    display: flex;
    margin: 2rem auto;
    flex-direction: row;
    gap: 0;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    .colorizedLine {
        display: flex;
        height: 5px;
        width: 100%;
        position: absolute;
    }
    .forum-etapa-100 & .colorizedLine {
        ${({ theme }) => css`
            background: ${theme.colors.stage1};
        `}
    }
    .forum-etapa-10 & .colorizedLine {
        ${({ theme }) => css`
            background: ${theme.colors.stage2};
        `}
    }
    .forum-etapa-1 & .colorizedLine {
        ${({ theme }) => css`
            background: ${theme.colors.stage3};
        `}
    }
`;

export const ForumCardHead = styled.div`
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.white};
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.2rem;
    padding: 0.8rem 1.2rem;
    z-index: 10;
    border-radius: 4.04571px;

    .stage {
        font-weight: 700;
    }

    .forum-etapa-100 & .buttn {
        ${({ theme }) => css`
            background: ${theme.colors.stage1};
        `}
    }
    .forum-etapa-10 & .buttn {
        ${({ theme }) => css`
            background: ${theme.colors.stage2};
        `}
    }
    .forum-etapa-1 & .buttn {
        ${({ theme }) => css`
            background: ${theme.colors.stage3};
        `}
    }
`;

export const ForumCardBody = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    padding: 1.6rem;

    h4 {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.3;
    }

    .forum-etapa-100 & h4 {
        ${({ theme }) => css`
            color: ${theme.colors.stage1};
        `}
    }
    .forum-etapa-10 & h4 {
        ${({ theme }) => css`
            color: ${theme.colors.stage2};
        `}
    }
    .forum-etapa-1 & h4 {
        ${({ theme }) => css`
            color: ${theme.colors.stage3};
        `}
    }

    .descr {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.4;
        margin: 0.8rem 0 0;
    }

    .times {
        font-size: 1.4rem;
        font-style: italic;
        margin: 0.8rem 0 0;
    }
`;

export const ForumCardFeet = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    gap: 1.6rem;
    padding: 0 1.6rem 1.6rem;

    .buttn {
        align-items: center;
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid #4598ab;
        border-radius: 0.4rem;
        color: #4598ab;
        display: flex;
        padding: 0.8rem 1.6rem;
        font-weight: 600;
        font-size: 1.2rem;
        justify-content: center;
        margin: auto 0 0;
        width: 100%;

        &:focus,
        &:hover {
            background: #4598ab;
            border-color: #4598ab;
            color: white;
        }
    }

    .forum-etapa-100 & .buttn {
        ${({ theme }) => css`
            border-color: ${theme.colors.stage1};
            color: ${theme.colors.stage1};
            &:focus,
            &:hover {
                background: ${theme.colors.stage1};
                border-color: ${theme.colors.stage1};
                color: white;
            }
        `}
    }
    .forum-etapa-10 & .buttn {
        ${({ theme }) => css`
            border-color: ${theme.colors.stage2};
            color: ${theme.colors.stage2};
            &:focus,
            &:hover {
                background: ${theme.colors.stage2};
                border-color: ${theme.colors.stage2};
                color: white;
            }
        `}
    }
    .forum-etapa-1 & .buttn {
        ${({ theme }) => css`
            border-color: ${theme.colors.stage3};
            color: ${theme.colors.stage3};
            &:focus,
            &:hover {
                background: ${theme.colors.stage3};
                border-color: ${theme.colors.stage3};
                color: white;
            }
        `}
    }
`;
