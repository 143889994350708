import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledMentoringSessionSent';

export const MentoringSessionSent = () => {
    const { t, modal, setModal } = useCXI();
    const { match } = modal;

    return (
        <Styled.MentoringSessionSent as={Container.Slim}>
            <Container.Head image={match.startup.logoUrl} />

            <Container.Body>
                <Styled.Title>{t('modal.MentoringSessionSent.title')}</Styled.Title>
                <Styled.Descr>{t('modal.MentoringSessionSent.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={() => setModal({ match, modal: 'MentoringSession' })}>
                    <span className="text">{t('modal.MentoringSessionSent.contribution')}</span>
                </Button>
                <Button variant="link" onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.MentoringSessionSent>
    );
};
