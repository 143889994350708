import React from 'react';

import { UrltoYoutubeEmbed } from 'utils/youtube';

import { Area } from '../components/area/Area';
import { Tags } from '../components/tags/Tags';
import { useCXIDashboardStartup } from '../useCXIDashboardStartup';

export const CXIDashboardStartupGeneralPresentation = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    const video = UrltoYoutubeEmbed(startup.video);

    return (
        <Area className="presentation" title={t('startup.presentation')}>
            <p>{startup.description}</p>

            {!!startup.tags?.length && <Tags tags={startup.tags} />}

            {!!video && (
                <div className="presentation-video">
                    <iframe src={video} title={startup.name} />
                </div>
            )}
        </Area>
    );
};
