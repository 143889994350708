import React from 'react';

import * as Styled from './StyledFormControl';

export function FormControlTopLabel(props) {
    // Don't show this element if it is empty
    return !props.text && props.text !== '' ? null : (
        <Styled.FormControlTopLabel id={props.id} htmlFor={props.htmlFor}>
            <span className="text" dangerouslySetInnerHTML={{ __html: props.text }} />
        </Styled.FormControlTopLabel>
    );
}

export const TopLabel = FormControlTopLabel;
export default FormControlTopLabel;
