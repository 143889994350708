import React, { useEffect, useState } from 'react';
import * as Form from 'components/form';
import * as Page from 'components/page';
import * as Alert from 'components/alert';

// import * as Modal from 'components/modal';
// import CorporateInvestmentAlertModal from 'modules/ranking/components/modal/CorporateInvestmentAlertModal';

export function StartupStep6AddForm(props) {
    const { conv, form, onSubmit } = props;
    const { vars } = props.useRankingStartup;
    const { startupDetails } = vars;

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [renderWarning, setRenderWarning] = useState(false);
    const [isCorporateInvestmentSelected, setIsCorporateInvestmentSelected] = useState(false);
    const [currencyType, setCurrencyType] = useState('');

    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [doNotShowAlertAgain, setDoNotShowAlertAgain] = useState(false);

    const handleInvestmentValue = value => {
        if (isCorporateInvestmentSelected) {
            return { currency: currencyType, amount: value.amount || '' };
        }
        return { currency: value.currency, amount: value.amount || '' };
    };

    // Create form fields object
    const fields = {
        investorName: {
            error: form.errors.investorName,
            label: props.t('investorName'),
            name: 'investorName',
            type: 'text',
            value: form.values.investorName,
            onChange: form.handleChange
        },
        investorEmail: {
            error: form.errors.investorEmail,
            label: props.t('investorEmail'),
            name: 'investorEmail',
            type: 'email',
            value: form.values.investorEmail,
            onChange: form.handleChange
        },
        investorConfirmationEmail: {
            error: form.errors.investorEmail,
            label: props.t('investorConfirmationEmail'),
            name: 'investorConfirmationEmail',
            type: 'email',
            value: form.values.investorConfirmationEmail,
            onChange: form.handleChange
        },
        companyName: {
            error: form.errors.companyName,
            label: props.t('StartupStep6.investorCompany'),
            name: 'companyName',
            required: false,
            type: 'generic',
            value: form.values.companyName,
            onChange: form.handleChange
        },
        type: {
            error: form.errors.type,
            label: props.t('glossary:investmentType'),
            name: 'type',
            options: [
                { name: props.t('glossary:investmentTypeSelect'), value: '' },
                ...conv.investmentType
            ],
            type: 'select',
            value: form.values.type,
            onChange: form.handleChange
        },
        investmentValue: {
            error: form.errors.investmentValue,
            label: props.t('glossary:investmentValue'),
            name: 'investmentValue',
            required: startupDetails.investmentProfile.applied,
            type: 'CurrencyAmount',
            value: handleInvestmentValue(form.values.investmentValue),
            onChange: form.handleChange,
            lockSelectCurrency: isCorporateInvestmentSelected
        },
        date: {
            displayFormat: 'MM/YYYY',
            error: form.errors.date,
            label: props.t('glossary:date'),
            name: 'date',
            type: 'date',
            value: form.values.date,
            onChange: form.handleChange
        },
        round: {
            error: form.errors.round,
            label: props.t('glossary:round'),
            name: 'round',
            type: 'generic',
            value: form.values.round,
            onChange: form.handleChange
        },
        rankingIndication: {
            error: form.errors.rankingIndication,
            label: props.t('glossary:rankingIndication'),
            name: 'rankingIndication',
            type: 'checkbox',
            required: false,
            value: form.values.rankingIndication,
            onChange: form.handleChange,
            onlyIf: startupDetails.investmentProfile.applied
        }
    };

    // Checks if email and email confirmation fields value are the same
    const investorEmail = fields.investorEmail.value;
    const investorConfirmationEmail = fields.investorConfirmationEmail.value;

    useEffect(() => {
        setIsEmailValid(investorConfirmationEmail === investorEmail);
    }, [isEmailValid, investorEmail, investorConfirmationEmail]);

    const propsAlert = {
        descr: props.t('glossary:emailConfirmationError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };

    // Check if investment type is "corporate" and set the state
    useEffect(() => {
        if (fields.type.value === 'Corporate') {
            setIsCorporateInvestmentSelected(true);
            setCurrencyType('USD');
        } else {
            setIsCorporateInvestmentSelected(false);
        }
    }, [fields.type]);

    // if "corporate field is selected at input, renders modal"
    // useEffect(() => {
    //     if (isCorporateInvestmentSelected) {
    //         setIsModalOpen(true);
    //     }
    // }, [isCorporateInvestmentSelected]);

    // render
    // Handle form submition
    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();

        if (!isEmailValid) {
            setRenderWarning(true);
            return false;
        }
        return form.handleSubmit(ev, onSubmit);
    };

    // const modalProps = {
    //     // incluir outras linguagens
    //     text: `${props.t('ranking:CorporateStep2Add.corporateSelectedModal', {
    //         rankingYear: ranking.year,
    //     })}`,
    //     doNotShowAnymore: `${props.t('CorporateStep2Add.doNotShowAnymore', {
    //         rankingYear: ranking.year,
    //     })}`,
    //     handleReject: () => {
    //         props.RegisterInvestmentAsContract(false);
    //         setIsModalOpen(false);
    //     },
    //     handleAccept: () => {
    //         props.RegisterInvestmentAsContract(true);
    //         setIsModalOpen(false);
    //         // se true, adicionar na API o investimento como contrato para o Ranking

    //         // Se responder sim, deve enviar no POST de /startups/:startupid/contracts o mesmo investimento em formato de contrato, com o tipo investimento.
    //         // Seguir estrutura da pagina de cadastrar contrato (precisa pegar companyId do usuario antes para checar se é corporate mesmo)
    //         // post investment as a contract, implementar ou usar a função do useRankingCorporate importada aqui?
    //     },
    //     handleDoNotShowAnymore: () => {
    //         setDoNotShowAlertAgain(true);
    //         // SETAR NO FRONT PREFERENCIA DE USER PRA NÃO MOSTRAR MAIS O MODAL E CONSIDERAR SEMPRE OS CONTRATOS PARA O RANKING

    //         // implementar function que vai dar adicionar no backend a preferencia do usuario em TODOS os contratos serem contabilizados para o Ranking
    //     },
    // };

    return (
        <Form.Form name="StartupStep6Add" onSubmit={evSubmit}>
            <Form.Field {...fields.investorName} />
            <Form.Field {...fields.investorEmail} />
            <Form.Field {...fields.investorConfirmationEmail} />
            {renderWarning && <Alert.AlertRow {...propsAlert} />}
            <Page.Ruler />
            <Form.Field {...fields.companyName} />
            <Form.Field {...fields.type} />
            <Form.Field {...fields.investmentValue} />
            <Form.Row>
                <Form.Field size="4" {...fields.date} />
                <Form.Field size="8" {...fields.round} />
            </Form.Row>
            <Form.Field size="8" {...fields.rankingIndication} />
            <Form.Submt {...form} text={props.t('glossary:continue')} />
            {/* <Modal.Modal close={() => setIsModalOpen(false)} isOpen={isModalOpen}>
                <CorporateInvestmentAlertModal {...modalProps} />
            </Modal.Modal> */}
        </Form.Form>
    );
}
