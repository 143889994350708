import { useEffectOnce } from 'react-use';
import { useCXI } from 'modules/cxi/useCXI';
import { RHP } from 'providers';
import { getDateXDaysAgo } from 'utils/date';

export const Mentoring = () => {
    const { modal, setModal, vars } = useCXI();
    const { isMentoring, mentoring } = vars;
    const { match } = modal;

    // Check if there is a match sent less than 5 days ago
    const daysAgo = getDateXDaysAgo(5, new Date());
    const dateMatch = match.mentoringRequestedDate
        ? new Date(match.mentoringRequestedDate)
        : undefined;

    useEffectOnce(() => {
        if (isMentoring && mentoring.matchid === match.matchid) {
            RHP.push(`/100-10-1/mentoring/${match.matchid}`);
            return;
        }
        if (isMentoring) {
            console.log('Mentoring', 'MentoringNotAllowed');
            setModal({ match, modal: 'MentoringNotAllowed' });
            return;
        }
        if (dateMatch && dateMatch < daysAgo) {
            console.log('Mentoring', 'MentoringAwaiting');
            setModal({ match, modal: 'MentoringAwaiting' });
            return;
        }
        console.log('Mentoring', 'MentoringForm');
        setModal({ match, modal: 'MentoringForm' });
    }, [isMentoring]);

    return null;
};
