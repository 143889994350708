import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Match from 'modules/cxi/components/match';

import * as Styled from './StyledStartupMatch';

export const StartupMatch = ({ match }) => {
    const { t } = useCXI();

    return (
        <Styled.StartupMatch>
            <Styled.StartupMatchColumn>
                <Match.MatchEvaluation match={match} />
                <Styled.Term>{t('startup.StartupMatch.evaluation')}</Styled.Term>
            </Styled.StartupMatchColumn>

            <Styled.StartupMatchColumn>
                <Match.MatchFeedback match={match} />
                <Styled.Term>{t('startup.StartupMatch.feedback')}</Styled.Term>
            </Styled.StartupMatchColumn>

            <Styled.StartupMatchColumn>
                <Match.MatchSpeedDating match={match} />
                <Styled.Term>{t('startup.StartupMatch.speed-dating')}</Styled.Term>
            </Styled.StartupMatchColumn>

            <Styled.StartupMatchColumn>
                <Match.MatchMentoring match={match} />
                <Styled.Term>{t('startup.StartupMatch.mentoring')}</Styled.Term>
            </Styled.StartupMatchColumn>

            <Styled.StartupMatchColumn>
                <Match.MatchPoints match={match} />
                <Styled.Term>{t('startup.StartupMatch.points')}</Styled.Term>
            </Styled.StartupMatchColumn>
        </Styled.StartupMatch>
    );
};
