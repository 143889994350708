import styled from 'styled-components';

export const Context = styled.div``;

export const Infos = styled.span`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .name {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.3;
    }
`;
