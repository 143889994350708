import React from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Form from 'components/form';
import * as Page from 'components/page';

import { Ruler } from 'components/page/ruler/PageRuler';

import { RankingInterestCard } from '../components';

export function StartupStep4(props) {
    const { vars, putStartupRankingApplication } = props.useRankingStartup;
    const { startupDetails, ranking, application } = vars;

    // Initialize a new form handler
    const form = useForm({
        ...application,
        specialAwards: startupDetails.rankingData?.[ranking.id]?.specialAwards || {}
    });

    // Check for previous step conclusion
    if (!application.steps || !application.steps.step3) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            ...application,
            ...values
        };
        // Set the current step to true;
        if (!payload.steps) {
            payload.steps = {};
        }

        payload.steps.step4 = true;

        return putStartupRankingApplication(payload)
            .then(() => RHP.push('/ranking/latam/startup/step5'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Page.Page
            name="StartupStep4"
            back="/ranking/latam/startup"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Page.Title>{props.t('StartupStep4.title')}</Page.Title>
            <Page.Descr>{props.t('StartupStep4.descr', { rankingYear: ranking.year })}</Page.Descr>

            <Ruler />

            <Form.Form name="StartupStep4Form" onSubmit={evSubmit}>
                <RankingInterestCard
                    {...propsForm}
                    name="social"
                    title={props.t('RankingInterestCard.title.social')}
                    descr={props.t('RankingInterestCard.descr.social')}
                    reasonText={props.t('RankingInterestCard.reason.social')}
                    appliedText={props.t('RankingInterestCard.join')}
                />

                <RankingInterestCard
                    {...propsForm}
                    name="sustainability"
                    title={props.t('RankingInterestCard.title.sustainability')}
                    descr={props.t('RankingInterestCard.descr.sustainability')}
                    reasonText={props.t('RankingInterestCard.reason.sustainability')}
                    appliedText={props.t('RankingInterestCard.join')}
                />

                <RankingInterestCard
                    {...propsForm}
                    name="accessibility"
                    title={props.t('RankingInterestCard.title.accessibility')}
                    descr={props.t('RankingInterestCard.descr.accessibility')}
                    reasonText={props.t('RankingInterestCard.reason.accessibility')}
                    appliedText={props.t('RankingInterestCard.join')}
                />

                <RankingInterestCard
                    {...propsForm}
                    name="governance"
                    title={props.t('RankingInterestCard.title.governance')}
                    descr={props.t('RankingInterestCard.descr.governance')}
                    reasonText={props.t('RankingInterestCard.reason.governance')}
                    appliedText={props.t('RankingInterestCard.join2')}
                />

                <RankingInterestCard
                    {...propsForm}
                    name="black"
                    title={props.t('RankingInterestCard.title.black')}
                    descr={props.t('RankingInterestCard.descr.black')}
                    reasonText={props.t('RankingInterestCard.reason.black')}
                    appliedText={props.t('RankingInterestCard.join2')}
                />

                <RankingInterestCard
                    {...propsForm}
                    name="women"
                    title={props.t('RankingInterestCard.title.women')}
                    descr={props.t('RankingInterestCard.descr.women')}
                    reasonText={props.t('RankingInterestCard.reason.women')}
                    appliedText={props.t('RankingInterestCard.join2')}
                />

                <RankingInterestCard
                    {...propsForm}
                    name="senior"
                    title={props.t('RankingInterestCard.title.senior')}
                    descr={props.t('RankingInterestCard.descr.senior')}
                    reasonText={props.t('RankingInterestCard.reason.senior')}
                    appliedText={props.t('RankingInterestCard.join2')}
                />

                <RankingInterestCard
                    {...propsForm}
                    name="lgbt"
                    title={props.t('RankingInterestCard.title.lgbt')}
                    descr={props.t('RankingInterestCard.descr.lgbt')}
                    reasonText={props.t('RankingInterestCard.reason.lgbt')}
                    appliedText={props.t('RankingInterestCard.join2')}
                />

                <div style={{ display: 'flex', gap: '10px' }}>
                    <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/startup/step3">
                        <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                        <span className="text" style={{ margin: '0 0 0 auto' }}>
                            {props.t('glossary:back')}
                        </span>
                    </Page.Buttn>

                    <div style={{ width: '80%' }}>
                        <Form.Submt paddingTop="10rem" {...form} text={props.t('glossary:save')} />
                    </div>
                </div>
            </Form.Form>
        </Page.Page>
    );
}
