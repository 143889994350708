import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterContactRoutes } from './RegisterContactRoutes';
import { useRegisterContact } from './useRegisterContact';
import * as Styled from './StyledRegisterContact';

export function RegisterContact(props) {
    const { t } = useTranslation('startup');
    const hookd = useRegisterContact();
    return !hookd.ready ? null : (
        <Styled.RegisterContactModule>
            <RegisterContactRoutes t={t} useRegisterContact={hookd} />
        </Styled.RegisterContactModule>
    );
}
export default RegisterContact;
