import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledCertificateRequested';

export const CertificateRequested = () => {
    const { t, setModal } = useCXI();

    return (
        <Styled.CertificateRequested as={Container.Slim}>
            <Container.Head>
                <Styled.Icons>
                    <span className="icon fal fa-award" aria-hidden="true" />
                </Styled.Icons>
            </Container.Head>

            <Container.Body>
                <Styled.Title>{t('modal.CertificateRequested.title')}</Styled.Title>
                <Styled.Descr>{t('modal.CertificateRequested.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.CertificateRequested>
    );
};
