import Moment from 'moment';
import { useConversionTable } from 'hooks';

export const useParseDate = ({ startDate = '', endDate = '' }) => {
    const conv = useConversionTable(['months']);
    const initial = Moment(startDate).utc();
    const end = Moment(endDate).utc();

    const buildDate = date => {
        const dd = String(date.get('date')).padStart(2, '0');
        const mm = String(conv.months[date.get('month') + 1]).substring(0, 3);
        const yyyy = String(date.get('year'));

        return {
            dd,
            mm,
            yyyy,
            ddmm: `${dd} ${mm}`,
            fullDate: `${dd} ${mm} ${yyyy}`,
        };
    };

    const formattedDate = {
        formattedStartDate: initial && buildDate(initial),
        formattedEndDate: end && buildDate(end),
    };

    let intervalWithMonth = `${formattedDate.formattedStartDate.ddmm}`;
    if (endDate) {
        intervalWithMonth += ` - ${formattedDate.formattedEndDate.ddmm}`;
    }

    const intervalWithYear = `${intervalWithMonth} ${formattedDate.formattedEndDate.yyyy}`;

    return {
        formattedDate,
        intervalWithMonth,
        intervalWithYear,
    };
};

export default useParseDate;
