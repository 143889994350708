import React from 'react';
import { AboutUser } from 'modules/ranking/ranking-latam/_investor/components/user/AboutUser';
import logoRanking from 'assets/images/logo-ranking-2022.svg';

import * as List from 'components/list';
import * as Page from 'components/page';

import { CG } from 'contexts/ContextGlobal';
import { NextStep } from './components/NextStep';

// MUDAR PARA LATAM?
const country = {
    pt: 'Brasil',
    en: 'Brazil',
    es: 'Brasil',
};

export const InvestorIndexView = props => {
    const { state } = React.useContext(CG);
    return (
        <Page.Page
            name="InvestorIndex"
            back="/ranking/latam"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Page.Image>
                <img src={logoRanking} alt="Logo Ranking 100 Open Startups" width="40%" />
            </Page.Image>

            <Page.Title>
                {props.t('InvestorIndex.title', { country: country[state.language] })}
            </Page.Title>
            <Page.Descr>{props.t('InvestorIndex.descr')}</Page.Descr>

            <List.List name="InvestorIndexSteps">
                <List.Steps items={props.steps} itemKey="step" />
            </List.List>

            <NextStep {...props} />

            <Page.Ruler type="dots" />
           
            <AboutUser /> 

            <List.List name="InvestorIndexActions">
                <List.Vertical items={props.actions} itemKey="id" />
            </List.List>
        </Page.Page>
    );
};
export default InvestorIndexView;
