import React, { createContext } from 'react';

import { useAuthContext } from 'hooks/useAuthContext';
import { RHP } from 'providers';

import { useCXI } from '../useCXI';

export const CXIDashboardContext = createContext();
export const CXIDashboardContextProvider = ({ companyId, children }) => {
    const { hasScope } = useAuthContext();
    const { t } = useCXI();

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState({
        stages: {}
    });
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    React.useEffect(() => {
        if (!companyId || !hasScope('cxiAdmin')) {
            RHP.push('/');
        }
    }, [companyId]);

    const stateValue = { t, companyId, vars, addVars, setVars };
    return (
        <CXIDashboardContext.Provider value={stateValue}>{children}</CXIDashboardContext.Provider>
    );
};
