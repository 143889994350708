import styled from 'styled-components';
import { positions } from 'utils/styles';

export const AppTemplate = styled.div.attrs(props => ({
    className: `app-template at-${props.name} background`,
}))`
    position: fixed;
    ${positions(0, 0, 0, 0)}

    &.at-AppTemplateError,
    &.at-AppTemplateMustUpdate {
        background: #444;

        &::before {
            filter: grayscale(1);
        }
        .app-template-icons .icon {
            color: var(--ion-color-danger);
        }
    }

    &.at-AppTemplateLogin .container {
        align-items: center;
        justify-content: center;
    }
`;

export const AppTemplateApp = styled.div.attrs(props => ({
    className: `app-template-app`,
}))``;
export const AppTemplateContent = styled.div.attrs(props => ({
    className: `app-template-content`,
}))``;

export const Container = styled.div.attrs({
    className: 'app-template-container container',
})`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    max-width: 40rem !important;
    padding: 3rem;
    padding-top: calc(3rem + var(--ion-safe-area-top));
    padding-bottom: calc(3rem + var(--ion-safe-area-bottom));
`;

// ELEMENTS TO USE
// ----------------------------------------------

export const Buttn = styled.button.attrs(props => {
    const disabled = props.disabled ? 'disabled' : '';
    return {
        className: `app-template-buttn btn btn-block btn-light ${disabled}`,
        disabled: !!disabled,
    };
})`
    margin-top: 3rem;
`;
export const Descr = styled.div.attrs({
    className: 'app-template-descr',
})`
    font-size: 1.6rem;
    line-height: 1.4;
`;
export const Icons = styled.div.attrs({
    className: 'app-template-icons',
})`
    font-size: 4em;
    line-height: 1;
    margin-bottom: 2rem;

    .icon + .icon {
        margin-left: 2rem;
    }
`;
export const Links = styled.a.attrs({
    className: 'app-template-links btn btn-link btn-primary',
})`
    margin-top: 2rem;
`;
export const Title = styled.div.attrs({
    classname: 'app-template-title',
})`
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 1rem;
`;
