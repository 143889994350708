import React, { useContext } from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useUser } from 'hooks';
import { RHP } from 'providers';

import { useForm } from 'components/form';
import * as Page from 'components/page';

import { EcosystemStep4AddForm } from './EcosystemStep4AddForm';

export function EcosystemStep4Add(props) {
    const { emitAlert, state } = useContext(CG);

    const { language } = state;

    const {
        vars,
        postCorpEcosystemRelationship,
        getProgramsByCompanyId,
        postProgramByCompanyId
    } = props.useRankingEcosystem;

    const { ranking } = vars;

    const user = useUser();

    const currDate = new Date();

    // Initialize a new form handler
    const form = useForm({
        year: currDate.getFullYear(),
        otherContact: false,
        ecossystemCompanyName: user?.company?.id ? user?.company?.name : undefined
    });

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step1 !== true) {
        RHP.replace('/ranking/ecosystem');
        return null;
    }

    // Handle form submitions
    const onSubmit = async values => {
        try {
            let companyPrograms = [];
            let program = null;

            if (user?.company?.id) {
                companyPrograms = await getProgramsByCompanyId(user?.company?.id);

                program = companyPrograms?.find(e => e?._id === values.programName.value);

                if (values.programName.__isNew__ && !values?.companyName) {
                    program = await postProgramByCompanyId(
                        values.programName.label,
                        user?.company?.id
                    );
                }
            }

            delete program?.regDate;
            delete program?.companies;

            const payload = {
                year: values.year,
                programName: values.programName.label,
                description: values.description,
                linkUrl: values.linkUrl,
                ranking,
                entity: 'ecosystem',
                company: {
                    name: values.companyName || user?.company?.name,
                    contact: {
                        name: values.companyContactName,
                        email: values.companyContactEmail
                    }
                }
            };

            if (program) {
                payload.program = program;
            }

            if (!user?.company?.id) {
                payload.ecosystem = { name: values.ecosystemCompanyName };
            }

            // Add relationship
            await postCorpEcosystemRelationship(payload);

            RHP.push('/ranking/latam/ecosystem/step4');
        } catch (err) {
            emitAlert({
                message: `Ocorreu um erro inesperado, tente novamente mais tarde. Erro: ${err.message}`,
                header: 'Ops...'
            });
        } finally {
            form.submited();
        }
    };

    const propsForm = { ...props, form, onSubmit, language };

    return (
        <Page.Page name="EcosystemStep4Add">
            <Page.Title>{props.t('EcosystemStep3.title')}</Page.Title>
            <Page.Descr>{props.t('EcosystemStep3.descr')}</Page.Descr>
            <EcosystemStep4AddForm {...propsForm} />
        </Page.Page>
    );
}

export default EcosystemStep4Add;
