import React from 'react';

import * as Page from 'components/page';

import { OiweekBack } from '../components';

export const OiweekSuccessSDPaid = props => {
    const { vars } = props.useOiweek;
    const { oiweek } = vars;
    return (
        <Page.Page name="OiweekSuccessSDPaid">
            <Page.Title>{props.t('OiweekSuccess.title')}</Page.Title>
            <Page.Descr>{props.t('OiweekSuccess.descr-SD-paid')}</Page.Descr>

            <OiweekBack oiweek={oiweek} />
        </Page.Page>
    );
};
export default OiweekSuccessSDPaid;
