import React from 'react';

import * as Page from 'components/page';
import * as Form from 'components/form';

import { isValidCNPJ } from 'utils/validation';

export const BasicDataSection = props => {
    const { form } = props;

    // Handle blur for CPF/CNPJ inputs
    const onBlur = {
        isValidCNPJ: ev => {
            const { name, value } = ev.target;
            // Clear the errors and warnings before validating
            form.clearMessages({ name });
            // Only check if the value is filled
            if (!value) return false;
            // Check if the CPF is a valid one
            const cleanValue = value.replace(/[^0-9]/g, '');
            if (!isValidCNPJ(cleanValue)) {
                // Emit error message for registered email
                const error = props.t('BasicDataSection.notValidCNPJ');
                return form.emitError({ name, error });
            }
            return true;
        },
    };

    const fields = {
        startupName: {
            error: form.errors.startupName,
            label: props.t('glossary:startupName'),
            name: 'startupName',
            type: 'text',
            required: true,
            value: form.values.startupName,
            onChange: form.handleChange,
        },
        wasNameChanged: {
            error: form.errors.wasNameChanged,
            label: props.t('BasicDataSection.wasNameChanged'),
            name: 'wasNameChanged',
            type: 'checkbox',
            required: false,
            value: form.values.wasNameChanged,
            onChange: form.handleChange,
        },
        startupNameObs: {
            error: form.errors.startupNameObs,
            label: props.t('glossary:observations'),
            placeholder: 'Explique aqui a mudança no nome da sua startup.',
            name: 'startupNameObs',
            type: 'textarea',
            onlyIf: form.values.wasNameChanged,
            value: form.values.startupNameObs,
            onChange: form.handleChange,
        },
        location: {
            error: form.errors.location,
            label: props.t('glossary:location'),
            name: 'location',
            type: 'Location',
            value: form.values.location,
            onChange: form.handleChange,
        },
        cnpj: {
            error: form.errors.cnpj,
            topLabel: '',
            label: props.t('glossary:cnpj'),
            name: 'cnpj',
            type: 'text',
            maxLength: 18,
            required: !form.values.noCnpj,
            disabled: form.values.noCnpj,
            displayFormat: '00.000.000/0000-00',
            value: form.values.cnpj,
            onChange: form.handleChange,
            onBlur: ev => form.handleBlur(ev, onBlur.isValidCNPJ),
        },
        foundingYear: {
            error: form.errors.foundingYear,
            label: props.t('BasicDataSection.foundingYear'),
            name: 'foundingYear',
            type: 'date',
            displayFormat: 'YYYY',
            value: form.values.foundingYear,
            onChange: form.handleChange,
        },
        noCnpj: {
            error: form.errors.noCnpj,
            label: props.t('BasicDataSection.noCnpj'),
            name: 'noCnpj',
            type: 'checkbox',
            required: false,
            value: form.values.noCnpj,
            onChange: form.handleChange,
        },
    };
    return (
        <>
            <Page.Descr id="descr">{props.t('BasicDataSection.title')}</Page.Descr>
            <Form.Field {...fields.startupName} />
            <Form.Field {...fields.wasNameChanged} />
            <Form.Field {...fields.startupNameObs} />
            <Form.Field {...fields.location} />
            <Form.Row>
                <Form.Field size="6" {...fields.cnpj} />
                <Form.Field size="6" {...fields.foundingYear} />
            </Form.Row>
            <Form.Field {...fields.noCnpj} />
        </>
    );
};

export default BasicDataSection;
