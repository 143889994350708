import React from 'react';

import { UserAvatar } from 'core/components/user';
import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';
import { RHP } from 'providers';

import logoRanking from 'assets/images/logo-ranking.svg';

import { Progress } from '../progress';
import * as Styled from './StyledStartupCard';

export const StartupCard = ({ match }) => {
    const { t, putMatchById } = useCXI();
    const cardRef = React.useRef(null);

    const [disabled, setDisabled] = React.useState(false);

    const handleNot = async () => {
        setDisabled(true);
        await putMatchById(match.matchid, { active: false }).finally(() => setDisabled(false));
    };
    const handleYes = async () => {
        setDisabled(true);
        await putMatchById(match.matchid, { active: true }).finally(() => setDisabled(false));
        RHP.push(`/100-10-1/startup/${match.matchid}`);
    };

    const isRankingRecommendation = match.recommendedBy?.firstName === 'Ranking';
    const isRanking = isRankingRecommendation || !!match.card.ranking?.length;

    return (
        <Styled.StartupCard ref={cardRef}>
            <Styled.Image img={match.card.logoUrl} />

            <Styled.Title>{match.card.name}</Styled.Title>

            <Styled.Label>
                <span className="text">
                    {t('matches.StartupCard.level')}: {match.card.level}/5
                </span>
                <span className="ttip">
                    <span className="ttip-icon far fa-question-circle" aria-hidden="true" />
                    <span className="ttip-text">{t('matches.StartupCard.levelTooltip')}</span>
                </span>
            </Styled.Label>

            {isRanking && (
                <Styled.RecommendationRanking>
                    <span className="user-text">{t('matches.StartupCard.ranking')}</span>
                    <img src={logoRanking} alt="Logo Ranking Open Startups" />
                </Styled.RecommendationRanking>
            )}

            {!!match.recommendedBy?.userId && !isRankingRecommendation && (
                <Styled.Recommendation>
                    <UserAvatar user={{ ...match.recommendedBy }} />
                    <span className="user-information">
                        <span className="user-text">{t('matches.StartupCard.recommendedBy')}</span>
                        <span className="user-name">{`${match.recommendedBy.firstName} ${match.recommendedBy.lastName}`}</span>
                    </span>
                </Styled.Recommendation>
            )}

            <Styled.Descr>{match.card.description}</Styled.Descr>

            <Styled.IconWithText>
                <span className="icon far fa-map-marker-alt" aria-hidden="true" />
                <span className="text">{match.card.fullLocation || '-'}</span>
            </Styled.IconWithText>

            <Progress currentStage={match.card.stage} />

            <Styled.Buttons>
                <Button disabled={disabled} variant="outline" onClick={handleNot}>
                    {t('matches.StartupCard.reject')}
                </Button>
                <Button disabled={disabled} variant="default" onClick={handleYes}>
                    {t('matches.StartupCard.accept')}
                </Button>
            </Styled.Buttons>
        </Styled.StartupCard>
    );
};
