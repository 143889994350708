import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIG } from 'config';
import { useApi } from 'hooks';
import { useUser } from 'hooks/useUser';
import { RouterHistoryProvider as RHP } from 'providers';

import { useEffectOnce } from 'react-use';
import { PagesRoutes } from './PagesRoutes';

export function Pages(props) {
    const api = useApi();
    const { userid } = useUser();
    const { t } = useTranslation('pages');

    const [ready, setReady] = React.useState(false);

    useEffectOnce(() => {
        // Redirects for older hash routes in matchmaking app
        // Must keep this working for backwards compatibility
        if (
            window.location.hash.indexOf('#/share/') === 0 ||
            window.location.hash.indexOf('#/referral/') === 0
        ) {
            const buildURL = `${CONFIG.appLinks.matchmaking}/${window.location.hash}`;
            const redirURL = encodeURIComponent(buildURL);

            const getCXI = async () => {
                // Check if the user is a 100-10-1 user;
                return api.get(`/users/${userid}/100-10-1`);
            };
            const getRecommendation = async () => {
                // It the API with the refferal/share
                const hash = window.location.hash
                    .replace('#/share/', '')
                    .replace('#/referral/', '');
                return api.get(`/users/${userid}/recommendation/${hash}`);
            };

            Promise.all([getCXI(), getRecommendation()]).then(result => {
                const [cxi] = result;
                if (cxi.status !== 'none') {
                    // Redirect to the a new URL
                    RHP.replace(`/100-10-1/matches`);
                } else {
                    // Redirect to the a new URL
                    RHP.replace(`/iframe/${redirURL}`);
                }
            });
        }
        setReady(true);
    }, []);

    return !ready ? null : <PagesRoutes t={t} />;
}
export default Pages;
