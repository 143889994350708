import styled from 'styled-components';

export const Evaluations = styled.div`
    .c-header:not(.filter) {
        box-shadow: none;
        border-radius: 0;

        .c-header-head {
            background: none;
            padding: 0 0 1.6rem 0;
        }
    }
    .c-header.filter {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
    }
    .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
    }
    .c-header.filter + .c-header.filter {
        margin-top: 0.4rem;
    }
`;
export const EvaluationsList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Evaluation = styled.div`
    align-items: stretch;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 3.2rem 0;
`;
export const EvaluationInfo = styled.div`
    align-items: stretch;
    color: ${({ theme }) => theme.colors.black};
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 0.4rem;
    justify-content: space-between;

    .theme {
        color: ${({ theme }) => theme.colors.blue};
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
    }
    .question {
        font-size: 1.4rem;
        font-weight: 700;7
    }
`;
export const EvaluationText = styled.div`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.black};
    display: inline-flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    position: relative;
    word-break: break-word;

    @media (min-width: 992px) {
        text-align: left;
    }
`;
export const EvaluationFooter = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    .date {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.4rem;
        font-weight: 400;
    }

    .text {
        align-items: center;
        color: ${({ theme }) => theme.colors.neutralDark};
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        font-weight: 400;
        gap: 0.4rem;
    }
    .text .icon {
        color: ${({ theme }) => theme.colors.deepBlue};
    }
    .text strong {
        color: ${({ theme }) => theme.colors.deepBlue};
        font-weight: 600;
    }
`;
