import React from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';

export function ProfileCompanyCreateForm(props) {
    const { conv, form, onSubmit } = props;

    // Create form fields object
    const fields = {
        companyName: {
            error: form.errors.name,
            label: props.t('glossary:companyName'),
            name: 'name',
            type: 'text',
            value: form.values.name,
            onChange: form.handleChange,
        },
        companyWebsite: {
            error: form.errors.website,
            label: props.t('glossary:companyWebsite'),
            name: 'website',
            required: false,
            type: 'text',
            value: form.values.website,
            onChange: form.handleChange,
        },
        companySize: {
            error: form.errors.size,
            label: props.t('glossary:companySize'),
            name: 'size',
            options: [
                { name: props.t('glossary:companySizeSelect'), value: '' },
                ...conv.companySize,
            ],
            type: 'select',
            value: form.values.size,
            onChange: form.handleChange,
        },
        companyEmail: {
            disabled: !!form.values.noemail,
            error: form.errors.email,
            label: props.t('glossary:companyEmail'),
            name: 'email',
            helper: props.t('glossary:companyEmailHelper'),
            type: 'text',
            value: form.values.email,
            onChange: form.handleChange,
        },
        noEmail: {
            label: props.t('glossary:companySkipEmail'),
            name: 'noemail',
            type: 'checkbox',
            value: form.values.noemail,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="ProfileCompanyCreateForm" onSubmit={evSubmit}>
            <Form.Field {...fields.companyName} />
            <Form.Field {...fields.companyWebsite} />
            <Form.Field {...fields.companySize} />

            <Page.Ruler />
            <Page.Texts>{props.t('ProfileCompanyCreate.emailExplanation')}</Page.Texts>

            <Form.Field {...fields.companyEmail} />
            <Form.Field {...fields.noEmail} />

            <Form.Submt {...form} text={props.t('glossary:continue')} />
        </Form.Form>
    );
}
export default ProfileCompanyCreateForm;
