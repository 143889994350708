import React from 'react';
import Button from 'components/button';
import { Container } from '../container';
import * as Styled from './StyledMatchFromShare';
import { useStartupCXI } from '../../useStartupCXI';

export const MatchFromShare = () => {
    const { t, modal, setModal } = useStartupCXI();
    const { matchIdResponse } = modal;

    return (
        <Styled.StartupShare as={Container.Slim}>
            <Container.Head image={matchIdResponse?.evaluator?.photoUrl} />
            <Container.Head>
                <Styled.Title>{t('modal.MatchFromReferral.title')}</Styled.Title>
            </Container.Head>
            <Container.Body>
                <Styled.Descr>
                    {t('modal.MatchFromReferral.succeed', {
                        evaluator: matchIdResponse?.evaluator?.name,
                        company: matchIdResponse?.evaluator?.company
                    })}
                </Styled.Descr>
            </Container.Body>
            <Container.Feet>
                <Button onClick={() => setModal(null)}>{t('modal.close')}</Button>
            </Container.Feet>
        </Styled.StartupShare>
    );
};
