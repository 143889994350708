import React from 'react';
import { RoutesMenu } from './menu/RoutesMenu';

export const RoutesStructure = props => {
    const Component = props.then;
    return (
        <>
            <Component>{props.children}</Component>
            <RoutesMenu />
        </>
    );
};
export default RoutesStructure;
