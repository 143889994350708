import styled from 'styled-components';

export const ComponentAlert = styled.div.attrs(props => ({
    className: `component-alert alert alert-${props.type}`,
}))`
    border: 0;
    margin: 2rem 0 2rem 0;
    padding: 1.5rem;
    text-align: center;
`;

export const AlertBox = styled.div.attrs(props => ({
    className: `alertbox-alert alert alert-${props.type}`,
}))`
    color: ${props => props.color};
    background-color: ${props => props.bgColor};
    border: 0;
    margin: 2rem 1rem 2rem 0;
    padding: 1.5rem;
    text-align: left;
    align-items: center;
    justify-content: center;

    .icon {
        color: #ffffff;
        background-color: #45aaf2;
        font-size: 3rem;
        text-align: center;
        justify-content: center;
    }

    a {
        color: #45aaf2;
    }
`;
