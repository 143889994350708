import React from 'react';

import * as Form from 'components/form';

export function RegisterLocationForm(props) {
    const { form, onSubmit } = props;

    // Create form fields object
    const fields = {
        location: {
            error: form.errors.location,
            label: props.t('glossary:location'),
            name: 'location',
            type: 'Location',
            value: form.values.location,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="RegisterLocationForm" onSubmit={evSubmit}>
            <Form.Field {...fields.location} />
            <Form.Submt {...form} text={props.t('glossary:continue')} />
        </Form.Form>
    );
}
export default RegisterLocationForm;
