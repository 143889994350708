import React from 'react';

import { useStartupCXI } from '../../useStartupCXI';

import { Matches } from '../components/matches/Matches';
import * as Styled from '../components/StyledNetworkPage';

export const CXIMentoringAccepted = () => {
    const { t, vars } = useStartupCXI();
    const { matches } = vars;

    const mentories = matches.filter(m => m.mentoring && m.mentoring !== 'none');
    const filtered = mentories.filter(m => m.mentoring === 'accepted');

    return (
        <Styled.NetworkPage>
            <Styled.NetworkPageHead>
                <h2>{t('mentoring.accepted.title')}</h2>
                <h3>({mentories.length} mentorias)</h3>
            </Styled.NetworkPageHead>

            <Styled.NetworkPageBody>
                <Matches matches={filtered} t={t} />
            </Styled.NetworkPageBody>
        </Styled.NetworkPage>
    );
};
