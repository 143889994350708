import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Header } from 'modules/cxi/components/header/Header';

import { FilterTag } from '../components/filter-tag/FilterTag';
import { Matches } from '../components/matches/Matches';
import * as Styled from '../components/StyledNetworkPage';

export const CXINetworksEvaluated = () => {
    const { t, vars } = useCXI();
    const matches = vars.matches.filter(match => match.calculatedStage === 'evaluated');

    const [filterGroup, setFilterGroup] = React.useState([]);
    const [filterFeedback, setFilterFeedback] = React.useState('');

    const hasFilterGroup = value => !!filterGroup.includes(value);
    const hasFilterFeedback = value => filterFeedback === value;

    const updFilterGroup = value => {
        if (hasFilterGroup(value)) {
            return setFilterGroup(f => f.filter(i => i !== value));
        }
        return setFilterGroup(f => [...f, value]);
    };

    const updFilterFeedback = value => {
        if (hasFilterFeedback(value)) {
            return setFilterFeedback('');
        }
        return setFilterFeedback(value);
    };

    const filtered =
        filterGroup.length === 0 && !filterFeedback
            ? matches
            : matches.filter(match => {
                  if (filterGroup.includes(match.evaluation.group)) {
                      return true;
                  }
                  if (filterFeedback === match.evaluation?.startupFeedback) {
                      return true;
                  }
                  if (
                      filterFeedback === 'pending' &&
                      match.evaluation.group &&
                      !match.evaluation?.startupFeedback
                  ) {
                      return true;
                  }
                  return false;
              });

    return (
        <Styled.NetworkPage>
            <Styled.NetworkPageHead>
                <h2>{t('networks.evaluated.title')}</h2>
                <h3>({matches.length} Startups)</h3>
            </Styled.NetworkPageHead>

            <Header className="filter">
                {t('networks.filterGroup')}
                <FilterTag active={hasFilterGroup('A')} onClick={() => updFilterGroup('A')}>
                    <span className="text">A</span>
                </FilterTag>
                <FilterTag active={hasFilterGroup('B')} onClick={() => updFilterGroup('B')}>
                    <span className="text">B</span>
                </FilterTag>
                <FilterTag active={hasFilterGroup('C')} onClick={() => updFilterGroup('C')}>
                    <span className="text">C</span>
                </FilterTag>
                <FilterTag active={hasFilterGroup('D')} onClick={() => updFilterGroup('D')}>
                    <span className="text">D</span>
                </FilterTag>
            </Header>

            <Header className="filter">
                {t('networks.filterFeedback')}
                <FilterTag
                    active={hasFilterFeedback('positive')}
                    onClick={() => updFilterFeedback('positive')}
                >
                    <span className="text">{t('networks.evaluated.filterFeedback.positive')}</span>
                </FilterTag>
                <FilterTag
                    active={hasFilterFeedback('neutral')}
                    onClick={() => updFilterFeedback('neutral')}
                >
                    <span className="text">{t('networks.evaluated.filterFeedback.neutral')}</span>
                </FilterTag>
                <FilterTag
                    active={hasFilterFeedback('negative')}
                    onClick={() => updFilterFeedback('negative')}
                >
                    <span className="text">{t('networks.evaluated.filterFeedback.negative')}</span>
                </FilterTag>
                <FilterTag
                    active={hasFilterFeedback('pending')}
                    onClick={() => updFilterFeedback('pending')}
                >
                    <span className="text">{t('networks.evaluated.filterFeedback.pending')}</span>
                </FilterTag>
            </Header>

            <Styled.NetworkPageBody>
                <Matches matches={filtered} t={t} />
            </Styled.NetworkPageBody>
        </Styled.NetworkPage>
    );
};
