import React, { useEffect, useState } from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';
// import * as Alert from 'components/alert';

import CreatableSelect from 'react-select/creatable';
import { CorporationCard } from '../components';

import * as Styled from './styledEcosystemStep4';

export function EcosystemStep4EditForm({ form, onSubmit, relationship, t, useRankingEcosystem }) {
    const { getProgramsByCompanyId } = useRankingEcosystem;

    const [companyPrograms, setCompanyPrograms] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(false);

    useEffect(() => {
        if (relationship?.ecosystemId) {
            const getProgramsOptions = async () => {
                const allCompanyPrograms = await getProgramsByCompanyId(relationship?.ecosystemId);

                setCompanyPrograms(allCompanyPrograms);
            };

            getProgramsOptions();
        }
    }, []);

    // Create form fields object
    const fields = {
        companyContactName: {
            error: form.errors.companyContactName,
            label: t('companyContactName'),
            name: 'companyContactName',
            type: 'text',
            value: form.values.company.contact.name,
            onChange: form.handleChange,
            disabled: form.values.isConfirmed
        },
        companyContactEmail: {
            error: form.errors.ecosystemContactEmail,
            label: t('companyContactEmail'),
            name: 'companyContactEmail',
            type: 'email',
            value: form.values.company.contact.email,
            onChange: form.handleChange,
            disabled: form.values.isConfirmed
        },
        companyConfirmationEmail: {
            error: form.errors.companyConfirmationEmail,
            label: t('companyContactEmail2'),
            name: 'companyConfirmationEmail',
            type: 'email',
            value: form.values.company.contact.email,
            onChange: form.handleChange,
            disabled: form.values.isConfirmed
        },
        companyName: {
            error: form.errors.companyName,
            label: t('ecossystemCompanyName'),
            name: 'companyName',
            type: 'text',
            value: form.values.companyName,
            onChange: form.handleChange,
            disabled: form.values.isConfirmed
        },
        programName: {
            required: true,
            error: form.errors.programName,
            label: t('glossary:programName'),
            name: 'programName',
            type: 'generic',
            options: companyPrograms?.map(item => {
                return { label: item.name, value: item._id };
            }),
            value: form.values.programName,
            onChange: ev => form.setValues(prev => ({ ...prev, programName: ev }))
        },
        description: {
            error: form.errors.description,
            label: t('glossary:description'),
            name: 'description',
            required: false,
            type: 'textarea',
            value: form.values.description,
            onChange: form.handleChange
        },
        year: {
            displayFormat: 'YYYY',
            error: form.errors.year,
            label: t('glossary:year'),
            maxLength: 4,
            name: 'year',
            type: 'text',
            value: form.values.year,
            onChange: form.handleChange,
            disabled: form.values.isConfirmed
        },
        linkUrl: {
            error: form.errors.linkUrl,
            label: t('glossary:linkUrl'),
            name: 'linkUrl',
            type: 'text',
            required: false,
            value: form.values.linkUrl,
            onChange: form.handleChange
        }
    };

    const companyContactEmail = fields.companyContactEmail.value;
    const companyConfirmationEmail = fields.companyConfirmationEmail.value;

    useEffect(() => {
        setIsEmailValid(companyConfirmationEmail === companyContactEmail);
    }, [isEmailValid, companyContactEmail, companyConfirmationEmail]);

    // const propsAlert = {
    //     descr: t('glossary:emailConfirmationError'),
    //     icons: 'far fa-exclamation-circle',
    //     type: 'warning',
    //     color: 'red',
    // };

    // Handle form submission
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Form.Form name="EcosystemStep4Edit" onSubmit={evSubmit}>
            <Page.Boxed>
                <CorporationCard {...form.values} />
                <Page.Discl>{t('EcosystemStep3Edit.noChangeToCorporation')}</Page.Discl>
            </Page.Boxed>
            {/*
            <Form.Field {...fields.companyContactName} />

            <Form.Field {...fields.companyContactEmail} />

            <Form.Field {...fields.companyConfirmationEmail} /> */}

            {/* {renderWarning && <Alert.AlertRow {...propsAlert} />} */}

            <Page.Ruler />

            <Styled.ProgramLabel>Nome do programa</Styled.ProgramLabel>
            <CreatableSelect
                placeholder="Selecione ou digite um nome para criar"
                options={fields.programName.options}
                styles={{
                    control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: '#ddd'
                    }),
                    menu: baseStyles => ({
                        ...baseStyles,
                        zIndex: 91
                    })
                }}
                onChange={fields.programName.onChange}
                value={fields.programName.value}
            />

            <Form.Row>
                <Form.Field size="4" {...fields.year} />
                <Form.Field size="8" {...fields.linkUrl} />
            </Form.Row>
            <Form.Field {...fields.description} />

            <Form.Submt {...form} text={t('glossary:save')} />
        </Form.Form>
    );
}
export default EcosystemStep4EditForm;
