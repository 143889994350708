import React from 'react';
import axios from 'axios';

import { CONFIG } from 'config';
import { CA, CG } from 'contexts';
import { RHP } from 'providers';

let refreshing = false;

const pendingPromises = [];

export function useApi() {
    const { authState, dispatch } = React.useContext(CA);
    const { userid, token, refresh_token } = authState.auth;

    const [newAuth, setNewAuth] = React.useState();

    // Get the global context (it may be unavaliable)
    const globalContext = React.useContext(CG);

    // Create the api using axios
    const api = axios.create({ baseURL: CONFIG.apiUrl });

    // Make the api_key if the user is logged
    api.interceptors.request.use(config => {
        const { headers } = config;
        // Set language of request
        try {
            const { language } = globalContext.state;
            headers.common['Accept-Language'] = language;
        } catch (err) {
            // Do nothing
        }
        // Add the api_key to the header config
        if (!config.url?.includes('http') && token) {
            headers.Authorization = `Bearer ${token}`;
            // headers.api_key = headers.api_key || token || '';
        }

        return { ...config, headers };
    });
    api.interceptors.response.use(res => {
        return res.data;
    });

    function refreshToken(originalRequest) {
        // Before starting, check refreshing status
        if (refreshing) {
            pendingPromises.push(originalRequest);
            return new Promise(() => setTimeout(() => null, 1000));
        }

        // Create the api using axios
        async function handleThen(res) {
            await dispatch({ type: 'UPDATE_AUTH_INFO', auth: res });
            return res;
        }
        async function handleCatchRefresh(error) {
            await dispatch({ type: 'UNAUTHENTICATE' });
            return error;
        }
        function handleFinally(res) {
            refreshing = false;
            return res;
        }

        // If userid or refresh_token missing, reject promise;
        // console.log('useApiRefreshToken', !userid || !refresh_token);
        if (!userid || !refresh_token) {
            RHP.replace('/login');
            // eslint-disable-next-line no-console
            console.error('useApiRefreshToken', 'No Refresh Token Found...');
            dispatch({ type: 'UNAUTHENTICATE' });
            return Promise.reject();
        }

        // Set the refreshing and refreshPromise
        refreshing = true;
        return api
            .post('/refreshtoken', {
                userid,
                refresh_token: newAuth?.refresh_token ?? refresh_token,
            })
            .then(handleThen)
            .catch(handleCatchRefresh)
            .finally(handleFinally);
    }

    function handleCatch(err) {
        // Get the response with the error
        const { response } = err;

        // Error message handler function
        const errorMessage = () => {
            try {
                const { emitAlert } = globalContext;
                emitAlert(response?.data?.error?.message || err);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('useApi', 'handleCatch', e, err);
            }
            return Promise.reject(err);
        };

        // Check error type for unauthorized requests and tries to refresh the token
        // Only do this if it has a refresh_token avaliable
        if (response && response.status === 401 && response.config) {
            if (!refresh_token) {
                dispatch({ type: 'UNAUTHENTICATE' });
                // Regular error and message handling
                // return errorMessage(err);
                return Promise.reject(err);
            }

            const originalRequest = err.config;

            // Redo the originalRequest with the refreshed token
            // eslint-disable-next-line no-console
            console.log('useApi', 'originalRequest being remade', originalRequest.url);

            return refreshToken(originalRequest)
                .then(newAuthResponse => {
                    setNewAuth(newAuthResponse);
                    return newAuthResponse;
                })
                .catch(error => {
                    if (authState.authenticated && response.status === 401) {
                        return;
                    }
                    errorMessage(error);
                });
        }

        // Regular error and message handling
        return errorMessage(err);
    }

    function apiDelete(...props) {
        const [endpoint, headers] = props;
        return api.delete(endpoint, headers).catch(handleCatch);
    }

    function apiGet(...props) {
        const [endpoint, headers, configs] = props;
        return api.get(endpoint, headers).catch(err => {
            if (configs?.isErrorHandled) throw err;
            return handleCatch(err);
        });
    }

    function apiPatch(...props) {
        const [endpoint, payload, headers] = props;
        return api.patch(endpoint, payload, headers).catch(handleCatch);
    }

    function apiPost(...props) {
        const [endpoint, payload, headers] = props;
        return api.post(endpoint, payload, headers).catch(handleCatch);
    }

    function apiPut(...props) {
        const [endpoint, payload, headers] = props;
        return api.put(endpoint, payload, headers).catch(handleCatch);
    }

    React.useEffect(() => {
        if (!newAuth || pendingPromises.length === 0) {
            return () => null;
        }
        const mappedRequests = pendingPromises.map(req => {
            const newRequestwithnewAuth = req;
            newRequestwithnewAuth.headers.Authorization = `Bearer ${newAuth.token}`;
            newRequestwithnewAuth.headers.authorization = `Bearer ${newAuth.token}`;
            newRequestwithnewAuth.headers.api_key = `${newAuth.token}`;
            return newRequestwithnewAuth;
        });

        Promise.all(mappedRequests.map(req => api(req)));

        return () => null;
    }, [api, newAuth]);

    return {
        delete: apiDelete,
        get: apiGet,
        patch: apiPatch,
        post: apiPost,
        put: apiPut,
    };
}
export default useApi;
