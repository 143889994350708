import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionSpeedDating = ({ match }) => {
    const { t, setModal } = useCXI();
    return (
        <Styled.MatchAction
            className="s2 action"
            onClick={() => setModal({ match, modal: 'SpeedDating' })}
        >
            <span className="text">{t('match.action.speed-dating')}</span>
        </Styled.MatchAction>
    );
};
