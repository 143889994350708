import styled from 'styled-components';

export const SpeedDatingSent = styled.div`
    text-align: center;

    .m-container-feet {
        flex-direction: column;
        gap: 1.2rem;
    }
`;

export const Descr = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.8rem;
    margin-top: 0.8rem;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.2rem;
    font-weight: 700;
`;

export const Button = styled.button`
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    padding: 0;
`;
