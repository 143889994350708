import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const PageNavbar = styled.div.attrs({
    className: 'c-page-navbar',
})`
    padding-top: calc(1.5rem + var(--ion-safe-area-top));
    position: relative;
    z-index: 1000;
    & + .component-page-container {
        padding-top: 0;
    }
`;

export const PageNavbarContainer = styled.div.attrs({
    className: 'c-page-navbar-container container',
})`
    align-items: center;
    display: flex;
    height: 4.4rem;
    justify-content: flex-start;
`;

export const PageNavbarBack = styled.button.attrs({
    className: 'c-page-navbar-back',
})`
    align-items: center;
    background: transparent;
    border: 0;
    display: flex;
    color: var(--primary);
    flex: 0 0 4.4rem;
    width: fit-content;
    font-size: 2.4rem;
    height: 4.4rem;
    padding: 0;
    text-align: left;
    -webkit-appearance: none;
`;

export const ButtonText = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #0094f9;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
`;

export const PageNavbarText = styled.div.attrs({
    className: 'c-page-navbar-text',
})`
    color: var(--primary);
    flex: 0 0 calc(100% - 8.8rem);
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    ${truncate(1, 2.75)}
`;
