import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const Conversation = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    display: flex;
    gap: 1.6rem;
    text-align: left;
    width: 100%;

    &.active {
        .conversation-image {
            border-color: ${({ theme }) => theme.colors.deepBlue};
        }
    }
`;

export const ConversationImage = styled.div.attrs({
    className: 'conversation-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 2px solid #ddd;
    border-radius: 50%;
    flex: 0 0 6.8rem;
    height: 6.8rem;
    padding: 0.8rem;
    position: relative;
    width: 6.8rem;

    .badge {
        align-items: center;
        background: ${({ theme }) => theme.colors.red};
        border-radius: 9999px;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        font-size: 1rem;
        justify-content: center;
        line-height: 1;
        min-width: 1.65rem;
        padding: 0.4rem 0.6rem 0.25rem;
        position: absolute;
        ${positions('0', '0', 'auto', 'auto')}
    }
`;

export const ConversationTexts = styled.div`
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 0.4rem;
    justify-content: space-between;

    .startup {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.3;
        ${truncate(2, 1.3)}
    }
    .startup.multiline {
        ${truncate(2, 1.3)}
    }
    .message {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.3;
        ${truncate(1, 1.3)}
    }
`;

export const ConversationInfos = styled.div`
    align-items: stretch;
    display: flex;
    gap: 0.8rem;

    .date {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.2rem;
    }
    .icon {
        color: ${({ theme }) => theme.colors.deepBlue};
        font-size: 1.6rem;
        margin-left: auto;
    }
`;
