import React from 'react';
import { Button } from 'modules/cxi/components/button';
import { useCXI } from '../../../useCXI';
import * as Styled from './StyledChallengesEmpty';

export const CXIChallengesEmpty = () => {
    const { t } = useCXI();
    return (
        <Styled.ChallengesEmpty>
            <span className="icon fa-2x far fa-empty-set" aria-hidden="true" />
            <Styled.ChallengesEmptyTitle>{t('challenge.empty')}</Styled.ChallengesEmptyTitle>
            <Styled.ChallengesEmptyDescr>{t('challenge.emptyDescr')}</Styled.ChallengesEmptyDescr>

            <Button variant="blue" href="https://100os.net/cxi_challenge">
                <span className="text">{t('challenge.buttonEmpty')}</span>
            </Button>
        </Styled.ChallengesEmpty>
    );
};

export default CXIChallengesEmpty;
