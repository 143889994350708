import React from 'react';

import * as Tabs from 'components/tabs';
import * as List from 'components/list';
import * as Page from 'components/page';

import UserAvatar from 'core/components/user/avatar/UserAvatar';

import * as Styled from './StyledComponents';

export const FounderSection = props => {
    const { form } = props;
    const { vars, deleteFounder, saveEditedFields } = props.useStartupId;
    const { founders } = vars;

    const FounderCard = ({ data }) => {
        const founder = data;

        const handleDelete = () => {
            deleteFounder(founder._id);
        };

        return (
            <Styled.FounderCard>
                <UserAvatar user={{ firstName: founder.fullName, photoUrl: founder.logoUrl }} />
                <Styled.CardInfos>
                    <div className="card-header">
                        <span className="name">{founder.fullName}</span>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={handleDelete}
                            className="icon far fa-times"
                            aria-hidden="true"
                        />
                    </div>
                    <span className="text">{founder.email}</span>
                    <span className="text">{founder.phone}</span>
                    {/* <span className="text">{founder.linkedin}</span> */}
                    <a
                        href={founder.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text"
                    >
                        {founder.linkedin}
                    </a>
                </Styled.CardInfos>
            </Styled.FounderCard>
        );
    };

    return (
        <>
            <Page.Descr>{props.t('FounderSection.title')}</Page.Descr>
            <Styled.SubDescr>{props.t('FounderSection.descr')}</Styled.SubDescr>
            <Styled.TabsContainer>
                <Tabs.TabsContainer>
                    <Tabs.TabsButtons>
                        <Tabs.Tab name="Founder">{props.t('FounderSection.title')}</Tabs.Tab>
                    </Tabs.TabsButtons>

                    <List.List name="Founder">
                        <List.Vertical items={founders} itemKey="_id" itemComponent={FounderCard} />
                        <Styled.BtnContainer>
                            <Page.Buttn
                                to={`/startup/${vars.startupId}/founder`}
                                type="linkd btn-block"
                                onClick={() => saveEditedFields(form.values)}
                            >
                                <span className="icon far fa-plus-circle" aria-hidden="true" />
                                <span className="text">{props.t('glossary:add')}</span>
                                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                            </Page.Buttn>
                        </Styled.BtnContainer>
                    </List.List>
                </Tabs.TabsContainer>
            </Styled.TabsContainer>
        </>
    );
};

export default FounderSection;
