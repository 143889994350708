import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledForumSubscription';

export const ForumSubscription = () => {
    const { t, setModal } = useCXI();

    return (
        <Styled.ForumSubscription as={Container.Slim}>
            <Container.Body>
                <Styled.Title>{t('modal.ForumSubscription.title')}</Styled.Title>
                <Styled.Descr>{t('modal.ForumSubscription.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.ForumSubscription>
    );
};
