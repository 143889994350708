import React from 'react';
import { useTranslation } from 'react-i18next';

import { OiweekRoutes } from './OiweekRoutes';
import { useOiweek } from './useOiweek';
import * as Styled from './StyledOiweek';

export function Oiweek(props) {
    const { t } = useTranslation('oiweek');
    const hookd = useOiweek();
    return !hookd.ready ? null : (
        <Styled.OiweekModule>
            <OiweekRoutes t={t} useOiweek={hookd} />
        </Styled.OiweekModule>
    );
}
export default Oiweek;
