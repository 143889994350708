import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ContextAuth as CA } from 'contexts';
import { LinksWithText, Subtitle } from 'components/page';

import * as Styled from './StyledUserCompany';

const UserCompanyU = ({ data, user }) => (
    <Styled.CardInfos>
        <span className="name">{data.name}</span>
        {user.position && <span className="bold">{user.position}</span>}
        {user.department && <span className="text">{user.department}</span>}
    </Styled.CardInfos>
);

const Lwtxt = props =>
    !props.text && !props.link ? null : (
        <LinksWithText to={props.href}>
            {props.text && <span className="text">{props.text}</span>}
            {props.link && <span className="link">{props.link}</span>}
        </LinksWithText>
    );

const Title = props => (!props.text ? null : <Subtitle {...props} />);

export function UserCompany(props) {
    const { state } = React.useContext(CA);
    const { t } = useTranslation('components');
    const { user } = state;
    // Get the company data source
    const data = props.data || props.company || user.company;

    // Decide wicth information to show
    const Infos = UserCompanyU;

    // Get the user company
    const propsData = {
        href: '/profile/company',
        title: {
            icon: 'far fa-pencil',
            text: t('UserCompany.aboutCompany'),
            link: t('glossary:edit'),
        },
        ...props,
    };

    // Check if it has a link prop and pass parameters
    const propsLink = {
        as: propsData.href ? Link : undefined,
        to: propsData.href,
    };
    return !data ? null : (
        <Styled.UserCompany>
            <Title href={propsData.href} {...propsData.title} />
            <Styled.UserCompanyCard {...propsLink}>
                <Styled.CardImage img={data.logo} />
                <Infos data={data} user={user} />
            </Styled.UserCompanyCard>
            <Lwtxt href={propsData.href} {...propsData.lwtxt} />
        </Styled.UserCompany>
    );
}
export default UserCompany;
