import React, { useEffect, useState } from 'react';
import { useCXI } from '../useCXI';
import { FilterTag } from './components/filter-tag/FilterTag';
import { OpportunityCard } from './components/opportunity/OpportunityCard';

import * as Styled from './StyledCXIOpportunities';

export function CXIOpportunities() {
    const { t, vars, getCXIOpportunities } = useCXI();
    const { matches } = vars;
    const [displayedOpportunities, setDisplayedOpportunities] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [offsetsArray, setOffsetsArray] = useState(['']);
    const [filters, setFilters] = useState([]);
    const pageSize = 12;

    useEffect(() => {
        async function getOpportunities() {
            const filtersString = filters
                ?.map(filter => `&filters=${encodeURIComponent(filter)}`)
                ?.join('');
            const opportunities = await getCXIOpportunities(
                pageSize,
                offsetsArray?.[currentPage - 1],
                filtersString
            );
            setDisplayedOpportunities(opportunities?.records);

            setOffsetsArray([...offsetsArray, opportunities?.offset]);
        }

        getOpportunities();
    }, [currentPage, filters]);

    const hasFilter = value => filters?.includes(value);

    const updFilter = value => {
        setCurrentPage(1);
        if (hasFilter(value)) {
            return setFilters(prevFilters => prevFilters?.filter(f => f !== value));
        }
        return setFilters(prevFilters => [...prevFilters, value]);
    };

    const handlePreviousPageClick = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPageClick = () => {
        return displayedOpportunities?.length < pageSize ? false : setCurrentPage(currentPage + 1);
    };

    const formattedMatches = matches?.map(match => {
        return { startupId: match?.startup?.startupId, id: match.matchid };
    });

    // eslint-disable-next-line no-unused-vars
    const generateAltLink = displayedOpportunities?.map(opportunity => {
        const newOpportunity = opportunity;
        formattedMatches.forEach(match => {
            if (match.startupId === opportunity?.fields?.StartupID) {
                newOpportunity.fields.ShareLink = `https://app.openstartups.net/100-10-1/startup/${match.id}`;
            }
        });
        return newOpportunity;
    });

    return (
        <Styled.CXIOpportunities>
            <Styled.CXIOpportunitiesFilters>
                <Styled.Filter>
                    <p>Filtrar:</p>
                    <FilterTag
                        active={hasFilter('Gestão de Pessoas')}
                        onClick={() => updFilter('Gestão de Pessoas')}
                    >
                        <span className="text">{t('opportunities.filters.people')}</span>
                    </FilterTag>
                    <FilterTag
                        active={hasFilter('Proposta de Valor')}
                        onClick={() => updFilter('Proposta de Valor')}
                    >
                        <span className="text">{t('opportunities.filters.value')}</span>
                    </FilterTag>
                    <FilterTag
                        active={hasFilter('Problemas e Visão')}
                        onClick={() => updFilter('Problemas e Visão')}
                    >
                        <span className="text">{t('opportunities.filters.visionAndProblem')}</span>
                    </FilterTag>
                    <FilterTag active={hasFilter('Produto')} onClick={() => updFilter('Produto')}>
                        <span className="text">{t('opportunities.filters.product')}</span>
                    </FilterTag>
                    <FilterTag active={hasFilter('Mercado')} onClick={() => updFilter('Mercado')}>
                        <span className="text">{t('opportunities.filters.market')}</span>
                    </FilterTag>
                    <FilterTag
                        active={hasFilter('Modelo de Negócio')}
                        onClick={() => updFilter('Modelo de Negócio')}
                    >
                        <span className="text">{t('opportunities.filters.businessModel')}</span>
                    </FilterTag>
                    <FilterTag
                        active={hasFilter('Escalabilidade')}
                        onClick={() => updFilter('Escalabilidade')}
                    >
                        <span className="text">{t('opportunities.filters.escalability')}</span>
                    </FilterTag>
                    <FilterTag
                        active={hasFilter('Estratégia de Saída (Exit)')}
                        onClick={() => updFilter('Estratégia de Saída (Exit)')}
                    >
                        <span className="text">{t('opportunities.filters.investment')}</span>
                    </FilterTag>
                    <FilterTag active={hasFilter('Outros')} onClick={() => updFilter('Outros')}>
                        <span className="text">{t('opportunities.filters.others')}</span>
                    </FilterTag>
                </Styled.Filter>
            </Styled.CXIOpportunitiesFilters>

            <Styled.Title>{t('opportunities.title')}</Styled.Title>
            <Styled.Descr>{t('opportunities.description')}</Styled.Descr>

            <Styled.CXIOpportunitiesFilter />

            <Styled.Descr>
                <b className="subtitle">{t('opportunities.subtitle')} </b>
            </Styled.Descr>

            <Styled.CXIOpportunitiesList>
                {displayedOpportunities &&
                    displayedOpportunities?.length > 0 &&
                    displayedOpportunities?.map(opportunity => (
                        <OpportunityCard key={opportunity.id} data={opportunity} t={t} />
                    ))}
            </Styled.CXIOpportunitiesList>
            {displayedOpportunities?.length > 0 && (
                <Styled.CXIOpportunitiesPagination>
                    {currentPage !== 1 && (
                        <i className="fas fa-less-than" onClick={handlePreviousPageClick} />
                    )}
                    <b>{currentPage}</b>
                    {displayedOpportunities?.length === pageSize && (
                        <i className="fas fa-greater-than" onClick={handleNextPageClick} />
                    )}
                </Styled.CXIOpportunitiesPagination>
            )}

            {/* <Styled.LaunchChallengeBanner>
                <p>
                    A corporação da qual você faz parte tem alguma necessidade que poderia ser
                    resolvida com o auxílio de uma startup? Busque um parceiro de negócio ideal
                    através da nossa plataforma.
                </p>
                <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScZYHA8PCT9ggWrj5w9Mu2bENnNokLLlpDHh5Lrx_tEs-d3Jw/viewform"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {props.t('opportunities.launchChallenge')}
                </a>
            </Styled.LaunchChallengeBanner> */}
        </Styled.CXIOpportunities>
    );
}
