import styled from 'styled-components';

export const StartupIdIndex = styled.div.attrs({
    className: 'styled-ranking-index',
})`
    .component-page-container {
        text-align: center;
    }

    .c-page-title {
        font-size: 2rem;
    }
    .c-page-descr {
        color: var(--primary);
        font-size: 2.2rem;
        font-weight: 700;
        margin-top: 0.4rem;
    }
    .c-page-texts {
        margin-top: 3rem;
    }

    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444;
    }
    .list-vertical-item-link .icon {
        font-size: 1.8rem;
    }
`;

export const Image = styled.div.attrs({
    className: 'startup-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 4px solid #ddd;
    border-radius: 50%;
    margin: 2.2rem auto 0;
    flex: 0 0 10rem;
    height: 10rem;
    padding: 1.2rem;
    width: 10rem;
`;
