import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const UserProfile = styled.div.attrs({
    className: 'user-profile',
})`
    align-items: center;
    color: inherit;
    display: flex;
    flex-direction: column;

    .name {
        display: block;
        font-size: 1.8rem;
        font-weight: 600;
        ${truncate(1)}
    }
    .desc {
        font-size: 1.4rem;
        font-weight: 400;
        ${truncate(1)}
    }

    .user-avatar {
        margin: 0 0 1rem;
        width: 8rem;
    }
`;
