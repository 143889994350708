import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from 'hooks';
import { RHP } from 'providers';

export const CXICertificationContext = createContext();
export const CXICertificationContextProvider = ({ children, userId }) => {
    const api = useApi();
    const { t } = useTranslation('cxi');

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState({ t, userId, ready: false });
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    React.useEffect(() => {
        addVars({ ready: false });

        const getCXICertificate = async () => {
            try {
                const configs = { isErrorHandled: true };
                return await api.get(`/usercxicertificate/${userId}`, {}, configs);
            } catch (e) {
                return {};
                // return {
                //     certified: true,
                //     certificationDate: '2023-01-20T13:11:58.652Z',
                //     certifications: [
                //         {
                //             certificationType: 'white',
                //             certificationStatus: 'confirmed',
                //             certifiedAt: '2023-01-20T13:11:58.652Z',
                //             certifiedBy: '5e67bea2bc3a5c0f3e5ad769',
                //         },
                //         {
                //             certificationType: 'green',
                //             certificationStatus: 'pending',
                //         },
                //     ],
                //     currentBelt: 'white',
                //     userName: 'Daniel Vasserman',
                // };
            }
        };

        getCXICertificate()
            .then(certificate => {
                console.log(certificate);
                addVars({ certificate, ready: true });
            })
            .catch(() => {
                RHP.replace(`/`);
            });
    }, [userId]);

    const stateValue = { t, vars, addVars, setVars };
    return (
        <CXICertificationContext.Provider value={stateValue}>
            {children}
        </CXICertificationContext.Provider>
    );
};
