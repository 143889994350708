import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { RHP } from 'providers';

import * as Styled from './StyledMetrics';

export const MetricStage3Startup = ({ icon, numb, text, tooltip }) => {
    const { t, vars } = useCXI();
    const { isMentoring, mentoring } = vars;

    const goToMentoring = () => {
        RHP.push(`/100-10-1/mentoring/${mentoring?.matchid}`);
    };

    return isMentoring ? (
        <Styled.MetricsLink onClick={goToMentoring}>
            <Styled.StartupImage img={mentoring.startup.logoUrl} />
            <span className="cont">
                <span className="text">{t(`home.Metrics.mentoring.text`)}</span>
                <span className="numb">{mentoring.startup.name}</span>
            </span>
        </Styled.MetricsLink>
    ) : (
        <Styled.MetricsItem>
            <span className={`icon fal ${icon || 'fa-hashtag'}`} aria-hidden="true" />
            <span className="cont">
                <span className="text">{text}</span>
                <span className="numb">{numb}</span>
            </span>
            <span className="ttip" style={!tooltip ? { display: 'none' } : {}}>
                <span className="ttip-icon far fa-question-circle" aria-hidden="true" />
                <span className="ttip-text">{tooltip}</span>
            </span>
        </Styled.MetricsItem>
    );
};
