import React from 'react';
import { useTranslation } from 'react-i18next';

import { CA } from 'contexts';
import * as Form from 'components/form';
import { useForm } from 'components/form';
import { Ruler, Subtitle } from 'components/page';

export function ContractReauditForm(props) {
    const { t } = useTranslation('ranking');
    const { state } = React.useContext(CA);
    const data = props.data || props.relationship || undefined;

    console.log(state);

    const { postStartupContractReconsideration } = props.useRankingStartup;

    // Initialize a new form handler
    const form = useForm({});

    const fields = {
        message: {
            error: form.errors.message,
            label: t('reconsideration.inputLabel'),
            name: 'message',
            type: 'textarea',
            value: form.values.message,
            onChange: form.handleChange,
        },
    };

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            _id: data._id,
            userId: state.user.userid,
            message: values.message,
        };
        // Add startup contact information
        return postStartupContractReconsideration(payload)
            .then(res => {
                props.setReauditing(false);
                return res;
            })
            .catch(err => {
                props.setReauditing(false);
                return err;
            });
    };

    // Handle form submition
    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();
        return form.handleSubmit(ev, onSubmit);
    };

    return !props.reauditing ? null : (
        <Form.Form name="ContractReauditForm" onSubmit={evSubmit}>
            <Ruler />
            <Subtitle text={t('reconsideration.titleSubmit')} />
            <Form.Field {...fields.message} />
            <Form.Submt {...form} text={t('reconsideration.submit')} />
        </Form.Form>
    );
}
