import React from 'react';

import { ContextAuth as CA } from 'contexts';

// import { EvaluatorIncomplete } from './EvaluatorIncomplete';
import { EvaluatorInvite } from './EvaluatorInvite';
import { EvaluatorOk } from './EvaluatorOk';

export function Evaluator(props) {
    const { state, scopeAllows } = React.useContext(CA);
    const { user, cxi } = state;

    const isUserCxiRegistered = cxi !== false;

    if (!scopeAllows('evaluator')) {
        return null;
    }

    // Set variable to decide witch component to use
    let Component = () => null;

    if (isUserCxiRegistered) {
        Component = () => null;
    }
    // Check if the user is an evaluator
    else if (user.evaluator && !isUserCxiRegistered) {
        Component = EvaluatorOk;
        // if (['incomplete'].includes(user.evaluatorStatus)) {
        //     Component = EvaluatorIncomplete;
        // }
    }
    // Not a evalutor, but, should it be? Should we show an invite?
    else if (
        user.entrepreneurStatus !== 'registered' ||
        [5, 6, 7, 13, 14].includes(user.role) ||
        ['invited'].includes(user.evaluatorStatus)
    ) {
        // Show invite to register as evaluator if:
        //      - Is not an entrepreneur, or
        //      - Has a typical evaluator role, or
        //      - Has been invited as evaluator
        Component = EvaluatorInvite;
    }

    return <Component />;
}
