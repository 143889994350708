import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { geoPatterson } from 'd3-geo-projection';
import { useEffectOnce } from 'react-use';
import brazilTopoJson from './topojsonMaps/brazil.json';
import southAmericaTopoJson from './topojsonMaps/southAmerica.json';
import worldMap from './topojsonMaps/worldMap.json';

const southAmericaCountriesList = [
    'AR',
    'BO',
    'CL',
    'CO',
    'EC',
    'FK',
    'GF',
    'GY',
    'PY',
    'PE',
    'SR',
    'UY',
    'VE'
];

export const GeoChart = props => {
    const [markerName, setMarkerName] = useState('');
    const markers = props.data;
    const [mapOpt, setMapOpt] = useState({
        geoUrl: brazilTopoJson,
        scale: 810,
        center: [3, 5],
        projection: 'geoMercator'
    });

    const [markerTextDisplay, setMarkerTextDisplay] = useState(markers);
    const uniqueCountries = new Set();

    const filtered = markers?.forEach(marker => {
        uniqueCountries.add(marker.country);
    });

    const uniqueCountriesArr = Array.from(uniqueCountries);

    useEffectOnce(() => {
        for (const uniqueCountry of uniqueCountriesArr) {
            if (uniqueCountry === 'BR' && uniqueCountriesArr.length === 1) {
                setMapOpt({
                    geoUrl: brazilTopoJson,
                    scale: 810,
                    center: [3, 7]
                });
            } else if (southAmericaCountriesList.includes(uniqueCountry)) {
                setMapOpt({
                    geoUrl: southAmericaTopoJson,
                    scale: 400,
                    center: [3, -8]
                });
            } else if (
                !southAmericaCountriesList.includes(uniqueCountry) &&
                uniqueCountry !== 'BR'
            ) {
                setMapOpt({
                    geoUrl: worldMap,
                    scale: 610,
                    center: [-20, -15],
                    projection: geoPatterson()
                });
            }
        }
    }, []);

    return (
        <ComposableMap
            projection={mapOpt.projection}
            projectionConfig={{
                rotate: [58, 20, 0],
                center: mapOpt.center,
                scale: mapOpt.scale
            }}
            style={{ width: '100%', height: '100%' }}
        >
            <Geographies geography={mapOpt.geoUrl}>
                {({ geographies }) =>
                    geographies?.map(geo => {
                        return (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                fill="#d6f2ff"
                                stroke="#0f0654"
                            />
                        );
                    })
                }
            </Geographies>

            {markers?.map(
                ({ _id, cityName, name, coordinates, markerOffset, radius, showText = false }) => {
                    return (
                        <Marker
                            key={_id}
                            coordinates={coordinates}
                            onMouseEnter={() => {
                                setMarkerName(name);
                                setMarkerTextDisplay(true);
                            }}
                            onMouseLeave={() => {
                                setMarkerName('name');
                                setMarkerTextDisplay(false);
                            }}
                        >
                            {/* //circle radius, tamanho conforme distribuição de startups */}
                            <circle
                                r={radius}
                                fill="#3b76fe"
                                stroke="#202297"
                                strokeWidth={2}
                                className="geoCircle"
                            />

                            {markerName === name && markerTextDisplay && (
                                <text
                                    textAnchor="upper"
                                    y={coordinates[1] + 100}
                                    style={{ fontSize: '25px' }}
                                >
                                    {radius} - {cityName}
                                </text>
                            )}
                        </Marker>
                    );
                }
            )}
        </ComposableMap>
    );
};
