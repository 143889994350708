import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Page from './index';

export const routes = [
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/feedbacks',
        component: Page.CXIFeedbacksIndex,
    },
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/feedbacks/evaluated',
        component: Page.CXIFeedbacksEvaluated,
    },
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/feedbacks/unevaluated',
        component: Page.CXIFeedbacksUnevaluated,
    },
];

export const CXIFeedbacksRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default CXIFeedbacksRoutes;
