import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Progress = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2.4rem;
    position: relative;

    &::before {
        background: ${({ theme }) => theme.colors.lightGray};
        content: '';
        position: absolute;
        ${positions('1.1rem', 'auto', '1.1rem', '1.1rem')}
        transform: translateX(-1.5px);
        width: 0.3rem;
    }

    & > div {
        align-items: center;
        color: ${({ theme }) => theme.colors.energyBlue};
        display: flex;
        position: relative;

        .icon {
            align-items: center;
            background: ${({ theme }) => theme.colors.lightGray};
            color: ${({ theme }) => theme.colors.white};
            display: flex;
            border-radius: 50%;
            height: 2.2rem;
            justify-content: center;
            width: 2.2rem;
        }
        .icon::before {
            content: '\f00c';
            font-family: 'Font Awesome 5 Pro';
            font-size: 1rem;
            font-weight: 900;
            line-height: 1;
            transform: translate(-0.5px, 1.5px);
        }

        .text {
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 1.4rem;
            margin-left: 0.8rem;
        }
    }

    & > div.default .icon::before {
        background: ${({ theme }) => theme.colors.white};
        border-radius: 50%;
        content: '';
        height: 1rem;
        width: 1rem;
        transform: translateY(0);
    }

    & > div.current {
        .icon {
            background: ${({ theme }) => theme.colors.energyBlue};
        }
        .text {
            font-size: 1.8rem;
        }
    }
`;
