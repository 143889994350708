import React from 'react';
import { useMedia } from 'react-use';

import { useStartupCXI } from '../useStartupCXI';

import { CXIMessagesIndex } from './index/CXIMessagesIndex';
import { CXIMessagesMatch } from './match/CXIMessagesMatch';

import { CXIMessagesRoutes } from './CXIMessagesRoutes';

import * as Styled from './StyledCXIMessages';

export const CXIMessages = () => {
    const { vars } = useStartupCXI();
    const { isMenuOpen } = vars;

    const isMobile = useMedia('(max-width: 992px)');
    const isDesktop = useMedia('(min-width: 992px) and (max-width:1200px)');

    return isMobile || (isDesktop && isMenuOpen) ? (
        <Styled.CXIMessages>
            <CXIMessagesRoutes />
        </Styled.CXIMessages>
    ) : (
        <Styled.CXIMessagesLayout>
            <Styled.CXIMessagesWrapper>
                <Styled.CXIMessagesIndex>
                    <CXIMessagesIndex />
                </Styled.CXIMessagesIndex>
                <Styled.CXIMessagesMatch>
                    <CXIMessagesMatch />
                </Styled.CXIMessagesMatch>
            </Styled.CXIMessagesWrapper>
        </Styled.CXIMessagesLayout>
    );
};
