import React from 'react';

import { ContextAuth as CA } from 'contexts';

import { EntrepreneurInvite } from './EntrepreneurInvite';
import { EntrepreneurTech } from './EntrepreneurTech';

export function Entrepreneur(props) {
    const { authState, scopeAllows } = React.useContext(CA);
    const { user } = authState;

    if (!scopeAllows('startup')) {
        return null;
    }

    // Has one or more startup?
    if (user.entrepreneurStatus === 'registered') {
        // console.log('User has one or more startup(s)');
    }
    // User should is a Cientist/NIT?
    else if ([9, 12].includes(user.role)) {
        return <EntrepreneurTech />;
    }
    // User should have a startup?
    else if ([1, 9, 12].includes(user.role) || user.entrepreneurStatus === 'invited') {
        return <EntrepreneurInvite />;
    }

    return null;
}
export default Entrepreneur;
