import React from 'react';
import axios from 'axios';

import { CA, CG } from 'contexts';
import { UserAvatar } from 'core/components/user';
import { useApi } from 'hooks';

import * as Styled from './StyledFormCustomEditAvatar';

export function FormCustomEditAvatar(props) {
    const { state, dispatch } = React.useContext(CA);
    const { emitAlert } = React.useContext(CG);
    const api = useApi();

    // Manage the image submition state
    const [image, setImage] = React.useState();

    // Create the fileReader
    let fileReader;

    // Send the image to the S3 server
    function sendToServer(file) {
        const { userid } = state.user;
        // Request permission to the API to upload the file to S3
        api.post(`/users/${userid}/avatar`, { fileName: file.name }).then(url => {
            // Write file to the S3 server
            axios.put(url.uploadUrl, file).then(() => {
                // Update the user image with the new file
                api.put(`users/${userid}`, { photoUrl: url.photoUrl });
                setImage(url.photoUrl);
                dispatch({ type: 'UPDATE_USER_AVATAR', photoUrl: url.photoUrl });
            });
        });
        return true;
    }

    // Update the local state
    function onLoaded() {
        const content = fileReader.result;
        return setImage(content);
    }

    // Once the user selects an image to submit
    function onChange(file) {
        // Check if the file is an image
        if (file && !file.type.match('image.*')) {
            emitAlert('File is not an image!');
            return null;
        }

        try {
            // Include load listeners on the fileReader
            fileReader = new FileReader();
            fileReader.onloadend = onLoaded;
            fileReader.readAsDataURL(file);
            return sendToServer(file);
        } catch (err) {
            return emitAlert(err);
        }
    }

    // Create the input for edition
    const propsInput = {
        accept: 'image/*',
        id: 'form-custom-edit-avatar-input',
        type: 'file',
        onChange: ev => onChange(ev.target.files[0])
    };
    return (
        <Styled.FormCustomEditAvatar htmlFor={propsInput.id}>
            <input {...propsInput} />
            <Styled.UserAvatar as={UserAvatar} photoUrl={image || undefined} />
            <Styled.IconCamera />
        </Styled.FormCustomEditAvatar>
    );
}
export const EditAvatar = FormCustomEditAvatar;
export default FormCustomEditAvatar;
