import React, { createContext } from 'react';

import { useCXI } from '../useCXI';

const initial = {
    currentStep: 0,
    totalSteps: 8,
};

export const CXISetupContext = createContext();
export const CXISetupContextProvider = ({ children }) => {
    const { t } = useCXI();

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    const stateValue = { t, vars, addVars, setVars };
    return <CXISetupContext.Provider value={stateValue}>{children}</CXISetupContext.Provider>;
};
