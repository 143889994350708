import styled from 'styled-components';

export const CXIFeedback = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    text-align: center;

    .m-container {
        z-index: 0;
    }
    .m-container-head,
    .m-container-close {
        display: none;
    }
    .m-container-feet {
        justify-content: center;
    }
    .m-container-feet .cxi-c-button[type='button'] {
        display: none;
    }
`;

export const CXIFeedbackCard = styled.div`
    align-items: center;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 48rem;
    padding: 3.2rem;
    text-align: center;

    button {
        margin-top: 3.2rem;
    }
`;

export const CXIFeedbackHead = styled.div`
    background: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: center;
    padding: 1.6rem 2.4rem;
    padding-top: calc(1.6rem + var(--ion-safe-area-bottom));
    position: relative;
    width: 100%;
`;

export const CXIFeedbackBody = styled.div.attrs({
    className: 'container',
})`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    gap: 1.6rem;
    width: 100%;

    @media (min-width: 768px) {
        gap: 3.2rem;
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }
`;

export const Logo = styled.img`
    height: 6.8rem;
    cursor: pointer;
`;
export const Area = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    padding: 3.2rem;
    text-align: center;
`;

export const Descr = styled.div`
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.3;
    margin: 0;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.3;
    margin: 0 auto;
`;

export const Intro = styled.h1`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.4rem;
    line-height: 1.2;
    margin: 0;
`;
