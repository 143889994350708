import styled from 'styled-components';

import background from 'assets/images/bg-100-10-1.jpg';

import { positions } from 'utils/styles';

export const CXIIndex = styled.div.attrs({
    className: 'styled-cxi-index',
})`
    .cp-CXIIndex ion-content {
        --background: #e5f1f9;
    }
    .logo {
        width: 200px;
    }
`;

export const Background = styled.div.attrs({
    className: 'styled-cxi-index-background',
})`
    align-items: center;
    background: #003866;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 3rem;
    text-align: center;
    &::before {
        background: no-repeat 0 0;
        background-color: inherit;
        background-image: url(${background});
        background-size: cover;
        content: '';
        margin-top: -12rem;
        position: absolute;
        ${positions(0, 'auto', 0, '50%')}
        transform: translateX(-50%);
        width: 102vw;
    }
    & > * {
        position: relative;
    }

    .logo {
        margin-top: -3rem;
    }

    .c-page-descr {
        margin-top: 0;
    }
`;

export const Report = styled.div.attrs({
    className: 'styled-cxi-index-report',
})`
    padding: 2.5rem 0 0 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    &.styled-cxi-index-report::after {
        background-image: linear-gradient(to bottom, #fff, #e5f1f9);
        content: '';
        height: 160px;
        left: -12%;
        position: absolute;
        top: 0;
        width: 130%;
        z-index: 0;
    }
    p {
        position: relative;
        z-index: 1;
    }
    .reports {
        color: #444444;
        display: flex;
        justify-content: space-around;
        position: relative;
        z-index: 1;
        strong {
            display: flex;
            font-size: 7rem;
            line-height: 7rem;
        }
        .report-a {
            color: #00adfb;
        }
        .report-b {
            color: #0180c2;
        }
        .report-c {
            color: #015f98;
        }
        .report-d {
            color: #003866;
        }
    }
`;

export const Profile = styled.div.attrs({
    className: 'styled-cxi-index-progress',
})`
    align-items: center;
    background: #003866;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 7.5rem 0 2.5rem 0;
    padding: 15px;
    position: relative;
    text-align: center;
    .user-profile {
        margin: -60px 0 0 0;
        position: relative;
        .desc {
            display: none;
        }
    }

    .desc {
        font-size: 1.4rem;
        font-weight: 400;
        margin-top: 0.5rem;
    }
`;

export const UserProfile = styled.div.attrs({
    className: 'menu-user-profile-user-profile',
})``;

export const UserProfileLink = styled.a.attrs({
    className: 'btn btn-round btn-sm btn-primary',
})`
    margin-top: 1.5rem;
`;
