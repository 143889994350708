import styled from 'styled-components';

export const CXIOpportunities = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header.filter {
        margin-top: 2.4rem;
    }
    .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
    }
    .c-header.filter + .c-header.filter {
        margin-top: 0.4rem;
    }
`;

export const CXIOpportunitiesList = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 3.2rem;
    gap: 3.2rem;
    grid-auto-rows: 1fr;

    @media (min-width: 768px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(3, 1fr);
        }
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1200px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(4, 1fr);
        }
        gap: 2.8rem;
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const CXIOpportunitiesFilter = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 3.2rem 0 4.8rem;
    gap: 3.2rem;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const Title = styled.h1`
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
`;

export const Descr = styled.h1`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 1.6rem 0 0;
    margin-top: 1rem;

    .subtitle {
    }

    .challengesLength {
        font-size: 1.5rem;
        color: #0094f9;
    }
`;

export const CXIOpportunitiesFilters = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 3.2rem 0 4.8rem;
    gap: 3.2rem;
    width: 100%;

    .cxi-layout:not(.is-open) & {
        grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const Filter = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    background: #c4c4c4;
    border: 0;
    border-radius: 8px;
    transition: all 0.3s ease;
    width: 100%;

    p {
        font-weight: 700;
        font-size: 1.5rem;
        margin-right: 10px;
    }
`;

export const LaunchChallengeBanner = styled.div`
    display: flex;
    margin-top: 30px;
    gap: 40px;
    background-color: #bbe6ef;
    align-items: center;
    justify-content: center;
    padding: 25px 20px;
    border: 1px solid #0094f9;
    border-radius: 5px;
    width: 100%;

    p {
        font-size: 1.5rem;
        margin-right: 10px;
    }

    a {
        width: fit-content;
        white-space: nowrap;
        background: #0094f9;
        color: white !important;
        border-radius: 3px;
        padding: 10px 14px;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.05);
        }
    }
`;

export const CXIOpportunitiesPagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: black;
    padding-top: 30px;
    font-weight: 700;
    gap: 30px;
    max-width: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;

    ul {
        display: flex;
        gap: 30px;
        padding: 0;
        list-style: none;
        margin: 0;
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    i {
        cursor: pointer;
        color: #0094f9;
        transition: 0.5s ease all;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 27px;
        height: 27px;
        &:hover {
            color: white;
            border-radius: 100px;
            transform: scale(1.5);
            transition: 0.5s ease all;
            background-color: #0094f9;
        }
    }

    b {
        background-color: #0094f9;
        width: 27px;
        height: 27px;
        color: white;
        text-align: center;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.5s ease all;

        &:hover {
            transform: scale(1.3);
            color: white;
            border-radius: 100px;
            width: 27px;
            height: 27px;
            transform: scale(1.3);
            transition: 0.5s ease all;
            background-color: #b1b1b1;
        }

        &.active {
            background-color: #0094f9;
            color: white;
            border-radius: 100px;
            width: 27px;
            height: 27px;
            transform: scale(1.3);
            transition: 0.5s ease all;
        }
    }
`;
