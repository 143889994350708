import React from 'react';
import { Link } from 'react-router-dom';

import { TableSubtitle } from './subtitle/TableSubtitle';
import { TableObject } from './ComponentTableObject';
import { TableRows } from './ComponentTableRows';

import * as Styled from './StyledComponentTable';

export function ComponentTable(props) {
    const propsTable = { ...props };

    if (props.href !== undefined) {
        propsTable.as = Link;
        propsTable.to = propsTable.href;
    }

    return (
        <Styled.ComponentTable {...propsTable}>
            {!!props.subtitle && <TableSubtitle {...props.subtitle} />}
            {!!props.object && <TableObject {...props.object} />}
            {!!props.rows && <TableRows {...props.rows} />}
            {props.children}
        </Styled.ComponentTable>
    );
}
export const Table = ComponentTable;
export default ComponentTable;
