import React from 'react';
import { Link } from 'react-router-dom';

import * as Styled from './StyledListVertical';

export const ListVerticalItemLink = ({ data }) => {
    const item = { ...data };

    // Check if the item is a link
    if (item.href !== undefined && !item.disabled) {
        item.as = data.as || Link;
        item.to = item.href;
    }
    if (item.disabled) {
        item.href = '#';
    }

    // Check if the item has a custom arrow
    item.arrw = item.arrw || 'far fa-long-arrow-right';

    return (
        <Styled.ListVerticalItemLink {...item}>
            {item.icon && <span className={`icon fa-fw ${item.icon}`} aria-hidden="true" />}
            {item.text && <span className="text">{item.text}</span>}
            {item.text && <span className={`arrw fa-fw ${item.arrw}`} aria-hidden="true" />}
        </Styled.ListVerticalItemLink>
    );
};
export const VerticalItemLink = ListVerticalItemLink;
export default ListVerticalItemLink;
