import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { MatchAction } from 'modules/cxi/components/match';
import { useCXI } from 'modules/cxi/useCXI';
import { RHP } from 'providers';

import { useCXIStartup } from '../../useCXIStartup';
import * as Styled from './StyledStartupCard';

export const StartupCardActions = () => {
    const { t, setModal } = useCXI();
    const { vars, addVars } = useCXIStartup();
    const { match, showMatch } = vars;

    const { unreadMessages } = match;

    const goToMessages = () => RHP.push(`/100-10-1/messages/${match.matchid}`);

    return (
        <Styled.StartupCardActions>
            <Styled.StartupCardColumn className="eval">
                <MatchAction match={match} />
            </Styled.StartupCardColumn>

            <Styled.StartupCardColumn className="opts">
                <Button
                    variant="outline"
                    onClick={() => setModal({ match, modal: 'StartupOptions' })}
                >
                    <span className="text">{t('startup.StartupCard.dropdown')}</span>
                    <span className="icon far fa-chevron-down" aria-hidden="true" />
                </Button>
            </Styled.StartupCardColumn>

            <Styled.StartupCardColumn className="btns">
                <Button variant="outline" onClick={goToMessages}>
                    <span className="icon far fa-fw fa-comments-alt" aria-hidden="true" />
                    <span className="text">{t('startup.StartupCard.messages')}</span>
                    {!!unreadMessages && <span className="badge">{unreadMessages}</span>}
                </Button>

                <Button
                    variant="outline"
                    onClick={() => setModal({ match, modal: 'StartupShare' })}
                >
                    <span className="icon far fa-fw fa-share-alt" aria-hidden="true" />
                    <span className="text">{t('startup.StartupCard.share')}</span>
                </Button>

                {/* {match.status === 'confirmed' && (
                    <Styled.IconWithBttn onClick={goToMessages}>
                        <span className="icon far fa-fw fa-comments-alt" aria-hidden="true" />
                        <span className="text sr-only">Messages</span>
                        {!!unreadMessages && <span className="badge">{unreadMessages}</span>}
                    </Styled.IconWithBttn>
                )} */}

                {/* <Styled.IconWithBttn onClick={() => setModal({ match, modal: 'StartupShare' })}>
                    <span className="icon far fa-fw fa-share-alt" aria-hidden="true" />
                    <span className="text sr-only">Share</span>
                </Styled.IconWithBttn> */}
            </Styled.StartupCardColumn>

            <Styled.StartupCardColumn className="link">
                <Button variant="link" onClick={() => addVars({ showMatch: !showMatch })}>
                    <span className="text">{t('startup.showMatch')}</span>
                    {showMatch && <span className="icon far fa-chevron-up" aria-hidden="true" />}
                    {!showMatch && <span className="icon far fa-chevron-down" aria-hidden="true" />}
                </Button>
            </Styled.StartupCardColumn>
        </Styled.StartupCardActions>
    );
};
