import { useForm } from 'components/form';
import { CONFIG } from 'config';
import { ContextAuth as CA, CG } from 'contexts';
import { RHP } from 'providers';
import React from 'react';
import { useEffectOnce } from 'react-use';

import * as Page from 'components/page';

import { RankingValidForm } from './RankingValidForm';

export function RankingValid(props) {
    const { state } = React.useContext(CA);
    const { vars, postUserSendEmail, getCompanyByEmail } = props.useRanking;
    const { emitAlert } = React.useContext(CG);
    // Initialize a new form handler
    const form = useForm({
        email: vars.email || state.user.email
    });

    useEffectOnce(() => {
        // Check if the user has a verified e-mail
        if (state.user.hasVerifiedEmail) {
            RHP.replace(vars.redirectTo || '/ranking');
        }
    });
    // Handle form submition
    const onSubmit = async values => {
        const { email } = values;

        let isCorporateEmail = true;
        if (vars.redirectTo === '/ranking/corporate') {
            await getCompanyByEmail(email).then(res => {
                if (!res.validcompany) {
                    isCorporateEmail = false;
                    const errorMessage = 'O endereço de email não pode ter domínio público.';
                    emitAlert(errorMessage);
                    return false;
                }
            });
        }
        if (!isCorporateEmail) return false;
        // Do the submition api request
        return postUserSendEmail({
            baseUrl: `${CONFIG.baseUrl}${vars.redirectTo || '/ranking/validate'}${
                RHP.location.search
            }${RHP.location.hash}`,
            returnUrl: vars.redirectTo
        })
            .then(() => RHP.push('/ranking/validate'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="RankingValid">
            <Page.Title>{props.t('RankingValid.title')}</Page.Title>
            <Page.Descr>{props.t('RankingValid.descr')}</Page.Descr>
            <RankingValidForm {...propsForm} />
        </Page.Page>
    );
}
export default RankingValid;
