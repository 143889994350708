import useCXI from 'modules/cxi/useCXI';
import React from 'react';

import { UrltoYoutubeEmbed } from 'utils/youtube';

export const CXIChallengeStartupGeneralPresentation = props => {
    const { t } = useCXI();
    const { startup } = props;
    const video = UrltoYoutubeEmbed(startup?.video);

    if (video) {
        return (
            <div className="presentation-video">
                <iframe src={video} title={startup.name} />
            </div>
        );
    }
    if (startup?.video) {
        return (
            <div>
                <a href={startup.video} target="_blank" rel="noopener noreferrer">
                    {t('presentation')}
                </a>
            </div>
        );
    }
    return <p>{t('noPresentation')}.</p>;
};
