import { IonDatetime } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLangDetection } from 'hooks';
import * as Styled from './StyledFormInput';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {}
};

export const FormInputDate = props => {
    const { t } = useTranslation('glossary');
    const [value, setValue] = useState(props.input.value);
    const { detect } = useLangDetection();

    const boolProps = {};

    const currentLanguage = detect();

    if (props.input.disabled === true) {
        boolProps.disabled = props.input.disabled;
    }

    // const handleDateChange = e => {
    //     const ev = fakeEvent;
    //     ev.target.name = props.input.name;
    //     ev.target.value = e.detail.value;
    //     return props.input.onChange(ev);
    // };

    // const clearDate = () => {
    //     props.input.value = '';
    //     const ev = fakeEvent;
    //     setDisplayDateBox(o => !o);
    //     ev.target.name = props.input.name;
    //     ev.target.value = '';
    //     return props.input.onChange(ev);
    // };

    // const handleNoDateclick = ev => {
    //     props.input.value = new Date().toISOString().slice(0, 10);
    //     ev.target.value = '';
    //     setDisplayDateBox(o => !o);
    // };

    return !props.input ? null : (
        <Styled.FormInput name="date">
            {props.children}
            <IonDatetime
                translate="no"
                locale={currentLanguage}
                presentation={props.presentation || 'date'}
                cancelText={t('cancel')}
                className="form-control"
                doneText={t('select')}
                name={props.input.name}
                id={props.input.id}
                value={value || null}
                min={props.input.min}
                max={props.input.max}
                onIonChange={e => {
                    const ev = fakeEvent;
                    ev.target.name = props.input.name;
                    ev.target.value = e.detail.value;
                    setValue(e.detail.value);
                    return props.input.onChange(ev);
                }}
                {...boolProps}
            />
        </Styled.FormInput>
    );
};

export const date = FormInputDate;
export default FormInputDate;
