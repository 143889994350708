import React from 'react';

import { formatDate } from 'utils/date';

import { useCXIStartup } from '../useCXIStartup';

import * as Styled from './StyledCXIStartupSummaries';

export const CXIStartupSummariesResearcher = () => {
    const { t, vars } = useCXIStartup();
    const { startup } = vars;

    const data = startup.demographics.researcher;
    const birthdate = data?.birthdate ? formatDate(new Date(data?.birthdate), 'dd/MM/yyyy') : '-';

    return (
        <Styled.CXIStartupSummariesResearcher>
            {!!data.name && (
                <>
                    <dt>{t('startup.name')}</dt>
                    <dd>{data.name}</dd>
                </>
            )}

            {!!data.birthdate && (
                <>
                    <dt>{t('startup.birthdate')}</dt>
                    <dd>{birthdate}</dd>
                </>
            )}

            {!!data.profile && (
                <>
                    <dt>{t('startup.profile')}</dt>
                    <dd>
                        <a href={data.profile} target="_blank" rel="noopener noreferrer">
                            {data.profile}
                        </a>
                    </dd>
                </>
            )}
        </Styled.CXIStartupSummariesResearcher>
    );
};
