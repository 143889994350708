import React from 'react';
import * as Form from 'components/form';
import * as Styled from './StyledRegisterContactForm';

export const RegisterContactForm = props => {
    const { form, onSubmit } = props;

    const fields = {
        fullName: {
            error: form.errors.fullName,
            label: props.t('RegisterContactForm.fullName'),
            name: 'fullName',
            type: 'text',
            required: true,
            value: form.values.fullName,
            onChange: form.handleChange,
        },
        email: {
            error: form.errors.email,
            label: props.t('RegisterContactForm.contactEmail'),
            name: 'email',
            type: 'email',
            required: true,
            value: form.values.email,
            onChange: form.handleChange,
        },

        company: {
            error: form.errors.company,
            label: props.t('RegisterContactForm.company'),
            name: 'company',
            type: 'text',
            value: form.values.company,
            required: false,
            onChange: form.handleChange,
        },
        phone: {
            error: form.errors.phone,
            topLabel: '',
            label: props.t('RegisterContactForm.contactPhone'),
            name: 'phone',
            type: 'phone',
            maxLength: 18,
            required: false,
            value: form.values.phone,
            onChange: form.handleChange,
        },
        sendEmail: {
            error: form.errors.sendEmail,
            label: props.t('RegisterContactForm.sendEmail'),
            name: 'sendEmail',
            type: 'checkbox',
            required: false,
            value: form.values.sendEmail,
            onChange: form.handleChange,
        },
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Form.Form name="RegisterContactForm" onSubmit={evSubmit}>
            <Styled.FormContent>
                <Form.Field {...fields.fullName} />
                <Form.Field {...fields.email} />
                <Form.Field {...fields.company} />
                <Form.Field {...fields.phone} />
                <Form.Field {...fields.sendEmail} />
            </Styled.FormContent>
            <Form.Submt {...form} text={props.t('glossary:save')} />
        </Form.Form>
    );
};

export default RegisterContactForm;
