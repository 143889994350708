import React from 'react';
import { useClickAway } from 'react-use';

import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledGuide';

const LINK_TO_MATERIAL =
    'https://drive.google.com/file/d/187UAYHRc61M5z2qS9t7wlv0QVJ0l8eni/view?usp=sharing';

export const Guide = () => {
    const { t } = useCXI();
    const { setModal } = useCXI();
    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
        setModal(null);
    });

    return (
        <Styled.Guide ref={containerRef}>
            <Styled.Close onClick={() => setModal(null)}>
                <span className="icon far fa-times" aria-hidden="true" />
            </Styled.Close>
            <Container.Head>
                <span className="text">{t('menu.guide')}</span>
            </Container.Head>

            <Container.Body>
                <iframe
                    src="https://www.youtube-nocookie.com/embed/_Tlp8FWGC9M?controls=0"
                    title="Como começar a contribuir com Startups"
                />
            </Container.Body>

            <Container.Feet>
                <p>{t('guide.title')}</p>

                <p>{t('guide.part1')}</p>

                <p>{t('guide.part2')}</p>

                <p>{t('guide.part3')}</p>

                <p>{t('guide.part4')}</p>
                <a
                    href={LINK_TO_MATERIAL}
                    title="Entenda o programa através do material explicativo"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="icon far fa-fw fa-arrow-down" aria-hidden="true" />
                    <span className="text">{t('guide.button')}</span>
                </a>
                <hr size="20px" color="0094f9" />
            </Container.Feet>
        </Styled.Guide>
    );
};
