import React from 'react';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

import { useApi } from 'hooks';
import { EventCard } from 'modules/events/components';
import * as List from 'components/list';

import * as Styled from './StyledHomeListEvents';

export function HomeListEvents(props) {
    const { t } = useTranslation('home');
    const api = useApi();

    // Use the state do handle the api request
    const [events, setEvents] = React.useState([]);

    function filterEvents(e) {
        if (
            (e.requireRegistered || e.hiddenUser) &&
            (!e.user || !['registered', 'attended'].includes(e.user.eventStatus))
        ) {
            return false;
        }
        return true;
    }

    // Get the latest events
    let mounted = false;
    useEffectOnce(() => {
        mounted = true;
        const today = new Date();
        api.get(`/events?after=${today.toISOString()}`)
            .then(res => {
                const items = res.filter(filterEvents);
                return mounted && setEvents(items);
            })
            .catch(err => mounted && setEvents([]));
        return () => {
            mounted = false;
        };
    });

    // Don't show if the events array is empty
    return !events.length ? null : (
        <Styled.HomeListEvents as={List.List} name="HomeListEvents">
            <List.Subtitle href="/events" text={t('HomeListEvents.title')} />
            <List.Scrolls items={events} itemKey="_id" itemComponent={EventCard} />
        </Styled.HomeListEvents>
    );
}
export default HomeListEvents;
