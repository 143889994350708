import React from 'react';
import * as Styled from './StyledSessions';

function RightSession(props) {
    const { data } = props;

    function formatTable(array) {
        // eslint-disable-next-line prefer-spread
        const tableData = Array.apply(null, Array(5));

        for (let i = 0; i < tableData.length; i++) {
            if (typeof tableData[i] === 'undefined') {
                tableData[i] = array[i];
            }
        }
        return tableData;
    }

    const groups = formatTable(data?.companiesWithMostContracts);
    const startups = formatTable(data?.mostContractedStartups);

    return (
        <Styled.RightSession>
            <Styled.RightSessionContainer>
                <Styled.Title>
                    <h1>Grupos que mais contratam</h1>
                </Styled.Title>
                <Styled.TableContainer>
                    <Styled.ExecutiveTableList width="100%">
                        {groups.length > 0 ? (
                            <tbody>
                                <tr>
                                    <th>
                                        <span />
                                    </th>
                                    <th>Nome</th>
                                    <th>Pontos</th>
                                </tr>
                                {groups.map((group, index) => {
                                    return group !== undefined ? (
                                        <tr key={group?.name}>
                                            <td>{index + 1}º</td>
                                            <td>{group?.name}</td>
                                            <td>{group?.points}</td>
                                        </tr>
                                    ) : (
                                        <tr key={index + Math.random()}>
                                            <td>{index + 1}º</td>
                                            <td />
                                            <td />
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : null}
                    </Styled.ExecutiveTableList>
                </Styled.TableContainer>
            </Styled.RightSessionContainer>

            <Styled.RightSessionContainer>
                <Styled.Title>
                    <h1>Startups mais contratadas pelo Grupo</h1>
                </Styled.Title>
                <Styled.TableContainer>
                    <Styled.ExecutiveTableList width="100%">
                        {startups.length > 0 ? (
                            <tbody>
                                <tr>
                                    <th>
                                        <span />
                                    </th>
                                    <th>Nome</th>
                                    <th>Pontos</th>
                                </tr>
                                {startups.map((startup, index) => {
                                    return startup !== undefined ? (
                                        <tr key={startup?.name}>
                                            <td>{index + 1}º</td>
                                            <td>{startup?.name}</td>
                                            <td>{startup?.points}</td>
                                        </tr>
                                    ) : (
                                        <tr key={index + Math.random()}>
                                            <td />
                                            <td />
                                            <td />
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : null}
                    </Styled.ExecutiveTableList>
                </Styled.TableContainer>
            </Styled.RightSessionContainer>
        </Styled.RightSession>
    );
}

export default RightSession;
