import React from 'react';
import { useClickAway } from 'react-use';

import { UserAvatar } from 'core/components/user';

import * as Styled from './StyledNavbarProfile';

export const NavbarProfile = ({ items }) => {
    const [isOpen, setOpen] = React.useState(false);

    const dropdownRef = React.useRef(null);

    const toggleOpen = () => {
        setOpen(o => !o);
    };
    const closeAndAction = action => {
        setOpen(false);
        action();
    };

    useClickAway(dropdownRef, () => {
        setOpen(false);
    });

    return (
        <Styled.NavbarProfile ref={dropdownRef}>
            <Styled.NavbarProfileToggle onClick={toggleOpen}>
                <UserAvatar />
                <span className="icon fas fa-chevron-down" aria-hidden="true" />
            </Styled.NavbarProfileToggle>

            <Styled.NavbarProfileDropdown isOpen={isOpen}>
                {items.map(item => (
                    <li key={item.title}>
                        <Styled.Button onClick={() => closeAndAction(item.action)}>
                            <span className={`icon fa-fw ${item.icon}`} aria-hidden="true" />
                            <span className="text">{item.title}</span>
                        </Styled.Button>
                    </li>
                ))}
            </Styled.NavbarProfileDropdown>
        </Styled.NavbarProfile>
    );
};
