import styled from 'styled-components';

export const ComponentPage = styled.div.attrs(props => ({
    className: `component-page cp-${props.name}`,
}))``;

export const ComponentPageForm = styled.div.attrs(props => ({
    className: `component-page-form`,
}))`
    .component-page-container {
        align-items: stretch;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
    }
    .component-form {
        flex: 1 1 auto;
    }
    .form-submit-holder {
        margin-top: auto;
    }
`;

export const Container = styled.div.attrs({
    className: `component-page-container container`,
})`
    min-height: 100%;
    padding: 1.5rem;
    padding-top: calc(1.5rem + var(--ion-safe-area-top));
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;
`;

// ComponentPage: ELEMENTS
// ----------------------------------------------

export const PageContent = styled.div.attrs({
    className: `component-page-content`,
})``;

export const PageMenuBtn = styled.div.attrs({
    className: `component-page-menubtn`,
})``;

export const PageNavbar = styled.div.attrs({
    className: `component-page-navbar`,
})``;
