import React from 'react';
import { FormField } from 'components/form/field/FormField';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {},
};

export function FormCustomSelectRangeArray(props) {
    // Handle everytime the input changes
    function handleChange(ev) {
        ev.preventDefault();
        const { value } = ev.target;
        // Create a fake component to pass trough
        const event = { ...fakeEvent };
        event.target = {
            name: props.name,
            value: value.split(','),
        };
        return props.onChange(event);
    }
    // Create form fields object
    const field = {
        ...props,
        type: 'select',
        value: props.value && props.value.join(','),
        options: props.options.map(option => ({
            name: option.name,
            value: option.value.replace('range-', ''),
            disabled: option.disabled
        })),
        onChange: handleChange,
    };

    return <FormField {...field} />;
}
export const SelectRangeArray = FormCustomSelectRangeArray;
export default FormCustomSelectRangeArray;
