import React from 'react';
import { Link } from 'react-router-dom';

import * as Page from 'components/page';

import logoCXI from 'assets/images/logo-100-10-1.png';

import { UserProfile } from 'core/components/user';

import { CXIProgress } from '../components/progress/CXIProgress';
import { CXIVideos } from '../components/videos/CXIVideos';

import * as Styled from './StyledCXIIndex';

export const CXIIndexView = props => (
    <Page.Page name="CXIIndex" back="/home">
        <Styled.Background>
            <img className="logo" src={logoCXI} alt="Programa 100-10-1" />
            <Page.Descr>{props.t('CXIIndex.descr')}</Page.Descr>
        </Styled.Background>

        <Styled.Report>
            <p>
                <strong>{props.t('CXIIndex.report')}</strong>
            </p>
            <div className="reports">
                <div>
                    <strong className="report-a">A</strong>0/10
                </div>
                <div>
                    <strong className="report-b">B</strong>0/10
                </div>
                <div>
                    <strong className="report-c">C</strong>0/10
                </div>
                <div>
                    <strong className="report-d">D</strong>0/10
                </div>
            </div>
        </Styled.Report>

        <CXIProgress />

        <CXIVideos />

        <Styled.Profile>
            <Styled.UserProfile as={UserProfile} />
            <div className="desc">{props.t('CXIProfile.descr')}</div>
            <Styled.UserProfileLink as={Link} to="/profile">
                <span className="text">{props.t('CXIProfile.btn')}</span>
            </Styled.UserProfileLink>
        </Styled.Profile>
    </Page.Page>
);
export default CXIIndexView;
