import styled from 'styled-components';

export const Startup = styled.div`
    .cxi-matches-startup-card {
        align-self: stretch;
        background: rgba(196, 196, 196, 0.15);
        border-radius: 0.8rem;
        box-shadow: none;
        margin: 0;
        max-width: 100%;
        padding-bottom: calc(3.2rem + 1.6rem);

        @media (min-width: 992px) {
            flex: 0 0 48rem;
            max-width: 48rem;
        }
    }
    .cxi-matches-startup-card-buttons {
        display: none;
    }

    .user-company-card-image {
        margin-top: 0;
    }

    .m-container-body {
        border-top: 0;
        padding: 0;

        @media (min-width: 992px) {
            align-items: center;
            flex-direction: row;
        }
    }
`;

export const StartupReturn = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.6rem;

    @media (min-width: 768px) {
        padding: 2.4rem;
    }
    @media (min-width: 992px) {
        padding: 3.2rem;
    }

    .button {
        align-items: center;
        background: ${({ theme }) => theme.colors.energyBlue};
        border: none;
        border-radius: 0.8rem;
        color: #fff;
        display: inline-flex;
        font-size: 1.4rem;
        font-weight: 600;
        gap: 0.8rem;
        justify-content: center;
        outline: none !important;
        padding: 1.2rem 2.4rem;
        transition: 0.2s;
        width: fit-content;

        &:focus,
        &:hover {
            background-color: #4598ab;
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
`;

export const Texts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    p {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.8rem;
        margin: 0;
    }

    .title {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 1.2;
    }
    .descr {
        font-weight: 700;
    }

    .highs {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
    }
`;

export const Buttons = styled.div`
    display: flex;
    gap: 1.6rem;
    margin-top: 2.4rem;
`;
