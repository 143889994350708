import styled from 'styled-components';
import { positions } from 'utils/styles';

export const StartupMatch = styled.div`
    border-radius: 0.8rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
    overflow: hidden;
    position: relative;
`;

export const StartupMatchColumn = styled.div`
    align-items: center;
    background: #fff;
    display: flex;
    flex: 1 1 calc(50% - 0.4rem);
    flex-direction: column;
    gap: 1.6rem;
    justify-content: space-between;
    padding: 2.4rem;

    @media (min-width: 576px) {
        flex: 1 1 calc(33.333% - 0.4rem);
    }
    @media (min-width: 992px) {
        flex: 1 1 calc(20% - 0.4rem);
    }

    .c-match-item {
        font-size: 4.8rem;
        height: 6.2rem;
    }
`;

export const StartupMatchOverlap = styled.div`
    align-items: center;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: center;
    position: absolute;
    ${positions(0, 0, 0, 0)}
`;

export const Term = styled.div`
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    white-space: nowrap;
    width: auto;
`;

export const Text = styled.div`
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4;
`;
