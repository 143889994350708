import React from 'react';
import { IonApp, IonContent } from '@ionic/react';

import * as Styled from './StyledAppTemplate';

export const AppTemplate = props => (
    <Styled.AppTemplate name={props.name}>
        <Styled.AppTemplateApp as={IonApp}>
            <Styled.AppTemplateContent as={IonContent}>
                <Styled.Container>{props.children}</Styled.Container>
            </Styled.AppTemplateContent>
        </Styled.AppTemplateApp>
    </Styled.AppTemplate>
);
export default AppTemplate;
