import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchFeedback';

export const MatchFeedback = ({ match }) => {
    const { t } = useCXI();
    const feedback = match.evaluation?.startupFeedback;

    const feedbackStatus = feedback?.startupFeedback ?? feedback;

    let ttip = '';
    let icon = '';
    if (feedbackStatus === 'positive') {
        icon = 'fa-laugh';
        ttip = 'Muito relevante';
    } else if (feedbackStatus === 'neutral') {
        icon = 'fa-meh';
        ttip = 'Indiferente';
    } else if (feedbackStatus === 'negative') {
        icon = 'fa-frown';
        ttip = 'Pouco relevante';
    }

    // Pending Startup Feedback
    if (icon === '' && match.evaluation?.group) {
        ttip = t('match.feedback.pending');
        icon = 'fa-user-clock';
    }

    // Pending Startup Feedback because Evaluation is old.
    if (!match.isCxi && match.evaluation?.group && !match.stages?.evaluated) {
        ttip = t('match.feedback.resubmit');
        icon = 'fa-exclamation-triangle';
    }

    return (
        <Styled.MatchFeedback className={feedbackStatus}>
            {!icon ? (
                <span className="text">-</span>
            ) : (
                <span className={`icon far ${icon}`} aria-hidden="true" />
            )}
            {!!ttip && <span className="ttip">{ttip}</span>}
        </Styled.MatchFeedback>
    );
};
