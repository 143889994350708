import styled from 'styled-components';

export const Support = styled.div`
    .m-container-body {
        color: ${({ theme }) => theme.colors.black}
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
    }
    .m-container-feet {
        flex-direction: column;
        gap: 1.2rem;
    }

    .component-list {
        margin-top: -0.8rem;
        width: 100%;

        @media (min-width: 768px) {
            margin-top: -1.2rem;
        }
    }
    .list-vertical-item-link {
        border-color: #45aaf2;
        color: #45aaf2;
        font-weight:600;

        .icon.fa-sac {
            color: #45aaf2;
        }
        .icon.fa-whatsapp {
            color: #45aaf2;
        }
        .icon.fa-facebook-messenger {
            color: #45aaf2;
        }
        .icon.fa-envelope-open,
        .icon.fa-envelope-open ~ * {
            color:#45aaf2;
        }
    }
`;
