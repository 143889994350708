import React from 'react';

/**
 * React hook to debounce value attribution
 *
 * @param value the value to be debounced
 * @param delay (in ms) to switch back to initial state once debounced.
 */
export const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debounced, setDebounced] = React.useState(value);

    React.useEffect(() => {
        let handle = null;
        if (value) {
            handle = window.setTimeout(() => {
                setDebounced(value);
            }, delay);
        }
        return () => {
            if (handle) window.clearTimeout(handle);
        };
    }, [value, delay]);

    return debounced;
};

export default useDebounce;
