import styled from 'styled-components';

export const CXIProfile = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;
    margin-top: 6rem;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }
`;

export const Title = styled.h1`
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
`;

export const Descr = styled.h1`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 1.6rem 0 0;
`;

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    button {
        margin: auto;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .link {
        word-break: break-all;
    }
`;

export const Link = styled.p`
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 1.2rem 0 0;
`;
