import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Alert from 'components/alert';

export function NextStep(props) {
    const { steps, useRankingEcosystem } = props;
    const { relationships, corpEcosystemRelationships, application } = useRankingEcosystem.vars;

    const { t } = useTranslation('ranking');

    // Create the alert element props
    const alert = {
        // Show texts informations
        title: t('NextStep.title'),
        // Build the button
        buttn: {
            href: '/ranking',
            text: ''
        },
        // Setup color and presentation
        type: 'primary'
    };

    // Get the first false step
    const step = steps.filter(s => {
        return s.isCompleted === false;
    })[0];

    if (step === undefined) {
        if (!application.applied) {
            alert.type = 'success';
            alert.icons = 'far fa-check-circle';
            alert.title = t('NextStep.completeEcosystem.title');
            alert.descr = t('NextStep.completeEcosystem.descr');
            alert.buttn = [
                {
                    href: '/ranking/latam/ecosystem/step1',
                    type: 'light',
                    text: t('NextStep.completeEcosystem.apply')
                },
                {
                    href: '/ranking/latam/ecosystem/step3',
                    text: t('NextStep.moreRelationships')
                }
            ];
        } else {
            alert.type = 'success';
            alert.icons = 'far fa-check-circle';
            alert.title = t('NextStep.success.title');
            alert.descr = t('NextStep.success.descr');
            alert.buttn = [
                {
                    href: '/ranking/latam/ecosystem/step3',
                    text: t('NextStep.moreRelationships')
                },
                {
                    href: '/ranking/latam/ecosystem/step4',
                    text: t('NextStep.moreCorpRelationships')
                }
            ];
        }
    } else {
        alert.buttn = {
            href: step.href,
            text: step.text
        };
    }

    // Filter only pending relationships

    const pendingStartup = relationships.filter(
        i =>
            i.company.status === 'pending' &&
            i.status === 'pending' &&
            i.startup.status === 'confirmed'
    );

    const pendingCompany = corpEcosystemRelationships.filter(
        i =>
            i.ecosystem.status === 'pending' &&
            i.status === 'pending' &&
            i.company.status === 'confirmed'
    );

    // Check for relationships
    if (steps[2].isCompleted && pendingStartup && pendingStartup.length > 0) {
        alert.buttn = {
            href: '/ranking/latam/ecosystem/step3',
            text: steps[2].text
        };
        alert.title = t('NextStep.pendingRelationships.titleStartup');
        alert.descr = t('NextStep.pendingRelationships.descr');
        alert.icons = 'far fa-exclamation-circle';
        alert.type = 'warning';
    }

    // step[1] = dados individuais
    // step[2] = startups apoiadas
    // step[3] = corporações apoiadas

    if (steps[3].isCompleted && pendingCompany && pendingCompany.length > 0) {
        alert.buttn = {
            href: '/ranking/latam/ecosystem/step4',
            text: steps[3].text
        };
        alert.title = t('NextStep.pendingRelationships.titleCorp');
        alert.descr = t('NextStep.pendingRelationships.descr');
        alert.icons = 'far fa-exclamation-circle';
        alert.type = 'warning';
    }

    return <Alert.Alert {...alert} />;
}
export default NextStep;
