import styled from 'styled-components';

export const MenuLang = styled.div.attrs({
    className: 'menu-lang',
})`
    float: right;
`;

export const MenuLangButton = styled.button.attrs({
    className: 'menu-lang-button',
})`
    background: transparent;
    border: 0;
    color: inherit;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 3rem;
    margin: 0 0 0 0.5rem;
    padding: 0;
    text-align: right;
    text-transform: uppercase;
    width: 3rem;
    -webkit-appearance: none;
`;
