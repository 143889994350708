import styled from 'styled-components';

export const FormContent = styled.div.attrs({
    className: 'form-content',
})`
    .c-page-descr {
        color: var(--primary);
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
    }
`;

export const Main = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
    }
`;
export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    gap: 20px;

    .csv {
        height: 45px;
    }
    @media (max-width: 768px) {
        gap: 10px;
        flex-direction: column;
    }
`;

export const AddAndShare = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    * {
        flex-wrap: nowrap;
    }
    .add {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: 1px solid white;
        height: 45px;
    }
    .share {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 45px;
    }

    img {
        width: 300px;
        height: 300px;
    }
`;
export const Startup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    align-items: center;
    gap: 15px;

    img {
        object-fit: contain;
        background: #fff no-repeat 50% 50%;
        background-image: url('${props => props.img}');
        background-size: contain;
        background-origin: content-box;
        border: 2px solid #ddd;
        border-radius: 50%;
        height: 10.8rem;
        padding: 0.8rem;
        position: relative;
        width: 10.8rem;
    }

    span {
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
    }
`;

export const ContactsPagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: black;
    padding-top: 30px;
    font-weight: 700;
    gap: 30px;
    max-width: 100%;
    margin-top: 3rem;
    margin-bottom: 2rem;

    ul {
        display: flex;
        gap: 30px;
        padding: 0;
        list-style: none;
        margin: 0;
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    i {
        cursor: pointer;
        color: #0094f9;
        transition: 0.5s ease all;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 27px;
        height: 27px;
        &:hover {
            color: white;
            border-radius: 100px;
            transform: scale(1.5);
            transition: 0.5s ease all;
            background-color: #0094f9;
        }
    }

    b {
        background-color: #0094f9;
        width: 27px;
        height: 27px;
        color: white;
        text-align: center;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.5s ease all;

        &:hover {
            transform: scale(1.3);
            color: white;
            border-radius: 100px;
            width: 27px;
            height: 27px;
            transform: scale(1.3);
            transition: 0.5s ease all;
            background-color: #b1b1b1;
        }

        &.active {
            background-color: #0094f9;
            color: white;
            border-radius: 100px;
            width: 27px;
            height: 27px;
            transform: scale(1.3);
            transition: 0.5s ease all;
        }
    }
`;

export const ContactsSection = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    padding-bottom: 2rem;
    @media (max-width: 768px) {
        gap: 5px;
    }
`;

export const Bg = styled.div`
    width: 200vw;
    position: absolute;
    top: 0;
    left: -50vw;
    right: 0;
    bottom: 0;
    background-color: rgb(242, 242, 242);
`;
export const NoContacts = styled.p`
    text-align: center;
    margin-top: 2rem;
`;
