import React from 'react';

import { useConversionTable } from 'hooks';

import * as Styled from './StyledCXIDashboardStartupSummaries';

export const CXIChallengeStartupSummariesTechnology = props => {
    const { startup, t } = props;

    const conv = useConversionTable(['yesNo']);
    const data = startup?.demographics.techStatus;

    return (
        <>
            <Styled.CXIChallengeStartupSummariesTechnology>
                {!!data.institution && (
                    <>
                        <dt>{t('startup.institution')}</dt>
                        <dd>{data.institution}</dd>
                    </>
                )}

                {!!data.hasPatent && (
                    <>
                        <dt>{t('startup.hasPatent')}</dt>
                        <dd>{conv.yesNo[data.hasPatent === 1]}</dd>
                    </>
                )}
            </Styled.CXIChallengeStartupSummariesTechnology>

            <Styled.CXIChallengeStartupSummariesTechnology>
                {!!data.hasPatent && data.patentWhere && (
                    <>
                        <dt>{t('startup.patentWhere')}</dt>
                        <dd>{data.patentWhere}</dd>
                    </>
                )}

                {!!data.hasPatent && data.patentWho && (
                    <>
                        <dt>{t('startup.patentWho')}</dt>
                        <dd>{data.patentWho}</dd>
                    </>
                )}

                {!!data.hasPatent && data.patentHowMuch && (
                    <>
                        <dt>{t('startup.patentHowMuch')}</dt>
                        <dd>{data.patentHowMuch}</dd>
                    </>
                )}
            </Styled.CXIChallengeStartupSummariesTechnology>
        </>
    );
};
