import styled from 'styled-components';

export const ProfileIndex = styled.div.attrs({
    className: 'styled-profile-index',
})`
    text-align: center;

    .cff-EditAvatar {
        margin-top: -2.7rem;
    }

    .c-page-title {
        /* margin-top: 1rem;
        min-height: 0; */
    }

    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444;
    }
`;
