/* eslint-disable no-underscore-dangle */
import React from 'react';
import Moment from 'moment';

import { Link } from 'react-router-dom';
import { iframeLink } from 'core/iframe';

import * as Styled from './StyledPostCard';

function getPostCategory(post) {
    if (!post._embedded['wp:term']) {
        return undefined;
    }
    let category = '';
    post._embedded['wp:term'][0].forEach(t => {
        if (t.taxonomy === 'category') {
            category = t.name;
        }
    });
    return category;
}

function getPostImage(post, size) {
    const image = {
        url: '', // Add a default image path
        alt: '',
    };
    if (
        post._embedded &&
        post._embedded['wp:featuredmedia'] &&
        post._embedded['wp:featuredmedia'][0] &&
        post._embedded['wp:featuredmedia'][0]
    ) {
        const media = post._embedded['wp:featuredmedia'][0];
        image.url = media.source_url;
        image.alt = media.alt_text;
        if (
            size &&
            media.media_details &&
            media.media_details.sizes &&
            media.media_details.sizes[size]
        ) {
            const sized = media.media_details.sizes[size];
            image.url = sized.source_url;
        }
    }
    return image;
}

export function PostCard(props) {
    const data = props.data || props.news || props.post;

    const momentDate = Moment(data.date);
    const formatDate = momentDate.format('DD/MM/YYYY');

    // Get the image url
    let imageSizeMobile = 'thumbnail';
    let imageSizeDesktp = 'whow-home-main-featured-mobile';
    if (props.type && props.type === 'featured') {
        imageSizeMobile = 'medium_large';
        imageSizeDesktp = 'full';
    }
    const imageMobile = getPostImage(data, imageSizeMobile);
    const imageDesktp = getPostImage(data, imageSizeDesktp);
    const category = getPostCategory(data);

    // Create a post card props controler
    const propsPostCard = {
        as: Link,
        to: iframeLink(data.guid.rendered),
        type: props.type,
    };
    const propsCardImage = {
        desktop: imageDesktp.url,
        mobile: imageMobile.url,
        alt: imageDesktp.alt,
    };
    return (
        <Styled.PostCard {...propsPostCard}>
            <Styled.CardImage {...propsCardImage} />
            <Styled.CardHoldr>
                <span className="badge badge-light">{category}</span>
                <span className="title">{data.title.rendered}</span>
                <span className="dated">{formatDate}</span>
            </Styled.CardHoldr>
        </Styled.PostCard>
    );
}
export default PostCard;
