import React from 'react';

import { Area } from '../components/area/Area';
import { Progress } from '../components/progress';
import { useCXIDashboardStartup } from '../useCXIDashboardStartup';

import * as Styled from './StyledCXIDashboardStartupGeneral';
import { CXIDashboardStartupGeneralPresentation } from './Presentation';
import { CXIDashboardStartupGeneralSectors } from './Sectors';
import { CXIDashboardStartupGeneralDetails } from './Details';
import { CXIDashboardStartupGeneralRanking } from './Ranking';

export const CXIDashboardStartupGeneral = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    return (
        <Styled.CXIDashboardStartupGeneral>
            <CXIDashboardStartupGeneralPresentation />

            <Styled.CXIDashboardStartupGeneralSidebar>
                <Area className="details" title={t('startup.details')}>
                    <CXIDashboardStartupGeneralDetails />
                    <Progress currentStage={startup.stage} />
                </Area>
            </Styled.CXIDashboardStartupGeneralSidebar>

            <Styled.CXIDashboardStartupGeneralFullwidth>
                <CXIDashboardStartupGeneralRanking />
                <CXIDashboardStartupGeneralSectors />
            </Styled.CXIDashboardStartupGeneralFullwidth>
        </Styled.CXIDashboardStartupGeneral>
    );
};
