import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Header } from 'modules/cxi/components/header/Header';

import { Conversation } from '../components/conversation';
import * as Styled from './StyledCXIMessagesIndex';

const SearchToggle = ({ onClick }) => (
    <Styled.HeaderSearch onClick={onClick}>
        <span className="icon far fa-search" aria-hidden="true" />
    </Styled.HeaderSearch>
);

export const CXIMessagesIndex = () => {
    const { t, vars } = useCXI();
    const { matches } = vars;

    const [filter, setFilter] = React.useState('');
    const [search, setSearch] = React.useState(false);

    const handleChange = e => {
        return setFilter(e.target.value);
    };

    const filtered = matches
        .filter(i => i.lastMessage && i.lastMessage?.messageid !== undefined)
        .filter(i => i.startup.name.toLowerCase().includes(filter.toLowerCase()));

    const messages = filtered
        // .filter(i => i.lastMessage?.messageid !== undefined)\
        .sort((a, b) => {
            if (a.startup.name < b.startup.name) return -1;
            if (a.startup.name > b.startup.name) return 1;
            return 0;
        })
        .sort((a, b) => {
            if (b.lastMessage?.messageid === undefined) return -1;
            if (a.lastMessage.regDate < b.lastMessage.regDate) return 1;
            if (a.lastMessage.regDate > b.lastMessage.regDate) return -1;
            return 0;
        });

    const field = {
        className: 'c-header-input',
        placeholder: t('messages.index.search'),
        type: 'search',
        value: filter,
        onChange: handleChange,
    };

    return (
        <Styled.CXIMessagesIndex>
            <Header
                button={<SearchToggle onClick={() => setSearch(s => !s)} />}
                title={t('messages.index.title')}
            >
                {search && <input name="search" {...field} />}
            </Header>

            {messages.map(match => (
                <Styled.CXIMessagesIndexConversation key={match.matchid}>
                    <Conversation match={match} />
                </Styled.CXIMessagesIndexConversation>
            ))}
        </Styled.CXIMessagesIndex>
    );
};
