export function UrltoYoutubeEmbed(urlVideo) {
    if (urlVideo && urlVideo.includes('watch?v=')) {
        const videoFormated = urlVideo.replace('watch?v=', 'embed/').split('&');
        return videoFormated[0];
    }

    if (urlVideo && urlVideo.includes('you')) {
        const videoUrlSplited = urlVideo?.split('/');
        if (!videoUrlSplited[3]?.includes('embed/')) {
            const lastPartVideoUrl = videoUrlSplited[3]?.includes('watch?v=')
                ? `/embed/${videoUrlSplited[3]?.replace('watch?v=', '')}`
                : `/embed/${videoUrlSplited[3]}`;
            const youtubeNameUrl = videoUrlSplited[2]?.replace('youtu.be', 'youtube.com');
            const firstPartVideoUrl = `${videoUrlSplited[0]}//${videoUrlSplited[1]}${youtubeNameUrl}`;

            return `${firstPartVideoUrl}${lastPartVideoUrl}`;
        }
    }

    return undefined;
}
