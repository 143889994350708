import React from 'react';

import * as Alerts from './StyledComponent';
import * as Styled from './StyledComponentAlert';

const AlertButton = props => {
    if (props.onClick !== undefined) {
        const onClick = ev => {
            props.onClick(ev);
        };

        return (
            <Alerts.Buttn type={props.type || props.type} onClick={onClick}>
                <span className="text">{props.text}</span>
            </Alerts.Buttn>
        );
    }

    return (
        <Alerts.Buttn to={props.href || props.to} type={props.type || props.type}>
            <span className="text">{props.text}</span>
        </Alerts.Buttn>
    );
};

export const ComponentAlert = props => (
    <Styled.ComponentAlert className={props.className} type={props.type}>
        {props.icons && (
            <Alerts.Icons>
                <span className={`icon fa-fw ${props.icons}`} aria-hidden="true" />
            </Alerts.Icons>
        )}

        {props.image && (
            <Alerts.Image>
                <img src={props.image.src} alt={props.image.alt} />
            </Alerts.Image>
        )}

        {props.title && <Alerts.Title>{props.title}</Alerts.Title>}
        {props.descr && <Alerts.Descr dangerouslySetInnerHTML={{ __html: props.descr }} />}

        {props.children}

        {props.buttn && !props.buttn.length && (
            <AlertButton onClick={props.onClick} {...props.buttn} />
        )}

        {props.buttn &&
            props.buttn.length &&
            props.buttn.map(b => (
                <AlertButton key={`cab-${b.href || b.to}`} onClick={b.onClick} {...b} />
            ))}
    </Styled.ComponentAlert>
);

export const AlertRow = props => {
    return (
        <Styled.AlertBox
            className={props.className}
            type={props.type}
            color={props.color}
            bgColor={props.bgColor}
        >
            <Alerts.AlertBox flexDirection={props.flexDirection}>
                <Alerts.Icons>
                    {props.icons && (
                        <span className={`icon fa-fw ${props.icons}`} aria-hidden="true" />
                    )}
                </Alerts.Icons>

                {props.descr && <Alerts.Descr dangerouslySetInnerHTML={{ __html: props.descr }} />}

                {props.children}
            </Alerts.AlertBox>
        </Styled.AlertBox>
    );
};

export const Alert = ComponentAlert;
export default ComponentAlert;
