import { useConversionOptions } from 'hooks';
import React from 'react';
import * as Form from '../../../../components/form';

import { ButtonsForm } from '../components/buttons-form';
import { ProgressBar } from '../components/progress-bar';
import * as Styled from './StyledStep2';

export function Step2Form({ form, onSubmit, t }) {
    const CURRENT_STEP = 2;

    const conv = useConversionOptions(['contractType', 'startupEngagement', 'objOpportunities']);

    const fields = {
        name: {
            name: 'name',
            type: 'generic',
            value: form.values.name,
            onChange: form.handleChange
        },
        description: {
            name: 'description',
            type: 'textarea',
            value: form.values.description,
            onChange: form.handleChange
        },
        cse: {
            name: 'cse',
            type: 'radio',
            value: form.values.cse,
            onChange: form.handleChange,
            options: [...conv.startupEngagement]
        },
        objectives: {
            name: 'objectives',
            type: 'checkboxes',
            value: form.values.objectives,
            onChange: form.handleChange,
            options: [...conv.objOpportunities]
        },
        relationships: {
            name: 'relationships',
            type: 'checkboxes',
            value: form.values.relationships,
            onChange: form.handleChange,
            options: [...conv.contractType]
        }
    };

    const legendLinks = [
        {
            text: '[100os.net/DesafioABAL]',
            url: 'https://100os.net/DesafioABAL'
        },
        {
            text: '[100os.net/DesafioJnJ-LA-Challenge]',
            url: 'https://100os.net/DesafioJnJ-LA-Challenge'
        },
        {
            text: '[100os.net/DesafioCitrosuco]',
            url: 'https://100os.net/DesafioCitrosuco'
        },
        {
            text: '[100os.net/DesafioVotorantimEnergia]',
            url: 'https://100os.net/DesafioVotorantimEnergia'
        },
        {
            text: '[100os.net/DesafioRoche]',
            url: 'https://100os.net/DesafioRoche'
        },
        {
            text: '[100os.net/DesafioVotorantimSA].',
            url: 'https://100os.net/DesafioVotorantimSA'
        }
    ];

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Styled.Step2>
            <ProgressBar current={CURRENT_STEP} />

            <Styled.Title>{t('form.step2.title')}</Styled.Title>
            <Styled.Subtitle>{t('form.step2.descr')}</Styled.Subtitle>

            <Form.Form onSubmit={evSubmit}>
                <Styled.Questions>{t('form.step2.question1')}</Styled.Questions>
                <Form.Field {...fields.name} />

                <Styled.Questions>{t('form.step2.question2')}</Styled.Questions>
                <Form.Field {...fields.description} />
                <p className="legend-text">
                    {t('form.step2.legend1')}
                    {legendLinks.map((link, index) => (
                        <a
                            key={link.url}
                            href={link.url}
                            className="legend-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {link.text}
                            {index !== legendLinks.length - 1 && ', '}
                        </a>
                    ))}
                </p>

                <Styled.Questions>{t('form.step2.question3')}</Styled.Questions>
                <Styled.CSE>
                    <Form.Field {...fields.cse} />
                </Styled.CSE>

                <Styled.Questions>{t('form.step2.question4')}</Styled.Questions>
                <Styled.Block>
                    <Form.Field {...fields.objectives} />
                </Styled.Block>

                <Styled.Questions>{t('form.step2.question5')}</Styled.Questions>
                <Styled.Block>
                    <Form.Field {...fields.relationships} />{' '}
                </Styled.Block>

                <br />
                {/* BOTÕES FINAIS */}
                <ButtonsForm currentStep={CURRENT_STEP} />
            </Form.Form>
        </Styled.Step2>
    );
}
