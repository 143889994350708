import React from 'react';

import { useApi } from 'hooks';
import { CA } from 'contexts';

export function useSubscription() {
    const api = useApi();
    const { state } = React.useContext(CA);

    // Initial values for the state
    const initial = {
        user: {},
        eventById: {},
        challenges: [],
        selectedChallenges: [],
        startups: [],
        customForm: [],
        eventProducts: [],
        eventPayPerUseTerm: {},
        meetings: [],
        eventTimeslots: [],
        fields: {
            interestInEvent: '',
            speeddating: '',
            challengeOwner: [],
            startupOwner: [],
        },
    };

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getEventById(id) {
        return api.get(`/events/${id}`).then(event => {
            addVars({
                eventById: event,
                eventTimeslots: event.timeslots,
                fields: {
                    interestInEvent: event.user.interestInEvent,
                    speeddating: event.user.speeddating,
                },
            });
            return event;
        });
    }

    function getChallengesFromEvent(eventId) {
        return api.get(`/events/${eventId}/challenges`).then(challenges => {
            addVars({ challenges });
            return challenges;
        });
    }

    function putEventProfile(payload, eventId) {
        return api.put(`events/${eventId}/profile`, payload).then(res => {
            // Fazer inscrição nos eventos filhos com o mesmo endpoint\
            if (Array.isArray(payload.events)) {
                payload.events.map(e => {
                    const childPayload = { ...payload };
                    delete childPayload.events;
                    return api.put(`/events/${e}/profile`, childPayload);
                });
                addVars({ selectedChallenges: payload.events });
            }
            return res;
        });
    }

    function getStartups() {
        const { userid } = state.user;
        return api
            .get(`users/${userid}/startups`)
            .then(res => {
                addVars({ startups: res });
            })
            .catch(err => Promise.resolve());
    }

    function getCustomForm(eventId) {
        return api.get(`events/${eventId}/profile`).then(res => {
            addVars({ customForm: res });
        });
    }

    function getUser() {
        addVars({ user: state.user });
        return state.user;
    }

    function getEventProducts(eventId) {
        return api.get(`/events/${eventId}/storeproducts`).then(res => {
            const filter = res.filter(r => r.acf && r.acf.product_app);
            addVars({ eventProducts: filter });
            return res;
        });
    }

    function postEventPayPerUse(payload, eventId) {
        return api.post(`/events/${eventId}/payperuse`, payload).then(res => {
            return res;
        });
    }

    function getEventPayPerUse(eventId) {
        return api.get(`/events/${eventId}/payperuse`).then(res => {
            addVars({ eventPayPerUse: res });
            if (!res || (res && !res.termId)) {
                return Promise.reject();
            }
            return api.get(`/terms/${res.termId}`).then(term => {
                addVars({ eventPayPerUseTerm: term });
                return { ...res, ...term };
            });
        });
    }

    function getMeetings(eventId) {
        return api.get(`/events/${eventId}/meetings`).then(res => {
            addVars({ meetings: res });
        });
    }

    function postMeeting(timeslot, eventId) {
        const payload = {
            timeslot,
            name: 'Horário bloqueado',
        };

        return api.post(`/events/${eventId}/blockedslots`, payload).then(res => {
            addVars({ meetings: [...vars.meetings, res] });
            return res;
        });
    }

    function putMeeting(meetingId, eventId, avoidStateChange = false) {
        return api
            .put(`/events/${eventId}/meetings/${meetingId}`, { status: 'rejected' })
            .then(res => {
                if (!avoidStateChange) {
                    const filteredMeetings = vars.meetings.filter(
                        meeting => meeting._id !== meetingId
                    );
                    addVars({ meetings: filteredMeetings });
                }

                return res;
            });
    }

    return {
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Custom functions
        getEventById,
        getChallengesFromEvent,
        getStartups,
        getCustomForm,
        getUser,
        getEventProducts,
        getEventPayPerUse,
        getMeetings,
        postMeeting,
        postEventPayPerUse,
        putEventProfile,
        putMeeting,
    };
}
export default useSubscription;
