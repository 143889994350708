import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ContextGlobal as CG } from 'contexts';
import * as Styled from './StyledMenuListPages';

export function MenuListPagesItem(props) {
    const { t } = useTranslation('menu');
    const data = props.data || props.item || undefined;
    // Get the dispatch function to close menu
    const { dispatch } = React.useContext(CG);
    // Function to change the language
    const click = ev => {
        dispatch({ type: 'MENU_OPEN', menu: false });
    };
    return (
        <Styled.MenuListPagesItem as={Link} to={data.path} onClick={click}>
            {/* i18next-extract-disable-next-line */}
            <span className="text">{t(`menu.${data.name}`)}</span>
            <span className="icon far fa-fw fa-long-arrow-right" aria-hidden="true" />
        </Styled.MenuListPagesItem>
    );
}
export default MenuListPagesItem;
