import React from 'react';

import * as Styled from './StyledMatchPoints';

export const MatchPoints = ({ match }) => {
    const { cxiPoints } = match;
    return (
        <Styled.MatchPoints className={cxiPoints ? 'points' : ''}>
            <span className="text">{cxiPoints || '0'}</span>
        </Styled.MatchPoints>
    );
};
