import styled from 'styled-components';

export const CompanySubscription = styled.div`
    background: #fff3cd;
    border: 1px solid #ffecb5;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    color: #664d03;
    margin-top: 3.2rem;
    padding: 1.6rem;
    position: relative;

    strong {
        color: #523e03;
    }
`;
