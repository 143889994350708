import styled from 'styled-components';
import { positions } from 'utils/styles';

export const CXIMatches = styled.div.attrs({
    className: 'styled-cxi-matches',
})`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }
`;

export const CXIMatchesCard = styled.div`
    align-items: center;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 48rem;
    padding: 3.2rem;
    text-align: center;

    button {
        margin-top: 2.4rem;
    }
    button + button {
        margin-top: 1.6rem;
    }

    &.networklimit {
        text-align: start;
    }
`;

export const CXIMatchesCardStack = styled.div`
    position: relative;
    width: 100%;

    &::before,
    &::after {
        background: #fff;
        border-radius: 6px;
        box-shadow: 0px 0px 15px #3428680d;
        content: " ":
        display: block;
        position: absolute;
        ${positions(0, 0, 0, 0)}
        transform: rotate(5deg);
        z-index: 1;
    }
    &::after {
        transform: rotate(-5deg);
    }

    .cxi-matches-startup-card {
        position: relative;
        z-index: 3;
    }
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.4rem;
    font-weight: 600;
    margin: 0;
`;

export const Count = styled.p`
    color: ${({ theme }) => theme.colors.energyBlue};
    font-size: 1.6rem;
    font-weight: 700;
    margin: 1.6rem 0 0;
    width: 100%;
`;

export const Descr = styled.p`
    color: #6b7882;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 1.6rem 0 0;
`;
