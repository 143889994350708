import React from 'react';

import { ComponentTableRow } from './ComponentTableRow';

export const ComponentTableObject = props => {
    if (!props.data || !props.name) {
        return false;
    }

    // Transform the object into an label/value pattern
    const data = Object.keys(props.data)
        .map(i1 => {
            const ret = {
                label: i1,
                value: props.data[i1] ? props.data[i1] : 'Não Informado',
                disabled: !props.data[i1],
            };
            if (props.dataConv && props.dataConv[i1]) {
                ret.value = props.dataConv[i1][ret.value];
            }
            return ret;
        })
        .filter(i2 => {
            const i = { ...i2 };
            // Transform boolean into a string
            if (typeof i.value === 'boolean') {
                i.value = i.value.toString();
            }
            // Don't show if not a string
            if (typeof i.value !== 'string') {
                return false;
            }

            if (props.dataOnly) {
                if (props.dataOnly.includes(i.label)) {
                    return true;
                }
                return false;
            }

            if (props.dataSkip && props.dataOnly.includes(i.label)) {
                return false;
            }
            return true;
        });

    return data.map((i, k) => {
        const kk = `${props.name}-${k}-${i.label}`;
        return <ComponentTableRow key={kk} data={i} />;
    });
};
export const TableObject = ComponentTableObject;
export default ComponentTableObject;
