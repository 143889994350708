import styled from 'styled-components';

export const Navigation = styled.div`
    background: ${({ theme }) => theme.colors.lighterGray};
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    gap: 0rem;
    padding: 0.8rem 0;
    overflow-x: scroll;
    max-width: 100%;
    width: 100%;

    ::-webkit-scrollbar {
        height: 0;
        width: 0;
    }

    div:first-child {
        color: #3fa9f5;
    }
`;

export const NavigationPage = styled.div`
    align-items: center;
    border-radius: 2px;
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    padding: 0rem 1rem;
    text-transform: uppercase;
    white-space: nowrap;

    span {
        padding-bottom: 1px;
        margin-right: 5px;
    }

    &.active {
        background: ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
    }
`;
