import styled from 'styled-components';

import background from 'assets/images/bg-cxi-startup.png';

import { positions } from 'utils/styles';

export const CXIStartup = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header.main,
    .c-header.main .c-header-head {
        background: transparent;
        padding: 0;

        .c-header-back {
            color: #fff;
            font-size: 2.4rem;
        }
        .c-header-bttn {
            color: #fff;
            font-size: 1.4rem;
            opacity: 0.8;
        }
    }

    .c-header.tabs {
        position: sticky;
        top: 0;
        z-index: 100;
    }
    .c-header.tabs .c-match-action {
        margin-left: auto;
        width: auto;
    }
    .c-header.tabs .c-match-actions {
        flex: 0 0 auto;
        flex-direction: row;
        margin-left: auto;
        width: auto;
    }
`;
export const CXIStartupLayout = styled.div`
    min-height: 100%;
    position: relative;

    &::before {
        background: transparent no-repeat bottom center;
        background-image: url(${background});
        background-size: cover;
        content: ' ';
        height: 28rem;
        position: absolute;
        ${positions('0', '0', 'auto', '0')}
        width: 100%;
        z-index: -1;
    }
`;
export const HeaderBack = styled.button.attrs({
    className: 'c-header-back',
})`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    display: flex;
    flex: 0 0 auto;
    font-size: 1.8rem;
    gap: 0.8rem;
    padding: 0;
    text-align: left;
    transform: translateY(1px);
    -webkit-appearance: none;

    .text {
        font-size: 1.4rem;
        line-height: 1.2;
    }

    @media (min-width: 1200px) {
        display: none;
    }
`;
export const CXIStartupCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.6rem 0;
    z-index: 1000;

    @media (min-width: 768px) {
        gap: 3.2rem;
        padding: 3rem 0;
    }
`;

export const CXIStartupTabs = styled.div`
    margin-top: 3.2rem;
`;
