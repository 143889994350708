import React from 'react';

import * as Styled from './StyledDashboardArea';

export const DashboardArea = ({ children, graph, title, graphData }) => (
    <Styled.DashboardArea>
        {!!title && <Styled.Title>{title}</Styled.Title>}
        <Styled.Area>
            <Styled.Infos>{children}</Styled.Infos>
            {!!graph && (
                <Styled.Graph>
                    {graph}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 10
                        }}
                    >
                        <div style={{ display: 'flex', gap: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: graphData?.datasets[0].backgroundColor[0],
                                        marginRight: '8px'
                                    }}
                                />
                                <div>{graphData?.labels[0]}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: graphData?.datasets[0].backgroundColor[1],
                                        marginRight: '8px'
                                    }}
                                />
                                <div>{graphData?.labels[1]}</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: graphData?.datasets[0].backgroundColor[2],
                                        marginRight: '8px'
                                    }}
                                />
                                <div>{graphData?.labels[2]}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: graphData?.datasets[0].backgroundColor[3],
                                        marginRight: '8px'
                                    }}
                                />
                                <div>{graphData?.labels[3]}</div>
                            </div>
                        </div>
                    </div>
                </Styled.Graph>
            )}
        </Styled.Area>
    </Styled.DashboardArea>
);
