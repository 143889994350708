import styled from 'styled-components';

export const ChallengesEmpty = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 3.2rem;
    text-align: center;
`;

export const ChallengesEmptyTitle = styled.h2`
    color: rgba(0, 0, 0, 0.9);
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 1.6rem;
`;
export const ChallengesEmptyDescr = styled.h2`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0.8rem 0 1rem;
`;
