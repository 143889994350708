import React from 'react';

import { CG } from 'contexts';
import { LSP } from 'providers';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';

import { Container } from '../container';
import * as Styled from './StyledMentoringForm';

export const MentoringForm = () => {
    const { state: global } = React.useContext(CG);
    const { t, modal, setModal, vars, putMatchMentoring, postMatchMessage, putProfile } = useCXI();
    const { profile } = vars.profile;
    const { match } = modal;

    const form = useForm({
        comment: LSP.get(`${match.matchid}_MENTORING_FORM_COMMENT`) ?? '',
        linkedin: profile.linkedin || '',
    });

    const fields = {
        comment: {
            error: form.errors.comment,
            name: 'comment',
            type: 'textarea',
            label: t('modal.MentoringForm.comment'),
            required: true,
            value: form.values.comment,
            onChange: ev => {
                LSP.set(`${match.matchid}_MENTORING_FORM_COMMENT`, ev.target.value);
                return form.handleChange(ev);
            },
        },
        linkedin: {
            error: form.errors.linkedin,
            name: 'linkedin',
            type: 'text',
            label: t('modal.MentoringForm.linkedin'),
            required: true,
            value: form.values.linkedin,
            onChange: form.handleChange,
        },
    };

    const onSubmit = async values => {
        const { language } = global;
        try {
            // Save linkedin to profile if changed
            if (profile.linkedin !== values.linkedin) {
                const ProfilePayload = { ...profile, linkedin: values.linkedin };
                await putProfile(ProfilePayload);
            }

            // Set the mentoring
            const commons = { language, cxiStage: 2 };
            const MentoringPayload = {
                ...commons,
                mentoring: 'requested',
                mentoringMessage: values.comment,
            };
            await putMatchMentoring(match.matchid, MentoringPayload);

            // Send message in chat
            const messagePayload = {
                lang: language,
                text: t('modal.MentoringForm.message', {
                    message: values.comment,
                    startup: match.startup.name,
                }),
            };
            await postMatchMessage(match.matchid, messagePayload);

            LSP.del(`${match.matchid}_MENTORING_FORM_COMMENT`);
            setModal({ match, modal: 'MentoringSent' });
        } catch (e) {
            form.setSubmit(false);
        }
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    const isSubmitDisabled = form.submit || !form.values.comment || !form.values.linkedin;

    return (
        <Styled.MentoringForm as={Container}>
            <Form.Form onSubmit={evSubmit}>
                <Container.Head image={match.startup.logoUrl}>
                    <span className="text">{t('modal.MentoringForm.title')}</span>
                </Container.Head>

                <Container.Body>
                    <Styled.Descr>
                        <span className="text">{t('modal.MentoringForm.description')}</span>
                    </Styled.Descr>

                    <Styled.MentoringFormField>
                        <Form.Field {...fields.comment} />
                    </Styled.MentoringFormField>

                    <Form.Field {...fields.linkedin} />
                </Container.Body>

                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.MentoringForm.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.MentoringForm>
    );
};
