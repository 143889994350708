import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { EventCode } from '../components/event-code';

import { LoginEmailForm } from './LoginEmailForm';

export function LoginEmail({ useLogin, t }) {
    const { emitAlert } = React.useContext(CG);
    const [state, setState] = React.useState({ prelogin: false });
    const { vars, postLogin, postPrelogin } = useLogin;

    // Initialize a new form handler
    const form = useForm({
        email: vars.email || ''
    });

    // Custom onChange events
    const onChange = {
        email: () => state.prelogin && setState({ prelogin: false })
    };

    // Handle form submitions
    const onSubmitPrelogin = values => {
        const { email } = values;
        // Do the submition api request
        return postPrelogin({ email }).then(res => {
            // Redirect was found, send to the register
            if (res.redirect === '/login/reset') {
                RHP.push(`/login/reset?email=${email}`);
                emitAlert(t('LoginIndex.noPasswordWarning'));
                return false;
            }
            if (res.redirect) {
                const hasReturnUrl = !!(vars.returnUrl && vars.returnUrl !== null);
                RHP.push(
                    `${res.redirect}?email=${email}${
                        hasReturnUrl ? `&returnUrl=${vars.returnUrl}` : ''
                    }`
                );
                return false;
            }
            // Set the prelogin variables to true
            form.submited();
            setState({ prelogin: res.prelogin });
            return res;
        });
    };

    const onSubmit = values => {
        const { email, password } = values;
        // Check if the prelogin had already ran
        if (!state.prelogin) {
            return onSubmitPrelogin(values);
        }
        // Do the submition api request
        return postLogin({ email, password })
            .then(() => RHP.push(vars.returnUrl || '/home'))
            .catch(
                () =>
                    emitAlert({
                        message: t('LoginPassword.wrongPassword'),
                        header: 'Ops...'
                    }),
                form.submited()
            );
    };

    const propsForm = { form, state, onChange, onSubmit, t };
    return (
        <Page.Page name="LoginEmail" back="/login">
            <Page.Title>{t('LoginEmail.title')}</Page.Title>
            <Page.Descr>{t('LoginEmail.descr')}</Page.Descr>

            <EventCode useLogin={useLogin} />

            <LoginEmailForm {...propsForm} />

            <Page.LWTXT to="/login/reset">
                <span className="text">{t('LoginEmail.resetText')}</span>
                <span className="link">{t('LoginEmail.resetLink')}</span>
            </Page.LWTXT>
        </Page.Page>
    );
}
export default LoginEmail;
