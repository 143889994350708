import React from 'react';
import { useEffectOnce } from 'react-use';

import { useApi } from 'hooks';
import { queryParam } from 'utils/query';

import * as Styled from './StyledEventCode';

export function EventCode({ useLogin }) {
    const api = useApi();
    const [event, setEvent] = React.useState(false);

    useEffectOnce(() => {
        let { eventCode } = useLogin.vars;
        // Not found on state? Check the queryParam
        if (!eventCode) {
            eventCode = queryParam('eventcode');
        }
        // Not found? Try to get from the returnUrl param
        if (!eventCode) {
            const returnUrl = queryParam('returnUrl');
            eventCode = queryParam('eventcode', decodeURIComponent(returnUrl));
        }
        if (eventCode) {
            api.get(`/eventcode/${eventCode}`).then(res => {
                useLogin.addVars({ eventCode });
                setEvent(res);
            });
        }
    });

    return event === false ? null : (
        <Styled.EventCode>
            <span className="logo">
                <img className="img-responsive" src={event.logoUrl} alt={event.name} />
            </span>
            <span className="name">{event.name}</span>
        </Styled.EventCode>
    );
}

export default EventCode;
