import styled from 'styled-components';

export const StartupProfile = styled.div`
    align-items: center;
    background: white;
    border: 0;
    display: flex;
    gap: 1.6rem;
    text-align: left;
    margin-bottom: 10px;

    .span {
        font-weight: 700;
    }

    .user-avatar {
        flex: 0 0 6.8rem;
        width: 6.8rem;
    }
    .avatar-match {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
`;

export const Infos = styled.span`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .name {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.3;
    }
`;

export const MatchButton = styled.div`
    align-items: center;
    justify-content: center;
    background-color: #0085ff;
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 0 6px;
    height: 16px;
    cursor: pointer;
    position: absolute;

    &:hover {
        background-color: #0064d6;
    }
`;

export const AnswerButton = styled.div`
    align-items: center;
    justify-content: center;
    background-color: #56c1d6;
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 4px 0px;
    padding: 0 6px;
    height: 16px;
    cursor: pointer;
    position: absolute;

    &:hover {
        background-color: #0094f9;
    }
`;
