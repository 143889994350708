import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';

import UserAvatar from 'core/components/user/avatar/UserAvatar';

import * as Page from 'components/page';
import * as Tabs from 'components/tabs';
import * as List from 'components/list';
import * as Form from 'components/form';

import { useConversionOptions, useConversionTable } from 'hooks/useConversionTable';

import * as Styled from './StyledComponents';

export const MergerSection = props => {
    const { form } = props;
    const { vars, deleteMerger, saveEditedFields } = props.useStartupId;
    const { acquisitions } = vars.advancedForm;

    const MergerCard = ({ data }) => {
        const conv = useConversionTable(['months']);
        const convMergerType = useConversionOptions(['mergerType']);

        const acquisition = data;
        const typeText = convMergerType.mergerType.filter(
            elem => elem.value === acquisition.type
        )[0].name;

        const acquisitionDate = moment(acquisition.date).utc();

        const mm = String(conv.months[acquisitionDate.get('month') + 1]).substring(0, 3);
        const yyyy = String(acquisitionDate.get('year'));

        const date = `${mm} ${yyyy}`;

        const handleDelete = () => {
            deleteMerger(acquisition._id);
        };

        return (
            <Styled.FounderCard>
                <UserAvatar
                    user={{
                        firstName: acquisition.name,
                        // photoUrl: acquisition.data.photoUrl,
                    }}
                />
                <Styled.CardInfos>
                    <div className="card-header">
                        <span className="name">{acquisition.name}</span>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={handleDelete}
                            className="icon far fa-times"
                            aria-hidden="true"
                        />
                    </div>
                    <span className="text">{date}</span>
                    <span className="text">{typeText}</span>
                </Styled.CardInfos>
            </Styled.FounderCard>
        );
    };

    const fields = {
        wasAcquired: {
            error: form.errors.wasAcquired,
            label: props.t('MergerSection.wasAcquired'),
            name: 'wasAcquired',
            type: 'YesNo',
            value: form.values.wasAcquired,
            onChange: form.handleChange,
        },
    };

    return (
        <>
            <Page.Descr>{props.t('MergerSection.title')}</Page.Descr>
            <Form.Field {...fields.wasAcquired} />
            <Styled.TabsContainer>
                <Tabs.TabsContainer>
                    <Tabs.TabsButtons>
                        <Tabs.Tab name="Merger">{props.t('MergerSection.title')}</Tabs.Tab>
                    </Tabs.TabsButtons>

                    <List.List name="Merger">
                        <List.Vertical
                            items={acquisitions}
                            itemKey="_id"
                            itemComponent={MergerCard}
                        />
                        <Styled.BtnContainer>
                            <Page.Buttn
                                to={`/startup/${vars.startupId}/merger`}
                                type="linkd btn-block"
                                onClick={() => saveEditedFields(form.values)}
                            >
                                <span className="icon far fa-plus-circle" aria-hidden="true" />
                                <span className="text">{props.t('glossary:add')}</span>
                                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                            </Page.Buttn>
                        </Styled.BtnContainer>
                    </List.List>
                </Tabs.TabsContainer>
            </Styled.TabsContainer>
        </>
    );
};

export default MergerSection;
