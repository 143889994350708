import React from 'react';
import bg from 'assets/images/corporateHub-banner.png';
import { Link } from 'react-router-dom';
import { useApi, useUser } from 'hooks';
import { useEffectOnce } from 'react-use';
import * as Styled from './StyledCorporateHubWidget';

export function CorporateHubWidget() {
    const api = useApi();
    const user = useUser();
    const [hasHubAccess, setHasHubAccess] = React.useState(null);

    useEffectOnce(() => {
        async function getCompanyById() {
            if (!user.company.id) {
                return false;
            }
            try {
                if (user.company.id) {
                    const { id } = user.company;
                    const response = await api.get(`/companies/${id}`).then(res => {
                        setHasHubAccess(res.hasHubAccess);
                        return res;
                    });
                    return response;
                }
            } catch (err) {
                console.error(err);
            }
        }
        getCompanyById();
    }, []);

    const url = '/corporateHub';
    return hasHubAccess && user?.pocs?.includes('hub') ? (
        <Styled.CorporateHubBanner as={Link} to={url}>
            <img src={bg} alt="CorporateHub Banner" />
        </Styled.CorporateHubBanner>
    ) : null;
}

export default CorporateHubWidget;
