import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const DashboardNumber = styled.p.attrs(props => ({
    className: props.active ? 'active' : ''
}))`
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin: 0;
    font-weight: 700;
    padding: 1rem;

    &.active {
        border: 2px solid ${({ theme }) => theme.colors.blue};
        padding: 1.2rem;
        background-color: ${({ theme }) => theme.colors.blue};
        color: white;
    }
`;

export const Num = styled.span.attrs({
    className: 'dashboard-number__num'
})`
    display: block;
    color: ${({ theme }) => theme.colors.blue};
    font-size: 3rem;
    font-weight: 700;

    line-height: 1;
    ${truncate(1, 1)}

    .active & {
        color: white;
        font-size: 3.2rem;
        font-weight: 700;
    }
`;

export const Txt = styled.span`
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.4;

    strong {
        color: ${({ theme }) => theme.colors.blue};
        font-weight: 600;
    }
`;
