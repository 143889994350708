import React, { useContext, useEffect, useState } from 'react';

import * as Alert from 'components/alert';
import * as Form from 'components/form';
import * as Page from 'components/page';
import { CG } from 'contexts';
import { CompanyCard } from 'core/components/company';
import CreatableSelect from 'react-select/creatable';
import { isValidEmail } from 'utils/validation';
import * as Styled from './styles';

export function StartupStep7AddForm({ form, onSubmit, useRankingStartup, t }) {
    const { getProgramsByCompanyId, vars, addVars, getCompanyByEmail } = useRankingStartup;
    const { emitAlert } = useContext(CG);

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [renderWarning, setRenderWarning] = useState(false);
    const [companyByEmail, setCompanyByEmail] = useState(null);
    const [companyPrograms, setCompanyPrograms] = useState(null);

    useEffect(() => addVars({ searchedCompany: {} }), []);

    useEffect(() => {
        if (companyByEmail && companyByEmail.company) {
            const getProgramsOptions = async () => {
                const allCompanyPrograms = await getProgramsByCompanyId(
                    companyByEmail?.company?.id
                );

                setCompanyPrograms(allCompanyPrograms);
            };

            getProgramsOptions();
        }
    }, [companyByEmail]);

    // Create form fields object
    const fields = {
        ecosystemContactName: {
            error: form.errors.ecosystemContactName,
            label: t('ecosystemContactName'),
            name: 'ecosystemContactName',
            type: 'text',
            value: form.values.ecosystemContactName,
            onChange: form.handleChange
        },
        ecosystemContactEmail: {
            error: form.errors.ecosystemContactEmail,
            label: t('ecosystemContactEmail'),
            name: 'ecosystemContactEmail',
            type: 'email',
            value: form.values.ecosystemContactEmail,
            onChange: form.handleChange,
            onBlur: ev => {
                form.clearMessages({ name: ev.target.name });
                setCompanyPrograms(null);
                const companyEmail = ev.target.value || '';

                if (!companyEmail || !isValidEmail(ev.target.value)) {
                    addVars({ searchedCompany: {} });
                    return;
                }

                getCompanyByEmail(companyEmail).then(res => {
                    setCompanyByEmail(res);
                    if (res.company) {
                        form.values.companyName = undefined;
                    }
                    if (!res.validdomain) {
                        emitAlert(t('StartupStep8Add.domainError'));

                        form.emitError({
                            name: 'ecosystemContactEmail',
                            error: t('StartupStep8Add.domainError')
                        });
                    }
                });
            }
        },
        ecosystemConfirmationEmail: {
            error: form.errors.ecosystemContactEmail2,
            label: t('ecosystemContactEmail2'),
            name: 'ecosystemConfirmationEmail',
            type: 'email',
            value: form.values.ecosystemConfirmationEmail,
            onChange: form.handleChange
        },
        companyName: {
            error: form.errors.companyName,
            label: 'Instituição/Organização',
            name: 'companyName',
            type: 'text',
            value: form.values.companyName,
            onChange: form.handleChange,
            onlyIf: !vars.searchedCompany?.validdomain
        },
        programName: {
            error: form.errors.programName,
            label: t('glossary:programName'),
            name: 'programName',
            type: 'generic',
            options: companyPrograms?.map(item => {
                return { label: item.name, value: item._id };
            }),
            value: form.values.programName,
            onChange: ev => form.setValues(prev => ({ ...prev, programName: ev }))
        },
        description: {
            error: form.errors.description,
            label: t('glossary:description'),
            name: 'description',
            required: false,
            type: 'textarea',
            value: form.values.description,
            onChange: form.handleChange
        },
        year: {
            displayFormat: 'YYYY',
            error: form.errors.year,
            label: t('glossary:year'),
            maxLength: 4,
            name: 'year',
            type: 'text',
            value: form.values.year,
            onChange: form.handleChange
        },
        linkUrl: {
            error: form.errors.linkUrl,
            label: t('glossary:linkUrl'),
            name: 'linkUrl',
            type: 'text',
            required: false,
            value: form.values.linkUrl,
            onChange: form.handleChange
        }
    };
    // validate if email is equal, If not, renders the Alert
    const ecosystemEmail = fields.ecosystemContactEmail.value;
    const ecosystemConfirmationEmail = fields.ecosystemConfirmationEmail.value;

    useEffect(() => {
        setIsEmailValid(ecosystemConfirmationEmail === ecosystemEmail);
    }, [isEmailValid, ecosystemEmail, ecosystemConfirmationEmail]);

    const propsAlert = {
        descr: t('glossary:emailConfirmationError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };

    // Handle form submition
    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();

        if (!form.values.programName || !form.values.programName.value) {
            emitAlert(t('EcosystemStep4.programNameRequired'));
            return false;
        }

        if (!isEmailValid) {
            setRenderWarning(true);
            return false;
        }

        return form.handleSubmit(ev, onSubmit);
    };

    return (
        <Form.Form name="StartupStep7Add" onSubmit={evSubmit}>
            <Form.Field {...fields.ecosystemContactName} />

            <Form.Field {...fields.ecosystemContactEmail} />

            {vars.searchedCompany?.company && (
                <Page.Boxed style={{ marginTop: '2rem' }}>
                    {!!Object.keys(vars.searchedCompany.company).length && (
                        <CompanyCard {...vars.searchedCompany} />
                    )}
                </Page.Boxed>
            )}

            <Form.Field {...fields.ecosystemConfirmationEmail} />
            {renderWarning && <Alert.AlertRow {...propsAlert} />}

            <Form.Field {...fields.companyName} />
            <Page.Ruler />

            <Styled.ProgramLabel>{t('glossary:programName')}</Styled.ProgramLabel>
            <CreatableSelect
                placeholder={t('glossary:programNameSelect')}
                options={fields.programName.options}
                styles={{
                    control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: '#ddd'
                    }),
                    menu: baseStyles => ({
                        ...baseStyles,
                        zIndex: 91
                    })
                }}
                onChange={fields.programName.onChange}
                value={fields.programName.value}
            />

            {/* <Form.Field {...fields.programName} /> */}
            <Form.Row>
                <Form.Field size="4" {...fields.year} />
                <Form.Field size="8" {...fields.linkUrl} />
            </Form.Row>
            <Form.Field {...fields.description} />

            <Form.Submt {...form} text={t('glossary:continue')} />
        </Form.Form>
    );
}
