import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const Metrics = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 4.8rem;
`;

export const MetricsItems = styled.div`
    display: grid;
    flex: 1 1 auto;
    gap: 1.6rem;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const MetricsItem = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.whiteDark};
    border-radius: 0.8rem;
    display: flex;
    flex: 1 1 auto;
    gap: 1.6rem;
    padding: 1.6rem;
    position: relative;

    @media (min-width: 768px) and (max-width: 1199px) {
        flex-direction: column;
        text-align: center;
    }

    .icon {
        align-items: center;
        color: ${({ theme }) => theme.colors.energyBlue};
        display: flex;
        flex: 0 0 4.4rem;
        font-size: 3.2rem;
        justify-content: center;
        height: 4.4rem;
        width: 4.4rem;
    }
    .cont {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        height: 100%;
        justify-content: center;
    }

    .text {
        color: ${({ theme }) => theme.colors.darkBlue};
        display: block;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }
    .numb {
        color: ${({ theme }) => theme.colors.energyBlue};
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }

    .ttip {
        cursor: help;
        position: absolute;
        ${positions('0.4rem', '0.8rem', 'auto', 'auto')}

        .ttip-icon {
            opacity: 0.5;
        }
        .ttip-text {
            background: ${({ theme }) => theme.colors.darkGray};
            border-radius: 0.8rem;
            box-shadow: 0px 2px 12px #34286812;
            color: ${({ theme }) => theme.colors.white};
            display: none;
            font-size: 1.2rem;
            line-height: 1.2;
            padding: 0.8rem 1rem;
            position: absolute;
            ${positions(0, 0, 'auto', 'auto')}
            width: 21rem;
        }

        &:focus,
        &:hover {
            .ttip-text {
                display: block;
            }
        }
    }

    .stage-1 > & {
        .icon,
        .numb {
            color: ${({ theme }) => theme.colors.stage1};
        }
    }
    .stage-2 > & {
        .icon,
        .numb {
            color: ${({ theme }) => theme.colors.stage2};
        }
    }
    .stage-3 > & {
        .icon,
        .numb {
            color: ${({ theme }) => theme.colors.stage3};
        }
    }
`;

export const MetricsLink = styled(MetricsItem).attrs({
    as: 'button',
})`
    border: none;
    text-align: left;
`;

export const StartupImage = styled.div.attrs({
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 2px solid #ddd;
    border-radius: 50%;
    flex: 0 0 4.4rem;
    height: 4.4rem;
    padding: 0.4rem;
    position: relative;
    width: 4.4rem;
`;
