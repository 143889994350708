import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const ChallengeCard = styled.div.attrs({
    className: 'cxi-challenges-card',
})`
    align-items: center;
    background: #fff;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: grid;
    grid-template-columns: 0.3fr 1fr 0.6fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: start;
    padding: 2rem !important;

    @media (max-width: 768px) {
        grid-template-columns: 0.3fr 1fr 0.5fr;
        font-size: 1rem;
        margin: 0;
        height: auto;

        .body {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
`;

export const Buttons = styled.div.attrs({
    className: 'cxi-challenges-card-buttons',
})`
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    button {
        width: 250px;
        flex: 1;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        button {
            width: 120px;
            flex: 1;
            font-size: 0.9rem;
            margin-bottom: 10px;
            padding: 1rem 1rem;
        }
    }
`;

export const Image = styled.div.attrs({
    className: 'user-company-card-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 4px solid #ddd;
    border-radius: 50%;
    flex: 0 0 14rem;
    width: 11rem;
    height: 11rem;

    @media (max-width: 768px) {
        width: 7rem;
        height: 7rem;
        margin-right: 15px;
    }
`;

export const Title = styled.strong`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
    ${truncate(2, 1.2)}
    @media (max-width: 768px) {
        font-size: 1.5rem;
        margin: 0;
    }
`;

export const Label = styled.div`
    margin: 1.2rem 0 1.2rem;
    position: relative;
    width: 100%;

    .text {
        background: ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
        border-radius: 1.2rem;
        font-size: 1.3rem;
        margin-right: 10px;
        font-weight: 600;
        padding: 0.3rem 0.8rem 0.4rem;
    }

    @media (max-width: 768px) {
        margin: 2px;

        .text {
            margin-right: 0px;
            font-size: 1rem;
        }
    }
`;

export const Descr = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 2.4rem 0 0;
    margin-top: 15px;
    text-align: justify;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const IconWithText = styled.p`
    align-items: baseline;
    color: ${({ theme }) => theme.colors.darkerBlue};
    display: flex;
    gap: 0.8rem;
    font-size: 1.2rem;
    font-weight: 400;
    margin-right: 5px;
    margin: 0.5rem 0 0;

    .icon {
        color: ${({ theme }) => theme.colors.deepBlue};
    }
    & + & {
        margin-top: 0.4rem;
    }

    p {
        font-weight: 400;
        font-size: 1rem;
    }

    @media (max-width: 768px) {
        display: initial;
    }
`;
