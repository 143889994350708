import React, { useEffect } from 'react';

import * as Form from 'components/form';
import { useForm } from 'components/form';
import * as Page from 'components/page';
import { RHP } from 'providers';

export function CorporateStep5({ useRankingCorporate, t }) {
    const { vars, updateSteps, putUserRankingApplication } = useRankingCorporate;
    const { steps, interests } = vars.application;

    // const user = useUser();

    // useEffect(() => {
    //     if (!user.pocs.some((e: any) => ['operational', 'hub', 'senior'].includes(e))) {
    //         RHP.replace('/ranking/latam/corporate/');
    //     }
    // }, []);

    // Update the step has completed if relationships exist
    useEffect(() => {
        if (steps.step4 && !steps.step5) {
            updateSteps({ step5: true });
        }
    }, []);

    const form = useForm({
        ...interests
    });

    const fields = {
        trophy: {
            // @ts-ignore
            error: form.errors.trophy,
            label: t('CorporateStep5.topic1Check1'),
            name: 'trophy',
            type: 'checkbox',
            required: false,
            value: form.values.trophy,
            onChange: form.handleChange
        },
        institutionalPresence: {
            // @ts-ignore
            error: form.errors.institutionalPresence,
            label: t('CorporateStep5.topic2Check1'),
            // helper:
            //     'inclui benefícios de exposição no evento, de visibilidade pós-evento e de acesso (1 C-Level + 3 convidados)',
            name: 'institutionalPresence',
            type: 'checkbox',
            required: false,
            value: form.values.institutionalPresence,
            onChange: form.handleChange
        },
        // singleTicket: {
        //     // @ts-ignore
        //     error: form.errors.singleTicket,
        //     label: 'Lista de Espera Convite Avulso',
        //     helper:
        //         'limitado a um convite por empresa, exclusivo para C-Level e disponibilizado apenas em caso de vagas remanescentes',
        //     name: 'singleTicket',
        //     type: 'checkbox',
        //     required: false,
        //     value: form.values.singleTicket,
        //     onChange: form.handleChange
        // },
        mediaKit: {
            // @ts-ignore
            error: form.errors.mediaKit,
            label: t('CorporateStep5.topic2Check2'),
            name: 'mediaKit',
            type: 'checkbox',
            required: false,
            value: form.values.mediaKit,
            onChange: form.handleChange
        },
        previousSectorialReport: {
            // @ts-ignore
            error: form.errors.previousSectorialReport,
            label: t('CorporateStep5.topic3Check1'),
            name: 'previousSectorialReport',
            type: 'checkbox',
            required: false,
            value: form.values.previousSectorialReport,
            onChange: form.handleChange
        },
        nextSectorialReport: {
            // @ts-ignore
            error: form.errors.nextSectorialReport,
            label: t('CorporateStep5.topic3Check2'),
            name: 'nextSectorialReport',
            type: 'checkbox',
            required: false,
            value: form.values.nextSectorialReport,
            onChange: form.handleChange
        }
    };

    // Check for previous step conclusion
    if (!steps || steps.step1 !== true) {
        RHP.replace('/ranking/latam/corporate/');
        return null;
    }

    const onSubmit = async (values: any) => {
        try {
            const payload = {
                ...vars.application,
                interests: { ...values }
            };

            payload.steps.step5 = true;

            console.log(payload);
            const response = await putUserRankingApplication(payload);

            RHP.push('/ranking/latam/corporate');
            return response;
        } catch (err) {
            console.error(err);
        } finally {
            form.submited();
        }
    };

    // @ts-ignore
    const evSubmit = (ev: any) => form.handleSubmit(ev, onSubmit);

    return (
        <Page.Page name="CorporateStep5" customBackText={t('glossary:backToRanking')}>
            <Page.Title>{t('CorporateStep5.title')}</Page.Title>
            <Page.Descr>{t('CorporateStep5.description')}</Page.Descr>

            <br />
            <Page.Ruler />

            <Form.Form name="CorporateStep5Form" onSubmit={evSubmit}>
                <Page.Title>{t('CorporateStep5.topic1Title')}</Page.Title>
                <Page.Descr>{t('CorporateStep5.topic1Description')}</Page.Descr>
                <Form.Field {...fields.trophy} />

                <Page.Ruler />

                <Page.Title>{t('CorporateStep5.topic2Title')}</Page.Title>
                <Page.Descr>{t('CorporateStep5.topic2Description')}</Page.Descr>
                <Form.Field {...fields.institutionalPresence} />

                {/* <Form.Field {...fields.singleTicket} /> */}
                <Form.Field {...fields.mediaKit} />

                <Page.Ruler />

                <Page.Title>{t('CorporateStep5.topic3Title')}</Page.Title>
                <Page.Descr>{t('CorporateStep5.topic3Description')}</Page.Descr>
                <Form.Field {...fields.previousSectorialReport} />
                <Form.Field {...fields.nextSectorialReport} />

                <Page.Ruler />

                <div style={{ display: 'flex', gap: 10 }}>
                    <Page.Buttn
                        style={{ width: '20%' }}
                        // @ts-ignore
                        to="/ranking/latam/corporate/step4"
                    >
                        <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                        <span className="text">{t('glossary:back')}</span>
                    </Page.Buttn>

                    <div style={{ width: '80%' }}>
                        <Form.Submt text={t('glossary:save')}>
                            <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                        </Form.Submt>
                    </div>
                </div>
            </Form.Form>
        </Page.Page>
    );
}
