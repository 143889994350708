import React from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { LoginPasswordForm } from './LoginPasswordForm';

export function LoginPassword(props) {
    const { vars, postNewPassword } = props.useLogin;

    // Initialize a new form handler
    const form = useForm({
        email: vars.email || '',
        pincode: vars.pincode || '',
        resetcode: vars.resetcode || '',
        returnUrl: vars.returnUrl || '',
    });

    // Check if the e-mail variable is filled
    // If not, then redirect to the reset page
    if (!form.values.email || (!form.values.pincode && !form.values.resetcode)) {
        // console.log('Redirecting...', 'No e-mail found in the state');
        RHP.replace(`/login/reset`);
        return null;
    }

    // Custom onChange events
    const onChange = {
        newpassword: ev => {
            const error = props.t('glossary:passwordCheckError');
            const name = 'newpasswordCheck';
            form.hasEqualValues(ev, 'newpassword', { name, error });
        },
        newpasswordCheck: ev => {
            const error = props.t('glossary:passwordCheckError');
            form.hasEqualValues(ev, 'newpassword', { error });
        },
    };

    // Handle form submition
    const onSubmit = values => {
        const { email, newpassword, pincode, resetcode } = values;
        // Do the submition api request
        return postNewPassword({ email, newpassword, pincode, resetcode })
            .then(() => RHP.push(vars.returnUrl || '/home'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onChange, onSubmit };
    return (
        <Page.Page name="LoginPassword" back="/login/reset">
            <Page.Title>{props.t('LoginPassword.title')}</Page.Title>
            <Page.Descr>{props.t('LoginPassword.descr')}</Page.Descr>

            <LoginPasswordForm {...propsForm} />
        </Page.Page>
    );
}
export default LoginPassword;
