import styled from 'styled-components';

export const CXIVideos = styled.div.attrs({
    className: 'cxi-videos',
})`
    margin-top: 4rem;
`;

export const CXIVideosLink = styled.a.attrs({
    className: 'cxi-videos-link',
    target: '_blank',
})`
    align-items: center;
    border-bottom: 1px solid #ccc;
    display: flex;
    font-size: 1.5rem;
    justify-content: space-between;
    padding: 6px 0;
    button {
        background: #45aaf2;
        border: 0;
        border-radius: 20px;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        padding: 0.7rem 1.5rem 0.6rem;
        text-transform: uppercase;
    }
    button:disabled {
        background: #ccc;
    }
`;
