import React, { useState, useEffect } from 'react';
import * as Form from 'components/form';
import * as Page from 'components/page';
import { useLocation } from 'react-router-dom';
import { Switch, Checkbox } from '@mui/material';

import * as Styled from './Styles';
import CompanyCard from '../components/company/CompanyCard';

function ConfigForm(props) {
    const {
        form,
        onSubmit,
        formatDateToApiCall,
        company,
        searchCompany,
        getHubCompanyById
    } = props;

    const [showHeader, setShowHeader] = useState(form.values.displayHeader);
    const [searchedCompany, setSearchedCompany] = useState(company);
    const [isCustomDate, setIsCustomDate] = useState(false);
    const { state } = props;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    let [companyId] = query.getAll('companyId');

    useEffect(() => {
        if (!companyId) {
            setSearchedCompany(company);
        }
    }, [company, companyId]);

    useEffect(() => {
        async function fetchCompanyByIdFromParams() {
            const fetchedCompany = await getHubCompanyById(companyId);
            return setSearchedCompany(fetchedCompany);
        }
        if (companyId) {
            fetchCompanyByIdFromParams();
        }
    }, [companyId]);

    const handleSwitchChange = () => {
        setShowHeader(!showHeader);
        form.setValues(v => ({
            ...v,
            displayHeader: !showHeader
        }));
    };
    // Date handler
    const currentDate = new Date();
    const currentYear = currentDate.getUTCFullYear();
    const currentMonth =
        String(currentDate.getUTCMonth() + 1).length === 1
            ? `0${currentDate.getUTCMonth() + 1}`
            : String(currentDate.getUTCMonth() + 1);
    const currentDay =
        String(currentDate.getUTCDate()).length === 1
            ? `0${currentDate.getUTCDate()}`
            : String(currentDate.getUTCDate());

    const onChange = {
        searchedCompany: ev => {
            if (ev.target.value.length > 0) {
                searchCompany(ev.target.value).then(res => {
                    return setSearchedCompany(res);
                });
            }
        }
    };

    const fields = {
        startDate: {
            displayFormat: 'DD-MM-YYYY',
            error: form.errors.startDate,
            label: props.t('glossary:startDate'),
            maxLength: 4,
            name: 'startDate',
            disabled: !isCustomDate,
            min: `${String(currentYear - 10)}-${currentMonth}-${currentDay}`,
            max: form.values.endDate || `${String(currentYear)}-${currentMonth}-${currentDay}`,
            required: false,
            type: 'date',
            value: form.values.startDate,
            onChange: form.handleChange,
            clearButton: true
        },

        endDate: {
            displayFormat: 'DD-MM-YYYY',
            error: form.errors.endDate,
            label: props.t('glossary:endDate'),
            maxLength: 4,
            name: 'endDate',
            disabled: !form.values.startDate,
            min:
                form.values.startDate || `${String(currentYear - 2)}-${currentMonth}-${currentDay}`,
            max: `${String(currentYear + 10)}-${currentMonth}-${currentDay}`,
            required: false,
            type: 'date',
            value: form.values.endDate,
            onChange: form.handleChange,
            clearButton: true
        },
        // DISPLAY OPTIONS
        displayMode: {
            error: form.errors.displayMode,
            label: `Selecione o modo de exibição do Dashboard`,
            name: 'displayMode',
            required: true,
            type: 'radio',
            options: [
                { name: 'Modo Interativo', value: 'interactive' },
                { name: 'Modo Automático', value: 'exhibition' }
            ],
            value: form.values.displayMode,
            onChange: form.handleChange
        },

        // DISPLAY OPTIONS FOR EXHIBITION MODE
        startupsApplied: {
            error: form.errors.startups,
            label: `Startups`,
            name: 'startups',
            required: false,
            type: 'checkbox',
            value: form.values.startups,
            onChange: form.handleChange
        },
        relationshipsApplied: {
            error: form.errors.relationships,
            label: `Relacionamentos`,
            name: 'relationships',
            required: false,
            type: 'checkbox',
            value: form.values.relationships,
            onChange: form.handleChange
        },
        challengesApplied: {
            error: form.errors.challenges,
            label: `Desafios`,
            name: 'challenges',
            required: false,
            type: 'checkbox',
            value: form.values.challenges,
            onChange: form.handleChange
        },
        executivesApplied: {
            error: form.errors.executives,
            label: `Executivos`,
            name: 'executives',
            required: false,
            type: 'checkbox',
            value: form.values.executives,
            onChange: form.handleChange
        },
        groupsApplied: {
            error: form.errors.group,
            label: `Grupo Empresarial`,
            name: 'group',
            required: false,
            type: 'checkbox',
            value: form.values.group,
            onChange: form.handleChange,
            disabled: !props.isHubGroup
        },
        // DISPLAY TIME
        displayTime: {
            error: form.errors.displayTime,
            label: `Intervalo entre a transição de telas em segundos`,
            name: 'displayTime',
            type: 'number',
            value: form.values.displayTime,
            onChange: form.handleChange
        },
        // selectCompany
        searchedCompany: {
            error: form.errors.searchedCompany,
            label: 'Procure a empresa pelo nome',
            name: 'searchedCompany',
            type: 'Company',
            value: form.values.searchedCompany,
            onChange: ev => form.handleChange(ev, onChange.searchedCompany),
            required: false
        }
    };

    if (!companyId) {
        companyId = state.user.company.id;
    }

    if (fields?.searchedCompany?.value?._id) {
        companyId = fields.searchedCompany?.value?._id;
    }

    function mountQueryString() {
        let startDate = '';
        if (fields.startDate.value) {
            startDate = formatDateToApiCall(fields?.startDate?.value);
        }
        let endDate = '';
        if (fields.endDate.value) {
            endDate = formatDateToApiCall(fields?.endDate?.value);
        }
        const displayMode = fields.displayMode.value;
        const visibleHeader = showHeader; // DELETAR ESSA VARIAVEL DEPOIS E USAR STATE DIRETO
        const displayTime = fields.displayTime.value;
        const viewOptions = [
            { name: 'startup', display: fields.startupsApplied.value },
            { name: 'executives', display: fields.executivesApplied.value },
            { name: 'challenges', display: fields.challengesApplied.value },
            { name: 'relationships', display: fields.relationshipsApplied.value },
            { name: 'group', display: fields.groupsApplied.value }
        ];
        const filteredViewOptions = viewOptions?.filter(el => {
            return el.display === true;
        });

        let viewQueryOptions = '';
        for (const view of filteredViewOptions) {
            viewQueryOptions += `+${view.name}`;
        }

        const mountedQueryForShare = `play/startups?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}&displayHeader=${visibleHeader}&displayMode=${displayMode}&displayTime=${displayTime}&viewOptions=${viewQueryOptions}&includeGroup=${true}`;
        return mountedQueryForShare;
    }

    const queryConfig = mountQueryString();

    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();
        form.values.companyId = companyId;
        form.values.queryConfig = queryConfig;
        return form.handleSubmit(ev, onSubmit);
    };
    useEffect(() => {
        setSearchedCompany(fields?.searchedCompany?.value);
    }, [fields?.searchedCompany?.value]);

    return (
        <Styled.FormWrapper>
            <Form.Form name="CorporateHubForm" onSubmit={evSubmit}>
                <Page.Ruler />
                <div
                    style={{
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center',
                        zoom: 1.1
                    }}
                >
                    <Checkbox
                        size="medium"
                        id="check"
                        value={isCustomDate}
                        onChange={e => {
                            setIsCustomDate(prev => !prev);
                            props.handleCleanStartDate(e);
                            props.handleCleanEndDate(e);
                        }}
                    />
                    <label htmlFor="check">Data personalizada?</label>
                </div>

                <Styled.DateFieldWrapper>
                    <div className="wrapper">
                        <Form.Field {...fields.startDate} />
                        {/* <Styled.Button onClick={props.handleCleanStartDate}>X</Styled.Button> */}
                    </div>
                    <div className="wrapper">
                        <Form.Field {...fields.endDate} />
                        {/* <Styled.Button onClick={props.handleCleanEndDate}>X</Styled.Button> */}
                    </div>
                </Styled.DateFieldWrapper>
                <Styled.DisplaySelect>
                    <Form.Field {...fields.displayMode} />
                </Styled.DisplaySelect>

                {fields.displayMode.value === 'exhibition' && (
                    <>
                        <Styled.SwitchContainer>
                            <Switch
                                color="primary"
                                checked={showHeader}
                                onChange={handleSwitchChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            Visualizar cabeçalho
                        </Styled.SwitchContainer>

                        <Styled.FieldWrapper>
                            <Form.Field {...fields.displayTime} />
                        </Styled.FieldWrapper>

                        <p>Selecione as telas que você gostaria de visualizar:</p>

                        <Styled.SelectFieldGroup>
                            <Form.Field {...fields.startupsApplied} />
                            <Form.Field {...fields.relationshipsApplied} />
                            <Form.Field {...fields.challengesApplied} />
                            <Form.Field {...fields.executivesApplied} />
                            {props.isHubGroup && <Form.Field {...fields.groupsApplied} />}
                        </Styled.SelectFieldGroup>
                    </>
                )}
                {state.tokn.admin && (
                    <>
                        <Form.Field {...fields.searchedCompany}>
                            <Page.LWTXT>
                                <span className="link">Selecione a empresa</span>
                            </Page.LWTXT>
                        </Form.Field>
                        <hr />
                        <CompanyCard data={searchedCompany} />
                    </>
                )}
                <Form.Submt
                    {...form}
                    query={queryConfig}
                    disabled={!fields.displayMode.value}
                    text="Abrir Dashboard Hub"
                />
            </Form.Form>
        </Styled.FormWrapper>
    );
}

export default ConfigForm;
