import React from 'react';
import { useClickAway } from 'react-use';
import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledOpportunity';

export const Opportunity = () => {
    const { t, modal, setModal } = useCXI();
    const { opportunity } = modal;
    const dropdownRef = React.useRef(null);

    useClickAway(dropdownRef, () => {
        setModal('');
    });

    function formatVideoLink(link) {
        if (link?.includes('watch?v=')) {
            const videoLink = link?.split('watch?v=')[1];
            return `https://www.youtube.com/embed/${videoLink}`;
        }
        if (link?.includes('youtu.be/')) {
            const videoLink = link?.split('youtu.be/')[1];
            return `https://www.youtube.com/embed/${videoLink}`;
        }
        return link;
    }

    const embeddedUrl = formatVideoLink(opportunity?.VideoURL);

    const types = opportunity?.ChallengeType?.split('|');
    return (
        <Styled.Opportunity ref={dropdownRef}>
            <Styled.OpportunityHead>
                <span className="imgWrapper">
                    <img src={opportunity.LogoURL} alt="Logo do desafio" />
                </span>

                <span>
                    {t('opportunities.challengeBy')} <b>{opportunity.StartupName}</b>
                </span>
            </Styled.OpportunityHead>

            <Styled.OpportunityBody>
                <span className="title">{opportunity.ChallengeName}</span>
                <span className="descr">{opportunity.ChallengeDescription}</span>
            </Styled.OpportunityBody>

            <Styled.OpportunityFeet>
                <Styled.BadgesWrapper>
                    {types &&
                        types?.map(type => (
                            <span className="badge" key={type}>
                                {type}
                            </span>
                        ))}
                </Styled.BadgesWrapper>
                {embeddedUrl?.includes('embed') ? (
                    <span className="videoWrapper">
                        <iframe
                            src={embeddedUrl}
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    </span>
                ) : (
                    <a
                        className="descr"
                        target="_blank"
                        href={embeddedUrl}
                        rel="noopener noreferrer"
                    >
                        {embeddedUrl}
                    </a>
                )}

                <Styled.Button
                    target="_blank"
                    rel="noreferrer noopener"
                    href={opportunity.ShareLink}
                >
                    {t('opportunities.wantToHelpButton')}
                </Styled.Button>
            </Styled.OpportunityFeet>
        </Styled.Opportunity>
    );
};
