import styled from 'styled-components';
import { positions } from 'utils/styles';

export const ListSteps = styled.ul.attrs({
    className: 'list-steps'
})`
    background: transparent;
    list-style: none;
    margin: 0;
    padding: 0;

    ion-item {
        --background: transparent;
        --border-color: transparent;
        --border-width: 0;
        --color: inherit;
        --min-height: 0;
    }
    ion-item > * {
        min-width: 100%;
    }
`;

export const ListStepsItem = styled.li.attrs({
    className: 'list-steps-item'
})`
    & + .list-steps-item .list-steps-item-link {
        padding-top: 1rem;
    }
`;

export const ListStepsItemLink = styled.a.attrs(props => {
    const type = props.type ? props.type : '';
    return { className: `list-steps-item-link ${type}` };
})`
    align-items: center;
    background: transparent;
    border: 0;
    color: #444;
    cursor: pointer;
    display: flex;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0;
    -webkit-appearance: none;

    .step {
        align-items: center;
        background: var(--primary);
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        flex: 0 0 auto;
        font-size: 2rem;
        height: 3.4rem;
        justify-content: center;
        line-height: 3.6rem;
        margin-right: 1rem;
        position: relative;
        text-align: center;
        width: 3.4rem;
    }
    .step::before {
        background: inherit;
        content: '';
        height: 2rem;
        position: absolute;
        ${positions('auto', 'auto', '-15px', '50%')}
        transform: translateX(-50%);
        width: 0.8rem;
    }

    .text {
        margin-right: auto;
        transform: translateY(0.1rem);
    }
    .icon {
        color: var(--primary);
        font-size: 2.4rem;
        margin-left: 1rem;
    }

    &.blocked {
        color: #ddd;
        .step {
            background: #ddd;
        }
        .icon {
            color: inherit;
        }
        &:active,
        &:focus,
        &:hover {
            color: #ddd;
        }
    }
    &.completed {
        .icon {
            color: var(--ion-color-success);
        }
    }
`;
