import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { useApi } from 'hooks';
import { StartupCXIContext } from './StartupCXIContext';

export function useStartupCXI(bootstrap) {
    const { t } = useTranslation('startup-cxi');
    const { vars, addVars, setVars, ...context } = useContext(StartupCXIContext);
    const api = useApi();

    // Get the startupId
    const { startupId } = vars;

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function calculateUnreadMessages(updatedMatches) {
        const matches = updatedMatches || vars.matches;
        const unreadMessages = matches.reduce((a, b) => {
            if (typeof a === 'number') return a + parseInt(b.unreadMessages, 10);
            return parseInt(a.unreadMessages, 10) + parseInt(b.unreadMessages, 10);
        }, 0);
        addVars({ unreadMessages });
        return unreadMessages;
    }

    function calculateFeedbackStage(match) {
        if (match.startupFeedback !== undefined) {
            return 'evaluated';
        }
        return 'unevaluated';
    }

    function checkMatchStage(match, stage) {
        const calculatedStage = calculateFeedbackStage(match);
        return stage === calculatedStage;
    }

    function mapFeedbackAndCalculateStage(feedback) {
        const calculatedStage = calculateFeedbackStage(feedback);
        return { ...feedback, calculatedStage };
    }

    async function removeMatchUnreadMessages(readMessages) {
        const { unreadMessages } = vars;
        addVars({ unreadMessages: unreadMessages - parseInt(readMessages, 10) });
    }

    async function getCXIFeedbacks() {
        try {
            const result = await api.get(`/startups/${startupId}/cxifeedbacks`);
            const feedbacks = result.map(mapFeedbackAndCalculateStage);
            addVars({ feedbacks });
        } catch (e) {
            throw new Error('CXI: Not able to get startup feedbacks.');
        }
    }

    async function getMatches() {
        try {
            const matches = await api.get(`/startups/${startupId}/matches`);
            calculateUnreadMessages(matches);
            addVars({ matches });
            // addVars({ matches: matches.filter(m => m.isCxi) });
        } catch (e) {
            throw new Error('CXI: Not able to get startup matches.');
        }
    }

    async function getMatchMessages(matchId) {
        try {
            const messages = await api.get(`/startups/${startupId}/matches/${matchId}/messages`);

            let prevMessage = {};
            const mappedMessages = messages.map(message => {
                const sameName = message.name === prevMessage.name;
                const sameFrom = message.from === prevMessage.from;
                if (!sameName || !sameFrom) {
                    prevMessage = message;
                    return message;
                }
                return { ...message, slim: true };
            });

            return mappedMessages;
        } catch (e) {
            throw new Error(`CXI: Not able to get startup match ${matchId} Messages.`);
        }
    }

    async function getStartupShare() {
        try {
            const startup = await api.get(`/startups/${startupId}/share`);
            return startup;
        } catch (e) {
            throw new Error(`CXI: Not able to get startup share for ${startupId} startup.`);
        }
    }

    async function postMatchMessage(matchId, payload) {
        try {
            const message = await api.post(
                `/startups/${startupId}/matches/${matchId}/messages`,
                payload
            );
            return message;
        } catch (e) {
            throw new Error(`CXI: Not able to post the message to match ${matchId}.`);
        }
    }

    async function postMatchSpeedDating(matchId, payload) {
        try {
            const newPayload = { ...payload, isCxi: true };
            await api.post(`/startups/${startupId}/matches/${matchId}/speed-datings`, newPayload);
            await getMatches();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId} speed-dating.`);
        }
    }

    async function putMatchById(matchId, payload) {
        try {
            const newPayload = { ...payload, isCxi: true };
            await api.put(`/startups/${startupId}/matches/${matchId}`, newPayload);
            if (payload.active) await getMatches();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId}.`);
        }
    }

    async function putMatchEvaluation(matchId, evaluationId, payload) {
        try {
            const newPayload = { ...payload, isCxi: true };
            await api.put(
                `/startups/${startupId}/matches/${matchId}/evaluations/${evaluationId}`,
                newPayload
            );
            await getCXIFeedbacks();
            await getMatches();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId}.`);
        }
    }

    async function putMatchMentoring(matchId, payload) {
        try {
            await api.put(`/startups/${startupId}/matches/${matchId}/mentoring`, payload);
            await getMatches();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId} mentoring.`);
        }
    }

    async function deleteMatch(matchId) {
        try {
            await api.delete(`/startups/${startupId}/matches/${matchId}`);
            await getMatches();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId}.`);
        }
    }

    useEffectOnce(() => {
        // if (!scopeAllows('100-10-1')) {
        //     RHP.replace('/home');
        //     return;
        // }
        // Initialize the module's service
        if (bootstrap) {
            Promise.all([getCXIFeedbacks(), getMatches()]).then(res => setReady(true));
        }
    });

    return {
        t,
        // Ready to load?
        ready,

        // Variables handlers
        addVars,
        setVars,
        vars,
        ...context,
        startupId,

        // Custom functions

        calculateUnreadMessages,
        calculateFeedbackStage,
        checkMatchStage,
        removeMatchUnreadMessages,

        getMatches,
        getMatchMessages,
        getStartupShare,
        postMatchMessage,
        putMatchById,
        putMatchEvaluation,
        putMatchMentoring,
        postMatchSpeedDating,
        deleteMatch,
    };
}

export default useStartupCXI;
