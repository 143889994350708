import React from 'react';

import { UrltoYoutubeEmbed } from 'utils/youtube';

import { Area } from '../components/area/Area';
// import { Tags } from '../components/tags/Tags';
import { useCXIStartup } from '../useCXIStartup';

export const CXIStartupGeneralPresentation = () => {
    const { t, vars } = useCXIStartup();
    const { startup } = vars;

    const video = UrltoYoutubeEmbed(startup.video);

    /* {!!startup.tags?.length && <Tags tags={startup.tags} />} */

    /* <p>{startup.description}</p> */
    return (
        <Area className="presentation" title={t('startup.presentation')}>
            {!!video && (
                <div className="presentation-video">
                    <iframe src={video} title={startup.name} />
                </div>
            )}
        </Area>
    );
};
