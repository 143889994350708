import styled from 'styled-components';

export const ProgressPoints = styled.div`
    align-items: stretch;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    overflow: hidden;
    position: relative;
`;

export const ProgressPointsHead = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1.6rem;

    .text {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1;
        margin: 0 auto 0 0.4rem;
    }
    .icon,
    .numb {
        color: ${({ theme }) => theme.colors.deepBlue};
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1;
    }
`;

export const ProgressPointsBody = styled.div`
    background: ${({ theme }) => theme.colors.white};
    flex: 1 1 auto;
    padding: 1.6rem;
`;
