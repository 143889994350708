// import React from 'react';
import { useEffectOnce } from 'react-use';

import { RHP } from 'providers';
import { useCXI } from '../useCXI';

export const CXIIndex = props => {
    const { checkPreferences } = useCXI();

    useEffectOnce(() => {
        // Decides where to send the user,
        // to fill the profile, or to the dashboard.
        const isValid = checkPreferences();
        if (isValid) {
            RHP.replace('/100-10-1/home');
        } else {
            RHP.replace('/100-10-1/setup/welcome/1');
        }
    });

    return null;
};
