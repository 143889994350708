import React from 'react';

import { ContextAuth as CA } from 'contexts';
import { ASCIIToDecimal } from 'utils/conversion';

import * as Styled from './StyledUserAvatar';

const UserAvatarLetters = ({ fullName, initials }) => {
    return (
        <Styled.Letters title={fullName}>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <foreignObject width="100%" height="100%">
                    <span className="text">{initials}</span>
                </foreignObject>
            </svg>
        </Styled.Letters>
    );
};

const UserAvatarHolder = props => (
    <Styled.UserAvatar {...props}>
        <Styled.Holder {...props}>{props.children}</Styled.Holder>
        {/* {props.user.products.includes('100-10-1') && <Styled.CXIIcon />} */}
    </Styled.UserAvatar>
);

export function UserAvatar(props) {
    const { state } = React.useContext(CA);
    const user = props.user || state.user;

    // Uncomment this to test the letters version
    // user.photoUrl = undefined;

    // Get the letters of the names to form the background color
    // Get the difference from '360' to add to hsl color scheme
    let initials = '';

    if (!user.firstName && !user.lastName && user.name) {
        user.firstName = user.name
            .split(' ')
            .slice(0, -1)
            .join(' ');
        user.lastName = user.name
            .split(' ')
            .slice(-1)
            .join(' ');
    }
    if (user.firstName) initials += user.firstName.substring(0, 1).toUpperCase();
    if (user.lastName) initials += user.lastName.substring(0, 1).toUpperCase();
    if (!user.lastName && user.firstName) initials += user.firstName.substring(1, 2).toUpperCase();

    const numbersHUE = ASCIIToDecimal(initials) % 360;
    user.initials = initials;

    // Add some information to the user component
    user.fullName = '';
    if (user.firstName) user.fullName += user.firstName;
    if (user.fullName && user.lastName) user.fullName += ' ';
    if (user.lastName) user.fullName += user.lastName;

    return (
        <UserAvatarHolder numbersHUE={numbersHUE} user={user}>
            {!!user.photoUrl === false && <UserAvatarLetters {...user} />}
            {!!user.photoUrl && <Styled.Image src={user.photoUrl} alt={user.fullName} />}
        </UserAvatarHolder>
    );
}
export default UserAvatar;
