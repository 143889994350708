import React from 'react';

import { Area } from '../components/area/Area';
import { useCXIStartup } from '../useCXIStartup';

import { CXIStartupSummariesTechnology } from './Technology';
import { CXIStartupSummariesResearcher } from './Researcher';

import * as Styled from './StyledCXIStartupSummaries';

export const CXIStartupSummaries = () => {
    const { t, vars } = useCXIStartup();
    const { startup } = vars;

    return (
        <Styled.CXIStartupSummaries>
            {!!startup.demographics?.techStatus && (
                <Area className="technology" title={t('startup.technology')}>
                    <CXIStartupSummariesTechnology />
                </Area>
            )}

            {!!startup.demographics?.researcher && (
                <Area className="researcher" title={t('startup.researcher')}>
                    <CXIStartupSummariesResearcher />
                </Area>
            )}
        </Styled.CXIStartupSummaries>
    );
};
