import styled from 'styled-components';
import { positions } from 'utils/styles';

export const PageRuler = styled.div.attrs(props => ({
    className: `c-page-ruler ${props.type || 'default'}`,
    role: 'separator',
}))`
    margin: 3rem 0;
    height: 2px;
    position: relative;

    &::before {
        border-color: rgba(255, 255, 255, 0.9);
        border-top: 1px dashed rgba(255, 255, 255, 0.9);
        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
        content: '';
        height: 100%;
        margin: 0;
        position: absolute;
        ${positions(0, 'auto', 'auto', '50%')}
        transform: translateX(-50%);
        width: 200%;
    }
    &.dots::before {
        border-style: solid;
        width: 100%;
    }

    .dots {
        background: var(--primary);
        border-radius: 50%;
        display: inline-block;
        height: 0.8rem;
        position: absolute;
        ${positions('50%', 'auto', 'auto', '50%')}
        transform: translate(-50%, -50%);
        width: 0.8rem;

        &::before,
        &::after {
            background: inherit;
            border-radius: inherit;
            content: '';
            height: 100%;
            margin: 0 0.6rem;
            position: absolute;
            width: 100%;
        }
        &::before {
            ${positions(0, '100%', 0, 'auto')}
        }
        &::after {
            ${positions(0, 'auto', 0, '100%')}
        }
    }

    .text {
        position: absolute;
        ${positions(0, 0, 0, '50%')}
        transform: translate('-50%', '-50%');
    }

    &.dark {
        &::before {
            border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
        }
    }
    &.solid {
        border-style: solid;
    }
`;
