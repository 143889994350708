import React from 'react';

import { iframeLink } from 'core/iframe';
import * as Page from 'components/page';

export const PagesTerms = props => (
    <Page.Page name="PagesTerms">
        <Page.Title>{props.t('PagesTerms.title')}</Page.Title>
        <Page.Descr>{props.t('PagesTerms.descr')}</Page.Descr>

        <Page.Ruler type="dots" />

        <Page.Buttn to={iframeLink('https://100os.net/terms')}>
            <span className="link">{props.t('PagesTerms.moreInfos')}</span>
            <span className="icon far fa-long-arrow-right" aria-hidden="true" />
        </Page.Buttn>
    </Page.Page>
);
export default PagesTerms;
