import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    box-shadow: 0px 2px 12px #34286812;
    padding: 18px 32px;
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const UserContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: ${({ theme }) => theme.colors.darkBlue};

    img {
        width: 60px;
        height: 60px;
    }

    strong {
        font-size: 15px;
    }

    span {
        font-size: 12px;
        opacity: 0.5;
        font-weight: bold;
    }
`;

export const Avatar = styled.div`
    border: 5px solid ${({ theme }) => theme.colors.lighterGray};
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background: ${({ theme }) => theme.colors.energyBlue};

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        object-fit: cover;
        object-position: center;
    }

    span {
        font-size: 16px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.white};
        opacity: 1 !important;
    }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    gap: 8px;

    strong {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 10px;
    }
`;

export const MessageChat = styled.div`
    flex: 1;
    background: ${({ theme }) => theme.colors.lighterGray};
    width: 100%;
    padding: 14px 24px;
    width: 100%;
    border-radius: 4px;
    position: relative;

    p {
        font-weight: bold;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.darkBlue};
    }

    &::before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        top: 10px;
        left: -8px;
        border-style: solid;
        border-width: 8px 8px 8px 0;
        border-color: transparent #f5f6fa transparent transparent;
    }
`;
