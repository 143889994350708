import React from 'react';

import { EvaluationsFeedback } from '../feedback/EvaluationsFeedback';
import { EvaluationsStartup } from '../startup/EvaluationsStartup';
import * as Styled from './StyledEvaluations';

export const Evaluations = ({ evaluations, t }) => (
    <Styled.Evaluations>
        <Styled.EvaluationsThead>
            <Styled.EvaluationsTr>
                <Styled.EvaluationsTh>
                    {t('dashboard.Evaluations.startup')} 
                    {/* ({evaluations.length}) */}
                </Styled.EvaluationsTh>
                <Styled.EvaluationsTh>{t('dashboard.Evaluations.feedback')}</Styled.EvaluationsTh>
                <Styled.EvaluationsTh>{t('dashboard.Evaluations.stage')}</Styled.EvaluationsTh>
                <Styled.EvaluationsTh>{t('dashboard.Evaluations.group')}</Styled.EvaluationsTh>
                <Styled.EvaluationsTh>{t('dashboard.Evaluations.comment')}</Styled.EvaluationsTh>
            </Styled.EvaluationsTr>
        </Styled.EvaluationsThead>

        <Styled.EvaluationsTbody>
            {evaluations.map(evaluation => {
                let stage = '100';
                if (evaluation.cxiStage === 2) stage = '10';
                if (evaluation.cxiStage === 3) stage = '1';

                return (
                    <tr key={evaluation._id}>
                        <td>
                            <EvaluationsStartup
                                date={evaluation.regDate}
                                startup={evaluation.startup}
                            />
                        </td>
                        <td>
                            <EvaluationsFeedback evaluation={evaluation} />
                        </td>
                        <td>{stage}</td>
                        <td>{evaluation.group ?? ''}</td>
                        <td> {(evaluation.comment ?? '').split(/(.{80})/).filter(Boolean).map((line, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <br />}
                                {line}
                            </React.Fragment>
                        ))}
                    </td>
                    </tr>
                );
            })}
        </Styled.EvaluationsTbody>
    </Styled.Evaluations>
);
