import React, { useState } from 'react';
import tooltip from 'assets/images/icons/tooltipRanking.png';
import * as Styled from './StyledStartupPosition';
import PositionCard from './PositionCard';

export function StartupPosition(props) {
    const { data, t } = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const { declared, confirmed, audited } = data || {};
    return (
        <Styled.Container>
            <Styled.Title>
                {t('StartupStep8.positionCardTitle')}
                <span
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <img src={tooltip} alt="tooltip icon" />
                </span>
                {showTooltip && (
                    <Styled.TitleTooltip>
                        {t('StartupStep8.positionCardTitleTooltip')}
                    </Styled.TitleTooltip>
                )}
            </Styled.Title>
            <Styled.CardContainer>
                <PositionCard
                    contracts={declared}
                    title={t('StartupStep8.declaredContracts')}
                    tooltipText={t('StartupStep8.declaredTooltipText')}
                    {...props}
                />
                <PositionCard
                    contracts={confirmed}
                    title={t('StartupStep8.confirmedContracts')}
                    tooltipText={t('StartupStep8.confirmedTooltipText')}
                    {...props}
                />
                <PositionCard
                    contracts={audited}
                    title={t('StartupStep8.auditedContracts')}
                    tooltipText={t('StartupStep8.auditedTooltipText')}
                    {...props}
                />
            </Styled.CardContainer>
            {confirmed?.upper === 0 ||
                (declared?.upper === 0 && (
                    <Styled.ZeroPointsText>
                        {t('StartupStep8.positionCardZeroPointsText')}
                    </Styled.ZeroPointsText>
                ))}
        </Styled.Container>
    );
}
export default StartupPosition;
