import { createBrowserHistory } from 'history';

// Create the HistoryProvider
export const RouterHistoryProvider = createBrowserHistory();

RouterHistoryProvider.listen((location, action) => {
    // location is an object like window.location
    // console.log(action, location.pathname, location.state);
});
export const RHP = RouterHistoryProvider;
export default RouterHistoryProvider;
