import React from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';

import { StartupCard } from '../components';

export function InvestorStep3EditForm(props) {
    const { conv, form, onSubmit } = props;

    // Create form fields object
    const fields = {
        companyName: {
            error: form.errors.companyName,
            label: props.t('investorCompany'),
            name: 'companyName',
            required: false,
            type: 'generic',
            value: form.values.companyName,
            onChange: form.handleChange,
        },

        type: {
            disabled: form.values.isConfirmed,
            error: form.errors.type,
            label: props.t('glossary:investmentType'),
            name: 'type',
            options: [
                { name: props.t('glossary:investmentTypeSelect'), value: '' },
                ...conv.investmentType,
            ],
            type: 'select',
            value: form.values.type,
            onChange: form.handleChange,
        },
        value: {
            error: form.errors.value,
            label: props.t('glossary:investmentValue'),
            name: 'value',
            required: false,
            type: 'CurrencyAmount',
            value: form.values.value,
            onChange: form.handleChange,
        },
        date: {
            disabled: form.values.isConfirmed,
            displayFormat: 'MM/YYYY',
            error: form.errors.date,
            label: props.t('glossary:date'),
            name: 'date',
            type: 'date',
            value: form.values.date,
            onChange: form.handleChange,
        },
        round: {
            error: form.errors.round,
            label: props.t('glossary:round'),
            name: 'round',
            type: 'generic',
            value: form.values.round,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="InvestorStep3Edit" onSubmit={evSubmit}>
            <Page.Boxed>
                <StartupCard {...form.values} />
                <Page.Discl>{props.t('InvestorStep3Edit.noChangeToStartup')}</Page.Discl>
            </Page.Boxed>

            <Page.Ruler />

            <Form.Field {...fields.companyName} />
            <Form.Field {...fields.type} />
            <Form.Field {...fields.value} />

            <Form.Row>
                <Form.Field size="4" {...fields.date} />
                <Form.Field size="8" {...fields.round} />
            </Form.Row>

            <Form.Submt {...form} text={props.t('glossary:save')} />
        </Form.Form>
    );
}
export default InvestorStep3EditForm;
