import React from 'react';

import blackBelt from 'assets/images/belts/selo-black-belt.png';
import brownBelt from 'assets/images/belts/selo-brown-belt.png';
import greenBelt from 'assets/images/belts/selo-green-belt.png';
import whiteBelt from 'assets/images/belts/selo-white-belt.png';

import useCXI from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';

import { RHP } from 'providers';
import { useUser } from 'hooks';

import * as Styled from './StyledBelts';

const BeltsHead = ({ t }) => (
    <Styled.BeltsHead>
        <span className="icon fal fa-award" aria-hidden="true" />
        <span className="text">{t('home.Belts.title')}</span>
    </Styled.BeltsHead>
);

export const Belts = () => {
    const user = useUser();
    const { t, vars } = useCXI();
    const { cxi } = vars;

    const { currentBelt } = cxi;

    let currentBeltImg = 'white';

    switch (currentBelt) {
        case 'black':
            currentBeltImg = blackBelt;
            break;
        case 'brown':
            currentBeltImg = brownBelt;
            break;
        case 'green':
            currentBeltImg = greenBelt;
            break;
        default:
            currentBeltImg = whiteBelt;
            break;
    }

    const handleCertificate = () => {
        RHP.push(`/100-10-1/certification/${user.userid}`);
    };

    return (
        <Styled.Belts className={`stage-${cxi.currentStage}`}>
            <BeltsHead t={t} />

            <Styled.BeltsBody>
                <img src={currentBeltImg} alt="" />
            </Styled.BeltsBody>

            <Styled.BeltsFeet>
                <Button variant="link" onClick={handleCertificate}>
                    {t('home.Belts.certification')}
                </Button>
            </Styled.BeltsFeet>
        </Styled.Belts>
    );
};
