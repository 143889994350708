import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './StyledScheduleHourDate';

export function ScheduleHourDate(props) {
    const { timeslot, onClick, blocked } = props;
    const { t } = useTranslation('subscription');

    const Dated = date => {
        return <span className="ddmm">{date.time}</span>;
    };

    return (
        <Styled.HourDate onClick={onClick} blocked={blocked}>
            <span className="icon fas fa-chevron-up" />
            <Dated {...timeslot} />
            <p className="available-text">{blocked ? '' : t('SubscriptionSchedule.available')}</p>
            <div className="lock-button">
                <p>{blocked ? t('SubscriptionSchedule.unlock') : t('SubscriptionSchedule.lock')}</p>
            </div>
        </Styled.HourDate>
    );
}
export default ScheduleHourDate;
