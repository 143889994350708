import useCXI from 'modules/cxi/useCXI';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as Styled from './StyledCXIChallengeStartupQuestions';

export const CXIChallengeStartupQuestions = props => {
    const { getEventsQuestions, vars } = useCXI();
    const { questions } = vars;
    const { challengeId } = useParams();
    const { startup } = props;

    const [answersQuestion, setAnswersQuestion] = useState([]);

    useEffect(() => {
        async function fetchQuestions() {
            await getEventsQuestions(challengeId);
            const answers = startup?.event?.answers || [];

            const question = answers.map(answer => ({
                answer: answer?.answer.join(', '),
                question: answer?.question,
                questionId: answer?.questionId
            }));

            setAnswersQuestion(question);
        }

        fetchQuestions();
    }, [challengeId, startup]);

    return (
        <Styled.CXIChallengeQuestions>
            {answersQuestion.length > 0 &&
                answersQuestion.map((answer, index) => {
                    const question = questions.find(q => q._id === answer.questionId);

                    return (
                        question && (
                            <div key={index}>
                                <strong>{question.name || question.question}</strong>
                                <p>{answer.answer}</p>
                            </div>
                        )
                    );
                })}
        </Styled.CXIChallengeQuestions>
    );
};
