import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Alert from 'components/alert';

import { EventDate } from '../../utils/EventDate';
import * as Styled from './StyledOiweekCall2Action';

const Counter = props => {
    const { t } = useTranslation('oiweek');

    const s = 1000;
    const m = s * 60;
    const h = m * 60;
    const d = h * 24;

    const date = new Date(props.date);
    const [time, setTime] = React.useState(date.getTime() - new Date().getTime());

    React.useEffect(() => {
        if (date.getTime() - new Date().getTime() < 0) {
            return undefined;
        }
        const interval = setInterval(() => setTime(date.getTime() - new Date().getTime()), s);
        return () => clearInterval(interval);
    }, [date]);

    return time <= 0 ? null : (
        <Styled.C2AInviteCounter>
            <li>
                <span className="num">{Math.floor(time / d)}</span>
                <span className="txt">{t('C2AInvite.days')}</span>
            </li>
            <li>
                <span className="num">{Math.floor((time % d) / h)}</span>
                <span className="txt">{t('C2AInvite.hours')}</span>
            </li>
            <li>
                <span className="num">{Math.floor((time % h) / m)}</span>
                <span className="txt">{t('C2AInvite.minutes')}</span>
            </li>
            <li>
                <span className="num">{Math.floor((time % m) / s)}</span>
                <span className="txt">{t('C2AInvite.seconds')}</span>
            </li>
        </Styled.C2AInviteCounter>
    );
};

export function C2AInvite(props) {
    const { t } = useTranslation('oiweek');

    // Create the alert element props
    const propsAlert = {
        title: t('C2AInvite.title'),
        descr: t('C2AInvite.descr'),
        buttn: [
            {
                href: '/oiweek/subscribe',
                text: t('C2AInvite.button'),
            },
        ],
        type: 'primary',

        color: props.color || undefined,
    };

    return (
        <Styled.C2AInvite as={Alert.Alert} {...propsAlert}>
            <EventDate {...props} />
            <Counter date={props.startDate} />
        </Styled.C2AInvite>
    );
}
export default C2AInvite;
