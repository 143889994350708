import React from 'react';

import { useParams } from 'react-router';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

import * as Page from 'components/page';
import { CONFIG } from 'config';
import { useParseDate } from 'hooks';
import { iframeLink } from 'core/iframe/IframeLink';

import * as Styled from './StyledEventsSingle';

function Icon(props) {
    return (
        <a className={props.name} href={props.href} target="_blank" rel="noopener noreferrer">
            <span className={props.className} aria-hidden="true" />
        </a>
    );
}

export function EventsSingle(props) {
    const { id } = useParams();
    const { t } = useTranslation('events');

    const { vars, getEventById } = props.useEvents;

    const { eventById } = vars;

    useEffectOnce(() => {
        getEventById(id);
    });

    const { logoUrl, name, headline, locationString, startDate, endDate } = eventById;

    const { intervalWithYear } = useParseDate({ startDate, endDate });

    // format locationString
    // Sao Paulo, BR-SP ----> Sao Paulo, SP
    const splittedLocation = locationString?.split(', ');
    let formattedLocation = '';
    if (splittedLocation) {
        const splittedState = splittedLocation && splittedLocation[1].split('-');
        formattedLocation = `${splittedLocation[0]}, ${splittedState[1]}`;
    }

    // retira <br> dos headlines
    const paragraphs = headline?.split('<br>').filter(elem => elem !== '');

    let buttonText = t('EventsSingle.soon');
    let buttonTo = `/events/${eventById._id}`;
    let disabled=true;

    if (eventById.stages?.app) {
        buttonText = t('event.checkin');
        buttonTo = iframeLink(`${CONFIG.appLinks.events}?autocheckin=${eventById._id}`);
        disabled = false;
    }

    if (
        eventById.config?.general.isOiweek &&
        !eventById.stages.app &&
        eventById.user.eventStatus !== 'registered' &&
        eventById.user.eventStatus !== 'attended'
    ) {
        buttonText = t('event.signUp');
        buttonTo = '/oiweek/subscribe';
        disabled = false;
    }

    const href = `${CONFIG.baseUrl}/events/${id}`;

    return (
        <Page.Page name="EventsSingle">
            <Styled.EventCard>
                <Styled.CardImage img={logoUrl} alt="event logo" />

                <Styled.SocialMediaContainer>
                    <Styled.IconsContainer>
                        <Styled.SocialMediaIcon>
                            <Icon
                                href={`https://www.facebook.com/sharer.php?u=${href}`}
                                name="facebook"
                                className="icon fab fa-facebook-f"
                            />
                        </Styled.SocialMediaIcon>
                        <Styled.SocialMediaIcon>
                            <Icon
                                href={`http://twitter.com/share?url=${href}`}
                                name="twitter"
                                className="icon fab fa-twitter"
                            />
                        </Styled.SocialMediaIcon>
                        <Styled.SocialMediaIcon>
                            <Icon
                                href={`http://www.linkedin.com/shareArticle?mini=true&url=${href}`}
                                name="linkedin"
                                className="icon fab fa-linkedin-in"
                            />
                        </Styled.SocialMediaIcon>
                    </Styled.IconsContainer>
                    <Styled.ShadowedText>
                        <span>{t('EventsSingle.share')}</span>
                    </Styled.ShadowedText>
                </Styled.SocialMediaContainer>

                <Styled.EventInfoContainer>
                    <Page.Title>{name}</Page.Title>

                    <Styled.AddressDateContainer>
                        <Styled.SubTextContainer>
                            <span className="icon fal fa-map-marker-alt" aria-hidden="true" />
                            <Styled.SubText>{formattedLocation}</Styled.SubText>
                        </Styled.SubTextContainer>
                        <Styled.SubTextContainer>
                            <span className="icon fal fa-calendar-alt" aria-hidden="true" />
                            <Styled.SubText>{intervalWithYear}</Styled.SubText>
                        </Styled.SubTextContainer>
                    </Styled.AddressDateContainer>

                    <Page.Button className={`btn-event ${disabled ? 'disabled' : ''}`} to={buttonTo}>
                        <span className="text">{buttonText}</span>
                        <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                    </Page.Button>
                </Styled.EventInfoContainer>

                <Styled.DescriptionContainer>
                    <Styled.DescriptionTitle>
                        {t('EventsSingle.descriptionTitle')}
                    </Styled.DescriptionTitle>
                    {paragraphs?.map(parag => (
                        <p key={parag} dangerouslySetInnerHTML={{ __html: parag }} />
                        // <p key={parag}>{parag}</p>
                    ))}
                </Styled.DescriptionContainer>
            </Styled.EventCard>
        </Page.Page>
    );
}
export default EventsSingle;
