import React from 'react';

import * as List from 'components/list';

import { FormField } from '../field/FormField';
import * as Styled from './StyledFormAutocomplete';

// Handle the timeout interval
let timeout;

export function FormAutocomplete(props) {
    const { field, initialValue, items, itemsFetch, itemKey, itemComponent } = props;

    // Manage some modal states
    const [shows, setShows] = React.useState(false);
    const [value, setValue] = React.useState(field.value);

    React.useEffect(() => {
        setValue(field.value);
    }, [initialValue, field.value]);

    function handleClick(val, callback) {
        setShows(false);
        return callback(val);
    }

    function handleChange(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        // Show list on change
        setShows(true);

        // Get the current target
        const { target } = ev;
        // Updates the state, so the field value is updated
        setValue(target.value);
        // Create timeout interval to debounce entry
        clearTimeout(timeout);
        timeout = setTimeout(() => itemsFetch(target.value), 500);
        return false;
    }

    // Modify the items onClick to the handled by autocomplete
    const altItems = items.slice(0, 10).map(r => ({
        ...r,
        onClick: () => handleClick(r.full, r.onClick),
    }));

    // Create the field with the search
    const propsSearch = {
        ...field,
        name: `search-${field.name}`,
        type: 'text',
        value,
        onChange: handleChange,
    };

    return (
        <Styled.FormAutocomplete shows={shows}>
            {/* Fake form field to show/hide the modal */}
            <FormField {...propsSearch} />

            {shows && altItems && (
                <Styled.FormAutocompleteList>
                    <List.Vertical
                        items={altItems}
                        itemKey={itemKey}
                        itemComponent={itemComponent}
                    />
                    {React.Children.map(props.children, child => {
                        if (child && child.props && child.props.onClick) {
                            return React.cloneElement(child, {
                                onClick: ev => {
                                    ev.persist();
                                    child.props.onClick(ev, value);
                                    setShows(false);
                                    setValue('');
                                    return true;
                                },
                            });
                        }
                        return child;
                    })}
                </Styled.FormAutocompleteList>
            )}

            {/* {state && <Modal.Modal {...propsModal} />} */}
            {/* Autocomplete is a little complex, please pay attention. */}
        </Styled.FormAutocomplete>
    );
}
export default FormAutocomplete;
