import React from 'react';

import { CONFIG } from 'config';
import { CA, CG, CN } from 'contexts';
import { useApi } from 'hooks';
import { queryParam } from 'utils/query';
import { isValidEmail } from 'utils/validation';

export function useRegister() {
    const { state, dispatch } = React.useContext(CA);
    const { globalState } = React.useContext(CG);
    const { notifyState } = React.useContext(CN);
    const api = useApi();

    // Initial values for the state
    const initial = {
        baseUrl: `${CONFIG.baseUrl}/register/password`,
        referralcode: queryParam('referralcode'),
        resetcode: queryParam('resetcode'),
        returnUrl: queryParam('returnUrl'),
        // User overwrites
        email: queryParam('email'),
        location: globalState.location,
        ...state.user
    };

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * HANDLE FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const handleAuth = (auth, user) => {
        const data = { auth, user };
        dispatch({ type: 'AUTHENTICATE', ...data });
        return user;
    };

    const handlePrelogin = res => {
        if (!res.password || (!res.password && !res.user)) {
            return false;
        }
        return true;
    };

    const handleRegister = auth => {
        // Build headers with authentication token
        const headers = { headers: { api_key: auth.token } };
        // Get user informations and authenticate the user
        return api.get(`/users/${auth.userid}`, headers).then(user => {
            // Check if notification is avaliable
            const { notificationToken } = notifyState;
            if (!notificationToken) return handleAuth(auth, user);
            // Update the notificationToken on user authentication
            return api
                .put(`/users/${auth.userid}/devices`, { notificationToken }, headers)
                .finally(handleAuth(auth, user));
        });
    };

    const handleUser = ({ userid }) => {
        return api.get(`/users/${userid}`).then(user => {
            addVars(user);
            dispatch({ type: 'UPDATE_USER_INFO', user });
            return true;
        });
    };

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getCompanyDomain(email) {
        // E-mail must be valid to get the domain
        if (!isValidEmail(email)) {
            return Promise.reject();
        }

        const domain = email.replace(/.*@/, '');
        return api.get(`/companydomain/${domain}`).then(res => {
            addVars({ company: res });
            return res;
        });
    }
    function getCompanyById(companyId) {
        const id = companyId || state.user.company.id;
        return api.get(`/companies/${id}`).then(res => {
            addVars({ company: res });
            return res;
        });
    }

    function postCompany(payload) {
        // Save the user domain from payload
        const domain = payload.domain || (payload.email && payload.email.replace(/.*@/, '')) || '';
        const newPayload = { ...payload, domain };
        return api.post(`/companies`, newPayload).then(res => {
            addVars({ company: res });
            return res;
        });
    }
    function postPrelogin(payload) {
        return api.post(`/prelogin`, payload).then(res => {
            return handlePrelogin(res);
        });
    }
    function postUser(payload) {
        // Save the user domain from payload
        const newPayload = { ...payload, domain: payload.email.replace(/.*@/, '') };
        return api.post(`/users`, newPayload).then(res => {
            // TEMP_FIX - Will @ 01/10/2019
            // Set '_id' to 'userid' so the auth can be more similar to the Register response
            // We do this so the auth can be generated equally from both endpoints
            const auth = { ...res, userid: res._id };
            addVars(newPayload);
            return handleRegister(auth);
        });
    }

    function putUser(payload) {
        const { userid } = state.auth;
        return api.put(`/users/${userid}`, payload).then(res => {
            addVars(payload);
            return handleUser(res);
        });
    }

    return {
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Custom functions
        getCompanyDomain,
        getCompanyById,
        postCompany,
        postPrelogin,
        postUser,
        putUser
    };
}
export default useRegister;
