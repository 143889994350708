import React from 'react';
import { useEffectOnce } from 'react-use';

import { CA } from 'contexts';
import { useApi, useUser } from 'hooks';
import { queryParam } from 'utils/query';

// import { useApi } from 'hooks';

export function useRankingEcosystem() {
    const { state } = React.useContext(CA);
    const api = useApi();
    const user = useUser();

    // Initial values for the state
    const initial = {
        application: {
            applied: false,
            steps: {},
            interests: {}
        },
        relationships: [],
        validate: false,
        ranking: {
            name: '2025ecosystem',
            year: '2025',
            country: 'BR',
            id: '2025ecosystem'
        },
        searchedCompany: {},
        companyDetails: {}
    };

    // Check for queryStrings
    if (
        queryParam('relationshipId') &&
        queryParam('relationshipResponse') &&
        queryParam('lastUpdated')
    ) {
        initial.validate = {
            _id: queryParam('relationshipId'),
            status: queryParam('relationshipResponse'),
            lastUpdated: queryParam('lastUpdated')
        };
    }

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    // /////////////////////////////////
    // RANKING APPLICATION FUNCTIONS  //
    // /////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function getCompanyDetails() {
        const companyId = user.company.id;

        const companyDetails = await api.get(`/companies/${companyId}`);

        addVars({ companyDetails });

        return companyDetails;
    }

    async function getCompanyPocs() {
        try {
            const companyId = user.company.id;

            const companyPocs = await api.get(`/companies/${companyId}/pocs`);

            let { pocsRequest } = vars;

            if (!pocsRequest?.length) {
                pocsRequest = companyPocs.pocs.flatMap(poc => {
                    const uniquePocs = Array.from(new Set(poc.pocs));
                    return uniquePocs
                        .filter(e => !e.includes('hub'))
                        .map(pocItem => ({
                            name: poc.fullName,
                            email: poc.email,
                            department: poc.evaluatorProfile?.department || 'N/A',
                            position: poc.evaluatorProfile?.position || 'N/A',
                            type: 'keep',
                            status: 'confirmed',
                            poc: pocItem,
                            photoUrl: poc.photoUrl
                        }));
                });
            }

            addVars({ companyPocs, pocsRequest });
            return companyPocs;
        } catch (e) {
            console.error(e);
            throw new Error('RankingCorporate: Not able to get companyPocs.');
        }
    }

    async function getProgramsByCompanyId(companyId) {
        const response = await api.get(`/companies/${companyId}/programs`);
        return response;
    }

    async function getUserRankingApplication() {
        const { userid } = state.user;

        const application = await api.get(
            `/users/${userid}/rankingapplications/${vars.ranking.name}`
        );

        if (Object.keys(application).length) {
            addVars({ application });
            return application;
        }

        return null;
    }

    function putUserRankingApplication(payload, updateVars = true) {
        const { userid } = state.user;
        return api
            .put(`/users/${userid}/rankingapplications/${vars.ranking.name}`, payload)
            .then(res => {
                // console.log('useRankingInvestor', 'putUserRankingApplication', res);
                if (updateVars) addVars({ application: res });
                return res;
            });
    }

    async function postProgramByCompanyId(programName, companyId) {
        const payload = { name: programName };

        const response = await api.post(`/companies/${companyId}/programs`, payload);

        return response;
    }

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const updateSteps = update => {
        // Get the current application variable
        const { application } = vars;
        application.steps = { ...application.steps, ...update };
        addVars({ application });
        return putUserRankingApplication({ ...application }, false);
    };

    // /////////////////////////////////////
    // RELATIONSHIP VALIDATION FUNCTIONS  //
    // /////////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function putCompanyDetails(payload, addResponseToVars = true) {
        const companyId = user.company.id;

        const companyDetails = await api.put(`/companies/${companyId}`, payload);

        if (addResponseToVars) {
            addVars({ companyDetails });
        }

        return companyDetails;
    }

    function getUserRelationships() {
        const { userid } = state.auth;
        return api.get(`/users/${userid}/ecosystemrelationships`).then(res => {
            addVars({ relationships: res });
            return res;
        });
    }
    function getUserRelationshipById(investmentId) {
        const { userid } = state.auth;
        return api.get(`/users/${userid}/ecosystemrelationships/${investmentId}`).then(res => {
            // console.log('getUserRelationshipById', investmentId, res);
            return res;
        });
    }

    function postUserRelationship(payload) {
        // {
        //     "date": "2019-07-01",
        //     "type": "Angel",
        //     "round": "Seed 2",
        //     "value": {
        //         "currency": "BRL",
        //         "amount": 20000,
        //     "currency": "USD",
        //     "startupId": "5afc879a0dce2d0eaba9f32a",
        //     "startup": {
        //         "name" : "Opa Opa ola ola voces",
        //         "contact": {
        //             "name": "Felipe Vignon",
        //             "email" : "felipe.feliposa@gmail.com"
        //         }
        //     },
        //     "linkUrl": "https://linkao.com.br",
        //     "rankings": {
        //         "2020": true
        //     }
        // }
        const { userid } = state.auth;
        return api.post(`/users/${userid}/ecosystemrelationships`, payload).then(res => {
            return getUserRelationships(res);
        });
    }

    function putUserRelationship(payload) {
        // {
        //     "date": "2019-07-01",
        //     "type": "Angel",
        //     "round": "Seed 2",
        //     "vaue": {"currency": "USD", "amount": 20000 },
        //     "linkUrl": "https://linkao.com.br",
        //     "rankings": { "2020": false }
        // }
        const { userid } = state.auth;
        const { _id } = payload;
        return api.put(`/users/${userid}/ecosystemrelationships/${_id}`, payload).then(res => {
            return getUserRelationships(res);
        });
    }

    function deleteUserRelationship(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api.delete(`/users/${userid}/ecosystemrelationships/${_id}`).then(res => {
            return getUserRelationships(res);
        });
    }

    function postUserRelationshipValidation(payload) {
        // {
        //     "status": "confirmed",
        //     "lastUpdated": "2020-03-09T20:32:44.701Z"
        // }
        const { userid } = state.auth;
        const { _id } = payload;
        return api
            .post(`/users/${userid}/ecosystemrelationships/${_id}/validation`, payload)
            .then(res => {
                return getUserRelationships(res);
            });
    }

    function postUserRelationshipValidationEmail(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api.post(`/users/${userid}/ecosystemrelationships/${_id}/sendvalidationemail`);
    }

    // /////////////////////////////////////
    // CORP-ECOSYSTEM RELATIONSHIP FUNCTIONS  //
    // /////////////////////////////////////
    // GET /users/:userid/corpecosystemrelationships/
    function getUserCorpEcosystemRelationships() {
        const { userid } = state.auth;
        return api
            .get(`/users/${userid}/corpecosystemrelationships`, {
                params: { entity: 'ecosystem' }
            })
            .then(res => {
                addVars({ corpEcosystemRelationships: res });
                return res;
            });
    }

    async function getCompanyByEmail(companyEmail) {
        const response = await api.get(`/companyfromemail/${companyEmail}`);

        // addVars({ searchedCompany: response });
        return response;
    }

    // POST /users/:userid/corpecosystemrelationships
    function postCorpEcosystemRelationship(payload) {
        const { userid } = state.auth;
        return api.post(`/users/${userid}/corpecosystemrelationships/`, payload).then(res => {
            return getUserCorpEcosystemRelationships(res);
        });
    }

    function postCorpEcosystemRelationshipValidation(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api
            .post(`/users/${userid}/corpecosystemrelationships/${_id}/validation`, payload)
            .then(res => {
                return getUserCorpEcosystemRelationships(res);
            });
    }
    function postCorpEcosystemRelationshipValidationEmail(payload) {
        const { userid } = state.auth;
        const { _id } = payload;

        return api.post(
            `/users/${userid}/corpecosystemrelationships/${_id}/sendvalidationemail`,
            payload,
            {
                params: { entity: 'ecosystem' }
            }
        );
    }

    function putCorpEcosystemRelationship(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api
            .put(`/users/${userid}/corpecosystemrelationships/${_id}`, payload, {
                params: { entity: 'ecosystem' }
            })
            .then(res => {
                return getUserCorpEcosystemRelationships(res);
            });
    }

    async function postPocsRequest({ pocsRequest }) {
        try {
            const response = await api.post(
                `/companies/${user.company.id}/pocrequest`,
                pocsRequest
            );
            return response;
        } catch (e) {
            throw new Error('RankingCorporate: Not able to post pocsRequest.');
        }
    }

    function deleteCorpEcosystemRelationship(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api
            .delete(`/users/${userid}/corpecosystemrelationships/${_id}`, {
                data: { entity: 'ecosystem' }
            })
            .then(res => {
                return getUserCorpEcosystemRelationships();
            });
    }

    useEffectOnce(() => {
        // Validate investiment if avaliable
        // Validation moved to useRanking()

        // Initialize the module's service
        Promise.all([
            getUserRankingApplication(),
            getUserRelationships(),
            getUserCorpEcosystemRelationships(),
            getCompanyPocs(),
            getCompanyDetails()
        ]).then(res => setReady(true));
    });

    return {
        // Ready to load?
        ready,
        // Variables handlers
        vars,

        addVars,
        deleteCorpEcosystemRelationship,
        deleteUserRelationship,
        getCompanyByEmail,
        getCompanyDetails,
        getCompanyPocs,
        getProgramsByCompanyId,
        getUserCorpEcosystemRelationships,
        getUserRankingApplication,
        getUserRelationshipById,
        getUserRelationships,
        postCorpEcosystemRelationship,
        postCorpEcosystemRelationshipValidation,
        postCorpEcosystemRelationshipValidationEmail,
        postPocsRequest,
        postProgramByCompanyId,
        postUserRelationship,
        postUserRelationshipValidation,
        postUserRelationshipValidationEmail,
        putCompanyDetails,
        putCorpEcosystemRelationship,
        putUserRankingApplication,
        putUserRelationship,
        setVars,
        updateSteps
    };
}
export default useRankingEcosystem;
