import React from 'react';

import { Header } from 'modules/cxi/components/header/Header';

import { useStartupCXI } from '../../useStartupCXI';
import { FilterTag } from '../components/filter-tag/FilterTag';
import { Matches } from '../components/matches/Matches';
import * as Styled from '../components/StyledNetworkPage';

export const CXIFeedbacksEvaluated = () => {
    const { t, vars } = useStartupCXI();
    const feedbacks = vars.feedbacks.filter(match => match.calculatedStage === 'evaluated');

    const [filter, setFilter] = React.useState([]);
    const hasFilter = value => filter === value;

    const updFilter = value => {
        if (hasFilter(value)) {
            return setFilter('');
        }
        return setFilter(value);
    };

    const [stage, setStage] = React.useState(0);
    const hasStage = value => stage === value;

    const updStage = value => {
        if (hasStage(value)) {
            return setStage('');
        }
        return setStage(value);
    };

    let filtered = feedbacks;

    if (filter.length) {
        filtered = filtered.filter(match => filter.includes(match.startupFeedback));
    }
    if (stage) {
        filtered = filtered.filter(match => {
            if (stage === 2 && match.cxiStage < 3) return true;
            if (stage === 3 && match.cxiStage > 2) return true;
            return false;
        });
    }

    return (
        <Styled.NetworkPage>
            <Styled.NetworkPageHead>
                <h2>{t('feedbacks.evaluated.title')}</h2>
                <h3>({feedbacks.length} Feedbacks)</h3>
            </Styled.NetworkPageHead>

            <Header className="filter">
                {t('feedbacks.filter')}
                <FilterTag active={hasStage(2)} onClick={() => updStage(2)}>
                    <span className="text">de Avaliador</span>
                </FilterTag>
                <FilterTag active={hasStage(3)} onClick={() => updStage(3)}>
                    <span className="text">de Mentor</span>
                </FilterTag>
                e
                <FilterTag active={hasFilter('positive')} onClick={() => updFilter('positive')}>
                    <span className="text">Positive</span>
                </FilterTag>
                <FilterTag active={hasFilter('neutral')} onClick={() => updFilter('neutral')}>
                    <span className="text">Neutral</span>
                </FilterTag>
                <FilterTag active={hasFilter('negative')} onClick={() => updFilter('negative')}>
                    <span className="text">Negative</span>
                </FilterTag>
            </Header>

            <Styled.NetworkPageBody>
                <Matches matches={filtered} t={t} />
            </Styled.NetworkPageBody>
        </Styled.NetworkPage>
    );
};
