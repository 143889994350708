import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledProgressPoints';

export const ProgressPoints = () => {
    const { t, vars } = useCXI();
    const { cxi } = vars;

    return !cxi.metrics.points ? null : (
        <Styled.ProgressPoints className={`stage-${cxi.currentStage}`}>
            <Styled.ProgressPointsHead>
                <span className="icon fal fa-medal" aria-hidden="true" />
                <span className="text">{t('home.ProgressPoints.title')}</span>
                <span className="numb">{cxi.metrics.points}</span>
            </Styled.ProgressPointsHead>
        </Styled.ProgressPoints>
    );
};
