import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ContextGlobal as CG } from 'contexts';
import * as Styled from './StyledMenuUserLogout';

export function MenuUserLogout(props) {
    const { t } = useTranslation('glossary');
    // Get the dispatch function to close menu
    const { dispatch } = React.useContext(CG);
    // Function to change the language
    const click = ev => {
        dispatch({ type: 'MENU_OPEN', menu: false });
    };
    return (
        <Styled.MenuUserLogout as={Link} to="/logout" onClick={click}>
            <span className="text">{t('logout')}</span>
            <span className="icon far fa-sign-out" aria-hidden="true" />
        </Styled.MenuUserLogout>
    );
}
export default MenuUserLogout;
