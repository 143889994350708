import styled from 'styled-components';

export const EventCode = styled.div.attrs({
    className: 'event-code',
})`
    align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: #222;
    display: flex;
    margin-top: 2rem;
    padding: 1rem;

    .name {
        flex: 0 0 60%;
        font-size: 1.2rem;
        font-weight: 600;
        padding-left: 2rem;
    }
    .logo {
        flex: 0 0 40%;
    }
    .logo img {
        height: auto;
        max-width: 100%;
        width: auto;
    }
`;
