import { RHP } from 'providers';
import React from 'react';
import * as Styles from './styles';

interface ButtonFormProps {
    currentStep: number;
}

export function ButtonsForm({ currentStep }: ButtonFormProps) {
    return (
        <Styles.ButtonsContainer>
            <Styles.Button
                disabled={currentStep === 1}
                type="button"
                onClick={() => RHP.push(`/100-10-1/form/${currentStep - 1}`)}
            >
                <i className="far fa-chevron-left" />
            </Styles.Button>

            <Styles.Button type="submit" style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                {currentStep !== 5 ? 'AVANÇAR' : 'FINALIZAR'}
                <i className="far fa-chevron-right" />
            </Styles.Button>
        </Styles.ButtonsContainer>
    );
}
