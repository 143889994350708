import React from 'react';

import { useForm } from 'components/form';
import * as Page from 'components/page';

import { RHP } from 'providers';
import { FounderAddForm } from './FounderAddForm';

export function FounderAdd(props) {
    const { vars, postFounder } = props.useStartupId;

    // Initialize a new form handler
    const form = useForm({});

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            email: form.values.founderEmail,
            fullName: form.values.founderName,
            phone: form.values.phone,
            linkedin: form.values.linkedin,
        };
        // Add relationship
        return postFounder(payload)
            .then(() => RHP.push(`/startup/${vars.startupId}/advanced-edit`))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="FounderAdd">
            <Page.Title>{props.t('FounderAdd.title')}</Page.Title>

            <FounderAddForm {...propsForm} />
        </Page.Page>
    );
}
export default FounderAdd;
