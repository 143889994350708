import React from 'react';

import { RHP } from 'providers';
import * as Styled from './StyledHeader';

export const Header = ({
    back = false,
    button,
    ButtonComponent,
    children,
    title,
    tooltip,
    ...props
}) => (
    <Styled.Header {...props}>
        <Styled.HeaderHead style={!back && !title ? { display: 'none' } : {}}>
            {!!back && (
                <>
                    <Styled.HeaderBack onClick={() => RHP.goBack()}>
                        <span className="icon far fa-arrow-left" aria-hidden="true" />
                        <span className="text sr-only">Voltar</span>
                    </Styled.HeaderBack>

                    <Styled.HeaderClose onClick={() => RHP.goBack()}>
                        <span className="icon far fa-times" aria-hidden="true" />
                        <span className="text">Fechar</span>
                    </Styled.HeaderClose>
                </>
            )}

            {!!title && <span className="text">{title}</span>}

            {!!tooltip && (
                <span className="ttip">
                    <i className="ttip-icon far fa-question-circle" aria-hidden="true" />
                    <i className="ttip-text">{tooltip}</i>
                </span>
            )}

            {!!button && button.label && (
                <Styled.HeaderHeadButton onClick={button.onClick}>
                    <span className="bttn-text">{button.label}</span>
                    <span className={`bttn-icon far ${button.icon}`} aria-hidden="true" />
                </Styled.HeaderHeadButton>
            )}

            {!!button && !button.label ? button : null}
        </Styled.HeaderHead>

        {!!children && <Styled.HeaderBody>{children}</Styled.HeaderBody>}
    </Styled.Header>
);
