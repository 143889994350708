import { useForm } from 'components/form';
import * as Page from 'components/page';
import { CA, CG } from 'contexts';
import { RHP } from 'providers';
import React, { useContext, useState } from 'react';

import { EcosystemStep3AddForm } from './EcosystemStep3AddForm';

export function EcosystemStep3Add(props) {
    const { emitAlert, state } = useContext(CG);
    const { language } = state;

    const {
        state: { user }
    } = useContext(CA);

    const {
        vars,
        postUserRelationship,
        getProgramsByCompanyId,
        postProgramByCompanyId
    } = props.useRankingEcosystem;

    const { ranking } = vars;

    const currDate = new Date();

    const [skip, setSkip] = useState(false);

    // Initialize a new form handler
    const form = useForm({
        year: currDate.getFullYear(),
        otherContact: false
    });

    // Custom onChange events
    const onChange = {
        startup: () => {
            setSkip(false);
            form.emitError({ name: 'startup', error: undefined });
        }
    };

    // Handle form submitions
    const onSubmit = async values => {
        try {
            let companyPrograms = [];
            let program = null;

            if (user?.company.id) {
                companyPrograms = await getProgramsByCompanyId(user?.company.id);

                program = companyPrograms?.find(e => e?._id === values.programName.value);

                if ((values.programName.__isNew__ || !program) && !values?.startupName) {
                    program = await postProgramByCompanyId(
                        values.programName.label,
                        user?.company.id
                    );
                }
            }

            delete program?.regDate;
            delete program?.companies;

            const payload = {
                year: values.year,
                entity: 'ecosystem',
                programName: values.programName.label,
                description: values.description,
                linkUrl: values.linkUrl,
                ranking,
                emailLanguage: language,
                company: {
                    name: user.company.name
                }
            };

            if (program) {
                payload.program = program;
            }

            // Add startup trough id
            if (values.startup && values.startup._id) {
                payload.startupId = values.startup._id;
            } else if (
                values.startupName &&
                values.contactName &&
                values.contactEmail &&
                values.startupCountry
            ) {
                // Add startup contact information
                payload.startup = {
                    name: values.startupName,

                    contact: {
                        name: values.contactName,
                        email: values.contactEmail
                    },
                    location: {
                        country: values.startupCountry
                    }
                };
            }

            await postUserRelationship(payload);

            RHP.push('/ranking/latam/ecosystem/step3');
        } catch (err) {
            emitAlert({
                message: `Ocorreu um erro inesperado, tente novamente mais tarde. Erro: ${err.message}`,
                header: 'Ops...'
            });
        } finally {
            form.submited();
        }
    };

    const propsForm = { ...props, form, skip, setSkip, onChange, onSubmit };

    return (
        <Page.Page name="EcosystemStep3Add">
            <Page.Title>{props.t('EcosystemStep2Add.title')}</Page.Title>
            <Page.Descr>{props.t('EcosystemStep2Add.descr')}</Page.Descr>

            <EcosystemStep3AddForm {...propsForm} />
        </Page.Page>
    );
}

export default EcosystemStep3Add;
