import React from 'react';
import * as Styled from './StyledDescription';

export const Description = props => {
    const { startup } = props;

    let truncatedText = '';
    if (startup?.description) {
        truncatedText =
            startup.description.length > 150
                ? `${startup.description.substring(0, 150)}...`
                : startup.description;
    }

    return (
        <Styled.Description>
            <Styled.Infos>
                <div key={startup?.id}>
                    <span className="text">{truncatedText}</span>
                </div>
            </Styled.Infos>
        </Styled.Description>
    );
};

export default Description;
