import React from 'react';

import { useCXI } from '../useCXI';

import { CXIModalError } from './CXIModalError';

import * as Modals from './index';
import * as Styled from './StyledCXIModal';

export const CXIModal = () => {
    const { modal, setModal } = useCXI();
    const { modal: name } = modal;

    const renderModal = React.useMemo(() => {
        const Component = Modals[name] || CXIModalError;
        return <Component />;
    }, [name]);

    return (
        <Styled.CXIModal>
            <Styled.CXIModalBackdrop onClick={() => setModal(null)} />
            <Styled.CXIModalContent>{renderModal}</Styled.CXIModalContent>
        </Styled.CXIModal>
    );
};
