import React from 'react';
import { IonToast } from '@ionic/react';

import { ContextNotify as CN } from 'contexts';

export function AppNotif() {
    const { state, notified } = React.useContext(CN);
    const { notification } = state;

    // Check if there is a notification to be shown
    if (!notification || (notification && !notification.data)) {
        return null;
    }

    const toastProps = {
        color: 'dark',
        duration: 10000,
        header: notification.data.title,
        message: notification.data.body,
        mode: 'ios',
        position: 'top',

        isOpen: true,
        onDidDismiss: () => notified(),
    };
    return <IonToast {...toastProps} />;
}
export default AppNotif;
