import React from 'react';
import { useCopyToClipboard, useEffectOnce } from 'react-use';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledStartupShare';

export const StartupShare = () => {
    const { t, modal, setModal, getStartupShare } = useCXI();
    const { match } = modal;

    const [link, setLink] = React.useState(null);
    const [copy, setCopy] = useCopyToClipboard();

    useEffectOnce(() => {
        getStartupShare(match)
            .then(res => {
                setLink(res.link);
            })
            .catch(() => setModal(null));
    }, []);

    // const message = `Hey, I've just found out this interesting startup, and I think you should take a look at it. Cheers! ${link}`;
    const message = t('modal.StartupShare.whatsapp', { link });

    return (
        <Styled.StartupShare as={Container.Slim}>
            <Container.Head image={match.startup.logoUrl} />

            <Container.Body>
                <Styled.Title>{t('modal.StartupShare.title')}</Styled.Title>
            </Container.Body>

            <Container.Feet>
                <div className="shareLink"> {link || 'Loading...'} </div>
                <Button variant="share" size="small" onClick={() => setCopy(link)}>
                    {!copy.value && <span className="text">{t('modal.StartupShare.copy')}</span>}
                    {!!copy.value && <span className="text">{t('modal.StartupShare.copied')}</span>}
                </Button>
            </Container.Feet>
            {/* implementar links de share */}
            <Styled.Title>{t('modal.StartupShare.shareOptions')}</Styled.Title>

            <Container.Feet>
                <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`}>
                    <Button variant="linkShare" size="medium">
                        WhatsApp
                    </Button>
                </a>
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button variant="linkShare" size="medium">
                        Facebook
                    </Button>
                </a>
            </Container.Feet>
        </Styled.StartupShare>
    );
};
