import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const Sessions = styled.div``;

export const SessionsTable = styled.table`
    min-width: 100%;
`;

export const SessionsTableThead = styled.thead``;
export const SessionsTableTbody = styled.tbody``;

export const SessionsTableTd = styled.td`
    background: white;
    padding: 1.6rem;
    text-align: left;

    &.dated strong {
        font-weight: bold;
        text-transform: capitalize;
        white-space: nowrap;
    }
    .participants {
        font-size: 1.3rem;
        ${truncate(1, 1.4)}
    }
`;

export const SessionsTableTh = styled.th`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    padding: 0 0 0.6rem 1.2rem;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    width: auto;

    @media (min-width: 992px) {
        padding: 0 0 1.2rem 1.2rem;
    }

    &:first-child {
        padding-left: 0 !important;
        text-align: center;
        width: 0;
    }
    &:last-child {
        padding-left: 1.2rem;
        padding-right: 0;
        text-align: left;
        width: 0;
    }
`;

export const SessionsTableTr = styled.tr`
    border-top: 1px solid ${({ theme }) => theme.colors.whiteDarker};

    &:first-child {
        border-top: 0;

        td:first-child {
            border-top-left-radius: 0.8rem;
        }
        td:last-child {
            border-top-right-radius: 0.8rem;
        }
    }

    &:last-child {
        td:first-child {
            border-bottom-left-radius: 0.8rem;
        }
        td:last-child {
            border-bottom-right-radius: 0.8rem;
        }
    }

    &:nth-child(odd) td {
        background: ${({ theme }) => theme.colors.whiteDark};
    }
`;

export const SessionsTableEmpty = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 3.2rem;
    text-align: center;
`;
export const SessionsTableEmptyTitle = styled.h2`
    color: rgba(0, 0, 0, 0.9);
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 1.6rem;
`;
export const SessionsTableEmptyDescr = styled.h2`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.3rem;
    font-weight: 400;
    margin: 0.8rem 0 1.2rem;
`;
