import React from 'react';

import * as Styled from './StyledStartupImage';

export function StartupImage(props) {
    const data = props.data || props.startup || undefined;

    const info = {};
    if (data.data) {
        info.image = { img: encodeURI(data.data.logoUrl), alt: data.data.name };
    }
    if (data.startupStatus) {
        info.image = { img: encodeURI(data.logoUrl), alt: data.name };
    }

    return !data ? null : (
        <Styled.StartupImageHolder>
            <Styled.StartupImage {...info.image} />
        </Styled.StartupImageHolder>
    );
}
export default StartupImage;
