import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledCardsSupport';

export const CardsSupport = () => {
    const { t, setModal } = useCXI();

    const handleClick = () => {
        setModal({ modal: 'Support' });
    };

    return (
        <Styled.CardsSupport>
            <Styled.CardsHead>
                <h2>{t('home.CardsSupport.title')}</h2>
            </Styled.CardsHead>

            <Styled.CardsBody>
                <div className="card">
                    <span className="icon fas fa-users-class" aria-hidden="true" />
                    <span className="title">{t('cards.name2')}</span>
                    <span className="descr">{t('cards.descr2')}</span>
                    <a
                        className="buttn"
                        href="https://100os.net/worksessionCXI"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="text">{t('cards.button2')}</span>
                    </a>
                </div>

                <div className="card">
                    <span className="icon fas fa-user-headset" aria-hidden="true" />
                    <span className="title">{t('cards.name3')}</span>
                    <span className="descr">{t('cards.descr3')}</span>
                    <button className="buttn" type="button" onClick={handleClick}>
                        <span className="text">{t('cards.button3')}</span>
                    </button>
                </div>
            </Styled.CardsBody>
        </Styled.CardsSupport>
    );
};
