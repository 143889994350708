import React from 'react';
import { useLocation } from 'react-router';

import { RHP } from 'providers';

import * as Styled from './StyledNavigation';

export const NavigationPage = ({ children, path }) => {
    const location = useLocation();

    const route = `/100-10-1/networks${path}`;
    const active = location.pathname === route ? 'active' : '';

    const handleClick = () => RHP.push(route);

    return (
        <Styled.NavigationPage className={active} onClick={handleClick}>
            {children}
        </Styled.NavigationPage>
    );
};

export const Page = NavigationPage;
