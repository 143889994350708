import React from 'react';
import { IonToast } from '@ionic/react';

import { ContextGlobal as CG } from 'contexts';

export function AppToast() {
    const { state, emitToast } = React.useContext(CG);

    const toastProps = {
        color: 'dark',
        duration: 4000,
        mode: 'md',
        message: state.toast,
        isOpen: !!state.toast,
        onDidDismiss: () => emitToast(''),
    };
    return !state.toast ? null : <IonToast {...toastProps} />;
}
export default AppToast;
