import { useEffectOnce } from 'react-use';
import { useStartupCXI } from '../../useStartupCXI';

export const Evaluate = () => {
    const { modal, setModal } = useStartupCXI();
    const { match } = modal;

    useEffectOnce(() => {
        setModal({ match, modal: 'EvaluateForm' });
    }, []);

    return null;
};
