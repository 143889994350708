import styled from 'styled-components';

export const FilterTag = styled.button.attrs(props => ({
    className: `filter-tag ${props.active ? 'active' : ''}`,
}))`
    align-items: center;
    background: white;
    border: 1px solid #0094f9;
    border-radius: 16rem;
    color: #0094f9;
    display: flex;
    font-size: 1.3rem;
    font-weight: 600;
    gap: 0.6rem;
    line-height: 1.2;
    justify-content: center;
    padding: 1rem 1.6rem;

    &.active {
        background: #0094f9;
        color: #fff;
        justify-content: space-between;
    }
`;
