import React from 'react';

import { RHP } from 'providers';
import { useForm } from 'components/form';
import * as Page from 'components/page';

import { RegisterLocationForm } from './RegisterLocationForm';

export function RegisterLocation(props) {
    const { vars, putUser } = props.useRegister;

    // Initialize a new form handler
    const form = useForm({
        location: vars.location || {},
    });

    // Handle form submition
    const onSubmit = values => {
        const payload = values;
        // Do the submition api request
        return putUser(payload)
            .then(() => RHP.push('/register/company'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="RegisterLocation">
            <Page.Title>{props.t('RegisterLocation.title')}</Page.Title>
            <Page.Descr>{props.t('RegisterLocation.descr')}</Page.Descr>

            <RegisterLocationForm {...propsForm} />
        </Page.Page>
    );
}
export default RegisterLocation;
