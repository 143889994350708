import React, { useMemo, useRef } from 'react';
import defaultAvatar from 'assets/images/startup-default.png';
import Button from 'components/button';
import { useClickOutside } from 'hooks/useClickOutside';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    Container,
    Modal,
    MessageContent,
    StartupContent,
    Avatar,
    Title,
    Badges,
    Tag,
    LocationText,
    MessageContentActions,
} from './styles';

interface StartupLocation {
    city: string;
    country: string;
    state: string;
}

interface StartupBadgeName {
    language: string;
    text: string;
    _id: string;
}

interface StartupBadge {
    logoUrl: string;
    name: StartupBadgeName[];
    points: number;
    rank: number;
    _id: number;
}

export interface StartupData {
    name: string;
    logoUrl: string;
    badges: StartupBadge[];
    location: StartupLocation;
}

interface NotificationProps {
    closeModal(): void;
    startup: StartupData;
}

export const Notification: React.FC<NotificationProps> = ({ closeModal, startup }) => {
    const { t } = useTranslation('cxi-old');
    const modalRef = useRef<HTMLDivElement>(null);
    const { push } = useHistory();

    useClickOutside({
        ref: modalRef,
        action: () => closeModal(),
    });

    const badges = useMemo(() => {
        return startup?.badges
            ? startup?.badges?.map(badge => `${badge.name[0].text} - #${badge.rank}`.toUpperCase())
            : [];
    }, [startup]);

    return (
        <Container>
            <Modal ref={modalRef}>
                <MessageContent>
                    <h1>{t('StandAloneContent.Notification.text1')}</h1>
                    <p>{t('StandAloneContent.Notification.text2')}</p>
                    <p>{t('StandAloneContent.Notification.text3')}</p>
                    <MessageContentActions>
                        <Button onClick={() => push('/home/?startupId={{startupId}}')}>
                            {t('StandAloneContent.Notification.text4')}
                        </Button>
                        <Button variant="outline" onClick={() => push('/home')}>
                            {t('StandAloneContent.Notification.text5')}
                        </Button>
                    </MessageContentActions>
                </MessageContent>
                <StartupContent>
                    <Avatar src={startup?.logoUrl || defaultAvatar} />
                    <Title>{startup?.name}</Title>
                    <Badges>
                        {badges.map(badge => (
                            <Tag key={badge}>{badge}</Tag>
                        ))}
                    </Badges>
                    <LocationText>
                        <span className="icon fal fa-map-marker-alt" />
                        {`${startup?.location?.city} - ${startup?.location?.state}, ${startup?.location?.country}`}
                    </LocationText>
                </StartupContent>
            </Modal>
        </Container>
    );
};
