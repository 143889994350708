import React from 'react';
import { CA } from 'contexts';
import exclamationicon from 'assets/images/profile-info-reminder-exclamation.svg';
import useCXI from 'modules/cxi/useCXI';
import * as Styled from './StyledProfileInfoReminder';

export const ProfileInfoReminder = () => {
    const { t } = useCXI();
    const { user } = React.useContext(CA).state;
    return (
        !!(!user.personalEmail || !user.phone) && (
            <Styled.ProfileInfoReminder>
                <Styled.Description>
                    <img src={exclamationicon} alt="exclamation icon" />
                    <p>{t('profileReminder.title')}</p>
                </Styled.Description>
                <Styled.Button href="/profile/edit">
                    <span className="text">{t('profileReminder.button')}</span>
                </Styled.Button>
            </Styled.ProfileInfoReminder>
        )
    );
};
