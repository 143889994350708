import React from 'react';
import * as Form from 'components/form';
import * as Page from 'components/page';
import { CG } from 'contexts';
import { RHP } from 'providers';

export function EditPocsAdd({ useRankingCorporate }) {
    const { vars, addVars, t, getCompanyByEmail } = useRankingCorporate;
    const { pocsRequest, companyDetails } = vars;

    const { emitAlert } = React.useContext(CG);

    const queryParameters = new URLSearchParams(window.location.search);
    const poc = queryParameters.get('poc');

    const form = Form.useForm({
        name: '',
        email: '',
        department: '',
        position: '',
        type: 'add',
        status: 'pending',
        poc
    });

    const fields = {
        name: {
            error: form.errors.name,
            label: t('glossary:fullName'),
            name: 'name',
            type: 'text',
            value: form.values.name,
            onChange: form.handleChange
        },
        email: {
            error: form.errors.email,
            label: t('glossary:email'),
            name: 'email',
            type: 'text',
            value: form.values.email,
            onChange: form.handleChange
        },
        position: {
            error: form.errors.position,
            label: t('glossary:position'),
            name: 'position',
            type: 'text',
            value: form.values.position,
            onChange: form.handleChange
        },
        department: {
            error: form.errors.department,
            label: t('glossary:department'),
            name: 'department',
            type: 'text',
            value: form.values.department,
            onChange: form.handleChange
        }
    };

    const onSubmit = async values => {
        const isValidEmail = await getCompanyByEmail(values.email);

        if (
            !isValidEmail.validdomain ||
            !isValidEmail?.company ||
            (isValidEmail?.company && companyDetails._id !== isValidEmail?.company?.id)
        ) {
            emitAlert({
                message: `O domínio de e-mail informado não está vinculado a esta empresa. Verifique se o domínio está correto ou entre em contato com nossa equipe: helpme@openstartups.net.`,
                header: 'Ops...'
            });
            form.submited();
            return;
        }

        if (!pocsRequest.filter(e => e.poc === poc).some(value => value.email === values.email)) {
            const parsedCurrentPocs = [...pocsRequest, { ...values }];

            addVars({ pocsRequest: parsedCurrentPocs });
        } else {
            emitAlert({
                message: `Email ${values.email} ja esta incluso na lista`,
                header: 'Ops...'
            });
        }
        RHP.goBack();
    };

    // @ts-ignore
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Page.Page name="PocsEditAdd" customBackText={t('glossary:backToRanking')}>
            <Page.Title>{t('rankingEditPocsAdd.title')}</Page.Title>
            <Page.Descr>{t('rankingEditPocsAdd.description')}</Page.Descr>

            <Form.Form name="editPocsAddForm" onSubmit={evSubmit}>
                <Form.Field {...fields.name} />

                <Form.Field {...fields.email} />
                <Form.Field {...fields.position} />
                <Form.Field {...fields.department} />

                <Form.Submt paddingTop="10rem" {...form} text={t('glossary:save')} />
            </Form.Form>
        </Page.Page>
    );
}
