import { useContext } from 'react';
import { useData } from 'modules/corporatehub/context/dataContext';
import { useApi } from 'hooks';
import { RHP } from 'providers';
import { ContextAuth as CA } from 'contexts';

export function useCorporateHub(props) {
    const api = useApi();
    const dataContext = useData();
    const { state } = useContext(CA);
    const {
        setStartupsPageVars,
        setChallengesPageVars,
        setRelationshipsPageVars,
        setExecutivesPageVars,
        setGroupsPageVars,
        setConfigVars,
        setCompany
    } = dataContext.mutations;

    async function getHubCompanyById(companyId) {
        try {
            const id = companyId;
            const response = await api.get(`/companies/${id}`).then(res => {
                setCompany(oldState => {
                    return {
                        ...oldState,
                        ...res
                    };
                });
                return res;
            });
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function searchCompany(name) {
        try {
            return api.get(`/companies?name=${encodeURIComponent(name)}`).then(res => {
                return res;
            });
        } catch (err) {
            console.log(err);
        }
    }

    async function getPageData(configObj, endpoint, setPageVars) {
        try {
            const response = await api
                .get(
                    `companies/${configObj.companyId ||
                        state.user.company.id}/hub/${endpoint}?startDate=${
                        configObj.startDate
                    }&endDate=${configObj.endDate}&includeGroup=${configObj.includeGroup}`
                )
                .then(res => {
                    setPageVars(res);
                    return res;
                });
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function getPrizes(configObj) {
        try {
            const response = await api.get(`/companies/${configObj.companyId}/awards`).then(res => {
                setCompany(oldState => {
                    return {
                        ...oldState,
                        awards: res
                    };
                });
                return res;
            });
            return response;
        } catch (err) {
            console.log(err);
        }
    }
    async function getStartupsPageData(configObj) {
        getPageData(configObj, 'startups', setStartupsPageVars);
    }

    async function getRelationshipsPageData(configObj) {
        // eslint-disable-next-line no-unused-vars
        const bubbleChart = await getPageData(configObj, 'startupsintensity', res => {
            setRelationshipsPageVars({
                bubbleChartData: res,
                geoChartData: []
            });
        });
        // eslint-disable-next-line no-unused-vars
        const geoChartData = await getPageData(configObj, 'startupslocation', res => {
            setRelationshipsPageVars(oldState => ({
                ...oldState,
                geoChartData: [...res]
            }));
        });
    }

    async function getChallengesPageData(configObj) {
        getPageData(configObj, 'challenges', setChallengesPageVars);
    }

    async function getExecutivesPageData(configObj) {
        getPageData(configObj, 'executives', setExecutivesPageVars);
    }

    async function getGroupsPageData(configObj) {
        getPageData(configObj, 'group', setGroupsPageVars);
    }

    function pushToDashboard(configUrl) {
        RHP.push(`/corporatehub/${configUrl}`);
    }

    async function callGetters(configObj) {
        const company = await getHubCompanyById(configObj.companyId || state.user.company.id);
        if (configObj.includeGroup === undefined) {
            configObj.includeGroup =
                company.isHubGroup === undefined || !company.isHubGroup
                    ? false
                    : company.isHubGroup;
        }
        setConfigVars(configObj);
        getPrizes(configObj);
        getStartupsPageData(configObj);
        getChallengesPageData(configObj);
        getExecutivesPageData(configObj);
        getRelationshipsPageData(configObj);
        getGroupsPageData(configObj);
    }

    return {
        callGetters,
        pushToDashboard,
        getHubCompanyById,
        searchCompany,
        getStartupsPageData,
        getChallengesPageData,
        getExecutivesPageData,
        getGroupsPageData,
        getRelationshipsPageData
    };
}

export default useCorporateHub;
