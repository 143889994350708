import React from 'react';
import { useEffectOnce } from 'react-use';

import logo from 'assets/images/logo-100-10-1.png';

import { RHP } from 'providers';
import { queryParamsToObject } from 'utils/query';

import { Trans } from 'react-i18next';
import { Button } from 'modules/cxi/components/button';
import { useStartupCXI } from '../../useStartupCXI';
import * as Styled from './StyledCXIFeedback';
import { EvaluateForm } from '../../modal';

export const CXIFeedback = () => {
    const { t, vars, addVars } = useStartupCXI();
    const { startup, startupId } = vars;

    const query = vars.selectedMatch || queryParamsToObject(window.location.search);
    const { evaluationId } = query;
    const match = vars.feedbacks.find(m => m._id === evaluationId);

    const backHome = () => {
        RHP.push(`/startup/${startupId}/100-10-1/`);
    };

    useEffectOnce(() => {
        if (!vars.selectedMatch) {
            addVars({ simpleLayout: true });
            return () => addVars({ simpleLayout: false });
        }
        return () => {};
    }, []);

    return !match ? (
        <Styled.CXIFeedback>
            <Styled.CXIFeedbackCard>
                <Styled.Title>{t('feedback.error.title')}</Styled.Title>
                <Styled.Descr>{t('feedback.error.description')}</Styled.Descr>
                <Button onClick={backHome}>{t('feedback.error.goToHome')}</Button>
            </Styled.CXIFeedbackCard>
        </Styled.CXIFeedback>
    ) : (
        <Styled.CXIFeedback>
            {!vars.selectedMatch && (
                <Styled.CXIFeedbackHead>
                    <Styled.Logo src={logo} alt="Logo 100-10-1" onClick={backHome} />
                </Styled.CXIFeedbackHead>
            )}
            <Styled.CXIFeedbackBody>
                <Styled.Intro>
                    <Trans i18nKey="feedback.title" t={t}>
                        <strong>{{ startupName: startup.name }}</strong>
                    </Trans>
                </Styled.Intro>

                <Styled.Descr>
                    <p>{t('feedback.description')}</p>
                </Styled.Descr>

                <EvaluateForm match={match} modalSuccess="Startup" />
            </Styled.CXIFeedbackBody>
        </Styled.CXIFeedback>
    );
};
