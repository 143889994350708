import styled from 'styled-components';
import { positions } from 'utils/styles';

export const FormInputRadio = styled.div.attrs({
    className: 'form-input-radio',
})`
    position: relative;
    .form-control-label {
        margin-bottom: 1rem;
    }
`;

export const FormInputRadioItem = styled.div.attrs({
    className: 'form-input-radio-item',
})`
    display: inline-block;
    margin-right: 1.5rem;
    position: relative;

    .form-radio-label {
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin: 0;
        padding: 0 0 0 2.8rem;
    }
    .form-radio-label::before {
        color: #ddd;
        content: '\f111';
        font-family: 'Font Awesome 5 Pro';
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.2;
        position: absolute;
        ${positions(0, 'auto', 'auto', 0)}
        transform: translateY(-2px);
    }

    .form-radio {
        height: 1.8rem;
        opacity: 0;
        position: absolute;
        ${positions(0, 'auto', 'auto', 0)}
        width: 1.8rem;
    }

    .form-radio[disabled] ~ .form-radio-label {
        color: #ddd;
        &::before {
            font-weight: 600;
        }
    }
    .form-radio:checked ~ .form-radio-label::before {
        color: var(--primary);
        content: '\f192';
    }
`;
