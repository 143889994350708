import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Progress = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 3.2rem;
    max-width: 480px;
    position: relative;

    &::before {
        background: rgb(223, 226, 233);
        content: '';
        height: 3px;
        position: absolute;
        ${positions('50%', '3px', 'auto', '3px')}
        transform: translateY(-1.5px);
    }

    div {
        align-items: center;
        background: rgb(255, 255, 255);
        border-radius: 50%;
        display: flex;
        height: 2.4rem;
        justify-content: center;
        position: relative;
        width: 2.4rem;
    }

    .default {
        background: transparent;

        &::before {
            background: rgb(255, 255, 255);
            border: 2px solid rgb(223, 226, 233);
            border-radius: 50%;
            content: '';
            display: block;
            height: 1.6rem;
            width: 1.6rem;
        }
    }
    .current {
        box-shadow: rgb(0 0 0 / 15%) 0px 1px 6px;

        &::before {
            background: ${({ theme }) => theme.colors.energyBlue};
            border-radius: 50%;
            content: '';
            display: block;
            height: 0.8rem;
            width: 0.8rem;
        }
    }
    .previous {
        background: ${({ theme }) => theme.colors.energyBlue};
        color: ${({ theme }) => theme.colors.white};
        font-size: 1rem;

        &::before {
            content: '\f00c';
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            transform: translate(0, 1px);
        }
    }
`;
