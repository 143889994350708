import React from 'react';

import * as Step from '../components/step';
import { Languages } from '../forms/Languages';

export const CXIStep7 = () => (
    <Step.Step currentStep={7}>
        <Languages />
    </Step.Step>
);
