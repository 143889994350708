import React from 'react';

import { CONFIG } from 'config';
import { ListVertical } from 'components/list';
import { RHP } from 'providers';

import * as Styled from './StyledUserStartups';

const hrefStartup = CONFIG.appLinks.startup;

export const UserStartupsListItem = ({ data }) => {
    let { logoUrl } = data;
    let className = data.typeOfProject;
    if (data.startupStatus === 'none') {
        logoUrl = undefined;
        className = 'none';
    }

    const handleClick = async () => {
        if (data.startupStatus === 'none') {
            const href = `${hrefStartup}/registerstartup/?startupId=${data._id}&lang=pt&back=/home/?startupId=${data._id}`;
            return RHP.push(`/iframe/${encodeURIComponent(href)}`);
        }
        if (data.href) return RHP.push(data.href);
        if (data.onClick) return data.onClick();
        return null;
    };

    return (
        <Styled.UserStartupsListItem className={className} onClick={handleClick}>
            <Styled.ListItemImage img={encodeURI(logoUrl)} />
            <span className="name">{data.name}</span>
            {className === 'none' ? (
                <span className="code">Não registrada</span>
            ) : (
                <span className="code">{data.inductId}</span>
            )}
        </Styled.UserStartupsListItem>
    );
};

export const UserStartupsList = ({ data }) => (
    <Styled.UserStartupsList>
        <ListVertical items={data} key="_id" itemComponent={UserStartupsListItem} />
    </Styled.UserStartupsList>
);
