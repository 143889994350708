import styled from 'styled-components';

export const Card = styled.div`
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
`;

export const Header = styled(Card)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 18%;
    background-color: #3b3e45;
    width: 99%;
    gap: 10px;
    margin: 5px auto 0 auto;
    cursor: pointer;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-left: 70px; */
    }
`;

export const FirstHeaderContainer = styled(Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 82%;
    padding: 10px;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    h1 {
        color: #0085f2;
    }
    p {
        font-weight: bold;
    }
`;

export const PrizesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #0085f2;
    }
`;
export const PoweredByContainer = styled(Card)`
    display: flex;
    padding: 10px;
    min-width: 18%;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    text-transform: uppercase;
    gap: 15px;
`;
export const PoweredByLogo = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 90px;
    }
`;
export const CopyButton = styled.div`
    display: flex;
    cursor: pointer;
    flex-wrap: nowrap;
    font-weight: 700;
    gap: 10px;
    border: none;
    white-space: nowrap;
    align-items: center;
    padding: 10px;
    color: #0094f9;
    margin-left: 9rem;
    margin-right: 2rem;
`;

export const PrizesIndividualLogo = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    align-self: center;

    img {
        width: 80px;
        height: auto;
        max-height: 80px;
    }
`;
export const PrizesLogosContainer = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UserLogo = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    img {
        max-width: 140px;
        min-width: 100px;
        max-height: 100px;
    }
`;

export const ConfigButton = styled.a`
    align-items: center;
    border-radius: 2px;
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
    display: flex;

    flex: 1 1 auto;
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    padding: 0.2rem 1rem;
    text-transform: uppercase;
    white-space: nowrap;
    gap: 10px;
    background: ${({ theme }) => theme.colors.deepBlue};
    color: ${({ theme }) => theme.colors.white};

    ::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
`;
