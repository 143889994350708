import React from 'react';
import { formatDate } from 'utils/date';

import * as Styled from './StyledEvaluationsStartup';

export const EvaluationsStartup = ({ startup, date }) => {
    const formatted = formatDate(new Date(date), "'Em' dd/MM/yyyy, 'às' H'h'mm");
    return (
        <Styled.EvaluationsStartup>
            <Styled.Image img={startup.logoUrl} />
            <Styled.Infos>
                <Styled.Title>{startup.name}</Styled.Title>
                <Styled.Descr>
                    {date && formatted ? formatted : 'Sem data de cadastro'}
                </Styled.Descr>
            </Styled.Infos>
        </Styled.EvaluationsStartup>
    );
};
