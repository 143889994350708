import React from 'react';
import { useLocation } from 'react-router';
import { useEffectOnce } from 'react-use';

import { useCXI } from '../useCXI';
import { CXISetupContextProvider } from './CXISetupContext';
import { CXISetupRoutes } from './CXISetupRoutes';
import { useCXISetup } from './useCXISetup';

const CXISetupModule = props => {
    const { addVars: cxiAddVars } = useCXI();
    const { addVars: stpAddVars } = useCXISetup();

    const { search } = useLocation();

    useEffectOnce(() => {
        cxiAddVars({ simpleLayout: true });
        return () => cxiAddVars({ simpleLayout: false });
    }, []);

    useEffectOnce(() => {
        if (search.includes('edit')) {
            stpAddVars({ isEditing: true });
        }
        return () => {
            stpAddVars({ isEditing: false });
        };
    }, []);

    return <CXISetupRoutes />;
};

export const CXISetup = () => (
    <CXISetupContextProvider>
        <CXISetupModule />
    </CXISetupContextProvider>
);
export default CXISetup;
