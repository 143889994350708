import React from 'react';

import { useConversionTable } from 'hooks';

import * as Styled from './StyledCompanyCard';

export function CompanyCard(props) {
    const { vars } = props.useRankingStartup;
    const data = props.data || props.startup || vars.searchedCompany.company;
    const conv = useConversionTable(['companyType', 'companySize']);

    const info = {};
    if (data.logo) {
        info.image = { img: encodeURI(data.logo), alt: data.name };
    }
    info.title = data.name;
    info.size = conv.companySize[data.size];
    info.type = conv.companyType[data.type];

    return !data ? null : (
        <Styled.CompanyCard>
            <Styled.CardImage {...info.image} />
            <Styled.CardInfos>
                <span className="name">{info.title}</span>
                <span className="text">{info.size}</span>
                {info.type && <span className="text">{info.type}</span>}
            </Styled.CardInfos>
        </Styled.CompanyCard>
    );
}
export default CompanyCard;
