import React, { useState } from 'react';
import tooltip from 'assets/images/icons/tooltipRanking.png';
import greenArrow from 'assets/images/icons/greenArrowUp.png';
import redArrow from 'assets/images/icons/redArrowDown.png';
import * as Styled from './StyledBottomPosition';

export function BottomCardPosition(props) {
    const { position, t } = props;
    const { upper, lower } = position || {};
    const [showLeftTooltip, setShowLeftTooltip] = useState(false);
    const [showRightTooltip, setShowRightTooltip] = useState(false);

    return (
        <Styled.BottomPositionContainer>
            <Styled.BottomPosition>
                <img src={greenArrow} alt="up arrow" />
                <span>{upper || '-'}</span>
                <img
                    src={tooltip}
                    alt="tooltip"
                    onMouseOver={() => setShowLeftTooltip(true)}
                    onMouseLeave={() => setShowLeftTooltip(false)}
                />
                {showLeftTooltip && (
                    <Styled.Tooltip>{t('CorporateStep4.bottomCardGreenTooltip')}</Styled.Tooltip>
                )}
            </Styled.BottomPosition>
            <Styled.BottomPosition>
                <img src={redArrow} alt="up arrow" />
                <span>{lower || '-'}</span>
                <img
                    src={tooltip}
                    alt="tooltip"
                    onMouseOver={() => setShowRightTooltip(true)}
                    onMouseLeave={() => setShowRightTooltip(false)}
                />
                {showRightTooltip && (
                    <Styled.Tooltip>{t('CorporateStep4.bottomCardRedTooltip')}</Styled.Tooltip>
                )}
            </Styled.BottomPosition>
        </Styled.BottomPositionContainer>
    );
}
export default BottomCardPosition;
