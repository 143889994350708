import React, { useEffect, useState } from 'react';
import { useData } from 'modules/corporatehub/context/dataContext';
import * as Styled from './StyledCorporateHubIndex';
import Header from '../components/components/staticHeader/Header';
import CorporateHubStartupView from '../components/pageComponents/startups/CorporateHubStartupView';
import InteractiveHeader from '../components/components/interactiveHeader/InteractiveHeader';
import CorporateHubRelationshipView from '../components/pageComponents/relationships/CorporateHubRelationshipView';
import CorporateHubChallengesView from '../components/pageComponents/challenges/CorporateHubChallengesView';
import CorporateHubExecutiveView from '../components/pageComponents/executives/CorporateHubExecutiveView';
import CorporateHubGroupView from '../components/pageComponents/groups/CorporateHubGroupView';
import useCorporateHub from '../useCorporateHub';

export function CorporateHubIndex(props) {
    const dataContext = useData();
    const { configObjFromURL } = props;
    const { configVars } = dataContext.state;
    const { displayMode } = configVars;
    const [activeComponent, setActiveComponent] = useState('');
    const { callGetters } = useCorporateHub();
    function handleTabChange(component) {
        setActiveComponent(component);
    }
    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (configObjFromURL.displayMode != undefined) {
            callGetters(configObjFromURL);
            setActiveComponent(configObjFromURL.viewOptions[0].name);
        } else {
            callGetters(configVars);
            setActiveComponent(configVars.viewOptions[0].name);
        }
    }, []);

    const pages = configVars?.viewOptions;

    useEffect(() => {
        if (displayMode === 'exhibition') {
            const timer = configVars?.displayTime;
            let currentPage = 0;
            setInterval(() => {
                if (currentPage >= pages.length || currentPage === undefined) {
                    currentPage = 0;
                }
                setActiveComponent(pages[currentPage]?.name);
                return currentPage++;
            }, timer);
        }
    }, []);

    return displayMode === 'exhibition' ? (
        <Styled.CorporateHubIndex>
            <Styled.BackgroundLayer />
            {configVars.displayHeader && <Header />}
            {activeComponent === 'startup' ? <CorporateHubStartupView /> : null}
            {activeComponent === 'relationships' ? <CorporateHubRelationshipView /> : null}
            {activeComponent === 'challenges' ? <CorporateHubChallengesView /> : null}
            {activeComponent === 'executives' ? <CorporateHubExecutiveView /> : null}
            {activeComponent === 'group' ? <CorporateHubGroupView /> : null}
        </Styled.CorporateHubIndex>
    ) : (
        <Styled.CorporateHubIndex>
            <Styled.BackgroundLayer />
            <InteractiveHeader
                handlePageChange={handleTabChange}
                activeComponent={activeComponent}
            />
            {activeComponent === 'startup' ? <CorporateHubStartupView /> : null}
            {activeComponent === 'relationships' ? <CorporateHubRelationshipView /> : null}
            {activeComponent === 'challenges' ? <CorporateHubChallengesView /> : null}
            {activeComponent === 'executives' ? <CorporateHubExecutiveView /> : null}
            {activeComponent === 'group' ? <CorporateHubGroupView /> : null}
        </Styled.CorporateHubIndex>
    );
}
