import React from 'react';

import { RankingCorporateRoutes } from './RankingCorporateRoutes';
import { useRankingCorporate } from './useRankingCorporate';
import { RankingContextProvider } from './RankingContext';

function RankingCorporateModule() {
    const hookd = useRankingCorporate();
    return !hookd.ready ? null : (
        <RankingContextProvider>
            <RankingCorporateRoutes t={hookd.t} useRankingCorporate={hookd} />
        </RankingContextProvider>
    );
}

export function RankingCorporate() {
    return (
        <RankingContextProvider>
            <RankingCorporateModule />
        </RankingContextProvider>
    );
}
export default RankingCorporate;
