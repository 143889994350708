import React from 'react';
import { useEffectOnce } from 'react-use';

import { CA } from 'contexts';
import { useApi } from 'hooks';
import { RHP } from 'providers';
import { queryParam } from 'utils/query';

export function useProfile() {
    const { state, dispatch, scopeAllows } = React.useContext(CA);
    const api = useApi();

    // Initial values for the state
    const initial = {
        ...state.user,
        returnTo: queryParam('returnTo'),
    };

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * HANDLE FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const handleAuth = auth => {
        dispatch({ type: 'UPDATE_AUTH_INFO', auth });
        return auth;
    };

    const handleUser = ({ _id, userid }) => {
        api.get(`/users/${_id || userid}`).then(user => {
            dispatch({ type: 'UPDATE_USER_INFO', user });
            return true;
        });
    };

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function postNewPassword(payload) {
        return api.post(`/newpassword`, payload).then(res => {
            return handleAuth(res);
        });
    }
    function putUser(payload) {
        const { userid } = state.auth;
        return api.put(`/users/${userid}`, payload).then(res => {
            addVars(payload);
            return handleUser(res);
        });
    }

    useEffectOnce(() => {
        if (!scopeAllows('ranking')) {
            RHP.replace('/home');
        }
    });

    return {
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Custom functions
        postNewPassword,
        putUser,
    };
}
export default useProfile;
