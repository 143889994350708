import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { useTranslation } from 'react-i18next';
import 'react-intl-tel-input/dist/main.css';

import { CONFIG } from 'config';
import * as Styled from './StyledFormInput';

export function FormInputPhone(props) {
    const { t } = useTranslation('glossary');

    // Set the International Telephone Input variables
    const propsIntlTelInput = {
        autoComplete: props.autoComplete || 'phone',
        // autoPlaceholder: 'polite',
        defaultCountry: 'br',
        fieldId: props.input.id,
        fieldName: props.input.name,
        format: true,
        inputClassName: 'form-control',
        telInputProps: {},
        placeholder: props.input.placeholder,
        preferredCountries: CONFIG.preferredCountries,
        value: props.input.value,

        // Some functions for the input to work properlly
        onPhoneNumberChange: (status, value, country) => {
            props.input.onChange({
                persist: () => null,
                preventDefault: () => null,
                target: { name: props.input.name, value },
            });
        },
    };

    // Don't show this element if it is empty
    return !props.input ? null : (
        <Styled.FormInput name="phone">
            <div className="addInfo">
                <strong> {t('additionalInformation')} </strong>
                <span className="reqs">({t('optional')})</span>
            </div>
            {props.children}
            <IntlTelInput {...propsIntlTelInput} />
        </Styled.FormInput>
    );
}
export const tel = FormInputPhone;
export const phone = FormInputPhone;
export default FormInputPhone;
