import React from 'react';

import { Button } from 'modules/cxi/components/button';

import { useStartupCXI } from '../../useStartupCXI';
import { Container } from '../container';
import * as Styled from './StyledMentoringAccepted';

export const MentoringAccepted = () => {
    const { t, modal, setModal } = useStartupCXI();
    const { match } = modal;

    return (
        <Styled.MentoringAccepted as={Container.Slim}>
            <Container.Head image={match.evaluator.photoUrl} />

            <Container.Body>
                <Styled.Title>{t('modal.MentoringAccepted.title')}</Styled.Title>
                <Styled.Descr>{t('modal.MentoringAccepted.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.MentoringAccepted>
    );
};
