import React from 'react';
import { IonList } from '@ionic/react';

import { ListStepsItem } from './ListStepsItem';
import * as Styled from './StyledListSteps';

export const ListSteps = props => (
    <Styled.ListSteps as={IonList} {...props}>
        {props.items
            .filter(e => e.isVisible ?? true)
            .map((i, k) => {
                const kk = i[props.itemKey] || `${props.name}-${props.itemKey}-${k}`;
                return <ListStepsItem key={kk} data={i} {...props} />;
            })}
    </Styled.ListSteps>
);
export const Steps = ListSteps;
export default ListSteps;
