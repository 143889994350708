import React from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';
import { useConversionOptions } from 'hooks/useConversionTable';

export function InvestmentAddForm(props) {
    const { form, onSubmit } = props;
    const { vars } = props.useStartupId;
    const { startup } = vars;
    const conv = useConversionOptions(['investmentType']);

    // Create form fields object
    const fields = {
        investorName: {
            error: form.errors.investorName,
            label: props.t('InvestmentAdd.investorName'),
            name: 'investorName',
            type: 'text',
            value: form.values.investorName,
            onChange: form.handleChange,
        },
        investorEmail: {
            error: form.errors.investorEmail,
            label: props.t('InvestmentAdd.investorEmail'),
            name: 'investorEmail',
            type: 'email',
            value: form.values.investorEmail,
            onChange: form.handleChange,
        },
        companyName: {
            error: form.errors.companyName,
            label: props.t('InvestmentAdd.investorCompany'),
            name: 'companyName',
            required: false,
            type: 'generic',
            value: form.values.companyName,
            onChange: form.handleChange,
        },
        investmentType: {
            error: form.errors.investmentType,
            label: props.t('glossary:investmentType'),
            name: 'investmentType',
            options: [
                { name: props.t('glossary:investmentTypeSelect'), value: '' },
                ...conv.investmentType,
            ],
            type: 'select',
            value: form.values.investmentType,
            onChange: form.handleChange,
        },
        investmentValue: {
            error: form.errors.investmentValue,
            label: props.t('glossary:investmentValue'),
            name: 'investmentValue',
            required: startup.investmentProfile.applied,
            type: 'CurrencyAmount',
            value: form.values.investmentValue,
            onChange: form.handleChange,
        },
        date: {
            displayFormat: 'MM/YYYY',
            error: form.errors.date,
            label: props.t('glossary:date'),
            name: 'date',
            type: 'date',
            value: form.values.date,
            onChange: form.handleChange,
        },
        round: {
            error: form.errors.round,
            label: props.t('glossary:round'),
            name: 'round',
            type: 'generic',
            value: form.values.round,
            onChange: form.handleChange,
        },
        rankingIndication: {
            error: form.errors.rankingIndication,
            label: props.t('glossary:rankingIndication'),
            name: 'rankingIndication',
            type: 'checkbox',
            required: false,
            value: form.values.rankingIndication,
            onChange: form.handleChange,
            onlyIf: startup.investmentProfile.applied,
        },
    };

    // Handle form submition
    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();
        return form.handleSubmit(ev, onSubmit);
    };
    return (
        <Form.Form name="InvestmentAddForm" onSubmit={evSubmit}>
            <Form.Field {...fields.investorName} />
            <Form.Field {...fields.investorEmail} />

            <Page.Ruler />

            <Form.Field {...fields.companyName} />
            <Form.Field {...fields.investmentType} />
            <Form.Field {...fields.investmentValue} />

            <Form.Row>
                <Form.Field size="4" {...fields.date} />
                <Form.Field size="8" {...fields.round} />
            </Form.Row>

            <Form.Field size="8" {...fields.rankingIndication} />

            <Form.Submt {...form} text={props.t('glossary:save')} />
        </Form.Form>
    );
}
export default InvestmentAddForm;
