import React from 'react';
import image from 'assets/images/100-10-1-welcome3.jpg';
import { RHP } from 'providers';
import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';
import * as Styled from '../StyledCXIWelcomeModal';
import { useCXISetup } from '../../useCXISetup';
import ProgressBar from '../components/ProgressBar';

export const CXIWelcomeStep3 = props => {
    const { t } = useCXI();
    const { vars } = useCXISetup();

    const handleClick = () => {
        RHP.push('/100-10-1/setup/welcome/4');
    };

    let buttonText = t('setup.welcome.next');
    if (vars.isEditing) {
        buttonText = t('setup.index.edit');
    }

    return (
        <Styled.CxiModal>
            <Styled.CXISetupIndexCard>
                <Styled.Image>
                    <img src={image} alt="Logo 100-10-1" />
                </Styled.Image>

                <Styled.Title>{t('setup.welcome.step3.title')}</Styled.Title>
                <Styled.Descr> {t('setup.welcome.step3.descr')}</Styled.Descr>
                <ProgressBar current={3} />
                <Button size="large" variant="default" onClick={handleClick} className="nextButton">
                    {buttonText}
                </Button>

                <Button
                    variant="link"
                    onClick={() => RHP.push('/100-10-1/setup/1')}
                    className="skipButton"
                >
                    {t('setup.welcome.skip')}
                </Button>
            </Styled.CXISetupIndexCard>
        </Styled.CxiModal>
    );
};
