import React from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { OiweekSubscribeForm } from './OiweekSubscribeForm';
import * as Styled from './StyledOiweekSubscribe';

export const OiweekSubscribe = props => {
    const { vars, putOiweekProfile } = props.useOiweek;
    const { user } = vars.oiweek;

    // Initialize a new form handler
    const form = useForm({
        events: [],
        startups: [],
        speeddating: String(!!user.speeddating),
    });

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            events: values.events.map(e => Number(e)) || [],
            startups: values.startups || [],
            speeddating: values.speeddating !== 'false',
        };
        putOiweekProfile(payload).then(res => {
            RHP.push('/oiweek/success');
            return res;
        });
        return true;
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Styled.OiweekSubscribe>
            <Page.Page name="OiweekSubscribe">
                <Page.Title>{props.t('OiweekSubscribe.title')}</Page.Title>
                <Page.Descr>{props.t('OiweekSubscribe.descr')}</Page.Descr>

                <OiweekSubscribeForm {...propsForm} />
            </Page.Page>
        </Styled.OiweekSubscribe>
    );
};
export default OiweekSubscribe;
