import styled from 'styled-components';

export const Navigation = styled.div`
    background: ${({ theme }) => theme.colors.lighterGray};
    border-radius: 0.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 0.4rem;
    overflow-x: scroll;
    max-width: 100%;
    width: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        gap: 0;
    }

    ::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
`;

export const NavigationPage = styled.div`
    align-itens: center;
    border-radius: 0.6rem;
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    font-size: 1.2rem;
    font-weight: 600;
    justify-content: flex-start;
    padding: 1rem 1.6rem;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: 768px) {
        justify-content: center;
    }

    &.active {
        background: ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
    }
`;
