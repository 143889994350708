import React from 'react';

import * as Step from '../components/step';
import { Industries } from '../forms/Industries';

export const CXIStep2 = () => (
    <Step.Step currentStep={2}>
        <Industries />
    </Step.Step>
);
