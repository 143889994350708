import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Ranking from './index';

export const routes = [
    {
        exact: true,
        path: '/ranking/latam/investor',
        component: Ranking.InvestorIndex,
    },
    {
        exact: true,
        path: '/ranking/latam/investor/step1',
        component: Ranking.InvestorStep1,
    },
    {
        exact: true,
        path: '/ranking/latam/investor/step2',
        component: Ranking.InvestorStep2,
    },
    {
        exact: true,
        path: '/ranking/latam/investor/step3',
        component: Ranking.InvestorStep3,
    },
    {
        exact: true,
        path: '/ranking/latam/investor/step3/add',
        component: Ranking.InvestorStep3Add,
    },
    {
        exact: true,
        path: '/ranking/latam/investor/step3/edit/:id',
        component: Ranking.InvestorStep3Edit,
    },
];

export const RankingInvestorRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default RankingInvestorRoutes;
