import React from 'react';

import * as Form from 'components/form';

export function RankingTermForm(props) {
    const { form, onSubmit } = props;

    // Create form fields object
    const fields = {
        accept: {
            error: form.errors.accept,
            label: props.t('glossary:acceptTermRanking'),
            name: 'accept',
            type: 'checkbox',
            value: form.values.accept,
            onChange: form.handleChange
        }
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="RankingTermForm" onSubmit={evSubmit}>
            <Form.Field {...fields.accept} />
            <Form.Submt {...form} text={props.t('glossary:continue')} />
        </Form.Form>
    );
}
