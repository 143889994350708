import React from 'react';

import { useEffectOnce } from 'react-use';

import * as List from 'components/list';
import * as Page from 'components/page';

import { PostCard } from '../components/post/PostCard';
import * as Styled from './StyledNewsIndex';

export function NewsIndex(props) {
    const { vars, getPosts } = props.useNews;
    const { posts } = vars;

    // Get posts from the api only when the component renders
    useEffectOnce(() => {
        getPosts();
    });

    // Separate posts on different variables
    const featureds = posts.slice(0, 1);
    const others = posts.slice(1, posts.length);

    return (
        <Styled.NewsIndex>
            <Page.Page name="NewsIndex" loading={!posts.length}>
                <Styled.Background>
                    <Page.Title>{props.t('NewsIndex.title')}</Page.Title>
                    <Page.Title className="reflection">{props.t('NewsIndex.title')}</Page.Title>
                    <Styled.MuchoLoco>
                        <span className="g">{props.t('NewsIndex.green')}</span>
                        <span className="p">{props.t('NewsIndex.pink')}</span>
                        <span className="b">{props.t('NewsIndex.blue')}</span>
                    </Styled.MuchoLoco>
                </Styled.Background>

                <PostCard type="featured" post={featureds[0]} />

                <List.List name="NewsIndexList">
                    <List.Vertical items={others} itemKey="id" itemComponent={PostCard} />
                </List.List>
            </Page.Page>
        </Styled.NewsIndex>
    );
}
export default NewsIndex;
