import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

import bg from 'assets/images/bg-image.png';

export const EventCard = styled.div.attrs({
    className: 'event-card'
})`
    align-items: stretch;
    background: #fff;
    border: 0.5rem solid rgba(0, 0, 0, 0.1);
    display: block;
    color: #444;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 20rem;

    &:active,
    &:focus,
    &:hover {
        color: #444;
        text-decoration: none;
    }
`;

export const EventCardDated = styled.div.attrs({
    className: 'event-card-dated-holder'
})``;

export const CardDated = styled.div.attrs({
    className: 'event-card-dated'
})`
    align-items: center;
    background: #f2f2f2;
    display: inline-flex;
    height: 4rem;
    padding: 0 1rem;
    position: absolute;
    ${positions(0, 'auto', 'auto', '50%')}
    transform: translate(-50%, -50%);

    & + .bold {
        margin-top: 2rem;
    }

    .dated,
    .split {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1;
        text-align: center;
    }
    .dated .dd {
        display: block;
    }
    .dated .mm {
        display: block;
        font-size: 1rem;
        line-height: 1;
        margin-top: 0.2rem;
        text-transform: uppercase;
    }

    .split {
        display: block;
        padding: 0 1rem;
    }
`;

export const CardImage = styled.div.attrs({
    className: 'event-card-image'
})`
    flex: 0 0 15.5rem;
    height: 15.5rem;
    margin: -0.5rem -0.5rem 0;
    position: relative;

    &::before {
        background: transparent no-repeat 50% 50%;
        background-image: url(${bg});
        background-size: cover;
        content: '';
        opacity: 0.3;
        position: absolute;
        ${positions(0, 0, 0, 0)}
    }
    &::after {
        background: transparent no-repeat 50% 50%;
        background-image: url('${props => props.img}');
        background-size: contain;
        background-origin: content-box;
        content: '';
        padding: 1.5rem 1.5rem 2.5rem;
        position: absolute;
        ${positions(0, 0, 0, 0)}
    }
`;

export const CardInfos = styled.div.attrs({
    className: 'event-card-infos'
})`
    align-items: stretch;
    flex: 1 1 auto;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    position: relative;
    text-align: left;

    .bold {
        color: var(--primary);
        display: block;
        font-size: 1.2rem;
        font-weight: 600;
        ${truncate(1)}
        text-transform: uppercase;
    }

    .name {
        display: block;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.4;
        margin-top: 0.5rem;
        ${truncate(3, 1.4)}
    }

    .bttn {
        margin-top: auto;
        padding-top: 2rem;
    }
`;
