import React from 'react';
import { formatDate } from 'utils/date';

import * as Styled from './StyledMatchReceived';

export const MatchReceived = ({ match }) => {
    const { history } = match;

    let date = history.mentoringRequestedDate ?? match.regDate;
    let dated = formatDate(new Date(date), "dd/MM H'h'mm");
    let title = formatDate(new Date(date), "'Enviado dia' dd/MM/yyyy 'às' H'h'mm");

    if (history.mentoringAcceptedDate) {
        date = history.mentoringAcceptedDate;
        dated = formatDate(new Date(date), "dd/MM H'h'mm");
        title = formatDate(new Date(date), "'Aceito dia' dd/MM/yyyy 'às' H'h'mm");
    }
    if (history.mentoringRejectedDate) {
        date = history.mentoringRejectedDate;
        dated = formatDate(new Date(date), "dd/MM H'h'mm");
        title = formatDate(new Date(date), "'Rejeitado dia' dd/MM/yyyy 'às' H'h'mm");
    }

    return (
        <Styled.MatchReceived title={title}>
            <span className="text">{dated}</span>
        </Styled.MatchReceived>
    );
};
