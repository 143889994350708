import { useData } from 'modules/corporatehub/context/dataContext';
import React from 'react';
import * as Styled from './StyledCorporateHubChallenges';
import LeftSession from './components/LeftSession';
import RightSession from './components/RightSession';

export default function CorporateHubChallengesView(props) {
    const dataContext = useData();
    const { challengesPageVars } = dataContext.state;
    const { company } = dataContext.state;

    const companyName = company.isCxiGroup ? `do grupo ${company.name}` : `da ${company.name}`;
    return (
        <Styled.SessionContainer>
            <LeftSession data={challengesPageVars} />
            <RightSession data={challengesPageVars} companyName={companyName} />
        </Styled.SessionContainer>
    );
}
