import styled from 'styled-components';

export const ChallengeChat = styled.div`
    text-align: center;
    gap: 1.2rem 1rem;

    .m-container-feet {
        flex-flow: inherit;
        display: flex;
    }

    .dark-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999;
    }

    .warning-message {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        z-index: 1000;
    }
`;

export const Descr = styled.div`
    font-size: 1.4rem;
    line-height: 1.4;
`;

export const Title = styled.div`
    color: #005995;
    font-size: 20px;
    font-weight: 700;
`;

export const SubTitle = styled.div`
    color: #252830;
    font-size: 14px;
    font-weight: 400;
`;

export const Avatar = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: -5rem;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #fff;

    .user-avatar-holder {
        border: 3px solid #fff;
    }
`;
