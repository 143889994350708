import React from 'react';
// import axios from 'axios';
import { useEffectOnce } from 'react-use';

// import { CA } from 'contexts';
import { useApi } from 'hooks';
import { RHP } from 'providers';

export function useStartupId(startupId) {
    const api = useApi();
    // const { state } = React.useContext(CA);
    // const { dispatch } = React.useContext(CG);

    // Initial values for the state
    const initial = {
        startup: {},
        startupId: startupId || '',
        matchId: '',
        evaluationId: '',
        speedDatingId: '',
        speedDating: {},
        evaluation: {},
        startupForm: {
            startupName: '',
            wasNameChanged: false,
            startupNameObs: '',
            location: '',
            cnpj: '',
            foundingYear: '',
            noCnpj: false,
            isStartupActive: false,
            seekingInvestment: true,
            seekingHowMuch: '',
            externalInvestment: false,
        },
        advancedForm: {
            acquisitions: [],
            interests: [],
            causes: [],
            otherCause: '',
            howCanYouHelp: '',
            howCanWeHelp: '',
        },
        founders: [],
        investments: [],
    };

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);
    const [error, setError] = React.useState();

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    /**
     * HANDLER FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function saveEditedFields(values) {
        const startupForm = {
            startupName: values.startupName,
            startupNameObs: values.startupNameObs,
            location: values.location,
            cnpj: values.cnpj,
            foundingYear: values.foundingYear,
            noCnpj: values.noCnpj,
            financial: values.financial,
            seekingInvestment: values.seekingInvestment,
            seekingHowMuch: values.seekingHowMuch,
            wasNameChanged: values.wasNameChanged,
            externalInvestment: values.externalInvestment,
        };
        const advancedForm = {
            isStartupActive: values.isStartupActive,
            corpsIncomePercentage: values.corpsIncomePercentage,
            wasAcquired: values.wasAcquired,
            acquisitions: values.acquisitions,
            interests: values.interests,
            causes: values.causes,
            otherCause: values.otherCause,
            howCanWeHelp: values.howCanWeHelp,
            howCanYouHelp: values.howCanYouHelp,
        };
        return addVars({
            startupForm,
            advancedForm,
        });
    }

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function getStartup() {
        return api
            .get(`/startups/${startupId}`)
            .then(res => {
                addVars({
                    startup: res,
                    startupForm: {
                        startupName: res.name,
                        wasNameChanged: Boolean(res.nameChanges),
                        startupNameObs: res.nameChanges,
                        location: res.location,
                        cnpj: res.demographics.status.cnpj,
                        foundingYear: String(res.demographics.status.founding),
                        noCnpj: res.demographics.status.noTaxId,
                        financial: res.scaleup.financial,
                        seekingInvestment:
                            res.demographics.financial.seekingInvestment === 1 && 'true',
                        seekingHowMuch: res.demographics.financial.seekingHowMuch,
                        externalInvestment: res.demographics.financial.invested === 1,
                    },
                });
                return res;
            })
            .catch(err => {
                console.log(err);
                setError(err.message);
                return Promise.reject(new Error('Error getting startup'));
            });
    }

    async function putStartup(payload) {
        return api
            .put(`/startups/${startupId}`, payload)
            .then(getStartup)
            .catch(err => {
                setError(err.message);
            });
    }

    async function getInvestments() {
        return api
            .get(`/startups/${startupId}/investments`)
            .then(res => {
                addVars({ investments: res });
                return res;
            })
            .catch(err => {
                return setError(err.message);
            });
    }

    async function postInvestment(payload) {
        return api.post(`/startups/${startupId}/investments`, payload).then(getInvestments);
    }

    async function putInvestment(payload) {
        return api.post(`/startups/${startupId}/investments`, payload).then(getInvestments);
    }

    function deleteInvestment(investmentId) {
        return api
            .delete(`/startups/${startupId}/investments/${investmentId}`)
            .then(getInvestments);
    }

    async function getFounders() {
        return api
            .get(`/startups/${startupId}/founders`)
            .then(res => {
                addVars({ founders: res });
                return res;
            })
            .catch(err => {
                return setError(err.message);
            });
    }

    async function postFounder(payload) {
        return api.post(`/startups/${startupId}/founders`, payload).then(getFounders);
    }

    async function deleteFounder(founderId) {
        return api.delete(`/startups/${startupId}/founders/${founderId}`).then(getFounders);
    }

    async function getStartupAdvanced(skipState) {
        return api.get(`/startups/${startupId}/advanced`).then(res => {
            if (!skipState) {
                addVars({
                    advancedForm: {
                        isStartupActive: res.isActive,
                        corpsIncomePercentage: String(res.percentageCorporate),
                        wasAcquired: res.wasAcquired,
                        acquisitions: res.acquisitions,
                        interests: res.interests,
                        causes: res.causes,
                        otherCause: res.otherCause,
                        howCanWeHelp: res.howCanWeHelp,
                        howCanYouHelp: res.howCanYouHelp,
                    },
                });
            }
            return res;
        });
    }

    async function putStartupAdvanced(payload, skipState = false) {
        return api
            .put(`/startups/${startupId}/advanced`, payload)
            .then(() => getStartupAdvanced(skipState));
    }

    async function deleteMerger(mergerId) {
        const { acquisitions } = vars.advancedForm;
        const newAcquisitions = acquisitions.filter(elem => elem._id !== mergerId);

        const payload = {
            acquisitions: newAcquisitions,
        };

        return putStartupAdvanced(payload);
    }

    async function getUserFeedback({ evaluationId }) {
        return api
            .get(`/startups/${startupId}/evaluations/${evaluationId}`)
            .then(res => {
                addVars({ evaluation: res });
                return res;
            })
            .catch(err => {
                return setError(err.message);
            });
    }

    async function putStartupFeedback(payload) {
        return api.put(
            `/startups/${startupId}/matches/${vars.matchId}/evaluations/${vars.evaluationId}`,
            payload
        );
    }

    async function getUserSpeedDating({ matchId, speedDatingId }) {
        return api
            .get(`/startups/${startupId}/matches/${matchId}/speed-datings/${speedDatingId}`)
            .then(res => {
                addVars({ speedDating: res });
                return res;
            })
            .catch(err => {
                return setError(err.message);
            });
    }

    async function putStartupSpeedDating(payload) {
        return api.put(
            `/startups/${startupId}/matches/${vars.matchId}/speed-datings/${vars.speedDatingId}`,
            payload
        );
    }

    async function getContacts(pageSize, offset) {
        return api
            .get(`/startups/${startupId}/contacts?&limit=${pageSize}&offset=${offset}`)
            .then(res => {
                addVars({ contacts: res });
                return res;
            })
            .catch(err => {
                return setError(err.message);
            });
    }

    useEffectOnce(() => {
        // Initialize the module's service
        Promise.all([getStartup(), getStartupAdvanced(), getInvestments(), getFounders()])
            .then(res => setReady(true))
            .catch(err => {
                console.error('useStartupId', err);
                RHP.replace('/startup');
            });
    });

    return {
        // Ready to load?
        ready,
        error,
        // Variables handlers
        addVars,
        setVars,
        vars,
        startupId: vars.startupId,
        // handler functions
        saveEditedFields,
        // custom functions
        getContacts,
        // Startup
        getStartup,
        putStartup,
        // Investments
        getInvestments,
        postInvestment,
        putInvestment,
        deleteInvestment,
        // Founders
        getFounders,
        postFounder,
        deleteFounder,
        // StartupAdvanced
        getStartupAdvanced,
        putStartupAdvanced,
        // Mergers
        deleteMerger,
        // Evaluations
        getUserFeedback,
        putStartupFeedback,
        // SpeedDatings
        getUserSpeedDating,
        putStartupSpeedDating,
    };
}
export default useStartupId;
