import React from 'react';
import { useCXI } from 'modules/cxi/useCXI';
import { Description } from './description/Description';
import { Context } from './context/Context';
import * as Styled from './StyledInscribedStartup';
import { StartupProfile } from './card/StartupProfile';
import { ButtonAction } from './actionButton/Button';

export const InscribedStartup = props => {
    const { vars, t } = useCXI();
    const { startups } = vars;
    const { filters } = props;

    return (
        <Styled.InscribedStartup>
            <Styled.InscribedStartupThead>
                <Styled.InscribedStartupTr>
                    <Styled.InscribedStartupTh>{t('challenge.titles.1')}</Styled.InscribedStartupTh>
                    <Styled.InscribedStartupTh1>
                        {t('challenge.titles.2')}
                    </Styled.InscribedStartupTh1>
                    <Styled.InscribedStartupTh2>
                        {t('challenge.titles.3')}
                    </Styled.InscribedStartupTh2>
                    <Styled.InscribedStartupTh>{t('challenge.titles.4')}</Styled.InscribedStartupTh>
                </Styled.InscribedStartupTr>
            </Styled.InscribedStartupThead>

            <Styled.InscribedStartupTbody>
                {startups?.length > 0 &&
                    startups?.map(startup => {
                        return (
                            <Styled.InscribedStartupTr key={startup._id}>
                                <Styled.InscribedStartupTd>
                                    <StartupProfile startup={startup} />
                                </Styled.InscribedStartupTd>

                                <Styled.InscribedStartupTd1>
                                    <Description startup={startup} />
                                </Styled.InscribedStartupTd1>

                                <Styled.InscribedStartupTd2>
                                    <Context startup={startup} />
                                </Styled.InscribedStartupTd2>

                                <Styled.InscribedStartupTd>
                                    <ButtonAction startup={startup} filters={filters} />
                                </Styled.InscribedStartupTd>
                            </Styled.InscribedStartupTr>
                        );
                    })}
            </Styled.InscribedStartupTbody>
        </Styled.InscribedStartup>
    );
};

export default InscribedStartup;
