import questionTypes from './questionTypes.json';

export function parseQuestionType(type) {
    return questionTypes[type];
}

export function parseQuestionAnswers(answers) {
    return answers.map(answer => ({ name: answer.text, value: String(answer._id) }));
}

export function parseUserAnswers(fields, values) {
    return fields.map(field => {
        const answer =
            typeof values[field.name] === 'string' ? [values[field.name]] : values[field.name];

        return {
            questionId: field.id,
            answer,
        };
    });
}

export function parseRadioAnswer(answer) {
    return Array.isArray(answer) ? answer[0] : answer;
}
