import React from 'react';
import * as Styled from './StyledFormInput';

export const FormInputPassword = props =>
    // Don't show this element if it is empty
    !props.input ? null : (
        <Styled.FormInputAbsolute name="password">
            <input className="form-control" {...props.input} />
            {props.children}
        </Styled.FormInputAbsolute>
    );
export const password = FormInputPassword;
export default FormInputPassword;
