import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RouterHistoryProvider as RHP } from 'providers';
import * as Styled from './StyledPageNavbar';

const StyledPageNavbar = ({ children }) => (
    <Styled.PageNavbar>
        <Styled.PageNavbarContainer>{children}</Styled.PageNavbarContainer>
    </Styled.PageNavbar>
);

export function PageNavbar(props) {
    const { t } = useTranslation('glossary');

    // Figure out what the button should do
    let propsButton = { onClick: () => RHP.goBack() };
    if (props.back !== undefined) {
        propsButton = { as: Link, to: props.back };
    }
    // If the back property is false, don't show the component
    return props.back === false ? null : (
        <StyledPageNavbar>
            {/* Create the back button based on 'back' props */}
            <Styled.PageNavbarBack {...propsButton}>
                <span className="icon far fa-arrow-left" aria-hidden="true" />
                <span className="text sr-only">{t('back')}</span>
                {props.customBackText && (
                    <Styled.ButtonText>{props.customBackText}</Styled.ButtonText>
                )}
            </Styled.PageNavbarBack>

            {/* Add a title to the navbar element */}
            <Styled.PageNavbarText>{props.title || ''}</Styled.PageNavbarText>
        </StyledPageNavbar>
    );
}
export default PageNavbar;
