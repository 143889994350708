import React from 'react';

import * as List from 'components/list';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledSupport';

export const Support = () => {
    const { t } = useCXI();

    // Create the secondary actions options
    const sac = [
        {
            as: 'a',
            icon: 'fa fa-question-circle',
            href: 'https://helpme.openstartups.net/hc/pt-br',
            text: t('support.sac'),
        },
    ];
    const actions = [
        {
            as: 'a',
            icon: 'fab fa-whatsapp',
            href: 'https://wa.me/551135005001',
            text: t('support.whatsapp'),
        },
        {
            as: 'a',
            icon: `fa fa-envelope`,
            href: 'mailto:helpme@openstartups.net',
            text: t('support.email'),
        },
        {
            as: 'a',
            icon: 'fab fa-facebook-messenger',
            href: 'http://www.facebook.com/messages/t/1616610695217236',
            text: t('support.messenger'),
        },
    ];

    return (
        <Styled.Support as={Container.Slim}>
            <Container.Head>
                <span className="text">{t('menu.support')}</span>
            </Container.Head>

            <Container.Body>{t('support.descr')}</Container.Body>

            <Container.Feet>
                <List.List name="SupportList">
                    <List.Vertical items={sac} itemKey="icon" />
                </List.List>
                <p>{t('support.part1')}</p>
                <p>{t('support.part2')}</p>
                <List.List name="SupportList">
                    <List.Vertical items={actions} itemKey="icon" />
                </List.List>
            </Container.Feet>
        </Styled.Support>
    );
};
