import React from 'react';

import { useStartupCXI } from '../../useStartupCXI';

import { Container } from '../container';
import * as Styled from './StyledGuide';

export const Guide = () => {
    const { t } = useStartupCXI();

    return (
        <Styled.Guide as={Container.Slim}>
            <Container.Head>
                <span className="text">{t('menu.guide')}</span>
            </Container.Head>

            <Container.Body>
                <iframe
                    src="https://www.youtube-nocookie.com/embed/_Tlp8FWGC9M?controls=0"
                    title="Como começar a contribuir com Startups"
                />
            </Container.Body>

            <Container.Feet>
                <p>{t('guide.title')}</p>

                <p>{t('guide.part1')}</p>

                <p>{t('guide.part2')}</p>

                <p>{t('guide.part3')}</p>

                <p>{t('guide.part4')}</p>
            </Container.Feet>
        </Styled.Guide>
    );
};
