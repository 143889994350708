import React from 'react';

import { CG } from 'contexts';
import { LSP } from 'providers';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';

import { Container } from '../container';
import * as Styled from './StyledSpeedDatingForm';

export const SpeedDatingForm = () => {
    const { state: global } = React.useContext(CG);
    const { t, vars, modal, setModal, postMatchMessage, postMatchSpeedDating } = useCXI();
    const { match } = modal;

    const form = useForm({
        reason: LSP.get(`${match.matchid}_SPEEDDATING_FORM_REASON`) ?? '',
        themes: LSP.get(`${match.matchid}_SPEEDDATING_FORM_THEMES`) ?? '',
    });

    const fields = {
        reason: {
            error: form.errors.reason,
            name: 'reason',
            type: 'textarea',
            topLabel: t('modal.SpeedDatingForm.reason.field'),
            label: t('modal.SpeedDatingForm.reason.placeholder'),
            required: true,
            value: form.values.reason,
            onChange: ev => {
                LSP.set(`${match.matchid}_SPEEDDATING_FORM_REASON`, ev.target.value);
                return form.handleChange(ev);
            },
        },
        themes: {
            error: form.errors.themes,
            name: 'themes',
            type: 'input',
            label: t('modal.SpeedDatingForm.themes.field'),
            helper: t('modal.SpeedDatingForm.themes.placeholder'),
            required: true,
            value: form.values.themes,
            onChange: ev => {
                LSP.set(`${match.matchid}_SPEEDDATING_FORM_THEMES`, ev.target.value);
                return form.handleChange(ev);
            },
        },
    };

    const onSubmit = async values => {
        const { language } = global;
        try {
            // Set the speed-dating status
            const commons = { language, cxiStage: vars.cxi.currentStage, mentoring: false };
            const SpeedDatingPayload = { ...commons, ...values };
            await postMatchSpeedDating(match.matchid, SpeedDatingPayload);

            // Send message in chat
            const messagePayload = {
                lang: language,
                text: t('modal.SpeedDatingForm.message', {
                    message: values.reason,
                    startup: match.startup.name,
                    themes: values.themes,
                }),
            };
            await postMatchMessage(match.matchid, messagePayload);
            LSP.del(`${match.matchid}_SPEEDDATING_FORM_REASON`);
            LSP.del(`${match.matchid}_SPEEDDATING_FORM_THEMES`);
            setModal({ match, modal: 'SpeedDatingSent' });
        } catch (e) {
            form.setSubmit(false);
        }
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    const isSubmitDisabled = form.submit || !form.values.reason;

    return (
        <Styled.SpeedDatingForm as={Container}>
            <Form.Form onSubmit={evSubmit}>
                <Container.Head image={match.startup.logoUrl}>
                    <span className="text">{t('modal.SpeedDatingForm.title')}</span>
                </Container.Head>

                <Container.Body>
                    <Styled.Descr>
                        {t('modal.SpeedDatingForm.description', { startup: match.startup.name })}
                    </Styled.Descr>

                    {/* <Styled.SpeedDatingFormField>
                        <Form.Field {...fields.reason} />
                    </Styled.SpeedDatingFormField> */}

                    <Form.Field {...fields.reason} />
                    <Form.Field {...fields.themes} />
                </Container.Body>

                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.SpeedDatingForm.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.SpeedDatingForm>
    );
};
