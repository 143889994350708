import styled from 'styled-components';

export const OiweekSuccess = styled.div.attrs({
    className: 'oiweek-success',
})`
    .component-page-container {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const OiweekSuccessBody = styled.div.attrs({
    className: 'oiweek-success-body',
})`
    background: #f2f2f2;
    color: #444;
    flex: 1 1 auto;
    margin-top: 3rem;
    padding-top: 1rem;
    position: relative;
    text-align: center;

    background: #f2f2f2;
    color: #444;
    flex: 1 1 auto;
    margin-top: 3rem;
    padding-top: 1rem;
    position: relative;
    text-align: center;

    @media (min-width: 768px) {
        text-align: left;
    }

    &::before {
        background: inherit;
        content: '';
        position: absolute;
        top: 0;
        bottom: calc(-5.9rem - var(--ion-safe-area-bottom));
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
    }

    & > * {
        position: relative;
    }
`;

export const OiweekButton = styled.a.attrs({
    className: 'oiweek-button',
})`
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.8rem;
    box-shadow: 0 0 2rem rgba(255, 255, 255, 0.95);
    background: #fff;
    color: #444;
    display: block;
    margin-top: 2rem;
    padding: 1rem;
    text-align: center;

    .bttn {
        background: var(--primary);
        margin-top: 0.625rem;
    }

    .name {
        color: var(--primary);
        display: block;
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    .numb {
        display: block;
        font-weight: 700;
        margin-top: 0.5rem;
    }
    .text {
        display: block;
    }
`;
