import React from 'react';
import { useClickAway } from 'react-use';

import { useStartupCXI } from '../../useStartupCXI';
import * as Styled from './StyledContainer';

export const ContainerSlim = ({ children, ...props }) => {
    const { setModal } = useStartupCXI();

    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
        setModal(null);
    });

    return (
        <Styled.ContainerSlim {...props} ref={containerRef}>
            <Styled.Close onClick={() => setModal(null)}>
                <span className="icon far fa-times" aria-hidden="true" />
            </Styled.Close>

            {children}
        </Styled.ContainerSlim>
    );
};
