import styled, { css } from 'styled-components';

interface ContainerProps {
    variant:
        | 'default'
        | 'tag'
        | 'outline'
        | 'gradient'
        | 'success'
        | 'toggle'
        | 'purple'
        | 'claret'
        | 'yellow'
        | 'gray'
        | 'pink';
    size: 'small' | 'medium' | 'large' | 'table' | 'modal' | 'action';
}

const renderButtonSize = {
    small: css`
        padding: 0.75rem 1.25rem;
        font-weight: 600;
        font-size: 14px;
    `,
    medium: css`
        padding: 0.75rem 1.95rem;
        font-weight: 600;
        font-size: 14px;
    `,
    large: css`
        padding: 1.25rem 5rem;
        font-size: 18px;
        font-weight: bold;
    `,
    table: css`
        padding: 0.25rem 1rem;
        font-size: 12px;
    `,
    modal: css`
        padding: 0.625rem 1.25rem;
        font-size: 20px;
        font-weight: bold;
    `,
    action: css`
        padding: 1.25rem 1.1rem;
        font-size: 16px;
        font-weight: bold;
        border-radius: 12px;
    `,
};

const renderButtonVariant = {
    default: css`
        background: ${({ theme }) => theme.colors.energyBlue};
        color: #fff;

        &:hover {
            background-color: #4598ab;
        }
    `,
    tag: css`
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.black};

        &:hover {
            cursor: default;
        }
    `,
    purple: css`
        background: ${({ theme }) => theme.colors.deepBlue};
        color: #fff;

        &:hover {
            box-shadow: inset 0px 2px 3px ${({ theme }) => theme.colors.deepBlue};
        }
    `,
    yellow: css`
        background: ${({ theme }) => theme.colors.yellowDarker};
        color: #fff;

        &:hover {
            box-shadow: inset 0px 2px 3px ${({ theme }) => theme.colors.yellowDarker};
        }
    `,
    outline: css`
        background: transparent;
        color: #0094F9;

        border: 1px solid #c3c6d0;

        &:hover {
            background: transparent;
            color: #3fa9f5;
            border-color: #3fa9f5;
        }
    `,
    gradient: css`
        background: transparent linear-gradient(75deg, #5438ff 0%, #a53a87 100%) 0% 0% no-repeat
            padding-box;
        color: #fff;

        &:hover {
            box-shadow: inset 0px 2px 3px #2a0ddf66;
        }
    `,
    success: css`
        background: #00d672;
        color: #fff;

        &:hover {
            background: #00c267;
        }
    `,
    toggle: css`
        background: #e93535;
        color: #fff;
    `,
    claret: css`
        background: #a53a87;
        color: #fff;

        &:hover {
            opacity: 0.8;
        }
    `,
    pink: css`
        background: #8b39ae;
        color: #fff;

        &:hover {
            box-shadow: inset 0px 2px 3px #8b39ae;
        }
    `,
    gray: css`
        background: #e0e0e0;
        color: #adadad;
        cursor: default !important;

        &:hover {
            background: #e0e0e0;
        }
    `,
};

export const Container = styled.button<ContainerProps>`
    padding: 0.75rem 1.75rem;
    background: red;
    border: none;
    border-radius: 40px;
    width: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    outline: none !important;
    transition: 0.2s;

    ${({ variant }) => renderButtonVariant[variant]}
    ${({ size }) => renderButtonSize[size]}

    .icon {
        width: 16px;
        height: 16px;
        margin-right: 0.75rem;
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;
