import React from 'react';
import * as Styled from './StyledSessions';
import BubbleChart from './BubbleChart';

function LeftSession({ data, companyName }) {
    const relationshipsPageVars = data;
    const bubbleData = relationshipsPageVars?.bubbleChartData;
    return (
        <Styled.LeftSession>
            <Styled.Container>
                <Styled.Title>
                    <h1>Startups com mais intensidade de open innovation com {companyName}</h1>
                </Styled.Title>
                <Styled.BubbleChartContainer id="fluid">
                    <BubbleChart data={bubbleData} />
                </Styled.BubbleChartContainer>
            </Styled.Container>
        </Styled.LeftSession>
    );
}

export default LeftSession;
