import styled from 'styled-components';
import { positions } from 'utils/styles';

export const StartupImage = styled.div.attrs({
    className: 'startup-image-image',
})`
    background: #eee no-repeat 50% 50%;
    background-image: url(${props => props.img});
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 0.1rem;
    display: block;
    position: absolute;
    ${positions(0, 0, 0, 0)}
`;

export const StartupImageHolder = styled.div.attrs({
    className: 'startup-image',
})`
    border-radius: 50%;
    display: block;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
`;
