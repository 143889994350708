import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?:
        | 'default'
        | 'tag'
        | 'purple'
        | 'outline'
        | 'gradient'
        | 'success'
        | 'toggle'
        | 'claret'
        | 'yellow'
        | 'gray'
        | 'pink';
    size?: 'small' | 'medium' | 'large' | 'table' | 'modal' | 'action';
}

const Button: React.FC<ButtonProps> = ({
    children,
    variant = 'default',
    size = 'medium',
    ...rest
}) => {
    return (
        <Container size={size} variant={variant} type="button" {...rest}>
            {children}
        </Container>
    );
};

export default Button;
