import React from 'react';

import { useForm } from 'components/form';

import * as Form from 'components/form';
import * as Page from 'components/page';

import { RHP } from 'providers';
import { BasicDataSection } from '../components/BasicDataSection';
import { FounderSection } from '../components/FounderSection';
import { IncomeSection } from '../components/IncomeSection';
import { InvestmentSection } from '../components/InvestmentSection';
import { MergerSection } from '../components/MergerSection';
import { InterestSection } from '../components/InterestSection';

import * as Styled from './StyledAdvancedEdit';

export const AdvancedEdit = props => {
    const { vars, putStartupAdvanced, putStartup } = props.useStartupId;
    const { startup } = vars;

    // Initialize a new form handler
    const form = useForm({
        ...vars.startupForm,
        ...vars.advancedForm,
    });

    // Handle form submitions
    const onSubmit = values => {
        const startupPayload = {
            name: values.startupName,
            nameChanges: values.startupNameObs,
            location: {
                country: values.location.country.id,
                state: values.location.state.id,
                city: values.location.city.id,
            },
            demographics: {
                status: {
                    cnpj: values.cnpj,
                    noTaxId: values.noCnpj,
                    founding: new Date(String(values.foundingYear)).getUTCFullYear(),
                },
                financial: {
                    invested: values.externalInvestment === 'true' ? 1 : 2,
                    seekingInvestment: values.seekingInvestment === 'true' ? 1 : 2,
                    seekingHowMuch: values.seekingHowMuch,
                },
            },
            scaleup: {
                financial: values.financial
                    .filter(elem => elem.revenues || elem.employees)
                    .sort((a, b) => a.year - b.year),
            },
            language: startup.language,
        };

        const advancedFormPayload = {
            language: startup.language,
            isActive: values.isStartupActive,
            percentageCorporate: Number(values.corpsIncomePercentage),
            wasAcquired: values.wasAcquired,
            acquisitions: values.aquisitions,
            interests: values.interests,
            causes: values.causes,
            otherCause: values.otherCause,
            howCanWeHelp: values.howCanWeHelp,
            howCanYouHelp: values.howCanYouHelp,
        };

        putStartup(startupPayload).then(() => {
            putStartupAdvanced(advancedFormPayload).then(() =>
                RHP.push(`/startup/${vars.startupId}`)
            );
        });
    };

    const propsForm = { ...props, form, onSubmit };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Page.Page name="AdvancedEdit">
            <Page.Title>{props.t('AdvancedEdit.title')}</Page.Title>
            <Page.Descr>{props.t('AdvancedEdit.descr')}</Page.Descr>

            <Form.Form name="StartupAdvancedEditForm" onSubmit={evSubmit}>
                <Styled.FormContent>
                    <BasicDataSection {...propsForm} />
                    <FounderSection {...propsForm} />
                    <IncomeSection {...propsForm} />
                    <InvestmentSection {...propsForm} />
                    <MergerSection {...propsForm} />
                    <InterestSection {...propsForm} />
                </Styled.FormContent>
                <Form.Submt {...form} text={props.t('glossary:save')} />
            </Form.Form>
        </Page.Page>
    );
};

export default AdvancedEdit;
