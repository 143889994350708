import React from 'react';

import { CG } from 'contexts';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { Button } from 'modules/cxi/components/button';

import { useStartupCXI } from '../../useStartupCXI';
import { Container } from '../container';
import * as Styled from './StyledMentoringFinish';

export const MentoringFinish = () => {
    const { state: global } = React.useContext(CG);
    const { t, modal, setModal, putMatchMentoring } = useStartupCXI();
    const { match } = modal;

    const form = useForm({});

    const fields = {
        comment: {
            error: form.errors.comment,
            name: 'comment',
            type: 'textarea',
            label: 'Por quê você gostaria de interromper a mentoria?',
            required: true,
            value: form.values.comment,
            onChange: form.handleChange,
        },
    };

    const onSubmit = async values => {
        const { language } = global;
        try {
            // Set the mentoring
            const commons = { language, cxiStage: 2 };
            const MentoringPayload = {
                ...commons,
                mentoring: 'finished',
                mentoringMessage: values.comment,
            };
            await putMatchMentoring(match.matchid, MentoringPayload);
            setModal({ match, modal: 'MentoringFinished' });
        } catch (e) {
            form.setSubmit(false);
        }
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    const isSubmitDisabled = form.submit || !form.values.comment;

    return (
        <Styled.MentoringFinish as={Container}>
            <Form.Form onSubmit={evSubmit}>
                <Container.Head>
                    <span className="text">{t('modal.MentoringFinish.title')}</span>
                </Container.Head>

                <Container.Body>
                    <Styled.Descr>
                        <span className="text">{t('modal.MentoringFinish.description')}</span>
                    </Styled.Descr>

                    <Styled.MentoringFinishField>
                        <Form.Field {...fields.comment} />
                    </Styled.MentoringFinishField>
                </Container.Body>

                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.MentoringFinish.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.MentoringFinish>
    );
};
