import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Menu = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 3.2rem 2.4rem;
    width: 100%;
    height: 100%;
`;

export const MenuAvatar = styled.button`
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    display: inline-flex;
    flex-direction: row;
    padding: 0;
    padding-right: 5px;
    position: relative;

    .icon {
        align-items: center;
        background: ${({ theme }) => theme.colors.white};
        border-radius: 50%;
        box-shadow: 0px 1px 6px #00000027;
        color: ${({ theme }) => theme.colors.deepBlue};
        display: flex;
        font-size: 1.2rem;
        height: 2.4rem;
        justify-content: center;
        position: absolute;
        ${positions('auto', 0, 0, 'auto')}
        width: 2.4rem;
        z-index: 200;
    }

    .user-avatar {
        width: 7rem;
        z-index: 100;
    }
`;
export const MenuCompany = styled.div.attrs({
    role: 'img'
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 50%;
    flex: 0 0 7rem;
    height: 7rem;
    margin-left: -2rem;
    padding: 0.8rem;
    position: relative;
    width: 7rem;
    z-index: 150;

    ${props => !props.img && 'display: none;'}
`;

export const MenuItems = styled.nav`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-top: 2.4rem;
    width: 100%;

    hr {
        border-top: 2px solid ${({ theme }) => theme.colors.lighterGray};
        margin: 1.2rem -2.4rem;
    }
`;
export const MenuItem = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    border-radius: 0.8rem;
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    gap: 1.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1.2rem 1.6rem;
    position: relative;
    text-align: left;

    &:focus,
    &:hover {
        background: ${({ theme }) => theme.colors.lighterGray};
        color: ${({ theme }) => theme.colors.deepBlue};
    }
    &.is-active {
        background: ${({ theme }) => theme.colors.lighterGray};
    }

    &.is-highlight {
        background: ${({ theme }) => theme.colors.deepBlue};
        border: 2px solid ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
        margin-top: 3.2rem;
        width: 100%;

        .icon {
            transform: translateY(1px);
        }

        &:focus,
        &:hover,
        &.is-active {
            background: transparent;
            color: ${({ theme }) => theme.colors.deepBlue};
        }

        & + & {
            margin-top: 0.8rem;
        }

        &.stage-1 {
            background: ${({ theme }) => theme.colors.stage1};
            border: 2px solid ${({ theme }) => theme.colors.stage1};

            &:focus,
            &:hover,
            &.is-active {
                background: transparent;
                color: ${({ theme }) => theme.colors.stage1};
            }
        }
        &.stage-2 {
            background: ${({ theme }) => theme.colors.stage2};
            border: 2px solid ${({ theme }) => theme.colors.stage2};

            &:focus,
            &:hover,
            &.is-active {
                background: transparent;
                color: ${({ theme }) => theme.colors.stage2};
            }
        }
        &.stage-3 {
            background: ${({ theme }) => theme.colors.stage3};
            border: 2px solid ${({ theme }) => theme.colors.stage3};

            &:focus,
            &:hover,
            &.is-active {
                background: transparent;
                color: ${({ theme }) => theme.colors.stage3};
            }
        }
    }

    .badge {
        align-items: center;
        background: ${({ theme }) => theme.colors.red};
        border-radius: 9999px;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        font-size: 1rem;
        justify-content: center;
        line-height: 1;
        margin: -0.8rem 0 0 1.2rem;
        min-width: 1.65rem;
        padding: 0.4rem 0.6rem 0.25rem;
        position: absolute;
        ${positions('50%', 'auto', 'auto', '1.6rem')}
        transform: translateY(-50%);
    }
    .icon {
        font-size: 2rem;
    }
`;

export const Name = styled.div`
    color: ${({ theme }) => theme.colors.deepBlue};
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 2rem;
    text-align: center;
    word-wrap: break-word;
    text-overflow: ellipsis;
`;

export const Desc = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 0.8rem;
`;

export const Update = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    gap: 0.6rem;
    margin-top: 1.6rem;
    padding: 0;

    .icon {
        font-size: 0.9rem;
    }

    &[disabled] {
        opacity: 0.5;
    }
`;
export const PocBadge = styled.div`
    align-items: center;
    background: #56c1d6;
    border-radius: 0px 7.26837px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 1;
    position: absolute;
    top: 0.6rem;
    right: -0.2rem;
    padding: 3px 7px;
    text-transform: uppercase;
    z-index: 1000;
`;
