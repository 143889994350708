import React from 'react';
import { useEffectOnce } from 'react-use';

import { CONFIG } from 'config';

import * as Styled from './StyledPageTitle';

export function PageTitle(props) {
    const { appName } = CONFIG;

    useEffectOnce(() => {
        // Udpdate the document title
        if (props.children) {
            const title = props.children.toString();
            document.title = `${title} - ${appName}`;
        }
    });

    return (
        <Styled.PageTitle className={props.className}>
            <span className="text">{props.children}</span>
        </Styled.PageTitle>
    );
}
export const Title = PageTitle;
export default PageTitle;
