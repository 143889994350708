import styled from 'styled-components';

export const CXIMentoring = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header.c-header--main {
        position: sticky;
        top: 0;
        z-index: 900;
    }

    .is-open & {
        width: calc(100vw - 32rem);
    }
`;

export const CXIMentoringRoutes = styled.div`
    margin-top: 3.2rem;
`;

export const Texts = styled.div`
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.energyBlue};
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 0 3.2rem;
    padding: 2.4rem;

    @media (min-width: 768px) {
        padding: 3.2rem;
    }

    p {
        color: ${({ theme }) => theme.colors.neutralDark};
        margin: 0;
    }

    .title {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 1.8rem;
        font-weight: 700;
    }
    .descr {
        font-weight: 700;
    }

    .footr {
        font-weight: 700;
    }

    button {
        margin-top: 1.2rem;
    }
`;
