import React from 'react';
import { useParams } from 'react-router';
import { useEffectOnce } from 'react-use';

import { CA } from 'contexts';
import { useForm } from 'components/form';
import { RHP } from 'providers';
import { isValidCPF, isValidCNPJ } from 'utils/validation';

import * as Page from 'components/page';

import { SubscriptionPayPerUseForm } from './SubscriptionPayPerUseForm';
import * as Styled from './StyledSubscriptionPayPerUse';

export const SubscriptionPayPerUse = props => {
    const { eventId } = useParams();
    const { vars, postEventPayPerUse, getEventPayPerUse } = props.useSubscription;
    const { eventPayPerUseTerm } = vars;

    useEffectOnce(() => {
        getEventPayPerUse(eventId);
    }, []);

    const { state } = React.useContext(CA);

    // Initialize a new form handler
    const form = useForm({
        email: state.user.email,
        name: state.user.fullName,
        taxIdType: 'CPF',
        taxId: '',
    });

    // Handle blur for CPF/CNPJ inputs
    const onBlur = {
        isValidCPF: ev => {
            const { name, value } = ev.target;
            // Clear the errors and warnings before validating
            form.clearMessages({ name });
            // Only check if the value is filled
            if (!value) return false;
            // Check if the CPF is a valid one
            const cleanValue = value.replace(/[^0-9]/g, '');
            if (!isValidCPF(cleanValue)) {
                // Emit error message for registered email
                const error = props.t('EventPayPerUse.notValidCPF');
                return form.emitError({ name, error });
            }
            return true;
        },
        isValidCNPJ: ev => {
            const { name, value } = ev.target;
            // Clear the errors and warnings before validating
            form.clearMessages({ name });
            // Only check if the value is filled
            if (!value) return false;
            // Check if the CPF is a valid one
            const cleanValue = value.replace(/[^0-9]/g, '');
            if (!isValidCNPJ(cleanValue)) {
                // Emit error message for registered email
                const error = props.t('EventPayPerUse.notValidCNPJ');
                return form.emitError({ name, error });
            }
            return true;
        },
    };

    // Handle change on form fields
    const onChange = {
        taxIdType: ev => {
            const { value } = ev.target;
            const newEvent = { ...ev };
            newEvent.target.name = 'taxId';
            newEvent.target.value = form.values.taxId;
            if (value === 'CPF') {
                return onBlur.isValidCPF(newEvent);
            }
            if (value === 'CNPJ') {
                return onBlur.isValidCNPJ(newEvent);
            }
            form.clearMessages({ name: newEvent.target.name });
            return false;
        },
    };

    // Handle form submitions
    const onSubmit = values => {
        const termId = eventPayPerUseTerm._id;
        const payload = { ...values, signed: true, termId };
        postEventPayPerUse(payload).then(res => {
            RHP.push('/events');
            return true;
        });
    };

    const propsForm = { ...props, form, onBlur, onChange, onSubmit };
    return (
        <Styled.SubscriptionPayPerUse>
            <Page.Page name="eventPayPerUse">
                <Page.Title>{props.t('EventPayPerUse.title')}</Page.Title>
                <Page.Descr>{eventPayPerUseTerm.htmlFriendly}</Page.Descr>

                <SubscriptionPayPerUseForm {...propsForm} />
            </Page.Page>
        </Styled.SubscriptionPayPerUse>
    );
};
export default SubscriptionPayPerUse;
