import React from 'react';

import { OiweekSuccessSDFree } from './OiweekSuccessSDFree';
import { OiweekSuccessSDNone } from './OiweekSuccessSDNone';
import { OiweekSuccessSDPaid } from './OiweekSuccessSDPaid';

import * as Styled from './StyledOiweekSuccess';

export const OiweekSuccess = props => {
    const { vars } = props.useOiweek;
    const { user } = vars.oiweek;

    // Check if the user selected no speeddating?
    if (!user.speeddating) {
        user.speeddating = false;
    }
    return (
        <Styled.OiweekSuccess>
            {user.speeddating === false && <OiweekSuccessSDNone {...props} />}
            {user.speeddating === true && user.paid && <OiweekSuccessSDPaid {...props} />}
            {user.speeddating === true && !user.paid && <OiweekSuccessSDFree {...props} />}
        </Styled.OiweekSuccess>
    );
};
export default OiweekSuccess;
