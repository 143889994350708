import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { useForm } from 'components/form';
import * as Form from 'components/form';
import { Button } from 'modules/cxi/components/button';
import { Container } from '../container';
import * as Styled from './StyledReport';

export const Report = () => {
    const { t, modal, setModal } = useCXI();
    const { match, message } = modal;

    const form = useForm();

    const fields = {
        comment: {
            error: form.errors.comment,
            name: 'comment',
            type: 'textarea',
            label: t('modal.Report.comment.field'),
            placeholder: t('modal.Report.comment.commentPlaceholder'),
            required: true,
            value: form.values.comment,
            onChange: ev => {
                return form.handleChange(ev);
            }
        },
        unmatchStartup: {
            error: form.errors.applied,
            label: t('modal.Report.option'),
            name: 'unmatchStartup',
            required: false,
            type: 'checkbox',
            value: form.values.unmatchStartup,
            onChange: form.handleChange
        }
    };

    const onSubmit = async event => {
        try {
            event.preventDefault();
            setModal({ match, modal: 'ReportSent' });

            window.location.href = `mailto:helpme@openstartups.net?subject=Den%C3%BAncia de Mensagem Imprópria - 100-10-1 Chat&body=Startup Denunciada: ${
                match.startup.name
            } - ID da Startup Denunciada: ${
                match.startup.startupId
            }.%0D%0A%0D%0AMensagem Original enviada pela Startup: "${message}".
                %0D%0A%0D%0ADescrição da Denúncia: ${fields.comment.value}.
                ${
                    fields.unmatchStartup.value
                        ? '%0D%0A%0D%0AGostaria de desfazer o match com a startup.'
                        : ''
                }`;
        } catch (e) {
            console.log(e, 'ERROR');
            form.setSubmit(false);
        }
    };
    const isSubmitDisabled = form.submit || !form.values.comment;

    return (
        <Styled.ReportForm as={Container}>
            <Form.Form onSubmit={e => onSubmit(e)}>
                <Container.Head image={match.startup.logoUrl}>
                    <span className="text">{t('modal.Report.title')}</span>
                </Container.Head>
                <Container.Body>
                    <Styled.Descr>
                        <span className="text">{t('modal.Report.description')}</span>
                    </Styled.Descr>

                    <Styled.Space />

                    <Styled.Title>
                        <span className="text">{t('modal.Report.comment.title')}</span>
                    </Styled.Title>

                    <Styled.ReportFormField>
                        <Form.Field {...fields.comment} />
                    </Styled.ReportFormField>

                    <Styled.Space />

                    <Form.Field {...fields.unmatchStartup} />
                </Container.Body>
                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.Report.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.ReportForm>
    );
};
