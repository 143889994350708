import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledEvaluationsFeedback';

export const EvaluationsFeedback = ({ evaluation }) => {
    const { t } = useCXI();
    const feedback = evaluation?.startupFeedback;

    let ttip = '';
    let icon = '';
    if (feedback === 'positive') {
        icon = 'fa-laugh';
        ttip = 'POSITIVO';
    } else if (feedback === 'neutral') {
        icon = 'fa-meh';
        ttip = 'NEUTRO';
    } else if (feedback === 'negative') {
        icon = 'fa-frown';
        ttip = 'NEGATIVO';
    }

    // Pending Startup Feedback
    if (evaluation?.group && icon === '') {
        ttip = t('match.feedback.pending');
        icon = 'fa-user-clock';
    }

    return (
        <Styled.EvaluationsFeedback className={feedback}>
            {!icon ? (
                <span className="text">-</span>
            ) : (
                <span className={`icon far ${icon}`} aria-hidden="true" />
            )}
            {!!ttip && <span className="ttip">{ttip}</span>}
        </Styled.EvaluationsFeedback>
    );
};
