import React from 'react';
import { useEffectOnce } from 'react-use';
import { CA } from 'contexts';
import { useApi } from 'hooks';

export function useStartup(skipRelated) {
    const api = useApi();
    const { state } = React.useContext(CA);
    // Initial values for the state
    const initial = {
        startups: [],
    };

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    /**
     * HANDLER FUNCTIONS
     * ------------------------------------------------------------------------
     */

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    //  async function getStartup() {
    //     const { userid } = state.user;
    //     return api
    //         .get(`users/${userid}/startups`)
    //         .then(res => {
    //             addVars({ startups: res });
    //         })
    //         .catch(err => Promise.resolve('Error getting startups'));
    // }

    async function getStartups() {
        const { userid } = state.user;
        return api.get(`users/${userid}/startups`).then(res => {
            addVars({ startups: res });
        });
    }

    async function getMatchId(referralCode, startupId) {
        const matchId = await api.get(
            `startups/${startupId}/recommendation/${referralCode}?&isCxi=true`
        );

        return matchId;
    }

    useEffectOnce(() => {
        // Initialize the module's service
        Promise.all([getStartups()])
            .then(res => setReady(true))
            .catch(err => {
                console.error('useStartup', err);
                // RHP.replace('/home')
            });
    });

    return {
        // Ready to load?
        ready,
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Include vars functions
        // Custom functions
        getStartups,
        getMatchId,
        // putMatchById,
    };
}
export default useStartup;
