import { useEffectOnce } from 'react-use';
import { useParams } from 'react-router';

import { RHP } from 'providers';

export const CXIIndex = props => {
    const { startupId } = useParams();

    useEffectOnce(() => {
        RHP.replace(`/startup/${startupId}/100-10-1/feedbacks`);
    });

    return null;
};
