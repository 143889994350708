import styled from 'styled-components';

export const Area = styled.div`
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    overflow: hidden;
    height: auto;
`;

export const AreaHead = styled.div.attrs({
    className: 'area-head',
})`
    align-items: baseline;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    padding: 1.6rem;

    .text {
        color: ${({ theme }) => theme.colors.deepBlue};
        display: flex;
        flex-direction: column;
    }
    .text .title {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.1;
    }
    .text .descr {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.3;
        opacity: 0.75;
        margin-top: 0.4rem;
        transform: translateY(0.2rem);
    }
    .icon {
        margin-left: auto;
    }
`;

export const AreaBody = styled.div.attrs({
    className: 'area-body',
})`
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    gap: 1.6rem;
    padding: 1.6rem;

    @media (min-width: 992px) {
        padding: 2.4rem;
        height: 100%;
        white-space: pre-line;
    }
`;
