export const RANKING_CATEGORIES = [
    'AgriTechs',
    'Artificial Intelligence',
    'AutoTechs',
    'Big Data',
    'BioTechs',
    'Blockchain',
    'CityTechs',
    'CleanTechs',
    'ConstrucTechs',
    'Customer Service',
    'Cybersecurity and Identification',
    'Drones',
    'EdTechs',
    'EnergyTechs',
    'ESGTech',
    'FashionTechs',
    'FinTechs',
    'FoodTechs',
    'GovTechs',
    'HealthTechs',
    'HRtechs',
    'IndTechs',
    'InsurTech',
    'IoT',
    'LegalTechs',
    'LogTechs',
    'Marketplace',
    'MarTechs',
    'Mobility',
    'New Trends',
    'PetTech',
    'Productivity',
    'RegTechs',
    'RetailTechs',
    'TravelTechs',
    'VR/AR',
    'Water and Sanitation'
];
