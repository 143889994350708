import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Events from './index';

export const routes = [
    {
        exact: true,
        path: '/event',
        component: Events.EventsIndex,
    },
    {
        exact: true,
        path: '/event/:id',
        component: Events.EventsSingle,
    },

    {
        exact: true,
        path: '/events',
        component: Events.EventsIndex,
    },
    {
        exact: true,
        path: '/events/:id',
        component: Events.EventsSingle,
    },
    {
        exact: false,
        path: '/events/:eventId/subscription',
        component: Events.Subscription,
    },
];

export const EventsRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default EventsRoutes;
