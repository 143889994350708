import styled from 'styled-components';

export const PagesSupport = styled.div.attrs({
    className: 'styled-pages-support',
})`
    .component-form {
        margin: 0;
    }
    .component-form .form-submit-holder {
        padding-top: 2rem;
    }

    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444;

        .icon.fa-whatsapp {
            color: #25d366;
        }
        .icon.fa-facebook-messenger {
            color: #0084ff;
        }
        .icon.fa-envelope-open,
        .icon.fa-envelope-open ~ * {
            color: var(--primary);
        }
    }
`;
