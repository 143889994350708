import styled from 'styled-components';
import { positions } from 'utils/styles';

export const CXIStartupGeneral = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) and (max-width: 1199px) {
        gap: 3.2rem;
        .cxi-layout:not(.is-open) & {
            display: grid;
            grid-template:
                'a b' auto
                'c c' auto
                'd e' auto / 1fr 42rem;
        }
    }
    @media (min-width: 1200px) {
        display: grid;
        gap: 3.2rem;
        grid-template:
            'a b' auto
            'c c' auto
            'd e' auto / 1fr 42rem;
    }

    .presentation {
        grid-area: a;
    }

    .ranking {
        grid-area: c;
    }

    .ranking .area-body {
        background: transparent;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.2rem;
        overflow: hidden;
        padding: 0 !important;
        position: relative;
    }

    .details .area-body {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        gap: 3.2rem;
        justify-content: space-between;
        padding: 2.4rem;
    }

    .preferences .area-body {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        gap: 3.2rem;
        padding: 1rem;
        justify-content: space-between;
    }

    .presentation-video {
        grid-area: a;
        background: #000;
        padding: 56% 0 0 0 !important;
        position: relative;

        iframe {
            border: 0;
            height: 100%;
            position: absolute;
            ${positions(0, 0, 0, 0)}
            width: 100%;
        }
    }
`;

export const CXIStartupGeneralDetails = styled.dl`
    dt {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 1.2rem;
        font-weight: 700;
        margin: 0;
    }
    dd {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0.2rem 0 0;

        & + dt {
            margin-top: 1.4rem;
        }
    }
`;

export const CXIStartupPreferences = styled.dl`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) {
        gap: 1rem;
    }
`;
export const CXIStartupGeneralSectors = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 992px) {
        gap: 1rem;
    }
`;

export const CXIStartupGeneralSidebar = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) {
        gap: 3.2rem;
    }
`;

export const CXIStartupGeneralFullwidth = styled.div`
    align-items: stretch;
    display: contents;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) {
        gap: 3.2rem;
    }
`;

export const CXIStartupGeneralRankingItem = styled.div`
    align-items: center;
    background: #fff;
    display: flex;
    font-size: 1.4rem;
    flex: 1 1 calc(50% - 0.4rem);
    flex-direction: column;
    grid-area: c c;
    gap: 1.2rem;
    white-space: pre-line;
    padding: 1rem;
    flex: 1 1 calc(33.333% - 0.4rem);

    @media (min-width: 992px) {
        flex: 1 1 calc(20% - 0.4rem);
    }

    img {
        width: 10rem;
    }
`;
