import React from 'react';

import { Header } from 'modules/cxi/components/header/Header';
import { useCXI } from 'modules/cxi/useCXI';

import { RHP } from 'providers';
import { MatchActionMentoringSession } from '../components/match/action/MatchActionMentoringSession';

import { Evaluations } from './components/evaluations/Evaluations';
import { Matches } from './components/matches/Matches';
import { useCXIMentoring } from './useCXIMentoring';
import { CXIMentoringContextProvider } from './CXIMentoringContext';

import * as Styled from './StyledCXIMentoring';
import { Sessions } from './components/sessions/Sessions';

export const CXIMentoringModule = () => {
    const { t, vars } = useCXIMentoring();
    const { match, startup } = vars;

    return (
        <Styled.CXIMentoringLayout className={startup.typeOfProject === 'tech' ? 'is-tech' : ''}>
            <Styled.CXIMentoring>
                <Styled.NetworkPageHead>
                    <h2>{t('mentoring.title')}</h2>
                </Styled.NetworkPageHead>

                <Matches matches={[match]} t={t} />

                <Styled.CXIMentoringSpacer />

                <Sessions match={match} />

                <Styled.CXIMentoringSpacer />

                <Header
                    button={
                        <div style={{ marginLeft: 'auto' }}>
                            <MatchActionMentoringSession match={match} />
                        </div>
                    }
                    title={t('mentoring.titleSessions')}
                />

                <hr />

                <Evaluations match={match} />
            </Styled.CXIMentoring>
        </Styled.CXIMentoringLayout>
    );
};

export const CXIMentoring = () => {
    const { vars } = useCXI();
    const { mentoring } = vars;

    const matchId = mentoring?.matchid;

    if (!matchId) {
        RHP.push('/100-10-1');
        return null;
    }

    return (
        <CXIMentoringContextProvider matchId={matchId}>
            <CXIMentoringModule />
        </CXIMentoringContextProvider>
    );
};
export default CXIMentoring;
