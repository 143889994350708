import React from 'react';

import * as Form from 'components/form';

import * as Styled from './StyledOiweekSubscribe';

export function OiweekSubscribeForm(props) {
    const { form, onSubmit, useOiweek } = props;
    const { oiweek, oiweekEvents, startups } = useOiweek.vars;

    // Create form fields object
    const fields = {};

    let hasASpeedDating = false;

    if (oiweek.type === 2) hasASpeedDating = true;

    // Check if the event has child events
    if (oiweekEvents && oiweekEvents.length > 0) {
        // Convert startups to option type array
        const eventsOptions = oiweekEvents.map(s => ({
            name: s.theme || s.name,
            value: String(s._id),
        }));
        const speedDatings = oiweekEvents.filter(s => s.type === 2);
        if (speedDatings.length > 0) hasASpeedDating = true;
        // Create the startups field
        fields.events = {
            error: form.errors.events,
            label: props.t('OiweekSubscribe.events'),
            name: 'events',
            options: eventsOptions,
            type: 'checkboxes',
            value: form.values.events,
            onChange: form.handleChange,
        };
    }

    if (hasASpeedDating) {
        fields.speeddating = {
            error: form.errors.speeddating,
            label: props.t('OiweekSubscribe.speedDating', {
                duration: oiweek.meetingDuration / 60000,
            }),
            name: 'speeddating',
            helper: props.t('OiweekSubscribe.speedDatingHelper'),
            helperRender: 'label',
            type: 'YesNo',
            value: form.values.speeddating === undefined ? true : form.values.speeddating,
            onChange: form.handleChange,
        };
    }

    // Check if the user has startups
    if (
        form.values.speeddating === 'true' &&
        hasASpeedDating &&
        startups.length &&
        startups.length > 0
    ) {
        // Convert startups to option type array
        const startupOptions = startups.map(s => ({ name: s.name, value: s._id }));
        // Create the startups field
        fields.startups = {
            error: form.errors.startups,
            label: props.t('OiweekSubscribe.startup'),
            name: 'startups',
            options: startupOptions,
            type: 'checkboxes',
            value: form.values.startups,
            onChange: form.handleChange,
        };
    }

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Styled.OiweekSubscribeForm>
            <Form.Form name="OiweekSubscribeForm" onSubmit={evSubmit}>
                {fields.speeddating && <Form.Field {...fields.speeddating} />}
                {fields.events && <Form.Field {...fields.events} />}
                {fields.startups && <Form.Field {...fields.startups} />}

                <Form.Submt {...form} text={props.t('OiweekSubscribe.submit')} />
            </Form.Form>
        </Styled.OiweekSubscribeForm>
    );
}
export default OiweekSubscribeForm;
