import React from 'react';

import { useConversionTable } from 'hooks';

import { Area } from '../components/area/Area';
import { useCXIDashboardStartup } from '../useCXIDashboardStartup';
import { CXIDashboardStartupFinancialFinances } from './Finances';

import * as Styled from './StyledCXIDashboardStartupFinancial';

export const CXIDashboardStartupFinancial = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    const conv = useConversionTable(['whoInvested']);

    return (
        <Styled.CXIDashboardStartupFinancial>
            {!!startup.demographics?.financial && (
                <Area className="finances" title={t('startup.finances')}>
                    <CXIDashboardStartupFinancialFinances />
                </Area>
            )}

            {!!startup.demographics.financial?.whoInvested?.length && (
                <Area className="invested" title={t('startup.invested')}>
                    {startup.demographics.financial.whoInvested.map(item => (
                        <p key={item}>{conv.whoInvested[item]}</p>
                    ))}
                </Area>
            )}
        </Styled.CXIDashboardStartupFinancial>
    );
};
