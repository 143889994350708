import React from 'react';
import { useTranslation } from 'react-i18next';

import logo100oc from 'assets/images/logo-100oc.svg';

import { iframeLink } from 'core/iframe';
import * as Alert from 'components/alert';
import { CONFIG } from 'config';

export function EvaluatorOk() {
    const { t } = useTranslation('home');

    // Create the alert element props
    const propsAlert = {
        // Set the image to be shown
        image: {
            src: logo100oc,
            alt: t('glossary:100 Open Corps'),
        },
        // Show texts informations
        title: t('EvaluatorOk.title'),
        descr: t('EvaluatorOk.descr'),
        // Build the button
        buttn: {
            href: iframeLink(CONFIG.appLinks.matchmaking),
            text: t('EvaluatorOk.button'),
        },
        // Setup color and presentation
        type: 'dark',
    };
    return <Alert.Alert {...propsAlert} />;
}
export default EvaluatorOk;
