import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const Relationship = styled.div.attrs({
    className: 'relationship'
})`
    background: #f2f2f2;
    margin-top: 2rem;
    padding: 1.5rem;

    .relationship-card {
        margin-top: 0;
    }

    .component-table {
        margin-top: 1rem;
    }

    .badge {
        margin: 1rem 1rem 0 0;
    }
    .badge + .component-table {
        margin-top: -1.8rem;
    }

    & + .btn-linkd {
        margin-top: 3rem;
    }

    .btn.btn-link {
        color: #999;
        min-height: auto;
        justify-content: center;
        padding: 0;
        text-align: center;
        text-decoration: underline;
        text-transform: none;
    }
`;
export const RelationshipButtons = styled.div.attrs({
    className: 'relationship-buttons'
})`
    display: flex;
    flex-wrap: wrap;
    margin: -1rem 0 0 -1rem;
    padding-top: 1rem;
    justify-content: center;

    .btn {
        flex: calc(50% - 1rem);
        margin: 1rem 0 0 1rem;
        min-height: auto;
        justify-content: center;
    }
`;

export const RelationshipCard = styled.div`
    align-items: center;
    background: #fff;
    border: 1px solid #e4e4e4;
    color: inherit;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
    -webkit-appearance: none;
`;

export const CardImage = styled.div`
    background: #f2f2f2 no-repeat 50% 50%;
    background-image: url(${props => props.img});
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 50%;
    flex: 0 0 6.5rem;
    height: 6.5rem;
    padding: 0.1rem;
`;

export const CardInfos = styled.div`
    flex: 1 1 calc(100% - 6.5rem);
    font-size: 1.4rem;
    margin-left: 1.5rem;
    text-align: left;

    .name {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.4;
        ${truncate(1)}
    }

    .bold {
        display: block;
        font-weight: 600;
        margin-top: 0;
        ${truncate(1)}
    }

    .text {
        display: block;
        margin-top: 0.1rem;
        ${truncate(1)}
    }
    .badge {
        margin-right: 4px;
    }
`;
