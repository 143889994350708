import styled from 'styled-components';
import { positions } from 'utils/styles';

export const FormCustomEditLogo = styled.label.attrs({
    className: 'form-custom-edit-avatar'
})`
    display: inline-block;
    position: relative;

    input {
        visibility: hidden;
        position: absolute;
    }
`;

export const IconCamera = styled.span.attrs({
    className: 'icon-camera far fa-camera-alt'
})`
    background-color: #fff;
    border-radius: 50%;
    color: #aaa;
    font-size: 1.6rem;
    padding: 0.8rem;
    position: absolute;
    ${positions('auto', 0, 0, 'auto')}
`;

export const CompanyLogo = styled.span.attrs({
    className: 'user-avatar'
})``;
