import React from 'react';

export const FormInputSelectOptions = ({ options, input }) =>
    options.length &&
    options.map((o, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <option key={`options-${input.name}-${i}`} value={o.value} disabled={o.disabled}>
            {o.name}
        </option>
    ));
export default FormInputSelectOptions;
