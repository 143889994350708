import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const UsersUser = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    display: flex;
    gap: 1.6rem;
    padding: 0;
    text-align: left;
`;

export const Image = styled.div`
    flex: 0 0 4.2rem;
    width: 4.2rem;

    .user-avatar {
        width: 100%;
    }
`;

export const Infos = styled.div``;

export const Title = styled.span`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.3;
    ${truncate(2, 1.3)}

    small {
        display: block;
    }
`;

export const Descr = styled.span`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.3;
    opacity: 0.75;
    ${truncate(1, 1.3)}
`;

export const Email = styled.span`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.3;
    opacity: 0.75;
    ${truncate(1, 1.3)}
`;
