import React from 'react';

import { ErrorBoundary } from 'components/error-boundary';

import { CXILayout } from './layout/CXILayout';
import { CXIModal } from './modal/CXIModal';

import { CXIContextProvider } from './CXIContext';
import { CXIRoutes } from './CXIRoutes';
import { Loading } from './components/loading';
import { useCXI } from './useCXI';

const CXIModule = () => {
    const { ready, modal, t } = useCXI(true);

    if (!ready) {
        return <Loading>{t('loading')}</Loading>;
    }

    return (
        <>
            <CXILayout>
                <CXIRoutes />
            </CXILayout>
            {!!modal && <CXIModal />}
        </>
    );
};

export function CXI() {
    return (
        <ErrorBoundary>
            <CXIContextProvider>
                <CXIModule />
            </CXIContextProvider>
        </ErrorBoundary>
    );
}
export default CXI;
