import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { InvestorEditForm } from './InvestorEditForm';

export function InvestorEdit(props) {
    const conv = useConversionOptions(['howCanContribute', 'investorType']);
    const { vars, putUserInvestor } = props.useInvestor;

    // Initialize a new form handler
    const form = useForm({
        ...vars.investor,
    });

    // Handle form submitions
    const onSubmit = values => {
        const payload = { ...values };
        return putUserInvestor(payload)
            .then(() => RHP.push('/home'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="InvestorEdit">
            <Page.Title>{props.t('InvestorEdit.title')}</Page.Title>
            <Page.Descr>{props.t('InvestorEdit.descr')}</Page.Descr>

            <InvestorEditForm {...propsForm} />
        </Page.Page>
    );
}
export default InvestorEdit;
