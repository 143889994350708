import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Progress = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1.6rem auto;
    max-width: 380px;
    padding: 0 3.2rem;
    position: relative;
    width: 100%;

    &::before {
        background: ${({ theme }) => theme.colors.lightGray};
        content: '';
        height: 3px;
        position: absolute;
        ${positions('1.1rem', '3.8rem', 'auto', '3.8rem')}
        transform: translateY(-1.5px);
    }

    & > div {
        align-items: center;
        color: ${({ theme }) => theme.colors.energyBlue};
        display: flex;
        height: 2.2rem;
        position: relative;
        width: 2.2rem;

        .icon {
            align-items: center;
            background: ${({ theme }) => theme.colors.lightGray};
            color: ${({ theme }) => theme.colors.white};
            display: flex;
            border-radius: 50%;
            height: 1.6rem;
            justify-content: center;
            width: 1.6rem;
        }
        .icon::before {
            content: '\f00c';
            font-family: 'Font Awesome 5 Pro';
            font-size: 0.8rem;
            font-weight: 900;
            line-height: 1;
        }

        .text {
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 1.4rem;
            margin-top: 0.8rem;
            position: absolute;
            ${positions('100%', 'auto', 'auto', '50%')}
            transform: translateX(-50%);
        }
    }

    & > div.default .icon::before {
        background: ${({ theme }) => theme.colors.white};
        border-radius: 50%;
        content: '';
        height: 1rem;
        width: 1rem;
        transform: translateY(0);
    }

    & > div.current {
        .icon {
            background: ${({ theme }) => theme.colors.energyBlue};
            height: 2.2rem;
            width: 2.2rem;
        }
        .icon::before {
            font-size: 1rem;
            transform: translate(-0.5px, 1.5px);
        }
        .text {
            font-size: 1.8rem;
        }
    }
`;
