import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CA, CG } from 'contexts';
import { UserProfile } from 'core/components/user';

import * as Styled from './StyledMenuUserProfile';

export function MenuUserProfile(props) {
    const { t } = useTranslation('glossary');
    const { scopeAllows } = React.useContext(CA);
    const { dispatch } = React.useContext(CG);
    // Function to change the language
    const click = ev => {
        dispatch({ type: 'MENU_OPEN', menu: false });
    };
    return (
        <Styled.MenuUserProfile>
            <Styled.UserProfile as={UserProfile} />
            {scopeAllows('user') && (
                <Styled.EditProfile as={Link} to="/profile" onClick={click}>
                    <span className="text">{t('editUserProfile')}</span>
                </Styled.EditProfile>
            )}
        </Styled.MenuUserProfile>
    );
}
export default MenuUserProfile;
