import styled from 'styled-components';

export const OiweekIndex = styled.div.attrs({
    className: 'styled-oiweek-index',
})`
    .component-page-container {
        text-align: center;
    }

    .oiweek-call2action {
        margin-top: 3rem;
    }
`;
