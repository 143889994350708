import React from 'react';

import { useStartupCXI } from '../useStartupCXI';

import { StartupCXIModalError } from './StartupCXIModalError';

import * as Modals from './index';
import * as Styled from './StyledStartupCXIModal';

export const StartupCXIModal = () => {
    const { modal } = useStartupCXI();
    const { modal: name } = modal;

    const renderModal = React.useMemo(() => {
        const Component = Modals[name] || StartupCXIModalError;
        return <Component />;
    }, [name]);

    return (
        <Styled.StartupCXIModal>
            <Styled.StartupCXIModalBackdrop />
            <Styled.StartupCXIModalContent>{renderModal}</Styled.StartupCXIModalContent>
        </Styled.StartupCXIModal>
    );
};
