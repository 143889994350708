import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
// import { formatDate } from 'utils/date';

import * as Styled from './StyledMatchSpeedDating';

export const MatchSpeedDating = ({ match }) => {
    const { t } = useCXI();
    const { speedDating } = match;

    let ttip = '';
    let icon = '';
    if (speedDating === 'requested') {
        ttip = t('match.speed-dating.requested');
        icon = 'fa-clock';
        // } else if (speedDating === 'accepted') {
        //     const date = formatDate(new Date(match.latestMeeting.startTime), "dd/MM/yyyy, 'às' H'h'mm");
        //     ttip = t('match.speed-dating.accepted', { date });
        //     icon = 'fa-clock';
        // } else if (speedDating === 'rejected') {
        //     ttip = t('match.speed-dating.rejected');
        //     icon = 'fa-minus-circle';
    } else if (speedDating === 'held') {
        ttip = 'Realizado';
        icon = 'fa-check-circle';
    }

    return (
        <Styled.MatchSpeedDating className={speedDating}>
            {!icon ? (
                <span className="text">-</span>
            ) : (
                <span className={`icon far ${icon}`} aria-hidden="true" />
            )}
            {!!ttip && <span className="ttip">{ttip}</span>}
        </Styled.MatchSpeedDating>
    );
};
