import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { StartupIdRoutes } from './StartupIdRoutes';
import { useStartupId } from './useStartupId';

import * as Styled from './StyledStartupId';

export const StartupId = props => {
    const { t } = useTranslation('startupId');
    const { startupId } = useParams();
    const hookd = useStartupId(startupId);
    
    return !hookd.ready ? null : (
        <Styled.StartupIdModule>
            <StartupIdRoutes t={t} useStartupId={hookd} />
        </Styled.StartupIdModule>
    );
};
export default StartupId;
