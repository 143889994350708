import React from 'react';

import { RHP } from 'providers';
import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledSpeedDatingSent';

export const SpeedDatingSent = () => {
    const { t, modal, setModal } = useCXI();
    const { match } = modal;

    const goToChat = () => {
        RHP.push(`/100-10-1/messages/${match.matchid}`);
        setModal(null);
    };

    return (
        <Styled.SpeedDatingSent as={Container.Slim}>
            <Container.Head image={match.startup.logoUrl} />

            <Container.Body>
                <Styled.Title>{t('modal.SpeedDatingSent.title')}</Styled.Title>
                <Styled.Descr>{t('modal.SpeedDatingSent.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={goToChat}>
                    <span className="text">{t('modal.SpeedDatingSent.goToChat')}</span>
                </Button>
                <Button variant="link" onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.SpeedDatingSent>
    );
};
