import React from 'react';
import { useEffectOnce } from 'react-use';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { LoginValidateForm } from './LoginValidateForm';

export function LoginValidate(props) {
    const { emitAlert } = React.useContext(CG);
    const { vars, postResetPassword, postValidateCode } = props.useLogin;

    // Initialize a new form handler
    const form = useForm({
        email: vars.email || '',
        baseUrl: vars.baseUrl || '',
        pincode: vars.pincode || '',
        resetcode: vars.resetcode || '',
        returnUrl: vars.returnUrl || '',
    });

    // Function to resend the password validation
    // This is activated on a page button
    function resendCode(ev) {
        ev.preventDefault();
        // Get values to resend the e-mail
        const { baseUrl, email, returnUrl } = form.values;
        form.setSubmit(true);
        // Do the resetPassword request to the API
        return postResetPassword({ baseUrl, email, returnUrl })
            .then(() => emitAlert(props.t('LoginValidate.resendCode')))
            .finally(() => form.submited());
    }

    // Handle form submition
    const onSubmit = values => {
        const { email, resetcode, pincode } = values;
        // Do the submition api request
        return postValidateCode({ email, resetcode, pincode })
            .then(() => RHP.push('/login/password'))
            .catch(() => form.submited());
    };

    useEffectOnce(() => {
        // Check if user has passed params with verificationCode
        // console.log('Must Validate email automatically');
        const { email, resetcode, pincode } = form.values;
        if (email && (resetcode || pincode)) {
            onSubmit(form.values);
        }
    });

    // Check if the e-mail variable is filled
    // If not, then redirect to the reset page
    if (!form.values.email) {
        // console.log('Redirecting...', 'No e-mail found in the state');
        RHP.replace(`/login/reset`);
        return null;
    }

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="LoginValidate" back="/login/reset">
            <Page.Title>{props.t('LoginValidate.title')}</Page.Title>
            <Page.Descr>{props.t('LoginValidate.descr')}</Page.Descr>

            <LoginValidateForm {...propsForm} />

            <Page.LWTXT as="a" href="/login/validate" onClick={resendCode}>
                <span className="text">{props.t('LoginValidate.resendText')}</span>
                <span className="link">{props.t('LoginValidate.resendLink')}</span>
            </Page.LWTXT>
        </Page.Page>
    );
}
export default LoginValidate;
