import React from 'react';

import * as List from 'components/list';
import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';
import { RHP } from 'providers';

import { Container } from '../container';
import * as Styled from './StyledStartupOptions';

export const StartupOptions = () => {
    const { t, modal, setModal, vars } = useCXI();
    const { cxi, isMentoring, mentoring } = vars;
    const { match } = modal;

    // const cs = match.calculatedStage;

    const actions = [
        {
            text: t('modal.StartupOptions.evaluations'),
            hide: match.evaluation?.group === undefined,
            onClick: () => setModal({ match, modal: 'Evaluations' }),
        },
        {
            text: t('modal.StartupOptions.evaluate'),
            // hide: !match.evaluation?.group,
            onClick: () => setModal({ match, modal: 'Evaluate' }),
        },
        // {
        //     text: t('modal.StartupOptions.reevaluate'),
        //     hide: match.evaluation?.group,
        //     onClick: () => setModal({ match, modal: 'Evaluate' }),
        // },
        {
            text: t('modal.StartupOptions.speed-dating'),
            // hide: cxi.currentStage === 1,
            // hide: match.stages.evaluated && match.evaluation.startupFeedback
            onClick: () => setModal({ match, modal: 'SpeedDating' }),
        },
        {
            text: t('modal.StartupOptions.feedback'),
            show: match.speedDating !== 'none',
            // show: cxi.currentStage > 1 && match.speedDating !== 'none',
            onClick: () => setModal({ match, modal: 'Feedback' }),
        },
        {
            text: t('modal.StartupOptions.mentor'),
            show: !isMentoring,
            onClick: () => setModal({ match, modal: 'Mentoring' }),
        },
        {
            text: t('modal.StartupOptions.mentoring'),
            show: isMentoring && mentoring?.matchid === match.matchid,
            onClick: () => {
                RHP.push(`/100-10-1/mentoring/${match.matchid}`);
                setModal(null);
            },
        },
        {
            text: t('modal.StartupOptions.mentoringFinish'),
            show: match.mentoring === 'accepted',
            onClick: () => setModal({ match, modal: 'MentoringFinish' }),
        },
        {
            // icon: 'far fa-share-alt',
            text: t('modal.StartupOptions.share'),
            onClick: () => setModal({ match, modal: 'StartupShare' }),
        },
        {
            // icon: 'far fa-comments-alt',
            text: t('modal.StartupOptions.messages'),
            hide: match.status !== 'confirmed',
            onClick: () => {
                RHP.push(`/100-10-1/messages/${match.matchid}`);
                setModal(null);
            },
        },
        {
            // icon: 'far fa-exclamation-triangle',
            text: t('modal.StartupOptions.support'),
            onClick: () => setModal({ match, modal: 'Support' }),
        },

        {
            // icon: 'far fa-undo',
            text: t('modal.StartupOptions.unmatch'),
            onClick: () => setModal({ match, modal: 'StartupUnmatch' }),
        },
    ];

    const mappedActions = actions
        .map(action => {
            if (action.hide || ('show' in action && !action.show)) {
                return { ...action, disabled: true, onClick: () => {} };
            }
            return action;
        })
        .filter(action => !action.disabled);

    return (
        <Styled.StartupOptions as={Container.Slim}>
            <Container.Head>
                <span className="text">{t('modal.StartupOptions.title')}</span>
            </Container.Head>

            <Container.Body>
                <Styled.Descr>{t('modal.StartupOptions.description')}</Styled.Descr>
                <List.List name="StartupOptionsMatchList">
                    <List.Vertical items={mappedActions} itemKey="text" />
                </List.List>
            </Container.Body>

            <Container.Feet>
                <Button variant="link" onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.StartupOptions>
    );
};
