import React from 'react';
import { Redirect, Route as ReactRoute, Switch } from 'react-router-dom';

import { RoutePrivate } from 'routes';
import * as Pages from '.';

export const routes = [
    {
        exact: true,
        name: 'About',
        path: '/about',
        private: false,
        component: Pages.PagesAbout,
    },
    {
        exact: true,
        name: 'Terms',
        path: '/terms',
        private: false,
        component: Pages.PagesTerms,
    },
    {
        exact: true,
        name: 'Support',
        path: '/support',
        private: false,
        component: Pages.PagesSupport,
    },
    {
        exact: true,
        path: '/share/evaluator/:referralCode',
        private: true,
        name: 'MatchFromReferral',
        component: Pages.MatchFromReferral,
    },

    // Handle all the request that don't go any where
    // We do this so the 'pages' module can handle root requests
    {
        exact: true,
        path: '/:error',
        private: false,
        component: Pages.PagesError,
    },
    {
        exact: false,
        path: '/',
        private: false,
        component: ({ location }) => <Redirect to={{ ...location, pathname: '/home' }} />,
    },
];

export const PagesRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const Route = r.private ? RoutePrivate : ReactRoute;
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default PagesRoutes;
