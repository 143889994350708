import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { iframeLink } from 'core/iframe';

import * as Page from 'components/page';

import { useEffectOnce } from 'react-use';
import { SubscriptionBack } from '../components';

import * as Styled from './StyledSubscriptionSuccess';

const Button = props => (
    <Styled.SubscriptionButton as={Link} to={props.to}>
        {props.children}
        {props.name && <span className="name">{props.name}</span>}
        {props.text && <span className="text">{props.text}</span>}
        {props.numb && <span className="numb">{props.numb}</span>}
        {props.link && <span className="bttn btn btn-sm btn-primary btn-round">{props.link}</span>}
    </Styled.SubscriptionButton>
);

export const SubscriptionSuccessSDFree = props => {
    const { eventId } = useParams();

    const { vars, getEventById, getEventProducts } = props.useSubscription;
    const { eventById, eventsProducts } = vars;

    useEffectOnce(() => {
        getEventById(eventId);
        getEventProducts(eventId);
    });

    let price = '';
    if (eventById.payPerUsePrice && eventById.payPerUsePrice.currency) {
        const { currency, amount } = eventById.payPerUsePrice;
        price = new Intl.NumberFormat('pt-br', { style: 'currency', currency }).format(amount);
    }

    // Button: PayPerUse
    const propsPayPerUse = {
        to: `/events/${eventId}/subscription/payperuse`,
        name: props.t('SubscriptionSuccess.singleName'),
        text: props.t('SubscriptionSuccess.singleDesc'),
        numb: props.t('SubscriptionSuccess.singlePrice', { price }),
        link: props.t('SubscriptionSuccess.productButton'),
    };

    const products = eventsProducts?.map(p => ({
        id: p.id,
        to: iframeLink(p.link, `events/${eventId}}/subscription/success`),
        name: p.acf.product_name,
        text: p.acf.product_description,
        numb: p.acf.product_value,
        link: props.t('SubscriptionSuccess.productButton'),
    }));

    return !products || !eventById ? null : (
        <Page.Page name="SubscriptionSuccessSDFree">
            <Page.Title>{props.t('SubscriptionSuccess.title')}</Page.Title>
            <Page.Descr>{props.t('SubscriptionSuccess.descr-SD-free1')}</Page.Descr>
            <Page.Descr>{props.t('SubscriptionSuccess.descr-SD-free2')}</Page.Descr>

            <Styled.SubscriptionSuccessBody>
                <Page.Title>{props.t('SubscriptionSuccess.body-title')}</Page.Title>
                <Page.Descr>{props.t('SubscriptionSuccess.body-descr')}</Page.Descr>

                {eventById.payPerUse && <Button {...propsPayPerUse} />}

                {products.map(p => (
                    <Button key={`product-${p.id}`} {...p} />
                ))}

                <Page.Discl>{props.t('SubscriptionSuccess.body-discl')}</Page.Discl>

                <SubscriptionBack event={eventById} />
            </Styled.SubscriptionSuccessBody>
        </Page.Page>
    );
};
export default SubscriptionSuccessSDFree;
