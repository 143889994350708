import React from 'react';

import logoRanking from 'assets/images/logo-ranking.svg';

import { Area } from '../components/area/Area';
import { useCXIStartup } from '../useCXIStartup';

import { CXIStartupGeneralRankingItem } from './StyledCXIStartupGeneral';

export const CXIStartupGeneralRanking = () => {
    const { t, vars } = useCXIStartup();
    const { startup } = vars;

    return !startup.badges?.length ? null : (
        <Area className="ranking" title={t('startup.ranking')}>
            {startup.badges.map(b => (
                <CXIStartupGeneralRankingItem key={b.name}>
                    <img src={logoRanking} alt="Logo Ranking Open Startups" />
                    <span className="text">{b.name}</span>
                </CXIStartupGeneralRankingItem>
            ))}
        </Area>
    );
};
