import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

export const formatDate = (date: Date, dateFormat: string) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
        return 'Data inválida';
    }

    return format(date, dateFormat, { locale: ptBr });
};

export const getDateXDaysAgo = (numOfDays: number, date = new Date()) => {
    const daysAgo = new Date(date.getTime());
    daysAgo.setDate(date.getDate() - numOfDays);
    return daysAgo;
};
