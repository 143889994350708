import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContextAuth as CA } from 'contexts';
import * as List from 'components/list';

import * as Styled from './StyledHomeListActions';

export function HomeListActions() {
    const { t } = useTranslation('home');
    const { state, scopeAllows } = React.useContext(CA);

    const actions = [
        {
            id: 0,
            href: '/investor',
            text: t('HomeListActions.investorRegister'),
        },
        {
            id: 1,
            href: '/matches',
            text: t('HomeListActions.evaluatorRegister'),
        },
        {
            id: 2,
            href: '/startup',
            text: t('HomeListActions.startupRegister'),
        },
    ];

    // Check if the user is already an investor
    if (state.user.investor === true) {
        actions[0].text = t('HomeListActions.investorEdit');
        // delete actions[0];
    }
    // Check if the user is already a evaluator
    if (state.user.evaluator === true) {
        actions[1].text = t('HomeListActions.evaluatorEdit');
        // delete actions[1];
    }
    // Check if the user is already an entrepreneur
    if (state.user.entrepreneur === true) {
        actions[2].text = t('HomeListActions.startupEdit');
        // delete actions[2];
    }

    if (!scopeAllows('investor')) {
        delete actions[0];
    }
    if (!scopeAllows('evaluator')) {
        delete actions[1];
    }
    if (!scopeAllows('startup')) {
        delete actions[2];
    }

    return !actions.length ? null : (
        <Styled.HomeListActions as={List.List} name="HomeListActions">
            <List.Vertical items={actions} itemKey="id" />
        </Styled.HomeListActions>
    );
}
export default HomeListActions;
