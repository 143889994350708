import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Startup from './index';

export const routes = [
    {
        exact: true,
        path: '/startup',
        component: Startup.StartupIndex,
    },
    {
        exact: false,
        path: '/startup/:startupId',
        component: Startup.StartupId,
    },
 
];

export const StartupRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default StartupRoutes;
