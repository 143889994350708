import React from 'react';

import * as Styled from './StyledContainer';

export const ContainerHead = ({ children, image, title, ...props }) => (
    <Styled.ContainerHead {...props} texts={!!title || !!children} image={image}>
        {!!image && <Styled.Image image={image} />}
        {!!title && <Styled.Title>{title}</Styled.Title>}
        {!!children && <div className="children">{children}</div>}
    </Styled.ContainerHead>
);
