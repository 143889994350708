import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';

import { forumsList } from './forumsList';
import { ForumCard } from './ForumCard';
import * as Styled from './StyledForums';

export const Forums = () => {
    const { vars, t } = useCXI();
    const { cxi } = vars;

    let filtered = forumsList;

    if (cxi.currentStage === 1) {
        filtered = forumsList.filter(i => i.stage.includes('Forum Etapa 100'));
    }
    if (cxi.currentStage === 1) {
        filtered = forumsList.filter(i => i.stage.includes('Forum Etapa 10'));
    }
    if (cxi.currentStage === 3) {
        filtered = forumsList.filter(i => i.stage.includes('Forum Etapa 1'));
    }

    return (
        <Styled.Forums>
            <Styled.ForumsHead>
                <h2>{t('forum.title')}</h2>
                <p>{t('forum.descr')}</p>
            </Styled.ForumsHead>

            <Styled.ForumsBody>
                {filtered.map(i => (
                    <ForumCard key={`${i.stage}-${i.type}`} data={i} />
                ))}
            </Styled.ForumsBody>
        </Styled.Forums>
    );
};
