import React from 'react';
import { Redirect, Route as ReactRoute, Switch } from 'react-router-dom';

import { RoutePrivate } from 'routes';
import * as Pages from '.';

export const routes = [
    {
        exact: true,
        path: '/100-10-1/certification/:userId',
        private: false,
        component: Pages.CXICertificationIndex,
    },
    {
        exact: true,
        path: '/100-10-1/certification',
        private: false,
        component: ({ location }) => <Redirect to={{ ...location, pathname: '/home' }} />,
    },
];

export const CXICertificationRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const Route = r.private ? RoutePrivate : ReactRoute;
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default CXICertificationRoutes;
