import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as RegisterContact from './index';

export const routes = [
    {
        exact: true,
        path: '/registercontact/:startupSecret',
        component: RegisterContact.RegisterContactIndex,
    },
];

export const RegisterContactRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default RegisterContactRoutes;
