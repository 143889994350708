import React from 'react';

import * as Form from 'components/form';

import { useConversionOptions } from 'hooks/useConversionTable';

export const InterestSection = props => {
    const { form } = props;

    const convInterests = useConversionOptions(['startupInterests']);
    const convCauses = useConversionOptions(['causes']);

    const fields = {
        interests: {
            error: form.errors.interests,
            label: props.t('InterestSection.interests'),
            name: 'interests',
            type: 'checkboxes',
            required: false,
            options: convInterests.startupInterests,
            value: form.values.interests,
            onChange: form.handleChange,
        },
        causes: {
            error: form.errors.causes,
            label: props.t('InterestSection.causes'),
            name: 'causes',
            type: 'checkboxes',
            required: false,
            options: convCauses.causes,
            value: form.values.causes,
            onChange: form.handleChange,
        },
        otherCause: {
            error: form.errors.otherCause,
            label: props.t('InterestSection.otherCause'),
            name: 'otherCause',
            type: 'text',
            required: form.values.causes.includes('other'),
            value: form.values.otherCause,
            onlyIf: form.values.causes.includes('other'),
            onChange: form.handleChange,
        },
        howCanYouHelp: {
            error: form.errors.howCanYouHelp,
            label: props.t('InterestSection.howCanYouHelp'),
            name: 'howCanYouHelp',
            type: 'textarea',
            required: false,
            value: form.values.howCanYouHelp,
            onChange: form.handleChange,
        },
        howCanWeHelp: {
            error: form.errors.howCanWeHelp,
            label: props.t('InterestSection.howCanWeHelp'),
            name: 'howCanWeHelp',
            type: 'textarea',
            required: false,
            value: form.values.howCanWeHelp,
            onChange: form.handleChange,
        },
    };

    return (
        <>
            <Form.Field {...fields.interests} />
            <Form.Field {...fields.causes} />
            <Form.Field {...fields.otherCause} />
            <Form.Field {...fields.howCanYouHelp} />
            <Form.Field {...fields.howCanWeHelp} />
        </>
    );
};

export default InterestSection;
