import React, { useContext } from 'react';
import { useEffectOnce } from 'react-use';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ContextAuth as CA, ContextGlobal as CG } from 'contexts';
import * as CorporateHub from './index';
import CorporateHubStartupView from './components/pageComponents/startups/CorporateHubStartupView';
import CorporateHubRelationshipsView from './components/pageComponents/relationships/CorporateHubRelationshipView';
import CorporateHubChallengesView from './components/pageComponents/challenges/CorporateHubChallengesView';
import CorporateHubExecutiveView from './components/pageComponents/executives/CorporateHubExecutiveView';
import CorporateHubGroupView from './components/pageComponents/groups/CorporateHubGroupView';
import ConfigPage from './components/config/ConfigPage';
import useCorporateHub from './useCorporateHub';

export const routes = [
    {
        exact: true,
        path: '/corporatehub',
        component: ConfigPage
    },
    {
        exact: true,
        path: '/corporatehub/startup',
        component: CorporateHubStartupView
    },
    {
        exact: true,
        path: '/corporatehub/relationships',
        component: CorporateHubRelationshipsView
    },
    {
        exact: true,
        path: '/corporatehub/challenges',
        component: CorporateHubChallengesView
    },
    {
        exact: true,
        path: '/corporatehub/executives',
        component: CorporateHubExecutiveView
    },
    {
        exact: true,
        path: '/corporatehub/group',
        component: CorporateHubGroupView
    },
    {
        exact: true,
        path: '/corporatehub/config',
        component: ConfigPage
    },
    {
        exact: false,
        path: `/corporatehub/play*`,
        component: CorporateHub.CorporateHubIndex
    }
];

export const CorporateHubRoutes = props => {
    const { dispatch } = React.useContext(CG);
    const { state } = useContext(CA);
    const isPoc = state.user.pocs.includes('hub');
    const hookd = useCorporateHub();
    const { configObjFromURL } = props;

    useEffectOnce(() => {
        dispatch({ type: 'MENU_SHOW', menu: false });
    });

    return !isPoc ? (
        <Redirect to="/home" />
    ) : (
        <Switch>
            {routes.map(r => {
                const Component = r.component || React.createComponent(r.toRender);
                const routeProp = { ...r, component: undefined };
                return (
                    <Route
                        key={r.path}
                        {...routeProp}
                        render={() => (
                            <Component
                                configObj={configObjFromURL}
                                {...props}
                                useCorporateHub={hookd}
                            />
                        )}
                    />
                );
            })}
        </Switch>
    );
};
export default CorporateHubRoutes;
