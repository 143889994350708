import styled from 'styled-components';

import grafismo from 'assets/images/grafismo-ranking.png';
import logoRanking from 'assets/images/logo-ranking-2022.svg';

// import { positions } from 'utils/styles';

export const Ranking = styled.div.attrs({
    className: 'ranking',
})`
    background: #fff;
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
        background: transparent no-repeat 100% 100%;
        background-image: url(${grafismo});
        background-size: 100% 100%;
        content: '';
        height: 312px;
        position: absolute;
        left: -150px;
        top: -220px;
        width: 312px;

        @media (min-width: 576px) {
            top: -190px;
        }
        @media (min-width: 768px) {
            top: -150px;
        }
    }

    &::after {
        left: auto;
        right: -150px;
        transform: rotateY(180deg);
    }

    & > * {
        position: relative;
    }
    figure {
        display: block;
    }
    .c-alert-title {
        background: transparent no-repeat top center;
        background-image: url(${logoRanking});
        background-size: 70% 60%;
        padding: 80px 0 0 0;
        /* margin: 1rem 15rem 0; */
    }

    .c-alert-descr {
        max-width: 450px;
        line-height: 1.4;
        margin: 1rem auto 0;
    }

    .component-list {
        /* margin: 0; */
    }

    .list-vertical-item-link {
        color: #494444;
    }
`;
