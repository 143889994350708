import React from 'react';

import logo from 'assets/images/logo-100-10-1.png';
import blackBelt from 'assets/images/belts/selo-black-belt.png';
import brownBelt from 'assets/images/belts/selo-brown-belt.png';
import greenBelt from 'assets/images/belts/selo-green-belt.png';
import whiteBelt from 'assets/images/belts/selo-white-belt.png';

import { Button } from 'modules/cxi/components/button';

import { usePDF } from 'react-to-pdf';
import { CXICertificationCertificate } from '../certificate/CXICertificationCertificate';

import { useCXICertification } from '../useCXICertification';
import * as Styled from './StyledCXICertificationIndex';

interface ICertificate {
    userName: string;
    currentBelt: 'black' | 'brown' | 'green' | 'white';
    certifiedAt: Date;
}

export function CXICertificationIndex() {
    const { t, vars } = useCXICertification();
    const { certificate } = vars;

    const { userName, currentBelt, certifiedAt }: ICertificate = certificate;

    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

    const certRef = React.useRef(null);
    const currentCertifiedBelt = {
        color: '',
        img: '',
        name: '',
        text: '',
        certifiedAt
    };

    switch (currentBelt) {
        case 'black':
            currentCertifiedBelt.color = 'black';
            currentCertifiedBelt.img = blackBelt;
            currentCertifiedBelt.name = 'Black Belt';
            currentCertifiedBelt.text = `Este certificado é concedido à ${
                userName.split(' ')[0]
            }, que concluiu a formação do nível Black Belt do Programa 100-10-1 Startups de formação de mentores de startups. ${
                userName.split(' ')[0]
            } demonstrou excelência em fornecer orientação estratégica para startups, tendo conquistado 30 feedbacks classificados como 'Muito Relevantes' pelas startups em avaliações online, speed datings e mentoria. Parabenizamos ${
                userName.split(' ')[0]
            } por essa conquista e reconhecemos sua capacidade de ajudar startups a alcançarem seu potencial máximo, contribuindo significativamente para seu sucesso.`;
            break;
        case 'brown':
            currentCertifiedBelt.color = 'brown';
            currentCertifiedBelt.img = brownBelt;
            currentCertifiedBelt.name = 'Brown Belt';
            currentCertifiedBelt.text = `Este certificado é concedido à ${
                userName.split(' ')[0]
            }, que concluiu a formação do nível Brown Belt do Programa 100-10-1 Startups de formação de mentores de startups. ${
                userName.split(' ')[0]
            } demonstrou grande habilidade em se conectar com startups, tendo conquistado 20 feedbacks classificados como 'Muito Relevantes' pelas startups em avaliações online e speed datings. Parabenizamos ${
                userName.split(' ')[0]
            } por essa conquista e reconhecemos sua habilidade em fornecer feedbacks construtivos e úteis, contribuindo para o crescimento das startups.`;
            break;
        case 'green':
            currentCertifiedBelt.color = 'green';
            currentCertifiedBelt.img = greenBelt;
            currentCertifiedBelt.name = 'Green Belt';
            currentCertifiedBelt.text = `
        Este certificado é concedido à ${
            userName.split(' ')[0]
        }, que concluiu a formação do nível Green Belt do Programa 100-10-1 Startups de formação de mentores de startups. ${
                userName.split(' ')[0]
            } demonstrou habilidade e competência em fornecer feedbacks relevantes e úteis para as startups, tendo atingido a meta de 10 contribuições classificadas como 'Muito Relevantes' pelas startups nas avaliações online. Parabenizamos ${
                userName.split(' ')[0]
            } por essa conquista e reconhecemos sua capacidade de identificar pontos fortes e oportunidades de melhoria das startups, contribuindo para seu desenvolvimento.`;
            break;
        default:
            currentCertifiedBelt.color = 'white';
            currentCertifiedBelt.img = whiteBelt;
            currentCertifiedBelt.name = 'White Belt';
            currentCertifiedBelt.text = `Este certificado é concedido à ${userName}, que concluiu a formação do nível White Belt do Programa 100-10-1 Startups de formação de mentores de startups. ${
                userName.split(' ')[0]
            } demonstrou grande interesse e empenho em iniciar sua formação como mentor de startups, tendo realizado o primeiro acesso ao programa, definido suas preferências e iniciado sua jornada de conhecimento sobre o universo das startups. Parabenizamos ${userName} por essa conquista e desejamos sucesso em sua jornada como mentor de startups.`;
            break;
    }

    const handleDownload = async () => {
        toPDF();
    };

    function handleSharing(socialNetwork: 'li' | 'fb' | 't' | 'wa') {
        const socials = {
            li: `https://www.linkedin.com/shareArticle?url=${window.location.href}&title=Certificado`,
            fb: `https://www.facebook.com/sharer.php?u=${window.location.href}`,
            t: `https://twitter.com/intent/tweet?url=${window.location.href}&text=Certificado`,
            wa: `https://wa.me/?text=Certificado%5Cn%20${window.location.href}`
        };
        window.location.href = socials[socialNetwork];
    }

    return (
        <Styled.CXICertificationIndex>
            <Styled.CXICertificationIndexHead>
                <img src={logo} alt="Logo 100-10-1" />
            </Styled.CXICertificationIndexHead>

            <Styled.CXICertificationIndexBody>
                <Styled.Header>
                    <Styled.Title>{t('CXICertification.title')}</Styled.Title>
                    <Styled.Button
                        as={Button}
                        size="small"
                        variant="outline"
                        onClick={handleDownload}
                    >
                        <span className="icon far fa-download" aria-hidden="true" />
                        <span className="text">{t('CXICertification.download')}</span>
                    </Styled.Button>
                </Styled.Header>
            </Styled.CXICertificationIndexBody>

            {!!currentCertifiedBelt && (
                <Styled.CXICertificationIndexBody ref={certRef}>
                    <CXICertificationCertificate
                        certificateRef={targetRef}
                        belt={currentCertifiedBelt}
                        userName={userName}
                        t={t}
                    />
                </Styled.CXICertificationIndexBody>
            )}

            <Styled.CXICertificationIndexFooter>
                <Styled.FooterTitle>{t('CXICertification.share')}</Styled.FooterTitle>

                <Styled.ButtonContainer>
                    <Styled.Button
                        as={Button}
                        size="small"
                        variant="blue"
                        onClick={() => handleSharing('fb')}
                    >
                        <span className="icon fab fa-facebook-f" aria-hidden="true" />
                        <span className="text">{t('CXICertification.shareOn')} Facebook</span>
                    </Styled.Button>

                    <Styled.Button
                        as={Button}
                        size="small"
                        variant="blue"
                        onClick={() => handleSharing('li')}
                    >
                        <span className="icon fab fa-linkedin" aria-hidden="true" />
                        <span className="text">{t('CXICertification.shareOn')} LinkedIn</span>
                    </Styled.Button>

                    <Styled.Button
                        as={Button}
                        size="small"
                        variant="blue"
                        onClick={() => handleSharing('wa')}
                    >
                        <span className="icon fab fa-whatsapp" aria-hidden="true" />
                        <span className="text">{t('CXICertification.shareOn')} Whatsapp</span>
                    </Styled.Button>

                    <Styled.Button
                        as={Button}
                        size="small"
                        variant="blue"
                        onClick={() => handleSharing('t')}
                    >
                        <span className="icon fab fa-twitter" aria-hidden="true" />
                        <span className="text">{t('CXICertification.shareOn')} Twitter</span>
                    </Styled.Button>
                </Styled.ButtonContainer>
            </Styled.CXICertificationIndexFooter>
        </Styled.CXICertificationIndex>
    );
}
