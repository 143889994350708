import React from 'react';
import { LSP } from 'providers';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';
import { UserAvatar } from 'core/components/user';
import { Container } from '../container';

import * as Styled from './StyledChallengeFeedback';

export const ChallengeFeedback = () => {
    const { modal, setModal, vars, putChallengeStartupStatus } = useCXI();
    const { matches } = vars;
    const { challengeId, startup, filters } = modal;

    const form = useForm();

    const fields = {
        comment: {
            error: form.errors.comment,
            name: 'comment',
            type: 'textarea',
            label: 'Mande uma devolutiva personalizada',
            required: true,
            value: form.values.comment,
            placeholder:
                'Utilize este campo para explicar à startup os motivos pelos quais você não a selecionou para dar continuidade ao desafio.',
            onChange: ev => {
                LSP.set(`${matches.matchid}_FEEDBACK_FORM_COMMENT`, ev.target.value);
                return form.handleChange(ev);
            },
        },
    };

    const putEventStatus = () => {
        const payload = {
            eventStatus: 'rejected',
            feedback: fields.comment.value,
        };
        putChallengeStartupStatus(challengeId, startup?._id, payload, filters).then(res => {
            fields.comment.value = '';
            setModal(null);
        });
    };

    return (
        <Styled.ChallengeFeedback as={Container.Slim}>
            <Styled.Avatar>
                <div className="feedback">
                    <UserAvatar user={{ firstName: startup?.name, photoUrl: startup?.logoUrl }} />
                </div>
                <Styled.Title> Arquivar e enviar devolutiva </Styled.Title>
            </Styled.Avatar>

            <Container.Body>
                <Styled.SubTitle>
                    Você está enviando uma devolutiva para a {startup?.name}, encerrando a
                    participação desta no desafio.
                </Styled.SubTitle>
            </Container.Body>

            <Container.Body>
                <Styled.FeedbackFormField>
                    <Form.Field {...fields.comment} />
                </Styled.FeedbackFormField>
            </Container.Body>

            <Container.Feet style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outline" onClick={() => setModal(null)}>
                    CANCELAR
                </Button>

                <Button variant="blue" onClick={putEventStatus}>
                    ENVIAR
                </Button>
            </Container.Feet>
        </Styled.ChallengeFeedback>
    );
};
