import React from 'react';

import { useConversionTable } from 'hooks';
import { useCXI } from 'modules/cxi/useCXI';

import { Option } from '../components/option/Option';

import * as Form from '../components/form';

export const Communities = () => {
    const { t, vars, addVars } = useCXI();
    const conv = useConversionTable(['community']);
    const data = vars.profile.profile.sector.communities;

    const handleChange = value => {
        let newData = [...data];
        if (data.includes(value)) {
            newData = data.filter(v => v !== value);
        } else {
            newData.push(value);
        }

        const profile = {
            ...vars.profile,
            profile: {
                ...vars.profile.profile,
                sector: { ...vars.profile.profile.sector, communities: [...newData] },
            },
        };
        addVars({ profile });
    };

    return (
        <Form.Form name="Communities">
            <Form.Title>{t('setup.Titles.communities')}</Form.Title>
            <Form.Descr>{t('setup.Descrs.communities')}</Form.Descr>

            <Form.OptionsContainer>
                {Object.keys(conv.community)
                    .map(value => parseInt(value, 10))
                    .map(value => (
                        <Option
                            label={conv.community[String(value)]}
                            value={value}
                            key={value}
                            checked={!!data.includes(value)}
                            onChange={() => handleChange(value)}
                        />
                    ))}
            </Form.OptionsContainer>
        </Form.Form>
    );
};
