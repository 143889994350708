import React from 'react';
import { IonList } from '@ionic/react';

import { ListVerticalItem } from './ListVerticalItem';
import * as Styled from './StyledListVertical';

export const ListVertical = props => (
    <Styled.ListVertical as={IonList} {...props}>
        {props.items.map((i, k) => {
            const kk = i[props.itemKey] || `${props.name}-${props.itemKey}-${k}`;
            return <ListVerticalItem key={kk} data={i} {...props} />;
        })}
    </Styled.ListVertical>
);
export const Vertical = ListVertical;
export default ListVertical;
