import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import * as Styled from './StyledSessions';

function RightSession(props) {
    const { data } = props;
    const dataChart = data.contractsWithStartupsChartData;
    // eslint-disable-next-line no-useless-computed-key
    const formattedDataChart = dataChart.map(item => {
        return {
            date: item.date,
            contract: item.contracts,
            // eslint-disable-next-line no-useless-computed-key
            ['categoria A']: item.catA,
            // eslint-disable-next-line no-useless-computed-key
            ['categoria B']: item.catB,
            // eslint-disable-next-line no-useless-computed-key
            ['categoria C']: item.catC,
            // eslint-disable-next-line no-useless-computed-key
            ['categoria D']: item.catD
        };
    });
    const formatDataForXAxis = chartData => {
        const d = [...chartData];
        const datesAlreadyListed = [];
        for (let i = 0; i < d.length; i++) {
            const year = d[i].date.split('-')[0];
            // if (!datesAlreadyListed.includes(year)) {
            //     datesAlreadyListed.push(year);
            //     d[i].year = year;
            // }
            d[i].year = year;
            datesAlreadyListed.push(year);
        }
        return d;
    };

    return (
        <Styled.RightSession className="right-session">
            <Styled.UpperContainer>
                <Styled.UpperTitle>
                    <h1>{data.contractsWithStartupsTotal}</h1>
                    <h2>contrato(s) com startups</h2>
                </Styled.UpperTitle>

                <Styled.RSGraphicsContainer>
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                            width="100%"
                            height="100%"
                            data={
                                formattedDataChart.length > 0
                                    ? formatDataForXAxis(formattedDataChart)
                                    : null
                            }
                            margin={{
                                top: 10,
                                right: 40,
                                left: -20,
                                bottom: 0
                            }}
                        >
                            <XAxis dataKey="year" tick />
                            <YAxis />
                            <Tooltip />
                            <Area
                                type="monotone"
                                dataKey="categoria A"
                                stackId="1"
                                stroke="#8ccffa"
                                fill="#8ccffa"
                            />
                            <Area
                                type="monotone"
                                dataKey="categoria B"
                                stackId="1"
                                stroke="#4DB4FB"
                                fill="#4DB4FB"
                            />
                            <Area
                                type="monotone"
                                dataKey="categoria C"
                                stackId="1"
                                fill="#1882cc"
                            />
                            <Area
                                type="monotone"
                                dataKey="categoria D"
                                stackId="1"
                                stroke="#005995"
                                fill="#005995"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </Styled.RSGraphicsContainer>
            </Styled.UpperContainer>
            <Styled.LowerContainer>
                <h2>Novas Startups Contratadas</h2>
                <Styled.NewStartupsLogosContainer>
                    {data.latestContractedStartups.slice(0, 10).map(startup => {
                        return (
                            <Styled.NewStartupsIndividualLogo key={startup.logoUrl}>
                                <img src={startup.logoUrl} alt={startup.name} />
                            </Styled.NewStartupsIndividualLogo>
                        );
                    })}
                </Styled.NewStartupsLogosContainer>
            </Styled.LowerContainer>
        </Styled.RightSession>
    );
}

export default RightSession;
