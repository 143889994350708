import React from 'react';

import { iframeLink } from 'core/iframe';
import { CONFIG } from 'config';
import { StartupCard } from 'modules/cxi/matches/components/startup-card/StartupCard';
import { CXIContextProvider } from 'modules/cxi/CXIContext';
import { Button } from 'modules/cxi/components/button';
import { RHP } from 'providers';

import { useStartupCXI } from '../../useStartupCXI';

import { Container } from '../container';
import * as Styled from './StyledStartup';

export const Startup = () => {
    const { t, modal, setModal, vars } = useStartupCXI();
    const { startupId, startup } = vars;

    const card = {
        ...startup,
        ranking: {},
    };
    const match = { ...modal.match, card };

    const handleMore = async () => {
        setModal(null);
        RHP.push(`/startup/${startupId}/100-10-1`);
    };

    console.log(iframeLink(`${CONFIG.appLinks.startup}/home/?startupId=${startupId}`).pathname);

    return (
        <CXIContextProvider>
            <Styled.Startup as={Container}>
                <Container.Body>
                    <Styled.StartupReturn>
                        <Styled.Texts>
                            <p className="title">{t('modal.Startup.texts1')}</p>
                            <p className="descr">{t('modal.Startup.texts2')}</p>
                            <p className="highs">{t('modal.Startup.texts3')}</p>
                            <p>{t('modal.Startup.texts4')}</p>
                            <p>{t('modal.Startup.texts5')}</p>
                        </Styled.Texts>

                        <Styled.Buttons>
                            <a
                                className="button"
                                href={
                                    iframeLink(
                                        `${CONFIG.appLinks.startup}/home/?startupId=${startupId}`
                                    ).pathname
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('modal.Startup.edit')}
                            </a>
                            <Button variant="outline" onClick={handleMore}>
                                {t('modal.Startup.more')}
                            </Button>
                        </Styled.Buttons>
                    </Styled.StartupReturn>
                    <StartupCard match={match} />
                </Container.Body>
            </Styled.Startup>
        </CXIContextProvider>
    );
};
