import React from 'react';
import { Route as ReactRoute, Switch } from 'react-router-dom';

import { RoutePrivate } from 'routes';
import * as Register from './index';

export const routes = [
    {
        exact: true,
        path: '/register',
        private: false,
        component: Register.RegisterIndex,
    },
    {
        exact: true,
        path: '/register/company',
        private: true,
        component: Register.RegisterCompany,
    },
    {
        exact: true,
        path: '/register/company/create',
        private: true,
        component: Register.RegisterCompanyCreate,
    },
    {
        exact: true,
        path: '/register/company/select',
        private: true,
        component: Register.RegisterCompanySelect,
    },
    {
        exact: true,
        path: '/register/company/validate',
        private: true,
        component: Register.RegisterCompanyValidate,
    },
    {
        exact: true,
        path: '/register/finished',
        private: true,
        component: Register.RegisterFinished,
    },
    {
        exact: true,
        path: '/register/location',
        private: true,
        component: Register.RegisterLocation,
    },
    {
        exact: true,
        path: '/register/profile',
        private: true,
        component: Register.RegisterProfile,
    },
];
export const RegisterRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const Route = r.private ? RoutePrivate : ReactRoute;
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
