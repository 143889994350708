import * as Core from '../core';
import * as Modules from '.';

export const routes = [
    {
        exact: false,
        icon: 'fal fa-home',
        name: 'Home',
        path: '/Home',
        private: true,
        component: Core.Home,
    },
    {
        exact: false,
        icon: 'fal fa-bring-forward',
        name: 'Matchmaking',
        path: '/matches',
        private: true,
        scope: 'evaluator',
        component: Modules.Matches,
    },
    {
        exact: false,
        path: '/100-10-1/certification/:userId',
        private: false,
        component: Modules.CXICertification,
    },
    {
        exact: false,
        icon: 'fal fa-hands-helping',
        name: '100-10-1',
        path: '/100-10-1',
        private: true,
        // scope: '100-10-1',
        component: Modules.CXI,
    },
    {
        exact: false,
        icon: 'fal fa-calendar-week',
        name: 'Events',
        path: '/events',
        private: true,
        scope: 'event',
        component: Modules.Events,
    },
    {
        exact: false,
        path: '/event',
        private: true,
        scope: 'event',
        component: Modules.Events,
    },
    {
        exact: false,
        icon: 'fal fa-newspaper',
        menuLang: ['pt'],
        name: 'News',
        path: '/news',
        private: true,
        component: Modules.News,
    },
    {
        exact: false,
        icon: 'fal fa-sort-shapes-up',
        name: 'Ranking',
        path: '/ranking',
        private: true,
        scope: 'ranking',
        component: Modules.Ranking,
    },
    {
        exact: false,
        icon: 'fal fa-rocket',
        name: 'Startup',
        path: '/startup',
        private: true,
        scope: 'user',
        component: Modules.Startup,
    },
    {
        exact: false,
        icon: 'fal fa-rocket',
        name: 'RegisterContact',
        path: '/registercontact/:startupSecret',
        private: false,
        scope: 'user',
        component: Modules.RegisterContact,
    },

    {
        exact: false,
        name: 'Oiweek',
        path: '/oiweek',
        private: true,
        component: Modules.Oiweek,
    },
    {
        exact: false,
        name: 'Investor',
        path: '/investor',
        private: true,
        scope: 'investor',
        component: Modules.Investor,
    },
    {
        exact: false,
        name: 'CorporateDashboard',
        path: '/corporatehub',
        private: true,
        icon: 'far fa-chart-bar',
        scope: 'user',
        component: Modules.CorporateHub,
    },
];
export default routes;
