import React from 'react';
import { useEffectOnce } from 'react-use';

import { Header } from 'modules/cxi/components/header/Header';
import { queryParamsToObject } from 'utils/query';
import { getDateXDaysAgo } from 'utils/date';

import { useStartupCXI } from '../useStartupCXI';

import * as Nav from './components/navigation';
import { CXIMentoringRoutes } from './CXIMentoringRoutes';
import * as Styled from './StyledCXIMentoring';

export const CXIMentoring = () => {
    const { t, vars, setModal, putMatchMentoring } = useStartupCXI();

    const query = queryParamsToObject(window.location.search);
    const { matchId, mentoring } = query;
    const match = vars.matches.find(m => m.matchid === matchId);

    useEffectOnce(() => {
        console.log(matchId, match, mentoring);
        if (matchId && match && mentoring) {
            const { history } = match;

            if (mentoring === match.mentoring) {
                const newMatch = { ...match, mentoringError: t(`mentoring.error.resent`) };
                setModal({ match: newMatch, modal: 'MentoringError' });
                return;
            }
            if (match.mentoring !== 'requested') {
                const newMatch = { ...match, mentoringError: t(`mentoring.error.notRequested`) };
                setModal({ match: newMatch, modal: 'MentoringError' });
                return;
            }

            // Check if there is older than 5 days ago
            const daysAgo = getDateXDaysAgo(5, new Date());
            const dateMatch = history.mentoringRequestedDate
                ? new Date(history.mentoringRequestedDate)
                : undefined;

            const isOverdue = match.mentoring === 'requested' && dateMatch && dateMatch < daysAgo;
            if (isOverdue) {
                setModal({ match, modal: 'MentoringOverdue' });
                return;
            }

            putMatchMentoring(match.matchid, { mentoring })
                .then(() => {
                    const modal = `Mentoring${mentoring === 'accepted' ? 'Accepted' : 'Rejected'}`;
                    setModal({ match, modal });
                })
                .catch(e => {
                    const newMatch = { ...match, mentoringError: e };
                    setModal({ match: newMatch, modal: 'MentoringError' });
                });
        }
        return () => {};
    }, []);

    return (
        <Styled.CXIMentoring>
            <Header
                className="c-header--main"
                title={t('mentoring.title')}
                tooltip={t('mentoring.tooltip')}
            >
                <Nav.Navigation>
                    <Nav.Page path="">{t('mentoring.tab.all')}</Nav.Page>
                    <Nav.Page path="/requested">{t('mentoring.tab.requested')}</Nav.Page>
                    <Nav.Page path="/accepted">{t('mentoring.tab.accepted')}</Nav.Page>
                    <Nav.Page path="/rejected">{t('mentoring.tab.rejected')}</Nav.Page>
                </Nav.Navigation>
            </Header>

            <Styled.CXIMentoringRoutes>
                <CXIMentoringRoutes />
            </Styled.CXIMentoringRoutes>
        </Styled.CXIMentoring>
    );
};
