import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const CXIMessagesIndex = styled.div`
    @media (min-width: 992px) {
        // background: rgba(0, 0, 0, 0.05);
        // border-radius: 0.8rem;
        // box-shadow: 0px 0px 15px #3428680d;
        // padding: 1.6rem;

        // .c-header {
        //     margin: -1.6rem -1.6rem 0;
        // }
    }

    .c-header {
        box-shadow: none;
        position: sticky;
        top: 0;
        z-index: 100;
    }
    .c-header .c-header-body {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        gap: 0.8rem;
    }

    .c-header-input {
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
        border-radius: 0.8rem;
        padding: 0.8rem 1.4rem;
        width: 100%;
    }
`;

export const CXIMessagesIndexTitle = styled.h2`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.3;
    margin: 2.4rem 0;
    ${truncate(1, 1.3)}
`;

export const CXIMessagesIndexConversation = styled.div`
    margin-top: 1.6rem;
    & + & {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 1.6rem;
    }
`;

export const HeaderSearch = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    display: flex;
    flex: 0 0 auto;
    font-size: 1.8rem;
    margin: 0 0 0 auto;
    padding: 0;
    text-align: left;
    transform: translateY(1px);
    -webkit-appearance: none;
`;
