import React, { useContext } from 'react';

import { useApi } from 'hooks';
import { useEffectOnce } from 'react-use';
import { CXIDashboardContext } from './CXIDashboardContext';

export const useCXIDashboard = (bootstrap = false) => {
    const { t, companyId, vars, addVars, setVars, ...context } = useContext(CXIDashboardContext);
    const api = useApi();

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    let isIncludeGroup = false;
    function includeGroup() {
        return vars.includeGroup || isIncludeGroup ? '?includeGroup=true' : '';
    }

    async function getCompany() {
        try {
            const company = await api.get(`/companies/${companyId}`);
            isIncludeGroup = company.isCxiGroup;
            addVars({ company, includeGroup: company.isCxiGroup });
            // Check parent for company info
            if (company.groupid) {
                const parent = await api.get(`/companies/${company.groupid}`);
                isIncludeGroup = parent.isCxiGroup;
                addVars({
                    parentCompany: parent,
                    company: parent.isCxiGroup ? parent : company,
                    includeGroup: parent.isCxiGroup
                });
            }
        } catch (e) {
            throw new Error('CXI: Not able to get company.');
        }
    }

    async function getCompanyRanking() {
        try {
            const ranking = await api.get(
                `/companies/${companyId}/100-10-1/ranking${includeGroup()}`
            );
            addVars({ ranking });
            return ranking;
        } catch (e) {
            throw new Error('CXI: Not able to get company CXI ranking.');
        }
    }

    async function getStats() {
        try {
            const stats = await api.get(`/companies/${companyId}/100-10-1/stats${includeGroup()}`);
            addVars({ stats });
        } catch (e) {
            throw new Error('CXI: Not able to get company dashboard stats.');
        }
    }

    async function getStages(stagesStart, stagesEnd) {
        try {
            const params =
                stagesStart && stagesEnd
                    ? { startDate: stagesStart, endDate: stagesEnd }
                    : undefined;
            const stages = await api.get(
                `/companies/${companyId}/100-10-1/stages${includeGroup()}`,
                {
                    params
                }
            );
            addVars({ stages });
        } catch (e) {
            throw new Error('CXI: Not able to get company dashboard stages.');
        }
    }

    async function getStartups() {
        try {
            const startups = await api.get(
                `/companies/${companyId}/100-10-1/startups${includeGroup()}`
            );
            addVars({
                startups: startups.sort((a, b) => {
                    if (a.suggestionsAccepted > b.suggestionsAccepted) return -1;
                    if (a.suggestionsAccepted < b.suggestionsAccepted) return 1;
                    return 0;
                })
            });
        } catch (e) {
            throw new Error('CXI: Not able to get company dashboard startups.');
        }
    }

    async function getStartupById(startupId) {
        try {
            const url = `/companies/${companyId}/100-10-1/startups/${startupId}/profile${includeGroup()}`;
            return await api.get(url);
        } catch (e) {
            throw new Error('CXI: Not able to get company dashboard startup.');
        }
    }

    async function getUsers() {
        try {
            const users = await api.get(`/companies/${companyId}/100-10-1/users${includeGroup()}`);
            addVars({ users });
        } catch (e) {
            throw new Error('CXI: Not able to get company dashboard users.');
        }
    }

    async function getUserById(userId) {
        try {
            const user = vars.users.find(u => u._id === userId);
            if (!user) {
                throw new Error('CXI: Company dashboard user not found.');
            }
            return user;
        } catch (e) {
            throw new Error('CXI: Not able to get company dashboard user.');
        }
    }

    async function getUserEvaluations(userId) {
        try {
            const url = `/companies/${companyId}/100-10-1/users/${userId}/evaluations${includeGroup()}`;
            return await api.get(url);
        } catch (e) {
            throw new Error('CXI: Not able to get company dashboard user evaluations.');
        }
    }

    async function downloadDashboardUsers() {
        const url = `/companies/${companyId}/100-10-1/users${includeGroup()}`;
        return api
            .get(url, {
                responseType: 'blob',
                headers: {
                    Accept: 'text/csv'
                }
            })
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'DashboardUsers.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    }

    async function downloadDashboardGeral() {
        const url = `/companies/${companyId}/100-10-1/stats${includeGroup()}`;
        return api
            .get(url, {
                responseType: 'blob',
                headers: {
                    Accept: 'text/csv'
                }
            })
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'DashboardGeral.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    }

    async function downloadDashboardStartups() {
        const url = `/companies/${companyId}/100-10-1/startups${includeGroup()}`;
        return api
            .get(url, {
                responseType: 'blob',
                headers: {
                    Accept: 'text/csv'
                }
            })
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'DashboardStartups.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    }

    async function bootstrapCXIDashboard() {
        getCompany().then(() =>
            Promise.all([getStats(), getStartups(), getUsers()]).then(res => setReady(true))
        );
    }

    useEffectOnce(() => {
        // Initialize the module's service
        if (bootstrap && companyId) {
            bootstrapCXIDashboard();
        }
    });

    if (context === undefined) {
        throw new Error('useCXIDashboard must be used within a CXIDashboardContextProvider');
    }
    return {
        t,
        // Ready to load?
        ready,

        // Variables handlers
        companyId,
        addVars,
        setVars,
        vars,
        ...context,
        downloadDashboardUsers,
        downloadDashboardStartups,
        downloadDashboardGeral,
        getCompany,
        getCompanyRanking,
        getStages,
        getStats,
        getStartups,
        getStartupById,
        getUsers,
        getUserById,
        getUserEvaluations
    };
};
