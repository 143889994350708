import React from 'react';

import * as Styled from './StyledOption';

export const Option = ({ checked, icon, label, text, onChange, ...props }) => {
    const handleClick = () => onChange();
    return (
        <Styled.Option
            className={checked ? 'checked' : ''}
            type="button"
            onClick={handleClick}
            {...props}
        >
            <span className="circle" aria-label={checked ? 'Selecionado' : ''}>
                {text && <span className="text">{text}</span>}
                {icon && <span className={`icon far ${icon}`} aria-hidden="true" />}
                {checked && <span className="chck fas fa-check-circle" aria-hidden="true" />}
            </span>
            <span className="label">{label}</span>
        </Styled.Option>
    );
};
