import React from 'react';
import * as Styled from './StyledComponentForm';

export const ComponentForm = ({ children, name, onSubmit, ...props }) => (
    <Styled.ComponentForm name={name} onSubmit={onSubmit} {...props}>
        {children}
    </Styled.ComponentForm>
);
export const Form = ComponentForm;
export default ComponentForm;
