import React from 'react';

import { RHP } from 'providers';
import { useForm } from 'components/form';
import * as Page from 'components/page';

import { MergerAddForm } from './MergerAddForm';

export function MergerAdd(props) {
    const { addVars, vars, putStartupAdvanced } = props.useStartupId;

    // Initialize a new form handler
    const form = useForm({});

    // Handle form submitions
    const onSubmit = values => {
        const newAcquisition = {
            type: values.mergerType,
            date: values.mergerDate,
            name: values.withWhom,
        };
        const acquisitions = [...vars.advancedForm.acquisitions];
        const newAcquisitions = [...acquisitions, newAcquisition];
        const payload = {
            acquisitions: newAcquisitions,
        };

        // Add merger (skip addVars = true)
        return putStartupAdvanced(payload, true)
            .then(() => {
                addVars({ advancedForm: { ...vars.advancedForm, acquisitions: newAcquisitions } });
                return RHP.push(`/startup/${vars.startupId}/advanced-edit#merger-session`);
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="MergerAdd">
            <Page.Title>{props.t('MergerAdd.title')}</Page.Title>

            <MergerAddForm {...propsForm} />
        </Page.Page>
    );
}
export default MergerAdd;
