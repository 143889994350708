import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Container = styled.div.attrs({
    className: 'cxi-layout',
})`
    background: ${({ theme }) => theme.colors.whiteDarker};
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: auto;
    position: fixed;
    ${positions(0, 0, 0, 0)}

    ion-content {
        --background: transparent;
        --color: #fff;
    }
`;

export const Content = styled.div`
    align-items: stretch;
    flex: 1 1 auto;
    display: flex;
`;

export const Main = styled.main.attrs({
    className: 'cxi-main',
})`
    position: relative;
    width: 100%;
    z-index: 300;

    ion-content {
        --background: #f2f2f2;
        --color: #444;
    }

    .component-page-container {
        padding: 2.4rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
        @media (min-width: 992px) {
            padding: 3.2rem;
        }
    }
    .cp-CXINetworks .component-page-container {
        max-width: 100% !important;
        width: 100% !important;
    }
`;

export const Menu = styled.nav.attrs(props => ({
    className: props.menuOpen ? 'is-open' : '',
}))`
    background: #fff;
    box-shadow: 0px 2px 12px #34286812;
    display: none;
    flex: 0 0 32rem;
    overflow: scroll-y;
    position: fixed;
    ${positions('0', 'auto', 0, 0)}
    transform: translateX(-100%);
    width: 32rem;
    z-index: 999;

    &.is-open {
        display: block;
        transform: translateX(0);
    }

    @media (min-width: 992px) {
        overflow: hidden;
        position: static;
        ${positions('auto', 'auto', 'auto', 'auto')}
        z-index: 200;

        &.is-open + .cxi-main {
            width: calc(100vw - 3.2rem);
        }
    }
`;
