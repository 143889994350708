import React, { useEffect } from 'react';

import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

import { LocalStorageProvider as LSP } from 'providers';
import { CONFIG } from 'config';

const unhandledApi = axios.create({ baseURL: CONFIG.apiUrl });
unhandledApi.interceptors.response.use(res => {
    return res.data;
});

const initial = {
    // Authentication variables
    auth: LSP.get('100os-auth') || {},
    user: LSP.get('100os-user') || {},
    tokn: false,
    authenticated: false
};

// Tries to set the state to auhenticated
// If everything is ok with the userid and token
if (initial.auth.token && initial.user.userid) {
    try {
        initial.tokn = jwtDecode(initial.auth.token);
        initial.authenticated = true;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('ContextAuth', 'Initial Token is not valid');
        initial.authenticated = false;
    }
}

const normalizeUserVars = oldUser => {
    const user = oldUser;
    user.fullname = '';
    if (user.firstName && user.lastName) {
        user.fullname = `${user.firstName} ${user.lastName}`;
    }
    user.locationString = '';
    if (user.location && user.location.city && user.location.city.name) {
        user.locationString = `${user.location.city.name}, ${user.location.state.name} - ${user.location.country.name}`;
    }
    return user;
};

const contextReducer = (state, action) => {
    const reduce = {
        AUTHENTICATE: () => {
            try {
                const { auth } = action;
                // Tries to decode the JWT token
                const tokn = jwtDecode(auth.token);
                // Create fullname variable
                const user = normalizeUserVars({ ...action.user });
                // Write values to the localStorage
                LSP.set('100os-auth', auth);
                LSP.set('100os-user', user);
                return { auth, user, tokn, authenticated: true };
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('ContextAuth', 'Token is not valid');
                return { ...initial };
            }
        },
        UNAUTHENTICATE: () => {
            LSP.set('100os-auth', action.auth || {});
            LSP.set('100os-user', action.user || {});
            return {
                ...state,
                auth: {},
                user: {},
                tokn: {},
                authenticated: false,
                pathnameUrl: action.pathnameUrl,
                returnUrl: action.returnUrl
            };
        },
        // Update only the authentication info
        UPDATE_AUTH_INFO: () => {
            LSP.set('100os-auth', action.auth || {});
            return { ...state, auth: action.auth };
        },
        // Update only the authentication info
        UPDATE_CXI_INFO: () => {
            return { ...state, cxi: action.cxi };
        },
        // Update only the authenticated user
        UPDATE_USER_INFO: () => {
            // Create fullname variable
            const user = normalizeUserVars({ ...action.user });
            LSP.set('100os-user', user || {});
            return { ...state, user };
        },
        // Update only the authenticated user's avatar
        UPDATE_USER_AVATAR: () => {
            const user = { ...state.user, photoUrl: action.photoUrl };
            LSP.set('100os-user', user || {});
            return { ...state, user };
        }
    }[action.type];
    return (reduce && reduce()) || state;
};

// Create the proper context variables
export const ContextAuth = React.createContext();
export const ContextAuthProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(contextReducer, initial);

    const hasScope = scope => {
        return state.tokn.scopes?.includes(scope);
    };

    const scopeAllows = scope => {
        if (!scope || state.tokn.scopes?.includes('full')) return true;
        if (!state.tokn.scopes?.includes(scope)) return false;
        return true;
    };

    async function getCXIProfile(auth) {
        const { userid, token } = auth;
        const cxi = await unhandledApi(`/users/${userid}/100-10-1`, {
            headers: { api_key: token }
        });
        if (cxi.status !== 'registered') {
            dispatch({ type: 'UPDATE_CXI_INFO', cxi: false });
        }
        dispatch({ type: 'UPDATE_CXI_INFO', cxi });
    }

    const { auth } = state;
    useEffect(() => {
        if (auth.userid && auth.token) {
            getCXIProfile(auth);
        }
    }, [auth]);

    const stateValue = {
        state,
        dispatch,
        hasScope,
        scopeAllows,
        authState: state,
        authDispatch: dispatch
    };
    return <ContextAuth.Provider value={stateValue}>{children}</ContextAuth.Provider>;
};

// Create some shortcuts to export
export const CA = ContextAuth;
export const CAP = ContextAuthProvider;
