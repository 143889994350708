import React from 'react';
import { Route, Switch } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import * as Profile from './index';

export const routes = [
    {
        exact: true,
        path: '/profile',
        component: Profile.ProfileIndex,
    },
    {
        exact: true,
        path: '/profile/company',
        component: Profile.ProfileCompany,
    },
    {
        exact: true,
        path: '/profile/company/create',
        component: Profile.ProfileCompanyCreate,
    },
    {
        exact: true,
        path: '/profile/company/select',
        component: Profile.ProfileCompanySelect,
    },
    {
        exact: true,
        path: '/profile/company/validate',
        component: Profile.ProfileCompanyValidate,
    },
    {
        exact: true,
        icon: 'far fa-user',
        name: 'Edit',
        path: '/profile/edit',
        component: Profile.ProfileEdit,
    },
    {
        exact: true,
        icon: 'far fa-compass',
        name: 'Location',
        path: '/profile/location',
        component: Profile.ProfileLocation,
    },
    {
        exact: true,
        icon: 'far fa-lock',
        name: 'Password',
        path: '/profile/password',
        component: Profile.ProfilePassword,
    },
];
export const ProfileRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
