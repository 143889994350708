import React from 'react';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

import { ContextGlobal as CG } from 'contexts';
import { useNewsApi } from 'modules/news/useNewsApi';
import { PostCard } from 'modules/news/components';
import * as List from 'components/list';

import * as Styled from './StyledHomeListNews';

export function HomeListNews(props) {
    const { language } = React.useContext(CG).state;
    const { t } = useTranslation('home');
    const api = useNewsApi();

    // Use the state do handle the api request
    const [news, setNews] = React.useState([]);

    function filterNews(e) {
        if (
            (e.requireRegistered || e.hiddenUser) &&
            (!e.user || !['registered', 'attended'].includes(e.user.eventStatus))
        ) {
            return false;
        }
        return true;
    }

    // Get the latest news
    let mounted = false;
    useEffectOnce(() => {
        mounted = true;
        api.get(`/posts/?_embed=true&per_page=3`)
            .then(res => {
                const items = res.filter(filterNews);
                return mounted && setNews(items);
            })
            .catch(err => mounted && setNews([]));
        return () => {
            mounted = false;
        };
    });

    // Don't show if the news array is empty
    return language !== 'pt' || !news.length ? null : (
        <Styled.HomeListNews as={List.List} name="HomeListNews">
            <List.Subtitle href="/news" text={t('HomeListNews.title')} />
            <List.Vertical items={news} itemKey="_id" itemComponent={PostCard} />
        </Styled.HomeListNews>
    );
}
export default HomeListNews;
