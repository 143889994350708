import styled from 'styled-components';

export const ItemPocContainer = styled.div`
    font-size: 16px;
    // max-width: 685px;
`;

export const LastChangeContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    div {
        font-weight: bolder;
        font-size: 16px;
    }

    p {
        font-size: 13px;
        font-weight: 300;
        font-style: italic;
    }
`;

export const PocsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
`;

export const PocsSession = styled.div`
    & > span {
        font-style: italic;
    }
`;
