import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionMentoringSessionAdd = ({ match }) => {
    const { setModal } = useCXI();

    return (
        <Styled.MatchAction
            className="icon-only"
            onClick={() => setModal({ match, modal: 'MentoringSession' })}
        >
            <span className="icon fas fa-fw fa-plus-circle" aria-hidden="true" />
        </Styled.MatchAction>
    );
};
