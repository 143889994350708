import styled from 'styled-components';

export const RegisterFinished = styled.div.attrs({
    className: 'styled-register-finished',
})`
    .component-page-container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        min-height: 100%;
        padding: 3rem;
        padding-top: calc(3rem + var(--ion-safe-area-top));
        padding-bottom: calc(3rem + var(--ion-safe-area-bottom));
    }

    .component-list {
        margin-top: auto;
        padding-top: 4rem;
    }
    .component-list .list-vertical-item-link {
        border-color: rgba(255, 255, 255, 0.2);
        color: #fff;
    }
`;
