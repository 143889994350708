import React from 'react';
import { useEffectOnce } from 'react-use';
import { Link } from 'react-router-dom';

import { useCXI } from 'modules/cxi/useCXI';
import { Content } from 'modules/cxi/contents/components/content/Content';
import * as Styled from './StyledContents';

export const Contents = () => {
    const { t, vars, getCXIContents, getUserReadCXIContents } = useCXI();
    const { readUserContents } = vars;
    useEffectOnce(() => {
        if (!vars.contents) getCXIContents();
        if (!readUserContents) getUserReadCXIContents();
    }, []);

    const readContentIds = Object.keys(readUserContents);

    const filtered = vars.contents.map(i => {
        i.read = readContentIds.includes(i.id);
        return i;
    });

    return !vars.contents?.length ? null : (
        <Styled.Contents>
            <Styled.ContentsHead>
                <h2>{t('home.Contents.title')}</h2>
                <Link to="/100-10-1/contents">{t('home.Contents.seemore')}</Link>
            </Styled.ContentsHead>
            <Styled.ContentsInfo>
                <p>{t('home.Contents.descr')}</p>
            </Styled.ContentsInfo>
            <Styled.ContentsBody>
                {filtered.slice(0, 4).map(content => (
                    <Content key={content.id} data={content} />
                ))}
            </Styled.ContentsBody>
        </Styled.Contents>
    );
};
