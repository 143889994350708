// import React from 'react';

// import { useEffectOnce } from 'react-use';

// import * as List from 'components/list';
// import * as Page from 'components/page';
// import * as Tabs from 'components/tabs';

// import { EventCard, EventCardSmall } from '../components';

import { CONFIG } from 'config';
import { iframeReplace } from 'core/iframe';

export function EventsIndex(props) {
    iframeReplace(CONFIG.appLinks.events, '/home')();
    return null;

    // const { vars, getEvents } = props.useEvents;
    // const { futureEvents, byYear } = vars;

    // // Get events from the api only when the component renders
    // useEffectOnce(() => {
    //     getEvents();
    // });

    // return (
    //     <Page.Page name="EventsIndex">
    //         <Page.Title>{props.t('EventsIndex.title')}</Page.Title>
    //         <Page.Descr>{props.t('EventsIndex.descr')}</Page.Descr>

    //         <List.List name="EventsIndexList">
    //             <List.Scrolls items={futureEvents} itemKey="_id" itemComponent={EventCard} />
    //         </List.List>

    //         {futureEvents.length > 0 && <Page.Ruler />}

    //         {Object.keys(byYear).length > 0 && (
    //             <Tabs.TabsContainer>
    //                 <Tabs.TabsButtons>
    //                     {Object.keys(byYear)
    //                         .reverse()
    //                         .map(year => (
    //                             <Tabs.Tab key={`tab-year-${year}`} name={`year-${year}`}>
    //                                 {year}
    //                             </Tabs.Tab>
    //                         ))}
    //                 </Tabs.TabsButtons>

    //                 {Object.keys(byYear)
    //                     .reverse()
    //                     .map(year => (
    //                         // <List.List key={`list-year-${year}`} name={`year-${year}`}>
    //                         //     <List.Scrolls
    //                         //         items={byYear[year]}
    //                         //         itemKey="_id"
    //                         //         itemComponent={EventCard}
    //                         //     />
    //                         // </List.List>
    //                         <div
    //                             className="row align-items center"
    //                             key={`list-year-${year}`}
    //                             name={`year-${year}`}
    //                         >
    //                             {byYear[year].map(event => (
    //                                 <div key={event._id} className="col col-auto pt-5">
    //                                     <EventCardSmall item={event} />
    //                                 </div>
    //                             ))}
    //                         </div>
    //                     ))}
    //             </Tabs.TabsContainer>
    //         )}
    //     </Page.Page>
    // );
}
export default EventsIndex;
