// import React from 'react';

// import { ContextAuth as CA } from 'contexts';
import { RouterHistoryProvider as RHP } from 'providers';

export function RegisterCompany(props) {
    // TEMP_FIX - Will @ 16/03/2020
    // USER CAN'T CHOOSE THE COMPANY SEND DIRECTLY TO PROFILE
    // The changes necessary for this were not implemented
    RHP.push('/register/profile');
    return null;

    // const { state } = React.useContext(CA);
    // const { user } = state;

    // // Check if the user was not given a company
    // if (!user.company || (user.company && !user.company.id)) {
    //     RHP.push('/register/company/select');
    //     return null;
    // }

    // // Check if the user is not validated in the company
    // if (!user.company.valid) {
    //     RHP.push('/register/company/validate');
    //     return null;
    // }

    // // If non of the above, go to set the profile
    // RHP.push('/register/profile');
    // return null;
}
export default RegisterCompany;
