import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const StartupCard = styled.div.attrs({
    className: 'cxi-matches-startup-card',
})`
    align-items: center;
    background: #fff;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 52rem;
    margin: 4.4rem auto 0;
    padding: 3.2rem;
    position: relative;
    text-align: center;
    width: 100%;
`;

export const Buttons = styled.div.attrs({
    className: 'cxi-matches-startup-card-buttons',
})`
    align-items: center;
    display: flex;
    gap: 1.6rem;
    margin-top: 4.8rem;
`;

export const Image = styled.div.attrs({
    className: 'user-company-card-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 4px solid #ddd;
    border-radius: 50%;
    margin-top: -7.6rem;
    flex: 0 0 14rem;
    height: 14rem;
    padding: 1.6rem;
    width: 14rem;
`;

export const Title = styled.strong`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 1.6rem 0 0;
    text-align: center;
    ${truncate(2, 1.2)}
`;

export const Label = styled.div`
    margin: 1.6rem 0 0;
    position: relative;

    .text {
        background: ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
        border-radius: 1.2rem;
        font-size: 1.2rem;
        font-weight: 600;
        padding: 0.3rem 0.8rem 0.4rem;
    }

    .ttip {
        cursor: help;
        margin: -0.2rem 0 0 0.4rem;
        position: absolute;
        ${positions(0, 'auto', 'auto', '100%')}

        .ttip-icon {
            opacity: 0.5;
        }
        .ttip-text {
            background: ${({ theme }) => theme.colors.darkGray};
            border-radius: 0.8rem;
            box-shadow: 0px 2px 12px #34286812;
            color: ${({ theme }) => theme.colors.white};
            display: none;
            font-size: 1.2rem;
            line-height: 1.2;
            padding: 0.8rem 1rem;
            position: absolute;
            ${positions(0, 0, 'auto', 'auto')}
            width: 21rem;
        }

        &:focus,
        &:hover {
            .ttip-text {
                display: block;
            }
        }
    }
`;

export const Descr = styled.strong`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 2.4rem 0 0;
    text-align: center;
    ${truncate(3, 1.4)}
`;

export const IconWithText = styled.p`
    align-items: center;
    color: ${({ theme }) => theme.colors.darkGray};
    display: flex;
    gap: 0.8rem;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 1.6rem 0 0;

    .icon {
        color: ${({ theme }) => theme.colors.energyBlue};
    }
    & + & {
        margin-top: 0.4rem;
    }
`;

export const Recommendation = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.energyBlueLight};
    border-radius: 4.4rem;
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    gap: 0.8rem;
    justify-content: flex-start;
    margin-top: 1.6rem;
    max-width: 20rem;
    padding: 0.4rem;
    text-align: left;

    @media (min-width: 768px) {
        margin: 3.2rem 0 0 -2rem;
        position: absolute;
        ${positions(0, 'auto', 'auto', 0)}
    }

    .user-avatar {
        width: 3.5rem;
    }
    .user-information {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.2rem;
        padding-right: 1.2rem;
    }
    .user-information .user-text {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }
    .user-information .user-name {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }
`;

export const RecommendationRanking = styled.div`
    align-items: center;
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: flex-start;
    margin-top: 1.6rem;
    max-width: 20rem;
    padding: 0;
    text-align: left;

    @media (min-width: 768px) {
        margin: 1.6rem 1.6rem 0 0;
        position: absolute;
        ${positions(0, 0, 'auto', 'auto')}
    }

    img {
        width: 10rem;
    }

    .user-text {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }
`;
