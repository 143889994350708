import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { MetricItem } from './MetricItem';

import * as Styled from './StyledMetrics';

export const MetricsStage2 = () => {
    const { t, vars } = useCXI();
    const { stage2 } = vars.cxi.metrics;

    const metric1 = Number(stage2.speedDatingsRequested || 0);
    const metric2 = Number(stage2.speedDatingsHeld || 0);
    const metric3 = Number(stage2.feedbacksPositive || 0);

    return (
        <Styled.MetricsItems className="stage-2">
            <MetricItem
                icon="fa-envelope-open-text"
                numb={t('home.Metrics.speedDatingsSent.number', { number: metric1 })}
                text={t('home.Metrics.speedDatingsSent.text')}
                tooltip={t('home.Metrics.speedDatingsSent.tooltip')}
            />
            <MetricItem
                icon="fa-handshake"
                numb={t('home.Metrics.speedDatingsHeld.number', { number: metric2 })}
                text={t('home.Metrics.speedDatingsHeld.text')}
                tooltip={t('home.Metrics.speedDatingsHeld.tooltip')}
            />
            <MetricItem
                icon="fa-hands-helping"
                numb={t('home.Metrics.speedDatingsPositive.number', { number: metric3 })}
                text={t('home.Metrics.speedDatingsPositive.text')}
                tooltip={t('home.Metrics.speedDatingsPositive.tooltip')}
            />
        </Styled.MetricsItems>
    );
};
