import React from 'react';
import { IonContent, IonModal } from '@ionic/react';

import { ModalNavbar } from './navbar/ModalNavbar';
import * as Styled from './StyledComponentModal';

export const ComponentModal = props => {
    return (
        <Styled.ComponentModal
            as={IonModal}
            isOpen={props.isOpen}
            onDidDismiss={() => props.close()}
        >
            <IonContent>
                <ModalNavbar close={props.close} title={props.title} />
                <Styled.ModalContainer>{props.children}</Styled.ModalContainer>
            </IonContent>
        </Styled.ComponentModal>
    );
};

export const Modal = ComponentModal;
export default ComponentModal;
