import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionEvaluate = ({ match }) => {
    const { t, setModal } = useCXI();
    const { evaluation } = match;

    let text = t('match.action.evaluate');
    let disabled = false;
    if (evaluation?.group && !evaluation?.startupFeedback) {
        text = t('match.action.waiting');
        disabled = true;
    }

    return (
        <Styled.MatchAction
            className="s1 action"
            disabled={disabled}
            onClick={() => setModal({ match, modal: 'Evaluate' })}
        >
            <span className="text">{text}</span>
        </Styled.MatchAction>
    );
};
