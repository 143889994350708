import React, { useEffect, useState } from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';

import { Container } from '../container';
import * as Styled from './StyledEvaluations';
import { EvaluationsEvaluation } from './EvaluationsEvaluation';

export const Evaluations = () => {
    const { t, modal, setModal, getMatchEvaluations } = useCXI();
    const { match } = modal;

    const [evaluations, setEvaluations] = useState(false);

    useEffect(() => {
        if (match) {
            getMatchEvaluations(match.matchid).then(res => {
                setEvaluations(res);
            });
        }
    }, []);

    return (
        <Styled.Evaluations as={Container}>
            <Container.Head image={match.startup.logoUrl}>
                <span className="text">{t('modal.Evaluations.title')}</span>
            </Container.Head>

            <Container.Body>
                {evaluations === false && 'Loading...'}
                {evaluations.length > 0 &&
                    evaluations.map(evaluation => (
                        <EvaluationsEvaluation key={evaluation._id} evaluation={evaluation} />
                    ))}
            </Container.Body>

            <Container.Feet>
                <Button variant="outline" onClick={() => setModal(null)}>
                    {t('modal.cancel')}
                </Button>
                <Button onClick={() => setModal({ match, modal: 'EvaluateForm' })}>
                    {t('modal.Evaluations.send')}
                </Button>
            </Container.Feet>
        </Styled.Evaluations>
    );
};
