import React, { createContext } from 'react';

export const TabsContext = createContext();
export const TabsContextProvider = ({ className, children, initial }) => {
    // Define variables holder to be used on the module
    const [tab, setTab] = React.useState(initial || '');
    const stateValue = { tab, setTab };
    return (
        <TabsContext.Provider className={className} value={stateValue}>
            {children}
        </TabsContext.Provider>
    );
};
