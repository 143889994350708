import styled from 'styled-components';

export const FilterTag = styled.button.attrs(props => ({
    className: props.active ? 'active' : '',
}))`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 2rem;
    color: ${({ theme }) => theme.colors.energyBlue};
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    gap: 0.4rem;
    line-height: 1.2;
    justify-content: center;
    padding: 0.6rem 1rem;
    min-width: 4rem;
    width: fit-content;

    &.active {
        background: ${({ theme }) => theme.colors.energyBlue};
        color: ${({ theme }) => theme.colors.white};
    }
`;
