import React from 'react';
import * as Styled from './StyledCXIProgress';

export function CXIProgressModule({ name, size }) {
    let status = 'blocked';
    let icon = 'fa-lock-alt';

    if (size >= 100) {
        status = 'finalized';
        icon = 'fa-check';
    }
    if (size > 0 && size < 100) {
        status = 'in-progress';
        icon = '';
    }

    return (
        <Styled.Module status={status}>
            <div className="text">
                {name}
                <span className="icon">
                    <i className={`fas ${icon}`} aria-hidden="true" />
                </span>
            </div>
            <div className="bar" style={{ width: `${size}%` }} />
        </Styled.Module>
    );
}
export default CXIProgressModule;
