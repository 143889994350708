import styled from 'styled-components';

export const Card = styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
`;

// LEFT SESSION
export const LeftSession = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    padding: 5px 5px;
    justify-content: space-between;
    margin: 0;
    height: 100%;
    gap: 10px;

    @media screen and (max-width: 900px) {
        width: 100%;
        min-height: 100%;
        position: relative;
    }
`;

//COMUNIDADES COM GRÁFICO
export const LSCommunitiesContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 37%;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 900px) {
        min-height: 80%;
    }

    h2 {
        width: 100%;
        font-size: 2.2rem;
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: bold;
    }
`;

export const CircledSpanListIcon = styled.div`
    background-color: ${props => props.BgColor};
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 100%;
`;
export const LSCommunitiesGraphicsContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: baseline;

    span {
        height: 100%;
        display: flex;
        width: 100%;
        justify-content: baseline;
    }

    @media screen and (max-width: 900px) {
        span {
            flex-direction: column;
            justify-content: baseline;
            align-items: center;
        }
    }
`;

export const ChartDiv = styled.div`
    display: flex;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 900px) {
        width: 100%;
        min-height: 50%;
        max-height: 50%;
    }
`;

export const LegendContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: left;
    @media screen and (max-width: 900px) {
        width: 100%;
        height: 30%;
    }
    ul {
        list-style: none;
        padding: 0;
    }
    //bolinha dos LI
    li {
        display: flex;
        align-items: center;
        justify-content: left;
    }
`;
//STARTUPS CONTRATADAS
export const ContractedStartupsContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 46%;

    @media screen and (max-width: 900px) {
        min-height: 100%;
        background: green;
    }
`;

export const ContractedStartupsTitle = styled.div`
    display: flex;
    align-items: baseline;
    padding: 10px;
    font-weight: bold;

    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 3.5rem;
        margin-bottom: 4px;
    }
    h2 {
        font-size: 2.2rem;
        margin-left: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
`;

export const ContractedStartupsGraphicsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .chartDiv {
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: -0.5rem;
    }
`;

//VALORES CONTRATADOS DE STARTUPS
export const ContractedValueContainer = styled(Card)`
    display: flex;
    height: 15%;
    padding: 10px;
    width: 100%;
    justify-content: left;
    align-items: center;
    margin: 0 auto;

    div {
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
    }

    h1 {
        width: fit-content;
        font-size: 3.5rem;
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: bold;

        &.smallText {
            font-size: 2.2rem;
        }
    }
`;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//RIGHT SESSION
export const RightSession = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    padding: 5px 5px;
    justify-content: space-between;
    margin: 0;
    gap: 10px;
    @media screen and (max-width: 900px) {
        width: 100%;
        min-height: 100%;
        position: relative;
    }
`;

//CONTRATOS COM STARTUPS
export const UpperContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    ul {
        display: flex;
        margin: 0;
        padding: 0;
        gap: 10px;
    }
    li {
        align-items: center;
        padding: 10px;
        display: flex;
        list-style: none;
        text-transform: uppercase;
    }
`;

export const UpperTitle = styled.div`
    display: flex;
    align-items: baseline;
    gap: 10px;
    padding-left: 10px;

    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 4rem;
    }
    h2 {
        font-size: 2.2rem;
        margin-left: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
`;

export const RSGraphicsContainer = styled(Card)`
    width: 100%;
    height: 90%;
    display: flex;
    padding: 0;
`;

//NOVAS STARTUPS CONTRATADAS
export const LowerContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30%;
    text-transform: uppercase;
    display: flex;

    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.2rem;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px;
        padding-bottom: 5px;
    }
`;

export const NewStartupsLogosContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
`;

export const NewStartupsIndividualLogo = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
        max-width: 75%;
        max-height: 50px;
    }
`;
