import React from 'react';
import * as Styled from './StyledFormControl';

export const FormControlHelper = props =>
    // Don't show this element if it is empty
    !props.text ? null : (
        <Styled.FormControlHelper id={props.id}>
            <span className="text">{props.text}</span>
        </Styled.FormControlHelper>
    );
export default FormControlHelper;
