import styled from 'styled-components';

export const MenuUserLogout = styled.button.attrs({
    className: 'menu-user-logout btn btn-linkd',
})`
    border-color: #fff !important;
    color: #fff !important;
    margin-top: 3rem;

    .icon {
        font-size: 1em;
        line-height: 1em;
    }
`;
