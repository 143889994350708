import React, { createContext } from 'react';

import { Loading } from '../components/loading';

import { useCXI } from '../useCXI';

export const CXIStartupContext = createContext();
export const CXIStartupContextProvider = ({ children, matchId }) => {
    const { t, vars: cxi, getMatchById, getMatchStartup } = useCXI();
    const { matches } = cxi;

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState({ loading: true, matchId });
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    React.useEffect(() => {
        if (matchId) {
            Promise.all([getMatchById(matchId), getMatchStartup(matchId)]).then(
                ([match, startup]) => {
                    addVars({ loading: false, match, startup });
                }
            );
        }
    }, [matchId, matches]);

    const stateValue = { t, vars, addVars, setVars };
    return vars.loading ? (
        <Loading>Carregando Startup...</Loading>
    ) : (
        <CXIStartupContext.Provider value={stateValue}>{children}</CXIStartupContext.Provider>
    );
};
