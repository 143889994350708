import styled from 'styled-components';

export const FilterTag = styled.button.attrs(props => ({
    className: `filter-tag ${props.active ? 'active' : ''}`,
}))`
    align-items: center;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.energyBlue};
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.energyBlue};
    display: flex;
    font-size: 1.3rem;
    font-weight: 600;
    gap: 0.6rem;
    line-height: 1.2;
    justify-content: center;
    padding: 1rem 1.6rem;

    &.active {
        background: ${({ theme }) => theme.colors.energyBlue};
        color: ${({ theme }) => theme.colors.white};
        justify-content: space-between;
    }

    .investimentos & {
        border-color: #2ebf88;
        color: #2ebf88;
        &.active {
            background: #2ebf88;
            color: #fff;
        }
    }
    .open-innovation & {
        border-color: #ff1963;
        color: #ff1963;
        &.active {
            background: #ff1963;
            color: #fff;
        }
    }
    .open-startups & {
        border-color: #b846e6;
        color: #b846e6;
        &.active {
            background: #b846e6;
            color: #fff;
        }
    }
`;
