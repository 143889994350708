import React from 'react';

import { useEffectOnce } from 'react-use';

import * as Page from 'components/page';
import * as Form from 'components/form';

import { useForm } from 'components/form';
import { useParams } from 'react-router';
import { RHP } from 'providers';
import * as Components from '../components';

export function SubscriptionSpeedDating(props) {
    const { eventId } = useParams();

    const {
        vars,
        getEventById,
        getChallengesFromEvent,
        getStartups,
        getUser,
        putEventProfile,
    } = props.useSubscription;

    const { challenges, startups, user, eventById } = vars;

    const form = useForm({
        ...vars.fields,
    });

    useEffectOnce(() => {
        getEventById(eventId);
        getChallengesFromEvent(eventId);
        getStartups();
        getUser();
    });

    const challengeOptions = challenges.map(challenge => ({
        name: challenge.name,
        value: String(challenge._id),
    }));

    const startupOptions = startups.map(startup => ({
        name: startup.name,
        value: String(startup._id),
    }));

    const fields = {
        interestInEvent: {
            error: form.errors.interestInEvent,
            label: props.t('SubscriptionSpeedDating.interestInEvent'),
            helper: props.t('SubscriptionSpeedDating.helperTextEvent'),
            name: 'interestInEvent',
            type: 'textarea',
            value: form.values.interestInEvent,
            onChange: form.handleChange,
            placeholder: props.t('SubscriptionSpeedDating.placeholder'),
        },
        challengeOwner: {
            error: form.errors.challengeOwner,
            label: props.t('SubscriptionSpeedDating.helperTextSpeedDating'),
            name: 'challengeOwner',
            options: challengeOptions,
            type: 'checkboxes',
            value: form.values.challengeOwner,
            onChange: form.handleChange,
        },
        startupOwner: {
            error: form.errors.startupOwner,
            label: props.t('SubscriptionSpeedDating.helperTextStartupOwner'),
            name: 'startupOwner',
            options: startupOptions,
            type: 'checkboxes',
            value: form.values.startupOwner,
            onChange: form.handleChange,
        },
    };

    const startupEntrepreuner =
        !challengeOptions.length && !startupOptions.length && user.role === 1;

    const scientistResearcher =
        !challengeOptions.length && !startupOptions.length && user.role === 9;

    const onSubmit = values => {
        const payload = {
            companyChallenges: values.challengeOwner?.map(e => Number(e)) || [],
            startups: values.startupOwner?.map(e => Number(e)) || [],
            interestInEvent: values.interestInEvent,
        };

        putEventProfile(payload, eventId).then(res => {
            let url = `/events/${eventId}/subscription/unlockschedule`;

            if (!eventById.timeslots.length) {
                url = `/events/${eventId}/subscription/success`;
            }

            RHP.push(url);
            return res;
        });
        return true;
    };

    return (
        <Page.Page name="SubscriptionSpeedDating">
            <Form.Form
                name="SubscriptionSpeedDatingForm"
                onSubmit={ev => form.handleSubmit(ev, onSubmit)}
            >
                <Page.Title>{props.t('Subscription.title')}</Page.Title>
                <Form.Field {...fields.interestInEvent} />

                {challengeOptions.length > 0 && <Form.Field {...fields.challengeOwner} />}
                {startupOptions.length > 0 && <Form.Field {...fields.startupOwner} />}

                {startupEntrepreuner && <Components.RegisterStartup />}
                {scientistResearcher && <Components.RegisterTech />}

                <Form.Submt {...form} text={props.t('Subscription.next')} />
            </Form.Form>
        </Page.Page>
    );
}
export default SubscriptionSpeedDating;
