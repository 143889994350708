import styled from 'styled-components';

export const FormRow = styled.div.attrs({
    className: 'form-row',
})`
    display: flex;
    margin: 0 0 0 -2rem;

    & > .component-form-field {
        flex-shrink: 0;
        flex-grow: 0;
        padding-left: 2rem;
    }
    & > .form-field {
        padding: 0.5rem 0 0 2rem;
    }
`;
