import React from 'react';

import { OiweekCalendar } from '../calendar/OiweekCalendar';

import { C2AClosed } from './C2AClosed';
import { C2AInvite } from './C2AInvite';
import { C2AOpened } from './C2AOpened';
import { C2AOngoing } from './C2AOngoing';

import * as Styled from './StyledOiweekCall2Action';

export function OiweekCall2Action(props) {
    const { vars } = props.useOiweek;
    const { oiweek } = vars;

    let Component = C2AInvite;
    if (
        oiweek.user &&
        (oiweek.user.eventStatus === 'registered' || oiweek.user.eventStatus === 'attended')
    ) {
        Component = C2AClosed;
    }
    if (oiweek.stages.app) {
        if (oiweek.stages.ongoing) Component = C2AOngoing;
        else Component = C2AOpened;
    }

    const C2AProps = {
        oiweek,
        color: oiweek.bgColor,
        event: oiweek._id,
        knowMoreUrl: oiweek.url,
        endDate: new Date(oiweek.endDate),
        startDate: new Date(oiweek.startDate)
    };
    return !oiweek ? null : (
        <Styled.OiweekCall2Action>
            <Component {...C2AProps} />
            <OiweekCalendar useOiweek={props.useOiweek} />
        </Styled.OiweekCall2Action>
    );
}
export default OiweekCall2Action;
