import React from 'react';

import { CG } from 'contexts';
import { LSP } from 'providers';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';

import { useConversionOptions } from 'hooks';
import { Container } from '../container';
import * as Styled from './StyledContributionForm';

export const ContributionForm = () => {
    const { state: global } = React.useContext(CG);
    const { t, modal, setModal, putMatchEvaluation } = useCXI();
    const { match } = modal;

    const options = useConversionOptions(['contributionTheme']);
    const form = useForm({
        theme:
            LSP.get(`${match.matchid}_CONTRIBUTION_FORM_THEME`) ??
            options.contributionTheme[0].value,
        question: LSP.get(`${match.matchid}_CONTRIBUTION_FORM_QUESTION`) ?? '',
        comment: LSP.get(`${match.matchid}_CONTRIBUTION_FORM_COMMENT`) ?? ''
    });

    const fields = {
        theme: {
            error: form.errors.theme,
            name: 'theme',
            type: 'select',
            label: t('modal.ContributionForm.theme'),
            required: true,
            value: form.values.theme,
            options: options.contributionTheme,
            onChange: ev => {
                LSP.set(`${match.matchid}_CONTRIBUTION_FORM_THEME`, ev.target.value);
                return form.handleChange(ev);
            }
        },
        question: {
            error: form.errors.question,
            name: 'question',
            type: 'generic',
            label: t('modal.ContributionForm.question'),
            required: true,
            value: form.values.question,
            onChange: ev => {
                LSP.set(`${match.matchid}_CONTRIBUTION_FORM_QUESTION`, ev.target.value);
                return form.handleChange(ev);
            }
        },
        comment: {
            error: form.errors.comment,
            name: 'comment',
            type: 'textarea',
            label: t('modal.ContributionForm.comment'),
            placeholder: t('modal.ContributionForm.commentPlaceholder'),
            required: true,
            value: form.values.comment,
            onChange: ev => {
                LSP.set(`${match.matchid}_CONTRIBUTION_FORM_COMMENT`, ev.target.value);
                return form.handleChange(ev);
            }
        }
    };

    const onSubmit = async values => {
        const { language } = global;
        try {
            const extraValues = {};
            // Adding the mentoringSessionId if available
            if (match.mentoringSessionId) {
                extraValues.mentoringSessionId = match.mentoringSessionId;
            }

            // Set the evaluation
            const commons = { language, isCxi: true, cxiStage: 3 };
            const EvaluationPayload = { ...commons, ...values, ...extraValues };
            await putMatchEvaluation(match.matchid, EvaluationPayload);

            LSP.del(`${match.matchid}_CONTRIBUTION_FORM_THEME`);
            LSP.del(`${match.matchid}_CONTRIBUTION_FORM_QUESTION`);
            LSP.del(`${match.matchid}_CONTRIBUTION_FORM_COMMENT`);

            setModal({ match, modal: 'ContributionSent' });
        } catch (e) {
            form.setSubmit(false);
        }
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    const isSubmitDisabled =
        form.submit || !form.values.theme || !form.values.question || !form.values.comment;

    return (
        <Styled.ContributionForm as={Container}>
            <Form.Form onSubmit={evSubmit}>
                <Container.Head image={match.startup.logoUrl}>
                    <span className="text">{t('modal.ContributionForm.title')}</span>
                </Container.Head>

                <Container.Body>
                    <Styled.Descr>
                        <span className="text">{t('modal.ContributionForm.description')}</span>
                    </Styled.Descr>
                    <Form.Field {...fields.theme} />
                    <Form.Field {...fields.question} />
                    <Form.Field {...fields.comment} />
                </Container.Body>

                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.ContributionForm.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.ContributionForm>
    );
};
