import React from 'react';

import * as Step from '../components/step';
import { InterestInStartups } from '../forms/InterestInStartup';
import { XpEvaluating } from '../forms/XpEvaluating';

export const CXIStep6 = () => (
    <Step.Step currentStep={6}>
        <InterestInStartups />
        <XpEvaluating />
    </Step.Step>
);
