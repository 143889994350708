import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const Opportunity = styled.div`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 6.2px 0px 6.2px 0px;
    box-shadow: 2px 2px 10px gray;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.4s ease;
    margin: auto;
    background: white;
    width: 270px;
    height: 100%;
    cursor: pointer;
    &:focus,
    &:hover {
        transform: scale(1.1);
    }
`;

export const OpportunityHead = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 7px;
    gap: 8px;
    color: white;
    padding: 11px 15px;
    background: linear-gradient(#0094f9 60%, white 60%) no-repeat;

    .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        min-width: 75px;
        padding: 5px;
        border-radius: 50%;
        background: #ffffff;
        border: 3px solid #dcd8d8;
    }
    img {
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        z-index: 10;
        height: auto;
        width: 50px;
        height: 100%;
    }
    .startupName {
        ${truncate(2, 1.4)}
    }
`;

export const OpportunityBody = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    padding: 11px 18px;
    height: 100%;

    .title {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.3;
        ${truncate(3, 1.3)}
    }

    .descr {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.4;
        margin-top: 0.8rem;
        max-height: 100% !important;
        ${truncate(4, 1.4)}
    }
`;

export const OpportunityFeet = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: auto;
    padding: 11px 18px;

    .badge {
        background: #0094f9;
        border-radius: 1.6rem;
        color: ${({ theme }) => theme.colors.white};
        display: inline-block;
        font-size: 1rem;
        line-height: 1.2rem;
        padding: 0.6rem 0.8rem;
        width: fit-content;
        max-width: 100%;
    }

    .state {
        align-items: center;
        color: ${({ theme }) => theme.colors.lightGray};
        display: flex;
        flex-direction: row;
        font-size: 1.2rem;
        line-height: 1.2rem;
        gap: 0.4rem;
        justify-content: flex-end;
        text-align: end;

        .icon {
            color: ${({ theme }) => theme.colors.energyBlue};
        }
    }
`;

export const Button = styled.button.attrs(props => ({
    className: `filter-tag ${props.active ? 'active' : ''}`,
}))`
    align-items: center;
    background: transparent;
    border: 1px solid #0094f9;
    border-radius: 0.4rem;
    color: #0094f9;
    display: flex;
    font-size: 1.3rem;
    font-weight: 600;
    gap: 0.6rem;
    line-height: 1.2;
    justify-content: center;
    padding: 1rem 1.6rem;
    width: 100%;
    margin-bottom: 10px;
    &.active {
        background: ${({ theme }) => theme.colors.energyBlue};
        color: ${({ theme }) => theme.colors.white};
        justify-content: space-between;
    }
`;

export const BadgesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;
