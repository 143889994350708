import React from 'react';

// import { useConversionTable } from 'hooks';
import { Area } from '../components/area/Area';
import { Tags } from '../components/tags/Tags';
import { useCXIStartup } from '../useCXIStartup';

import * as Styled from './StyledCXIStartupGeneral';

export const CXIStartupGeneralSectors = props => {
    const { profile } = props;
    const { t, vars } = useCXIStartup();
    const { startup } = vars;

    const tagsInCommonChallenge = startup.sector.challenges.map(challenge => ({
        challenge,
        isCommon: profile.sector.challenges.includes(challenge),
    }));
    const countOfCommonChallenge = startup.sector.challenges.filter(challenge =>
        profile.sector.challenges.includes(challenge)
    ).length;

    const tagsInCommonCommunities = startup.sector.communities.map(community => ({
        community,
        isCommon: profile.sector.communities.includes(community),
    }));
    const countOfCommonCommunities = startup.sector.communities.filter(community =>
        profile.sector.communities.includes(community)
    ).length;

    const tagsInCommonIndustries = startup.sector.industries.map(industry => ({
        industry,
        isCommon: profile.sector.industries.includes(industry),
    }));
    const countOfCommonIndustries = startup.sector.industries.filter(industry =>
        profile.sector.industries.includes(industry)
    ).length;

    const tagsInCommonTrends = startup.sector.trends.map(trend => ({
        trend,
        isCommon: profile.sector.trends.includes(trend),
    }));
    const countOfCommonTrends = startup.sector.trends.filter(trend =>
        profile.sector.trends.includes(trend)
    ).length;

    return (
        <Styled.CXIStartupGeneralSectors>
            {!!startup.sector?.challenges?.length && (
                <Area
                    className="challenge"
                    title={t('startup.sector.challenges')}
                    descr={`(${countOfCommonChallenge} em Comum)`}
                >
                    <Tags tags={tagsInCommonChallenge} />
                </Area>
            )}

            {!!startup.sector?.communities?.length && (
                <Area
                    className="community"
                    title={t('startup.sector.communities')}
                    descr={`(${countOfCommonCommunities} em Comum)`}
                >
                    <Tags tags={tagsInCommonCommunities} />
                </Area>
            )}

            {!!startup.sector?.industries?.length && (
                <Area
                    className="industry"
                    title={t('startup.sector.industries')}
                    descr={`(${countOfCommonIndustries} em Comum)`}
                >
                    <Tags tags={tagsInCommonIndustries} />
                </Area>
            )}

            {!!startup.sector?.trends?.length && (
                <Area
                    className="trend"
                    title={t('startup.sector.trends')}
                    descr={`(${countOfCommonTrends} em Comum)`}
                >
                    <Tags tags={tagsInCommonTrends} />
                </Area>
            )}
        </Styled.CXIStartupGeneralSectors>
    );
};
