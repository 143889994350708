import styled from 'styled-components';

export const FormCustomStartupItem = styled.a.attrs(props => {
    const disabled = props.disabled ? 'disabled' : '';
    return { className: `form-custom-startup-item ${disabled}` };
})`
    cursor: pointer;
    align-items: center;
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(68, 68, 68, 0.2);
    color: #444;
    display: flex;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 1rem 0;
    -webkit-appearance: none;

    &.startup .code {
        color: var(--os-startups);
    }
    &.tech .code {
        color: var(--os-tech);
    }

    .name {
        margin-left: 1rem;
        margin-right: auto;
        transform: translateY(0.1rem);
    }
    .levelStatusWrapper {
        display: flex;
        margin: auto 1rem;
        gap: 10px;
    }

    .code {
        display: flex;
        align-items: center;
        color: var(--primary);
        font-size: 1.2rem;
        font-weight: 700;
        margin-right: 1rem;
        text-transform: uppercase;
        margin-top: 1px;
        /* transform: translateY(-1px); */
    }
`;

export const ItemImage = styled.div.attrs({
    className: 'item-image',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url(${props => props.img});
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 3rem;
    flex: 0 0 3rem;
    height: 3rem;
    padding: 0.25rem;
`;
