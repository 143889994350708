import React from 'react';
import { withTranslation } from 'react-i18next';

import { AppTemplate } from 'app/templates';
import * as Styled from 'app/templates/StyledAppTemplate';

class EB extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: '', hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Atualiza o state para que a próxima renderização mostre a UI alternativa.
        return { error, hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Você também pode registrar o erro em um serviço de relatórios de erro
    }

    render() {
        if (this.state.hasError) {
            // Você pode renderizar qualquer UI alternativa
            return (
                <AppTemplate name="ErrorBoundary">
                    <Styled.Icons>
                        <span className="icon fad fa-exclamation-triangle" aria-hidden="true" />
                    </Styled.Icons>

                    <Styled.Title>{this.props.t('AppTemplateErrorBoundary.title')}</Styled.Title>
                    <Styled.Descr>{this.props.t('AppTemplateErrorBoundary.descr')}</Styled.Descr>
                    <Styled.Descr>{this.state.error}</Styled.Descr>

                    <Styled.Buttn as="a" href="/">
                        <span className="text">
                            {this.props.t('AppTemplateErrorBoundary.button')}
                        </span>
                        <span className="icon far fa-sync" aria-hidden="true" />
                    </Styled.Buttn>
                </AppTemplate>
            );
        }

        return this.props.children;
    }
}

export const ErrorBoundary = withTranslation('app')(EB);
export default ErrorBoundary;
