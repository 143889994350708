import React from 'react';

import { PageSubtitle } from 'components/page';
import * as Styled from './StyledListSubtitle';

export const ListSubtitle = props => {
    return <Styled.ListSubtitle as={PageSubtitle} {...props} />;
};
export const Subtitle = ListSubtitle;
export default ListSubtitle;
