import React from 'react';

import logo100os from 'assets/images/logo-100os.svg';

import { MenuLang } from 'core/menu/components';

import * as List from 'components/list';
import * as Page from 'components/page';

import { EventCode } from '../components/event-code';

import * as Styled from './StyledLoginIndex';

export const LoginIndexView = props => (
    <Styled.LoginIndex as={Page.Page} name="LoginIndex" back={false}>
        <MenuLang />

        <Page.Image className="rippled" width="10rem">
            <img className="logo" src={logo100os} alt="100 Open Startups" />
        </Page.Image>

        <Page.Title>{props.t('LoginIndex.title')}.</Page.Title>
        <Page.Descr>{props.t('LoginIndex.descr')}</Page.Descr>

        <EventCode useLogin={props.useLogin} />

        <Page.Buttn type="light" to="/login/email">
            <span className="icon far fa-envelope" aria-hidden="true" />
            <span className="text">{props.t('LoginIndex.enterWithEmail')}</span>
            <span className="icon fas fa-long-arrow-right" aria-hidden="true" />
        </Page.Buttn>

        <List.List name="LoginIndexList">
            <List.Vertical items={props.actions} itemKey="href" />
        </List.List>

        {/* Add the elements to social login */}
    </Styled.LoginIndex>
);
export default LoginIndexView;
