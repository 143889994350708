import styled from 'styled-components';

export const CXIDashboard = styled.div``;

export const CXIDashboardHeader = styled.div.attrs({
    className: 'container'
})`
    min-height: 100%;
    padding: 1.6rem 1.6rem 0;
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem 3.2rem 0;
    }
`;
export const CompanyLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 65px;
    height: auto;
    max-height: 50px;
    margin: 0 20px 0 10px;

    svg {
        width: 100%;
    }

    img {
        width: 100%;
    }
`;
export const CXIDashboardRoutes = styled.div``;

export const Logo = styled.div.attrs({
    className: 'dashboard-company-logo',
    role: 'img'
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 50%;
    flex: 0 0 4.8rem;
    height: 4.8rem;
    padding: 0.2rem;
    position: relative;
    width: 4.8rem;
`;

export const Text = styled.div.attrs({
    className: 'dashboard-company-text'
})`
    color: ${({ theme }) => theme.colors.deepBlue};
    font-size: 3.2rem;
    font-weight: 700;
`;
