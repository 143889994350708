import React from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';

export function RegisterCompanyValidateForm(props) {
    const { form, onSubmit } = props;

    // Create form fields object
    const fields = {
        companyEmail: {
            error: form.errors.email,
            label: props.t('glossary:companyEmail'),
            name: 'email',
            helper: props.t('glossary:companyEmailHelper'),
            type: 'text',
            value: form.values.email,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="RegisterCompanyValidateForm" onSubmit={evSubmit}>
            <Page.Ruler />

            <Form.Field {...fields.companyEmail} />
            <Form.Field {...fields.noEmail} />
            <Form.Submt {...form} text={props.t('glossary:continue')} />
        </Form.Form>
    );
}
export default RegisterCompanyValidateForm;
