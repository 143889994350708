import React from 'react';

import * as Form from 'components/form';

export function EcosystemStep1Form(props) {
    const { form, onSubmit } = props;

    // Create form fields object
    const fields = {
        applied: {
            error: form.errors.applied,
            label: props.t('glossary:appliedEcosystem'),
            name: 'applied',
            type: 'checkbox',
            value: form.values.applied,
            onChange: form.handleChange,
            required: false,
        },
        isUpToDate: {
            error: form.errors.isUpToDate,
            label: props.t('glossary:profileDataIsUpToDate'),
            name: 'isUpToDate',
            type: 'checkbox',
            value: form.values.isUpToDate,
            onChange: form.handleChange,
        },
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="EcosystemStep1Form" onSubmit={evSubmit}>
            <Form.Field {...fields.isUpToDate} />
            <Form.Field {...fields.applied} />

            <Form.Submt {...form} text={props.t('glossary:save')} disabled={props.disabled} />
        </Form.Form>
    );
}
export default EcosystemStep1Form;
