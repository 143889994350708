import React from 'react';
import * as Styled from './StyledListScrolls';

export function ListScrollsItem(props) {
    const data = props.data || props.item || undefined;
    const Component = props.itemComponent || (() => <div>itemComponentUndefined</div>);
    return (
        <Styled.ListScrollsItem>
            <Component data={data} />
        </Styled.ListScrollsItem>
    );
}
export const ScrollsItem = ListScrollsItem;
export default ListScrollsItem;
