import React from 'react';

import { useConversionTable } from 'hooks';

import * as Styled from './StyledCompanyCard';

export function CompanyCard(props) {
    const { data } = props;
    const conv = useConversionTable(['companyType', 'companySize']);
    const info = {};
    if (data) {
        if (data?.logo || data?.logourl || data?.logoUrl) {
            info.image = {
                img: encodeURI(data.logo || data.logourl || data.logoUrl),
                alt: data.name
            };
        }
        info.title = data.name;
        info.size = conv.companySize[data?.size];
        info.type = conv.companyType[data?.type];
    }

    return !data ? null : (
        <Styled.CompanyCard>
            <Styled.CardImage {...info?.image} />
            <Styled.CardInfos>
                <span className="name">{info?.title}</span>
                <span className="text">{info?.size}</span>
                {info?.type && <span className="text">{info?.type}</span>}
                <Styled.BadgesContainer>
                    {data?.domains?.map(domain => {
                        return <Styled.DomainBadge key={domain}>{domain}</Styled.DomainBadge>;
                    })}
                </Styled.BadgesContainer>
            </Styled.CardInfos>
        </Styled.CompanyCard>
    );
}
export default CompanyCard;
