import React from 'react';

import { useCXISetup } from '../../useCXISetup';
import * as Styled from './StyledProgress';

export const Progress = ({ currentStep }) => {
    const { vars } = useCXISetup();
    const { totalSteps } = vars;

    const getClassName = step => {
        if (step === currentStep) return 'current';
        if (step < currentStep) return 'previous';
        return 'default';
    };

    return (
        <Styled.Progress aria-label="">
            {[...Array(totalSteps).keys()]
                .map(x => x + 1)
                .map(step => (
                    <div className={getClassName(step)} role="presentation" key={step} />
                ))}
        </Styled.Progress>
    );
};
