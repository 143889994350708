import React from 'react';

import { useForm } from 'components/form';
import { ContextAuth as CA } from 'contexts';
import { useConversionTable } from 'hooks';
import { RHP } from 'providers';

import * as Page from 'components/page';
import * as Table from 'components/table';

import { CorporateStep1Form } from './form-step-1';

export function CorporateStep1({ useRankingCorporate, t }) {
    const { state } = React.useContext(CA);

    const { vars, putUserRankingApplication } = useRankingCorporate;

    const { application } = vars;

    const conv = useConversionTable(['ecosystemRole']);

    // Initialize a new form handler
    const form = useForm({
        isUpToDate: !!application.appliedAt
    });

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            ...application,
            ...values
        };

        // Set the current step to true;
        if (!payload.steps) {
            payload.steps = {};
        }

        payload.steps.step1 = true;

        return putUserRankingApplication(payload)
            .then(() => RHP.push('/ranking/latam/corporate/step2'))
            .catch(() => form.submited());
    };

    // Properties for the table
    const tableAbout = {
        href: '/profile/edit',
        subtitle: {
            text: t('CorporateStep1.aboutYou'),
            link: t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                fullName: state.user.fullName,
                email: state.user.email,
                personalEmail: state.user.personalEmail,
                phone: state.user.phone,
                gender: state.user.gender,
                ecosystemRole: state.user.role
            },
            name: 'aboutYou',
            dataConv: { ...conv, role: conv.ecosystemRole }
        }
    };

    const tableLocation = {
        href: '/profile/location',
        subtitle: {
            text: t('CorporateStep1.aboutLocation'),
            link: t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                location: state.user.locationString
            },
            name: 'aboutLocation'
        }
    };

    const tableWork = {
        href: '/profile/company',
        subtitle: {
            text: t('CorporateStep1.aboutWork'),
            link: t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                companyName: state.user.company.name,
                position: state.user.position,
                department: state.user.department
            },
            name: 'aboutYou',
            dataConv: { ...conv, role: conv.ecosystemRole }
        }
    };

    const propsForm = { t, form, onSubmit };

    return (
        <Page.Page
            name="CorporateStep1"
            back="/ranking/latam/corporate"
            customBackText={t('glossary:backToRanking')}
        >
            <Page.Title>{t('CorporateStep1.title')}</Page.Title>
            <Page.Descr>{t('CorporateStep1.descr')}</Page.Descr>

            <Table.Table {...tableAbout} />
            <Table.Table {...tableLocation} />
            <Table.Table {...tableWork} />

            <Page.Ruler />

            <CorporateStep1Form {...propsForm} />
        </Page.Page>
    );
}
