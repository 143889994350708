import React from 'react';
import { useClickAway } from 'react-use';

import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledAbout100';

export const About100 = () => {
    const { setModal } = useCXI();
    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
        setModal(null);
    });

    return (
        <Styled.About100 ref={containerRef}>
            <Styled.Close onClick={() => setModal(null)}>
                <span className="icon far fa-times" aria-hidden="true" />
            </Styled.Close>
            <Container.Head>
                <span className="text">Etapa de Avaliações online</span>
            </Container.Head>

            <Container.Body>
                <p>
                    Agora que você configurou o seu perfil, passará a receber sugestões de startups
                    sempre que clicar no menu CONHECER STARTUPS. Ah, sim, você pode sempre que
                    quiser atualizar as preferências que você indicou ;)
                </p>
                <p>
                    Uma de suas metas nessa etapa é CONHECER 100 STARTUPS. As startups são sugeridas
                    em cards resumidos para que você confirme seu interesse em avaliá-las. Ao
                    confirmar, abra a proposta completa e avalie a startup, deixando o mais
                    importante neste momento, o seu FEEDBACK. Todas startups que você conheceu e
                    avaliou ficam organizadas no seu PAINEL.
                </p>
                <p>
                    Seus comentários são apresentados para as startups que irão classificá-los.
                    Desta forma você contribui com as startups enquanto vai acumulando pontos até
                    conquistar 10 FEEDBACKS MUITO RELEVANTES e poder passar para a próxima etapa.
                </p>
            </Container.Body>
        </Styled.About100>
    );
};
