import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Buttn = styled.button.attrs(props => ({
    as: props.to ? Link : undefined,
    className: `c-page-buttn btn btn-${props.type || 'primary'}`,
    role: 'button'
}))``;
export const Button = Buttn;

export const Cntnt = styled.div.attrs({
    className: 'c-page-cntnt'
})`
    font-size: 1.4rem;
    line-height: 1.4;
    margin: 0 -1.5rem;
    padding: 3rem 3rem 0;

    p {
        margin: 0;
    }
    p + p {
        margin-top: 1.4em;
    }

    & ~ .c-page-buttn {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        width: auto;
    }
    & ~ .c-page-links-with-text {
        padding: 1rem 1.5rem 0;
        & + .c-page-links-with-text {
            margin-top: 1rem;
        }
    }
`;
export const Content = Cntnt;

export const LinksWithText = styled.div.attrs(props => ({
    as: props.to ? Link : undefined,
    className: 'c-page-links-with-text'
}))`
    color: #444;
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    line-height: 1.4;
    margin: 2rem 0 0;
    text-decoration: none;

    .link {
        color: var(--primary);
        display: block;
        text-decoration: underline;
    }
`;
export const LWTXT = LinksWithText;
