import React from 'react';
import { useEffectOnce } from 'react-use';

import { RHP } from 'providers';
import * as Alert from 'components/alert';
import * as List from 'components/list';
import * as Modal from 'components/modal';
import * as Page from 'components/page';
import * as Tabs from 'components/tabs';

import { Investment, InvestmentCard } from '../components';

export function InvestorStep3(props) {
    const { vars, updateSteps } = props.useRankingInvestor;
    const { steps } = vars.application;
    const [modalData, setModalData] = React.useState(false);

    const investments = vars.investments.map(i => {
        const item = { ...i };
        item.onClick = () => setModalData(i);
        return item;
    });

    // Update the step has completed if investments exist
    useEffectOnce(() => {
        if (vars.investments && vars.investments.length > 0 && steps.step2) {
            updateSteps({ step3: true });
        }
    });

    // Check for previous step conclusion
    if (!steps || steps.step2 !== true) {
        RHP.replace('/ranking/latam/investor/');
        return null;
    }

    const propsAlert = {
        title: props.t('InvestorStep3.noInvestment'),
        descr: props.t('InvestorStep3.noInvestmentDescr'),
        icons: 'far fa-exclamation-circle',
        buttn: {
            href: '/ranking/latam/investor/step3/add',
            text: props.t('InvestorStep3.addInvestment'),
        },
        type: 'warning',
    };

    const pending = investments
        .filter(i => i.status === 'pending')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const rejected = investments
        .filter(i => i.status === 'rejected')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const confirmed = investments
        .filter(i => i.status === 'confirmed')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    return (
        <Page.Page name="InvestorStep3" back="/ranking/latam/investor" customBackText={props.t("glossary:backToRanking")}>
            <Page.Title>{props.t('InvestorStep3.title')}</Page.Title>
            <Page.Descr>{props.t('InvestorStep3.descr')}</Page.Descr>

            {investments
                .filter(i => i.investor.status === 'pending')
                .slice(0, 1)
                .map(i => {
                    const item = { ...i };
                    item.onClick = undefined;
                    return (
                        <Investment
                            key={`pending-${i._id}`}
                            data={item}
                            useRankingInvestor={props.useRankingInvestor}
                        />
                    );
                })}
            {!vars.investments.length && <Alert.Alert {...propsAlert} />}

            <Page.Buttn to="/ranking/latam/investor/step3/add" type="linkd btn-block">
                <span className="icon far fa-plus-circle" aria-hidden="true" />
                <span className="text">{props.t('InvestorStep3.addInvestment')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Tabs.TabsContainer>
                <Tabs.TabsButtons>
                    <Tabs.Tab name="IS3C">
                        {props.t('glossary:confirmedBy.startup')}
                        {confirmed.length > 0 && <span>({confirmed.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="IS3P">
                        {props.t('glossary:pendingConfirmation')}
                        {pending.length > 0 && <span>({pending.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="IS3R">
                        {props.t('glossary:rejectedBy.startup')}
                        {rejected.length > 0 && <span>({rejected.length})</span>}
                    </Tabs.Tab>
                </Tabs.TabsButtons>

                <List.List name="IS3C">
                    <List.Vertical items={confirmed} itemKey="_id" itemComponent={InvestmentCard} />
                </List.List>
                <List.List name="IS3P">
                    <List.Vertical items={pending} itemKey="_id" itemComponent={InvestmentCard} />
                </List.List>
                <List.List name="IS3R">
                    <List.Vertical items={rejected} itemKey="_id" itemComponent={InvestmentCard} />
                </List.List>
            </Tabs.TabsContainer>

            <Page.Buttn to="/ranking/latam/investor">
                <span className="text">{props.t('glossary:continue')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Modal.Modal
                close={() => setModalData(false)}
                title={props.t('InvestorStep3.investment')}
                isOpen={!!modalData}
            >
                {modalData && (
                    <Investment
                        closeModal={() => setModalData(false)}
                        data={modalData}
                        style={{ marginTop: '1rem' }}
                        useRankingInvestor={props.useRankingInvestor}
                    />
                )}
            </Modal.Modal>
        </Page.Page>
    );
}
export default InvestorStep3;
