import React from 'react';
import * as Styled from './StyledFormSubmit';

const FormSubmitHolder = props => (
    <Styled.FormSubmitHolder>
        <Styled.FormSubmit {...props}>{props.children}</Styled.FormSubmit>
    </Styled.FormSubmitHolder>
);

export function FormSubmit(props) {
    // Get the icon and set a default on
    let icon = props.icon || `fa-long-arrow-right`;
    let disabled = false;

    // Handles the icon and show loading animation
    if (props.submit) {
        icon = `fa-spin fa-circle-notch`;
        disabled = true;
    }

    // Shows the error icon in the button
    if (props.errors && Object.keys(props.errors).length > 0) {
        icon = `fa-times`;
        disabled = true;
    }

    const propsSubmit = {
        disabled: props.disabled || disabled,
        type: props.type || 'submit',
    };
    return (
        <FormSubmitHolder {...propsSubmit}>
            <span className="text">{props.text}</span>
            <span className={`icon fa-fw far ${icon}`} aria-hidden="true" />
        </FormSubmitHolder>
    );
}
export const Submt = FormSubmit;
export const Submit = FormSubmit;
export default FormSubmit;
