import styled from 'styled-components';

export const ListVertical = styled.ul.attrs({
    className: 'list-vertical'
})`
    background: transparent;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    ion-item {
        --background: transparent;
        --border-color: transparent;
        --border-width: 0;
        --color: inherit;
        --min-height: 0;
    }
    ion-item > * {
        min-width: 100%;
    }
`;

export const ListVerticalItem = styled.li.attrs({
    className: 'list-vertical-item'
})``;

export const ListVerticalItemLink = styled.a.attrs(props => {
    const disabled = props.disabled ? 'disabled' : '';
    return { className: `list-vertical-item-link ${disabled}` };
})`
    align-items: center;
    background: transparent;
    border: 0;
    border-bottom: 1px solid var(--primary);
    color: var(--primary);
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    font-weight: 500;
    min-height: 4.4rem;
    padding: 0;
    -webkit-appearance: none;

    .icon {
        transform: translateY(1.5px);
    }
    .text {
        margin-right: auto;
        transform: translateY(0.1rem);
    }
    .text + .icon,
    .icon + .text {
        margin-left: 1rem;
    }

    &.disabled {
        color: #ddd;
        .arrw,
        .icon,
        .text {
            color: #ddd !important;
        }
    }
`;
