import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const Menu = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 3.2rem 2.4rem;
`;

export const MenuAvatar = styled.button`
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    display: inline-block;
    padding: 0;
    position: relative;
    width: 7rem;

    .startup-image-holder {
        width: 100%;
    }
`;

export const MenuItems = styled.nav`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-top: 1.6rem;
    width: 100%;
`;
export const MenuItem = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    border-radius: 0.8rem;
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    gap: 1.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1.2rem 1.6rem;
    position: relative;
    text-align: left;

    &:focus,
    &:hover {
        background: ${({ theme }) => theme.colors.lighterGray};
        color: ${({ theme }) => theme.colors.deepBlue};
    }
    &.is-active {
        background: ${({ theme }) => theme.colors.lighterGray};
    }
    &.is-highlight {
        background: ${({ theme }) => theme.colors.deepBlue};
        border: 2px solid ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
        margin-top: 3.2rem;
        width: 100%;

        &:focus,
        &:hover,
        &.is-active {
            background: transparent;
            color: ${({ theme }) => theme.colors.deepBlue};
        }
        .icon {
            transform: translateY(1px);
        }
    }

    .badge {
        align-items: center;
        background: ${({ theme }) => theme.colors.red};
        border-radius: 9999px;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        font-size: 1rem;
        justify-content: center;
        line-height: 1;
        margin: -0.8rem 0 0 1.2rem;
        min-width: 1.65rem;
        padding: 0.4rem 0.6rem 0.25rem;
        position: absolute;
        ${positions('50%', 'auto', 'auto', '1.6rem')}
        transform: translateY(-50%);
    }
    .icon {
        font-size: 2rem;
    }
`;

export const Name = styled.div`
    color: ${({ theme }) => theme.colors.deepBlue};
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 2rem;
    text-align: center;
    ${truncate(2, 1.2)}
`;

export const Desc = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 0.8rem;
`;

export const Update = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    gap: 0.6rem;
    margin-top: 1.6rem;
    padding: 0;

    .icon {
        font-size: 0.9rem;
    }

    &[disabled] {
        opacity: 0.5;
    }
`;
