export function queryParam(nameParam, urlParam) {
    const name = nameParam.replace(/[[\]]/g, '\\$&');
    const url = urlParam || window.location.href;

    // Regex to find some query parameters
    const rgx = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const res = rgx.exec(url);

    if (nameParam === 'redirectUrl' && (!res || (res && !res[2]))) {
        return queryParam('returnUrl');
    }

    // Check the results from the regex filter
    if (!res) return null;
    if (!res[2]) return '';
    // Return the proper parameter
    return decodeURIComponent(res[2]);
}

export const queryParamsToObject = query => {
    return Object.fromEntries(new URLSearchParams(query));
    // // Check if query is empty, return nothing
    // if (!query) {
    //     return {};
    // }
    // const newParams = [];
    // return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
    //     const [k, v] = param.split('=');
    //     newParams[k] = v ? decodeURIComponent(v) : '';
    //     return newParams;
    // }, {});
};
