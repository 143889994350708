import React, { useEffect, useState } from 'react';
import { RHP } from 'providers';
import { Button } from 'modules/cxi/components/button';
import * as Page from 'components/page';
import { useTranslation } from 'react-i18next';
import * as Styled from './StyledGrowUpContacts';
import { ContactCard } from './components/ContactCard';
import CSVButton from './components/CSVButton/CSVButton';
import { GrowUpContactsModal } from './components/modal/GrowUpContactsModal';

export const GrowUpContacts = props => {
    const { t } = useTranslation('startup');

    const { vars, getContacts } = props.useStartupId;
    const { startup } = vars;
    const [currentPage, setCurrentPage] = useState(1);
    const [displayedContacts, setDisplayedContacts] = useState([]);
    const pageSize = 5;
    const [modal, setModal] = useState(false);

    useEffect(() => {
        async function getContactsData() {
            let offSet;
            if (currentPage === 1) {
                offSet = 0;
            } else {
                offSet = pageSize * (currentPage - 1);
            }
            const contacts = await getContacts(pageSize, offSet);
            setDisplayedContacts(contacts);
        }

        getContactsData();
    }, [currentPage]);

    function handleButtonClick() {
        RHP.push(`/registercontact/${startup.startupSecret}`);
    }

    // pagination
    const handlePreviousPageClick = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleNextPageClick = () => {
        if (displayedContacts && displayedContacts?.length > pageSize)
            setCurrentPage(currentPage + 1);
    };
    return (
        <Page.Page>
            <Styled.Main>
                <Styled.Startup>
                    <img src={startup?.logoUrl} alt={`logo da ${startup?.name}`} />
                    <span>{startup?.name}</span>
                </Styled.Startup>

                <Page.Title>{t('GrowUpContacts.title')}</Page.Title>
                <Styled.Buttons>
                    <Styled.AddAndShare>
                        <Button onClick={handleButtonClick} className="add">
                            {t('GrowUpContacts.addButton')}
                        </Button>
                        <Button onClick={() => setModal(true)} className="share">
                            <i className="fas fa-share" />
                        </Button>
                        {modal && <GrowUpContactsModal {...props} setModal={setModal} />}
                    </Styled.AddAndShare>
                    <CSVButton startupId={startup.startupid} className="csv" t={t} />
                </Styled.Buttons>
            </Styled.Main>

            <hr />
            {displayedContacts?.length === 0 && currentPage === 1 && (
                <Styled.NoContacts>
                    {t('GrowUpContacts.noContracts')} <br />
                </Styled.NoContacts>
            )}

            {displayedContacts?.length > 0 && (
                <Styled.ContactsSection>
                    <Styled.Bg />
                    {displayedContacts &&
                        displayedContacts?.length > 0 &&
                        displayedContacts?.map(contact => {
                            return <ContactCard contact={contact} key={contact._id} t={t} />;
                        })}
                </Styled.ContactsSection>
            )}

            <Styled.ContactsPagination>
                {currentPage !== 1 && (
                    <i className="fas fa-less-than" onClick={handlePreviousPageClick} />
                )}
                <b>{currentPage}</b>
                {displayedContacts?.length === pageSize && (
                    <i className="fas fa-greater-than" onClick={handleNextPageClick} />
                )}
            </Styled.ContactsPagination>

            <hr />
        </Page.Page>
    );
};

export default GrowUpContacts;
