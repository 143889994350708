import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { ProfileCompanyCreateForm } from './ProfileCompanyCreateForm';

export function ProfileCompanyCreate(props) {
    const conv = useConversionOptions(['companySize']);
    const { emitToast } = React.useContext(CG);
    const { postCompany } = props.useProfile;

    // Initialize a new form handler
    const form = useForm();

    // Handle form submition
    const onSubmit = values => {
        const payload = values;
        // Do the submition api request
        return postCompany(payload)
            .then(() => {
                emitToast(props.t('ProfileCompanyCreate.saveSuccess'));
                RHP.push('/profile/company');
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="ProfileCompanyCreate" back="/profile/company/select">
            <Page.Title>{props.t('ProfileCompanyCreate.title')}</Page.Title>
            <Page.Descr>{props.t('ProfileCompanyCreate.descr')}</Page.Descr>

            <ProfileCompanyCreateForm {...propsForm} />
        </Page.Page>
    );
}
export default ProfileCompanyCreate;
