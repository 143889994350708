import React from 'react';
import logo from 'assets/images/logo-100-10-1.png';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';
import { RHP } from 'providers';

import { useCXISetup } from '../../useCXISetup';
import { Progress } from '../progress';
import * as Styled from './StyledStep';

export const Step = ({ currentStep, children }) => {
    const { putProfile, t, vars: cxiVars } = useCXI();
    const { profile } = cxiVars;

    const { vars: stpVars } = useCXISetup();
    const { isEditing, totalSteps } = stpVars;

    const handleBack = () => {
        const prev = currentStep - 1;
        RHP.push(`/100-10-1/setup/${prev}`);
    };
    const handleOver = () => RHP.push(`/100-10-1/profile`);

    const handleSave = async () => {
        await putProfile(profile.profile);
        RHP.push(`/100-10-1/profile`);
    };
    const handleNext = async () => {
        await putProfile(profile.profile);
        const next = currentStep + 1;
        if (next > totalSteps) {
            RHP.push(`/100-10-1/setup/completed`);
        } else {
            RHP.push(`/100-10-1/setup/${next}`);
        }
    };

    return (
        <Styled.Step>
            <Styled.StepHead>
                <img src={logo} alt="Logo 100-10-1" />
            </Styled.StepHead>

            <Styled.StepBody>
                <Progress currentStep={currentStep} />
                {children}
            </Styled.StepBody>

            <Styled.StepFeet>
                {currentStep > 1 && (
                    <Button variant="outline" onClick={handleBack}>
                        {t('setup.back')}
                    </Button>
                )}

                {isEditing && (
                    <Button variant="outline" onClick={handleOver}>
                        {t('setup.cancel')}
                    </Button>
                )}

                <div className="steps-of">
                    {currentStep} / {totalSteps}
                </div>
                <div className="steps-spacer" />

                {isEditing && <Button onClick={handleSave}>{t('setup.save')}</Button>}

                <Button type="submit" onClick={handleNext}>
                    {t('setup.advance')}
                </Button>
            </Styled.StepFeet>
        </Styled.Step>
    );
};
