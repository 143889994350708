import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Buttn = styled.button.attrs(props => ({
    as: props.to ? Link : undefined,
    className: `c-alert-buttn btn btn-round btn-sm btn-${props.type || 'primary'}`,
    type: 'button',
}))`
    margin: 1.5rem 0.5rem 0;
    text-align: center;
`;
export const Button = Buttn;

export const Descr = styled.div.attrs({
    className: 'c-alert-descr',
})`
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;

    .c-alert-image + &,
    .c-alert-title + & {
        margin-top: 1rem;
    }
`;

export const Icons = styled.div.attrs({
    className: 'c-alert-icons',
})`
    align-items: center;
    display: flex;
    justify-content: center;
    // margin-top: -3.5rem;

    .icon {
        background: #fff;
        border-radius: 2rem;
        font-size: 4rem;
        line-height: 4rem;
        margin: 0 0.5rem;
        width: 4rem;
    }
`;

export const Image = styled.figure.attrs({
    className: 'c-alert-image',
})`
    align-items: center;
    display: flex;
    justify-content: center;
    /* margin-top: -3.5rem; */

    img {
        max-height: 6rem;
    }
`;

export const Title = styled.div.attrs({
    className: 'c-alert-title',
})`
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0;

    .c-alert-icons + &,
    .c-alert-image + & {
        margin-top: 1rem;
    }
`;

export const AlertBox = styled.div.attrs({
    className: 'c-alert-alertbox',
})`
    display: flex;
    gap: 2rem;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0;
    flex-direction: ${props => props.flexDirection || 'row'};

    .c-alert-icons + &,
    .c-alert-image + & {
        margin-top: 1rem;
    }
`;
