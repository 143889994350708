import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContextGlobal as CG } from 'contexts';
import { Menu } from 'core/menu';

import * as Styled from './StyledRoutesMenu';

const StyledsRoutesMenuWrapper = props => (
    <Styled.RoutesMenuWrapper>
        <Menu close={props.onClick} />
    </Styled.RoutesMenuWrapper>
);

export function RoutesMenu(props) {
    const { t } = useTranslation('app');
    const { state, dispatch } = React.useContext(CG);
    const { menuOpen, menuShow } = state;

    // Handle the states of the menu
    const [visible, setVisible] = React.useState(false);

    function toggleOpen(value) {
        dispatch({ type: 'MENU_OPEN', menu: value });
    }

    // Use effect to emit timeouts on open/close of the menu
    // This is made for the visibility changes
    // Clear the timeout when the component unmounts
    React.useEffect(() => {
        const timeout = setTimeout(() => setVisible(menuOpen), 0);
        return () => clearTimeout(timeout);
    }, [menuOpen]);

    const i = menuOpen ? 'fa-arrow-down' : 'fa-bars';
    const p = { open: menuOpen, visible };

    return menuShow === false ? null : (
        <Styled.RoutesMenu {...p}>
            <Styled.RoutesMenuEllipse />
            <StyledsRoutesMenuWrapper />
            <Styled.RoutesMenuToggler onClick={() => toggleOpen(!menuOpen)}>
                <span className={`icon far fa-fw ${i}`} aria-hidden="true" />
                <span className="text sr-only">{t('toggleMenu')}</span>
            </Styled.RoutesMenuToggler>
        </Styled.RoutesMenu>
    );
}
export default RoutesMenu;
