import { useContext } from 'react';
import { CXIStartupContext } from './CXIStartupContext';

export const useCXIStartup = () => {
    const context = useContext(CXIStartupContext);
    if (context === undefined) {
        throw new Error('useCXIStartup must be used within a CXIStartupContextProvider');
    }
    return context;
};
