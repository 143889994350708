import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Container } from '../container';
import * as Styled from './StyledChallengeMatchSuccess';

export const ChallengeMatchSuccess = props => {
    const { t } = useCXI();

    return (
        <Styled.ChallengeChat as={Container.Slim}>
            <Container.Head />
            <Container.Body>{t('challenge.matchSuccess')}</Container.Body>
        </Styled.ChallengeChat>
    );
};
