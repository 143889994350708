import React from 'react';
import { useEffectOnce } from 'react-use';

import { useForm } from 'components/form';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { RankingTermForm } from './RankingTermForm';
import * as Styled from './StyledRankingTerm';

export function RankingTerm(props) {
    const { vars, getTerm, postUserTerm, putUser } = props.useRanking;

    // Initialize a new form handler
    const form = useForm({
        termId: vars.userTerm.termId || '',
    });

    // Term should be loaded to proceed
    useEffectOnce(() => {
        if (vars.userTerm.termId && vars.userTerm.signed === true) {
            RHP.replace(vars.redirectTo || '/ranking/');
            return;
        }
        // Get the term to be signed
        if (vars.userTerm.termId && !vars.term._id) {
            getTerm(vars.userTerm.termId);
        }
    });

    // Handle form submition
    const onSubmit = values => {
        const { termId, acceptPrivacyTerms } = values;

        if (acceptPrivacyTerms) {
            putUser({ acceptPrivacyTerms });
        }
        // Do the submition api request
        return postUserTerm({ termId, signed: true })
            .then(() => RHP.push(vars.redirectTo || '/ranking/'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Styled.RankingTerm as={Page.Page} name="RankingTerm" loading={!vars.term._id}>
            <Page.Title>{props.t('RankingTerm.title')}</Page.Title>
            <Page.Descr>{props.t('RankingTerm.descr')}</Page.Descr>
            {vars.term._id && <Page.BoxedHTML html={vars.term.htmlDescription} />}
            {vars.term._id && <RankingTermForm {...propsForm} />}
        </Styled.RankingTerm>
    );
}
export default RankingTerm;
