import React from 'react';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

import { useApiLocation } from 'hooks';
import { Disclaimer } from 'components/page';

import { FormAutocomplete } from '../autocomplete/FormAutocomplete';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {},
};

function locationValue(value) {
    const city = value.city?.name;
    const country = value.country?.name;
    const state = value.state?.id.replace(`${value.country.id}-`, '');
    return `${city}, ${state} - ${country}`;
}

export function FormCustomLocation(props) {
    const { t } = useTranslation('glossary');
    const api = useApiLocation();

    // Manage field information states
    const [items, setItems] = React.useState([]);
    const [value, setValue] = React.useState(props.value);

    // Handles the click on an item of the autocomplete
    function handleClick(val) {
        setValue(val);
        // Create a fake event to pass trough
        const event = { ...fakeEvent, target: { name: props.name, value: val } };
        return props.onChange(event);
    }

    function handleItems(item) {
        return {
            id: item._id,
            full: item,
            // Default item link fields
            text: locationValue(item),
            arrw: 'far fa-dot-circle',
            onClick: handleClick,
        };
    }

    // Handles the call to the api for the results
    function itemsFetch(val) {
        api.getByName(val).then(res => {
            // console.log('FormCustomLocation', 'itemsFetch', res);
            setItems(res.map(handleItems).slice(0, 5));
        });
    }
    useEffectOnce(() => {
        itemsFetch();
    });

    // Clear some input variables to pass trough
    const field = { ...props, icon: 'far fa-compass', type: 'text', value: '' };
    // Create the field value
    if (value && value.country) {
        field.value = locationValue(value);
    }

    // Create the arguments to build the Autocomplete
    const propsAutocomplete = {
        field,
        // Props to search
        items,
        itemKey: 'id',
        itemsFetch,
        // Props to customize the modal
        title: field.label,
        search: t('companySearch'),
    };
    return (
        <FormAutocomplete {...propsAutocomplete} initialValue={value}>
            <Disclaimer>{t('locationSearchDisclaimer')}</Disclaimer>
            {props.children}
        </FormAutocomplete>
    );
}
export const Location = FormCustomLocation;
export default FormCustomLocation;
