import styled from 'styled-components';

export const ProfileInfoReminder = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #fffb9e;
    padding: 20px;
    margin-bottom: 35px;
    border: 1px solid #ffd400;
    border-radius: 5px;

    @media screen and (max-width: 990px) {
        flex-direction: column;
    }
`;

export const Description = styled.div`
    display: flex;
    flex: 2.5;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &p {
        font-family: 'Kanit';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
    }
`;

export const Button = styled.a`
    flex: 1;
    background: #ffd400;
    border-radius: 3px;
    border: none;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    margin-left: 80px;
    text-align: center;
    padding: 5px 12px;

    /* Regular White */

    color: #ffffff;
    @media screen and (max-width: 990px) {
        margin: auto;
        margin-top: 20px;
    }
`;
