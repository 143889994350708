import styled from 'styled-components';

export const ListScrolls = styled.ul.attrs(props => ({
    className: 'list-scrolls',
}))`
    .list-subtitle + & {
        padding-top: 1rem;
    }
`;

export const ListScrollsItem = styled.li.attrs(props => ({
    className: 'list-scrolls-item',
}))`
    /* background: #fff;
    border-left: 0.75rem solid rgba(0, 0, 0, 0.2);
    border-right: 0.75rem solid rgba(0, 0, 0, 0.2); */
    flex: 0 0 23rem;
    padding: 0 0.75rem 0;
    white-space: normal;
    word-wrap: break-word;
`;
