import React from 'react';

import { RHP } from 'providers';
import { useCXI } from 'modules/cxi/useCXI';
import { getDateXDaysAgo } from 'utils/date';
import * as Styled from './StyledMatchAction';

export const MatchActionMentoring = ({ match }) => {
    const { t, setModal, vars } = useCXI();
    const { mentoring } = match;

    // Check if there is a request sent less than 5 days ago
    const daysAgo = getDateXDaysAgo(5, new Date());
    const dateRequest = match.mentoringRequestedDate
        ? new Date(match.mentoringRequestedDate)
        : undefined;

    let disabled = false;
    if (vars.isMentoring && vars.mentoring.matchid !== match.matchid) {
        disabled = true;
    }

    let text = t('match.action.mentor');
    let onClick = () => setModal({ match, modal: 'Mentoring' });
    if (mentoring === 'accepted') {
        text = t('match.action.mentoring');
        onClick = () => RHP.push(`/100-10-1/mentoring/${match.matchid}`);
    }
    if (mentoring === 'requested' && (!dateRequest || dateRequest > daysAgo)) {
        text = t('match.action.mentorReRequest');
    }

    return (
        <Styled.MatchAction className="s3 action" disabled={disabled} onClick={onClick}>
            <span className="text">{text}</span>
        </Styled.MatchAction>
    );
};
