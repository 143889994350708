import styled from 'styled-components';
import { positions } from 'utils/styles';

export const GrowUpModal = styled.div`
    overflow-y: auto;
    position: fixed;
    ${positions(0, 0, 0, 0)}
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GrowUpModalBackdrop = styled.button.attrs({
    'aria-hidden': true,
})`
    background: rgba(0, 0, 0, 0.8);
    border: 0;
    position: fixed;
    ${positions(0, 0, 0, 0)}
    z-index: 999991;
`;

export const Modal = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 1.6rem;
    padding-top: calc(1.6rem + var(--ion-safe-area-top));
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;
    z-index: 999999;
    background-color: white;
    width: fit-content;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-top: calc(3.2rem + var(--ion-safe-area-top));
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }
    .shareLink {
        background: #e2fcff;
        border-radius: 4.2px;
        padding: 8px;
        color: #388089;
        font-size: 11px;
        text-align: center;
        width: 400px;
    }
`;

export const Descr = styled.div`
    font-size: 1.4rem;
    line-height: 1.4;
`;

export const Title = styled.div`
    color: #005995;
    margin: 15px auto;
    font-size: 20px;
    font-weight: 700;
`;

export const SubTitle = styled.div`
    color: #005995;
    font-size: 16px;
    font-weight: 700;
`;
export const Head = styled.div`
    display: flex;
    img {
        width: 100px;
        height: auto;
    }
`;

export const Body = styled.div`
    font-size: 1.4rem;
    display: flex;
    line-height: 1.4;
`;

export const Feet = styled.div`
    font-size: 1.4rem;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.4;
    margin-bottom: 15px;
`;
