import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const PostCard = styled.div.attrs(props => ({
    className: `post-card ${props.type ? props.type : ''}`,
}))`
    display: flex;
    padding: 0;
    position: relative;

    .tit {
        display: block;
        ${truncate(3)}
    }
    @media (min-width: 567px) {
        flex-direction: column;
        .post-card-holdr {
            padding: 2rem 0 0;
        }
        .post-card-image {
            flex: auto;
            height: auto;
            padding-top: 100%;
        }
    }

    &.featured {
        color: #fff;
        display: block;
    }
    &.featured .post-card-holdr {
        background: rgba(0, 0, 0, 0.5);
        padding: 1.5rem;
        position: absolute;
        ${positions('auto', 0, 0, 0)}

        .title {
            font-size: 1.8rem;
        }
        .dated {
            color: #fff;
        }
    }
    &.featured .post-card-image {
        border-top: 2px solid #fff;
        flex: auto;
        height: auto;
        padding-top: 75%;
        @media (min-width: 768px) {
            padding-top: 36.6%;
        }

        &::before {
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
        }
    }
`;

export const CardHoldr = styled.div.attrs({
    className: 'post-card-holdr',
})`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 1rem;

    .badge {
        font-weight: 600;
        text-transform: uppercase;
    }
    .title {
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.2;
        margin-top: 1rem;
        ${truncate(3)}
    }
    .dated {
        color: #999;
        display: block;
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: auto;
        padding-top: 1rem;
        text-transform: uppercase;
    }
`;

export const CardImage = styled.div.attrs(props => ({
    className: `post-card-image`,
    role: 'img',
    'aria-label': props.alt,
}))`
    background: #ddd;
    flex: 0 0 12.8rem;
    height: 12.8rem;
    margin: 0;
    position: relative;

    &::before {
        background: transparent no-repeat 50% 50%;
        background-image: url(${props => props.mobile});
        background-size: cover;
        content: '';
        position: absolute;
        ${positions(0, 0, 0, 0)}
    }
    @media (min-width: 567px) {
        &::before {
            background-image: url(${props => props.desktop});
        }
    }
`;
