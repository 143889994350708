import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { InvestorStep2Form } from './InvestorStep2Form';

export function InvestorStep2(props) {
    const conv = useConversionOptions(['howCanContribute', 'investorType']);
    const { vars, putUserInvestor, updateSteps } = props.useRankingInvestor;

    // Initialize a new form handler
    const form = useForm({
        ...vars.investor,
    });

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step1 !== true) {
        RHP.replace('/ranking/latam/investor/');
        return null;
    }

    // Handle form submitions
    const onSubmit = values => {
        const payload = { ...values };

        return putUserInvestor(payload)
            .then(() => {
                // Set the current step to true;
                updateSteps({ step2: true });
                RHP.push('/ranking/latam/investor/step3');
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="InvestorStep2" back="/ranking/latam/investor" customBackText={props.t("glossary:backToRanking")}>
            <Page.Title>{props.t('InvestorStep2.title')}</Page.Title>
            <Page.Descr>{props.t('InvestorStep2.descr')}</Page.Descr>

            <InvestorStep2Form {...propsForm} />
        </Page.Page>
    );
}
export default InvestorStep2;
