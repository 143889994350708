import React from 'react';

import { useUser } from 'hooks';
import { useCXI } from 'modules/cxi/useCXI';
import { useEffectOnce } from 'react-use';
import { Trans } from 'react-i18next';
import * as Styled from './StyledRanking';
import { RankingItem } from './RankingItem';
import { RankingItemUser } from './RankingItemUser';

export const Ranking = () => {
    const { company } = useUser();
    const { t, vars, getCXIRanking } = useCXI();
    const { parentCompany } = vars;
    const { ranking, user } = vars.ranking || {};

    const companyName = parentCompany?.name || company?.name || '';

    useEffectOnce(() => {
        if (!vars.ranking) getCXIRanking();
    }, []);

    return !companyName || !ranking || !user ? null : (
        <Styled.Ranking>
            <Styled.RankingHead>
                <Trans
                    className="text"
                    i18nKey="home.Ranking.title"
                    t={t}
                    company={{ companyName }}
                >
                    Top {t('topContr')} <strong>{{ companyName }}</strong>
                </Trans>
            </Styled.RankingHead>

            <Styled.RankingBody>
                {[...ranking]
                    .map((i, idx) => ({ ...i, key: `${i._id}-${idx}` }))
                    .map(i => (
                        <RankingItem key={i.key} data={i} />
                    ))}
                <RankingItemUser data={user} />
            </Styled.RankingBody>
        </Styled.Ranking>
    );
};
