import React from 'react';

import logo from 'assets/images/logo-100-10-1.png';

import { RHP } from 'providers';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';
import * as Styled from './StyledCXISetupCompleted';

export const CXISetupCompleted = props => {
    const { t } = useCXI();

    const handleClick = () => {
        RHP.push('/100-10-1/dashboard');
    };

    return (
        <Styled.CXISetupCompleted>
            <Styled.CXISetupCompletedCard>
                <Styled.Image>
                    <img src={logo} alt="Logo 100-10-1" />
                </Styled.Image>

                <Styled.Title>{t('setup.completed.title')}</Styled.Title>
                <Styled.Descr>{t('setup.completed.description')}</Styled.Descr>

                <Button size="large" variant="default" onClick={handleClick}>
                    {t('setup.completed.goToDashboard')}
                </Button>
            </Styled.CXISetupCompletedCard>
        </Styled.CXISetupCompleted>
    );
};
