import styled from 'styled-components';

export const Ranking = styled.div`
    margin-top: 4.8rem;
`;

export const RankingHead = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 22px;

    strong {
        color: ${({ theme }) => theme.colors.blue};
    }
`;

export const RankingBody = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 2.4rem;
    gap: 3.2rem;

    @media (min-width: 768px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
