import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { formatDate } from 'utils/date';

import { useStartupCXI } from '../../useStartupCXI';
import { Container } from '../container';
import * as Styled from './StyledMentoringForm';

export const MentoringForm = () => {
    const { t, modal, setModal, putMatchMentoring } = useStartupCXI();
    const { match } = modal;
    const { evaluator, history, mentoring } = match;

    const [disabled, setDisabled] = React.useState(false);

    let date = history.mentoringRequestedDate || match.regDate;
    let dated = formatDate(new Date(date), "'Enviado dia' dd/MM/yyyy 'às' H'h'mm");

    if (mentoring === 'accepted' && history.mentoringAcceptedDate) {
        date = history.mentoringAcceptedDate;
        dated = formatDate(new Date(date), "'Aceito dia' dd/MM/yyyy 'às' H'h'mm");
    }
    if (mentoring === 'rejected' && history.mentoringRejectedDate) {
        date = history.mentoringRejectedDate;
        dated = formatDate(new Date(date), "'Rejeitado dia' dd/MM/yyyy 'às' H'h'mm");
    }
    if (mentoring === 'finished' && history.mentoringFinishedDate) {
        date = history.mentoringFinishedDate;
        dated = formatDate(new Date(date), "'Finalizado dia' dd/MM/yyyy 'às' H'h'mm");
    }

    const handleClick = async status => {
        setDisabled(true);
        try {
            const payload = { mentoring: status };
            await putMatchMentoring(match.matchid, payload);

            const modal = `Mentoring${mentoring === 'accepted' ? 'Accepted' : 'Rejected'}`;
            setModal({ match, modal });
        } catch (e) {
            const newMatch = { ...match, mentoringError: e };
            setModal({ match: newMatch, modal: 'MentoringError' });
        }
    };

    return (
        <Styled.MentoringForm as={Container.Slim}>
            <Container.Head image={evaluator.photoUrl} />

            <Styled.MentoringInfo>
                <span className="name">{evaluator.fullName}</span>
                <span className="company">{evaluator.company}</span>
                <span className="position">{evaluator.position}</span>
                <span className="date">{dated}</span>
            </Styled.MentoringInfo>

            <Container.Body>
                <Styled.Descr>{t('modal.MentoringForm.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                {mentoring === 'requested' && (
                    <Button
                        disabled={disabled}
                        variant="success"
                        onClick={() => handleClick('accepted')}
                    >
                        <span className="text">{t('modal.MentoringForm.accept')}</span>
                    </Button>
                )}
                {mentoring === 'requested' && (
                    <Button
                        disabled={disabled}
                        variant="toggle"
                        onClick={() => handleClick('rejected')}
                    >
                        <span className="text">{t('modal.MentoringForm.reject')}</span>
                    </Button>
                )}

                {mentoring === 'accepted' && (
                    <Button
                        disabled={disabled}
                        onClick={() => setModal({ match, modal: 'MentoringFinish' })}
                    >
                        <span className="text">{t('modal.MentoringForm.finish')}</span>
                    </Button>
                )}

                <Button variant="link" onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.MentoringForm>
    );
};
