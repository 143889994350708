import React from 'react';
import { useEffectOnce } from 'react-use';

import * as Styled from './StyledListScrollHold';

const ScrollArrow = props => {
    return (
        <Styled.ScrollArrow side={props.side} onClick={props.onClick}>
            <span className={`icon fas fa-fw fa-chevron-${props.side}`} aria-hidden="true" />
            <span className="text sr-only">{props.side}</span>
        </Styled.ScrollArrow>
    );
};

const ScrollHoldr = props => (
    <Styled.ListScrollsHold className={props.className} height={props.height}>
        <Styled.ListScrollsHoldAbsolute>
            <Styled.ListScrollsHoldContainer>{props.children}</Styled.ListScrollsHoldContainer>
        </Styled.ListScrollsHoldAbsolute>
    </Styled.ListScrollsHold>
);

export function ListScrollsHold(props) {
    const scroll = React.useRef(null);
    const amount = props.amountToScroll;

    // Store the scroll state
    const [state, setState] = React.useState({});

    // Functions to handle left/right actions
    function scrollToLeft() {
        const x = state.actual + state.clientWidth - 84;
        scroll.current.style.transform = `translateX(${x}px)`;
        setState(oldState => ({ ...oldState, actual: x }));
    }
    function scrollToRight() {
        const x = state.actual - state.clientWidth + 84;
        scroll.current.style.transform = `translateX(${x}px)`;
        setState(oldState => ({ ...oldState, actual: x }));
    }

    useEffectOnce(() => {
        const { clientWidth, scrollWidth } = scroll.current;
        setState({ actual: 0, clientWidth: amount || clientWidth, scrollWidth });
    });

    return (
        <ScrollHoldr className={props.className}>
            <Styled.ListScrollsHoldInside ref={scroll}>
                <Styled.Overflow>{props.children}</Styled.Overflow>
            </Styled.ListScrollsHoldInside>
            <Styled.ListScrollsHoldControls>
                <ScrollArrow {...state} side="left" onClick={scrollToLeft} />
                <ScrollArrow {...state} side="right" onClick={scrollToRight} />
            </Styled.ListScrollsHoldControls>
        </ScrollHoldr>
    );
}
export default ListScrollsHold;
