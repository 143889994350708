import React from 'react';

import { useUser } from 'hooks';
import { useCXI } from 'modules/cxi/useCXI';
import { stringToKebabCase } from 'utils/conversion';
import logoOiweek from 'assets/images/logo-oiweek-black.png';
import * as Styled from './StyledForumCard';

// const LINK = 'https://100os.net/Forum_Praticantes_Programa_100_10_1_Startups';

const FETCH_OPTIONS = {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        Authorization: 'Bearer keyru9bHHRzjSsnzv',
        'Content-Type': 'application/json'
    }
};

export const ForumCard = ({ data }) => {
    const user = useUser();
    const { setModal, t } = useCXI();

    const [loading, setLoading] = React.useState(false);

    const record = {
        fields: {
            Name: user.fullName,
            Email: user.email,
            Company: user.company?.name ?? '-',
            Forum: `${data.type} ${data.title}`,
            Date: new Date().toISOString()
        }
    };

    const handleSubscription = () => {
        setLoading(true);
        fetch('https://api.airtable.com/v0/appm4PONkgPVPVUtV/Foruns', {
            ...FETCH_OPTIONS,
            body: JSON.stringify({ records: [record] })
        }).then(() => {
            setModal({ modal: 'ForumSubscription' });
            setLoading(false);
        });
    };

    if (data.stage === t('forum.dataStage1')) {
        data.tag = t('forum.dataTag1');
    }
    if (data.stage === t('forum.dataStage2')) {
        data.tag = t('forum.dataTag2');
    }
    if (data.stage === t('forum.dataStage3')) {
        data.tag = t('forum.dataTag3');
    }
    return (
        // change stage to type
        <Styled.ForumCard className={stringToKebabCase(data.stage)}>
            <span className="logo">
                <img src={logoOiweek} alt="100 Open Startups Logo" height={64} />
            </span>

            <Styled.ForumCardHeadWrapper>
                <div className="colorizedLine" />
                <Styled.ForumCardHead className={stringToKebabCase(data.stage)}>
                    <span className="type">{data.tag}</span>
                </Styled.ForumCardHead>
            </Styled.ForumCardHeadWrapper>

            <Styled.ForumCardBody>
                <h4>{data.title}</h4>
                <p className="descr">{data.descr}</p>
                <p className="times">{data.times}</p>
            </Styled.ForumCardBody>
            <Styled.ForumCardFeet>
                {!!data.file && (
                    <a
                        className="buttn download"
                        href={data.file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('forum.download')}
                    </a>
                )}
                <button
                    className="buttn"
                    type="button"
                    disabled={loading}
                    onClick={handleSubscription}
                >
                    {loading ? t('forum.send') : t('forum.access')}
                </button>
            </Styled.ForumCardFeet>
        </Styled.ForumCard>
    );
};
