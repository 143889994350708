import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useEffectOnce } from 'react-use';

import { PublicHeader } from 'components/public-header';
import { CA } from 'contexts';

import { StandAloneContent } from '../components/stand-alone-content';
import { Container } from './styles';

export const CxiSpeedDating = props => {
    const { addVars, getUserSpeedDating, putStartupSpeedDating } = props.useStartupId;
    const { state } = React.useContext(CA);

    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const matchId = search.get('matchId');
    const speedDatingId = search.get('speedDatingId');
    const cxiStage = search.get('cxiStage');

    const [status, setStatus] = useState(undefined);
    const [startupUserAccepted, setStartupUserAccepted] = useState('');
    const [startupInfo, setStartupInfo] = useState(undefined);
    const [messageData, setMessageData] = useState({
        comment: '',
        description: '',
        position: '',
        time: new Date(),
        username: '',
        userid: '',
        avatar: '',
    });

    useEffectOnce(() => {
        addVars({
            matchId,
            speedDatingId,
        });

        async function loadSpeedDatingData() {
            const info = await getUserSpeedDating({
                matchId,
                speedDatingId,
            });
            setStartupUserAccepted(state.user.userid);
            setMessageData({
                comment: info.reason,
                username: info.evaluator.name,
                description: info.company.name,
                position: info.evaluator.position,
                userid: info.evaluator._id,
                time: new Date(info.updatedAt),
                avatar: info.evaluator?.photoUrl,
            });
            setStartupInfo(info.startup);
        }

        loadSpeedDatingData();
    });

    const onSubmit = async event => {
        const payload = {
            status,
            startupUserAccepted,
            isCxi: true,
            cxiStage: Number(cxiStage),
        };

        await putStartupSpeedDating(payload);
    };

    return (
        <>
            <PublicHeader />
            <Container>
                <StandAloneContent
                    title={`Olá, ${startupInfo?.name}. Você recebeu um convite para Speed-Dating:`}
                    description="O Speed-Dating é uma conversa rápida de até 18 minutos que acontece entre o representante de uma corporação que possui interesse em soluções inovadoras e a sua startup. Nessa conversa, vocês podem compartilhar conhecimentos, cocriar soluções e fechar negócios benéficos para ambas as partes. Aceitando este convite, você receberá um link para conversar com este representante durante a próxima Oiweek."
                    type="speed-dating"
                    message={messageData}
                    handleSubmit={onSubmit}
                    onChange={value => setStatus(value)}
                    onChangeQuestion={value => setStatus(value ? 'accepted' : 'rejected')}
                    startup={startupInfo}
                />
            </Container>
        </>
    );
};

export default CxiSpeedDating;
