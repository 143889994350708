import React from 'react';

import logo from 'assets/images/logo-100-10-1.png';
import menuIcon from 'assets/images/icons/menu-white.svg';

import { RHP } from 'providers';
import { useCXI } from 'modules/cxi/useCXI';

import { NavbarProfile } from './NavbarProfile';
import * as Styled from './StyledNavbar';

export const Navbar = ({ menuToggle }) => {
    const { t, setModal } = useCXI();

    const backHome = () => {
        RHP.push('/100-10-1/');
    };

    const profileItems = [
        {
            icon: 'far fa-user-circle',
            title: t('menu.profile'),
            action: () => RHP.push('/100-10-1/profile')
        },
        {
            icon: 'far fa-question-circle',
            title: t('menu.support'),
            action: () => setModal({ modal: 'Support' })
        },
        {
            icon: 'far fa-sign-out',
            title: t('menu.leave'),
            action: () => RHP.push('/')
        }
    ];

    return (
        <Styled.Container>
            <div className="navbar-element" />
            <div className="navbar-content">
                <Styled.MenuButton onClick={menuToggle}>
                    <img src={menuIcon} alt="Menu Icon" />
                </Styled.MenuButton>
                <Styled.Logo src={logo} alt="Logo 100-10-1" onClick={backHome} />
            </div>
            <NavbarProfile items={profileItems} />
        </Styled.Container>
    );
};
