import React from 'react';
import { useParams } from 'react-router';

import { Header } from 'modules/cxi/components/header/Header';
import * as Tabs from 'modules/cxi/components/tabs';

import { StartupCard } from './components/startup-card';

import { useCXIDashboardStartup } from './useCXIDashboardStartup';
import { CXIDashboardStartupContextProvider } from './CXIDashboardStartupContext';

import { CXIDashboardStartupGeneral } from './general/CXIDashboardStartupGeneral';

import * as Styled from './StyledCXIDashboardStartup';
import { CXIDashboardStartupProfile } from './profile/CXIDashboardStartupProfile';
import { CXIDashboardStartupFinancial } from './financial/CXIDashboardStartupFinancial';
import { CXIDashboardStartupSummaries } from './summaries/CXIDashboardStartupSummaries';

export const CXIDashboardStartupModule = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    return (
        <Styled.CXIDashboardStartupLayout
            className={startup.typeOfProject === 'tech' ? 'is-tech' : ''}
        >
            <Styled.CXIDashboardStartup>
                <Header className="main" back />

                <Styled.CXIDashboardStartupCard>
                    <StartupCard startup={startup} />
                </Styled.CXIDashboardStartupCard>

                <Tabs.TabsContainer initial="general">
                    <Header
                        className="tabs"
                        title={t('startup.title')}
                        tooltip={t('startup.tooltip')}
                    >
                        <Tabs.TabsButtons>
                            <Tabs.TabsButton name="general">
                                <span className="text">{t('startup.tab.general')}</span>
                            </Tabs.TabsButton>

                            <Tabs.TabsButton name="profile">
                                <span className="text">{t('startup.tab.profile')}</span>
                            </Tabs.TabsButton>

                            <Tabs.TabsButton name="financial">
                                <span className="text">{t('startup.tab.financial')}</span>
                            </Tabs.TabsButton>

                            {startup.typeOfProject === 'tech' && (
                                <Tabs.TabsButton name="summaries">
                                    <span className="text">{t('startup.tab.summaries')}</span>
                                </Tabs.TabsButton>
                            )}
                        </Tabs.TabsButtons>
                    </Header>

                    <Styled.CXIDashboardStartupTabs>
                        <Tabs.Tab name="general">
                            <CXIDashboardStartupGeneral />
                        </Tabs.Tab>

                        <Tabs.Tab name="profile">
                            <CXIDashboardStartupProfile />
                        </Tabs.Tab>

                        <Tabs.Tab name="financial">
                            <CXIDashboardStartupFinancial />
                        </Tabs.Tab>

                        {startup.typeOfProject === 'tech' && (
                            <Tabs.Tab name="summaries">
                                <CXIDashboardStartupSummaries />
                            </Tabs.Tab>
                        )}
                    </Styled.CXIDashboardStartupTabs>
                </Tabs.TabsContainer>
            </Styled.CXIDashboardStartup>
        </Styled.CXIDashboardStartupLayout>
    );
};

export const CXIDashboardStartup = () => {
    const { startupId } = useParams();
    return (
        <CXIDashboardStartupContextProvider startupId={startupId}>
            <CXIDashboardStartupModule />
        </CXIDashboardStartupContextProvider>
    );
};
export default CXIDashboardStartup;
