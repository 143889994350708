import React from 'react';

import * as Styled from './StyledTags';

export const Tags = ({ tags }) =>
    !!tags?.length && (
        <Styled.Tags className="tags">
            {tags.map(tag => !!tag && <li key={tag}>{tag}</li>)}
        </Styled.Tags>
    );
