import styled from 'styled-components';
import { positions } from 'utils/styles';

import bg from 'assets/images/bg-oiweek.jpg';

export const OiweekModule = styled.div.attrs({
    className: 'styled-oiweek-module',
})`
    background: #000 no-repeat 50% 50%;
    background-size: cover;
    background-image: url(${bg});
    color: #fff;
    position: fixed;
    ${positions(0, 0, 0, 0)}

    ion-content {
        --background: transparent;
        --color: #fff;
    }

    .c-page-title .text {
        max-height: 100%;
        -webkit-line-clamp: unset;
    }
`;
