import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';
import { RHP } from 'providers';

import { Container } from '../container';
import * as Styled from './StyledStartupUnmatch';

export const StartupUnmatch = () => {
    const { t, modal, setModal, deleteMatch } = useCXI();
    const { match } = modal;

    const [disabled, setDisabled] = React.useState(false);

    const handleClick = async () => {
        setDisabled(true);
        try {
            await deleteMatch(match.matchid);
            RHP.push('/100-10-1/networks');
            setModal(null);
        } catch (e) {
            setModal(null);
        }
    };

    return (
        <Styled.StartupUnmatch as={Container.Slim}>
            <Container.Head image={match.startup.logoUrl} />

            <Container.Body>
                <Styled.Title>{t('modal.StartupUnmatch.title')}</Styled.Title>
                <Styled.Descr>{t('modal.StartupUnmatch.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button variant="toggle" disabled={disabled} onClick={() => setModal(null)}>
                    <span className="text">{t('modal.StartupUnmatch.no')}</span>
                </Button>
                <Button variant="success" disabled={disabled} onClick={() => handleClick(true)}>
                    <span className="text">{t('modal.StartupUnmatch.yes')}</span>
                </Button>
            </Container.Feet>
        </Styled.StartupUnmatch>
    );
};
