import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionOptionsExtended = ({ match }) => {
    const { t, setModal } = useCXI();

    return (
        <Styled.MatchAction
            className="options-extended"
            onClick={() => setModal({ match, modal: 'StartupOptions' })}
        >
            <span className="text">{t(`match.action.options`)}</span>
            <span className="icon fas fa-fw fa-chevron-down" aria-hidden="true" />
        </Styled.MatchAction>
    );
};
