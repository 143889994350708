import { Button } from 'modules/cxi/components/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import QRCode from '../QRCode/QRCode';
import * as Styled from './StyledGrowUpContactsModal';

export const GrowUpContactsModal = props => {
    const { vars } = props.useStartupId;
    const { t } = useTranslation('startup');

    const { setModal } = props;
    const { startup } = vars;
    const link = `https://app.openstartups.net/registercontact/${startup.startupSecret}`;
    const [copy, setCopy] = useCopyToClipboard();
    const handleCopyLink = () => {
        setCopy(link);
    };
    const [qrCode, setQrCode] = React.useState(null);

    return (
        <Styled.GrowUpModal>
            <Styled.GrowUpModalBackdrop onClick={() => setModal(false)} className="backdrop" />
            <Styled.Modal>
                <Styled.Head>
                    <img src={startup.logoUrl} alt="startup logo" />
                </Styled.Head>

                <Styled.Body>
                    <Styled.Title>{t('GrowUpContactsModal.title')}</Styled.Title>
                </Styled.Body>

                <Styled.Feet>
                    <Styled.Descr>{t('GrowUpContactsModal.desc')}</Styled.Descr>
                    <div className="shareLink"> {link || 'Loading...'} </div>
                    <Button onClick={handleCopyLink}>
                        {!copy?.value && (
                            <span className="text">{t('GrowUpContactsModal.buttonCopy')}</span>
                        )}
                        {!!copy?.value && (
                            <span className="text">{t('GrowUpContactsModal.secButtonCopy')}</span>
                        )}
                    </Button>
                </Styled.Feet>
                <QRCode qrCode={qrCode} setQrCode={setQrCode} link={link} />
            </Styled.Modal>
        </Styled.GrowUpModal>
    );
};
