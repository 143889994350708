import React from 'react';

import { useTabs } from './useTabs';
import * as Styled from './StyledComponentTabs';

export const TabsButton = ({ children, name }) => {
    const { tab, setTab } = useTabs();
    return (
        <Styled.Button active={tab === name} onClick={() => setTab(name)}>
            {children}
        </Styled.Button>
    );
};

export const Button = TabsButton;
