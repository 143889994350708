import styled from 'styled-components';

export const Messages = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 2.4rem 0;
    justify-content: flex-end;

    @media (min-width: 768px) {
        padding: 2.4rem;
    }
`;
