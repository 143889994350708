import { useContext } from 'react';
import { CXICertificationContext } from './CXICertificationContext';

export const useCXICertification = () => {
    const context = useContext(CXICertificationContext);
    if (context === undefined) {
        throw new Error(
            'useCXICertification must be used within a CXICertificationContextProvider'
        );
    }
    return context;
};
