import React from 'react';

import * as Custom from './custom';
import { FormField } from './field/FormField';

import * as Styled from './StyledComponentForm';

export function ComponentField(props) {
    // Check for some mandatory props
    // console.error('FormField', 'All FormField must have a name property', props);
    if (!props.name) return null;

    // Check if conditional if is set to the field
    // console.log(`FormField:${props.name}`, 'Not showing because of onlyIf');
    if (props.onlyIf !== undefined && props.onlyIf !== true) return null;

    // Check for custom form elements before returning default field
    const ReturnFormField = Custom[props.type] || FormField;
    return (
        <Styled.ComponentFormField
            hiddenIf={props.hiddenIf}
            size={props.size}
            style={props.style}
            type={props.type}
        >
            <ReturnFormField {...props} />
        </Styled.ComponentFormField>
    );
}
export const Field = ComponentField;
export default ComponentField;
