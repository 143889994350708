import styled from 'styled-components';

export const CXIHome = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .mobile {
        display: block;
        @media (min-width: 768px) {
            display: none;
        }
    }
    .desktop {
        display: none;
        @media (min-width: 768px) {
            display: block;
        }
    }
`;

export const Row = styled.div`
    align-items: stretch;
    display: grid;
    gap: 3.2rem;
    position: relative;

    @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
export const Col = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
        &:first-child {
            grid-column: 1 / 3;
        }
    }
`;

export const CXIHomeArea = styled.div`
    background: #dadada;
    margin-top: 5.2rem;
    padding: 4.8rem 0;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        background: inherit;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100vw;
        z-index: 10;
    }
    &::before {
        right: 100%;
    }
    &::after {
        left: 100%;
    }
`;

export const CXIHomeCards = styled(CXIHomeArea)`
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    margin-top: 0;
    padding-top: 1.6rem;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const CXIHomeAbout = styled.div`
    margin-top: 4.8rem;
`;

export const TradeAndSupportCards = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 3.2rem;
    height: 100%;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;
