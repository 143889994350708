import { CONFIG } from 'config';

import * as Ranking from 'modules/ranking';
import * as StartupId from '..';

// verify thus itens on StartupId
import Opportunity from '../opportunity/Opportunity';
import GrowUpContacts from '../contacts/GrowUpContacts';

const hrefStartup = CONFIG.appLinks.startup;

export const routeItems = [
    {
        exact: true,
        path: '/ranking/startup/?startupId={{startupId}}',
        component: Ranking.RankingStartup,
        name: 'StartupSelect',
        icon: 'far fa-clipboard',
        replace: true
    },
    {
        exact: true,
        path: '/startup/{{startupId}}/100-10-1',
        component: StartupId.StartupCXI,
        name: '100-10-1',
        icon: 'far fa-hands-helping',
        replace: true
    },
    {
        exact: true,
        path: `/startup/{{startupId}}/contacts`,
        component: GrowUpContacts,
        name: 'growup',
        icon: 'far fa-ball-pile',
        replace: true
    },
    {
        exact: true,
        path: `${hrefStartup}/home/?startupId={{startupId}}`,
        component: Opportunity,
        name: 'Opportunities',
        icon: 'far fa-cube',
        replace: true,
        redirect: true
    }
];

export const profileItems = [
    {
        exact: true,
        path: `${hrefStartup}/home/?startupId={{startupId}}`,
        component: StartupId.StartupIdProfile,
        name: 'StartupProfile',
        icon: 'far fa-user',
        replace: true,
        redirect: true
    },
    {
        exact: true,
        path: `${hrefStartup}/registerstartup/?startupId={{startupId}}&lang=pt&back=/home/?startupId={{startupId}}`,
        component: StartupId.BasicEdit,
        name: 'BasicEdit',
        icon: 'far fa-cog',
        replace: true,
        redirect: true
    },
    {
        exact: true,
        path: '/startup/{{startupId}}/advanced-edit',
        component: StartupId.AdvancedEdit,
        name: 'AdvancedEdit',
        icon: 'far fa-cog',
        replace: true
    }
];
