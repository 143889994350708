import styled from 'styled-components';

export const Tags = styled.ul`
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 0.8rem;
    margin: 0;
    padding: 0;

    li {
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
        border-radius: 2rem;
        color: ${({ theme }) => theme.colors.lightGray};
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.2;
        padding: 0.6rem 1.2rem;
        width: fit-content;

        &.active {
            background-color: #0094f9;
            color: #ffffff;
            border-color: #0094f9;
        }
    }
`;
