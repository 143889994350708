import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { formatDate } from 'utils/date';

import * as Styled from './StyledEvaluations';

export const EvaluationsEvaluation = ({ evaluation }) => {
    const { t } = useCXI();
    const date = formatDate(new Date(evaluation?.regDate), "dd/MM/yyyy, 'às' H'h'mm");

    let icon = '';
    let text = '';

    if (evaluation?.startupFeedback === 'positive') {
        icon = 'fa-laugh';
        text = t('modal.Evaluations.startupFeedback.positive');
    } else if (evaluation?.startupFeedback === 'neutral') {
        icon = 'fa-meh';
        text = t('modal.Evaluations.startupFeedback.neutral');
    } else if (evaluation?.startupFeedback === 'negative') {
        icon = 'fa-frown';
        text = t('modal.Evaluations.startupFeedback.negative');
    }

    return (
        <Styled.Evaluation>
            <Styled.EvaluationText>&quot;{evaluation.comment}&quot;</Styled.EvaluationText>
            <Styled.EvaluationInfo>
                <span className="date">{date}</span>
                {!icon && !text ? (
                    <span className="text">{t('modal.Evaluations.startupFeedback.pending')}</span>
                ) : (
                    <span className="text">
                        Classificado como: <strong>{text}</strong>
                        <span className={`icon far ${icon}`} aria-hidden="true" />
                    </span>
                )}
            </Styled.EvaluationInfo>
        </Styled.Evaluation>
    );
};
