import React from 'react';
import { useClickAway } from 'react-use';

import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledAbout1';

export const About1 = () => {
    const { setModal } = useCXI();
    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
        setModal(null);
    });

    return (
        <Styled.About1 ref={containerRef}>
            <Styled.Close onClick={() => setModal(null)}>
                <span className="icon far fa-times" aria-hidden="true" />
            </Styled.Close>
            <Container.Head>
                <span className="text">Etapa de Mentoria</span>
            </Container.Head>

            <Container.Body>
                <p>
                    A partir de agora você verá em destaque ações vinculadas a jornada de mentoria,
                    foco desta etapa final do Programa 100-10-1 Startups.
                </p>

                <p>
                    O primeiro passo é, dentre as startups que você fez reunião de speed-dating,
                    selecionar qual startup você quer convidar para ser mentor. Seu convite será
                    apresentado junto a sua especialidade e o seu perfil de linkedin. A startup tem
                    até 5 dias para confirmar ou declinar de sua proposta de mentoria.
                </p>

                <p>
                    Se a startup confirmar, ótimo! Acesse a ABA MENTORIA e siga as orientações para
                    registrar as 5 reuniões de mentoria esperadas nesta etapa, e as contribuições
                    temáticas indicadas.
                </p>

                <p>
                    Se ela não responder no prazo limite ou declinar, você pode reenviar o convite
                    ou selecionar outra startup dentre as que você realizou speed-dating.
                </p>

                <p>
                    Nesta etapa seu objetivo é conquistar o aceite de uma startup para o processo de
                    mentoria do programa, realizar e registrar 5 reuniões de mentoria com a startup
                    e, ter dentre as contribuições feitas para sua startup mentorada, 10
                    classificadas como “MUITO RELEVANTE”.
                </p>
            </Container.Body>
        </Styled.About1>
    );
};
