import React from 'react';

import * as Styled from './StyledArea';

export const Area = ({ children, descr, title, ...props }) => {
    const [open, setOpen] = React.useState(true);
    const icon = `fa-chevron-${open ? 'up' : 'down'}`;
    return (
        <Styled.Area {...props}>
            <Styled.AreaHead
                onClick={() => {
                               setOpen(o => !o);
                }}
            >
                <span className="text">
                    {title && <span className="title">{title}</span>}
                    {descr && <span className="descr">{descr}</span>}
                </span>
                {!!children && <span className={`icon far ${icon}`} aria-hidden="true" />}
            </Styled.AreaHead>
            {open && !!children && <Styled.AreaBody>{children}</Styled.AreaBody>}
        </Styled.Area>
    );
};
