import React from 'react';
import { RHP } from 'providers';
import { useParams } from 'react-router';

import { UserAvatar } from 'core/components/user';

import useCXI from 'modules/cxi/useCXI';
import * as Styled from './StyledStartupProfile';

export const StartupProfile = props => {
    const { startup } = props;
    const { t } = useCXI();
    const { challengeId } = useParams();
    return (
        <Styled.StartupProfile>
            <UserAvatar user={{ firstName: startup.name, photoUrl: startup.logoUrl }} />
            <Styled.Infos>
                <div key={startup?.id}>
                    <span className="name">{startup?.name}</span>
                    <br />
                    <a
                        href={`/100-10-1/challenges/${challengeId}/startups/${startup._id}`}
                        onClick={event => {
                            event.preventDefault();
                            RHP.push(`/100-10-1/challenges/${challengeId}/startups/${startup._id}`);
                        }}
                    >
                        {t('challenge.seeProfile')}
                    </a>
                </div>
            </Styled.Infos>
        </Styled.StartupProfile>
    );
};
