import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonDatetime, IonModal, IonButton } from '@ionic/react';

import * as Styled from './StyledFormInput';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {},
};

export const FormInputCalendar = props => {
    const { t } = useTranslation('glossary');
    const [displayDateBox, setDisplayDateBox] = useState(false);

    const boolProps = {};

    if (props.input.disabled === true) {
        boolProps.disabled = props.input.disabled;
    }

    const handleDateChange = e => {
        const ev = fakeEvent;
        ev.target.name = props.input.name;
        ev.target.value = e.detail.value;
        return props.input.onChange(ev);
    };

    const clearDate = () => {
        props.input.value = '';
        const ev = fakeEvent;
        setDisplayDateBox(o => !o);
        ev.target.name = props.input.name;
        ev.target.value = '';
        return props.input.onChange(ev);
    };

    const handleNoDateclick = ev => {
        props.input.value = new Date().toISOString().slice(0, 10);
        ev.target.value = '';
        setDisplayDateBox(o => !o);
    };

    const formatDateValue = date => {
        return date ? date.slice(0, 10) : '';
    };

    const { value } = props.input;

    return !props.input ? null : (
        <Styled.FormInput name="date">
            {props.children}
            <div className="date-fields">
                <input
                    type="text"
                    className="day-input"
                    placeholder="DD"
                    value={formatDateValue(value)}
                    onChange={() => {}}
                    disabled
                />
                <input
                    type="text"
                    className="month-input"
                    placeholder="MM"
                    value={formatDateValue(value)}
                    onChange={() => {}}
                    disabled
                />
                <input
                    type="text"
                    className="year-input"
                    placeholder="YYYY"
                    value={formatDateValue(value)}
                    onChange={() => {}}
                    disabled
                />
            </div>

            <IonModal keepContentsMounted>
                <IonDatetime
                    presentation="date"
                    cancelText={t('cancel')}
                    displayFormat={props.displayFormat || 'DD-MM-YYYY'}
                    name={props.input.name}
                    id={props.input.id}
                    value={props.input.value}
                    min={props.input.min}
                    max={props.input.max}
                    required={props.input.required}
                    onIonChange={handleDateChange}
                    {...boolProps}
                />
                {props.clearButton && (
                    <IonButton expand="full" onClick={clearDate}>
                        {t('clearDate')}
                    </IonButton>
                )}
            </IonModal>
        </Styled.FormInput>
    );
};

export const calendar = FormInputCalendar;
export default FormInputCalendar;
