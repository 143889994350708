import React from 'react';
import logo from 'assets/images/logo.svg';

import { Container } from './styles';

export const PublicHeader = () => (
    <Container>
        <img src={logo} alt="Logo 100-10-1" />
    </Container>
);
