import React from 'react';

import { CA } from 'contexts';
import { useApi } from 'hooks';

export function useEvents() {
    const { scopeAllows } = React.useContext(CA);
    const api = useApi();

    // Initial values for the state
    const initial = { events: [], eventById: {}, futureEvents: [], pastEvents: [], byYear: {} };

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    function filterEvents(e) {
        if (
            (e.requireRegistered || e.hiddenUser) &&
            (!e.user || !['registered', 'attended'].includes(e.user.eventStatus))
        ) {
            return false;
        }
        return true;
    }

    function sortEvents(a, b) {
        if (a.startDate > b.startDate) return -1;
        if (a.startDate < b.startDate) return 1;
        return 0;
    }

    function handleEvents(events) {
        return events.sort(sortEvents).filter(filterEvents);
    }

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getEvents() {
        if (!scopeAllows('events')) {
            return Promise.resolve([]);
        }

        const past = [];
        const future = [];
        const byYear = {};
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1); // Get day before
        currentDate.setUTCHours(0, 0, 0, 0);

        return api.get('/events').then(events => {
            const filtered = handleEvents(events).map(event => {
                const endDate = event.endDate && new Date(event.endDate);
                if (endDate && endDate < currentDate) {
                    past.push(event);
                    if (!byYear[endDate.getFullYear()]) {
                        byYear[endDate.getFullYear()] = [];
                    }
                    byYear[endDate.getFullYear()].push(event);
                    return event;
                }
                future.push(event);
                return event;
            });
            addVars({ events: filtered, futureEvents: future, pastEvents: past, byYear });
            return filtered;
        });
    }

    function getEventById(id) {
        return api.get(`/events/${id}`).then(event => {
            addVars({ eventById: event });
            return event;
        });
    }

    function getFutureEvents() {
        const today = new Date();
        return api.get(`/events?after=${today.toISOString()}`).then(events => {
            const filtered = handleEvents(events);
            addVars({ futureEvents: filtered });
            return filtered;
        });
    }

    return {
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Custom functions
        getEvents,
        getEventById,
        getFutureEvents,
    };
}
export default useEvents;
