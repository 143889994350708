import React from 'react';

import anonAvatar from 'assets/images/anon-avatar.jpg';

import { UserAvatar } from 'core/components/user';
import * as Styled from './StyledMatchEvaluator';

export const MatchEvaluator = ({ match }) => {
    let { evaluator } = match;
    if (match.anonymous) {
        evaluator = { ...evaluator, photoUrl: anonAvatar };
    }

    return (
        <Styled.MatchEvaluator className={`${match.anonymous ? 'anonymous' : ''}`}>
            <UserAvatar user={evaluator} />
            <Styled.Infos>
                <span className="name">
                    {evaluator.fullName}
                    {/* {match.anonymous && (
                        <span className="ttip">
                            <i className="ttip-icon far fa-info-circle" aria-hidden="true" />
                            <i className="ttip-text">Este avaliador optou em manter-se anônimo.</i>
                        </span>
                    )} */}
                </span>

                {match.anonymous ? (
                    <i className="anonymous">Este avaliador optou em manter-se anônimo.</i>
                ) : (
                    <>
                        <span className="company">{match.company.name}</span>
                        <span className="position">{evaluator.position}</span>
                    </>
                )}
            </Styled.Infos>
        </Styled.MatchEvaluator>
    );
};
