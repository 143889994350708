export function hslFromNumber(h = 15, s = 85, l = 15) {
    return `hsl(${h}, ${s}%, ${l}%)`;
}
export function rgbFromNumber(r = 255, g = 255, b = 255) {
    return `rgb(${r}, ${g}, ${b})`;
}

export function positions(...p) {
    const [t, r, b, l] = p;
    return `
        ${t !== undefined ? `top:${t}` : ''}; 
        ${l !== undefined ? `left:${l}` : ''}; 
        ${r !== undefined ? `right:${r}` : ''}; 
        ${b !== undefined ? `bottom:${b}` : ''}; 
    `;
}

export function truncate(lines, lineHeight = 1.4) {
    const amount = `${lines * lineHeight}em`;
    return `
        display: -webkit-box;
        // height: ${amount || 'auto'};
        max-height: ${amount || 'auto'};
        overflow: hidden;
        word-wrap: break-word;
        text-overflow: ellipsis;
        -webkit-line-clamp: ${lines || 'auto'};
        -webkit-box-orient: vertical;
    `;
}
