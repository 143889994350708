import React from 'react';

import { Button } from 'modules/cxi/components/button';

import { useStartupCXI } from '../../useStartupCXI';
import { Container } from '../container';
import * as Styled from './StyledEvaluateSent';

export const EvaluateSent = () => {
    const { t, setModal } = useStartupCXI();

    return (
        <Styled.EvaluateSent as={Container.Slim}>
            <Container.Body>
                <Styled.Title>{t('modal.EvaluateSent.title')}</Styled.Title>
                <Styled.Descr>{t('modal.EvaluateSent.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.EvaluateSent>
    );
};
