import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNews } from './useNews';
import { NewsRoutes } from './NewsRoutes';

export function News(props) {
    const { t } = useTranslation('news');
    const hookd = useNews();
    return <NewsRoutes t={t} useNews={hookd} />;
}
export default News;
