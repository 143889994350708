import styled from 'styled-components';
import { positions } from 'utils/styles';

export const MatchAction = styled.button.attrs({
    className: 'c-match-action c-match-item'
})`
    background: ${({ theme }) => theme.colors.deepBlue};
    border: 0;
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.white};
    padding: 1rem 1.6rem;
    flex: 1 1 auto;
    font-size: 1.2rem;
    font-weight: 600;
    white-space: nowrap;

    .icon {
        font-size: 1.6rem;
        transform: translateY(2px);
    }

    &[disabled] {
        background: ${({ theme }) => theme.colors.lightGray} !important;
        color: ${({ theme }) => theme.colors.black} !important;
        opacity: 0.5;
    }

    &:not(.icon-only) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.icon-only {
        flex: 0 0 auto;
        min-height: 100%;
        padding: 0.8rem 0;
        min-width: 4rem;
    }
    &.icon-only:not(.options) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.secondary {
        background: ${({ theme }) => theme.colors.energyBlue};
        color: ${({ theme }) => theme.colors.white};
    }

    &.options {
        background: ${({ theme }) => theme.colors.whiteDarker};
        color: ${({ theme }) => theme.colors.gray};
        margin-left: 0.8rem;
    }
    &.options-extended {
        align-items: center;
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid #c3c6d0;
        color: ${({ theme }) => theme.colors.blue};
        display: flex;
        gap: 0.4rem;
    }
`;
export const MatchActionInline = styled(MatchAction)`
    //flex: 0 0 auto;
`;

export const MatchActions = styled.div.attrs({
    className: 'c-match-actions'
})`
    align-items: stretch;
    display: flex;
    flex-direction: row;
    gap: 0.1rem;
    width: 100%;

    &.stage-1 .action {
        background: ${({ theme }) => theme.colors.stage1};
        color: ${({ theme }) => theme.colors.white};
    }
    &.stage-1 .action.secondary {
        background: ${({ theme }) => theme.colors.purple};
        color: ${({ theme }) => theme.colors.white};
    }

    &.stage-2 .action {
        background: ${({ theme }) => theme.colors.stage2};
        color: ${({ theme }) => theme.colors.white};
    }

    &.stage-3 .action {
        background: ${({ theme }) => theme.colors.stage3};
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const MatchActionDropdown = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    position: relative;
`;

export const MatchActionDropdownList = styled.div.attrs(props => ({
    className: props.isOpen ? 'is-open' : ''
}))`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    color: ${({ theme }) => theme.colors.white};
    display: none;
    flex-direction: column;
    gap: 0.8rem;
    list-style: none;
    padding: 1.2rem;
    position: absolute;
    ${positions('100%', '-0.2rem', 'auto', 'auto')}
    min-width: 18.5rem;
    z-index: 900;

    &::before {
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.white} transparent;
        border-width: 0 6px 6px 6px;
        content: '';
        position: absolute;
        ${positions('-6px', '1rem', 'auto', 'auto')}
        transform: translateX(-50%);
    }

    &.is-open {
        display: flex;
        transform: translateX(0);
    }

    .action {
        border-radius: 0.4rem;
    }
    .s1.action {
        background: ${({ theme }) => theme.colors.stage1};
        color: ${({ theme }) => theme.colors.white};
    }
    .s1.action.secondary {
        background: ${({ theme }) => theme.colors.purple};
        color: ${({ theme }) => theme.colors.white};
    }
    .s2.action {
        background: ${({ theme }) => theme.colors.stage2};
        color: ${({ theme }) => theme.colors.white};
    }
    .s3.action {
        background: ${({ theme }) => theme.colors.stage3};
        color: ${({ theme }) => theme.colors.white};
    }
`;
