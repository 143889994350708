import React, { useMemo } from 'react';

import { RHP } from 'providers';
import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { useConversionTable } from 'hooks';
import * as Styled from './StyledInterests';
import { InterestsCard } from './InterestsCard';

export const Interests = () => {
    const { t, vars } = useCXI();
    const { profile } = vars.profile;

    const handleEdit = () => {
        RHP.push('/100-10-1/setup/1?edit');
    };

    const conv = useConversionTable([
        'challenge',
        'community',
        'industry',
        'innovationType',
        'interestArea',
        'interestInStartup',
        'stages',
        'trend',
    ]);

    const interests = useMemo(
        () => ({
            challenges: profile.sector.challenges.map(i => conv.challenge[i]),
            communities: profile.sector.communities.map(i => conv.community[i]),
            industries: profile.sector.industries.map(i => conv.industry[i]),
            innovationTypes: profile.innovationTypes.map(i => conv.innovationType[i]),
            interestAreas: profile.interestAreas.map(i => conv.interestArea[i]),
            interestInStartups: profile.interestInStartups.map(i => conv.interestInStartup[i]),
            stages: profile.stages.map(i => conv.stages[i]),
            regions: profile.regions.map(i => i.name),
            trends: profile.sector.trends.map(i => conv.trend[i]),
        }),
        [conv, profile]
    );

    return (
        <Styled.Interests>
            <Styled.InterestsHead>
                <Styled.Title>{t('profile.Interests.title')}</Styled.Title>
                <Styled.Button as={Button} variant="outline" onClick={handleEdit}>
                    <span className="icon far fa-pencil" aria-hidden="true" />
                    <span className="text">{t('profile.Interests.goToSetup')}</span>
                </Styled.Button>
            </Styled.InterestsHead>
            <Styled.InterestsBody>
                <InterestsCard tags={interests.challenges} step={1}>
                    {t('setup.Titles.challenges')}
                </InterestsCard>

                <InterestsCard tags={interests.industries} step={2}>
                    {t('setup.Titles.industries')}
                </InterestsCard>

                <InterestsCard tags={interests.trends} step={3}>
                    {t('setup.Titles.trends')}
                </InterestsCard>

                <InterestsCard tags={interests.communities} step={4}>
                    {t('setup.Titles.communities')}
                </InterestsCard>

                <InterestsCard tags={interests.stages} step={5}>
                    {t('setup.Titles.stages')}
                </InterestsCard>

                <InterestsCard tags={interests.regions} step={8}>
                    {t('setup.Titles.regions')}
                </InterestsCard>
            </Styled.InterestsBody>
        </Styled.Interests>
    );
};
