export const buildTimeslot = date => {
    const dd = String(date.get('date')).padStart(2, '0');
    const mm = String(date.get('month') + 1);
    const yyyy = String(date.get('year'));
    const HH = String(date.get('hour'));
    const min = String(date.get('minute')) === '0' ? '00' : String(date.get('minute'));

    return {
        dd,
        mm,
        yyyy,
        ddmm: `${dd}/${mm}`,
        HH,
        min,
        time: `${HH}:${min}`,
        isoString: date.toISOString(),
    };
};

export default buildTimeslot;
