import React from 'react';
import { formatDate } from 'utils/date';

import * as Styled from './StyledMatchReceived';

export const MatchReceived = ({ match }) => {
    const feedback = match.startupFeedback || '';

    const dated = formatDate(new Date(match.regDate), "dd/MM H'h'mm");
    const title = formatDate(new Date(match.regDate), "'Enviado dia' dd/MM/yyyy 'às' H'h'mm");

    return (
        <Styled.MatchReceived className={feedback ? 'feedback' : ''} title={title}>
            <span className="text">{dated}</span>
        </Styled.MatchReceived>
    );
};
