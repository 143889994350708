import React from 'react';

import { routes } from '../RankingRoutes';
import { RankingIndexView } from './RankingIndexView';

export const RankingIndex = props => {
    // Create the secondary actions options
    const actions = routes
        .filter(r => r.name)
        .map(r => ({
            icon: r.icon,
            text: props.t(`menu.${r.name}`),
            href: r.path,
        }));

    const propsView = { ...props, actions };
    return <RankingIndexView {...propsView} />;
};
export default RankingIndex;
