import React from 'react';

import { ListSubtitle } from './subtitle/ListSubtitle';
import { ListVertical } from './vertical/ListVertical';

import * as Styled from './StyledComponentList';

export function ComponentList(props) {
    // Get the properties necessary for the looping
    const { items, itemComponent } = props;

    // Check for empty results if listing is requested
    if (items !== undefined && (items.length <= 0 || !itemComponent)) {
        return <Styled.NoResults>{props.empty || 'Nothing found...'}</Styled.NoResults>;
    }

    // Decides witch list format to use
    const ListFormat = ListVertical;

    return (
        <Styled.ComponentList {...props}>
            {!!props.title && <ListSubtitle {...props.title} />}
            {!!props.items && <ListFormat {...props} />}
            {props.children}
        </Styled.ComponentList>
    );
}
export const List = ComponentList;
export default ComponentList;
