import packageInfo from '../../package.json';

export const CONFIG = {
    apiUrl: 'https://api.openstartups.net/app/v1',
    baseUrl: 'https://app.openstartups.net',
    appName: '100 Open Startups',

    // Nofitication firebase properties
    firebase: {
        apiKey: 'AIzaSyCK4sldX79_uVKSTV62-r07LscjHRZj_dE',
        authDomain: 'app-100os-dev.firebaseapp.com',
        databaseURL: 'https://app-100os-dev.firebaseio.com',
        projectId: 'app-100os-dev',
        storageBucket: 'app-100os-dev.appspot.com',
        messagingSenderId: '642909890893',
        appId: '1:642909890893:web:1da4e9d174f0748e39af04'
    },

    // Some informations for the configurations
    appPlatform: 'web',
    appVersion: packageInfo.version,
    preferredCountries: ['ar', 'br', 'ca', 'co', 'cl', 'in', 'mx', 'pe'],

    // Links to other apps from 100os
    appLinks: {
        events: 'https://event.openstartups.net',
        matchmaking: 'https://matchmaking.openstartups.net',
        startup: 'https://startup.openstartups.net',
        startupRanking: 'https://startupranking.openstartups.net'
    },

    // Data necessary for OMNIZE integration
    omnize: {
        token: 'gxutdYr4gqJELALNDx5RhUjvgrEH6z32',
        department_id: 16670
    },

    // Links for the app in the AppStores
    androidStore: 'https://play.google.com/store/apps/details?id=net.openstartups.app',
    iosStore: 'https://itunes.apple.com/us/app/100-open-startups/id1332822629?mt=8'
};
export default CONFIG;
