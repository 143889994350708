import React from 'react';
import { useTranslation } from 'react-i18next';

import { iframePush } from 'core/iframe/IframeLink';
import { CONFIG } from 'config';
import { StartupRoutes } from './StartupRoutes';
import { useStartup } from './useStartup';
import * as Styled from './StyledStartup';

export function Startup(props) {
    const { t } = useTranslation('startup');
    const hookd = useStartup();
    const { search } = window.location;
    if (search.includes('eventcode')) {
        iframePush(`${CONFIG.appLinks.startup}${search}`, '/home')();
        return null;
    }

    return !hookd.ready ? null : (
        <Styled.StartupModule>
            <StartupRoutes t={t} useStartup={hookd} />
        </Styled.StartupModule>
    );
}
export default Startup;
