import React from 'react';
import { useParams } from 'react-router';

import { UserAvatar } from 'core/components/user';
import { Header } from 'modules/cxi/components/header/Header';

import { useCXIDashboardUser } from './useCXIDashboardUser';
import { CXIDashboardUserContextProvider } from './CXIDashboardUserContext';
import { Evaluations } from './components/evaluations/Evaluations';

import * as Styled from './StyledCXIDashboardUser';

export const CXIDashboardUserModule = () => {
    const { t, vars } = useCXIDashboardUser();
    const { user, evaluations } = vars;

    const rank = user.rank ? `${user.rank}º` : 'N/A';
    const points = user.cxiStats?.metrics?.points ?? '0';

    let stage = '100';
    if (user.cxiStats.currentStage === 2) stage = '10';
    if (user.cxiStats.currentStage === 3) stage = '1';

    const [filter, setFilter] = React.useState('');

    const handleChange = e => {
        return setFilter(e.target.value);
    };

    const filtered = !filter
        ? evaluations
        : evaluations.filter(i => {
              let shouldShow = false;

              if (i.startup?.name.toLowerCase().includes(filter.toLowerCase())) shouldShow = true;
              if (i.comment?.toLowerCase().includes(filter.toLowerCase())) shouldShow = true;

              return shouldShow;
          });

    // const field = {
    //     className: 'c-header-input',
    //     placeholder: t('dashboard.user.search'),
    //     type: 'search',
    //     value: filter,
    //     onChange: handleChange,
    // };

    return (
        <Styled.CXIDashboardUserLayout>
            <Styled.CXIDashboardUser>
                <Header className="main" back="/100-10-1/dashboard/people" />

                <Styled.CXIDashboardUserCard>
                    <UserAvatar user={user} />
                    <Styled.Infos>
                        <Styled.Title>Painel de <span style={{ color: '#0094F9' }}>{`${user.firstName} ${user.lastName}`}</span></Styled.Title>
                        <Styled.Descr>{user.position}</Styled.Descr>
                    </Styled.Infos>

                    <Styled.Metric>
                        <Styled.MetricTitle>{t('dashboard.Users.rank')}</Styled.MetricTitle>
                        <Styled.MetricValue>{rank}</Styled.MetricValue>
                    </Styled.Metric>
                    <Styled.Metric>
                        <Styled.MetricTitle>{t('dashboard.Users.stage')}</Styled.MetricTitle>
                        <Styled.MetricValue>{stage}</Styled.MetricValue>
                    </Styled.Metric>
                    <Styled.Metric>
                        <Styled.MetricTitle>{t('dashboard.Users.points')}</Styled.MetricTitle>
                        <Styled.MetricValue>{points}</Styled.MetricValue>
                    </Styled.Metric>
                </Styled.CXIDashboardUserCard>

                {/* <Header className="filter">
                    <input name="search" {...field} />
                </Header> */}

                <Styled.CXIDashboardUserList>
                    <Evaluations evaluations={filtered} t={t} />
                </Styled.CXIDashboardUserList>
            </Styled.CXIDashboardUser>
        </Styled.CXIDashboardUserLayout>
    );
};

export const CXIDashboardUser = () => {
    const { userId } = useParams();
    return (
        <CXIDashboardUserContextProvider userId={userId}>
            <CXIDashboardUserModule />
        </CXIDashboardUserContextProvider>
    );
};
export default CXIDashboardUser;
