import React from 'react';

import * as Step from '../components/step';
import { Regions } from '../forms/Regions';

export const CXIStep8 = () => (
    <Step.Step currentStep={8}>
        <Regions />
    </Step.Step>
);
