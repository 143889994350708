import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './StyledFormControl';

export function FormControlLabel(props) {
    const { t } = useTranslation('glossary');
    const reqs = props.required || undefined;

    // Don't show this element if it is empty
    return !props.text ? null : (
        <Styled.FormControlLabel id={props.id} htmlFor={props.htmlFor}>
            <span className="text" dangerouslySetInnerHTML={{ __html: props.text }} />
            {!reqs && <span className="reqs">({t('optional')})</span>}
        </Styled.FormControlLabel>
    );
}
export const Label = FormControlLabel;
export default FormControlLabel;
