import * as Alert from 'components/alert';
import * as Form from 'components/form';
import { useForm } from 'components/form';
import * as Page from 'components/page';
import { ContextAuth as CA } from 'contexts';
import { cnpj } from 'cpf-cnpj-validator';
import { useApi, useApiLocation, useConversionOptions } from 'hooks';
import { RHP } from 'providers';
import React, { useContext, useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import { PocsCard } from '../components/pocs/PocsCard';
import * as Styled from './styles';

// EcosystemStep2 is equal to CorporateStep2

export function EcosystemStep2({ useRankingEcosystem, t }) {
    const { vars, updateSteps, putCompanyDetails } = useRankingEcosystem;
    const { ranking, companyDetails, companyPocs } = vars;

    const { state } = useContext(CA);

    const apiLocation = useApiLocation();
    const api = useApi();

    const [countries, setCountries] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [formDataChanged, setFormDataChanged] = useState(false);
    const [authorizedToEdit, setAuthorizedToEdit] = useState(false);

    const isCompanyValid = companyDetails?.valid;
    const corpHasSector = companyDetails?.companySector;

    const isOperational = state?.tokn?.pocs.includes('operational');
    const corpHasPocs = companyDetails?.hasPocs > 0;

    // Initialize a new form handler
    const form = useForm({
        ...vars.application,
        name: companyDetails?.name,
        awardName: companyDetails?.awardName,
        domains: companyDetails?.domains,
        logourl: companyDetails?.logourl,
        companySector: companyDetails?.companySector,
        companyType: companyDetails?.companyType,
        size: companyDetails?.size,
        taxId: companyDetails?.taxId,
        country: companyDetails?.country,
        isMiddleMarket: false,
        isPoc: false
    });

    useEffect(() => {
        const initialFormData = {
            ...vars.application,
            name: companyDetails?.name,
            awardName: companyDetails?.awardName,
            domains: companyDetails?.domains,
            logourl: companyDetails?.logourl,
            companySector: companyDetails?.companySector,
            companyType: companyDetails?.companyType,
            size: companyDetails?.size,
            taxId: companyDetails?.taxId,
            country: companyDetails?.country,
            isPoc: false
        };

        let formChanged = false;

        Object.keys(form.values).forEach(key => {
            if (form.values[key] !== initialFormData[key]) {
                formChanged = true;
            }
        });

        setFormDataChanged(formChanged);
    }, [form.values]);

    useEffect(() => {
        const handleBeforeUnload = event => {
            if (formDataChanged) {
                event.preventDefault();

                event.returnValue =
                    'Você tem alterações não salvas. Tem certeza de que deseja sair?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formDataChanged]);

    useEffect(() => {
        const run = async () => {
            if (companyDetails?.groupid || companyDetails?.groupId) {
                const groupId = companyDetails?.groupid || companyDetails?.groupId;
                const groupCompany = await api.get(`/companies/${groupId}`);

                setGroupName(groupCompany?.name || '');
            }
        };
        run();
    }, []);

    useEffect(() => {
        if (corpHasPocs) {
            if (isOperational) {
                setAuthorizedToEdit(true);
            } else {
                setAuthorizedToEdit(false);
            }
        } else if (!corpHasPocs) {
            setAuthorizedToEdit(false);
        }
    }, [isCompanyValid, authorizedToEdit, corpHasSector, corpHasPocs, isOperational]);

    useEffect(() => {
        apiLocation.geo('').then(res => {
            const names = res.map(country => {
                return { name: country.name, value: country.id };
            });
            setCountries(names);
        });
    }, []);

    const conv = useConversionOptions(['challenge', 'companySize', 'companySector', 'companyType']);

    const minDatePocs = new Date('2025-02-01');

    const isOutdatedPoc =
        !companyPocs.lastRequest ||
        !companyPocs.lastRequest.regDate ||
        new Date(companyPocs.lastRequest.regDate) < minDatePocs;

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step1 !== true) {
        RHP.replace('/ranking/latam/ecosystem/');
        return null;
    }

    const onBlur = {
        isValidCNPJ: ev => {
            const { name, value } = ev.target;
            // Clear the errors and warnings before validating
            form.clearMessages({ name });
            // Only check if the value is filled
            if (!value || form.values.country !== 'BR') return false;
            // Check if the CPF is a valid one
            const cleanValue = value.replace(/[^0-9]/g, '');

            if (!cnpj.isValid(cleanValue)) {
                // Emit error message for registered email
                const error = t('StartupStep2.warningTextCnpj');
                if (authorizedToEdit) {
                    return form.emitError({ name, error });
                }
                return false;
            }

            return true;
        }
    };

    const fields = {
        name: {
            error: form.errors.name,
            label: t('ranking:CorporateStep2.companyName'),
            name: 'name',
            type: 'text',
            value: form.values.name,
            onChange: form.handleChange,
            readOnly: !authorizedToEdit && companyDetails?.name
        },
        awardName: {
            error: form.errors.awardName,
            label: t('ranking:CorporateStep2.awardName'),
            name: 'awardName',
            type: 'text',
            value: form.values.awardName,
            onChange: form.handleChange,
            readOnly: !authorizedToEdit && companyDetails?.awardName
        },
        logourl: {
            error: form.errors.logourl,
            label: t('ranking:CorporateStep2.logoDescr'),
            name: 'logourl',
            type: 'CompanyLogo',
            value: form.values.logourl,
            onChange: form.handleChange,
            readOnly: !authorizedToEdit && companyDetails?.logourl
        },
        domains: {
            error: form.errors.domains,
            label: t('CorporateStep2.associatedDomainsTitle'),
            name: 'domains',
            type: 'text',
            value: form.values.domains,
            readOnly: true
        },
        companySector: {
            error: form.errors.companySector,
            label: `${t('ranking:CorporateStep2.sector')}`,
            name: 'companySector',
            type: 'select',
            options: [{ name: t('glossary:select'), value: '' }, ...conv.companySector],
            value: form.values.companySector,
            onChange: form.handleChange,
            readOnly: !authorizedToEdit && companyDetails?.companySector
        },
        taxId: {
            error: form.errors.taxId,
            label: t('glossary:cnpj'),
            name: 'taxId',
            type: 'generic',
            value: form.values.taxId,
            onBlur: ev => form.handleBlur(ev, onBlur.isValidCNPJ),
            onChange: form.handleChange,
            required: false,
            readOnly: !authorizedToEdit && companyDetails?.taxId
        },
        companyType: {
            error: form.errors.companyType,
            label: `${t('ranking:CorporateStep2.companyType')}`,
            name: 'companyType',
            type: 'select',
            options: [{ name: t('glossary:select'), value: '' }, ...conv.companyType],
            value: form.values.companyType,
            onChange: form.handleChange,
            readOnly: !authorizedToEdit && companyDetails?.companyType
        },
        size: {
            error: form.errors.size,
            label: t('glossary:companySize'),
            name: 'size',
            options: [{ name: t('glossary:select'), value: '' }, ...conv.companySize],
            type: 'select',
            value: form.values.size,
            onChange: form.handleChange,
            readOnly: !authorizedToEdit && companyDetails?.size
        },
        country: {
            error: form.errors.country,
            label: `${t('ranking:CorporateStep2.companyLocation')}`,
            name: 'country',
            type: 'select',
            options: [{ name: t('glossary:select'), value: countries.id }, ...countries],
            value: form.values.country,
            required: true,
            onChange: ev => {
                form.handleChange(ev);
                form.clearMessages({ name: 'taxId' });
            },
            readOnly: !authorizedToEdit && companyDetails?.country
        },
        isPoc: {
            error: form.errors.isPoc,
            label: t('CorporateStep2.pocPeople'),
            name: 'isPoc',
            type: 'checkbox',
            required: true,
            value: form.values.isPoc,
            onChange: form.handleChange
        }
    };

    if (form.values.country === 'BR') {
        fields.taxId.displayFormat = '00.000.000/0000-00';
        fields.taxId.maxLength = 18;
        fields.taxId.value = cnpj.format(form.values.taxId);
        fields.taxId.onChange = e => {
            e.target.value = cnpj.format(e.target.value);
            form.handleChange(e);
        };
    }

    // Handle form submitions
    const onSubmit = async values => {
        try {
            setFormDataChanged(false);

            const rankingPayload = {
                ...vars.application
            };

            // Set the current step to true;
            if (!rankingPayload.steps) {
                rankingPayload.steps = {};
            }

            rankingPayload.steps.step2 = true;

            const companyPayload = {
                name: values.name,
                awardName: values.awardName,
                // logourl: values.logourl,
                companySector: values.companySector,
                companyType: values.companyType,
                size: values.size,
                country: values.country !== 'Selecionar' ? values.country : '',
                taxId: values.taxId
            };

            const response = await putCompanyDetails(companyPayload);

            updateSteps({ step2: true });

            if (companyDetails?.hasPocs === 0) {
                RHP.push('/ranking/latam/ecosystem/editPocs');
            } else {
                RHP.push('/ranking/latam/ecosystem/step3');
            }

            return response;
        } catch (err) {
            console.error(err);
        } finally {
            form.submited();
        }
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    const propsOutdatedPocs = {
        descr: t('CorporateStep2.confirmPocs'),
        icons: 'far fa-info-circle',
        bgColor: '#FDFD96',
        type: 'warning'
    };

    return (
        <Page.Page
            name="EcosystemStep2"
            back="/ranking/latam/ecosystem"
            customBackText={t('glossary:backToRanking')}
        >
            <Prompt
                when={formDataChanged}
                message="Você tem alterações não salvas. Tem certeza de que deseja sair?"
            />
            <Page.Title>{t('EcosystemStep4.title')}</Page.Title>
            <Page.Descr>{t('CorporateStep2.descr', { rankingYear: ranking.year })}</Page.Descr>

            <Form.Form name="CorporateStep2Form" onSubmit={evSubmit}>
                <Form.Field {...fields.name} />

                {/* <Styled.LogoUploadContainer>
                    <Form.Field {...fields.logourl} style={{ flex: 0 }} />
                    <span>{t('CorporateStep2.uploadImage')}</span>
                </Styled.LogoUploadContainer> */}

                {/* <Form.Field {...fields.domains} />
                <Page.Descr style={{ fontSize: 'small', opacity: 0.7 , marginTop: '0.5rem' }}>
                    {t('CorporateStep2.associatedDomainsDescription')}
                </Page.Descr> */}

                {companyDetails?.domains?.length && (
                    <Styled.AssociatedDomain>
                        <h3>{t('CorporateStep2.associatedDomainsTitle')}</h3>
                        <div>
                            {companyDetails?.domains.map(domain => (
                                <p key={domain}>{domain}</p>
                            ))}
                        </div>
                        <span
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: t('CorporateStep2.associatedDomainsDescription')
                            }}
                        />
                    </Styled.AssociatedDomain>
                )}

                <Form.Field {...fields.awardName} />
                <Form.Field {...fields.companySector} />
                <Form.Field {...fields.companyType} />
                <Form.Field {...fields.country} />
                <Form.Field {...fields.taxId} />
                <Form.Field {...fields.size} />
                <br />

                {groupName && (
                    <p style={{ fontWeight: 'bold' }}>
                        {t('ranking:CorporateStep2.groupName')}
                        {groupName}
                    </p>
                )}

                {corpHasPocs && (
                    <div>
                        <Page.Descr style={{ fontWeight: 'bold' }}>
                            {t('CorporateStep2.pocTitle')}
                        </Page.Descr>

                        {companyPocs.pocs
                            .filter(e => e.pocs.includes('operational'))
                            .map(poc => (
                                <PocsCard
                                    key={poc._id}
                                    data={poc}
                                    operationalOnly
                                    useRankingEcosystem={useRankingEcosystem}
                                />
                            ))}

                        {isOutdatedPoc ? (
                            <Alert.AlertRow style={{ marginTop: 15 }} {...propsOutdatedPocs} />
                        ) : (
                            <p
                                style={{ marginTop: 15 }}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: t('CorporateStep2.editPocs')
                                }}
                            />
                        )}
                    </div>
                )}

                {companyDetails.hasPocs === 0 && (
                    <Page.Descr>{t('CorporateStep2.addPocs')}</Page.Descr>
                )}

                <div style={{ display: 'flex', gap: '10px' }}>
                    <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/ecosystem/step1">
                        <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                        <span className="text" style={{ margin: '0 0 0 auto' }}>
                            {t('glossary:back')}
                        </span>
                    </Page.Buttn>
                    <div style={{ width: '80%' }}>
                        <Form.Submt paddingTop="10rem" {...form} text={t('glossary:save')} />
                    </div>
                </div>
            </Form.Form>
        </Page.Page>
    );
}
