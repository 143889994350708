import React from 'react';

import * as Form from 'components/form';

import * as Styled from './StyledGrowingBusinessData';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {}
};

export const GrowingBusinessData = props => {
    const { form } = props;
    const actualYear = new Date().getFullYear();

    const financial = form.values.financial || [];
    let i = financial.findIndex(
        elem =>
            elem.year === props.year &&
            ((elem.year === actualYear && elem.isForecast) ||
                (elem.year !== actualYear && !elem.isForecast))
    );

    const onChange = ev => {
        const event = fakeEvent;
        event.target.name = 'financial';
        const { name, value } = ev.target;
        const newName = name.replace(`${props.year}-`, '');

        if (i < 0) {
            i = financial.length;
            financial[i] = { year: props.year };
        }

        if (newName === 'revenues') {
            financial[i] = {
                ...financial[i],
                revenues: Number(value.amount),
                currency: value.currency
            };
        }
        if (newName === 'employees') {
            financial[i] = { ...financial[i], employees: Number(value) };
        }

        financial[i] = { ...financial[i], isForecast: props.year === actualYear };

        event.target.value = financial;
        return form.handleChange(event);
    };

    const fields = {
        employees: {
            error: form.errors.financial?.[i].employees,
            label: '# de colaboradores',
            name: `${props.year}-employees`,
            type: 'generic',
            inputType: 'number',
            value: form.values.financial?.[i]?.employees,
            required: false,
            onChange,
            disabled: props.disabled
        },
        revenues: {
            error: form.errors.financial?.[i].revenues,
            name: `${props.year}-revenues`,
            type: 'CurrencyAmount',
            label: props.year === actualYear ? 'Previsão de Receita' : 'Receita',
            value: {
                amount: form.values.financial?.[i]?.revenues,
                currency: form.values.financial?.[i]?.currency
            },
            onChange,
            disabled: props.disabled
        },
        empty: {
            name: 'label-empty',
            label: props.year,
            type: 'empty'
        }
    };

    return (
        <Styled.GrowingBusinessData>
            <Form.Field size="12" {...fields.empty} />
            <Form.Field size="auto" {...fields.employees} />
            <Form.Field size="auto" {...fields.revenues} />
        </Styled.GrowingBusinessData>
    );
};

export default GrowingBusinessData;
