import React from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';

export function ProfilePasswordForm(props) {
    const { form, onChange, onSubmit } = props;

    // Create form fields object
    const fields = {
        lastpassword: {
            autoComplete: 'current-password',
            error: form.errors.lastpassword,
            label: props.t('glossary:lastpassword'),
            name: 'lastpassword',
            type: 'password',
            value: form.values.lastpassword,
            onChange: form.handleChange,
        },
        newpassword: {
            autoComplete: 'new-password',
            error: form.errors.newpassword,
            label: props.t('glossary:newpassword'),
            name: 'newpassword',
            type: 'password',
            value: form.values.newpassword,
            warning: form.warnings.newpassword,
            onChange: ev => form.handleChange(ev, onChange.newpassword),
        },
        newpasswordCheck: {
            autoComplete: 'new-password',
            error: form.errors.newpasswordCheck,
            label: props.t('glossary:newpasswordCheck'),
            name: 'newpasswordCheck',
            type: 'password',
            value: form.values.newpasswordCheck,
            onChange: ev => form.handleChange(ev, onChange.newpasswordCheck),
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="ProfilePasswordForm" onSubmit={evSubmit}>
            <Form.Field {...fields.lastpassword} />

            <Page.Ruler />
            <Page.Texts>{props.t('ProfilePassword.emailExplanation')}</Page.Texts>

            <Form.Field {...fields.newpassword} />
            <Form.Field {...fields.newpasswordCheck} />
            <Form.Submt {...form} text={props.t('glossary:save')} />
        </Form.Form>
    );
}
export default ProfilePasswordForm;
