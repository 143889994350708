import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from '../field/FormField';

export function FormCustomYesNo(props) {
    const { t } = useTranslation('glossary');
    const propsField = {
        ...props,
        options: [
            { name: t('yes'), value: 'true' },
            { name: t('no'), value: 'false' },
        ],
        value: `${props.value}`,
        type: 'radio',
    };
    return <FormField {...propsField} />;
}
export const YesNo = FormCustomYesNo;
export default FormCustomYesNo;
