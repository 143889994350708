import React from 'react';

import { useConversionTable } from 'hooks';

import * as Styled from './StyledCXIChallengeStartupGeneral';

export const CXIChallengeStartupGeneralDetails = props => {
    const { startup, t } = props;
    const conv = useConversionTable(['businessType', 'challenge', 'team', 'yesNo']);
    const businessTypes = startup?.businessTypes?.map(c => conv.businessType[c]);

    return (
        <Styled.CXIChallengeStartupGeneralDetails>
            {!!startup?.mainChallenge && (
                <>
                    <dt>{t('startup.mainChallenge')}</dt>
                    <dd>{conv.challenge[startup?.mainChallenge]}</dd>
                </>
            )}

            {!!startup?.businessTypes?.length && (
                <>
                    <dt>{t('startup.businessTypes')}</dt>
                    <dd>{businessTypes.join(', ')}</dd>
                </>
            )}

            {!!startup?.demographics?.status?.founding && (
                <>
                    <dt>{t('startup.foundation')}</dt>
                    <dd>{startup?.demographics.status.founding}</dd>
                </>
            )}

            {!!startup?.entrepreneur?.name && (
                <>
                    <dt>{t('startup.entrepreneur')}</dt>
                    <dd>{startup?.entrepreneur?.name}</dd>
                </>
            )}

            {!!startup?.demographics?.entrepreneur?.fullTime && (
                <>
                    <dt>{t('startup.fulltime')}</dt>
                    <dd>{conv.yesNo[!!startup?.demographics.entrepreneur.fullTime]}</dd>
                </>
            )}

            {!!startup?.demographics?.status?.team && (
                <>
                    <dt>{t('startup.employees')}</dt>
                    <dd>{conv.team[startup?.demographics.status.team]}</dd>
                </>
            )}
        </Styled.CXIChallengeStartupGeneralDetails>
    );
};
