import styled from 'styled-components';

export const FieldError = styled.span.attrs({
    className: 'field-error'
})`
    display: block;
    margin-top: 0.5rem;
    color: red;
`;

export const LanguageText = styled.span`
    display: block;
    margin-top: 1rem;
    color: #0094f9;
    cursor: pointer;
`;

export const ProgramLabel = styled.label`
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0 0 0.5rem;
    width: 100%;
`;
