import styled from 'styled-components';

import background from 'assets/images/bg-cxi-startup.png';

import { positions, truncate } from 'utils/styles';

export const CXIDashboardUser = styled.div.attrs({
    className: 'container'
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header.main,
    .c-header.main .c-header-head {
        background: transparent;
        padding: 0;

        .c-header-back {
            color: #fff;
            font-size: 2.4rem;
        }
        .c-header-bttn {
            color: #fff;
            font-size: 1.4rem;
            opacity: 0.8;
        }
    }

    .c-header-input {
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
        border-radius: 0.8rem;
        padding: 0.8rem 1.4rem;
        width: 100%;
    }
`;
export const CXIDashboardUserLayout = styled.div`
    min-height: 100%;
    position: relative;

    &::before {
        background: transparent no-repeat bottom center;
        background-image: url(${background});
        background-size: cover;
        content: ' ';
        height: 15rem;
        position: absolute;
        width: 100%;
        z-index: -1;
    }
`;

export const CXIDashboardUserCard = styled.div`
    align-items: center;
    background: #fff;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 1.6rem;
    padding: 2.4rem;
    position: sticky;
    text-align: center;

    @media (min-width: 992px) {
        flex-direction: row;
        gap: 2.4rem;
        text-align: start;
    }

    .user-avatar {
        flex: 0 0 6.4rem;
        width: 6.4rem;
    }

    & ~ .c-header.filter {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
    }
    & ~ .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
    }
    & ~ .c-header.filter + .c-header.filter {
        margin-top: 0.4rem;
    }
`;

export const CXIDashboardUserList = styled.div`
    margin-top: 2.4rem;
    @media (min-width: 768px) {
        margin-top: 3.2rem;
    }
`;

export const Infos = styled.div`
    flex: 1 1 auto;
`;

export const Title = styled.span`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.3;
    ${truncate(2, 1.3)}
`;

export const Descr = styled.span`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.3;
    opacity: 0.75;
    ${truncate(1, 1.3)}
`;

export const Metric = styled.div`
    align-items: center;
    border: 1px solid #f0f0f0;
    border-radius: 0.8rem;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;

    @media (min-width: 992px) {
        flex-direction: column;
        min-width: 120px;
        text-align: center;
        width: auto;
    }
`;
export const MetricTitle = styled.div`
    color: ${({ theme }) => theme.colors.blue};
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.3;
    ${truncate(1, 1.3)}
`;
export const MetricValue = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.3;
    ${truncate(1, 1.3)}
`;
