import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Option = styled.button`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 6px;
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.6rem;

    .circle {
        align-items: center;
        border: 2px solid ${({ theme }) => theme.colors.lightGray};
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.energyBlue};
        display: flex;
        height: 6.4rem;
        font-size: 3.2rem;
        justify-content: center;
        position: relative;
        width: 6.4rem;

        .chck {
            background: ${({ theme }) => theme.colors.white};
            border: 2px solid ${({ theme }) => theme.colors.white};
            border-radius: 50%;
            color: ${({ theme }) => theme.colors.darkBlue};
            font-size: 1.6rem;
            line-height: 1;
            position: absolute;
            ${positions(0, 0, 'auto', 'auto')}

            &::before {
                display: inline-block;
                transform: translate(0, 1px);
            }
        }

        .text {
            font-weight: 700;
        }
    }

    .label {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.2;
    }

    &:focus,
    &:hover {
        background: #f7f9fc;
        border-color: ${({ theme }) => theme.colors.energyBlue};

        .circle {
            border-color: ${({ theme }) => theme.colors.energyBlue};
        }
    }

    &.checked {
        background: #f7f9fc;
        border-color: ${({ theme }) => theme.colors.green};

        .circle {
            background: ${({ theme }) => theme.colors.green};
            border-color: ${({ theme }) => theme.colors.green};
            color: ${({ theme }) => theme.colors.white};
        }
        .circle .icon {
            font-weight: 900;
        }
    }
`;
