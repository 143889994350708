import React from 'react';
import { useTranslation } from 'react-i18next';

import { HomeRoutes } from './HomeRoutes';

export function Home(props) {
    const { t } = useTranslation('home');
    return <HomeRoutes t={t} />;
}
export default Home;
