import styled from 'styled-components';

export const CXICertificationIndex = styled.div`
    background: ${({ theme }) => theme.colors.white};
    /*padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));*/
    overflow-y: scroll;
`;

export const CXICertificationIndexHead = styled.div`
    background: rgb(240, 240, 240);
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGray};
    height: 8rem;
    max-height: 8rem;
    padding: 1.6rem 2.4rem;
    text-align: center;

    img {
        height: 100%;
    }
`;

export const CXICertificationIndexFooter = styled.div.attrs({ className: 'container' })`
    padding: 1.6rem 2.4rem;
`;

export const CXICertificationIndexBody = styled.div.attrs({ className: 'container' })`
    padding: 2.4rem;
    @media print {
        padding: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        @page { size: landscape; }
    }
`;

export const Header = styled.div`
    align-items: center;
    display: flex;
`;

export const FooterTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 1.6rem 0;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.deepBlue};
    font-weight: 500;
    font-size: 22px;
    margin: 0;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button {
        margin-left:0;
    }
`;

export const Button = styled.button`
    display: none;
    margin-left: auto;

    border-color: ${({ theme }) => theme.colors.deepBlue};

    @media (min-width: 480px) {
        display: inline-flex;
    }
`;
