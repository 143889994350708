import styled from 'styled-components';
import { positions } from 'utils/styles';

export const FormControlAlert = styled.label.attrs(props => ({
    className: `form-control-alert alert alert-${props.type ? props.type : 'primary'}`,
}))`
    display: block;
    font-size: 1.4rem;
`;

export const FormControlHelper = styled.label.attrs({
    className: `form-control-helper`,
})`
    color: #666;
    display: block;
    font-size: 1.2rem;
    margin: 1rem 0 0;

    .form-control-label + & {
        margin: -0.5rem 0 1rem;
    }
`;

export const FormControlIcons = styled.label.attrs({
    className: 'form-control-icons',
})`
    color: #999;
    font-size: 2.4rem;
    position: absolute;
    ${positions('50%', '1rem', 'auto', 'auto')}
    transform: translateY(-50%);
`;

export const FormControlLabel = styled.label.attrs({
    className: 'form-control-label',
})`
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0 0 0.5rem;
    width: 100%;

    .reqs {
        color: #999;
        font-size: 0.85em;
        margin-left: auto;
    }
`;

export const FormControlTopLabel = styled.label.attrs({
    className: 'form-control-top-label',
})``;
