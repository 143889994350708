import React from 'react';

import { LocalStorageProvider as LSP } from 'providers';

const initial = {
    alert: '',
    alertHeader: '',
    toast: '',

    // Stores the preffered language variable
    language: LSP.get('100os-language') || 'en',

    // Stores the user latest location
    location: LSP.get('100os-location') || {},
    geolocation: LSP.get('100os-geolocation') || {},

    // Oiweek
    oiweek: 0,

    // Controls some structural variables
    menuOpen: false,
    menuShow: true
};

const contextReducer = (state, action) => {
    const reduce = {
        // Alerts and Toast for error/warnings/etc
        ALERT: () => ({
            ...state,
            alert: action.alert,
            alertHeader: action.alertHeader || undefined
        }),
        TOAST: () => ({ ...state, toast: action.toast }),

        // Language reducers
        LANGUAGE: () => {
            LSP.set('100os-language', action.language);
            return { ...state, language: action.language };
        },

        // Location reducers
        LOCATION: () => {
            LSP.set('100os-location', action.location);
            return { ...state, location: action.location };
        },
        GEOLOCATION: () => {
            LSP.set('100os-geolocation', action.geolocation);
            return { ...state, location: action.geolocation };
        },

        OIWEEK: () => {
            return { ...state, oiweek: action.oiweek };
        },

        // Structural reducers
        MENU_OPEN: () => {
            return { ...state, menuOpen: action.menu };
        },
        MENU_SHOW: () => {
            return { ...state, menuShow: action.menu };
        }
    }[action.type];
    return (reduce && reduce()) || state;
};

// Create the proper context variables
export const ContextGlobal = React.createContext();
export const ContextGlobalProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(contextReducer, initial);
    const stateValue = {
        state,
        dispatch,
        globalState: state,
        globalDispatch: dispatch,
        // Shortcut dispatchers for alerts and toasts
        emitAlert: React.useCallback(msg => {
            if (typeof msg === 'string') {
                return dispatch({ type: 'ALERT', alert: msg });
            }
            const { header, message } = msg;
            return dispatch({ type: 'ALERT', alert: message, alertHeader: header });
        }, []),
        emitToast: msg => dispatch({ type: 'TOAST', toast: msg })
    };
    return <ContextGlobal.Provider value={stateValue}>{children}</ContextGlobal.Provider>;
};

// Create some shortcuts to export
export const CG = ContextGlobal;
export const CGP = ContextGlobalProvider;
