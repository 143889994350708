import { useEffectOnce } from 'react-use';
import { useCXI } from 'modules/cxi/useCXI';

export const Feedback = () => {
    const { modal, setModal } = useCXI();
    const { match } = modal;

    useEffectOnce(() => {
        setModal({ match, modal: 'FeedbackForm' });
    }, []);

    return null;
};
