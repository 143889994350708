import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { RegisterProfileForm } from './RegisterProfileForm';

export function RegisterProfile(props) {
    const conv = useConversionOptions(['companySize']);
    const { vars, putUser } = props.useRegister;

    // Initialize a new form handler
    const form = useForm({ ...vars });

    // Handle form submition
    const onSubmit = values => {
        const { position, department } = values;
        // Build the company variable
        const company = {
            name: values.companyName,
            size: values.companySize,
        };
        // Do the submition api request
        return putUser({ company, position, department })
            .then(() => RHP.push('/register/finished'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="RegisterProfile">
            <Page.Title>{props.t('RegisterProfile.title')}</Page.Title>
            <Page.Descr>{props.t('RegisterProfile.descr')}</Page.Descr>

            <RegisterProfileForm {...propsForm} />
        </Page.Page>
    );
}
export default RegisterProfile;
