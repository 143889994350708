import styled from 'styled-components';

export const ProgressBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

export const ProgressItem = styled.div`
    display: flex;
    height: 10px;
    width: 10px;
    background-color: #afeaf2;
    border-radius: 100px;

    &.active {
        background-color: #56c1d6;
        width: 140px;
    }
`;
