import React from 'react';
import { ResponsiveContainer, Line, LineChart, XAxis, YAxis, Tooltip } from 'recharts';
import * as Styled from './StyledSessions';

function LeftSession(props) {
    const { data } = props;

    const formatDataForXAxis = chartData => {
        const d = [...chartData];
        const datesAlreadyListed = [];
        for (let i = 0; i < d.length; i++) {
            const year = d[i].date.split('-')[0];
            // if (!datesAlreadyListed.includes(year)) {
            //     datesAlreadyListed.push(year);
            //     d[i].year = year;
            // }
            d[i].year = year;
            datesAlreadyListed.push(year);
        }
        return d;
    };

    return (
        <Styled.LeftSession>
            <Styled.MainLeftContainer>
                <Styled.LeftContainer>
                    <Styled.Title>
                        <h1>{data?.speedDatingMeetingsTotal || 0}</h1>
                        <h2>Speed-dating de executivos</h2>
                    </Styled.Title>
                    <Styled.LeftGraphicsContainer>
                        <div className="chartDiv">
                            <ResponsiveContainer width="100%" height="98%">
                                <LineChart
                                    data={
                                        data.challengesChartData.length > 0
                                            ? formatDataForXAxis(data.speedDatingMeetingsChartData)
                                            : null
                                    }
                                    margin={{
                                        top: 15,
                                        right: 40,
                                        left: -10,
                                        bottom: 15
                                    }}
                                >
                                    <XAxis dataKey="year" tick />
                                    <YAxis />
                                    <Tooltip />
                                    <Line
                                        type="monotone"
                                        dataKey="meetings"
                                        stroke="#3FA9F5"
                                        activeDot={false}
                                        dot={false}
                                        strokeWidth={4}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </Styled.LeftGraphicsContainer>
                </Styled.LeftContainer>
                <Styled.LeftContainer>
                    <Styled.Title>
                        <h1>{data.challengesTotal || 0}</h1>
                        <h2>Desafios lançados</h2>
                    </Styled.Title>
                    <Styled.LeftGraphicsContainer>
                        <div className="chartDiv">
                            <ResponsiveContainer width="100%" height="98%">
                                <LineChart
                                    data={
                                        data.challengesChartData.length > 0
                                            ? formatDataForXAxis(data.challengesChartData)
                                            : null
                                    }
                                    margin={{
                                        top: 15,
                                        right: 40,
                                        left: -10,
                                        bottom: 15
                                    }}
                                >
                                    <XAxis dataKey="year" tick />
                                    <YAxis />
                                    <Tooltip />
                                    <Line
                                        type="monotone"
                                        dataKey="challenges"
                                        stroke="#3FA9F5"
                                        activeDot={false}
                                        dot={false}
                                        strokeWidth={4}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </Styled.LeftGraphicsContainer>
                </Styled.LeftContainer>
            </Styled.MainLeftContainer>
        </Styled.LeftSession>
    );
}

export default LeftSession;
