import React from 'react';
import * as Styled from './StyledButton';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    size: Styled.Size;
    variant: Styled.Variant;
}

export const Button = ({
    children,
    variant = 'default',
    size = 'medium',
    ...rest
}: IButtonProps) => {
    return (
        <Styled.Button size={size} variant={variant} type="button" {...rest}>
            {children}
        </Styled.Button>
    );
};

export default Button;
