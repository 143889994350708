export const LocalStorageProvider = {
    // Delete an item form the localStorage
    del: key => {
        localStorage.removeItem(key);
        return undefined;
    },

    // Get the value from localStorage
    get: key => {
        const value = JSON.parse(localStorage.getItem(key));
        return value || undefined;
    },

    // Set the value to the localStorage
    set: (key, value) => {
        // Remove the item if the valu is 'null' or 'undefined'
        // Also check if the value is an empty object
        if (
            value === null ||
            value === undefined ||
            (value.constructor === Object && Object.entries(value).length === 0)
        ) {
            localStorage.removeItem(key);
            return undefined;
        }
        // All good? Then save the item
        localStorage.setItem(key, JSON.stringify(value));
        return value;
    },
};
export const LSP = LocalStorageProvider;
export default LocalStorageProvider;
