import { UserAvatar } from 'core/components/user';
import React from 'react';

import useCXI from 'modules/cxi/useCXI';
import * as Styled from './StyledRankingItem';

export const RankingItemUser = ({ data }) => {
    const { t } = useCXI();
    return (
        <Styled.RankingItem className={`rank-${data.rank} user`}>
            <Styled.Title>{t('position')}</Styled.Title>
            <Styled.Avatar>
                <UserAvatar user={data} />
                <span className="rank">{`${data.rank ?? 'N/A'}º`}</span>
            </Styled.Avatar>

            <Styled.Name>{`${data.firstName} ${data.lastName}`}</Styled.Name>
            <Styled.Desc>{data.position || '-'}</Styled.Desc>

            <Styled.Score>
                {data.score || 0} {t('points')}
            </Styled.Score>
        </Styled.RankingItem>
    );
};
