import styled from 'styled-components';

export const Support = styled.span.attrs({
    classname: 'support',
})`
    display: block;
    margin-top: 2rem;
`;

export const ContractTypesModal = styled.div.attrs({
    classname: 'contract-types-modal',
})`
    padding: 0 1.6rem;
`;

export const GroupTitle = styled.h3.attrs({
    classname: 'group-title',
})`
    margin: 1rem 0;
    color: var(--primary);
`;

export const GroupSubtitle = styled.li.attrs({
    classname: 'group-subtitle',
})`
    font-weight: bold;
    font-size: 1.6rem;
    margin: 0.5rem 0;
`;

export const SubtitleParag = styled.p.attrs({
    classname: 'subtitle-parag',
})`
    margin: 0.25rem 0;
`;

export const StyledFields = styled.div.attrs({
    className: 'styled-fields',
})`
    .ico-btn {
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
    }
`;

export const FieldError = styled.span.attrs({
    className: 'field-error',
})`
    display: block;
    margin-top: 0.5rem;
    color: red;
`;

export const LanguageText = styled.span`
    margin-top: 1rem;
    color: #0094f9;
    cursor: pointer;
`;
