import styled from 'styled-components';

export const OiweekPayPerUse = styled.div.attrs({
    className: 'oiweek-payperuse',
})`
    .component-page-container {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const OiweekPayPerUseForm = styled.div.attrs({
    className: 'oiweek-payperuse-form',
})`
    background: #fff;
    color: #444;
    flex: 1 1 auto;
    margin-top: 3rem;
    position: relative;

    &::before {
        background: inherit;
        content: '';
        position: absolute;
        top: 0;
        bottom: calc(-5.9rem - var(--ion-safe-area-bottom));
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
    }

    & > * {
        position: relative;
    }

    .c-page-texts {
        font-weight: bold;
    }
    .c-page-boxed {
        height: 150px;
        margin-top: 1rem;
    }
`;
