import React from 'react';
import { useEffectOnce } from 'react-use';

import { ContextAuth as CA } from 'contexts';
import { useApi } from 'hooks';
import { LSP } from 'providers';
import * as List from 'components/list';

import { HomeListBannersItem } from './HomeListBannersItem';
import * as Styled from './StyledHomeListBanners';

export function HomeListBanners() {
    const { user } = React.useContext(CA).state;
    const api = useApi();

    const params = `${user ? `userid=${user.userid}` : ''}`;
    const initialState = LSP.get(`100os/banners?${params}`) || [];

    // Use the state do handle the api request
    const [banners, setBanners] = React.useState(initialState);

    useEffectOnce(() => {
        api.get(`/banners?${params}`).then(res => {
            LSP.set(`100os/banners?${params}`, res);
            setBanners(res);
        });
    });

    return !banners.length ? null : (
        <Styled.HomeListBanners as={List.List} name="HomeListBanners">
            <List.Vertical items={banners} itemKey="_id" itemComponent={HomeListBannersItem} />
        </Styled.HomeListBanners>
    );
}
export default HomeListBanners;
