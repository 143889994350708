import styled from 'styled-components';

export const Forums = styled.div`
    position: relative;
`;

export const ForumsHead = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: space-between;

    a {
        color: ${({ theme }) => theme.colors.deepBlue};
        font-size: 1.4rem;
        font-weight: 600;
    }
    h2 {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 22px;
        margin: 0;
    }
`;

export const ForumsInfo = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 2.4rem;

    h3 {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }
`;

export const ForumsBody = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 2.4rem;
    gap: 3.2rem;

    @media (min-width: 768px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (min-width: 1200px) {
        .cxi-layout:not(.is-open) & {
            gap: 1.2rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (min-width: 1400px) {
        .is-open & {
            gap: 1.2rem;
            grid-template-columns: repeat(3, 1fr);
        }
    }
`;
