import React from 'react';
import { useTranslation } from 'react-i18next';

import { RankingEcosystemRoutes } from './RankingEcosystemRoutes';
import { useRankingEcosystem } from './useRankingEcosystem';

export function RankingEcosystem(props) {
    const { t } = useTranslation('ranking');
    const hookd = useRankingEcosystem();
    return !hookd.ready ? null : <RankingEcosystemRoutes t={t} useRankingEcosystem={hookd} />;
}
export default RankingEcosystem;
