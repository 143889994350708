import styled from 'styled-components';
import { positions } from 'utils/styles';

export const CXIModal = styled.div`
    overflow-y: auto;
    position: fixed;
    ${positions(0, 0, 0, 0)}
    z-index: 700;
`;

export const CXIModalBackdrop = styled.button.attrs({
    'aria-hidden': true
})`
    background: rgba(0, 0, 0, 0.8);
    border: 0;
    position: fixed;
    ${positions(0, 0, 0, 0)}
    z-index: 999991;
`;

export const CXIModalContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;

    padding: 1.6rem;
    padding-top: calc(1.6rem + var(--ion-safe-area-top));
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;
    z-index: 999999;
    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-top: calc(3.2rem + var(--ion-safe-area-top));
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }
`;

export const CXIModalError = styled.div`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    padding: 3.2rem !important;
    position: relative;
`;

export const CXIModalErrorDescr = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.8rem;
    margin-top: 0.8rem;
    text-align: center;
`;

export const CXIModalErrorTitle = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
`;
