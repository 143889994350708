import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIG } from 'config';
import * as Alert from 'components/alert';
import { iframeLink } from 'core/iframe/IframeLink';
import { useLocation } from 'react-router';

export function RegisterStartup() {
    const { t } = useTranslation('subscription');
    const location = useLocation();

    const href = iframeLink(`${CONFIG.appLinks.startup}`, location.pathname);

    // Create the alert element props
    const propsAlert = {
        // Show texts informations
        title: t('RegisterStartup.title'),
        descr: t('RegisterStartup.descr'),
        // Build the button
        buttn: {
            href,
            text: t('RegisterStartup.button'),
        },
        // Setup color and presentation
        type: 'info',
    };
    return <Alert.Alert {...propsAlert} />;
}
export default RegisterStartup;
