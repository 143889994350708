import React from 'react';

import { PageSubtitle } from 'components/page';
import * as Styled from './StyledTableSubtitle';

export const TableSubtitle = props => {
    return <Styled.TableSubtitle as={PageSubtitle} {...props} />;
};
export const Subtitle = TableSubtitle;
export default TableSubtitle;
