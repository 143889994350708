import React from 'react';

// import { LSP } from 'providers';
import { useCXI } from 'modules/cxi/useCXI';

import * as Styled from './StyledChecklistFixed';

export const ChecklistFixed = () => {
    const { t, vars } = useCXI();
    const { cxi } = vars;
    const data = cxi.metrics;

    const item1 = Number(data.matches || 0);
    const started1 = item1 > 0 ? 'started' : '';

    // const item2 = (LSP.get('100os-contents') || []).length;

    return (
        <Styled.ChecklistFixed>
            <Styled.ChecklistTitle>Seu progresso em conhecer 100 startups:</Styled.ChecklistTitle>

            <Styled.ChecklistItems>
                <Styled.ChecklistItem className={item1 >= 100 ? 'checked' : undefined || started1}>
                    <span className="text">{t('home.ChecklistFixed.step1')}</span>
                    <span className="numb">{`${String(item1).padStart(2, '0')} / 100`}</span>
                </Styled.ChecklistItem>
            </Styled.ChecklistItems>
        </Styled.ChecklistFixed>
    );
};
