import React from 'react';
import { useEffectOnce } from 'react-use';

import { ContextAuth as CA } from 'contexts';
import { useApi } from 'hooks';
import { RHP } from 'providers';

const investorTypes = {
    investorType: '',
    isQualifiedInvestor: false,
    isRegistered: false,
    hasInvestedInThePast: false,
    howCanContribute: [],
    howLearnedAbout: [],
    howMuchInvestment: {
        currency: 'BRL',
        amount: 0,
    },
};

export function useInvestor() {
    const { state, scopeAllows } = React.useContext(CA);
    const api = useApi();

    // Initial values for the state
    const initial = { investor: investorTypes };

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getUserInvestor() {
        const { userid } = state.auth;
        return api.get(`/users/${userid}/investor`).then(res => {
            // console.log('useRankingInvestor', 'getUserInvestor', res);
            addVars({ investor: res });
            return res;
        });
    }
    function putUserInvestor(payload) {
        const { userid } = state.auth;
        return api.put(`/users/${userid}/investor`, payload).then(res => {
            addVars({ investor: res });
            return res;
        });
    }

    useEffectOnce(() => {
        if (!scopeAllows('investor')) {
            RHP.replace('/home');
            return;
        }
        // Initialize the module's service
        Promise.all([getUserInvestor()]).then(res => setReady(true));
    });

    return {
        // Ready to load?
        ready,
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Custom functions
        getUserInvestor,
        putUserInvestor,
    };
}
export default useInvestor;
