import React from 'react';
import { useEffectOnce } from 'react-use';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

// Firebase imports
import firebase from 'firebase/app';
import 'firebase/messaging';

import { CONFIG } from 'config';
import { CA, CG, CN } from 'contexts';
import { useApi } from 'hooks';

import * as Components from './components';

export function AppNotifications() {
    const { authState } = React.useContext(CA);
    const { globalState, emitToast } = React.useContext(CG);
    const { notifyDispatch } = React.useContext(CN);
    const api = useApi();

    function notificationError(error) {
        // console.log('AppNotifications', 'Registration error!');
        emitToast('Notification registration error');
        return error;
    }

    function notificationPermissionDenied(error) {
        // console.log('AppNotifications', 'Notification permission denied!');
        // emitToast('Notification permission not granted');
        return error;
    }

    function registerFCMToken(token) {
        // console.log('AppNotifications Registration success!', JSON.stringify(token));
        // console.log(`AppNotifications token: ${token.value}`);
        notifyDispatch({ type: 'NOTIFICATION_TOKEN', notificationToken: token.value });

        // console.log(`Notification token: ${response.token.value}`);
        // Update the user notificationToken (or register a anonymus one)
        const payload = { notificationToken: token.value };
        if (authState.authenticated && authState.auth.userid) {
            // If the user is logged in, then update the user device
            // console.log('AppNotifications', 'registerFCMToken', '/users/:userid/device');
            api.put(`/users/${authState.auth.userid}/devices`, payload);
        } else {
            // So, the user is not authenticated, send to public endpoint
            // console.log('AppNotifications', 'registerFCMToken', '/devices');
            payload.geoLocation = globalState.location;
            api.post(`/devices`, payload);
        }
    }

    function notificationRegister() {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        return PushNotifications.requestPermissions()
            .then(result => {
                // console.log('AppNotifications PushNotification permission requested');
                // console.log(result.granted);
                if (result.granted) {
                    // Register with Apple / Google to receive push via APNS/FCM
                    // console.log('AppNotifications PushNotification registering');
                    return PushNotifications.register();
                }
                // Show some error
                // console.log('AppNotifications PushNotification permission not granted');
                const notifPermissionDenied = new Error('Notification permission not granted');
                return notificationPermissionDenied(notifPermissionDenied);
            })
            .catch(notificationError);
    }

    function nativeActionPerformed(action) {
        // console.log('AppNotifications', 'nativeActionPerformed', action);
        // Check if the action exists
        const notification = {
            actionType: 'click',
            silent: true,
            notificationSource: 'native',
            ...action.notification,
        };

        // Dispatch notification function
        notifyDispatch({ type: 'NOTIFICATION', notification });
        notifyDispatch({ type: 'REDIRECTED', notificationRoute: notification.data.route });
        return action;
    }

    function nativeNotificationReceived(notification) {
        // console.log('AppNotifications', 'nativeNotificationReceived', notification);
        // Add source to the notification object
        const data = {
            notificationSource: 'native',
            ...notification,
        };
        // Dispatch notification function
        notifyDispatch({ type: 'NOTIFICATION', notification: data });
        return notification;
    }

    function webActionPerformed(action) {
        // console.log('AppNotifications', 'webActionPerformed', action);
        // Check if the action exists
        const data = {
            actionType: 'click',
            silent: true,
            notificationSource: 'web',
            ...action,
        };
        // Dispatch notification function
        notifyDispatch({ type: 'NOTIFICATION', notification: data });
        notifyDispatch({ type: 'REDIRECTED', notificationRoute: data.data.route });
        return action;
    }

    function webNotificationReceived(notification) {
        // console.log('AppNotifications', 'webNotificationReceived', notification);
        // Check if the action exists
        const data = {
            notificationSource: 'web',
            ...notification,
        };
        // Dispatch notification function
        notifyDispatch({ type: 'NOTIFICATION', notification: data });
        return notification;
    }

    async function webNotificationRegister() {
        try {
            const messaging = firebase.messaging();
            await messaging.requestPermission().catch(err => {
                // Will ignore errors when requesting permission, since will handle this ahead in the code
            });
            const permission = await Notification.requestPermission();

            // No permission granted? Remove don't register
            if (permission !== 'granted') {
                const notifPermissionDenied = new Error('Notification permission not granted');
                return notificationPermissionDenied(notifPermissionDenied);
            }

            const token = await messaging.getToken();
            registerFCMToken({ value: token });

            // Listen to Messages received in foreground
            messaging.onMessage(webNotificationReceived);
            // Callback fired if Instance ID token is updated.
            messaging.onTokenRefresh(() => {
                messaging.getToken().then(rToken => registerFCMToken({ value: rToken }));
            });

            // Listen to Messages received in background
            const handleBackgroundMessage = event => {
                // Check if the message is a action
                if (event.data && event.data.messageType === 'action') {
                    webActionPerformed(event.data);
                }
                // Check if the message is a push
                if (event.data && event.data.messageType === 'push') {
                    webNotificationReceived(event.data);
                }
            };
            // Listen to Messages received in background
            return navigator.serviceWorker.addEventListener('message', handleBackgroundMessage);
        } catch (err) {
            // console.error('AppNotifications', 'webNotificationRegister', err);
            return false;
        }
    }

    // Run the effect to register the notification
    useEffectOnce(() => {
        // Check it the PushNotifications promise is avaliable
        // console.log('AppNotifications useEffectOnce');

        if (Capacitor.isPluginAvailable('PushNotifications')) {
            // console.log(
            //     'AppNotifications Capacitor PushNotifications available, registering and requesting permission'
            // );
            notificationRegister();

            // Method for Notification Registration
            PushNotifications.addListener('registration', registerFCMToken);
            // Method for registration error
            PushNotifications.addListener('registerError', notificationError);
            // Method to show the notification if the app is open on the device
            PushNotifications.addListener('pushNotificationReceived', nativeNotificationReceived);
            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed', nativeActionPerformed);
        }
        // Initilize Firebase for Notifications
        else {
            // console.log('AppNotifications Capacitor PushNotifications not available');
            // console.log('AppNotifications', 'Native not available, initializing Firebase');
            firebase.initializeApp(CONFIG.firebase);
            webNotificationRegister();
        }
    });

    return <Components.AppNotif />;
}
export default AppNotifications;
