import React from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Form from 'components/form';
import * as Page from 'components/page';

import { RANKING_CATEGORIES } from '../../../constants/categories';

export function StartupStep3({ useRankingStartup, t }) {
    const { vars, putStartupRankingApplication } = useRankingStartup;
    const { startupDetails, ranking, application } = vars;

    const currentRankingData = startupDetails.rankingData[ranking.id];
    const nullOption = { name: t('glossary:selectOption'), value: '' };

    // Initialize a new form handler
    const form = useForm({
        categoryOne: currentRankingData.categories[0] || nullOption.value,
        categoryTwo: currentRankingData.categories[1] || nullOption.value,
        categoryThree: currentRankingData.categories[2] || nullOption.value,
        categoryFour: currentRankingData.categories[3] || nullOption.value,
        newTrend: currentRankingData.newTrend
    });

    // Check for previous step conclusion
    if (!application.steps || !application.steps.step2) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            ...application,
            categories: [
                values.categoryOne,
                values.categoryTwo,
                values.categoryThree,
                values.categoryFour
            ]
        };

        if (values.categoryOne === 'New Trends') {
            payload.newTrend = values.newTrend;
            payload.categories = ['New Trends'];
        }

        payload.categories = payload.categories.filter(category => category);

        // Set the current step to true;
        if (!payload.steps) {
            payload.steps = {};
        }
        payload.steps.step3 = true;

        return putStartupRankingApplication(payload)
            .then(() => RHP.push('/ranking/latam/startup/step4'))
            .catch(() => form.submited());
    };

    const categoriesOptions = RANKING_CATEGORIES.map(elem => ({ name: elem, value: elem }));

    categoriesOptions.unshift(nullOption);

    const newTrendOption = categoriesOptions.find(elem => elem.name === 'New Trends');

    const fields = {
        categoryOne: {
            error: form.errors.categoryOne,
            label: t('glossary:categoryOne'),
            name: 'categoryOne',
            type: 'select',
            options: categoriesOptions,
            value: form.values.categoryOne,
            onChange: form.handleChange
        },
        categoryTwo: {
            disabled: !form.values.categoryOne,
            error: form.errors.categoryTwo,
            label: t('glossary:categoryTwo'),
            name: 'categoryTwo',
            type: 'select',
            required: false,
            options: categoriesOptions.filter(option => option.value !== 'New Trends'),
            value: form.values.categoryTwo,
            onChange: form.handleChange,
            onlyIf: form.values.categoryOne !== newTrendOption.value
        },
        categoryThree: {
            disabled: !form.values.categoryTwo,
            error: form.errors.categoryThree,
            label: t('glossary:categoryThree'),
            name: 'categoryThree',
            type: 'select',
            required: false,
            options: categoriesOptions.filter(option => option.value !== 'New Trends'),
            value: form.values.categoryThree,
            onChange: form.handleChange,
            onlyIf: form.values.categoryOne !== newTrendOption.value
        },
        categoryFour: {
            disabled: !form.values.categoryThree,
            error: form.errors.categoryFour,
            label: t('glossary:categoryFour'),
            name: 'categoryFour',
            type: 'select',
            required: false,
            options: categoriesOptions.filter(option => option.value !== 'New Trends'),
            value: form.values.categoryFour,
            onChange: form.handleChange,
            onlyIf: form.values.categoryOne !== newTrendOption.value
        },
        newTrend: {
            error: form.errors.newTrend,
            label: t('glossary:newTrend'),
            name: 'newTrend',
            type: 'generic',
            value: form.values.newTrend,
            onChange: form.handleChange,
            onlyIf: form.values.categoryOne === newTrendOption.value
        }
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Page.Page
            name="StartupStep3"
            back="/ranking/latam/startup"
            customBackText={t('glossary:backToRanking')}
        >
            <Page.Title>{t('StartupStep3.title')}</Page.Title>
            <Page.Descr>{t('StartupStep3.descr', { rankingYear: ranking.year })}</Page.Descr>

            <Form.Form name="StartupStep3Form" onSubmit={evSubmit}>
                <Form.Field {...fields.categoryOne} />
                <Form.Field {...fields.categoryTwo} />
                <Form.Field {...fields.categoryThree} />
                <Form.Field {...fields.categoryFour} />
                <Form.Field {...fields.newTrend} />

                <div style={{ display: 'flex', gap: '10px' }}>
                    <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/startup/step2">
                        <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                        <span className="text" style={{ margin: '0 0 0 auto' }}>
                            {t('glossary:back')}
                        </span>
                    </Page.Buttn>
                    <div style={{ width: '80%' }}>
                        <Form.Submt paddingTop="10rem" {...form} text={t('glossary:save')} />
                    </div>
                </div>
            </Form.Form>
        </Page.Page>
    );
}
