import styled from 'styled-components';

export const CXIChallenges = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (max-width: 768px) {
        padding: 5rem 1.6rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header.filter {
        margin-top: 2.4rem;
    }
    .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .c-header.filter + .c-header.filter {
        margin-top: 0.4rem;
    }
`;

export const Title = styled.h1`
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    display: flex;
    justify-content: space-between;
`;

export const Subtitle = styled.h1`
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    p {
        color: #0094f9;
        font-weight: 600;
        font-size: 1.6rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;

export const Descr = styled.h1`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 1.6rem 0 0;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

export const Tooltip = styled.p`
    position: absolute;
    top: 0;
    right: 1px;
    text-align: justify;
    color: black;
    width: 200px;
    font-weight: 400;
    font-size: 12px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #45aaf2;
    padding: 15px 17px;
    z-index: 200;

    b {
        color: #45aaf2;
    }
`;

export const Buttn = styled.button`
    padding: 0.7rem
    border-radius: 2rem;
    width: fit-content;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    font-size: 12px;
    color: ${({ theme }) => theme.colors.deepBlue};
    border-color: ${({ theme }) => theme.colors.deepBlue};
    outline: none !important;
    transition: 0.2s;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
    box-shadow: none;

    @media (max-width: 768px) {
        font-size: 10px;
    }

`;

export const Navigation = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    background: #c4c4c4;
    border: 0;
    border-radius: 8px;
    transition: all 0.3s ease;
    width: 100%;

    p {
        font-weight: 500;
        margin-top: auto;
        margin-right: 10px;
    }
`;

export const List = styled.div``;

export const CXIChallengesPagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: black;
    padding-top: 30px;
    font-weight: 700;
    gap: 30px;
    max-width: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;

    ul {
        display: flex;
        gap: 30px;
        padding: 0;
        list-style: none;
        margin: 0;
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    i {
        cursor: pointer;
        color: #0094f9;
        transition: 0.5s ease all;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 27px;
        height: 27px;
        &:hover {
            color: white;
            border-radius: 100px;
            transform: scale(1.5);
            transition: 0.5s ease all;
            background-color: #0094f9;
        }
    }

    b {
        background-color: #0094f9;
        width: 27px;
        height: 27px;
        color: white;
        text-align: center;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.5s ease all;

        &:hover {
            transform: scale(1.3);
            color: white;
            border-radius: 100px;
            width: 27px;
            height: 27px;
            transform: scale(1.3);
            transition: 0.5s ease all;
            background-color: #b1b1b1;
        }

        &.active {
            background-color: #0094f9;
            color: white;
            border-radius: 100px;
            width: 27px;
            height: 27px;
            transform: scale(1.3);
            transition: 0.5s ease all;
        }
    }
`;
