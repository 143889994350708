import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Page from './index';

export const routes = [
    {
        exact: true,
        path: '/100-10-1/networks',
        component: Page.CXINetworksIndex,
    },
    {
        exact: true,
        path: '/100-10-1/networks/evaluated',
        component: Page.CXINetworksEvaluated,
    },
    {
        exact: true,
        path: '/100-10-1/networks/mentoring',
        component: Page.CXINetworksMentoring,
    },
    {
        exact: true,
        path: '/100-10-1/networks/monitoring',
        component: Page.CXINetworksMonitoring,
    },
    {
        exact: true,
        path: '/100-10-1/networks/speed-dating',
        component: Page.CXINetworksSpeedDating,
    },
    {
        exact: true,
        path: '/100-10-1/networks/unevaluated',
        component: Page.CXINetworksUnevaluated,
    },
];

export const CXINetworksRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default CXINetworksRoutes;
