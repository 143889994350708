import styled from 'styled-components';

export const FreeTimeButton = styled.button.attrs({
    className: 'free-morning-button',
})`
    padding: 8px 16px;
    border: 1px solid #b7424c;
    color: white;
    margin: 0.8rem 1.6rem 0 0;
    background-color: #e9747e;
    font-size: 1.6rem;
    border-radius: 4px;
    transition: background-color 0.1s linear;

    :hover {
        background-color: #b7424c;
        border: 1px solid #b7424c;
    }

    :focus {
        outline: none;
    }
`;
