import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const MatchEvaluator = styled.div.attrs({
    className: 'c-match-evaluator c-match-item',
})`
    align-items: center;
    background: transparent;
    border: 0;
    display: flex;
    gap: 1.6rem;
    padding: 0;
    text-align: left;

    .user-avatar {
        flex: 0 0 6.8rem;
        width: 6.8rem;
    }

    &.anonymous .name {
        font-weight: 400;
        // text-transform: lowercase;
    }
    &:not(.anonymous) .name {
        margin-bottom: 0.4rem;
        ${truncate(1, 1.3)}
    }
`;

export const Infos = styled.span`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .name {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.3;
    }
    .anonymous,
    .company,
    .position {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.3;
        ${truncate(1, 1.3)}
    }

    .company {
        color: ${({ theme }) => theme.colors.black};
        font-weight: 500;
    }

    .ttip {
        cursor: help;
        font-size: 1.3rem;
        margin-left: 0.4rem;
        position: relative;
        text-transform: none;

        .ttip-icon {
            color: ${({ theme }) => theme.colors.energyBlue};
        }
        .ttip-text {
            background: ${({ theme }) => theme.colors.darkGray};
            border-radius: 0.8rem;
            box-shadow: 0px 2px 12px #34286812;
            color: ${({ theme }) => theme.colors.white};
            display: none;
            font-size: 1.2rem;
            font-style: normal;
            line-height: 1.2;
            padding: 0.8rem 1rem;
            position: absolute;
            ${positions(0, 0, 'auto', 'auto')}
            width: 18rem;
            z-index: 100;
        }

        &:focus,
        &:hover {
            .ttip-text {
                display: block;
            }
        }
    }
`;
