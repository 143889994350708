import styled from 'styled-components';
import { positions } from 'utils/styles';

import graph from 'assets/images/graph-ranking.png';

export const RankingModule = styled.div.attrs({
    className: 'styled-ranking-module',
})`
    background: #fff;
    position: fixed;
    ${positions(0, 0, 0, 0)}

    &::before,
    &::after {
        background: transparent no-repeat 0 0;
        background-image: url(${graph});
        background-size: cover;
        content: '';
        position: absolute;
        transform: rotate(10deg) scaleX(-1);
        transform-origin: 50% 50%;
        z-index: -1;
    }
    &::before {
        height: 37rem;
        margin: 0 -22rem -12rem 0;
        ${positions('auto', 0, '100%', 'auto')}
        width: 33.8rem;
    }
    &::after {
        height: 31.2rem;
        margin: -12rem 0 0 -15rem;
        ${positions('100%', 'auto', 'auto', 0)}
        width: 31.6rem;
    }

    ion-content {
        --background: transparent;
        --color: #444;
    }

    .component-page-container {
        /* flex: 0 0 auto; */
        /* margin-bottom: calc(5.9rem + var(--ion-safe-area-bottom)); */
        position: relative;
        padding-bottom: 1.5rem;
    }
    .component-page-container::before {
        background: rgba(255, 255, 255, 0.9);
        content: '';
        display: block;
        margin: 0.75rem;
        position: absolute;
        ${positions('2rem', 0, 0, 0)}
        z-index: -1;
    }

    .form-autocomplete-list {
        background: #f2f2f2;
    }
`;
