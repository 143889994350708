export function ASCIIToDecimal(chars: string) {
    const arrDecimal: string[] = [];
    // Loop trough the chars and build the array
    for (let n = 0, l = chars.length; n < l; n += 1) {
        const dec = Number(chars.charCodeAt(n)).toString(10);
        arrDecimal.push(dec);
    }
    return arrDecimal.join('');
}

export function stringToKebabCase(str: string) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
}
