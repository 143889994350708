import React, { useState } from 'react';
import * as Form from 'components/form';

import { Checkbox } from '@mui/material';

import { Label } from 'components/form/control';
import { ButtonsForm } from '../components/buttons-form';
import { ProgressBar } from '../components/progress-bar';
import * as Styled from './StyledStep5';

export function Step5Form({ t, form, onSubmit }) {
    const [hasOptionalQuestions, setHasOptionalQuestions] = useState(false);
    const CURRENT_STEP = 5;

    const fields = {
        question1: {
            name: 'question1',
            type: 'textarea',
            required: false,
            value: form.values.question1,
            onChange: form.handleChange
        },
        question2: {
            name: 'question2',
            type: 'textarea',
            required: false,
            value: form.values.question2,
            onChange: form.handleChange
        },
        question3: {
            name: 'question3',
            type: 'textarea',
            required: false,
            value: form.values.question3,
            onChange: form.handleChange
        },
        question4: {
            name: 'question4',
            type: 'textarea',
            required: false,
            value: form.values.question4,
            onChange: form.handleChange
        },
        question5: {
            name: 'question5',
            type: 'textarea',
            required: false,
            value: form.values.question5,
            onChange: form.handleChange
        }
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Styled.Step5>
            <ProgressBar current={CURRENT_STEP} />

            <Styled.Title>{t('form.step5.title')}</Styled.Title>
            <Styled.Subtitle>{t('form.step5.descr')}</Styled.Subtitle>
            <div style={{ flexDirection: 'row' }}>
                <Checkbox
                    onChange={() => setHasOptionalQuestions(!hasOptionalQuestions)}
                    isChecked={hasOptionalQuestions}
                    size="large"
                    title="teste"
                />
                <span>Desejo enviar perguntas opcionais</span>
            </div>

            <Form.Form name="ChallengeStep5Form" onSubmit={evSubmit}>
                {hasOptionalQuestions && (
                    <>
                        <Styled.Questions>{t('form.step5.question1')}</Styled.Questions>
                        <Form.Field {...fields.question1} />

                        <Styled.Questions>{t('form.step5.question2')}</Styled.Questions>
                        <Form.Field {...fields.question2} />

                        <Styled.Questions>{t('form.step5.question3')}</Styled.Questions>
                        <Form.Field {...fields.question3} />

                        <Styled.Questions>{t('form.step5.question4')}</Styled.Questions>
                        <Form.Field {...fields.question4} />

                        <Styled.Questions>{t('form.step5.question5')}</Styled.Questions>
                        <Form.Field {...fields.question5} />
                    </>
                )}

                <br />

                <ButtonsForm currentStep={CURRENT_STEP} />
            </Form.Form>
        </Styled.Step5>
    );
}
