import styled from 'styled-components';

export const MenuLogo = styled.figure.attrs({
    className: 'menu-logo rippled',
})`
    display: block;
    height: 10rem;
    position: relative;
    width: 10rem;

    img,
    svg {
        height: auto;
        width: 10rem;
    }
`;
