import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const Container = styled.div.attrs({
    className: 'm-container container',
})`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    padding: 0 !important;
    position: relative;
    width: 100%;
    z-index: 999999;
`;

export const ContainerHead = styled.div.attrs(props => {
    const withImage = props.image ? 'with-image' : '';
    const withTexts = props.texts ? 'with-texts' : '';
    return { className: `m-container-head ${withImage} ${withTexts}` };
})`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1.6rem;
    position: relative;

    @media (min-width: 768px) {
        padding: 2.4rem;
    }

    .text {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }

    &.with-image {
        padding-top: 5.8rem;
    }

    &.with-image.with-texts {
        padding-top: 2.4rem;
        padding-left: calc(10rem + 3.2rem);
    }
    &.with-image.with-texts .image {
        left: 1.6rem;
        transform: translateX(0);
    }
`;

export const ContainerBody = styled.div.attrs({
    className: 'm-container-body',
})`
    align-items: stretch;
    border-top: 2px solid ${({ theme }) => theme.colors.lighterGray};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.6rem;

    @media (min-width: 768px) {
        padding: 2.4rem;
    }
`;

export const ContainerFeet = styled.div.attrs({
    className: 'm-container-feet',
})`
    align-items: center;
    border-top: 2px solid ${({ theme }) => theme.colors.lighterGray};
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
    padding: 1.6rem;

    @media (min-width: 768px) {
        padding: 2.4rem;
    }
`;

export const ContainerSlim = styled.div.attrs({
    className: 'm-container-slim',
})`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    max-width: 56rem;
    min-width: calc(32rem - 6.4rem);
    padding: 0 !important;
    position: relative;

    .m-container-body,
    .m-container-feet {
        border: 0;
        justify-content: center;
    }
    .m-container-head + .m-container-body,
    .m-container-head + .m-container-feet,
    .m-container-body + .m-container-feet {
        padding-top: 0;
    }
`;

export const Close = styled.button.attrs({
    className: 'm-container-close',
})`
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.black};
    font-size: 2.4rem;
    line-height: 1;
    margin: 1.2rem 1.6rem 0 0;
    padding: 0;
    position: absolute;
    ${positions(0, 0, 'auto', 'auto')}
    z-index: 10;
`;

export const Image = styled.div.attrs({
    className: 'image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.image}');
    background-size: contain;
    background-origin: content-box;
    border: 4px solid #ddd;
    border-radius: 50%;
    margin-top: -5.8rem;
    flex: 0 0 10rem;
    height: 10rem;
    position: absolute;
    ${positions('auto', 'auto', '1.6rem', '50%')}
    transform: translateX(-50%);
    width: 10rem;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    ${truncate(1, 1.2)}
`;
