import styled from 'styled-components';

export const FormSubmit = styled.button.attrs(props => ({
    className: 'form-submit btn-primary btn',
    type: props.type || 'submit',
}))`
    .text {
        transform: translateY(1px);
    }
`;

export const FormSubmitHolder = styled.div.attrs({
    className: 'form-submit-holder',
})`
    ${props => props.paddingTop || '3rem'};
`;
