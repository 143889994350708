const contexts = {
    en: require.context('./en', true, /\.json$/),
    es: require.context('./es', true, /\.json$/),
    pt: require.context('./pt', true, /\.json$/),
};
const contextFiles = lang => {
    const ret = {};
    contexts[lang].keys().forEach(filename => {
        const name = filename.replace('./', '').replace('.json', '');
        ret[name] = contexts[lang](filename);
    });
    return ret;
};

const trans = {
    en: contextFiles('en'),
    es: contextFiles('es'),
    pt: contextFiles('pt'),
};
export const translations = trans;
export default translations;
