import styled from 'styled-components';

export const CXIChallengeProfile = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header.filter {
        margin-top: 2.4rem;
    }
    .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .c-header.filter + .c-header.filter {
        margin-top: 0.4rem;
    }
`;

export const Subtitle = styled.h1`
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    p {
        color: #0094f9;
        font-weight: 600;
        font-size: 1.6rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;
export const Filter = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    background: #c4c4c4;
    border: 0;
    border-radius: 8px;
    transition: all 0.3s ease;
    width: 100%;

    p {
        margin-top: auto;
        font-weight: 500;
        font-size: 1.5rem;
        margin-right: 10px;
    }

    h1 {
        font-size: 1.2rem;
    }
`;

export const Descr = styled.h1`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 1.6rem 0 0;
`;

export const Tooltip = styled.p`
    position: absolute;
    top: 0;
    right: 1px;
    text-align: justify;
    color: black;
    width: 200px;
    font-weight: 400;
    font-size: 12px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #45aaf2;
    padding: 15px 17px;
    z-index: 200;

    b {
        color: #45aaf2;
    }
`;

export const Buttn = styled.button`
    padding: 0.7rem
    border-radius: 2rem;
    width: fit-content;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    font-size: 12px;
    color: ${({ theme }) => theme.colors.deepBlue};
    border-color: ${({ theme }) => theme.colors.deepBlue};
    outline: none !important;
    transition: 0.2s;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
    box-shadow: none;
`;

export const NetworkPageBody = styled.div`
    margin-top: 4rem;
`;
