import React from 'react';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

import { CA, CG } from 'contexts';
import { RouterHistoryProvider as RHP } from 'providers';

import { queryParam } from 'utils/query';

import { LoginRoutes } from './LoginRoutes';
import { useLogin } from './useLogin';

export function Login(props) {
    const { t } = useTranslation('login');
    const hookd = useLogin();

    const redirectUrl = queryParam('redirectUrl');

    // Use contexts for specific functionalities
    const { state } = React.useContext(CA);
    const { dispatch } = React.useContext(CG);

    // Run some structural changes
    useEffectOnce(() => {
        dispatch({ type: 'MENU_SHOW', menu: false });
        return () => dispatch({ type: 'MENU_SHOW', menu: true });
    });

    if (state.authenticated) {
        RHP.replace(redirectUrl || '/home');
        return null;
    }

    return <LoginRoutes t={t} useLogin={hookd} />;
}
export default Login;
