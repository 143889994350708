import styled from 'styled-components';

export const HomeListBanners = styled.ul.attrs({
    className: 'home-list-banners',
})`
    margin-top: 0;

    .list-vertical-item + .list-vertical-item {
        --padding-top: 2rem;
    }

    .list-scrolls-item {
        flex: 0 0 auto;
        max-width: 100%;
        width: calc(100vw * 0.7);
    }
    .list-scrolls-item .home-list-banners-item {
        padding-right: 3rem;
    }
`;

export const HomeListBannersItem = styled.a.attrs({
    className: 'home-list-banners-item',
})`
    display: block;
    width: 100%;
`;

export const Figure = styled.figure.attrs({
    className: 'list-banners-figure',
})`
    background: rgba(0, 0, 0, 0.1);
    display: block;
    margin: 0;

    img {
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;

        &.mobi {
            display: block;
            @media (min-width: 768px) {
                display: none;
            }
        }
        &.desk {
            display: none;
            @media (min-width: 768px) {
                display: block;
            }
        }
    }
`;
