import styled from 'styled-components';

export const TableSubtitle = styled.h2.attrs({
    className: 'table-subtitle',
})`
    margin-bottom: 1rem;
    .c-page-subtitle-button {
        color: #999;
    }
`;
