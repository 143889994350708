import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const ModalNavbar = styled.div.attrs({
    className: 'modal-navbar',
})`
    padding-top: calc(1.5rem + var(--ion-safe-area-top));
`;

export const ModalNavbarContainer = styled.div.attrs({
    className: 'modal-navbar-container container',
})`
    display: flex;
    height: 4.4rem;
    justify-content: space-between;
`;

export const ModalNavbarBtn = styled.button.attrs({
    className: 'modal-navbar-btn',
})`
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--primary);
    display: flex;
    flex: 0 0 auto;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0;
    margin-left: auto;
    text-align: left;
    text-transform: uppercase;
    -webkit-appearance: none;

    .icon {
        font-size: 1.6rem;
        margin-left: 0.5rem;
    }
`;

export const ModalNavbarTxt = styled.h2.attrs({
    className: 'modal-navbar-txt',
})`
    margin: 0;
    color: var(--primary);
    flex: 1 1 auto;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.75;
    text-transform: uppercase;
    ${truncate(1, 2.75)}
`;
