import { CG } from 'contexts';
import useCXI from 'modules/cxi/useCXI';
import React, { useContext } from 'react';
import * as Styled from './StyledCompanySubscription';

export const CompanySubscription = () => {
    const { state } = useContext(CG);
    const { vars } = useCXI();
    const { company } = vars;

    const formattedDate = new Date(company?.cxiDeadline?.split('T')[0]);

    return !company?.cxiDeadline ? null : (
        <Styled.CompanySubscription>
            Licença de uso da <strong>100 Open Startups</strong> expira em:{' '}
            <strong>{formattedDate.toLocaleDateString(state.language)}</strong>.
        </Styled.CompanySubscription>
    );
};
