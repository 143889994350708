import { useForm } from 'components/form';
import React from 'react';

import { RHP } from 'providers';
import { Step2Form } from './Step2Form';

export function Step2(props) {
    const { useCXI } = props;
    const { addVars, vars } = useCXI;

    const form = useForm({ ...vars.challengerForm });

    const onSubmit = values => {
        const payload = {
            ...vars.challengerForm,
            name: values.name,
            description: values.description,
            challengeInfo: {
                cse: values.cse,
                objectives: values.objectives,
                relationships: values.relationships
            }
        };

        addVars({ challengerForm: { ...payload } });
        RHP.push('/100-10-1/form/3');
    };

    const propsForm = { ...props, form, onSubmit };
    return <Step2Form {...propsForm} />;
}
