import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const ComponentTable = styled.div.attrs({
    className: 'component-table',
})`
    display: block;
    margin-top: 3rem;
`;

export const ComponentTableRow = styled.div.attrs({
    className: 'component-table-row',
})`
    align-items: center;
    background: transparent;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    color: #444;
    display: flex;
    min-height: 4rem;
    justify-content: space-between;
    padding: 0;
    -webkit-appearance: none;

    .label {
        color: #999;
        display: inline-block;
        flex: 0 0 auto;
        font-size: 1.2rem;
        font-weight: 400;
        padding-left: 1rem;
        margin-left: auto;
    }
    .value {
        flex: 1 1 auto;
        font-size: 1.4rem;
        font-weight: 500;
        max-width: 80%;
        ${truncate(1)}
        color: ${props => props.disabled && '#999'};

    }

    & + .component-table-row {
        border-top: 0;
    }
`;
