import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Header } from '../components/header/Header';
import { Loading } from '../components/loading';
import { useCXI } from '../useCXI';

import { CXIDashboardRoutes } from './CXIDashboardRoutes';
import { CXIDashboardContextProvider } from './CXIDashboardContext';

import { useCXIDashboard } from './useCXIDashboard';

import * as Nav from './components/navigation';
import * as Styled from './StyledCXIDashboard';

export const CXIDashboardModule = () => {
    const { t } = useCXI();
    const location = useLocation();

    const hookd = useCXIDashboard(true);
    const { company } = hookd.vars;

    const shouldShowHeader = !location.pathname.includes('/users/')
    const shouldShowHeader1 = !location.pathname.includes('/startups/');

    return !hookd.ready ? (
        <Loading>{t('dashboard.loading')}</Loading>
    ) : (
        <Styled.CXIDashboard>
            {shouldShowHeader && shouldShowHeader1 && (
                <Styled.CXIDashboardHeader>
                    <Header>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Styled.CompanyLogo>
                                <img src={company.logourl} alt="Logo da empresa" />
                            </Styled.CompanyLogo>
                            <div style={{ marginRight: '40px' }}>
                                <h1>
                                    <strong> Dashboard </strong>
                                </h1>
                                <h3> Dados de performance da {company.name} no Programa 100-10-1 Startups</h3>
                            </div>
                        </div>
                    </Header>
                </Styled.CXIDashboardHeader>
            )}

            {shouldShowHeader && shouldShowHeader1 && (
                <Styled.CXIDashboardHeader>
                    <Header>
                        <Nav.Navigation>
                            <Nav.Page path="/index">{t('dashboard.tab.all')}</Nav.Page>
                            <Nav.Page path="/stages">{t('dashboard.tab.stages')}</Nav.Page>
                            <Nav.Page path="/users">{t('dashboard.tab.users')}</Nav.Page>
                            <Nav.Page path="/startups">{t('dashboard.tab.startups')}</Nav.Page>
                        </Nav.Navigation>
                    </Header>
                </Styled.CXIDashboardHeader>
            )}
            
            <Styled.CXIDashboardRoutes>
                <CXIDashboardRoutes />
            </Styled.CXIDashboardRoutes>
        </Styled.CXIDashboard>
    );
};

export const CXIDashboard = () => {
    const { companyId } = useParams();
    return (
        <CXIDashboardContextProvider companyId={companyId}>
            <CXIDashboardModule />
        </CXIDashboardContextProvider>
    );
};

export default CXIDashboard;
