import React from 'react';

import * as Form from 'components/form';

import { Ruler } from 'components/page/ruler/PageRuler';
import * as Styled from './StyledRankingInterestCard';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {},
};

export const RankingInterestCard = props => {
    const { form } = props;

    const { specialAwards } = form.values;

    const onChange = ev => {
        const event = fakeEvent;
        event.target.name = 'specialAwards';
        const { name, value } = ev.target;

        if (!specialAwards[props.name]) {
            specialAwards[props.name] = {};
        }

        specialAwards[props.name][name.replace(`${props.name}-`, '')] = value;
        event.target.value = specialAwards;

        return form.handleChange(event);
    };

    const fields = {
        applied: {
            error: form.errors.specialAwards?.[props.name].applied,
            label: props.appliedText,
            name: `${props.name}-applied`,
            required: false,
            type: 'checkbox',
            value: form.values.specialAwards[props.name]?.applied,
            onChange,
        },
        description: {
            error: form.errors.specialAwards?.[props.name].description,
            label: props.reasonText,
            name: `${props.name}-description`,
            type: 'textarea',
            value: form.values.specialAwards[props.name]?.description,
            onChange,
            required: form.values.specialAwards?.[props.name]?.applied,
            onlyIf: form.values.specialAwards?.[props.name]?.applied === true,
        },
    };

    return (
        <Styled.RankingInterestCard>
            <h2>{props.title}</h2>
            <p>{props.descr}</p>
            <Form.Field {...fields.applied} />
            <Form.Field {...fields.description} />
            <Ruler />
        </Styled.RankingInterestCard>
    );
};

export default RankingInterestCard;
