import React from 'react';
import { useEffectOnce } from 'react-use';

import { ContextAuth as CA } from 'contexts';
import { CONFIG } from 'config';
import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { RankingValidRecentlyForm } from './RankingValidRecentlyForm';

export function RankingValidRecently(props) {
    const { state } = React.useContext(CA);
    const { vars, postUserSendEmail } = props.useRanking;

    // Initialize a new form handler
    const form = useForm({
        email: vars.email || state.user.email,
    });

    useEffectOnce(() => {
        // Check if the user has a verified e-mail
        if (state.user.hasVerifiedEmailRecently) {
            RHP.replace(vars.redirectTo || '/ranking');
        }
    });

    // Handle form submition
    const onSubmit = values => {
        // Do the submition api request
        return postUserSendEmail({
            baseUrl: `${CONFIG.baseUrl}${vars.redirectTo || '/ranking/validate'}${
                RHP.location.search
            }${RHP.location.hash}`,
            returnUrl: vars.redirectTo,
        })
            .then(() => {
                RHP.push('/ranking/validate');
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="RankingValidRecently">
            <Page.Title>{props.t('RankingValid.title')}</Page.Title>
            <Page.Descr>{props.t('RankingValid.descr')}</Page.Descr>

            <RankingValidRecentlyForm {...propsForm} />
        </Page.Page>
    );
}
export default RankingValidRecently;
