import React from 'react';
import * as Styled from '../StyledFormInput';

import { FormInputSelectOptions } from './FormInputSelectOptions';
import { FormInputSelectGroups } from './FormInputSelectGroups';

export const FormInputSelect = props =>
    // Don't show this element if it is empty
    !props.options && !props.optgroups ? null : (
        <Styled.FormInput name="select">
            {props.children}
            <select className="form-control" readOnly {...props.input}>
                {props.optgroups && <FormInputSelectGroups {...props} />}
                {props.options && <FormInputSelectOptions {...props} />}
            </select>
        </Styled.FormInput>
    );
export const select = FormInputSelect;
export default FormInputSelect;
