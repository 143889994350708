import React, { useCallback, useState } from 'react';
import Button from 'components/button';
import { ActionCard } from './action-card';
import { Message } from './message';
import { Notification, StartupData } from './notification';

import {
    Container,
    Title,
    Subtitle,
    FeedbackAvaliatorContainer,
    FeedbackAvaliatorTitle,
    ActionContainer,
} from './styles';

interface MessageData {
    username: string;
    userid: string;
    description: string;
    time: Date;
    comment: string;
    position: string;
    avatar: string;
}

interface StandAloneContentProps {
    title: string;
    description: string;
    type: 'classification' | 'feedback' | 'speed-dating' | 'mentoring';
    message: MessageData;
    startup: StartupData;
    handleSubmit(): void;
    onChange(value: string): void;
    onChangeQuestion?: (value: boolean) => void;
}

export const StandAloneContent: React.FC<StandAloneContentProps> = ({
    description,
    title,
    type,
    message,
    handleSubmit,
    onChange,
    onChangeQuestion,
    startup,
}) => {
    const [showModal, setShowModal] = useState(false);

    const onSubmit = useCallback(() => {
        handleSubmit();
        setShowModal(true);
    }, [handleSubmit]);

    return (
        <Container>
            <Title>{title}</Title>
            <Subtitle>{description}</Subtitle>
            <FeedbackAvaliatorContainer>
                <FeedbackAvaliatorTitle>Feedback do avaliador:</FeedbackAvaliatorTitle>
                <Message {...message} />
            </FeedbackAvaliatorContainer>
            <ActionCard type={type} onChange={onChange} onChangeQuestion={onChangeQuestion} />
            <ActionContainer>
                <Button variant="claret" size="large" onClick={() => onSubmit()}>
                    <span className="icon fa fa-check" />
                    Confirmar
                </Button>
            </ActionContainer>
            {showModal && startup && (
                <Notification startup={startup} closeModal={() => setShowModal(false)} />
            )}
        </Container>
    );
};
