import React from 'react';

import * as Styled from './StyledPageTexts';

// Description

export const PageDescr = props => (
    <Styled.PageDescr
        className={props.className}
        style={props.style}
        dangerouslySetInnerHTML={{ __html: props.children }}
    />
);
export const Descr = PageDescr;
export const Description = PageDescr;

// Disclaimer

export const PageDiscl = props => (
    <Styled.PageDiscl
        className={props.className}
        style={props.style}
        dangerouslySetInnerHTML={{ __html: props.children }}
    />
);
export const Discl = PageDiscl;
export const Disclaimer = PageDiscl;

// Texts

export const PageTexts = props => (
    <Styled.PageTexts style={props.style} dangerouslySetInnerHTML={{ __html: props.children }} />
);
export const Texts = PageTexts;

export default PageTexts;
