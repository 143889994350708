import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.darkerstBlue};
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 21px;
    text-align: center;
`;

export const Subtitle = styled.p`
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

export const FeedbackAvaliatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
    width: 100%;
`;

export const FeedbackAvaliatorTitle = styled.h1`
    color: ${({ theme }) => theme.colors.black};
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 22px;
    text-align: center;
`;

export const ActionContainer = styled.div`
    margin-top: 30px;
`;
