import React, { useContext } from 'react';

import { useForm } from 'components/form';
import * as Page from 'components/page';
import { RHP } from 'providers';

import { CG } from 'contexts';
import { StartupStep7AddForm } from './add-form';

export function StartupStep7Add(props) {
    const { emitAlert } = useContext(CG);

    const {
        vars,
        postStartupRelationship,
        getCompanyByEmail,
        getProgramsByCompanyId,
        postProgramByCompanyId
    } = props.useRankingStartup;

    const { ranking } = vars;

    const currDate = new Date();

    // Initialize a new form handler
    const form = useForm({
        year: currDate.getFullYear(),
        otherContact: false
    });

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step5 !== true) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    // Handle form submitions
    const onSubmit = async values => {
        try {
            const currentCompany = await getCompanyByEmail(values.ecosystemContactEmail);
            let companyPrograms = [];
            let program = null;

            if (currentCompany?.company) {
                companyPrograms = await getProgramsByCompanyId(currentCompany?.company?.id);

                program = companyPrograms?.find(e => e?._id === values.programName.value);

                if (values.programName.__isNew__ && !values?.companyName) {
                    program = await postProgramByCompanyId(
                        values.programName.label,
                        currentCompany?.company?.id
                    );
                }
            }

            delete program?.regDate;
            delete program?.companies;

            const payload = {
                company: {
                    name: currentCompany?.company?.name || values.companyName,
                    contact: {
                        name: values.ecosystemContactName,
                        email: values.ecosystemContactEmail
                    }
                },
                description: values.description,
                entity: 'startup',
                linkUrl: values.linkUrl,
                programName: values.programName.label,
                ranking,
                year: values.year
            };

            if (program) {
                payload.program = program;
            }

            // Add relationship
            await postStartupRelationship(payload);

            RHP.push('/ranking/latam/startup/step7');
        } catch (err) {
            emitAlert({
                message: `Ocorreu um erro inesperado, tente novamente mais tarde. Erro: ${err.message}`,
                header: 'Ops...'
            });
        } finally {
            form.submited();
        }
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="StartupStep7Add">
            <Page.Title>{props.t('StartupStep7Add.title')}</Page.Title>
            <Page.Descr>{props.t('StartupStep7Add.descr')}</Page.Descr>

            <StartupStep7AddForm {...propsForm} />
        </Page.Page>
    );
}
