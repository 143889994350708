import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';

import { RHP } from 'providers';
import { Step3Form } from './Step3Form';

export function Step3(props) {
    const { useCXI } = props;
    const { addVars, vars } = useCXI;

    const conv = useConversionOptions(['companySize']);

    // Initialize a new form handler
    const form = useForm({ ...vars.challengerForm });

    // Handle form submition
    const onSubmit = values => {
        const payload = {
            ...vars.challengerForm,
            ...values
        };

        console.log(payload);

        addVars({ challengerForm: { ...payload } });
        RHP.push('/100-10-1/form/4');
    };

    const propsForm = { ...props, conv, form, onSubmit };

    return <Step3Form {...propsForm} />;
}
