import React, { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { RHP } from 'providers';

import { Button } from '../components/button';
import { Loading } from '../components/loading';
import { useCXI } from '../useCXI';

import { StartupCard } from './components/startup-card';
import * as Styled from './StyledCXIMatches';

const CXIMatchesComponent = () => {
    const { t, vars, getSuggestedMatches } = useCXI();
    const { suggestedMatches, matches } = vars;

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    const getSuggestedMatchesCallback = React.useCallback(async () => {
        setLoading(true);
        getSuggestedMatches()
            .then(() => setLoading(false))
            .catch(e => setError(e));
    }, [getSuggestedMatches]);

    useEffectOnce(() => {
        getSuggestedMatchesCallback();
    }, []);

    useEffectOnce(() => {
        if (!loading && suggestedMatches?.matches.length < 3) {
            getSuggestedMatchesCallback();
        }
    }, [loading, suggestedMatches, getSuggestedMatchesCallback]);

    useEffect(() => {
        if (suggestedMatches?.matches.length) {
            return () => {};
        }
        const timer = setTimeout(() => getSuggestedMatchesCallback(), 10000);
        return () => clearTimeout(timer);
    }, [suggestedMatches]);

    if (matches.length >= 100) {
        return (
            <Styled.CXIMatchesCard className="networklimit">
                <Styled.Title>{t('matches.networklimit.title')}</Styled.Title>
                <Styled.Descr>{t('matches.networklimit.description')}</Styled.Descr>

                {matches.length > 101 && (
                    <Styled.Count>
                        {t('matches.networklimit.startupCount', {
                            startups: matches.length,
                            diff: matches.length - 100,
                        })}
                    </Styled.Count>
                )}

                <Styled.Descr>{t('matches.networklimit.howtofixit')}</Styled.Descr>

                <Button onClick={() => RHP.push('/100-10-1/networks')}>
                    {t('matches.networklimit.goToNetworks')}
                </Button>
            </Styled.CXIMatchesCard>
        );
    }

    if (!!error && error.length) {
        return <p>{error}</p>;
    }

    if (loading) {
        return <Loading>{t('matches.loading')}</Loading>;
    }

    if (suggestedMatches.status === 'dailylimit') {
        return (
            <Styled.CXIMatchesCard>
                <Styled.Title>{t('matches.dailylimit.title')}</Styled.Title>
                <Styled.Descr>{t('matches.dailylimit.description')}</Styled.Descr>
            </Styled.CXIMatchesCard>
        );
    }
    if (suggestedMatches.status === 'evaluate') {
        return (
            <Styled.CXIMatchesCard>
                <Styled.Title>{t('matches.evaluate.title')}</Styled.Title>
                <Styled.Descr>{t('matches.evaluate.description')}</Styled.Descr>

                <Button onClick={() => RHP.push('/100-10-1/networks')}>
                    {t('matches.evaluate.goToNetworks')}
                </Button>
            </Styled.CXIMatchesCard>
        );
    }

    if (suggestedMatches.matches?.length === 0) {
        return (
            <Styled.CXIMatchesCard>
                <Styled.Title>{t('matches.preferences.title')}</Styled.Title>
                <Styled.Descr>{t('matches.preferences.description')}</Styled.Descr>

                <Button onClick={() => RHP.push('/100-10-1/setup/1')}>
                    {t('matches.preferences.goToPreferences')}
                </Button>
            </Styled.CXIMatchesCard>
        );
    }

    const match = suggestedMatches.matches[0];
    return (
        <Styled.CXIMatchesCardStack>
            <StartupCard match={match} />
        </Styled.CXIMatchesCardStack>
    );
};

export const CXIMatches = () => (
    <Styled.CXIMatches>
        <CXIMatchesComponent />
    </Styled.CXIMatches>
);
