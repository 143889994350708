import React from 'react';
import { useEffectOnce } from 'react-use';

import { ContextAuth as CA } from 'contexts';
import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { RankingValidateForm } from './RankingValidateForm';

export function RankingValidate(props) {
    const { state } = React.useContext(CA);
    const { vars, postVerifyEmail } = props.useRanking;

    // Initialize a new form handler
    const form = useForm({
        email: vars.email || state.user.email,
    });

    // Handle form submition
    const onSubmit = values => {
        const { email, verificationCode } = values;
        // Do the submition api request
        return postVerifyEmail({ email, verificationCode })
            .then(() => RHP.push(vars.redirectTo || '/ranking'))
            .catch(() => form.submited());
    };

    useEffectOnce(() => {
        // Check if user has passed params with verificationCode
        // console.log('Must Validate email automatically');
        if (state.user.hasVerifiedEmail && state.user.hasVerifiedEmailRecently) {
            RHP.push(vars.redirectTo || '/ranking');
            return;
        }
        if (vars.codeFromQuery) {
            onSubmit({
                email: state.user.email,
                verificationCode: vars.codeFromQuery,
            });
        }
    });

    // Check if the user has a verified e-mail or verified e-mail recently (depending on what ranking is being accessed)
    if (
        !vars.codeFromQuery &&
        ((vars.redirectTo === '/ranking/corporate' && state.user.hasVerifiedEmailRecently) ||
            (vars.redirectTo !== '/ranking/corporate' && state.user.hasVerifiedEmail))
    ) {
        RHP.push(vars.redirectTo || '/ranking');
        return null;
    }

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="RankingValidate" back={vars.codeFromQuery ? '/home' : undefined}>
            <Page.Title>{props.t('RankingValidate.title')}</Page.Title>
            <Page.Descr>{props.t('RankingValidate.descr')}</Page.Descr>

            <RankingValidateForm {...propsForm} />
        </Page.Page>
    );
}
export default RankingValidate;
