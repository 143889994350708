import styled from 'styled-components';

export const Form = styled.div`
    & + & {
        margin-top: 4.8rem;
    }
`;

export const Descr = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    margin: 0.8rem auto 0;
    max-width: 68rem;
    opacity: 0.7;
    text-align: center;
`;

export const Small = styled.p`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0.8rem auto 0;
    max-width: 48rem;
    opacity: 0.7;
    text-align: center;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.colors.black};
    font-size: 2.4rem;
    font-weight: 600;
    margin: 0 0;
    text-align: center;
`;

export const OptionsContainer = styled.div`
    align-content: stretch;
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    justify-content: center;
    margin-top: 2.4rem;

    & > * {
        flex: 0 0 calc(50% - 0.6rem);
        @media (min-width: 768px) {
            flex: 0 0 calc(33.333% - 0.8rem);
        }
        @media (min-width: 992px) {
            flex: 0 0 calc(25% - 1rem);
        }
    }
`;
