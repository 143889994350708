import React from 'react';
import { useTranslation } from 'react-i18next';

import logo100ot from 'assets/images/logo-100ot.svg';

import { iframeLink } from 'core/iframe';
import * as Alert from 'components/alert';

import { CONFIG } from 'config';

export function EntrepreneurTech() {
    const { t } = useTranslation('home');

    // Startup/Tech register href
    const href = `${CONFIG.appLinks.startup}/registerstartup`;

    // Create the alert element props
    const propsAlert = {
        // Set the image to be shown
        image: {
            src: logo100ot,
            alt: t('glossary:100 Open Startups'),
        },
        // Show texts informations
        title: t('EntrepreneurTech.title'),
        descr: t('EntrepreneurTech.descr'),
        // Build the button
        buttn: {
            href: iframeLink(`${href}/?typeOfProject=tech`),
            text: t('EntrepreneurTech.button'),
        },
        // Setup color and presentation
        type: 'warning',
    };
    return <Alert.Alert {...propsAlert} />;
}
export default EntrepreneurTech;
