import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
// import { useTranslation } from 'react-i18next';

import { iframeLink } from 'core/iframe';

import * as List from 'components/list';
// import * as Page from 'components/page';

import { EventDate } from '../../utils/EventDate';
import * as Styled from './StyledOiweekCalendar';

function Event(props) {
    const { data } = props;
    const endDate = new Date(data.endDate);
    const startDate = new Date(data.startDate);

    return (
        <Styled.OiweekCalendarEvent as={Link} to={iframeLink(data.url)}>
            <span className="text">{data.name}</span>
            <EventDate endDate={endDate} startDate={startDate} />
        </Styled.OiweekCalendarEvent>
    );
}

export const OiweekCalendar = props => {
    // const { t } = useTranslation('oiweek');

    const { vars } = props.useOiweek;
    const { oiweek } = vars;

    const [events, setEvents] = React.useState([]);

    useEffectOnce(() => {
        axios
            .get('https://api.openstartups.net/web/v1/events?only=future&language=pt')
            .then(res => res.data)
            .then(res => {
                const filtered = res
                    .filter(r => r._id !== oiweek._id)
                    .filter(r => r.config && r.config.general && r.config.general.isOiweek === true)
                    .filter(r => {
                        const nowDate = new Date();
                        const endDate = new Date(r.endDate);
                        nowDate.setUTCHours(0, 0, 0, 0);
                        endDate.setUTCHours(0, 0, 0, 0);
                        return nowDate <= endDate;
                    })
                    .sort((a, b) => {
                        if (a.startDate > b.startDate) return 1;
                        if (a.startDate < b.startDate) return -1;
                        return 0;
                    });
                setEvents(filtered);
            });
    });

    return !events.length ? null : (
        <Styled.OiweekCalendar as={List.List} name="OiweekCalendar">
            <List.Subtitle text="Próximas edições da Oiweek" />
            <List.Vertical items={events} itemKey="id" itemComponent={Event} />

            {/* <Page.Buttn
                type="light btn-round btn-sm mt-2 justify-content-center"
                to={iframeLink('https://oiweek.com')}
            >
                <span className="text mr-0">{t('C2AInvite.knowMore')}</span>
            </Page.Buttn> */}
        </Styled.OiweekCalendar>
    );
};
export default OiweekCalendar;
