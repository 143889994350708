import { RHP } from 'providers';
import React from 'react';

import * as Styled from './StyledInterests';

export const InterestsCard = ({ children, tags, step }) => {
    const handleClick = () => {
        RHP.push(`/100-10-1/setup/${step}?edit`);
    };

    return !tags || tags.length <= 0 ? null : (
        <Styled.InterestsCard onClick={handleClick}>
            <h2>
                <span className="text">{children}</span>
                <span className="icon far fa-pencil" aria-hidden="true" />
            </h2>
            <ul>{!!tags?.length && tags.map(tag => <li key={tag}>{tag}</li>)}</ul>
        </Styled.InterestsCard>
    );
};
