import styled from 'styled-components';

export const Card = styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
`;

export const LeftGraphicsContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    padding: 0;
    overflow: hidden;
    position: relative;

    .chartDiv {
        top: 0;
        width: 100%;
        height: 110%;
        position: absolute;
        left: -0.5rem;
    }
`;

// LEFT SESSION
export const LeftSession = styled.div`
    display: flex;
    flex-direction: column;
    width: 55%;
    height: 100%;
    padding: 5px 5px;
`;

export const MainLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    gap: 10px;
`;

// Speed dating de executivos e Total de desafios lançados
export const LeftContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 50%;
`;

/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// RIGHT SESSION

export const RightSession = styled.div`
    display: flex;
    flex-direction: row;
    width: 45%;
    height: 100%;
    gap: 10px;
    padding: 5px 5px;
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 50%;
`;
// GRAPHICS CONTAINER
export const GraphicsContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const Title = styled(Card)`
    display: flex;
    padding-left: 1rem;
    align-items: center;
    height: 15%;
    max-height: 15%;
    gap: 10px;
    width: 100%;

    div {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 10px;
        padding: 10px;
    }
    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 3.8rem;
        text-align: left;
    }
    h2 {
        font-size: 1.9rem;
        text-transform: uppercase;
        font-weight: bold;
    }
    h3 {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
    }
`;
export const NewChallengesTitle = styled(Card)`
    display: flex;
    padding-left: 1rem;
    align-items: center;
    height: 5%;
    max-height: 5%;
    padding-top: 3.5rem;
    gap: 10px;
    width: 100%;

    h2 {
        font-size: 1.9rem;
        text-transform: uppercase;
        font-weight: bold;
    }
`;
export const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-top: 10px;
`;
// NOVAS CANDIDATURAS A DESAFIOS
export const ChallengesApplicationsLogosContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 100%;
`;

export const IndividualLogoContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 100%;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: auto;
        max-height: 90px;
    }

    img {
        margin: auto;
        width: 100%;
        height: 90px;
        object-fit: contain;
    }

    p {
        text-align: center;
    }
`;

// RIGHT SESSION GRAPHICS
export const BarChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    &:last-child {
        margin-bottom: 0px;
    }
    span {
        margin-bottom: 10px;
        display: flex;
        max-width: 100%;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }
    h1 {
        font-weight: normal;
        font-size: 2rem;
        display: flex;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }

    p {
        display: flex;
        max-width: 100%;
        flex-wrap: nowrap;
        white-space: nowrap;
        border-radius: 5px;
        font-size: 1.8rem;
        width: ${props => (props.chartWidth ? props.chartWidth : '10%')};
        padding: 7px;
        font-weight: 500;
        background-color: ${props => (props.isActive ? '#00a9c9' : '#afafcf')};
    }
`;

export const ChallengesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    padding: 10px;
    padding-left: 1rem;
    gap: 18px;
`;
