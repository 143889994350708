import React from 'react';
import { useLocation } from 'react-router';

import { iframePush } from 'core/iframe';
import { RHP } from 'providers';
import { useCXI } from 'modules/cxi/useCXI';

import { useMedia } from 'react-use';
import * as Styled from './StyledMenu';

export const MenuItem = ({ badge, className, children, isBlank, to, onClick }) => {
    const { addVars } = useCXI();
    const { pathname } = useLocation();
    const isMobile = useMedia('(max-width: 991px)');

    const active = pathname.includes(to) ? ' is-active' : '';

    const handleClick = () => {
        if (isMobile) {
            addVars({ isMenuOpen: false });
        }
        if (to !== undefined) {
            if (isBlank) return iframePush(to, pathname)();
            return RHP.push(to);
        }
        return onClick();
    };

    return (
        <Styled.MenuItem className={className + active} onClick={handleClick}>
            {children}
            {!!badge && <span className="badge">{badge}</span>}
        </Styled.MenuItem>
    );
};
