import React from 'react';

import useCXI from 'modules/cxi/useCXI';

import { RHP } from 'providers';
import { useUser } from 'hooks';
import { formatDate } from 'utils/date';

import * as Styled from './StyledBelts';

export const BeltsCard = ({ belt }) => {
    const user = useUser();
    const { setModal, t } = useCXI();

    const handleClick = () => {
        if (['requested'].includes(belt.certificationStatus)) {
            setModal({ modal: 'CertificateRequested' });
            return;
        }
        if (['rejected'].includes(belt.certificationStatus)) {
            setModal({ modal: 'CertificateRejected', certificate: belt });
            return;
        }
        if (['confirmed'].includes(belt.certificationStatus)) {
            RHP.push(`/100-10-1/certification/${user.userid}`);
        }
    };

    let date = '';
    if (belt.certifiedAt) {
        date = formatDate(new Date(belt.certifiedAt ?? ''), 'dd/MM/yyyy');
    }

    return (
        <Styled.BeltsCard onClick={handleClick}>
            <img src={belt.img} alt={belt.name} />
            <span className="text">{belt.name}</span>
            {belt.certifiedAt && !!date && (
                <span className="date">{t(`profile.Belts.earned`, { date })}</span>
            )}
            {belt.certificationStatus === 'confirmed' && (
                <span className="icon fa fa-arrow-to-bottom" />
            )}
        </Styled.BeltsCard>
    );
};
