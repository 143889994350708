import React from 'react';

import blackBelt from 'assets/images/belts/selo-black-belt.png';
import brownBelt from 'assets/images/belts/selo-brown-belt.png';
import greenBelt from 'assets/images/belts/selo-green-belt.png';
import whiteBelt from 'assets/images/belts/selo-white-belt.png';

import { RHP } from 'providers';
import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { useUser } from 'hooks';
import { BeltsCard } from './BeltsCard';
import * as Styled from './StyledBelts';

export const Belts = () => {
    const user = useUser();
    const { t, vars } = useCXI();
    const { certificate } = vars;

    const certifications = certificate?.certifications || [];

    const mappedCertifications = certifications.map(c => {
        const cert = { ...c };
        if (cert.certificationType === 'black') {
            cert.color = 'black';
            cert.img = blackBelt;
            cert.name = 'Black Belt';
        }
        if (cert.certificationType === 'brown') {
            cert.color = 'brown';
            cert.img = brownBelt;
            cert.name = 'Brown Belt';
        }
        if (cert.certificationType === 'green') {
            cert.color = 'green';
            cert.img = greenBelt;
            cert.name = 'Green Belt';
        }
        if (cert.certificationType === 'white') {
            cert.color = 'white';
            cert.img = whiteBelt;
            cert.name = 'White Belt';
        }
        return cert;
    });

    const handleCertificate = () => {
        RHP.push(`/100-10-1/certification/${user.userid}`);
    };

    return !mappedCertifications.length ? null : (
        <Styled.Belts>
            <Styled.BeltsHead>
                <Styled.Title>{t('profile.Belts.title')}</Styled.Title>
                <Styled.Button as={Button} variant="outline" onClick={handleCertificate}>
                    <span className="icon far fa-award" aria-hidden="true" />
                    <span className="text">{t('profile.Belts.goToCertificate')}</span>
                </Styled.Button>
            </Styled.BeltsHead>

            <Styled.BeltsBody>
                {mappedCertifications.map(belt => (
                    <BeltsCard key={belt.name} belt={belt} />
                ))}
            </Styled.BeltsBody>
        </Styled.Belts>
    );
};
