import React from 'react';

import { useConversionTable } from 'hooks';

import { Area } from '../components/area/Area';
import { Tags } from '../components/tags/Tags';
import { useCXIDashboardStartup } from '../useCXIDashboardStartup';

import * as Styled from './StyledCXIDashboardStartupGeneral';

export const CXIDashboardStartupGeneralSectors = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    const conv = useConversionTable(['challenge', 'community', 'industry', 'trend']);

    return (
        <Styled.CXIDashboardStartupGeneralSectors>
            {!!startup.sector?.challenges?.length && (
                <Area className="challenge" title={t('startup.sector.challenges')}>
                    <Tags tags={startup.sector.challenges.map(c => conv.challenge[c])} />
                </Area>
            )}

            {!!startup.sector?.communities?.length && (
                <Area className="community" title={t('startup.sector.communities')}>
                    <Tags tags={startup.sector.communities.map(c => conv.community[c])} />
                </Area>
            )}

            {!!startup.sector?.industries?.length && (
                <Area className="industry" title={t('startup.sector.industries')}>
                    <Tags tags={startup.sector.industries.map(c => conv.industry[c])} />
                </Area>
            )}

            {!!startup.sector?.trends?.length && (
                <Area className="trend" title={t('startup.sector.trends')}>
                    <Tags tags={startup.sector.trends.map(c => conv.trend[c])} />
                </Area>
            )}
        </Styled.CXIDashboardStartupGeneralSectors>
    );
};
