import React from 'react';

import { UserCompany, UserStartups } from 'core/components/user';

import * as Form from 'components/form';
import * as List from 'components/list';
import * as Page from 'components/page';

import * as Styled from './StyledProfileIndex';

export const ProfileIndexView = props => (
    <Styled.ProfileIndex as={Page.Page} name="ProfileIndex" back="/home">
        <Form.Field name="editAvatar" type="EditAvatar" />

        <Page.Title>{props.fullname}</Page.Title>
        <Page.Descr>{props.t('ProfileIndex.descr')}</Page.Descr>

        <Page.Ruler type="dots" />

        <UserCompany user />
        <UserStartups />

        <List.List name="ProfileIndexList">
            <List.Vertical items={props.actions} itemKey="href" />
        </List.List>

        <Page.Discl>{props.t('ProfileIndex.disclaimer')}</Page.Discl>

        <Page.Buttn type="round btn-sm btn-primary" to="/logout">
            <span className="text">{props.t('glossary:logout')}</span>
        </Page.Buttn>
    </Styled.ProfileIndex>
);
export default ProfileIndexView;
