import React from 'react';
import { useParams } from 'react-router';

import { CG } from 'contexts';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { Header } from 'modules/cxi/components/header/Header';
import { useCXI } from 'modules/cxi/useCXI';
import { RHP } from 'providers';

import { useEffectOnce } from 'react-use';
import * as Styled from './StyledCXIMessagesMatch';
import { Messages } from '../components/messages';

export const CXIMessagesMatch = () => {
    const { state: global } = React.useContext(CG);
    const { t, vars, getMatchMessages, postMatchMessage, setModal } = useCXI();
    const { matchId } = useParams();

    const scrollEnd = React.useRef(null);
    const [messages, setMessages] = React.useState([]);

    const match = vars.matches.find(m => m.matchid === matchId);

    // const scrollToBottom = React.useCallback(() => {
    //     scrollEnd.current.scrollIntoView({ behavior: 'smooth' });
    // }, []);

    const updateMessages = React.useCallback(
        async id => {
            const result = await getMatchMessages(id);
            setMessages(result);
            if (messages.length !== result.length) {
                // scrollToBottom();
            }
        },
        [messages]
    );

    const form = useForm();

    const field = {
        error: form.errors.message,
        name: 'message',
        type: 'text',
        placeholder: t('messages.match.message'),
        required: true,
        value: form.values.message,
        onChange: form.handleChange
    };

    const onSubmit = async values => {
        const payload = { lang: global.language, text: values.message };
        try {
            await postMatchMessage(matchId, payload);
            form.clearValues();
            form.setSubmit(false);
            // updateMessages(matchId);
        } catch (e) {
            form.setSubmit(false);
        }
    };

    useEffectOnce(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    React.useEffect(() => {
        if (matchId) {
            updateMessages(matchId);
        }
    }, [matchId]); // eslint-disable-line

    React.useEffect(() => {
        if (!matchId) {
            return () => {};
        }
        const timeout = setTimeout(() => updateMessages(matchId), 60000);
        return () => clearTimeout(timeout);
    }, [matchId]);

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    const buttonHistory = {
        label: t('messages.match.feedbackHistory'),
        onClick: () => setModal({ match, modal: 'Evaluations' })
    };
    const buttonDetails = {
        label: t('messages.match.goToStartup'),
        onClick: () => RHP.push(`/100-10-1/startup/${match.matchid}`)
    };

    return !match ? null : (
        <Styled.CXIMessagesMatch>
            <Header back button={buttonHistory}>
                <div>
                    {t('messages.match.talkingWith')} <strong>{match.startup.name}</strong>
                </div>
                <Styled.HeaderHeadButton onClick={buttonDetails.onClick}>
                    <span className="bttn-text">{buttonDetails.label}</span>
                </Styled.HeaderHeadButton>
            </Header>

            <Styled.CXIMessagesMatchScroll>
                <Messages match={match} messages={messages} />
                <Styled.CXIMessagesMatchScrollEnd ref={scrollEnd} />
            </Styled.CXIMessagesMatchScroll>

            <Styled.CXIMessagesMatchForm as={Form.Form} onSubmit={evSubmit}>
                <Form.Field {...field} />
                <Form.Submt {...form} icon="fa-paper-plane" />
            </Styled.CXIMessagesMatchForm>
        </Styled.CXIMessagesMatch>
    );
};
