import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useApi } from './useApi';

export function useApiLocation() {
    const { globalState } = React.useContext(CG);
    const { geolocation } = globalState;
    const api = useApi();

    // Create the initial value
    const initial = {
        lat: geolocation.lat || undefined,
        lng: geolocation.lng || undefined,
        result: []
    };

    // Handle state for api variables
    const [variables, setVariables] = React.useState(initial);
    const appendState = newState => {
        setVariables(oldState => ({ ...oldState, newState }));
    };

    function geo({ country, state }) {
        // Get the cities
        if (country && state) {
            return api.get(`/geo/${country}/${state}`).then(res => {
                // console.log('useLocationApi', `/geo/${country}/${state}`, res);
                appendState({ cities: res });
                return res;
            });
        }
        // Get the states
        if (country) {
            return api.get(`/geo/${country}`).then(res => {
                // console.log('useLocationApi', `/geo/${country}`, res);
                appendState({ states: res });
                return res;
            });
        }
        // Get the countries
        return api.get(`/geo`).then(res => {
            // console.log('useLocationApi', `/geo`, res);
            appendState({ countries: res });
            return res;
        });
    }

    function getByName(name) {
        // Prepare string to be passed
        const payload = {
            name,
            lat: variables.lat || undefined,
            lng: variables.lng || undefined
        };
        // Get the cities from the api
        return api.post(`/near`, payload).then(res => {
            // console.log('useLocationApi', 'getByName', `/near`, res);
            appendState({ result: res });
            return res;
        });
    }

    function near({ lat, lng }) {
        return api.post(`/near`, { lat, lng }).then(res => {
            // console.log('useLocationApi', 'near', `/near`, res);
            appendState({ lat, lng, result: res });
            return res;
        });
    }

    function nearFromIp() {
        const url = 'https://extreme-ip-lookup.com/json/';
        return fetch(url)
            .then(res => res.json())
            .then(res => {
                appendState({ lat: res.lat, lng: res.lon });
                return near({ lat: res.lat, lng: res.lon });
            })
            .catch(err => console.error(err));
    }

    return {
        getByName,
        geo,
        near,
        nearFromIp,
        variables
    };
}
export default useApiLocation;
