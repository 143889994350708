import { useUser } from 'hooks';
import React from 'react';
import { useParams } from 'react-router';

import { CXICertificationContextProvider } from './CXICertificationContext';
import { CXICertificationRoutes } from './CXICertificationRoutes';
import { useCXICertification } from './useCXICertification';

export function CXICertificationModule(props) {
    const { vars, t } = useCXICertification();
    const { ready } = vars;
    return !ready ? null : <CXICertificationRoutes t={t} />;
}

export const CXICertification = () => {
    const user = useUser();
    let { userId } = useParams();

    if (!userId) {
        userId = user.userid;
    }

    return (
        <CXICertificationContextProvider userId={userId}>
            <CXICertificationModule />
        </CXICertificationContextProvider>
    );
};
export default CXICertification;
