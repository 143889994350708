import styled from 'styled-components';
import background from 'assets/images/bg-news.svg';
import { positions } from 'utils/styles';

export const NewsIndex = styled.div.attrs({
    className: 'styled-news-index',
})`
    h1 {
        font-size: 4rem;
        text-transform: uppercase;
    }
    .reflection {
        font-size: 4rem;
        text-transform: uppercase;
        transform: rotate(180deg);
        text-align: right;
        margin-top: -23px;
        -webkit-text-fill-color: #5b0643;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #ffffff;
    }

    .c-page-navbar {
        --primary: #fff;
    }

    .list-vertical {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: -2rem 0 0 -4rem;
    }
    .list-vertical-item {
        padding: 4rem 0 0 4rem;
    }
    @media (min-width: 567px) {
        .list-vertical-item {
            flex: 0 0 50%;
        }
    }
    @media (min-width: 992px) {
        .list-vertical-item {
            flex: 0 0 33.333%;
        }
    }
`;

export const Background = styled.div.attrs({
    className: 'styled-news-index-background',
})`
    background: #5b0643;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 3rem;
    &::before {
        background: no-repeat 0 0;
        background-color: inherit;
        background-image: url(${background});
        background-size: cover;
        content: '';
        margin-top: -12rem;
        position: absolute;
        ${positions(0, 'auto', 0, '50%')}
        transform: translateX(-50%);
        width: 102vw;
    }
    & > * {
        position: relative;
    }
`;

export const MuchoLoco = styled.div.attrs({
    className: 'styled-news-index-muchoLoco',
})`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1;
    margin-top: 3rem;
    text-transform: uppercase;
    .g,
    .p,
    .b {
        display: inline-block;
        padding: 0.5rem 0.5rem 0.4rem;
    }
    .g {
        background: #67bd9a;
    }
    .p {
        background: #dd3789;
        margin-left: 2rem;
    }
    .b {
        background: #10bde0;
    }
    img {
        bottom: 0;
        position: absolute;
        right: 0;
    }
`;
