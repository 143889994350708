import styled, { css } from 'styled-components';

export type Variant =
    | 'default'
    | 'blue'
    | 'green'
    | 'outlineg'
    | 'link'
    | 'tutorial'
    | 'tag'
    | 'keep'
    | 'purple'
    | 'yellow'
    | 'outline'
    | 'gradient'
    | 'success'
    | 'toggle'
    | 'claret'
    | 'pink'
    | 'gray'
    | 'share'
    | 'linkShare';

export type Size = 'small' | 'medium' | 'large' | 'challenge';

const renderButtonSize = {
    small: css`
        padding: 0.8rem 1.6rem;
        font-weight: 600;
        font-size: 1.2rem;
    `,
    medium: css`
        padding: 1.2rem 2.4rem;
        font-weight: 600;
        font-size: 1.4rem;
    `,
    large: css`
        padding: 1.6rem 3.2rem;
        font-size: 1.6rem;
        font-weight: bold;
    `,
    challenge: css`
        padding: 1rem 2rem;
        font-size: 1.2rem;
        font-weight: bold;
    `
};

const renderButtonVariant = {
    default: css`
        background: ${({ theme }) => theme.colors.energyBlue};
        color: #fff;

        &:focus,
        &:hover {
            background-color: #4598ab;
        }
    `,
    blue: css`
        border: 2px solid ${({ theme }) => theme.colors.deepBlue};
        background: ${({ theme }) => theme.colors.deepBlue};
        color: #fff;

        &:focus,
        &:hover {
            background: #fff;
            color: ${({ theme }) => theme.colors.deepBlue};
        }
    `,
    keep: css`
        border: 2px solid ${({ theme }) => theme.colors.deepBlue};
        background: ${({ theme }) => theme.colors.deepBlue};
        color: #fff;
    `,
    green: css`
        border: 2px solid #56c1d6;
        background: #56c1d6;
        color: #fff;

        &:focus,
        &:hover {
            background: #fff;
            color: #56c1d6;
        }
    `,
    outlineg: css`
        background: transparent;
        color: #56c1d6;

        border: 2px solid #56c1d6;

        &:focus,
        &:hover {
            background: #56c1d6;
            color: #fff;
            border-color: #3fa9f5;
        }
    `,
    link: css`
        background: transparent;
        border: 0;
        color: ${({ theme }) => theme.colors.deepBlue};
        font-weight: 600;
        padding: 0;

        &:focus,
        &:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.darkBlue};
        }
    `,
    tutorial: css`
        background: pink;
        border: 0;
        color: #ffffff;
        font-color: ${({ theme }) => theme.colors.darkBlue};
        font-weight: 600;
        padding: 0;

        &:focus,
        &:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.darkBlue};
        }
    `,
    tag: css`
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.black};

        &:focus,
        &:hover {
            cursor: default;
        }
    `,
    purple: css`
        background: ${({ theme }) => theme.colors.deepBlue};
        color: #fff;

        &:focus,
        &:hover {
            box-shadow: inset 0px 2px 3px ${({ theme }) => theme.colors.deepBlue};
        }
    `,
    yellow: css`
        background: ${({ theme }) => theme.colors.yellowDarker};
        color: #fff;

        &:focus,
        &:hover {
            box-shadow: inset 0px 2px 3px ${({ theme }) => theme.colors.yellowDarker};
        }
    `,
    outline: css`
        background: transparent;
        color: #0094f9;

        border: 2px solid #c3c6d0;

        &:focus,
        &:hover {
            background: transparent;
            color: #3fa9f5;
            border-color: #3fa9f5;
        }
    `,
    gradient: css`
        background: transparent linear-gradient(75deg, #5438ff 0%, #a53a87 100%) 0% 0% no-repeat
            padding-box;
        color: #fff;

        &:focus,
        &:hover {
            box-shadow: inset 0px 2px 3px #2a0ddf66;
        }
    `,
    success: css`
        background: #00d672;
        color: #fff;

        &:focus,
        &:hover {
            background: #00c267;
        }
    `,
    toggle: css`
        background: #e93535;
        color: #fff;
    `,
    claret: css`
        background: #a53a87;
        color: #fff;

        &:focus,
        &:hover {
            opacity: 0.8;
        }
    `,
    pink: css`
        background: #8b39ae;
        color: #fff;

        &:focus,
        &:hover {
            box-shadow: inset 0px 2px 3px #8b39ae;
        }
    `,
    gray: css`
        background: #e0e0e0;
        color: #adadad;
        cursor: default !important;

        &:focus,
        &:hover {
            background: #e0e0e0;
        }
    `,
    share: css`
        background: #56c1d6;
        color: #ffffff;
        cursor: default !important;
        margin-left: auto;

        &:focus,
        &:hover {
            background: #e0e0e0;
        }
    `,
    linkShare: css`
        background: #56c1d6;
        color: #ffffff;
        cursor: default !important;
        width: 250px;

        &:focus,
        &:hover {
            background: #e0e0e0;
        }
    `
};

export const Button = styled.button<{ size: Size; variant: Variant }>`
    padding: 0.8rem 1.6rem;
    border: none;
    border-radius: 0.8rem;
    width: fit-content;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    font-size: 14px;
    outline: none !important;
    transition: 0.2s;

    ${({ size }) => renderButtonSize[size]}
    ${({ variant }) => renderButtonVariant[variant]}

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;
