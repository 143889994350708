import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Container = styled.header`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    background: ${({ theme }) => theme.colors.white};
    display: flex;
    height: 80px;
    justify-content: space-between;
    min-height: 80px;
    padding: 0 2.4rem;
    padding-top: var(--ion-safe-area-top);
    position: relative;

    .navbar-content {
        align-items: center;
        display: flex;
        gap: 2.4rem;
        position: relative;
        width: 100%;
        z-index: 300;
    }

    .navbar-element {
        // overflow: hidden;
        position: absolute;
        ${positions(0, 'auto', 0, 0)}
        width: 22rem;

        &::before {
            background: ${({ theme }) => theme.colors.energyBlue};
            content: '';
            height: 400px;
            position: absolute;
            ${positions('0', '100%', 'auto', 'auto')}
            transform: rotate(45deg) translate(-98px, -370px);
            width: 400px;
            z-index: 300;
        }
    }
`;

export const Logo = styled.img`
    height: 50px;
    cursor: pointer;
`;

export const MenuButton = styled.button`
    border: none;
    background: transparent;
    outline: none !important;
`;
