import styled from 'styled-components';

export const Step5 = styled.div`
    min-height: 100%;
    position: relative;

    .form-control:focus,
    .iGCocn input.form-control:not(:placeholder-shown) {
        padding-top: 7px;
    }

    .form-container {
        display: flex;
        flex-direction: column;
    }

    .form-row {
        margin-bottom: 1rem;
    }

    .form-row1 {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

        input {
            height: 90px;
        }
    }

    label {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        width: 100%;
    }

    .legend-text {
        font-size: 9px;
        font-weight: normal;
        color: #66696e;
    }

    .legend-link {
        font-weight: normal;
        color: #0094f9;
    }

    textarea {
        border-radius: 8px;
        margin-top: 1px;
        border: 1px solid #ccc;
        padding: 0.5rem;
    }

    textarea::placeholder {
        font-size: 10px;
        font-style: italic;
        margin-left: 5px;
    }
`;

export const Title = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    display: flex;
    color: #0094f9;
`;

export const Subtitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    p {
        color: #0094f9;
        font-weight: 600;
        font-size: 1.4rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;

export const Questions = styled.h1`
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;
