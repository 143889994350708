import styled from 'styled-components';

export const Contents = styled.div`
    margin-top: 4.8rem;
`;

export const ContentsHead = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
        color: ${({ theme }) => theme.colors.deepBlue};
        font-size: 1.4rem;
        font-weight: 600;
    }
    h2 {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 22px;
        margin: 0;
    }
`;

export const ContentsInfo = styled.div`
    margin-top: 1.6rem;
`;

export const ContentsBody = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3.2rem;
    gap: 3.2rem;

    @media (min-width: 768px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;
