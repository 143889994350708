import styled from 'styled-components';

export const Evaluations = styled.div`
    .m-container-body {
        background: #f5f6fa;
        gap: 3.2rem;
    }
`;

export const Evaluation = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;

export const EvaluationInfo = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .date {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.4rem;
        font-weight: 400;
    }

    .text {
        align-items: center;
        color: ${({ theme }) => theme.colors.neutralDark};
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        font-weight: 400;
        gap: 0.4rem;
    }
    .text .icon {
        color: ${({ theme }) => theme.colors.deepBlue};
    }
    .text strong {
        color: ${({ theme }) => theme.colors.deepBlue};
        font-weight: 600;
    }
`;

export const EvaluationText = styled.div`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.black};
    display: inline-flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    position: relative;

    @media (min-width: 992px) {
        text-align: left;
    }
`;
