import React from 'react';
import * as Form from '../../../../components/form';

import { ButtonsForm } from '../components/buttons-form';
import { ProgressBar } from '../components/progress-bar';
import * as Styled from './StyledStep1';

export function Step1Form({ form, onSubmit, t }) {
    const CURRENT_STEP = 1;

    const fields = {
        companyName: {
            name: 'companyName',
            type: 'generic',
            value: form.values.companyName,
            onChange: form.handleChange,
            readOnly: true
        },
        email: {
            name: 'email',
            type: 'generic',
            value: form.values.email,
            onChange: form.handleChange,
            readOnly: true
        },
        name: {
            name: 'name',
            type: 'generic',
            value: form.values.name,
            onChange: form.handleChange
        },
        position: {
            name: 'position',
            type: 'generic',
            value: form.values.position,
            onChange: form.handleChange
        },
        department: {
            name: 'department',
            type: 'generic',
            value: form.values.department,
            onChange: form.handleChange
        },
        phone: {
            name: 'phone',
            type: 'tell',
            value: form.values.phone,
            onChange: form.handleChange
        }
        // championName: {
        //     name: 'championName',
        //     type: 'text',
        //     value: form.values.championName,
        //     onChange: form.handleChange,
        // },
        // championEmail: {
        //     name: 'championEmail',
        //     type: 'text',
        //     value: form.values.championEmail,
        //     onChange: form.handleChange,
        // },
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Styled.Step1>
            <ProgressBar current={CURRENT_STEP} />

            <Styled.Title>{t('form.step1.title')}</Styled.Title>
            <Styled.Subtitle>{t('form.step1.descr')}</Styled.Subtitle>

            <Form.Form name="ChallengeStep1Form" onSubmit={evSubmit}>
                <Styled.Questions>{t('form.step1.question1')}</Styled.Questions>
                <Form.Field {...fields.companyName} />

                <Styled.Questions>{t('form.step1.question2')}</Styled.Questions>
                <Form.Field {...fields.email} />

                <Styled.Questions>{t('form.step1.question3')}</Styled.Questions>
                <Form.Field {...fields.name} />

                <Styled.Questions>{t('form.step1.question4')}</Styled.Questions>
                <Form.Field {...fields.position} />

                <Styled.Questions>{t('form.step1.question5')}</Styled.Questions>
                <Form.Field {...fields.department} />

                <Styled.Questions>{t('form.step1.question6')}</Styled.Questions>
                <Styled.Phone>
                    <Form.Field {...fields.phone} />
                </Styled.Phone>

                {/*
                <Styled.Questions>{t('form.step1.question6')}</Styled.Questions>
                <Form.Field {...fields.championName} />

                <Styled.Questions>{t('form.step1.question7')}</Styled.Questions>
                <Form.Field {...fields.championEmail} />
                */}

                <br />
                <ButtonsForm currentStep={CURRENT_STEP} />
            </Form.Form>
        </Styled.Step1>
    );
}
