import React from 'react';

import * as Styled from './StyledDashboardNumber';

export const DashboardNumber = ({ active, children, number }) => (
    <Styled.DashboardNumber active={!!active}>
        {number !== undefined && <Styled.Num>{number}</Styled.Num>}
        <Styled.Txt>{children}</Styled.Txt>
    </Styled.DashboardNumber>
);
