import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';

import { MatchActionChevron } from './MatchActionChevron';
import { MatchActionEvaluate } from './MatchActionEvaluate';
import { MatchActionFeedback } from './MatchActionFeedback';
import { MatchActionMentoring } from './MatchActionMentoring';
import { MatchActionReevaluate } from './MatchActionReevaluate';
import { MatchActionSpeedDating } from './MatchActionSpeedDating';
import { MatchActionOptions } from './MatchActionOptions';

import * as Styled from './StyledMatchAction';

export const MatchAction = ({ match }) => {
    const { vars } = useCXI();
    const { isMentoring, mentoring } = vars;

    let stage = 0;
    const dropdown = [];
    let component = null;

    const setStage = s => (stage = s);
    const setComponent = c => (component = c);
    const push = d => dropdown.push(d);

    let hasSetComponent = false;

    // Mentoring
    if (
        match.mentoring !== 'none' &&
        (!isMentoring || (isMentoring && mentoring.matchid === match.matchid))
    ) {
        setStage(3);
        setComponent(<MatchActionMentoring match={match} />);
        hasSetComponent = true;
    } else {
        push(<MatchActionMentoring match={match} />);
    }

    // Speed-Dating
    if (!hasSetComponent && match.evaluated) {
        setStage(2);
        setComponent(<MatchActionSpeedDating match={match} />);
        hasSetComponent = true;
        if (['requested', 'held'].includes(match.speedDating)) {
            setComponent(<MatchActionFeedback match={match} />);
        }
    } else if (['requested', 'held'].includes(match.speedDating)) {
        push(<MatchActionFeedback match={match} />);
    } else {
        push(<MatchActionSpeedDating match={match} />);
    }

    // Evaluation
    if (!hasSetComponent) {
        setStage(1);
        setComponent(<MatchActionEvaluate match={match} />);
        hasSetComponent = true;
        if (match.evaluated) {
            setComponent(<MatchActionReevaluate match={match} />);
        }
    } else if (match.evaluated) {
        push(<MatchActionReevaluate match={match} />);
    } else {
        push(<MatchActionEvaluate match={match} />);
    }

    const renderCurrentAction = () => {
        return component;
    };

    return (
        <Styled.MatchActions className={`stage-${stage}`}>
            {renderCurrentAction()}
            <MatchActionChevron match={match}>
                {dropdown.map((component, idx) => (
                    <React.Fragment key={idx}>{component}</React.Fragment>
                ))}
            </MatchActionChevron>
            <MatchActionOptions match={match} />
        </Styled.MatchActions>
    );
};
