import React, { useContext, useState } from 'react';

import { useForm } from 'components/form';
import * as Page from 'components/page';
import { RHP } from 'providers';

import { CA, CG } from 'contexts';
import { useParams } from 'react-router';
import { EcosystemStep3EditForm } from './EcosystemStep3EditForm';

export function EcosystemStep3Edit(props) {
    const { emitAlert } = React.useContext(CG);

    const { id } = useParams();

    const {
        state: { user }
    } = useContext(CA);

    const {
        vars,
        putUserRelationship,
        getProgramsByCompanyId,
        postProgramByCompanyId
    } = props.useRankingEcosystem;

    const { ranking } = vars;

    const [changed, setChanged] = useState({});

    const relationship = vars.relationships.find(i => i._id === id);

    if (!relationship) {
        RHP.goBack();
    }

    // Initialize a new form handler
    const form = useForm({
        ...relationship,
        programName: {
            label: relationship.program.name || relationship.programName,
            value: relationship.program._id || relationship.programName
        },
        isConfirmed: !!(relationship.startup && relationship.startup.status === 'confirmed')
    });

    // Handle form changes
    const onBlur = {
        companyName: () => changed.companyName && setChanged({ companyName: false }),
        year: () => changed.year && setChanged({ year: false })
    };

    // Handle form changes
    const onChange = {
        companyName: () => setChanged({ companyName: true }),
        year: () => setChanged({ year: true })
    };

    // Handle form submitions
    const onSubmit = async values => {
        try {
            let companyPrograms = [];
            let program = null;

            if (user?.company.id) {
                companyPrograms = await getProgramsByCompanyId(user?.company.id);

                program = companyPrograms?.find(e => e?._id === values.programName.value);

                if (values.programName.__isNew__ || !program) {
                    program = await postProgramByCompanyId(
                        values.programName.label,
                        user?.company.id
                    );
                }
            }

            delete program?.regDate;
            delete program?.companies;

            const payload = {
                _id: values._id,
                entity: 'ecosystem',
                year: values.year,
                programName: values.programName.label,
                description: values.description,
                linkUrl: values.linkUrl,
                ranking
            };
            if (program) {
                payload.program = program;
            }

            await putUserRelationship(payload);

            RHP.push('/ranking/latam/ecosystem/step3');
        } catch (err) {
            emitAlert({
                message: `Ocorreu um erro inesperado, tente novamente mais tarde. Erro: ${err.message}`,
                header: 'Ops...'
            });
        } finally {
            form.submited();
        }
    };

    const propsForm = { ...props, form, onBlur, onChange, onSubmit, relationship };

    return (
        <Page.Page name="EcosystemStep3Edit">
            <Page.Title>{props.t('EcosystemStep2Edit.title')}</Page.Title>
            <Page.Descr>{props.t('EcosystemStep2Edit.descr')}</Page.Descr>

            <EcosystemStep3EditForm {...propsForm} />
        </Page.Page>
    );
}

export default EcosystemStep3Edit;
