import React from 'react';
import { IonItem } from '@ionic/react';

import { ListStepsItemLink } from './ListStepsItemLink';
import * as Styled from './StyledListSteps';

export function ListStepsItem(props) {
    const data = props.data || props.item || undefined;
    const Component = props.itemComponent || ListStepsItemLink;
    return (
        <Styled.ListStepsItem as={IonItem} lines="none">
            <Component data={data} />
        </Styled.ListStepsItem>
    );
}
export const StepsItem = ListStepsItem;
export default ListStepsItem;
