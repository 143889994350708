import styled from 'styled-components';

export const CXIContents = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header.filter {
        margin-top: 2.4rem;
    }
    .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
    }
    .c-header.filter + .c-header.filter {
        margin-top: 0.4rem;
    }
`;

export const CXIContentsList = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3.2rem;
    gap: 3.2rem;

    @media (min-width: 768px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

export const CXIContentsFilters = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 3.2rem 0 4.8rem;
    gap: 3.2rem;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const Filter = styled.div`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    overflow: hidden;
    padding: 2.4rem;
    transition: all 0.3s ease;

    p {
        margin: 0;
    }
    p span {
        font-weight: 700;
    }

    &.investimentos strong {
        color: #2ebf88;
    }
    &.open-innovation strong {
        color: #ff1963;
    }
    &.open-startups strong {
        color: #b846e6;
    }
`;

export const Title = styled.h1`
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
`;

export const Descr = styled.h1`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 1.6rem 0 0;
`;
