import React from 'react';
import { useTranslation } from 'react-i18next';

import { CXIProgressModule } from './CXIProgressModule';
import * as Styled from './StyledCXIProgress';

export function CXIProgress({ done }) {
    const { t } = useTranslation('matches');

    // Make sure that 'done' is at least 0
    const doneNumber = done || 0;

    return (
        <Styled.CXIProgress>
            <Styled.Head>
                <span className="name">{t('CXIProgress.progress')}</span>
                <span className="numb">{doneNumber}/100</span>
            </Styled.Head>
            <Styled.Body>
                <CXIProgressModule name={t('CXIProgress.module100')} size={100} />
                <CXIProgressModule name={t('CXIProgress.module10')} size={50} />
                <CXIProgressModule name={t('CXIProgress.module1')} size={0} />
            </Styled.Body>
        </Styled.CXIProgress>
    );
}
export default CXIProgress;
