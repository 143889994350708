import React from 'react';

import useCXI from 'modules/cxi/useCXI';
import { Area } from '../components/area/Area';
import { Progress } from '../components/progress';

import { useCXIStartup } from '../useCXIStartup';

import * as Styled from './StyledCXIStartupGeneral';
import { CXIStartupGeneralPresentation } from './Presentation';
import { CXIStartupGeneralSectors } from './Sectors';
import { CXIStartupGeneralDetails } from './Details';
import { CXIStartupGeneralRanking } from './Ranking';
import { CXIStartupProfile } from '../profile/CXIStartupProfile';
import { CXIStartupFinancial } from '../financial/CXIStartupFinancial';

export const CXIStartupGeneral = () => {
    const { t, vars } = useCXIStartup();
    const { startup } = vars;
    const cxi = useCXI();
    const { profile } = cxi.vars.profile;

    return (
        <Styled.CXIStartupGeneral>
            <CXIStartupGeneralPresentation />

            <Styled.CXIStartupGeneralSidebar>
                <Area className="details" title={t('startup.details')}>
                    <CXIStartupGeneralDetails />
                    <Progress currentStage={startup.stage} />
                </Area>
            </Styled.CXIStartupGeneralSidebar>

            <Styled.CXIStartupGeneralFullwidth>
                <CXIStartupGeneralRanking />
            </Styled.CXIStartupGeneralFullwidth>

            <CXIStartupProfile />

            <Styled.CXIStartupGeneralSidebar>
                <Area className="preferences" title={t('startup.preferences')}>
                    <CXIStartupGeneralSectors profile={profile} />
                </Area>
            </Styled.CXIStartupGeneralSidebar>

            <CXIStartupFinancial />
        </Styled.CXIStartupGeneral>
    );
};
