import React from 'react';
import { Redirect, Route as ReactRoute, Switch } from 'react-router-dom';

import * as Ranking from './index';

import { ChooseStartup } from './components/choosestartup/ChooseStartup';

export const routes = [
    {
        exact: true,
        path: '/ranking/latam/startup',
        component: Ranking.StartupIndex,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step1',
        component: Ranking.StartupStep1,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step2',
        component: Ranking.StartupStep2,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step3',
        component: Ranking.StartupStep3,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step4',
        component: Ranking.StartupStep4,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step5',
        component: Ranking.StartupStep5,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step6',
        component: Ranking.StartupStep6,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step6/add',
        component: Ranking.StartupStep6Add,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step6/edit/:id',
        component: Ranking.StartupStep6Edit,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step7',
        component: Ranking.StartupStep7,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step7/add',
        component: Ranking.StartupStep7Add,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step7/edit/:id',
        component: Ranking.StartupStep7Edit,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step8',
        component: Ranking.StartupStep8,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step8/add',
        component: Ranking.StartupStep8Add,
        signed: true
    },
    {
        exact: true,
        path: '/ranking/latam/startup/step9',
        component: Ranking.StartupStep9,
        signed: true
    },

    // Ranking pages outside the default modules
    // This page applies to all the modules
    {
        exact: true,
        path: '/ranking/latam/startup/term',
        component: Ranking.RankingTerm
    }
];

const RouteSigned = props => {
    const { vars } = props.props.useRankingStartup;
    if (vars.startupTerm.signed !== true) {
        const propsRedir = {
            pathname: '/ranking/latam/startup/term',
            search: `?returnUrl=${props.path}`
        };
        return <ReactRoute component={() => <Redirect to={propsRedir} />} />;
    }
    return <ReactRoute {...props} />;
};

export const RankingStartupRoutes = props => {
    const { vars } = props.useRankingStartup;
    const { startupDetails, startupTerm } = vars;

    return !startupDetails._id || (startupDetails._id && startupTerm.signed === undefined) ? (
        <ChooseStartup {...props} />
    ) : (
        <Switch>
            {routes.map(r => {
                const Component = r.component || React.createComponent(r.toRender);
                const Route = r.signed ? RouteSigned : ReactRoute;
                const routeProp = { ...r, props, component: undefined };
                return (
                    <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />
                );
            })}
        </Switch>
    );
};
export default RankingStartupRoutes;
