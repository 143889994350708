import React from 'react';

import * as Form from 'components/form';

import { useConversionOptions } from 'hooks/useConversionTable';

export function MergerAddForm(props) {
    const { form, onSubmit } = props;

    const convMergerType = useConversionOptions(['mergerType']);

    const fields = {
        mergerType: {
            error: form.errors.mergerType,
            label: props.t('MergerAdd.mergerType'),
            name: 'mergerType',
            type: 'select',
            options: [
                { name: props.t('glossary:select'), value: '' },
                ...convMergerType.mergerType,
            ],
            required: true,
            value: form.values.mergerType,
            onChange: form.handleChange,
        },
        mergerDate: {
            error: form.errors.mergerDate,
            label: props.t('MergerAdd.mergerDate'),
            name: 'mergerDate',
            displayFormat: 'MM-YYYY',
            type: 'date',
            required: true,
            value: form.values.mergerDate,
            onChange: form.handleChange,
        },
        withWhom: {
            error: form.errors.withWhom,
            topLabel: '',
            label: props.t('MergerAdd.withWhom'),
            name: 'withWhom',
            type: 'text',
            required: true,
            value: form.values.withWhom,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();
        return form.handleSubmit(ev, onSubmit);
    };
    return (
        <Form.Form name="MergerAddForm" onSubmit={evSubmit}>
            <Form.Field {...fields.mergerType} />
            <Form.Row>
                <Form.Field size="6" {...fields.mergerDate} />
                <Form.Field size="6" {...fields.withWhom} />
            </Form.Row>

            <Form.Submt {...form} text={props.t('glossary:save')} />
        </Form.Form>
    );
}
export default MergerAddForm;
