import * as Core from '.';

export const routes = [
    {
        exact: false,
        name: 'Home',
        path: '/home',
        private: true,
        component: Core.Home,
    },
    {
        exact: false,
        name: 'Login',
        path: '/login',
        private: false,
        component: Core.Login,
    },
    {
        exact: false,
        name: 'Logout',
        path: '/logout',
        private: false,
        component: Core.Logout,
    },
    {
        exact: false,
        name: 'Menu',
        path: '/menu',
        private: false,
        component: Core.Menu,
    },
    {
        exact: false,
        name: 'Profile',
        path: '/profile',
        private: true,
        component: Core.Profile,
    },
    {
        exact: false,
        name: 'Register',
        path: '/register',
        private: false,
        component: Core.Register,
    },

    // Route used to call another App inside this one
    // The second parameter must be sent with encodedURIComponent()
    {
        exact: false,
        path: '/iframe/:url',
        private: false,
        component: Core.Iframe,
    },

    // Page module for simple pages
    // Keep this last because it handles fallback
    {
        exact: false,
        path: '/',
        private: false,
        component: Core.Pages,
    },
];
export default routes;
