import React from 'react';

import { Button } from 'modules/cxi/components/button';

import { useStartupCXI } from '../../useStartupCXI';
import { Container } from '../container';
import * as Styled from './StyledMentoringError';

export const MentoringError = () => {
    const { t, modal, setModal } = useStartupCXI();
    const { match } = modal;

    let { mentoringError } = match;
    if (!mentoringError) {
        mentoringError = t('modal.MentoringError.description');
    }

    return (
        <Styled.MentoringError as={Container.Slim}>
            <Container.Head />

            <Container.Body>
                <Styled.Title>{t('modal.MentoringError.title')}</Styled.Title>
                <Styled.Descr>{mentoringError}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.MentoringError>
    );
};
