import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Page from 'components/page';

import { RHP } from 'providers';
import { UserStartupsList } from 'core/components/user';

export const ChooseStartup = props => {
    const { t } = useTranslation('ranking');
    const { ready, vars, addVars } = props.useRankingStartup;
    const { startups, startupId } = vars;

    if (ready && !startups.length) {
        RHP.push('/startup?rankingRedirect=true');
    }

    const handleClick = async startup => {
        // Ranking 2022: removida validação de pais para direcionamento. Todas vão para ranking LATAM
        const { _id } = startup;
        addVars({ startupId: _id });

        // // RETIRADO VALIDACAO em 2022
        // const { country, _id } = startup;
        // if (country === 'BR') {
        //     addVars({ startupId: _id });
        // } else {
        //     emitAlert(t('noRanking', { country }));
        //     iframePush(`${CONFIG.appLinks.startupRanking}?startupId=${_id}`, '/ranking')();
        // }
    };

    return (
        <Page.Page name="ChooseStartup" back="/ranking/latam">
            <Page.Title>{props.t('ChooseStartup.title')}</Page.Title>
            <Page.Descr>{props.t('ChooseStartup.descr')}</Page.Descr>
            <UserStartupsList
                data={startups.map(startup => ({
                    ...startup,
                    name: startupId === startup._id ? t('loadingStartupData') : startup.name,
                    onClick: () => handleClick(startup),
                }))}
            />
        </Page.Page>
    );
};

export default ChooseStartup;
