import styled from 'styled-components';

export const RankingInterestCard = styled.div.attrs({
    classname: 'ranking-interest-card',
})`
    h2 {
        font-size: 1.6rem;
        color: var(--primary);
    }

    p {
        margin: 0;
    }
`;
