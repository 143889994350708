import React from 'react';
import { LocalStorageProvider as LSP, RouterHistoryProvider as RHP } from '../providers';

const initial = {
    notification: {},
    notifications: LSP.get('100os-notifications') || [],
    notificationRoute: '',
    notificationToken: LSP.get('100os-notificationToken') || undefined,
};

const contextReducer = (state, action) => {
    const reduce = {
        // Alerts and Toast for error/warnings/etc
        NOTIFICATION: () => {
            /**
             * TEMPLATE
             * action.notification = {
             *      actionType: 'string', // Type of action taken. Enum: 'click'
             *      data: {
             *          body: 'string',   // Notification text
             *          image: 'string',  // Image that will be shown on notification
             *          route: 'string',  // Route to redirect the app to
             *          title: 'string',  // Notification title
             *      }
             *      notificationSource: 'string', // Source of the notification. Enum: 'web', 'native'
             * }
             */
            const { notifications } = state;
            const notification = { ...action.notification, date: new Date() };

            // Add current notification to the notifications list
            // Only if the notification has a route
            if (notification.route) {
                notifications.push(notification);
                LSP.set('100os-notifications', notifications);
            }

            const reducedValues = {
                notification: notification.silent ? {} : notification,
                notifications,
                notificationRoute: '',
            };

            // Should save route to redirect the user?
            if (notification.actionType) {
                reducedValues.notificationRoute = notification.data.route;
            }

            return { ...state, ...reducedValues };
        },

        NOTIFICATION_TOKEN: () => {
            LSP.set('100os-notificationToken', action.notificationToken || '');
            return { ...state, notificationToken: action.notificationToken };
        },

        NOTIFIED: () => {
            return { ...state, notification: {} };
        },
        REDIRECTED: () => {
            RHP.push(state.notificationRoute);
            return { ...state, notificationRoute: '' };
        },
    }[action.type];
    return (reduce && reduce()) || state;
};

// Create the proper context variables
export const ContextNotify = React.createContext();
export const ContextNotifyProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(contextReducer, initial);
    const stateValue = {
        state,
        dispatch,
        notifyState: state,
        notifyDispatch: dispatch,
        // Shortcut dispatchers for alerts and toasts
        notified: () => dispatch({ type: 'NOTIFIED' }),
        redirect: () => dispatch({ type: 'REDIRECT' }),
    };
    return <ContextNotify.Provider value={stateValue}>{children}</ContextNotify.Provider>;
};

// Create some shortcuts to export
export const CN = ContextNotify;
export const CNP = ContextNotifyProvider;
