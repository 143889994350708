import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';
import * as Page from 'components/page';
import { CG } from 'contexts';
import { InvestorStep3AddForm } from './InvestorStep3AddForm';

export function InvestorStep3Add(props) {
    const conv = useConversionOptions(['investmentType']);
    const { vars, postUserInvestment } = props.useRankingInvestor;
    const { ranking } = vars;
    const ContextGlobal = React.useContext(CG);
    const { emitAlert } = ContextGlobal;
    const [skip, setSkip] = React.useState(false);

    // Initialize a new form handler
    const form = useForm({
        value: { currency: '', amount: '' },
        otherContact: false,
    });

    // Custom onChange events
    const onChange = {
        startup: () => {
            setSkip(false);
            form.emitError({ name: 'startup', error: undefined });
        },
    };

    // Handle form submitions
    const onSubmit = values => {
        if (!values.date) {
            emitAlert(props.t('noDate'));
            form.submited();
            return;
        }
        const date = `${values.date.substring(0, 7)}-01T00:00:00.000+0000`;

        const payload = {
            date,
            type: values.type,
            round: values.round,
            value: values.value,
            ranking,
        };
        // Add CompanyName to investor
        if (values.companyName) {
            payload.investor = { companyName: values.companyName };
        }
        // Remove currency if the value is empty
        if (!values.value.currency) {
            payload.value = undefined;
        }
        // Add startup trough id
        if (values.startup && values.startup._id) {
            payload.startupId = values.startup._id;
        } else if (
            values.startupName &&
            values.contactName &&
            values.contactEmail &&
            values.startupCountry
        ) {
            // Add startup contact information
            payload.startup = {
                name: values.startupName,
                contact: {
                    name: values.contactName,
                    email: values.contactEmail,
                },
                location: {
                    country: values.startupCountry,
                },
            };
        }

        return postUserInvestment(payload)
            .then(() => RHP.push('/ranking/latam/investor/step3'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, skip, setSkip, onChange, onSubmit, vars };

    return (
        <Page.Page name="InvestorStep3Add">
            <Page.Title>{props.t('InvestorStep3Add.title')}</Page.Title>
            <Page.Descr>{props.t('InvestorStep3Add.descr')}</Page.Descr>
            <InvestorStep3AddForm {...propsForm} />
        </Page.Page>
    );
}
export default InvestorStep3Add;
