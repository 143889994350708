import React from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { RegisterCompanySelectForm } from './RegisterCompanySelectForm';

export function RegisterCompanySelect(props) {
    const { vars, putUser } = props.useRegister;

    // Initialize a new form handler
    const form = useForm({
        company: vars.company || {},
    });

    // Handle form submition
    const onSubmit = values => {
        const { company } = values;
        // Do the submition api request
        return putUser({ company })
            .then(() => RHP.push('/register/company'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="RegisterCompanySelect" back="/register/location">
            <Page.Title>{props.t('RegisterCompanySelect.title')}</Page.Title>
            <Page.Descr>{props.t('RegisterCompanySelect.descr')}</Page.Descr>

            <RegisterCompanySelectForm {...propsForm} />
        </Page.Page>
    );
}
export default RegisterCompanySelect;
