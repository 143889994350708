import React from 'react';

import * as Control from '../control';
import * as Input from '../input';

import * as Styled from './StyledFormField';

export function FormField(props) {
    // Build the default parameters for the input
    // The only required field is the 'name'
    const input = {
        autoComplete: props.autoComplete || 'off',
        autoFocus: props.autoFocus || null,
        disabled: props.disabled || false,
        id: props.id || props.name,
        name: props.name,
        maxLength: props.maxLength,
        placeholder: props.placeholder || ' ',
        readOnly: props.readOnly || false,
        required: props.required !== undefined ? props.required : true,
        type: props.type || 'text',
        value: props.value || '',
        max: props.max || undefined,
        min: props.min || undefined,

        // Custom event handlers for the field
        onBlur: props.onBlur || undefined,
        onChange: props.onChange || undefined,
        onClick: props.onClick || undefined,
        onFocus: props.onFocus || undefined
    };

    // Collect the necessary parameters for control
    const controls = {
        // Creating props for field
        field: {
            displayFormat: props.displayFormat,
            presentation: props.presentation,
            input,
            optgroups: props.optgroups,
            options: props.options,
            related: props.related || {},
            clearButton: props.clearButton || false
        },

        // Creating props for error messages
        error: {
            id: `${input.id}-error`,
            htmlFor: input.id,
            text: props.error
        },

        // Creating props for helper
        helper: {
            id: `${input.id}-helper`,
            text: props.helper,
            render: props.helperRender || 'bottom'
        },

        // Creating props for label
        label: {
            id: `${input.id}-label`,
            htmlFor: input.id,
            required: input.required,
            text: props.label
        },

        // Creating props for topLabel
        topLabel: {
            id: `${input.id}-topLabel`,
            htmlFor: input.id,
            required: input.required,
            text: props.topLabel
        },

        // Necessary props for icon
        icons: {
            icon: props.icon
        },

        // Creating props for warning messages
        warning: {
            id: `${input.id}-warning`,
            htmlFor: input.id,
            text: props.warning
        }
    };

    // This field have some alerts?
    // Associate the messages using aria attributes
    if (props.error) input['aria-describedby'] = controls.error.id;
    if (props.warning) input['aria-describedby'] = controls.warning.id;
    // Decides what component to render
    const ReturnInputComponent = Input[input.type] || Input.FormInputText;

    return (
        <Styled.FormField name={input.name} type={input.type} size={props.size}>
            <Control.FormControlTopLabel {...controls.topLabel} />
            <ReturnInputComponent {...controls.field} controls={controls}>
                <Control.FormControlLabel {...controls.label} />
                <Control.FormControlIcons {...controls.icons} />
                {controls.helper.render === 'label' && (
                    <Control.FormControlHelper {...controls.helper} />
                )}
            </ReturnInputComponent>

            <Control.FormControlError {...controls.error} />
            <Control.FormControlWarning {...controls.warning} />
            {controls.helper.render === 'bottom' && (
                <Control.FormControlHelper {...controls.helper} />
            )}
        </Styled.FormField>
    );
}
export default FormField;
