import React, { useMemo } from 'react';
import { useEffectOnce } from 'react-use';
import { useParams } from 'react-router';
import Moment from 'moment';

import * as Page from 'components/page';
import * as Form from 'components/form';
import * as Tabs from 'components/tabs';

import * as Components from '../components';
import { buildTimeslot } from '../../utils/buildTimeslot';
import { FreeTimeButton } from './StyledSubscriptionSchedule';

export function SubscriptionSchedule(props) {
    const { eventId } = useParams();

    const { vars, getEventById, getMeetings, postMeeting, putMeeting } = props.useSubscription;
    const { eventTimeslots, meetings } = vars;

    const formattedTimeslots = useMemo(() => {
        const oldTimeslots = eventTimeslots.map(timeslot => Moment(timeslot));
        return oldTimeslots.map(timeslot => buildTimeslot(timeslot));
    }, [eventTimeslots]);

    useEffectOnce(() => {
        getEventById(eventId);
        getMeetings(eventId);
    });

    const handleLockTimeslot = timeslot => {
        postMeeting(timeslot, eventId);
    };

    const handleUnlockTimeslot = meetingId => {
        putMeeting(meetingId, eventId);
    };

    const handleFreeMorning = () => {
        meetings.forEach(meeting => {
            if (new Date(meeting.timeslot).getHours() < '12') {
                putMeeting(meeting._id, eventId, true);
            }
        });
        getMeetings(eventId);
    };

    const handleFreeAfternoon = () => {
        meetings.forEach(meeting => {
            if (new Date(meeting.timeslot).getHours() >= '12') {
                putMeeting(meeting._id, eventId, true);
            }
        });
        getMeetings(eventId);
    };

    const Timeslots = ({ selectedDay }) =>
        formattedTimeslots.map(timeslot => {
            let meetingId = false;
            meetings.forEach(meeting => {
                if (meeting.timeslot === timeslot.isoString) {
                    meetingId = meeting._id;
                }
            });

            let handleClick = () => handleLockTimeslot(timeslot.isoString);

            if (meetingId) {
                handleClick = () => handleUnlockTimeslot(meetingId);
            }

            return selectedDay !== timeslot.ddmm ? null : (
                <Components.ScheduleHourDate
                    key={`${timeslot.ddmm}-${timeslot.time}`}
                    timeslot={timeslot}
                    onClick={handleClick}
                    blocked={!!meetingId}
                />
            );
        });

    return !formattedTimeslots ? null : (
        <Page.Page name="SubscriptionSchedule">
            <Form.Form>
                <Page.Title>{props.t('SubscriptionSchedule.title')}</Page.Title>
                <Page.Descr>{props.t('SubscriptionSchedule.descr')}</Page.Descr>
                <Page.Descr>{props.t('SubscriptionSchedule.freeTime')}</Page.Descr>
                <FreeTimeButton type="button" onClick={handleFreeMorning}>
                    {props.t('SubscriptionSchedule.freeMorning')}
                </FreeTimeButton>
                <FreeTimeButton type="button" onClick={handleFreeAfternoon}>
                    {props.t('SubscriptionSchedule.freeAfternoon')}
                </FreeTimeButton>

                <Tabs.TabsContainer>
                    <Tabs.TabsButtons>
                        {formattedTimeslots.map((timeslot, index) => {
                            const areDifferentDays =
                                timeslot.dd !== formattedTimeslots[index + 1]?.dd;

                            if (areDifferentDays) {
                                return (
                                    <Tabs.Tab
                                        key={`${timeslot.ddmm}-${timeslot.time}`}
                                        name={`timeslot-${timeslot.ddmm}`}
                                    >
                                        {timeslot.ddmm}
                                    </Tabs.Tab>
                                );
                            }
                            return () => null;
                        })}
                    </Tabs.TabsButtons>

                    {formattedTimeslots.map((timeslot, index) => {
                        const areDifferentDays = timeslot.dd !== formattedTimeslots[index + 1]?.dd;

                        if (areDifferentDays) {
                            return (
                                <Timeslots
                                    key={`list-timeslot-${timeslot.ddmm}-${timeslot.time}`}
                                    name={`timeslot-${timeslot.ddmm}`}
                                    selectedDay={timeslot.ddmm}
                                />
                            );
                        }
                        return () => null;
                    })}
                </Tabs.TabsContainer>
                <Page.Buttn to={`/events/${eventId}/subscription/success`}>
                    <span className="text">{props.t('Subscription.next')}</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </Page.Buttn>
            </Form.Form>
        </Page.Page>
    );
}
export default SubscriptionSchedule;
