import styled from 'styled-components';

import background from 'assets/images/bg-cxi.png';

export const CXISetupIndex = styled.div`
    align-items: center;
    background: no-repeat bottom center fixed;
    background-color: ${({ theme }) => theme.colors.lightGray};
    background-image: url(${background});
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    height: 100%;
    justify-content: center;
`;

export const CXISetupIndexCard = styled.div`
    align-items: center;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    justify-content: center;
    max-width: 68rem;
    padding: 3.2rem;
    text-align: center;
    width: 100%;

    button + button {
        margin-top: -0.8rem;
    }
`;

export const Image = styled.div`
    img {
        height: 100px;
    }
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.black};
    font-size: 2.4rem;
    font-weight: 600;
    margin: 0;
    max-width: 42rem;
`;

export const Descr = styled.p`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0 auto;
    max-width: 58rem;
    text-align: center;
`;
