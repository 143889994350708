import React, { useState } from 'react';
import { useCXI } from 'modules/cxi/useCXI';
import { UserAvatar } from 'core/components/user';
import { Button } from 'modules/cxi/components/button';
import { Container } from '../container';

import * as Styled from './StyledChallengeRestart';

export const ChallengeRestart = () => {
    const { modal, setModal, putChallengeStartupStatus } = useCXI();
    const { challengeId, startup, filters } = modal;

    const [simClicked, setSimClicked] = useState(false);

    const putOrConnect = async e => {
        e.preventDefault();

        const payload = {
            eventStatus: 'applied',
        };

        putChallengeStartupStatus(challengeId, startup._id, payload, filters).then(res => {
            setModal(null);
            setSimClicked(true);
        });
    };

    return (
        <Styled.ChallengeChat as={Container.Slim}>
            <Styled.Avatar>
                <UserAvatar user={{ firstName: startup?.name, photoUrl: startup?.logoUrl }} />
            </Styled.Avatar>

            <Container.Body>
                <Styled.Title> Deseja reiniciar o status dessa startup?</Styled.Title>
                <br />
                <Styled.SubTitle>
                    Ao confirmar, a startup será revertida ao status de candidata neste desafio
                </Styled.SubTitle>
            </Container.Body>

            <Container.Feet>
                <Button variant="blue" onClick={e => putOrConnect(e)} disabled={simClicked}>
                    Sim
                </Button>

                <Button variant="outline" onClick={() => setModal(null)}>
                    Não
                </Button>
            </Container.Feet>
        </Styled.ChallengeChat>
    );
};
