import styled from 'styled-components';

import background from 'assets/images/bg-cxi.png';

export const CXISetupCompleted = styled.div`
    align-items: center;
    background: no-repeat bottom center fixed;
    background-color: ${({ theme }) => theme.colors.lightGray};
    background-image: url(${background});
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
`;

export const CXISetupCompletedCard = styled.div`
    align-items: center;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 48rem;
    padding: 3.2rem;
    text-align: center;
`;

export const Image = styled.div`
    img {
        height: 100px;
    }
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.4rem;
    margin: 3.2rem 0 0;
`;

export const Descr = styled.p`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.4rem;
    font-weight: 400;
    margin: 1rem 0 3.2rem;
    text-align: center;
`;
