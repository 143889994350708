import React from 'react';

import useCXI from 'modules/cxi/useCXI';
import * as Styled from './StyledCardsTradeSpace';

export const CardsTradeSpace = () => {
    const { t } = useCXI();

    return (
        <Styled.CardsTradeSpace>
            <Styled.CardsHead>
                <h2>{t('cards.title')}</h2>
            </Styled.CardsHead>

            <Styled.CardsBody>
                <div className="card">
                    <span className="icon fab fa-whatsapp" aria-hidden="true" />
                    <span className="title">Whatsapp</span>
                    <span className="descr">{t('cards.descr1')}</span>
                    <a
                        className="buttn"
                        href="https://100os.net/whatsapp"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="text">{t('cards.button1')}</span>
                    </a>
                </div>
            </Styled.CardsBody>
        </Styled.CardsTradeSpace>
    );
};
