import React from 'react';

import { useStartupCXI } from 'modules/startup/_startupId/cxi/useStartupCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionReevaluate = ({ match }) => {
    const { t, setModal } = useStartupCXI();

    const handleClick = () => {
        setModal({ match, modal: 'Evaluate' });
    };

    return (
        <Styled.MatchAction className="reevaluate" onClick={handleClick}>
            <span className="text">{t('match.action.reevaluate')}</span>
        </Styled.MatchAction>
    );
};
