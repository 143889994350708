import { useForm } from 'components/form';
import * as Page from 'components/page';
import { Title } from 'components/page';
import { ContextAuth as CA } from 'contexts';
import { useData } from 'modules/corporatehub/context/dataContext';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConfigForm from './ConfigForm';

function ConfigPage(props) {
    const { configObjFromURL } = props;
    const { pushToDashboard, getHubCompanyById, searchCompany } = props.useCorporateHub;
    const { state } = useContext(CA);
    const dataContext = useData();
    const [startDate, setStartDate] = useState();
    const { company } = dataContext.state;
    const { setConfigVars, setCompany } = dataContext.mutations;
    const { isHubGroup } = dataContext.state.company;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    let viewOptions = query.getAll('viewOptions')[0]?.split(' ') || ['startup'];

    useEffect(() => {
        if (!configObjFromURL) {
            getHubCompanyById(state.user.company.id);
        }
    }, []);
    //
    const handleCleanStartDate = e => {
        e.preventDefault();
        form.addValues({ startDate: '' });
    };

    const handleCleanEndDate = e => {
        e.preventDefault();
        form.addValues({ endDate: '' });
    };

    function formatDateToApiCall(date) {
        return new Date(date)?.toISOString()?.split('T')[0];
    }

    const onSubmit = values => {
        const viewOptions = [
            { name: 'startup', display: values.startups },
            { name: 'executives', display: values.executives },
            { name: 'challenges', display: values.challenges },
            { name: 'relationships', display: values.relationships },
            { name: 'group', display: values.group }
        ];

        let filteredViewOptions = viewOptions?.filter(el => {
            return el.display === true;
        });

        if (filteredViewOptions?.length === 0) {
            filteredViewOptions = viewOptions;
        }

        if (values?.displayHeader === undefined) {
            values.displayHeader = true;
        }
        // let startDate = '';
        let endDate = '';
        if (values.startDate) {
            setStartDate(formatDateToApiCall(values.startDate));
        }
        if (values.endDate) {
            endDate = formatDateToApiCall(values.endDate);
        }

        const configUrl = form.values.queryConfig;
        const configVarsStorage = {
            endDate,
            startDate,
            displayHeader: values?.displayHeader,
            viewOptions: filteredViewOptions,
            displayTime: Number(values?.displayTime) * 1000,
            displayMode: values?.displayMode,
            configUrl: configUrl.toString(),
            companyId: form.values.companyId || state.user.company.id,
            includeGroup: company.isHubGroup
        };
        setConfigVars({
            ...configVarsStorage
        });
        localStorage.setItem('hubConfig', JSON.stringify(configVarsStorage));

        pushToDashboard(configUrl);
    };

    let initialFormValues = {
        startups: true,
        relationships: true,
        executives: true,
        challenges: true,
        group: dataContext.state.company.isHubGroup,
        startDate: undefined,
        endDate: undefined,
        displayHeader: true,
        displayTime: 5,
        displayMode: 'interactive',
        searchedCompany: ''
    };

    // FORMATTED TO SET INITIAL VALUES
    viewOptions = viewOptions
        .filter(view => view && view !== 'shareLink')
        .map(view => ({ name: view, display: true }));

    const formattedViewOptions = viewOptions.map(view => {
        if (view.display) return view.name;
        return null;
    });
    if (Object.keys(configObjFromURL).length !== 0) {
        initialFormValues = {
            startups: formattedViewOptions.includes('startup'),
            relationships: formattedViewOptions.includes('relationships'),
            executives: formattedViewOptions.includes('executives'),
            challenges: formattedViewOptions.includes('challenges'),
            group: formattedViewOptions.includes('group'),
            startDate: configObjFromURL.startDate,
            endDate: configObjFromURL.endDate,
            displayHeader: configObjFromURL.displayHeader,
            displayTime: isNaN(configObjFromURL.displayTime / 1000)
                ? 5
                : configObjFromURL.displayTime / 1000,
            displayMode: configObjFromURL.displayMode,
            searchedCompany: ''
        };
    }
    const form = useForm({ ...initialFormValues });
    const propsForm = {
        form,
        onSubmit,
        formatDateToApiCall,
        state,
        company,
        setCompany,
        getHubCompanyById,
        searchCompany,
        ...props
    };

    return (
        <Page.Page name="CorporateHubConfigPage">
            <Title>Configurar Dashboard</Title>
            <ConfigForm
                {...propsForm}
                useCorporateHub={props.useCorporateHub}
                handleCleanStartDate={handleCleanStartDate}
                handleCleanEndDate={handleCleanEndDate}
                isHubGroup={isHubGroup}
            />
        </Page.Page>
    );
}

export default ConfigPage;
