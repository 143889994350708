import styled, { keyframes } from 'styled-components';

const pulse = () => keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
        transform: scale(0.95);
    }
    70% {
        box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
        transform: scale(0.95);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(1);
    }
`;

export const Loading = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    height: 100%;
    justify-content: center;

    p {
        max-width: 24rem;
        text-align: center;
    }

    .startup-image {
        animation: ${pulse()} 3s infinite;
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        padding-top: 9.5rem;
        transform: scale(1);
        width: 9.5rem;
    }
`;
