import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledCertificateRejected';

export const CertificateRejected = () => {
    const { t, modal, setModal } = useCXI();
    const { certificate } = modal;

    return (
        <Styled.CertificateRejected as={Container.Slim}>
            <Container.Head>
                <Styled.Icons>
                    <span className="icon fal fa-award" aria-hidden="true" />
                </Styled.Icons>
            </Container.Head>

            <Container.Body>
                <Styled.Title>{t('modal.CertificateRejected.title')}</Styled.Title>
                <Styled.Descr>{t('modal.CertificateRejected.description')}</Styled.Descr>
            </Container.Body>

            {!!certificate?.rejectedComment && (
                <Container.Feet>
                    <Styled.Reason>{certificate.rejectedComment}</Styled.Reason>
                </Container.Feet>
            )}

            <Container.Feet>
                <Button onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.CertificateRejected>
    );
};
