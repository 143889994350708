import React from 'react';

import { Area } from '../components/area/Area';

import { CXIChallengeStartupSummariesTechnology } from './Technology';
import { CXIChallengeStartupSummariesResearcher } from './Researcher';

import * as Styled from './StyledCXIDashboardStartupSummaries';

export const CXIChallengeStartupSummaries = props => {
    const { startup, t } = props;
    return (
        <Styled.CXIChallengeStartupSummaries>
            {!!startup?.demographics?.techStatus && (
                <Area className="technology" title={t('startup.technology')}>
                    <CXIChallengeStartupSummariesTechnology startup={startup} t={t} />
                </Area>
            )}

            {!!startup?.demographics?.researcher && (
                <Area className="researcher" title={t('startup.researcher')}>
                    <CXIChallengeStartupSummariesResearcher startup={startup} t={t} />
                </Area>
            )}
        </Styled.CXIChallengeStartupSummaries>
    );
};
