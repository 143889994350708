import styled from 'styled-components';

export const InscribedStartup = styled.table`
    @media (min-width: 792px) {
        width: 360 px;
    }
`;

export const InscribedStartupThead = styled.thead`
    @media (min-width: 792px) {
        width: 360px;
    }
`;
export const InscribedStartupTbody = styled.tbody``;

export const InscribedStartupTd = styled.td`
    background: white;
    padding: 1.6rem;
    text-align: center;
    width: 300px;

    &:first-child {
        text-align: left;
    }
`;

export const InscribedStartupTd1 = styled.td`
    background: white;
    padding: 1.6rem;
    text-align: left;
    width: 300px;

    @media (min-width: 992px) and (max-width: 1200px) {
        display: none;
    }

    @media (max-width: 992px) {
        display: none;
    }
`;

export const InscribedStartupTd2 = styled.td`
    background: white;
    padding: 1.6rem;
    text-align: left;
    width: 300px;

    @media (max-width: 992px) {
        display: none;
    }
`;

export const InscribedStartupTh = styled.th`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    padding: 0 0 0.6rem 1.2rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: 992px) {
        padding: 0 0 1.2rem 1.2rem;
    }

    &:first-child {
        padding-left: 0 !important;
        text-align: left;
    }
    &:last-child {
        padding-left: 1.2rem;
        padding-right: 0;
        text-align: center;
        width: 200px;
    }
`;

export const InscribedStartupTh1 = styled.th`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    padding: 0 0 0.6rem 1.2rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: 992px) and (max-width: 1200px) {
        display: none;
    }
    @media (max-width: 992px) {
        display: none;
    }
`;
export const InscribedStartupTh2 = styled.th`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    padding: 0 0 0.6rem 1.2rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    @media (max-width: 992px) {
        display: none;
    }
`;

export const InscribedStartupTr = styled.tr`
    border-top: 1px solid ${({ theme }) => theme.colors.whiteDarker};

    &:first-child {
        border-top: 0;

        td:first-child {
            border-top-left-radius: 0.8rem;
        }
        td:last-child {
            border-top-right-radius: 0.8rem;
        }
    }

    &:last-child {
        td:first-child {
            border-bottom-left-radius: 0.8rem;
        }
        td:last-child {
            border-bottom-right-radius: 0.8rem;
        }
    }
`;

export const InscribedStartupEmpty = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 3.2rem;
    text-align: center;
`;
export const InscribedStartupEmptyTitle = styled.h2`
    color: rgba(0, 0, 0, 0.9);
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 1.6rem;
`;
export const InscribedStartupEmptyDescr = styled.h2`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.3rem;
    font-weight: 400;
    margin: 0.8rem 0 1.2rem;
`;
