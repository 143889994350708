import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextGlobal as CG } from 'contexts';

export function useForm(initial = {}) {
    const { t } = useTranslation('form');
    const { globalDispatch } = useContext(CG);

    // State managers for this hook to work
    const [errors, setErrors] = useState({});
    const [submit, setSubmit] = useState(false);
    const [values, setValues] = useState(initial);
    const [warnings, setWarnings] = useState({});

    // Easier handler for form submition
    const submited = () => setSubmit(false);
    // Easier handler for value addition
    const addValues = useCallback(val => setValues(v => ({ ...v, ...val })), []);

    const obj2Return = {
        errors,
        submit,
        values,
        warnings,
        setErrors,
        setSubmit,
        addValues,
        setValues,
        setWarnings,
        submited,
    };

    // Alert and error emition to form elements

    const emitError = ({ name, error }) =>
        setErrors(oldErrors => {
            const newErrors = { ...oldErrors, [name]: error };
            if (error === undefined) delete newErrors[name];
            return newErrors;
        });
    const emitWarning = ({ name, warning }) =>
        setWarnings(oldWarnings => {
            const newWarnings = { ...oldWarnings, [name]: warning };
            if (warning === undefined) delete newWarnings[name];
            return newWarnings;
        });

    // Cleanup functions

    const clearMessages = ({ name }) => {
        emitError({ name });
        emitWarning({ name });
    };
    const clearValues = vals => {
        const newValues = vals || initial;
        setValues(newValues);
    };

    // Some default handlers for validations

    const hasEqualValues = (ev, field, msg) => {
        const { name, value } = ev.target;
        const { error, warning } = msg;
        // Get the name from the proper place
        const emitName = msg.name || name;
        // Only check if both variables exist
        // And if the variables have the same name
        if (!value || !values[field] || name === field) return false;

        if (error) emitError({ name: emitName });
        if (warning) emitWarning({ name: emitName });

        // Check if the field values are equal
        // Only when the user has stopped typing
        if (value && values[field] && value !== values[field]) {
            if (error) emitError({ name: emitName, error });
            if (warning) emitWarning({ name: emitName, warning });
            return false;
        }
        return true;
    };

    // Form components handlers (see options)

    const handleBlur = (ev, callback = () => {}) => {
        ev.persist();
        // What? No target? EXIT!!!
        if (!ev.target) return callback(false);
        // Updates the values on the state
        return callback(ev);
    };

    let handleChangeTimeout;
    const handleChange = (ev, callback = () => {}) => {
        ev.persist();
        // What? No target? EXIT!!!
        if (!ev.target) return callback(false);
        // Updates the values on the state
        const { name, value } = ev.target;
        addValues({ [name]: value });
        // Use timeout for changes callbacks
        clearTimeout(handleChangeTimeout);
        handleChangeTimeout = setTimeout(() => callback(ev), 100);
        return true;
    };

    const handleSubmit = (ev, callback = () => {}) => {
        ev.preventDefault();
        ev.stopPropagation();

        // Check for errors before submition
        if (Object.getOwnPropertyNames(errors).length > 0) {
            globalDispatch({ type: 'ALERT', alert: t('useForm.submitError') });
            return false;
        }

        // Check for required fields before submit

        // Everything is set. Submit!
        setSubmit(true);
        return callback(values);
    };

    return {
        ...obj2Return,
        emitError,
        emitWarning,
        clearMessages,
        clearValues,
        hasEqualValues,
        handleBlur,
        handleChange,
        handleSubmit,
    };
}
export default useForm;
