import React from 'react';
import * as Styled from './StyledFormFieldset';

export const FormFieldset = props => (
    <Styled.FormFieldset {...props}>{props.children}</Styled.FormFieldset>
);
export const Fieldset = FormFieldset;

export const FormFieldsetLabel = props => (
    <Styled.FormFieldsetLabel {...props}>{props.children}</Styled.FormFieldsetLabel>
);
export const FieldsetLabel = FormFieldsetLabel;

export const FormFieldsetLegend = props => (
    <Styled.FormFieldsetLegend {...props}>{props.children}</Styled.FormFieldsetLegend>
);
export const FieldsetLegend = FormFieldsetLegend;

export default FormFieldset;
