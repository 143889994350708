import styled from 'styled-components';

export const Container = styled.div`
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
`;

export const Modal = styled.div`
    display: flex;
    align-items: center;
    justify-content: stretch;
`;

export const MessageContent = styled.div`
    background: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 18px 0 0 18px;
    height: 600px;
    padding: 0 82px 0 60px;

    h1 {
        margin-bottom: 22px;
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 26px;
        font-weight: bold;
    }

    p {
        color: ${({ theme }) => theme.colors.darkGray};
        max-width: 392px;
        font-size: 18px;
        text-align: justify;
        line-height: 22px;
        font-weight: bold;
        margin-bottom: 16px;
    }
`;

export const MessageContentActions = styled.div`
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

export const StartupContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;
    border-radius: 0 18px 18px 0;
    background: #f2f2f3;
    padding: 0 84px;
`;

export const Avatar = styled.img`
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 26px;
    font-weight: bold;
`;

export const Badges = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 8px 0;
`;

export const Tag = styled.p`
    padding: 4px 6px;
    background: ${({ theme }) => theme.colors.energyBlue};
    color: ${({ theme }) => theme.colors.white} !important;
    border-radius: 12px;
    font-size: 8px !important;
    opacity: 1 !important;
    font-weight: bold;
`;

export const LocationText = styled.strong`
    .icon {
        color: ${({ theme }) => theme.colors.energyBlue};
        margin-right: 5px;
    }

    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 12px;
`;

export const Subtitle = styled.p`
    margin-top: 30px;
    max-width: 208px;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkBlue};
    opacity: 0.5;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
`;
