import React from 'react';

import { Area } from '../components/area/Area';
import { useCXIDashboardStartup } from '../useCXIDashboardStartup';

import * as Styled from './StyledCXIDashboardStartupProfile';

export const CXIDashboardStartupProfile = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    return (
        <Styled.CXIDashboardStartupProfile>
            {startup.fullDescription?.challenge && (
                <Area
                    className="challenge"
                    descr={t('startup.challengeDescr')}
                    title={t('startup.challenge')}
                >
                    <p>{startup.fullDescription.challenge}</p>
                </Area>
            )}

            {startup.fullDescription?.value && (
                <Area className="value" descr={t('startup.valueDescr')} title={t('startup.value')}>
                    <p>{startup.fullDescription.value}</p>
                </Area>
            )}

            {startup.fullDescription?.validation && (
                <Area
                    className="validation"
                    descr={t('startup.validationDescr')}
                    title={t('startup.validation')}
                >
                    <p>{startup.fullDescription.validation}</p>
                </Area>
            )}

            {startup.fullDescription?.client && (
                <Area
                    className="client"
                    descr={t('startup.clientDescr')}
                    title={t('startup.client')}
                >
                    <p>{startup.fullDescription.client}</p>
                </Area>
            )}

            {startup.fullDescription?.resources && (
                <Area
                    className="resources"
                    descr={t('startup.resourcesDescr')}
                    title={t('startup.resources')}
                >
                    <p>{startup.fullDescription.resources}</p>
                </Area>
            )}

            {startup.fullDescription?.needs && (
                <Area className="needs" descr={t('startup.needsDescr')} title={t('startup.needs')}>
                    <p>{startup.fullDescription.needs}</p>
                </Area>
            )}

            {startup.fullDescription?.patentLicensing && (
                <Area
                    className="patentLicensing"
                    descr={t('startup.patentLicensingDescr')}
                    title={t('startup.patentLicensing')}
                >
                    <p>{startup.fullDescription.patentLicensing}</p>
                </Area>
            )}
        </Styled.CXIDashboardStartupProfile>
    );
};
