import React from 'react';

import anonAvatar from 'assets/images/anon-avatar.jpg';
import { UserAvatar } from 'core/components/user';
import { useConversionTable } from 'hooks';
import { formatDate } from 'utils/date';

import useStartupCXI from '../../useStartupCXI';

import * as Styled from './StyledFeedback';

export const Feedback = ({ match }) => {
    const { t } = useStartupCXI();

    let evaluator = { ...match.evaluator, company: match.company };
    if (match.anonymous) {
        const company = { name: 'Este avaliador optou em manter-se anônimo.' };
        evaluator = { ...evaluator, company, photoUrl: anonAvatar };
    }

    const conv = useConversionTable(['contributionTheme']);

    let theme = t('feedback.noTheme');
    if (match.theme) {
        theme = conv.contributionTheme[match.theme] ?? match.theme;
    } else if (match.cxiStage === 3) {
        theme = t('feedback.theme.contribution');
    } else if (match.cxiStage === 2) {
        theme = t('feedback.theme.feedback');
    } else if (match.cxiStage === 1) {
        theme = t('feedback.theme.evaluation');
    }

    const date = formatDate(new Date(match.regDate), "dd/MM/yyyy, 'às' H'h'mm");

    return (
        <Styled.Feedback>
            <Styled.FeedbackUser>
                <UserAvatar user={evaluator} />
                <Styled.Name>{evaluator.fullName}</Styled.Name>
                <Styled.Desc>{evaluator.company?.name}</Styled.Desc>
            </Styled.FeedbackUser>
            <Styled.FeedbackText>
                {!!theme && <span className="theme">{theme}</span>}
                {!!match.question && <span className="question">{match.question}</span>}
                <span className="text">&quot;{match.comment}&quot;</span>
                <span className="date">{date}</span>
                <span className="caret fas fa-caret-left" aria-hidden="true" />
            </Styled.FeedbackText>
        </Styled.Feedback>
    );
};
