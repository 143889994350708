import React from 'react';
import { useData } from 'modules/corporatehub/context/dataContext';
import * as Styled from './StyledInteractiveHeader';
import { UpperSection } from './upperSection/UpperSection';
import * as Nav from '../navigation';

function InteractiveHeader(props) {
    const { handlePageChange, activeComponent } = props;
    const dataContext = useData();
    const { isHubGroup } = dataContext?.state?.company;
    const { configVars, company } = dataContext.state;
    const shareLink = `http://app.openstartups.net/corporatehub/${configVars.configUrl}`;
    const configLink = configVars?.configUrl?.split('/startups')[1];
    return (
        <Styled.Header>
            <Styled.CXINetworks>
                <UpperSection button={null} title="Painel de Performance" shareLink={shareLink}>
                    <Nav.Navigation>
                        <Nav.Page
                            path={`/corporatehub/config${configLink}`}
                            handleComponent={() => handlePageChange}
                        >
                            <span className="icon far fa-arrow-left" aria-hidden="true" />
                            Configurações
                        </Nav.Page>
                        <Nav.Page
                            handleComponent={{ handlePageChange, activeComponent }}
                            component="startup"
                        >
                            Startups
                        </Nav.Page>
                        <Nav.Page
                            handleComponent={{ handlePageChange, activeComponent }}
                            component="relationships"
                        >
                            Relacionamentos
                        </Nav.Page>
                        <Nav.Page
                            handleComponent={{ handlePageChange, activeComponent }}
                            component="challenges"
                        >
                            Desafios
                        </Nav.Page>
                        <Nav.Page
                            handleComponent={{ handlePageChange, activeComponent }}
                            component="executives"
                        >
                            Executivos
                        </Nav.Page>
                        {isHubGroup && (
                            <Nav.Page
                                handleComponent={{ handlePageChange, activeComponent }}
                                component="group"
                            >
                                Grupo Empresarial
                            </Nav.Page>
                        )}
                    </Nav.Navigation>
                    <Styled.CompanyLogo>
                        <img src={company.logourl} alt="Logo da empresa" />
                    </Styled.CompanyLogo>
                </UpperSection>
            </Styled.CXINetworks>
        </Styled.Header>
    );
}

export default InteractiveHeader;
