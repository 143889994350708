import React from 'react';
import { useTranslation } from 'react-i18next';

import { RankingInvestorRoutes } from './RankingInvestorRoutes';
import { useRankingInvestor } from './useRankingInvestor';

export function RankingInvestor(props) {
    const { t } = useTranslation('ranking');
    const hookd = useRankingInvestor();
    return !hookd.ready ? null : <RankingInvestorRoutes t={t} useRankingInvestor={hookd} />;
}
export default RankingInvestor;
