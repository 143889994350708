import React from 'react';
import { ChallengeCard } from '../challenge-card/ChallengeCard';
import CXIChallengesEmpty from '../challengesEmpty/CXIChallengesEmpty';
import * as Styled from './StyledChallengesList';

export const CXIChallengesGeneral = props => {
    const { challenges } = props;

    return (
        <Styled.ChallengesGeneral>
            {challenges?.length === 0 && <CXIChallengesEmpty />}
            {challenges?.map((challenge, index) => {
                return (
                    <div key={challenge._id}>
                        <ChallengeCard data={challenge} />
                        {index !== challenges.length - 1 && <br />}
                    </div>
                );
            })}
        </Styled.ChallengesGeneral>
    );
};

export default CXIChallengesGeneral;
