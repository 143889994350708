import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const DashboardArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    height: 100%;

    .dashboardNumbersContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    ${truncate(1, 1.2)}
`;

export const Area = styled.div`
    align-items: stretch;
    background: #fff;
    border-radius: 0.8rem;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 2.4rem;
    justify-content: space-between;
    padding: 2.4rem;
    position: relative;

    @media (min-width: 992px) {
        align-items: flex-start;
        flex-direction: row;
    }

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            align-items: flex-start;
        }
    }
    @media (min-width: 1200px) {
        align-items: flex-start;
    }
`;

export const Infos = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 0.8rem;
    order: 2;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            order: 1;
        }
    }
    @media (min-width: 1200px) {
        order: 1;
    }
`;

export const Graph = styled.div`
    // align-self: center;
    order: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            order: 2;
        }
    }
    @media (min-width: 1200px) {
        order: 2;
    }
`;
