import React from 'react';
import { AboutUser } from 'modules/ranking/ranking-latam/_startup/components/user/AboutUser';
import logoRanking from 'assets/images/logo-ranking-2022.svg';

import * as List from 'components/list';
import * as Page from 'components/page';

import { CG } from 'contexts/ContextGlobal';
import * as Styled from './styles';

import { NextStep } from './next-step';

const country = {
    pt: 'Brasil',
    en: 'Brazil',
    es: 'Brasil'
};

export const StartupIndexView = props => {
    const { state } = React.useContext(CG);

    const { vars } = props.useRankingStartup;
    const { startupDetails } = vars;

    return (
        <Page.Page
            name="StartupIndex"
            back="/ranking/latam"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Page.Image>
                <img src={logoRanking} alt="Logo Ranking 100 Open Startups" width="40%" />
            </Page.Image>

            <Page.Title>
                {props.t('StartupIndex.title', { country: country[state.language] })}
            </Page.Title>
            <Page.Descr>{props.t('StartupIndex.descr')}</Page.Descr>

            <Page.Ruler type="dots" />

            <Styled.StartupDetails>
                <span className="startup-title">{props.t('StartupIndex.accessingAs')}</span>{' '}
                <span className="startup-name">{startupDetails.name}</span>
            </Styled.StartupDetails>

            <List.List name="StartupIndexSteps">
                <List.Steps items={props.steps} itemKey="step" />
            </List.List>

            <NextStep {...props} />

            <Page.Ruler type="dots" />

            <AboutUser />

            <List.List name="StartupIndexActions">
                <List.Vertical items={props.actions} itemKey="id" />
            </List.List>
        </Page.Page>
    );
};
