import React from 'react';

import { useCXIStartup } from '../../useCXIStartup';
import { StartupCardActions } from './StartupCardActions';
import { StartupCardSocials } from './StartupCardSocials';
import * as Styled from './StyledStartupCard';

export const StartupCard = () => {
    const { t, vars } = useCXIStartup();
    const { startup } = vars;

    return (
        <Styled.StartupCard>
            <Styled.StartupCardColumn className="image">
                <Styled.Image img={startup.logoUrl} />
            </Styled.StartupCardColumn>

            <Styled.StartupCardColumn className="texts">
                <Styled.Title>
                    <div className="header">
                        {startup.name}
                        <Styled.Level>
                            <span className="level">
                                {t('matches.StartupCard.level')}: {startup.level}/5
                            </span>
                        </Styled.Level>
                    </div>
                </Styled.Title>

                <Styled.Descr>{startup.description}</Styled.Descr>

                <Styled.IconWithText>
                    <span className="icon fas fa-map-marker-alt fa-lg" aria-hidden="true" />
                    <span className="text">{startup.fullLocation || '-'}</span>
                </Styled.IconWithText>

                <StartupCardSocials />
            </Styled.StartupCardColumn>

            <Styled.StartupCardColumn className="actions">
                <StartupCardActions />
            </Styled.StartupCardColumn>
        </Styled.StartupCard>
    );
};
