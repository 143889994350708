import React, { useState, useEffect } from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';
import * as Alert from 'components/alert';

import { useEffectOnce } from 'react-use';
import { useApiLocation } from 'hooks';

import { StartupCard } from '../components';

export function InvestorStep3AddForm(props) {
    const { conv, form, skip, setSkip, onChange, onSubmit } = props;
    const apiLocation = useApiLocation();

    // STATES
    const [isCorporateInvestmentSelected, setIsCorporateInvestmentSelected] = useState(false);
    const [currencyType, setCurrencyType] = useState('');
    const [countries, setCountries] = useState([]);
    const [requiredCountryWarning, setRequiredCountryWarning] = useState();
    const [renderRequiredCountryWarning, setRenderRequiredCountryWarning] = useState(false);

    const handleInvestmentValue = value => {
        if (isCorporateInvestmentSelected) {
            return { currency: currencyType, amount: value.amount };
        }
        return { currency: value.currency, amount: value.amount };
    };

    // Create form fields object
    const fields = {
        startup: {
            // disabled: skip,
            error: form.errors.startup,
            label: props.t('glossary:startup'),
            name: 'startup',
            type: 'Startup',
            value: form.values.startup,
            onChange: ev => form.handleChange(ev, onChange.startup),
            required: !form.values.startupName,
        },

        otherContact: {
            error: form.errors.otherContact,
            label: props.t('glossary:otherContact'),
            name: 'otherContact',
            type: 'YesNo',
            value: form.values.otherContact,
            onChange: form.handleChange,
        },

        startupName: {
            error: form.errors.startupName,
            label: props.t('glossary:startupName'),
            name: 'startupName',
            type: 'text',
            value: form.values.startupName,
            onChange: form.handleChange,
        },
        contactName: {
            error: form.errors.contactName,
            label: props.t('glossary:contactName'),
            name: 'contactName',
            type: 'text',
            value: form.values.contactName,
            onChange: form.handleChange,
        },
        contactEmail: {
            error: form.errors.contactEmail,
            label: props.t('glossary:contactEmail'),
            name: 'contactEmail',
            type: 'email',
            value: form.values.contactEmail,
            onChange: form.handleChange,
        },
        companyName: {
            error: form.errors.companyName,
            label: props.t('investorCompany'),
            name: 'companyName',
            required: false,
            type: 'generic',
            value: form.values.companyName,
            onChange: form.handleChange,
        },
        startupCountry: {
            error: form.errors.startupCountry,
            label: props.t('glossary:startupCountry'),
            name: 'startupCountry',
            type: 'select',
            value: form.values.startupCountry,
            onChange: form.handleChange,
            options: [{ name: props.t('glossary:select'), value: countries.id }, ...countries],
        },
        type: {
            error: form.errors.type,
            label: props.t('glossary:investmentType'),
            name: 'type',
            options: [
                { name: props.t('glossary:investmentTypeSelect'), value: '' },
                ...conv.investmentType,
            ],
            type: 'select',
            value: form.values.type,
            onChange: form.handleChange,
        },
        value: {
            error: form.errors.value,
            label: props.t('glossary:investmentValue'),
            name: 'value',
            required: false,
            type: 'CurrencyAmount',
            value: handleInvestmentValue(form.values.value),
            onChange: form.handleChange,
            lockSelectCurrency: isCorporateInvestmentSelected,
        },
        date: {
            displayFormat: 'MM/YYYY',
            error: form.errors.date,
            label: props.t('glossary:date'),
            name: 'date',
            type: 'date',
            value: form.values.date,
            onChange: form.handleChange,
            required: false,
        },
        round: {
            error: form.errors.round,
            label: props.t('glossary:round'),
            name: 'round',
            type: 'generic',
            value: form.values.round,
            onChange: form.handleChange,
        },
    };

    const onClick = (ev, currValue) => {
        setSkip(true);
        form.addValues({ startup: {}, startupName: currValue });
        return true;
    };

    useEffect(() => {
        if (fields.type.value === 'Corporate') {
            setIsCorporateInvestmentSelected(true);
            setCurrencyType('USD');
        } else {
            setIsCorporateInvestmentSelected(false);
        }
    }, [fields.type]);

    useEffectOnce(() => {
        apiLocation.geo('').then(res => {
            const names = res.map(country => {
                return { name: country.name, value: country.id };
            });
            setCountries(names);
        });
    });

    useEffect(() => {
        if (
            skip &&
            (fields.startupCountry.value === undefined ||
                fields.startupCountry.value === 'Selecionar')
        ) {
            setRequiredCountryWarning(true);
        } else {
            setRequiredCountryWarning(false);
            setRenderRequiredCountryWarning(false);
        }
    }, [fields.startupCountry.value, skip]);

    const propsRenderRequiredCountryWarningError = {
        descr: props.t('ranking:requiredCountryError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red',
    };
    console.log(form.values.date, 'date');
    // Handle form submition
    const evSubmit = ev => {
        if (skip && requiredCountryWarning) {
            ev.persist();
            ev.preventDefault();
            setRenderRequiredCountryWarning(true);
            return false;
        }
        setRenderRequiredCountryWarning(false);

        if (!skip && !form.values.startup) {
            ev.persist();
            ev.preventDefault();
            form.emitError({ name: 'startup', error: props.t('glossary:startupSelect') });
            return false;
        }

        return form.handleSubmit(ev, onSubmit);
    };

    return (
        <Form.Form name="InvestorStep3Add" onSubmit={evSubmit}>
            <Form.Field {...fields.startup}>
                <Page.LWTXT onClick={onClick}>
                    <span className="link">{props.t('InvestorStep3Add.skipStartup')}</span>
                </Page.LWTXT>
            </Form.Field>

            {(skip || form.values.startup) && (
                <Page.Boxed style={{ marginTop: '0' }}>
                    {skip && (
                        <>
                            <Page.Texts style={{ marginTop: '0' }}>
                                {props.t('InvestorStep3Add.skipStartupText')}
                            </Page.Texts>
                            <Form.Field {...fields.startupName} />
                            <Form.Field {...fields.startupCountry} />
                        </>
                    )}
                    {!!Object.keys(form.values.startup).length && (
                        <>
                            <StartupCard {...form.values} />
                            {/* <Form.Field {...fields.otherContact} /> */}
                        </>
                    )}
                    {(skip || form.values.otherContact === 'true') && (
                        <>
                            <Form.Field {...fields.contactName} />
                            <Form.Field {...fields.contactEmail} />
                        </>
                    )}
                </Page.Boxed>
            )}

            <Page.Ruler />

            <Form.Field {...fields.companyName} />
            <Form.Field {...fields.type} />
            <Form.Field {...fields.value} />
            {/* <Modal.Modal close={() => setIsModalOpen(false)} isOpen={isModalOpen}>
                <CorporateInvestmentAlertModal {...modalProps} />
            </Modal.Modal> */}

            <Form.Row>
                <Form.Field size="4" {...fields.date} />
                <Form.Field size="8" {...fields.round} />
            </Form.Row>
            {renderRequiredCountryWarning && (
                <Alert.AlertRow {...propsRenderRequiredCountryWarningError} />
            )}
            <Form.Submt {...form} text={props.t('InvestorStep3.addInvestment')} />
        </Form.Form>
    );
}
export default InvestorStep3AddForm;
