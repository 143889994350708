import React from 'react';

import { StartupsStartup } from '../startup/StartupsStartup';
import * as Styled from './StyledStartups';

export const Startups = ({ startups, t }) => (
    <Styled.Startups>
        <Styled.StartupsThead>
            <Styled.StartupsTr>
                <Styled.StartupsTh>
                    {t('dashboard.Startups.startup')} 
                    {/* ({startups.length}) */}
                </Styled.StartupsTh>
                <Styled.StartupsTh>{t('dashboard.Startups.matches')}</Styled.StartupsTh>
                <Styled.StartupsTh>{t('dashboard.Startups.a')}</Styled.StartupsTh>
                <Styled.StartupsTh>{t('dashboard.Startups.b')}</Styled.StartupsTh>
                <Styled.StartupsTh>{t('dashboard.Startups.c')}</Styled.StartupsTh>
                <Styled.StartupsTh>{t('dashboard.Startups.d')}</Styled.StartupsTh>
                <Styled.StartupsTh>{t('dashboard.Startups.feedbacks')}</Styled.StartupsTh>
                <Styled.StartupsTh>{t('dashboard.Startups.positive')}</Styled.StartupsTh>
            </Styled.StartupsTr>
        </Styled.StartupsThead>

        <Styled.StartupsTbody>
            {startups.map(startup => {
                const feedbacks =
                    (startup.stage1Feedbacks ?? 0) +
                    (startup.stage2Feedbacks ?? 0) +
                    (startup.stage3Feedbacks ?? 0);
                const feedbacksPositive =
                    (startup.stage1FeedbacksPositive ?? 0) +
                    (startup.stage2FeedbacksPositive ?? 0) +
                    (startup.stage3FeedbacksPositive ?? 0);
                return (
                    <tr key={startup._id}>
                        <td>
                            <StartupsStartup startup={startup} />
                        </td>
                        <td>{startup.suggestionsAccepted ?? 0}</td>
                        <td>{startup.a ?? 0}</td>
                        <td>{startup.b ?? 0}</td>
                        <td>{startup.c ?? 0}</td>
                        <td>{startup.d ?? 0}</td>
                        <td>{feedbacks}</td>
                        <td>{feedbacksPositive}</td>
                    </tr>
                );
            })}
        </Styled.StartupsTbody>
    </Styled.Startups>
);
