import React from 'react';

import { useForm } from 'components/form';
import * as Form from 'components/form';

import { Button } from 'modules/cxi/components/button';

import { useStartupCXI } from '../../useStartupCXI';
import { Container } from '../container';
import * as Styled from './StyledEvaluateForm';
import { Feedback } from '../../components/feedback/Feedback';

export const EvaluateForm = props => {
    const { t, modal, setModal, putMatchEvaluation } = useStartupCXI();
    const { match } = modal || props;

    const form = useForm({ startupFeedback: match.startupFeedback || '' });

    const cn0 = `btn-0 ${form.values.startupFeedback === 'negative' ? 'checked' : ''}`;
    const cn1 = `btn-1 ${form.values.startupFeedback === 'neutral' ? 'checked' : ''}`;
    const cn2 = `btn-2 ${form.values.startupFeedback === 'positive' ? 'checked' : ''}`;

    const onChangeStartupFeedback = startupFeedback => {
        form.addValues({ startupFeedback });
    };

    const onSubmit = async values => {
        const commons = { cxiStage: match.cxiStage };
        const payload = { ...commons, ...values };
        try {
            await putMatchEvaluation(match.matchId, match._id, payload);
            setModal({ match, modal: props.modalSuccess || 'EvaluateSent' });
            form.setSubmit(false);
        } catch (e) {
            form.setSubmit(false);
        }
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    const isSubmitDisabled = form.submit || !form.values.startupFeedback;

    return (
        <Styled.EvaluateForm as={Container} dontClickAway>
            <Form.Form onSubmit={evSubmit}>
                <Container.Head>
                    <span className="text">{t('modal.EvaluateForm.title')}</span>
                </Container.Head>

                <Container.Body>
                    <Feedback match={match} />

                    <Styled.Space />

                    <Styled.Title className="form-title">
                        <span className="text">
                            {t('modal.EvaluateForm.startupFeedback.title')}
                        </span>
                    </Styled.Title>

                    <Styled.EvaluateFormOptions>
                        <Button
                            className={cn0}
                            variant="outline"
                            onClick={() => onChangeStartupFeedback('negative')}
                        >
                            {t('modal.EvaluateForm.startupFeedback.negative')}
                            <span className="icon fas fa-check-circle" aria-hidden="true" />
                        </Button>
                        <Button
                            className={cn1}
                            variant="outline"
                            onClick={() => onChangeStartupFeedback('neutral')}
                        >
                            {t('modal.EvaluateForm.startupFeedback.neutral')}
                            <span className="icon fas fa-check-circle" aria-hidden="true" />
                        </Button>
                        <Button
                            className={cn2}
                            variant="outline"
                            onClick={() => onChangeStartupFeedback('positive')}
                        >
                            {t('modal.EvaluateForm.startupFeedback.positive')}
                            <span className="icon fas fa-check-circle" aria-hidden="true" />
                        </Button>
                    </Styled.EvaluateFormOptions>
                </Container.Body>

                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.EvaluateForm.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.EvaluateForm>
    );
};
