import React from 'react';

import { useStartupCXI } from 'modules/startup/_startupId/cxi/useStartupCXI';

import { MatchConnectionMessage } from './MatchConnectionMessage';
import { MatchConnectionConfirm } from './MatchConnectionConfirm';
import { MatchConnectionSD } from './MatchConnectionSD';

export const MatchConnection = ({ match }) => {
    const { t, vars } = useStartupCXI();

    const { matchId } = match;
    const matchFromMatches = [...vars.matches].find(m => m.matchid === matchId);

    if (match.anonymous || !matchFromMatches) {
        return <>{t('match.connection.noConnection')}</>;
    }
    if (matchFromMatches.status !== 'confirmed') {
        return <MatchConnectionConfirm match={match} />;
    }
    if (matchFromMatches.evaluator && matchFromMatches.evaluator.cxiStage >= 2) {
        return <MatchConnectionSD match={match} />;
    }
    return <MatchConnectionMessage match={match} />;
};
