import React, { createContext } from 'react';

import { Loading } from '../components/loading';

import { useCXI } from '../useCXI';

export const CXIMentoringContext = createContext();
export const CXIMentoringContextProvider = ({ children, matchId }) => {
    const {
        t,
        vars: cxi,
        getMatchById,
        getMatchEvaluations,
        getMatchMentoringSessions,
        getMatchStartup
    } = useCXI();
    const { matches, updateMatch } = cxi;

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState({ loading: true, matchId });
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    React.useEffect(() => {
        if (matchId) {
            Promise.all([
                getMatchById(matchId),
                getMatchEvaluations(matchId),
                getMatchMentoringSessions(matchId),
                getMatchStartup(matchId)
            ]).then(([match, evaluations, sessions, startup]) => {
                addVars({ loading: false, match, evaluations, sessions, startup });
            });
        }
    }, [matchId, matches, updateMatch]);

    const stateValue = { t, vars, addVars, setVars };
    return vars.loading ? (
        <Loading>Carregando Mentoria...</Loading>
    ) : (
        <CXIMentoringContext.Provider value={stateValue}>{children}</CXIMentoringContext.Provider>
    );
};
