import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Styled from './StyledFormCustomCompanyItem';

export const FormCustomCompanyItem = ({ data }) => {
    const item = { ...data };
    const { t } = useTranslation();
    // Check if the item is a link
    if (item.href !== undefined && !item.disabled) {
        item.as = data.as || Link;
        item.to = item.href;
    }
    if (item.disabled) {
        item.href = '#';
    }
    // Check if the item has a custom arrow
    item.arrw = item.arrw || 'far fa-long-arrow-right';
    return (
        <Styled.FormCustomCompanyItem {...item}>
            {/* {console.log(item)} */}
            <Styled.ItemImage img={encodeURI(data.full.logoUrl)} />
            <span className="name">{item.text}</span>
            {data?.full?.domains.map(domain => {
                return <Styled.DomainBadge>{domain}</Styled.DomainBadge>;
            })}
            <span className="levelStatusWrapper">
                {data.full.companyStatus === 'registered' && (
                    <span className="level">
                        <b>Level</b>: {data?.full?.level}
                    </span>
                )}
                {data.full.companyStatus !== 'registered' && (
                    <span className="status">
                        <b>{t('ranking:notRegistered')}</b>
                    </span>
                )}
            </span>

            <span className="code">{data.full.shortId}</span>
            {item.text && <span className={`arrw fa-fw ${item.arrw}`} aria-hidden="true" />}
        </Styled.FormCustomCompanyItem>
    );
};

export default FormCustomCompanyItem;
