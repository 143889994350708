import React from 'react';

import * as Step from '../components/step';
import { Communities } from '../forms/Communities';

export const CXIStep4 = () => (
    <Step.Step currentStep={4}>
        <Communities />
    </Step.Step>
);
