import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as Styled from './StyledCXIVideos';

export const CXIVideosLink = ({ data }) => {
    const { t } = useTranslation('matches');

    const item = { ...data };

    let Button = () => (
        <button disabled type="button">
            <span>{t('CXIVideosLink.watch')}</span>
        </button>
    );

    // Check if the item is a link
    if (item.href !== undefined) {
        item.as = data.as || Link;
        item.to = item.href;
        Button = () => (
            <button type="button">
                <span>{t('CXIVideosLink.watch')}</span>
            </button>
        );
    }

    return (
        <Styled.CXIVideosLink {...item}>
            {item.text && <span className="text">{item.text}</span>}
            <Button />
        </Styled.CXIVideosLink>
    );
};
export const VerticalItemLink = CXIVideosLink;
export default CXIVideosLink;
