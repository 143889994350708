import styled from 'styled-components';

export const FormContent = styled.div.attrs({
    className: 'form-content',
})`
    .c-page-descr {
        color: var(--primary);
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
    }
`;
