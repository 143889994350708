import React from 'react';
import { FormInputCheckbox } from '../checkbox/FormInputCheckbox';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {}
};

export function FormInputCheckboxesOptions(props) {
    const { options, input } = props;

    function handleChange(ev) {
        ev.persist();
        const { name, value } = ev.target;
        const event = { ...fakeEvent };
        event.target.name = input.name;
        event.target.value = input.value;
        if (!event.target.value.length) {
            event.target.value = [];
        }
        if (value) {
            event.target.value.push(name);
        } else {
            const index = input.value.indexOf(name);
            event.target.value.splice(index, 1);
        }
        return input.onChange(event);
    }

    return options.map((o, i) => {
        const inputProp = {
            ...input,
            // id: `${o.name}-${o.value}`,
            // name: `${o.name}[${o.value}]`,
            id: o.value,
            name: o.value,
            type: 'checkbox',
            required: false,
            value: input.value.includes(o.value),
            onChange: handleChange
        };
        const keyProp = `options-${input.name}-${o.value}`;
        return <FormInputCheckbox label={o.name} input={inputProp} key={keyProp} />;
    });
}
export default FormInputCheckboxesOptions;
