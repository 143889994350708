import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const Content = styled.a`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease;

    &:focus,
    &:hover {
        transform: scale(1.1);
    }
`;

export const ContentHead = styled.div`
    border-bottom: 4px solid ${({ theme }) => theme.colors.energyBlue};
    img {
        height: auto;
        width: 100%;
    }

    .investimentos & {
        border-color: #2ebf88;
    }
    .open-innovation & {
        border-color: #ff1963;
    }
    .open-startups & {
        border-color: #b846e6;
    }
`;

export const ContentBody = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    padding: 1.6rem;

    .title {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.3;
        ${truncate(3, 1.3)}
    }

    .descr {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.4;
        margin-top: 0.8rem;
        ${truncate(4, 1.4)}
    }
`;

export const ContentFeet = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    padding: 0 1.6rem 1.6rem;

    .badge {
        background: ${({ theme }) => theme.colors.energyBlue};
        border-radius: 1.6rem;
        color: ${({ theme }) => theme.colors.white};
        display: inline-block;
        font-size: 1.1rem;
        line-height: 1.2rem;
        padding: 0.6rem 0.8rem;
    }
    .investimentos & .badge {
        background-color: #2ebf88;
    }
    .open-innovation & .badge {
        background-color: #ff1963;
    }
    .open-startups & .badge {
        background-color: #b846e6;
    }

    .state {
        align-items: center;
        color: ${({ theme }) => theme.colors.lightGray};
        display: flex;
        flex-direction: row;
        font-size: 1.2rem;
        line-height: 1.2rem;
        gap: 0.4rem;
        justify-content: flex-end;
        text-align: end;

        .icon {
            color: ${({ theme }) => theme.colors.energyBlue};
        }
    }
    .investimentos & .state .icon {
        color: #2ebf88;
    }
    .open-innovation & .state .icon {
        color: #ff1963;
    }
    .open-startups & .state .icon {
        color: #b846e6;
    }
`;
