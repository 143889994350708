import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { ProfileCompanyForm } from './ProfileCompanyForm';

export function ProfileCompany(props) {
    const conv = useConversionOptions(['companySize']);
    const { emitToast } = React.useContext(CG);
    const { vars, putUser } = props.useProfile;

    // Initialize a new form handler
    const form = useForm({
        companyName: vars.company && vars.company.name ? vars.company.name : '',
        companySize: vars.company && vars.company.size ? vars.company.size : '',
        company: vars.company,
        department: vars.department || '',
        position: vars.position || '',
    });

    // Handle form submition
    const onSubmit = values => {
        const { position, department } = values;
        // Build the company variable
        const company = {
            name: values.companyName,
            size: values.companySize,
        };
        // Do the submition api request
        return putUser({ company, position, department })
            .then(() => {
                emitToast(props.t('ProfileCompany.saveSuccess'));
                if (vars.returnTo) {
                    RHP.push(vars.returnTo);
                    return;
                }
                RHP.goBack();
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="ProfileCompany">
            <Page.Title>{props.t('ProfileCompany.title')}</Page.Title>
            <Page.Descr>{props.t('ProfileCompany.descr')}</Page.Descr>

            <ProfileCompanyForm {...propsForm} />
        </Page.Page>
    );
}
export default ProfileCompany;
