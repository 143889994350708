import React from 'react';

import * as Styled from './StyledPageRuler';

export const PageRuler = props => (
    <Styled.PageRuler {...props}>
        {props.type === 'dots' && <span className="dots" aria-hidden="true" />}
        {props.type === 'text' && props.text && <span className="text">{props.text}</span>}
    </Styled.PageRuler>
);
export const Ruler = PageRuler;
export default PageRuler;
