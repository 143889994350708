export const forumsList = [
    {
        stage: 'Forum Etapa 10',
        type: 'Metodologia',
        file: '/files/COMUNIDADE DE PRATICANTES_Forum Etapa 10_Metodologia.pdf',
        title:
            'Como avaliar startups em profundidade e identificar formas de colaboração a partir dos speed-datings da etapa 10?',
        descr:
            'Apresentação do roteiro para reuniões de speed-datings, framework de avaliação de startups em profundidade e exemplos de contribuição para esta etapa.',
        times: 'Participação recomendada: 1 vez',
    },
    {
        stage: 'Forum Etapa 10',
        type: 'Profundidade',
        title: 'Avaliação em rede',
        descr:
            'Pitch de 3 startups mais desejadas pela rede e mentoria de dos participantes e especialistas convidados (Plataformas de Crowdfunding, Redes de Anjos, Super Angels, Venture Capitalists, Corporate Venture Capitalists)',
        times: 'Recomendação: participações recorrentes',
    },
    {
        stage: 'Forum Etapa 100',
        type: 'Metodologia',
        file: '/files/COMUNIDADE DE PRATICANTES_Forum Etapa 100_Metodologia.pdf',
        title: 'Como conhecer e pré-avaliar startups na etapa online?',
        descr:
            'Apresentação do framework de avaliação de startups com exemplos de contribuição para esta etapa',
        times: 'Participação recomendada: 1 vez',
    },
    {
        stage: 'Forum Etapa 100',
        type: 'Tempo e Qualidade',
        title: 'Quantas startups eu consigo contribuir no tempo?',
        descr:
            'Sabemos que o dia a dia do trabalho é corrido, então vamos aproveitar esse momento junto ao grupo de praticantes para avançarmos nas atividades dessa etapa em uma dinâmica prática e objetiva de colaboração.',
        times: 'Participação recomendada: 2 vezes',
    },
    {
        stage: 'Forum Etapa 100',
        type: 'Profundidade',
        title: 'Avaliação em rede',
        descr: 'Pitch de 3 startups mais desejadas pela rede e mentoria coletiva dos participantes',
        times: 'Recomendação: participações recorrentes',
    },
];
