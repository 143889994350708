import React from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';

import { RHP } from 'providers';
import { CG } from 'contexts';
import { Step5Form } from './Step5Form';

export function Step5(props) {
    const { useCXI } = props;
    const { postChallengerForm, vars } = useCXI;
    const { t } = useTranslation('cxi');

    const { emitAlert, state } = React.useContext(CG);

    const { language } = state;

    const conv = useConversionOptions(['companySize']);

    // Initialize a new form handler
    const form = useForm();

    // Handle form submition
    const onSubmit = async values => {
        try {
            const payload = {
                ...vars.challengerForm,
                customQuestions: {
                    ...values
                },
                language
            };

            await postChallengerForm(payload);
            emitAlert(t('form.success'));
        } catch (err) {
            console.error(err);
        } finally {
            RHP.push('/100-10-1/challenges');
        }
    };

    const propsForm = { ...props, conv, form, onSubmit };

    return <Step5Form {...propsForm} />;
}
