import React from 'react';
import * as Styled from './StyledFormInput';

export const FormInputGeneric = props =>
    // Don't show this element if it is empty
    !props.input ? null : (
        <Styled.FormInput name="generic">
            {props.children}
            <input className="form-control" {...props.input} type="text" />
        </Styled.FormInput>
    );
export const generic = FormInputGeneric;
export default FormInputGeneric;
