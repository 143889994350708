import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Header } from 'modules/cxi/components/header/Header';
import { RHP } from 'providers';

import * as Nav from './components/navigation';
import { CXINetworksRoutes } from './CXINetworksRoutes';
import * as Styled from './StyledCXINetworks';

export const CXINetworks = () => {
    const { t, vars } = useCXI();
    const { cxi } = vars;

    const button = {
        label: t('networks.goToMatches'),
        onClick: () => RHP.push(`/100-10-1/matches`),
    };

    return (
        <Styled.CXINetworks>
            <Header button={button} title={t('networks.title')} tooltip={t('networks.tooltip')}>
                <Nav.Navigation>
                    <Nav.Page path="">{t('networks.tab.all')}</Nav.Page>
                    <Nav.Page path="/unevaluated">{t('networks.tab.unevaluated')}</Nav.Page>
                    <Nav.Page path="/evaluated">{t('networks.tab.evaluated')}</Nav.Page>
                    <Nav.Page path="/speed-dating">{t('networks.tab.speed-dating')}</Nav.Page>
                    <Nav.Page path="/mentoring">{t('networks.tab.mentoring')}</Nav.Page>
                </Nav.Navigation>
            </Header>

            <Styled.CXINetworksRoutes>
                <CXINetworksRoutes />
            </Styled.CXINetworksRoutes>
        </Styled.CXINetworks>
    );
};
