import React from 'react';

import { StartupImage } from 'core/components/startup';
import * as Styled from './StyledLoading';
import useStartupCXI from '../../useStartupCXI';

export const Loading = ({ children }) => {
    const { vars } = useStartupCXI();
    const { startup } = vars;
    return (
        <Styled.Loading>
            <StartupImage data={startup} />
            <div>{children}</div>
        </Styled.Loading>
    );
};
