import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
    colors: {
        stage0: '#7f39be',
        stage1: '#3f25dd',
        stage2: '#0094f9',
        stage3: '#56C1D6',
        darkBlue: '#384364',
        darkerstBlue: '#221760',
        darkerBlue: '#00173F',
        yellow: '#E6EC30',
        darkYellow: '#FFDD00',
        yellowDarker: '#FFBB00',
        energyBlue: '#56C1D6',
        energyBlueLight: '#DCF2F6',
        deepBlue: '#0085F2',
        lightBlue: '#E5F4FF',
        blue: '#3FA9F5',
        lightGreen: '#00D672',
        green: '#00C267',
        salmon: '#FF8A76',
        pink: '#A53A87',
        lightPink: '#A53A87B3',
        lighterPink: '#D0C8FF',
        purple: '#5438FF',
        purpleHeart: '#7f39be',
        red: '#E93535',
        redDark: '#FF0000',
        black: '#252830',
        white: '#ffffff',
        whiteDark: '#F8F9FC',
        whiteDarker: '#f0f0f0',
        darkGray: '#6B7882',
        gray: '#8C92A8',
        lightGray: '#B6BFC8',
        lighterGray: '#F5F6FA',
        neutralDark: '#66696E',
    },
};
