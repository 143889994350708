import styled from 'styled-components';

export const ComponentModal = styled.div.attrs({
    className: 'component-modal',
})`
    --min-height: 80vh;

    ion-content {
        --background: transparent;
        --color: #444;
    }
`;

export const ModalContainer = styled.div.attrs({
    className: 'component-modal-container container',
})`
    flex: 1 1 auto;
    padding: 0 1.5rem;
    padding-bottom: calc(1.5rem + var(--ion-safe-area-top));
    position: relative;
`;
