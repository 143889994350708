import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const CXICertificationCertificate = styled.div`
    background: ${({ theme }) => theme.colors.white};
    --cert-color: ${({ theme }) => theme.colors.energyBlue};

    &.white {
        --cert-color: #dadada;
    }
    &.green {
        --cert-color: #0f8e38;
    }
    &.brown {
        --cert-color: #936037;
    }
    &.black {
        --cert-color: #373736;
    }

    border-top: 9px solid var(--cert-color);
    border-bottom: 9px solid var(--cert-color);
    color: #252830;
    height: 569px;
    padding: 37px 46px;
    position: relative;
    width: 994px;
    margin: 2.4rem auto 0 auto;

    border: 9px solid var(--cert-color);
    padding: 37px;

    .el-1,
    .el-2 {
        position: absolute;
        right: -1.5px;
        path {
            fill: var(--cert-color);
        }
    }
    .el-1 {
        top: 0;
    }
    .el-2 {
        bottom: 0;
        transform: rotate(90deg);
    }
`;

export const Logo = styled.div`
    img {
        height: 54px;
    }
`;
export const Text = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 29px;
    text-transform: uppercase;
`;

export const BeltDate = styled.div`
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    bottom: 22px;
    left: 37px;
    text-align: center;

    .val {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        text-transform: uppercase;
    }

    .lbl {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        display: block;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin-top: 8px;
        padding-top: 8px;
        text-transform: uppercase;
    }
    .lbl-sm {
        display: block;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 2px;
    }
`;

export const BeltName = styled.div.attrs({
    className: 'belt-name notranslate'
})`
    background: var(--cert-color);
    color: white;
    display: inline-block;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 96px;
    margin-left: -46px;
    overflow: hidden;
    margin-top: 29px;
    padding: 0 0 0 46px;
    position: relative;
    width: 547px;
    text-transform: uppercase;

    &::after {
        border-top: calc(96px) solid transparent;
        border-bottom: calc(96px) solid transparent;
        border-right: calc(48px) solid white;
        content: '';
        height: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
    }
`;

export const BeltSeal = styled.div`
    position: absolute;
    top: 100px;
    right: 100px;

    img {
        height: 289px;
    }
`;

export const UserName = styled.div`
    color: var(--cert-color);
    font-size: 48px;
    font-weight: 700;
    line-height: 72px;
    ${truncate(1)}
`;

export const UserText = styled.div`
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    margin-top: 5px;
    width: 547px;
`;

export const Signature = styled(BeltDate)`
    right: 144px;
    left: auto;
`;
