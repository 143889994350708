import React, { useEffect, useState } from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';
import { useApiLocation } from 'hooks';
import { useConversionOptions } from 'hooks/useConversionTable';

import * as Alert from 'components/alert';
import { cnpj } from 'cpf-cnpj-validator';
import { StartupCard } from '../components';
import * as Styled from './styles';

export function CorporateStep4AddForm({
    form,
    skip,
    setSkip,
    onChange,
    onBlur,
    onSubmit,
    openModal,
    dateError,
    language,
    useRankingCorporate,
    t
}) {
    const { vars, calculateContractPoints, checkStartupCountry } = useRankingCorporate;

    const { ranking, contractPoints } = vars;

    const convValue = useConversionOptions(['contractValue'], { namespace: 'currency' });
    const convType = useConversionOptions(['contractType']);
    const convEmailLanguage = useConversionOptions(['language']);

    const apiLocation = useApiLocation();

    const [lessThan100Usd, setLessThan100Usd] = useState(false);
    const [renderValueRangeWarning, setRenderValueRangeWarning] = useState(false);
    const [contractType, setContractType] = useState();
    const [countries, setCountries] = useState([]);
    const [requiredCountryWarning, setRequiredCountryWarning] = useState();
    const [renderRequiredCountryWarning, setRenderRequiredCountryWarning] = useState(false);
    const [showEmailLanguageField, setShowEmailLanguageField] = useState(false);

    React.useEffect(() => {
        const { startDate, endDate, formalized, valueRange, contractType } = form.values;
        if (form.values.startup) {
            calculateContractPoints({
                startDate,
                endDate,
                formalized,
                valueRange,
                type: contractType,
                selectedStartup: form.values.startup
            });
        }
    }, [form.values, calculateContractPoints]);

    React.useEffect(() => {
        if (form.values.startup) {
            checkStartupCountry(form.values.startup);
        }
    }, [form.values.startup, checkStartupCountry]);

    useEffect(() => {
        apiLocation.geo('').then(res => {
            const names = res.map(country => {
                return { name: country.name, value: country.id };
            });
            setCountries(names);
        });
    }, []);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Zero-pad the month
    const currentDay = String(currentDate.getDate()).padStart(2, '0'); // Zero-pad the day

    // const currentDate = new Date();
    // const currentYear = currentDate.getUTCFullYear();
    // const currentMonth =
    //     String(currentDate.getUTCMonth() + 1).length === 1
    //         ? `0${currentDate.getUTCMonth() + 1}`
    //         : String(currentDate.getUTCMonth() + 1);
    // const currentDay =
    //     String(currentDate.getUTCDate()).length === 1
    //         ? `0${currentDate.getUTCDate()}`
    //         : String(currentDate.getUTCDate());

    const contractTypeOptions = [
        { name: t('glossary:selectOption'), value: '' },
        ...convType.contractType.filter(type => type.value !== 'none')
    ];
    const emailLanguageOptions = [
        ...convEmailLanguage.language.filter(type => type.value !== 'none')
    ];

    // Create form fields object
    const fields = {
        startup: {
            error: form.errors.startup,
            label: t('glossary:startup'),
            name: 'startup',
            type: 'Startup',
            value: form.values.startup,
            onChange: ev => form.handleChange(ev, onChange.startup),
            required: !form.values.startupName
        },
        otherContact: {
            error: form.errors.otherContact,
            label: t('glossary:otherContact'),
            name: 'otherContact',
            type: 'YesNo',
            value: form.values.otherContact,
            onChange: form.handleChange
        },
        startupName: {
            error: form.errors.startupName,
            label: t('glossary:startupName'),
            name: 'startupName',
            type: 'text',
            value: form.values.startupName,
            onChange: form.handleChange
        },
        contactName: {
            error: form.errors.contactName,
            label: t('glossary:contactNameContractStartup'),
            name: 'contactName',
            type: 'text',
            value: form.values.contactName,
            onChange: form.handleChange
        },
        contactEmail: {
            error: form.errors.contactEmail,
            label: t('glossary:contactEmailContractStartup'),
            name: 'contactEmail',
            type: 'email',
            value: form.values.contactEmail,
            onChange: ev => form.handleChange(ev, onChange.contactEmail)
        },
        executiveResponsibleEmail: {
            error: form.errors.contactConfirmationEmail,
            label: t('glossary:executiveResponsibleEmail'),
            name: 'executiveResponsibleEmail',
            type: 'email',
            defaultValue: form.values.contactEmail,
            value: form.values.executiveResponsibleEmail,
            onChange: form.handleChange,
            required: true
        },
        corporateContractCountry: {
            error: form.errors.corporateContractCountry,
            label: t('glossary:corporateContractCountry'),
            name: 'corporateContractCountry',
            type: 'select',
            // options: countries,
            options: [{ name: t('glossary:select'), value: countries.id }, ...countries],
            value: form.values.corporateContractCountry,
            required: true,
            onChange: ev => {
                form.handleChange(ev);
                form.clearMessages({ name: 'taxId' });
            }
        },
        emailLanguage: {
            error: form.errors.emailLanguage,
            label: t('glossary:emailLanguage'),
            name: 'emailLanguage',
            type: 'select',
            options: emailLanguageOptions,
            required: showEmailLanguageField,
            value: form.values.emailLanguage,
            onChange: form.handleChange
        },
        startupCountry: {
            error: form.errors.startupCountry,
            label: t('glossary:startupCountry'),
            name: 'startupCountry',
            type: 'select',
            value: form.values.startupCountry,
            onChange: form.handleChange,
            required: requiredCountryWarning,
            options: [{ name: t('glossary:select'), value: countries.id }, ...countries]
        },
        taxId: {
            error: form.errors.taxId,
            label: t('glossary:cnpj'),
            name: 'taxId',
            type: 'text',
            onBlur: ev => form.handleBlur(ev, onBlur.isValidCNPJ),
            value: form.values.taxId,
            onChange: form.handleChange,
            required: false
        },
        contractExecutionCountries: {
            error: form.errors.contractExecutionCountries,
            label: t('glossary:contractExecutionCountries'),
            name: 'contractExecutionCountries',
            type: 'multipleSelect',
            // options: countries,
            options: [{ name: t('glossary:select'), value: countries.id }, ...countries],
            value: form.values.contractExecutionCountries,
            required: true,
            onChange: form.setValues
        },
        contractType: {
            error: form.errors.contractType,
            label: t('glossary:contractType'),
            name: 'contractType',
            type: 'select',
            options: contractTypeOptions,
            value: form.values.contractType,
            onChange: form.handleChange,
            icon: (
                <button className="ico-btn" type="button" onClick={openModal}>
                    <span
                        className="icon fa-fw fas fa-info-circle"
                        aria-hidden="true"
                        style={{ color: 'var(--primary)', cursor: 'pointer' }}
                    />
                </button>
            )
        },
        contractDescription: {
            error: form.errors.contractDescription,
            label: t('glossary:contractDescription'),
            name: 'contractDescription',
            maxLength: 500,
            type: 'textarea',
            value: form.values.contractDescription,
            onChange: form.handleChange
        },
        startDate: {
            displayFormat: 'MM-YYYY',
            error: form.errors.startDate,
            presentation: 'month-year',
            label: t('glossary:startDate'),
            maxLength: 4,
            name: 'startDate',
            min: `${String(currentYear - 10)}-${currentMonth}-${currentDay}`,
            max: form.values.endDate || `${String(currentYear)}-${currentMonth}-${currentDay}`,
            type: 'date',
            value: form.values.startDate,
            onChange: ev => form.handleChange(ev, onChange.date)
        },
        endDate: {
            displayFormat: 'MM-YYYY',
            presentation: 'month-year',
            error: form.errors.endDate,
            label: t('glossary:endDate'),
            maxLength: 4,
            name: 'endDate',
            min:
                form.values.startDate || `${String(currentYear - 2)}-${currentMonth}-${currentDay}`,
            max: `${String(currentYear + 10)}-${currentMonth}-${currentDay}`,
            type: 'date',
            value: form.values.endDate,
            onChange: ev => form.handleChange(ev, onChange.date)
        },
        formalized: {
            error: form.errors.formalized,
            label: t('glossary:isContractSignedCorps'),
            name: 'formalized',
            type: 'YesNo',
            required: true,
            value: form.values.formalized,
            onChange: form.handleChange
        },
        valueRange: {
            error: form.errors.valueRange,
            label: t('glossary:contractValueRange'),
            name: 'valueRange',
            type: 'SelectRangeArray',
            options: convValue.contractValue,
            required: true,
            value: form.values.valueRange,
            onChange: form.handleChange,
            onlyIf: form.values.formalized === 'true'
        },
        isUpToDate: {
            error: form.errors.isUpToDate,
            label: t('glossary:profileDataIsUpToDate'),
            name: 'isUpToDate',
            type: 'checkbox',
            required: true,
            value: form.values.isUpToDate,
            onChange: form.handleChange
        },
        isSecret: {
            error: form.errors.isSecret,
            label: t('glossary:isSecret'),
            name: 'isSecret',
            type: 'checkbox',
            required: false,
            value: form.values.isSecret,
            onChange: form.handleChange
        }
    };

    if (form.values.corporateContractCountry === 'BR') {
        fields.taxId.displayFormat = '00.000.000/0000-00';
        fields.taxId.maxLength = 18;
        fields.taxId.onChange = e => {
            e.target.value = cnpj.format(e.target.value);
            form.handleChange(e);
        };
    }

    const onClick = (ev, currValue) => {
        setSkip(true);
        form.addValues({ startup: {}, startupName: currValue });
        form.clearMessages(fields.startup);
        return true;
    };

    // validate contract value
    const highValue = 200000; //
    const handleValue = fieldValue => {
        if (fieldValue !== undefined) {
            return fieldValue[0] < highValue;
        }
    };

    useEffect(() => {
        setContractType(fields.contractType.value);
        setLessThan100Usd(handleValue(fields.valueRange.value));
        if (
            (contractType === 'investment200k' || contractType === 'acquisition200k') &&
            lessThan100Usd
        ) {
            setRenderValueRangeWarning(true);
        } else {
            setRenderValueRangeWarning(false);
        }
    }, [fields]);

    useEffect(() => {
        if (
            skip &&
            (fields.startupCountry.value === undefined ||
                fields.startupCountry.value === 'Selecionar')
        ) {
            setRequiredCountryWarning(true);
        } else {
            setRenderRequiredCountryWarning(false);
            setRequiredCountryWarning(false);
        }
    }, [fields.startupCountry.value, skip]);

    const propsValueRangeError = {
        descr: t('StartupStep8Add.valueRangeError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };

    const propsRenderRequiredCountryWarningError = {
        descr: t('ranking:requiredCountryError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };

    const userLanguage = language;
    let defaultEmailLanguage = language;

    if (form.values.startup?.languages?.length > 0) {
        const startupLanguages = form.values.startup.languages;

        if (startupLanguages.includes(userLanguage)) {
            defaultEmailLanguage = userLanguage;
        } else {
            defaultEmailLanguage = startupLanguages[0];
        }
    }

    if (fields.emailLanguage.value === undefined) {
        fields.emailLanguage.value = defaultEmailLanguage;
    }

    // Handle form submition
    const evSubmit = ev => {
        form.values.emailLanguage = defaultEmailLanguage;
        if (showEmailLanguageField) {
            form.values.emailLanguage = fields.emailLanguage.value;
        }
        if (skip && requiredCountryWarning) {
            ev.persist();
            ev.preventDefault();
            setRenderRequiredCountryWarning(true);
            return false;
        }
        setRenderRequiredCountryWarning(false);

        if (!skip && !form.values.startup) {
            ev.persist();
            ev.preventDefault();
            form.emitError({ name: 'startup', error: t('glossary:startupSelect') });

            return false;
        }

        return form.handleSubmit(ev, onSubmit);
    };

    return (
        <Form.Form name="CorporateStep4Add" onSubmit={evSubmit}>
            <Form.Field {...fields.startup}>
                <Page.LWTXT onClick={onClick}>
                    <span className="link">{t('CorporateStep4Add.skipStartup')}</span>
                </Page.LWTXT>
            </Form.Field>

            {(skip || form.values.startup) && (
                <Page.Boxed style={{ marginTop: '0' }}>
                    {skip && (
                        <>
                            <Page.Texts style={{ marginTop: '0' }}>
                                {t('CorporateStep4Add.skipStartupText')}
                            </Page.Texts>
                            <Form.Field {...fields.startupName} />
                            <Form.Field {...fields.startupCountry} />
                        </>
                    )}
                    {!!Object.keys(form.values.startup).length && (
                        <>
                            <StartupCard {...form.values} />

                            <Form.Field {...fields.otherContact} />
                        </>
                    )}
                    {(skip || form.values.otherContact === 'true') && (
                        <>
                            <Form.Field {...fields.contactName} />
                            <Form.Field {...fields.contactEmail} />
                        </>
                    )}

                    <br />
                    <Styled.LanguageText onClick={() => setShowEmailLanguageField(true)}>
                        {!showEmailLanguageField &&
                            t('CorporateStep4Add.emailLanguageInfo', {
                                startupLanguage: t(
                                    `conversionTable:language.${defaultEmailLanguage}`
                                )
                            })}
                    </Styled.LanguageText>
                    {showEmailLanguageField && <Form.Field {...fields.emailLanguage} />}
                </Page.Boxed>
            )}
            <Form.Field {...fields.corporateContractCountry} />
            <Form.Field {...fields.taxId} />
            <Form.Field {...fields.executiveResponsibleEmail} />

            <Page.Ruler />

            <Styled.StyledFields>
                <Form.Field {...fields.contractType} />
                <Form.Field {...fields.contractExecutionCountries} />
                <Form.Field {...fields.contractDescription} />
            </Styled.StyledFields>

            <Form.Row>
                <Form.Field size="6" {...fields.startDate} />
                <Form.Field size="6" {...fields.endDate} />
                {dateError && (
                    <Styled.DateError className="date-error">
                        {t('StartupStep8Add.dateError')}
                    </Styled.DateError>
                )}
            </Form.Row>

            <Page.Ruler />

            <Form.Field {...fields.formalized} />
            <Form.Field {...fields.valueRange} />

            {renderValueRangeWarning && <Alert.AlertRow {...propsValueRangeError} />}

            <Page.Ruler />

            <Styled.LanguageText>
                {t('StartupStep8Add.maximumScore')} {ranking.year}:{' '}
                <b>
                    {contractPoints} {t('StartupStep8Add.points')}
                </b>
            </Styled.LanguageText>

            <Page.Ruler />

            <Form.Field {...fields.isSecret} />
            <Form.Field {...fields.isUpToDate} />

            {renderRequiredCountryWarning && (
                <Alert.AlertRow {...propsRenderRequiredCountryWarningError} />
            )}
            <Form.Submt
                {...form}
                text={t('glossary:continue')}
                disabled={renderValueRangeWarning}
            />
        </Form.Form>
    );
}
