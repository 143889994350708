import styled from 'styled-components';

export const CardsSupport = styled.div`
    margin-top: 4.8rem;
    flex: 2;
    height: 100%;
`;

export const CardsHead = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
        color: ${({ theme }) => theme.colors.deepBlue};
        font-size: 1.4rem;
        font-weight: 600;
    }
    h2 {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 22px;
        margin: 0;
    }
`;

export const CardsBody = styled.div`
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    gap: 3.2rem;
    margin-top: 1.6rem;
    height: 100%;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 3.2rem;
            height: 100%;
        }
    }
    @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3.2rem;
        height: 100%;
    }

    .card {
        align-items: center;
        background: ${({ theme }) => theme.colors.white};
        border: 0;
        border-radius: 0.8rem;
        box-shadow: 0px 0px 15px #3428680d;
        display: flex;
        flex-direction: column;
        padding: 3.2rem;
        text-align: center;
        min-height: 340px;
        width: 100%;
    }
    .card .icon {
        align-items: center;
        background: ${({ theme }) => theme.colors.energyBlue};
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        font-size: 3.2rem;
        font-weight: 600;
        height: 6.4rem;
        justify-content: center;
        line-height: 1;
        width: 6.4rem;
    }
    .card .title {
        color: rgba(0, 0, 0, 0.9);
        font-size: 1.6rem;
        font-weight: 600;
        margin-top: 1.6rem;
    }
    .card .descr {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.3rem;
        font-weight: 400;
        margin: 0.8rem 0 1.2rem;
    }
    .card .buttn {
        align-items: center;
        background: ${({ theme }) => theme.colors.deepBlue};
        border: 2px solid ${({ theme }) => theme.colors.deepBlue};
        border-radius: 0.8rem;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        padding: 0.8rem 1.6rem;
        font-weight: 600;
        font-size: 1.2rem;
        justify-content: center;
        margin: auto 0 0;
        width: 100%;

        &:focus,
        &:hover {
            background: #4598ab;
            border-color: #4598ab;
            color: white;
        }
    }

 
`;
