import React from 'react';

import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Line,
    LineChart,
    XAxis,
    YAxis,
    Tooltip
} from 'recharts';
import * as Styled from './StyledSessions';

function renamePieChartObjectKeys(Array) {
    const newObject = Array?.map(object => {
        const newObject = {};
        newObject.type = object.community;
        newObject.value = object.value;
        return newObject;
    });

    return newObject;
}

function LeftSession(props) {
    const { data } = props;
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#00721e', '#fe0000'];
    const pieChartData = renamePieChartObjectKeys(data?.mostContractedCommunities);

    const totalContracted = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 0
    }).format(data.totalContractsAmount.total);

    const formatDataForXAxis = chartData => {
        const d = [...chartData];
        const datesAlreadyListed = [];
        for (let i = 0; i < d.length; i++) {
            const year = d[i].date.split('-')[0];
            // if (!datesAlreadyListed.includes(year)) {
            //     datesAlreadyListed.push(year);
            //     d[i].year = year;
            // }
            d[i].year = year;
            datesAlreadyListed.push(year);
        }
        return d;
    };

    return (
        <Styled.LeftSession className="left-session">
            <Styled.LSCommunitiesContainer>
                <Styled.LSCommunitiesGraphicsContainer>
                    <span>
                        <Styled.ChartDiv>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={pieChartData}
                                        cx="50%"
                                        cy="50%"
                                        labelLine
                                        outerRadius={95}
                                        fill="#8884d8"
                                        dataKey="value"
                                        nameKey="type"
                                    >
                                        {pieChartData?.map((entry, index) => {
                                            return entry.type !== 'Outras' ? (
                                                <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                            ) : entry.value > 0 ? (
                                                <Cell key={`cell-${index}`} fill="#c7c7c7" />
                                            ) : null;
                                        })}
                                    </Pie>
                                    <Tooltip separator="" />
                                </PieChart>
                            </ResponsiveContainer>
                        </Styled.ChartDiv>
                        {pieChartData?.length > 1 ? (
                            <Styled.LegendContainer>
                                <ul>
                                    {pieChartData?.map((entry, index) => {
                                        return entry.type !== 'Outras' ? (
                                            <li key={index}>
                                                <Styled.CircledSpanListIcon
                                                    BgColor={COLORS?.[index]}
                                                />
                                                {entry.type}
                                            </li>
                                        ) : entry.value > 0 ? (
                                            <li key={index}>
                                                <Styled.CircledSpanListIcon BgColor="#c7c7c7" />
                                                {entry.type}
                                            </li>
                                        ) : null;
                                    })}
                                </ul>
                            </Styled.LegendContainer>
                        ) : null}
                    </span>
                </Styled.LSCommunitiesGraphicsContainer>

                <h2>COMUNIDADES MAIS CONTRATADAS</h2>
            </Styled.LSCommunitiesContainer>

            <Styled.ContractedStartupsContainer>
                <Styled.ContractedStartupsTitle>
                    <h1>{data.contractedStartupsTotal}</h1>
                    <h2>Startup(s) Contratada(s)</h2>
                </Styled.ContractedStartupsTitle>
                <Styled.ContractedStartupsGraphicsContainer>
                    <div className="chartDiv">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                data={
                                    data.contractedStartupsChartData.length > 0
                                        ? formatDataForXAxis(data.contractedStartupsChartData)
                                        : null
                                }
                                margin={{
                                    top: 15,
                                    right: 40,
                                    left: -20,
                                    bottom: 15
                                }}
                            >
                                <XAxis dataKey="year" tick />
                                <YAxis />
                                <Tooltip />
                                <Line
                                    type="monotone"
                                    dataKey="startups"
                                    stroke="#0088FE"
                                    strokeWidth={4}
                                    activeDot={false}
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </Styled.ContractedStartupsGraphicsContainer>
            </Styled.ContractedStartupsContainer>
            <Styled.ContractedValueContainer>
                <div>
                    <h1 className={totalContracted.length > 8 ? 'smallText' : ''}>
                        {totalContracted}
                    </h1>
                    <h1 className={totalContracted.length > 8 ? 'smallText' : ''}>
                        Contratados de Startups
                    </h1>
                </div>
            </Styled.ContractedValueContainer>
        </Styled.LeftSession>
    );
}

export default LeftSession;
