import React from 'react';

import { ContextAuth as CA } from 'contexts';

import { InvestorInvite } from './InvestorInvite';
// import { InvestorOk } from './InvestorOk';

export function Investor(props) {
    const { state, scopeAllows } = React.useContext(CA);
    const { user } = state;

    if (!scopeAllows('investor')) {
        return null;
    }

    // Set variable to decide witch component to use
    let Component = () => null;

    // Check if the user is an evaluator
    if (user.investorInvited || [3, 4].includes(user.role)) {
        Component = InvestorInvite;
    }

    return <Component />;
}
