import styled from 'styled-components';

export const Matches = styled.table``;

export const MatchesThead = styled.thead``;
export const MatchesTbody = styled.tbody``;

export const MatchesTd = styled.td`
    background: white;
    padding: 1.6rem;
    text-align: center;

    &:first-child {
        text-align: left;
    }
`;

export const MatchesTh = styled.th`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    padding: 0 0 0.6rem 1.2rem;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    width: auto;

    @media (min-width: 992px) {
        padding: 0 0 1.2rem 1.2rem;
    }

    &:first-child {
        padding-left: 0 !important;
        width: 100%;
    }
`;

export const MatchesTr = styled.tr`
    border-top: 1px solid ${({ theme }) => theme.colors.whiteDarker};

    &:first-child {
        border-top: 0;

        td:first-child {
            border-top-left-radius: 0.8rem;
        }
        td:last-child {
            border-top-right-radius: 0.8rem;
        }
    }

    &:last-child {
        td:first-child {
            border-bottom-left-radius: 0.8rem;
        }
        td:last-child {
            border-bottom-right-radius: 0.8rem;
        }
    }
`;
