import React from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';

export function InvestorStep2Form(props) {
    const { conv, form, onSubmit } = props;

    // Create form fields object
    const fields = {
        investorType: {
            error: form.errors.investorType,
            label: props.t('investorType'),
            name: 'investorType',
            options: [{ name: props.t('investorTypeSelect'), value: '' }, ...conv.investorType],
            type: 'select',
            value: form.values.investorType,
            onChange: form.handleChange,
        },
        hasInvestedInThePast: {
            error: form.errors.hasInvestedInThePast,
            label: props.t('hasInvestedInThePast'),
            name: 'hasInvestedInThePast',
            type: 'YesNo',
            value: form.values.hasInvestedInThePast,
            onChange: form.handleChange,
        },
        howCanContribute: {
            error: form.errors.howCanContribute,
            label: props.t('howCanContribute'),
            name: 'howCanContribute',
            options: [...conv.howCanContribute],
            type: 'checkboxes',
            value: form.values.howCanContribute,
            onChange: form.handleChange,
        },

        isQualifiedInvestor: {
            error: form.errors.isQualifiedInvestor,
            label: props.t('isQualifiedInvestor'),
            name: 'isQualifiedInvestor',
            helper: props.t('isQualifiedInvestorHelper'),
            helperRender: 'label',
            type: 'YesNo',
            value: form.values.isQualifiedInvestor,
            onChange: form.handleChange,
        },
        howMuchInvestment: {
            error: form.errors.howMuchInvestment,
            label: props.t('howMuchInvestment'),
            name: 'howMuchInvestment',
            type: 'CurrencyAmount',
            value: form.values.howMuchInvestment,
            onChange: form.handleChange,
            lockSelectCurrency: true,
        },

        isRegistered: {
            error: form.errors.isRegistered,
            label: props.t('isRegistered'),
            name: 'isRegistered',
            type: 'YesNo',
            value: form.values.isRegistered,
            onChange: form.handleChange,
        },
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="InvestorStep2Form" onSubmit={evSubmit}>
            <Form.Field {...fields.investorType} />
            <Form.Field {...fields.hasInvestedInThePast} />
            <Form.Field {...fields.howCanContribute} />

            <Page.Ruler />

            {/* <Form.Field {...fields.isQualifiedInvestor} /> */}
            <Form.Field {...fields.howMuchInvestment} />

            <Page.Ruler />

            <Form.Field {...fields.isRegistered} />

            <Form.Submt {...form} text={props.t('glossary:save')} />
        </Form.Form>
    );
}
export default InvestorStep2Form;
