import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { useApi } from 'hooks';
import { RHP } from 'providers';
import { useAuthContext } from 'hooks/useAuthContext';
import { CXIContext } from './CXIContext';

export function useCXI(bootstrap = false) {
    const { t } = useTranslation('cxi');
    const { state: auth, hasScope, authDispatch } = useAuthContext();
    const { vars, addVars, setVars, ...context } = useContext(CXIContext);
    const api = useApi();

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    // Increments updateMatch
    const suggestMatchUpdate = () => {
        setVars(old => ({ ...old, updateMatch: old.updateMatch++ }));
    };

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function calculateUnreadMessages(updatedMatches) {
        const matches = updatedMatches || vars.matches;
        const unreadMessages = matches.reduce((a, b) => {
            if (typeof a === 'number') return a + parseInt(b.unreadMessages, 10);
            return parseInt(a.unreadMessages, 10) + parseInt(b.unreadMessages, 10);
        }, 0);
        addVars({ unreadMessages });
        return unreadMessages;
    }

    function calculateMatchStage(match) {
        if (match.mentoring !== 'none') {
            return 'mentoring';
        }

        // if (
        //     match.stages?.evaluated === true &&
        //     match.stages?.speedDatingConfirmed &&
        //     match.stages?.speedDatingFeedbackSent
        // ) {
        //     return 'monitoring';
        // }

        if (match.stages.evaluated === true && ['requested', 'held'].includes(match.speedDating)) {
            return 'speed-dating';
        }

        if (match.stages.evaluated === true || match.evaluation?.group) {
            return 'evaluated';
        }

        if (!match.stages.evaluated) {
            return 'unevaluated';
        }

        return 'error';
    }

    function checkMatchStage(match, stage) {
        const calculatedStage = calculateMatchStage(match);
        return stage === calculatedStage;
    }

    function checkPreferences() {
        const data = { ...vars.profile.profile };

        // Normalize some fields for comparassion
        // if (data.internationalStartups === undefined) {
        //     data.internationalStartups = [];
        // } else {
        //     data.internationalStartups = [data.internationalStartups];
        // }

        if (data.xpEvaluating === undefined) {
            data.xpEvaluating = [];
        } else {
            data.xpEvaluating = [data.xpEvaluating];
        }

        if (
            data.businessTypes.length > 0 &&
            data.interestInStartups.length > 0 &&
            // data.internationalStartups !== undefined &&
            data.languages.length > 0 &&
            data.regions.length > 0 &&
            data.sector.challenges.length > 0 &&
            data.sector.communities.length > 0 &&
            data.sector.industries.length > 0 &&
            data.sector.trends.length > 0 &&
            data.stages.length > 0 &&
            data.technologyBases.length > 0 &&
            data.xpEvaluating !== undefined
        ) {
            return true;
        }
        return false;
    }

    async function findMentoringMatch(matches) {
        const found = matches.filter(m => m.mentoring === 'accepted');
        if (found > 1) {
            console.warn('There is more than 1 accepted match', found);
        }
        const match = found[0];
        if (match) {
            addVars({ isMentoring: true, mentoring: match });
            return match;
        }
        return false;
    }

    function mapMatchAndCalculateStage(match) {
        const calculatedStage = calculateMatchStage(match);
        const remapedValues = {};
        if (!match.speedDating && match.speeddating) {
            remapedValues.speedDating = match.speeddating;
        }
        return { ...match, ...remapedValues, calculatedStage };
    }

    async function removeMatchUnreadMessages(readMessages) {
        const { unreadMessages } = vars;
        addVars({ unreadMessages: unreadMessages - parseInt(readMessages, 10) });
    }

    async function removeMatchFromSuggestedMatches(matchId) {
        const { suggestedMatches } = vars;
        const matches = suggestedMatches.matches.filter(m => m.matchid !== matchId);
        addVars({ suggestedMatches: { ...suggestedMatches, matches } });
    }

    async function postChallengerForm(payload) {
        const { userid } = auth.auth;
        try {
            const response = await api.post(`/users/${userid}/challenges`, payload);

            return response;
        } catch (e) {
            throw new Error('CXI: Not able to post the challenger Form');
        }
    }

    async function getCompany() {
        const { company } = auth.user;
        const companyId = company?.id;

        if (!companyId) return false;

        try {
            const result = await api.get(`/companies/${companyId}`);
            addVars({ company: result });
            return result;
        } catch (e) {
            throw new Error('CXI: Not able to get user Company.');
        }
    }

    async function getCXICertificate() {
        const { userid } = auth.auth;
        try {
            const configs = { isErrorHandled: true };
            const result = await api.get(`/users/${userid}/100-10-1/certificate`, {}, configs);
            addVars({ certificate: result });
            return result;
        } catch (e) {
            // Do nothing!
            return {};
        }
    }

    async function getCXIContents() {
        const { userid } = auth.auth;
        try {
            const result = await api.get(`/users/${userid}/100-10-1/contents`);
            addVars({ contents: result.records });
            return result;
        } catch (e) {
            throw new Error('CXI: Not able to get user CXI contents.');
        }
    }

    async function getCXIChallenges(filterQuery) {
        const { userid } = auth.auth;
        if (!filterQuery) filterQuery = '';
        try {
            const result = await api.get(
                `/users/${userid}/challenges?status=${filterQuery}`,
                {},
                {
                    isErrorHandled: true,
                    cache: 'no-store'
                }
            );
            addVars({ challenges: result });
            return result;
        } catch (e) {
            throw new Error('CXI: Not able to get user CXI Challenges.');
        }
    }

    async function getCXIChallengeStartup(startupid, challengeId) {
        try {
            const result = await api.get(
                `/events/${challengeId}/admin/startups/${startupid}/details`,
                {
                    cache: 'no-store'
                }
            );
            return result;
        } catch (e) {
            throw new Error('CXI: Not able to get user CXI Challenge Startup.');
        }
    }

    async function getCXIChallengeStatistics() {
        const { userid } = auth.auth;
        try {
            const result = await api.get(`/users/${userid}/challengesstatistics`, {
                cache: 'no-store'
            });
            addVars({ challengesStatistics: result });
            return result;
        } catch (e) {
            throw new Error('CXI: Not able to get user CXI Challenge Statistics.');
        }
    }

    async function getCXIEvents(eventId, filterQuery = []) {
        if (!filterQuery) filterQuery = '';

        try {
            const result = await api.get(
                `events/${eventId}/admin/startups?eventStatus=${filterQuery}`,
                { cache: 'no-store' },
                { isErrorHandled: true }
            );
            addVars({ startups: result });

            return result;
        } catch (e) {
            throw new Error('CXI: Not able to get event.');
        }
    }

    async function getEventsQuestions(eventId) {
        try {
            const result = await api.get(`events/${eventId}/admin/startupform`);
            addVars({ questions: result });
            return result;
        } catch (e) {
            throw new Error('CXI: Not able to get the questions to event.');
        }
    }

    async function getUserReadCXIContents() {
        const { userid } = auth.auth;
        try {
            const result = await api.get(`/users/${userid}/aggregatedcontents`);
            addVars({ readUserContents: result });
            return result;
        } catch (e) {
            throw new Error('CXI: Not able to get user CXI read contents.');
        }
    }

    async function getCXIProfile() {
        const { userid } = auth.auth;
        try {
            // Need to do this to set the document values
            await api.put(`users/${userid}/100-10-1`);
            const cxi = await api.get(`/users/${userid}/100-10-1`);
            // if (cxi.status !== 'registered') {
            //     throw new Error('CXI: User not registered on 100-10-1');
            // }
            // User is admin but not on the token
            // RefreshToken
            if (cxi.isCxiAdmin && !hasScope('cxiAdmin') && auth.auth.refresh_token) {
                const newAuth = await api.post('/refreshtoken', {
                    userid,
                    refresh_token: auth.auth.refresh_token
                });
                authDispatch({ type: 'UPDATE_AUTH_INFO', auth: newAuth });
            }
            addVars({ cxi: { ...cxi } });
        } catch (e) {
            console.error(e);
            RHP.replace('/home');
        }
    }

    async function getCXIRanking() {
        const { company } = auth.user;
        const companyId = company?.id;

        if (!companyId) return false;

        try {
            // Is company a part of the 100-10-1?
            let parent;
            const results = await api.get(`/companies/${companyId}`);

            // Check parent for company info
            if (results.groupid) {
                parent = await api.get(`/companies/${results.groupid}`);
                addVars({ parentCompany: parent });

                const ranking = await api.get(
                    `/companies/${results.groupid}/100-10-1/ranking?includeGroup=true`
                );
                addVars({ ranking });
                return ranking;
            }

            if (results.isCxi) {
                const ranking = await api.get(`/companies/${companyId}/100-10-1/ranking`);
                addVars({ ranking });
                return ranking;
            }

            return false;
        } catch (e) {
            throw new Error('CXI: Not able to get user CXI ranking.');
        }
    }

    async function getCXIParentRanking() {
        const { company } = auth.user;
        let companyId = company?.id;

        if (!companyId) return false;

        try {
            // Is company a part of the 100-10-1?
            const results = await api.get(`/companies/${companyId}`);
            addVars({ company: results });
            if (!results.groupid) {
                return false;
            }

            const parent = await api.get(`/companies/${results.groupid}`);
            addVars({ parentCompany: parent });
            // Replace companyId with the groupid
            companyId = results.groupid;

            const ranking = await api.get(`/companies/${companyId}/100-10-1/ranking`);
            addVars({ ranking });
            return ranking;
        } catch (e) {
            throw new Error('CXI: Not able to get user CXI ranking.');
        }
    }

    async function getMatches() {
        const { userid } = auth.auth;
        try {
            const result = await api.get(`/users/${userid}/matches`);
            const matches = result.map(mapMatchAndCalculateStage);
            calculateUnreadMessages(matches);
            await findMentoringMatch(matches);
            addVars({ matches: matches.sort((a, b) => new Date(a.regDate) - new Date(b.regDate)) });
            // addVars({ matches: matches.filter(m => m.isCxi) });
        } catch (e) {
            throw new Error('CXI: Not able to get user matches.');
        }
    }

    async function getMatchById(matchId) {
        const { userid } = auth.auth;
        try {
            const match = await api.get(`/users/${userid}/matches/${matchId}`);
            const matches = vars.matches.filter(m => m.matchid !== match.matchid);
            calculateUnreadMessages([...matches, match]);
            return mapMatchAndCalculateStage(match);
        } catch (e) {
            throw new Error(`CXI: Not able to get user match ${matchId}.`);
        }
    }

    async function getMatchEvaluations(matchId) {
        const { userid } = auth.auth;
        try {
            const evaluations = await api.get(`/users/${userid}/matches/${matchId}/evaluations`);
            return evaluations;
        } catch (e) {
            throw new Error(`CXI: Not able to get user match ${matchId} evaluations.`);
        }
    }

    async function getMatchMessages(matchId) {
        const { userid } = auth.auth;
        try {
            const messages = await api.get(`/users/${userid}/matches/${matchId}/messages`);

            let prevMessage = {};
            const mappedMessages = messages.map(message => {
                const sameName = message.name === prevMessage.name;
                const sameFrom = message.from === prevMessage.from;
                if (!sameName || !sameFrom) {
                    prevMessage = message;
                    return message;
                }
                return { ...message, slim: true };
            });

            await getMatches();
            return mappedMessages;
        } catch (e) {
            throw new Error(`CXI: Not able to get user match ${matchId} Messages.`);
        }
    }

    async function getMatchMentoringSessions(matchId) {
        const { userid } = auth.auth;
        try {
            const sessions = await api.get(`/users/${userid}/matches/${matchId}/mentoringsessions`);
            return sessions
                .filter(s => !!s.meetingDate)
                .sort((a, b) => new Date(a.meetingDate) - new Date(b.meetingDate));
        } catch (e) {
            throw new Error(`CXI: Not able to get user match ${matchId} mentoring sessions.`);
        }
    }

    async function getMatchSpeedDatings(matchId) {
        const { userid } = auth.auth;
        try {
            const speeddatings = await api.get(`/users/${userid}/matches/${matchId}/speed-datings`);
            return speeddatings;
        } catch (e) {
            throw new Error(`CXI: Not able to get user match ${matchId} speed datings.`);
        }
    }

    async function getMatchStartup(matchId) {
        const { userid } = auth.auth;
        try {
            await new Promise(p => setTimeout(p, 2000));
            const startup = await api.get(`/users/${userid}/matches/${matchId}/startup`);
            return startup;
        } catch (e) {
            throw new Error(`CXI: Not able to get user match ${matchId} startup.`);
        }
    }

    async function getProfile() {
        const { userid } = auth.auth;
        try {
            const profile = await api.get(`/users/${userid}/profile`);
            addVars({ profile });
        } catch (e) {
            throw new Error('CXI: Not able to get user profile.');
        }
    }

    async function getFullProfile() {
        const { userid } = auth.auth;
        try {
            const fullProfile = await api.get(`/users/${userid}`);
            addVars({ fullProfile });
        } catch (e) {
            throw new Error('CXI: Not able to get user full profile.');
        }
    }

    async function getStartupShare(match) {
        const { userid } = auth.auth;
        try {
            const startup = await api.get(`/users/${userid}/matches/${match.matchid}/share`);
            return startup;
        } catch (e) {
            throw new Error(
                `CXI: Not able to get startup share for ${match.startup.startupId} startup.`
            );
        }
    }

    async function getStats() {
        const { userid } = auth.auth;
        try {
            const stats = await api.get(`/users/${userid}/stats`);
            addVars({ stats });
        } catch (e) {
            throw new Error('CXI: Not able to get user stats.');
        }
    }

    async function getSuggestedMatches() {
        const { userid } = auth.auth;
        try {
            let suggestedMatches;
            suggestedMatches = await api.get(`/users/${userid}/suggestedmatches`);
            await new Promise(p => setTimeout(p, 1000));
            if (suggestedMatches.status === 'finding') {
                await new Promise(p => setTimeout(p, 5000));
                suggestedMatches = await api.get(`/users/${userid}/suggestedmatches`);
            }
            addVars({ suggestedMatches });
        } catch (e) {
            throw new Error('CXI: Not able to get user stats.');
        }
    }
    // MURAL DE OPORTUNIDADES
    async function getCXIOpportunities(pageSize, offset, filterQuery) {
        const { userid } = auth.auth;
        try {
            const opportunities = await api.get(
                `/users/${userid}/100-10-1/startupchallenges?&pageSize=${pageSize}&offset=${offset}${filterQuery}`,
                {},
                { isErrorHandled: true }
            );

            return opportunities;
        } catch (e) {
            // Do nothing
            // throw new Error('CXI: Not able to get CXI opportunities', e);
        }
    }

    async function bootstrapCXI() {
        Promise.all([
            getCompany(),
            getCXICertificate(),
            getCXIContents(),
            getCXIChallenges(),
            getCXIProfile(),
            getCXIRanking(),
            getMatches(),
            getProfile(),
            getFullProfile(),
            getStats(),
            getUserReadCXIContents(),
            getCXIChallengeStatistics()
        ]).then(res => setReady(true));
    }

    async function postCxiCertificateRequest(type) {
        try {
            const { userid } = auth.auth;
            const payload = { type };
            const response = await api.post(`/users/${userid}/100-10-1/certificate`, payload);
            return response;
        } catch (e) {
            throw new Error('CXI: Not able to get post user CXI certificate request.');
        }
    }

    async function postCxiReadContent(type, id) {
        try {
            const { userid } = auth.auth;
            const contentRead = {
                type,
                contentId: id
            };
            const response = await api.post(`/users/${userid}/contents`, contentRead);
            getUserReadCXIContents();
            return response;
        } catch (e) {
            throw new Error('CXI: Not able to get post user CXI read content.');
        }
    }

    async function postMatchMessage(matchId, payload) {
        const { userid } = auth.auth;
        try {
            const message = await api.post(`/users/${userid}/matches/${matchId}/messages`, payload);
            return message;
        } catch (e) {
            throw new Error(`CXI: Not able to post the message to match ${matchId}.`);
        }
    }

    async function postMatchMentoringSession(matchId, payload) {
        const { userid } = auth.auth;
        try {
            const newPayload = { ...payload, isCxi: true };
            const meeting = await api.post(
                `/users/${userid}/matches/${matchId}/mentoringsessions`,
                newPayload
            );
            await getMatches();
            suggestMatchUpdate();
            return meeting;
        } catch (e) {
            throw new Error(`CXI: Not able to post the user match ${matchId} mentoring session.`);
        }
    }

    async function postMatchSpeedDating(matchId, payload) {
        const { userid } = auth.auth;
        try {
            const newPayload = { ...payload, isCxi: true };
            await api.post(`/users/${userid}/matches/${matchId}/speed-datings`, newPayload);
            await bootstrapCXI();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId} speed-dating.`);
        }
    }

    async function postMatchChallenge(startupId, challengeId) {
        try {
            const newPayload = { isCxi: true };
            const response = await api.post(
                `/events/${challengeId}/admin/startups/${startupId}/requestmatch`,
                newPayload
            );
            return response;
        } catch (error) {
            return {
                error: new Error(
                    `Failed to post match for startup ${startupId} to event ${challengeId}: ${error.message}`
                )
            };
        }
    }

    async function putUser(payload) {
        const { userid } = auth.auth;
        return api.put(`/users/${userid}`, payload).then(res => {
            getFullProfile();
        });
    }

    async function putChallengeStartupStatus(eventId, startupId, payload, filters) {
        try {
            const url = `/events/${eventId}/admin/startups/${startupId}`;
            await api.put(url, payload);
            getCXIEvents(eventId, filters);
        } catch (e) {
            throw new Error(`CXI: Not able to update the event ${eventId} status/feedback.`);
        }
    }

    async function putMatchById(matchId, payload) {
        const { userid } = auth.auth;
        try {
            const newPayload = { ...payload, isCxi: true };
            await api.put(`/users/${userid}/matches/${matchId}`, newPayload);
            if (payload.active) await bootstrapCXI();
            await removeMatchFromSuggestedMatches(matchId);
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId}.`);
        }
    }

    async function putMatchEvaluation(matchId, payload) {
        const { userid } = auth.auth;
        try {
            const newPayload = { ...payload, isCxi: true };
            await api.put(`/users/${userid}/matches/${matchId}/evaluations`, newPayload);
            await bootstrapCXI();
            suggestMatchUpdate();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId}.`);
        }
    }

    async function putMatchMentoring(matchId, payload) {
        const { userid } = auth.auth;
        try {
            await api.put(`/users/${userid}/matches/${matchId}/mentoring`, payload);
            await bootstrapCXI();
            suggestMatchUpdate();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId} mentoring.`);
        }
    }

    async function putMatchSpeedDating(matchId, speedDatingId, payload) {
        const { userid } = auth.auth;
        try {
            const url = `/users/${userid}/matches/${matchId}/speed-datings/${speedDatingId}`;
            await api.patch(url, payload);
            await bootstrapCXI();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId} speed-dating.`);
        }
    }

    async function putProfile(payload) {
        const { userid } = auth.auth;
        try {
            const newLocation = {
                city: payload.location?.city?.id,
                state: payload.location?.state?.id,
                country: payload.location?.country?.id
            };
            const newPayload = {
                ...payload,
                location: newLocation,
                regions: payload.regions.map(r => Number(r.id))
            };
            const profile = await api.put(`/users/${userid}/profile`, newPayload);
            addVars({ profile });
        } catch (e) {
            throw new Error('CXI: Not able to update the user profile.');
        }
    }

    async function deleteMatch(matchId) {
        const { userid } = auth.auth;
        try {
            await api.delete(`/users/${userid}/matches/${matchId}`);
            await bootstrapCXI();
        } catch (e) {
            throw new Error(`CXI: Not able to update the user match ${matchId}.`);
        }
    }

    // SHARELINK FOR EVALUATOR
    async function getEvaluatorShareLink() {
        const { userid } = auth.auth;
        try {
            const shareLink = await api.get(`/users/${userid}/share`);
            return shareLink?.link;
        } catch (e) {
            throw new Error('CXI: Not able to get share Link.');
        }
    }

    useEffectOnce(() => {
        // if (!scopeAllows('100-10-1')) {
        //     RHP.replace('/home');
        //     return;w
        // }
        // Initialize the module's service
        if (bootstrap) {
            bootstrapCXI();
        }
    });

    return {
        t,
        // Ready to load?
        ready,

        // Variables handlers
        addVars,
        setVars,
        vars,
        ...context,

        // Custom functions

        calculateMatchStage,
        calculateUnreadMessages,
        checkMatchStage,
        checkPreferences,
        deleteMatch,
        getCXICertificate,
        getCXIChallengeStartup,
        getCXIChallengeStatistics,
        getCXIChallenges,
        getCXIContents,
        getCXIEvents,
        getCXIOpportunities,
        getCXIParentRanking,
        getCXIProfile,
        getCXIRanking,
        getCompany,
        getEvaluatorShareLink,
        getEventsQuestions,
        getFullProfile,
        getMatchById,
        getMatchEvaluations,
        getMatchMentoringSessions,
        getMatchMessages,
        getMatchSpeedDatings,
        getMatchStartup,
        getMatches,
        getProfile,
        getStartupShare,
        getStats,
        getSuggestedMatches,
        getUserReadCXIContents,
        postChallengerForm,
        postCxiCertificateRequest,
        postCxiReadContent,
        postMatchChallenge,
        postMatchMentoringSession,
        postMatchMessage,
        postMatchSpeedDating,
        putChallengeStartupStatus,
        putMatchById,
        putMatchEvaluation,
        putMatchMentoring,
        putMatchSpeedDating,
        putProfile,
        putUser,
        removeMatchFromSuggestedMatches,
        removeMatchUnreadMessages
    };
}

export default useCXI;
