import React from 'react';

import * as Page from 'components/page';

export const PagesError = props => (
    <Page.Page name="PagesError">
        <Page.Title>{props.t('PagesError.title')}</Page.Title>
        <Page.Descr>{props.t('PagesError.descr')}</Page.Descr>
    </Page.Page>
);
export default PagesError;
