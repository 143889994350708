import { CA } from 'contexts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryParam } from 'utils/query';

export const RankingContext = React.createContext();

export const RankingContextProvider = ({ children }) => {
    const { t } = useTranslation('ranking');
    const { state } = React.useContext(CA);

    const initial = {
        application: {
            applied: false,
            steps: {},
            interests: {
                trophy: false,
                institutionalPresence: false,
                singleTicket: false,
                mediaKit: false,
                previousSectorialReport: false,
                nextSectorialReport: false
            }
        },
        redirectTo: queryParam('returnUrl'),
        contracts: [],
        companies: [],
        companyDetails: state.user.company,
        validate: false,
        relationships: [],
        companyId: state.user.company.id,
        ranking: {
            id: '2025evaluator',
            name: '2025evaluator',
            year: '2025',
            country: 'BR'
        },
        companyPocs: [],
        pocsRequest: [],
        currentRanking: {
            id: '2025corps',
            name: '2025corps',
            year: '2025',
            country: 'BR'
        },
        contractPoints: 0
    };

    const [vars, setVars] = React.useState(initial);

    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    const stateValue = {
        t,
        vars,
        addVars,
        setVars
    };
    return <RankingContext.Provider value={stateValue}>{children}</RankingContext.Provider>;
};
