import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionFeedback = ({ match }) => {
    const { t, setModal } = useCXI();
    const { evaluation } = match;

    let text = t('match.action.feedback');
    let disabled = false;
    if (match.speedDating === 'held' && !evaluation?.startupFeedback) {
        text = t('match.action.waiting');
        disabled = true;
    }

    return (
        <Styled.MatchAction
            className="s2 action"
            disabled={disabled}
            onClick={() => setModal({ match, modal: 'Feedback' })}
        >
            <span className="text">{text}</span>
        </Styled.MatchAction>
    );
};
