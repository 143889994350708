import React from 'react';

import { UserAvatar } from 'core/components/user';
import { useUser } from 'hooks';
import { RHP } from 'providers';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import * as Styled from './StyledProfileCard';

export const ProfileCard = () => {
    const { t, vars } = useCXI();
    const user = useUser();
    const { isCxiAdmin } = vars.cxi;

    const handleEdit = () => {
        RHP.push('/profile/edit');
    };
    return (
        <Styled.ProfileCard>
            <UserAvatar />
            <div className="st">
                <div className="nameBadges">
                    <div className="profileColumn">
                        <Styled.Title>{user.fullName}</Styled.Title>
                        <Styled.Descr>{user.position}</Styled.Descr>
                    </div>

                    <div className="profileColumn badges-pt">
                        {user.pocs.includes('hub') && (
                            <Styled.PocBadge className="hubPoc">
                                Ponto de Contato | HUB
                            </Styled.PocBadge>
                        )}

                        {user.pocs.includes('operational') && (
                            <Styled.PocBadge className="rankingPoc">
                                Ponto de Contato | Ranking
                            </Styled.PocBadge>
                        )}
                        {isCxiAdmin && (
                            <Styled.PocBadge className="cxiPoc">
                                Ponto de Contato | 100-10-1
                            </Styled.PocBadge>
                        )}
                    </div>
                </div>

                <div className="spacer" />

                <Styled.IconWithText>
                    <span className="icon far fa-building" aria-hidden="true" />
                    <span className="text">{user.company?.name || '-'}</span>
                </Styled.IconWithText>

                <Styled.IconWithText>
                    <span className="icon far fa-briefcase" aria-hidden="true" />
                    <span className="text">{user.position || '-'}</span>
                </Styled.IconWithText>

                {/* {!!user.linkedin && (
                    <Styled.IconWithText>
                        <span className="icon fab fa-linkedin-in" aria-hidden="true" />
                        <a
                            className="text"
                            href={user.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {customLinkedIn}
                        </a>
                    </Styled.IconWithText>
                )} */}
            </div>
            <div className="nd">
                <Styled.IconWithText>
                    <span className="icon far fa-map-marker-alt" aria-hidden="true" />
                    <span className="text">{user.locationString || '-'}</span>
                </Styled.IconWithText>
                <Styled.IconWithText>
                    <span className="icon far fa-envelope" aria-hidden="true" />
                    <span className="text">{user.email || ''}</span>
                </Styled.IconWithText>
                <Styled.IconWithText>
                    <span className="icon far fa-phone-alt" aria-hidden="true" />
                    <span className="text">{user.phone || '-'}</span>
                </Styled.IconWithText>

                <Button variant="outline" onClick={handleEdit}>
                    <span className="icon far fa-pencil" aria-hidden="true" />
                    <span className="text">{t('profile.ProfileCard.goToEdit')}</span>
                </Button>
            </div>
        </Styled.ProfileCard>
    );
};
