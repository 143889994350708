import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledProgress';

export const Progress = props => {
    const { t } = useCXI();
    const { currentStage } = props;

    const stages = [
        t('matches.Progress.idea'),
        t('matches.Progress.prototype'),
        t('matches.Progress.pilot'),
        t('matches.Progress.market'),
    ];

    const getClassName = stage => {
        if (stage === currentStage) return 'current';
        if (stage < currentStage) return 'previous';
        return 'default';
    };

    return (
        <Styled.Progress aria-label="">
            {[...stages.keys()]
                .map(x => x + 1)
                .map(stage => (
                    <div className={getClassName(stage)} role="presentation" key={stage}>
                        <span className="icon" />
                        <span className="text">{stages[stage - 1]}</span>
                    </div>
                ))}
        </Styled.Progress>
    );
};
