import styled from 'styled-components';

export const TabsButtons = styled.div.attrs({
    className: 'c-tabs-buttons',
})`
    display: flex;
`;

export const Tab = styled.div.attrs(props => {
    const active = props.active ? 'active' : '';
    return { className: `c-tabs-tab ${active}` };
})`
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    padding: 1rem 1rem 0.8rem;
    text-transform: uppercase;

    &.active {
        background: #fff;
    }
    & > span {
        font-weight: 500;
    }
`;
