import React from 'react';
import moment from 'moment';

import { CG } from 'contexts';
import { UserAvatar } from 'core/components/user';
import { StartupImage } from 'core/components/startup';

import { useStartupCXI } from '../../../useStartupCXI';
import * as Styled from './StyledMessage';

export const Message = ({ match, message }) => {
    const { state: global } = React.useContext(CG);
    const { vars } = useStartupCXI();
    const { startup } = vars;

    // Moment language
    let lang = global.language;
    if (global.language === 'pt') {
        lang = 'pt-br';
    }
    moment.locale(lang);

    const momentDate = moment(message.regDate);
    const formatDate = momentDate.format('lll');

    const fullName = message.name || '';
    const firstName = fullName
        .split(' ')
        .slice(0, 1)
        .join(' ');
    const lastName = fullName
        .split(' ')
        .slice(1)
        .join(' ');
    const user = { firstName, lastName, photoUrl: message.photoUrl };

    const from = message.from === 'startup' ? 'me' : 'them';

    return (
        <Styled.Message from={from} slim={message.slim}>
            {from !== 'me' ? <UserAvatar user={user} /> : <StartupImage data={startup} />}

            <Styled.MessageTexts>
                {!message.slim && <span className="name">{message.name}</span>}
                <span className="text">{message.text}</span>
                <span className="time">{formatDate}</span>
                <span className="caret fas fa-caret-left" aria-hidden="true" />
            </Styled.MessageTexts>
        </Styled.Message>
    );
};
