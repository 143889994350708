import React from 'react';
import { useTranslation } from 'react-i18next';

import { useInvestor } from './useInvestor';
import { InvestorRoutes } from './InvestorRoutes';

export function Investor(props) {
    const { t } = useTranslation('ranking');
    const hookd = useInvestor();
    return !hookd.ready ? null : <InvestorRoutes t={t} useInvestor={hookd} />;
}
export default Investor;
