import React from 'react';

import { CG } from 'contexts';
import { LSP } from 'providers';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';

import { Container } from '../container';
import * as Styled from './StyledFeedbackForm';

export const FeedbackForm = () => {
    const { state: global } = React.useContext(CG);
    const { t, modal, setModal, putMatchEvaluation, putMatchSpeedDating } = useCXI();
    const { match } = modal;
    const { latestSpeedDating } = match;

    const form = useForm({ comment: LSP.get(`${match.matchid}_FEEDBACK_FORM_COMMENT`) ?? '' });

    const fields = {
        comment: {
            error: form.errors.comment,
            name: 'comment',
            type: 'textarea',
            label: t('modal.FeedbackForm.comment'),
            required: true,
            value: form.values.comment,
            onChange: ev => {
                LSP.set(`${match.matchid}_FEEDBACK_FORM_COMMENT`, ev.target.value);
                return form.handleChange(ev);
            },
        },
    };

    const onSubmit = async values => {
        const { language } = global;
        try {
            // Set the speed-dating as 'held'
            if (match.speedDating === 'requested' && latestSpeedDating) {
                const SpeedDatingPayload = { status: 'held' };
                await putMatchSpeedDating(match.matchid, latestSpeedDating._id, SpeedDatingPayload);
            }

            // Set the evaluation
            const commons = { language, isCxi: true, cxiStage: 2 };
            const EvaluationPayload = { ...commons, ...values };
            await putMatchEvaluation(match.matchid, EvaluationPayload);
            LSP.del(`${match.matchid}_FEEDBACK_FORM_COMMENT`);
            setModal({ match, modal: 'FeedbackSent' });
        } catch (e) {
            form.setSubmit(false);
        }
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    const isSubmitDisabled = form.submit || !form.values.comment;

    return (
        <Styled.FeedbackForm as={Container}>
            <Form.Form onSubmit={evSubmit}>
                <Container.Head image={match.startup.logoUrl}>
                    <span className="text">{t('modal.FeedbackForm.title')}</span>
                </Container.Head>

                <Container.Body>
                    <Styled.Descr>
                        <span className="text">{t('modal.FeedbackForm.description')}</span>
                    </Styled.Descr>

                    <Styled.FeedbackFormField>
                        <Form.Field {...fields.comment} />
                    </Styled.FeedbackFormField>
                </Container.Body>

                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.FeedbackForm.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.FeedbackForm>
    );
};
