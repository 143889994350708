import { RHP } from 'providers';
import React from 'react';

import * as Styled from './StyledMatchStartup';

export const MatchStartup = ({ match }) => (
    <Styled.MatchStartup onClick={() => RHP.push(`/100-10-1/startup/${match.matchid}`)}>
        <Styled.Image img={match.startup.logoUrl} />
        <Styled.Title>{match.startup.name}</Styled.Title>
    </Styled.MatchStartup>
);
