import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { CA } from 'contexts';
import { CONFIG } from 'config';
import { useApi } from 'hooks';

import * as Alert from 'components/alert';

import * as Styled from './StyledUserRedirect';

export function UserRedirect() {
    const { state, dispatch } = React.useContext(CA);
    const { t } = useTranslation('home');
    const api = useApi();

    const [data, setData] = React.useState({});

    function resetPassword() {
        const { email } = state.tokn;
        const { baseUrl } = CONFIG;
        const returnUrl = '/home';
        return api.post(`/resetpassword`, { email, baseUrl, returnUrl });
    }

    const handlePrelogin = res => {
        const newData = { redirect: '', message: '', show: true };

        if (!res.user) {
            newData.title = t('notRegistered');
            newData.message = t('signup');
            newData.redirect = '/register';
            newData.buttonMessage = t('register');
            newData.onClick = () => {
                dispatch({ type: 'UNAUTHENTICATE', pathnameUrl: newData.redirect });
            };
            return newData;
        }

        if (state.tokn.scopes.includes('full')) {
            const { email } = state.tokn;
            if (!res.password) {
                newData.title = t('tempAccess');
                newData.message = t('fullTextCreatePassword');
                newData.redirect = `/login/validate?email=${email}`;
                newData.buttonMessage = t('createPassword');
                newData.onClick = () => {
                    resetPassword();
                    dispatch({
                        type: 'UNAUTHENTICATE',
                        pathnameUrl: `/login/validate`,
                        returnUrl: `/home&email=${email}`,
                    });
                };
            } else {
                newData.show = false;
            }
        }

        if (!state.tokn.scopes.includes('full')) {
            const { email } = state.tokn;
            if (!res.password) {
                newData.title = t('singleSignon');
                newData.message = t('notFullTextCreatePassword');
                newData.redirect = `/login/validate?email=${email}`;
                newData.buttonMessage = t('createPassword');
                newData.onClick = () => {
                    resetPassword();
                    dispatch({
                        type: 'UNAUTHENTICATE',
                        pathnameUrl: `/login/validate`,
                        returnUrl: `/home&email=${email}`,
                    });
                };
            } else {
                newData.title = t('accessExpiresSoon');
                newData.message = t('fullAccess');
                newData.redirect = '/login/email';
                newData.buttonMessage = t('login');
                newData.onClick = () => {
                    dispatch({
                        type: 'UNAUTHENTICATE',
                        pathnameUrl: `/login/email`,
                        returnUrl: `/home&email=${email}`,
                    });
                };
            }
        }

        return newData;
    };

    function postPrelogin(payload) {
        return api.post(`/prelogin`, payload).then(res => {
            return handlePrelogin(res);
        });
    }

    useEffectOnce(() => {
        (async () => {
            const { email } = state.tokn;
            const newData = await postPrelogin({ email });
            setData(newData);
        })();
    });

    // Create the alert element props
    const propsAlert = {
        // Show texts informations
        title: data.title,
        descr: data.message,
        // Build the button
        buttn: {
            href: data.redirect,
            text: data.buttonMessage,
            onClick: data.onClick,
        },
        // Setup color and presentation
        type: 'primary',
    };

    return !data.show ? null : <Styled.UserRedirect as={Alert.Alert} {...propsAlert} />;
}
export default UserRedirect;
