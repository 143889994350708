import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { IonAlert } from '@ionic/react';

import { ContextGlobal as CG } from 'contexts';

type IAlertProps = ComponentProps<typeof IonAlert>;

export function AppAlert() {
    const { t } = useTranslation('glossary');
    const { state, emitAlert } = React.useContext(CG);

    const alertProps: IAlertProps = {
        buttons: [t('OK')],
        header: state.alertHeader || undefined,
        message: state.alert,
        mode: 'ios',

        isOpen: !!state.alert,
        onDidDismiss: () => emitAlert({ header: '', message: '' })
    };

    return state.alert && <IonAlert {...alertProps} />;
}
export default AppAlert;
