import React from 'react';

import { useCXIDashboardStartup } from '../../useCXIDashboardStartup';
import * as Styled from './StyledStartupCard';

export const StartupCardSocials = () => {
    const { vars } = useCXIDashboardStartup();
    const { startup } = vars;

    const { facebook, linkedin, others, twitter, website, youtube } = startup.links;

    return (
        <Styled.StartupCardSocials>
            {!!facebook && (
                <a href={facebook} title="Facebook" target="_blank" rel="noopener noreferrer">
                    <span className="icon fab fa-facebook" aria-hidden="true" />
                </a>
            )}
            {!!others && (
                <a href={others} title="Instagram" target="_blank" rel="noopener noreferrer">
                    <span className="icon fab fa-instagram" aria-hidden="true" />
                </a>
            )}
            {!!twitter && (
                <a href={twitter} title="Twitter" target="_blank" rel="noopener noreferrer">
                    <span className="icon fab fa-twitter" aria-hidden="true" />
                </a>
            )}
            {!!linkedin && (
                <a href={linkedin} title="LinkedIn" target="_blank" rel="noopener noreferrer">
                    <span className="icon fab fa-linkedin" aria-hidden="true" />
                </a>
            )}
            {!!youtube && (
                <a href={youtube} title="Youtube" target="_blank" rel="noopener noreferrer">
                    <span className="icon fab fa-youtube" aria-hidden="true" />
                </a>
            )}
            {!!website && (
                <a href={website} title="Website" target="_blank" rel="noopener noreferrer">
                    <span className="icon fas fa-link" aria-hidden="true" />
                    <span className="text">{website}</span>
                </a>
            )}
        </Styled.StartupCardSocials>
    );
};
