import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo100os from 'assets/images/logo-100os.svg';
import logo100ot from 'assets/images/logo-100ot.svg';

import * as Styled from './StyledUserStartups';

export const UserStartupsCard = ({ data }) => {
    const { t } = useTranslation('glossary');
    const level = { level: data.level, context: data.level === 0 ? 'pending' : '' };
    return (
        <Styled.UserStartupsCard as={Link} to={data.href}>
            <Styled.CardImage
                img={
                    encodeURI(data.logoUrl) ||
                    (data.typeOfProject === 'tech' ? logo100ot : logo100os)
                }
            />
            <Styled.CardInfos>
                <span className="name">{data.name}</span>
                <span className="code">{data.inductId}</span>
                <span className="bold">{t('level', level)}</span>
            </Styled.CardInfos>
        </Styled.UserStartupsCard>
    );
};
