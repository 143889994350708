import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileRoutes } from './ProfileRoutes';
import { useProfile } from './useProfile';

export function Profile(props) {
    const { t } = useTranslation('profile');
    const hookd = useProfile();
    return <ProfileRoutes t={t} useProfile={hookd} />;
}
export default Profile;
