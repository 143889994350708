import React from 'react';

import * as Form from 'components/form';

export function ProfileEditForm(props) {
    const { conv, form, onSubmit } = props;

    // Create form fields object
    const fields = {
        personal: [
            {
                autoComplete: 'given-name',
                error: form.errors.firstName,
                label: props.t('glossary:firstName'),
                name: 'firstName',
                type: 'text',
                value: form.values.firstName,
                onChange: form.handleChange,
            },
            {
                autoComplete: 'family-name',
                error: form.errors.lastName,
                label: props.t('glossary:lastName'),
                name: 'lastName',
                type: 'text',
                value: form.values.lastName,
                onChange: form.handleChange,
            },
            {
                autoComplete: 'email',
                disabled: true,
                error: form.errors.email,
                label: props.t('glossary:workEmail'),
                name: 'email',
                type: 'email',
                value: form.values.email,
                warning: form.warnings.email,
                onChange: form.handleChange,
            },
            {
                error: form.errors.gender,
                label: props.t('glossary:gender'),
                name: 'gender',
                required: false,
                type: 'radio',
                options: [...conv.gender],
                value: form.values.gender,
                onChange: form.handleChange,
            },
        ],
        personalEmail: [
            {
                error: form.errors.personalEmail,
                label: props.t('glossary:personalEmail'),
                name: 'personalEmail',
                type: 'email',
                value: form.values.personalEmail,
                warning: form.warnings.personalEmail,
                onChange: form.handleChange,
            },
        ],
        phone: [
            {
                error: form.errors.phone,
                label: props.t('glossary:phone'),
                name: 'phone',
                type: 'tel',
                required: false,
                value: form.values.phone,
                onChange: form.handleChange,
            },
        ],
        ecosystem: [
            {
                error: form.errors.role,
                helper: props.t('glossary:ecosystemRoleHelper'),
                label: props.t('glossary:ecosystemRole'),
                name: 'role',
                options: [
                    { name: props.t('glossary:ecosystemRoleSelect'), value: '' },
                    ...conv.ecosystemRole,
                ],
                type: 'select',
                value: form.values.role,
                onChange: form.handleChange,
            },
        ],
        profession: [
            {
                error: form.errors.profession,
                label: props.t('glossary:profession'),
                name: 'profession',
                type: 'text',
                required: false,
                value: form.values.profession,
                onChange: form.handleChange,
            },
            {
                error: form.errors.yearsOfXp,
                label: props.t('glossary:yearsOfXp'),
                name: 'yearsOfXp',
                type: 'number',
                maxLength: 2,
                required: false,
                value: form.values.yearsOfXp,
                onChange: form.handleChange,
            },
            {
                error: form.errors.linkedin,
                label: props.t('glossary:linkedin'),
                name: 'linkedin',
                type: 'text',
                required: false,
                value: form.values.linkedin,
                onChange: form.handleChange,
            },
        ],
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="ProfileEditForm" onSubmit={evSubmit}>
            <Form.Fieldset border={false}>
                {fields.personal.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset>
                {fields.personalEmail.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset>
                {fields.phone.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset>
                {fields.ecosystem.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset>
                <Form.FieldsetLabel>{props.t('ProfileEdit.professionHistory')}</Form.FieldsetLabel>
                {fields.profession.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Submit {...form} text={props.t('ProfileEdit.submit')} />
        </Form.Form>
    );
}
export default ProfileEditForm;
