import styled from 'styled-components';

export const Matches = styled.table`
    width: 100%;
`;
export const MatchesTbody = styled.tbody``;

export const MatchesTd = styled.td`
    background: white;
    padding: 1.6rem;
    text-align: center;
    width: auto;

    &:first-child {
        text-align: left;
    }
    &:last-child {
        padding-right: 2.4rem;
        white-space: nowrap;
        width: 0;
    }
`;

export const MatchesTr = styled.tr`
    border-top: 1px solid ${({ theme }) => theme.colors.whiteDarker};

    &:first-child {
        border-top: 0;

        td:first-child {
            border-top-left-radius: 0.8rem;
        }
        td:last-child {
            border-top-right-radius: 0.8rem;
        }
    }

    &:last-child {
        td:first-child {
            border-bottom-left-radius: 0.8rem;
        }
        td:last-child {
            border-bottom-right-radius: 0.8rem;
        }
    }
`;
