import React from 'react';
import { ResponsiveContainer, Line, LineChart, XAxis, YAxis, Tooltip } from 'recharts';

import * as Styled from './StyledSessions';

function LeftSession({ data }) {
    const formatDataForXAxis = chartData => {
        const d = [...chartData];
        const datesAlreadyListed = [];
        for (let i = 0; i < d.length; i++) {
            const year = d[i].date.split('-')[0];
            // if (!datesAlreadyListed.includes(year)) {
            //     datesAlreadyListed.push(year);
            //     d[i].year = year;
            // }
            d[i].year = year;
            datesAlreadyListed.push(year);
        }
        return d;
    };

    return (
        <Styled.LeftSession>
            <Styled.LeftBigContainer>
                <Styled.Title>
                    <h1>{data.startupsMatchedTotal}</h1>
                    <h2>Startups com match</h2>
                </Styled.Title>
                <Styled.GraphicsContainer>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            data={
                                data.startupsMatchedChartData.length > 0
                                    ? formatDataForXAxis(data.startupsMatchedChartData)
                                    : null
                            }
                            margin={{
                                top: 15,
                                right: 40,
                                left: -20,
                                bottom: 15
                            }}
                        >
                            <XAxis dataKey="year" tick />
                            <YAxis />
                            <Tooltip />
                            <Line
                                type="monotone"
                                dataKey="startups"
                                stroke="#0088FE"
                                activeDot={false}
                                strokeWidth={4}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Styled.GraphicsContainer>
            </Styled.LeftBigContainer>
            <Styled.RowContainer>
                <Styled.RowCard>
                    <Styled.BottomTitle>
                        <h1>{data.executivesRegistered}</h1>
                        <h2>Executivos cadastrados na base open startups</h2>
                    </Styled.BottomTitle>
                </Styled.RowCard>
                <Styled.RowCard>
                    <Styled.Title>
                        <h1>{data.executivesCertified}</h1>
                        <h2>Executivos certificados no programa 100-10-1 Startups</h2>
                    </Styled.Title>
                </Styled.RowCard>
            </Styled.RowContainer>
        </Styled.LeftSession>
    );
}

export default LeftSession;
