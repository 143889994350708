import React from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { LoginResetForm } from './LoginResetForm';

export function LoginReset(props) {
    const { vars, postResetPassword } = props.useLogin;

    // Initialize a new form handler
    const form = useForm({
        email: vars.email || '',
        baseUrl: vars.baseUrl || '',
        returnUrl: vars.returnUrl || '/home',
    });

    // Handle form submition
    const onSubmit = values => {
        const { baseUrl, email, returnUrl } = values;
        // Do the submition api request
        return postResetPassword({ baseUrl, email, returnUrl })
            .then(() => RHP.push('/login/validate'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="LoginReset" back="/login/email">
            <Page.Title>{props.t('LoginReset.title')}</Page.Title>
            <Page.Descr>{props.t('LoginReset.descr')}</Page.Descr>

            <LoginResetForm {...propsForm} />
        </Page.Page>
    );
}
export default LoginReset;
