import React, { useEffect, useState } from 'react';

import * as Alert from 'components/alert';
import * as List from 'components/list';
import * as Modal from 'components/modal';
import * as Page from 'components/page';
import * as Tabs from 'components/tabs';
import { RHP } from 'providers';

import { Relationship, RelationshipCard } from '../components';

export function CorporateStep3({ useRankingCorporate, t }) {
    const { vars, updateSteps } = useRankingCorporate;

    const { application, relationships } = vars;

    const { steps } = application;

    const [modalData, setModalData] = useState(false);

    const addHrefPage = '/ranking/latam/corporate/step3/add';

    const relationshipsWithOnClick = relationships.map(i => {
        const item = { ...i };
        item.onClick = () => setModalData(i);
        return item;
    });

    // Update the step has completed if relationships exist
    useEffect(() => {
        // if (relationships && relationships.length > 0 && steps.step5) {
        if (steps.step2 && !steps.step3) {
            updateSteps({ step3: true });
        }
    });

    // Check for previous step conclusion
    if (!steps || !steps.step2) {
        RHP.replace('/ranking/latam/corporate/');
        return null;
    }

    const pending = relationshipsWithOnClick
        .filter(i => i.status === 'pending')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const rejected = relationshipsWithOnClick
        .filter(i => i.status === 'rejected')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const confirmed = relationshipsWithOnClick
        .filter(i => i.status === 'confirmed')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const propsAlert = {
        title: t('CorporateStep3.noRelationship'),
        descr: t('CorporateStep3.noRelationshipDescr'),
        icons: 'far fa-exclamation-circle',
        buttn: {
            href: addHrefPage,
            text: t('CorporateStep3.addRelationship')
        },
        type: 'warning'
    };

    return (
        <Page.Page
            name="CorporateStep3"
            back="/ranking/latam/corporate"
            customBackText={t('glossary:backToRanking')}
        >
            <Page.Title>{t('CorporateStep3.title')}</Page.Title>
            <Page.Descr>{t('CorporateStep3.descr')}</Page.Descr>

            {relationships
                .filter(i => i.company.status === 'pending' && i.status === 'pending')
                .slice(0, 1)
                .map(item => {
                    return (
                        <Relationship
                            key={`pending-${item._id}`}
                            data={item}
                            useRankingCorporate={useRankingCorporate}
                        />
                    );
                })}

            {!relationshipsWithOnClick.length && <Alert.Alert {...propsAlert} />}

            <Page.Buttn to={addHrefPage} type="linkd btn-block">
                <span className="icon far fa-plus-circle" aria-hidden="true" />
                <span className="text">{t('CorporateStep3.addRelationship')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Tabs.TabsContainer>
                <Tabs.TabsButtons>
                    <Tabs.Tab name="TAB_CONFIRMED">
                        {t('glossary:confirmedBy.ecosystemAgent')}{' '}
                        {confirmed.length && <span>({confirmed.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="TAB_PENDING">
                        {t('glossary:pendingConfirmation')}{' '}
                        {pending.length && <span>({pending.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="TAB_REJECTED">
                        {t('glossary:rejectedBy.parties')}{' '}
                        {rejected.length && <span>({rejected.length})</span>}
                    </Tabs.Tab>
                </Tabs.TabsButtons>

                <List.List name="TAB_CONFIRMED">
                    <List.Vertical
                        items={confirmed}
                        itemKey="_id"
                        itemComponent={RelationshipCard}
                    />
                </List.List>

                <List.List name="TAB_PENDING">
                    <List.Vertical items={pending} itemKey="_id" itemComponent={RelationshipCard} />
                </List.List>

                <List.List name="TAB_REJECTED">
                    <List.Vertical
                        items={rejected}
                        itemKey="_id"
                        itemComponent={RelationshipCard}
                    />
                </List.List>
            </Tabs.TabsContainer>

            <div style={{ display: 'flex', margin: '10px 0' }}>
                <Page.Buttn
                    style={{ width: '25%', display: 'flex' }}
                    to="/ranking/latam/corporate/step2"
                >
                    <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                    <span className="text" style={{ margin: '0 0 0 auto' }}>
                        {t('glossary:back')}
                    </span>
                </Page.Buttn>
                <Page.Buttn
                    style={{ width: '80%', marginLeft: '10px' }}
                    to="/ranking/latam/corporate/step4"
                >
                    <span className="text">{t('glossary:continue')}</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </Page.Buttn>
            </div>

            <Modal.Modal
                close={() => setModalData(false)}
                title={t('CorporateStep3.relationship')}
                isOpen={!!modalData}
            >
                {modalData && (
                    <Relationship
                        closeModal={() => setModalData(false)}
                        data={modalData}
                        style={{ marginTop: '1rem' }}
                        useRankingCorporate={useRankingCorporate}
                    />
                )}
            </Modal.Modal>
        </Page.Page>
    );
}
