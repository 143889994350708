import styled from 'styled-components';
import { positions } from 'utils/styles';

export const FormInput = styled.div.attrs(props => {
    const name = props.name ? `fi-${props.name}` : '';
    const type = props.type ? `form-input-${props.type}` : '';
    return { className: `form-input ${type} ${name}` };
})`
    position: relative;

    &.fi-checkboxes > .form-control-label {
        margin-bottom: 1rem;
    }
    &.fi-phone .intl-tel-input {
        width: 100%;
    }
    &.fi-select .form-control {
        height: 4.6rem;
    }
    &.fi-select .form-control-icons {
        margin-top: 1.3rem;
        margin-right: 1rem;
    }

    .calendar {
        height: 100px;
    }
    .datetime {
        justify-content: left;
    }
    .addInfo {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
    }

    &.reqs {
        color: #999;
        font-size: 0.85em;
        margin-left: auto;
        display: flex;
    }

    &.fi-textarea .fake-control {
        position: relative;
        min-height: 10rem;
    }
    &.fi-textarea .form-control {
        line-height: 1.4;
        position: absolute;
        ${positions(0, 0, 0, 0)}
        resize: none;
        transition: all 0.3s ease;

        &.text-control {
            opacity: 0;
            position: relative;
            ${positions('auto', 'auto', 'auto', 'auto')}
            white-space: pre-wrap;
        }
    }
`;

export const FormInputAbsolute = styled.div.attrs(props => {
    const name = props.name ? `fi-${props.name}` : '';
    const type = props.type ? `form-input-${props.type}` : '';
    return { className: `form-input-absolute ${type} ${name}` };
})`
    position: relative;

    &.fi-password .form-control {
        letter-spacing: 0.3em;
    }

    .form-control-label {
        color: #999;
        margin-top: 1px;
        padding: 0.7rem 1rem;
        position: absolute;
        ${positions('50%', 'auto', 'auto', 0)}
        transform: translateY(-50%);
        transition: all 0.3s ease;
        z-index: 90;
    }
    .form-control-icon {
        margin: 1rem;
        position: absolute;
        ${positions(0, 0)}
        transform: translateY(-50%);
    }

    .form-control {
        height: 5.2rem;
        padding: 0.7rem 1rem;
    }
    .form-control:focus,
    input.form-control:not(:placeholder-shown) {
        padding-top: 2.5rem;

        & ~ .form-control-label {
            font-size: 1.2rem;
            line-height: 1.2rem;
            position: absolute;
            top: 0.2rem;
            transform: translateY(0);
        }
    }
`;
