import React from 'react';

import * as Styled from './StyledMatchFeedback';

export const MatchFeedback = ({ match }) => {
    const feedback = match.startupFeedback || '';

    let ttip = 'Aguardando seu feedback';
    let icon = 'fa-exclamation-circle';
    if (feedback === 'positive') {
        icon = 'fa-laugh';
        ttip = 'Muito relevante';
    } else if (feedback === 'neutral') {
        icon = 'fa-meh';
        ttip = 'Indiferente';
    } else if (feedback === 'negative') {
        icon = 'fa-frown';
        ttip = 'Pouco relevante';
    }

    return (
        <Styled.MatchFeedback className={feedback}>
            {!icon ? (
                <span className="text">-</span>
            ) : (
                <span className={`icon far ${icon}`} aria-hidden="true" />
            )}
            {!!ttip && <span className="ttip">{ttip}</span>}
        </Styled.MatchFeedback>
    );
};
