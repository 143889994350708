import React from 'react';

import { CXIIndexView } from './CXIIndexView';
import * as Styled from './StyledCXIIndex';

export function CXIIndex(props) {
    const propsView = { ...props };

    return (
        <Styled.CXIIndex>
            <CXIIndexView {...propsView} />
        </Styled.CXIIndex>
    );
}
export default CXIIndex;
