import React from 'react';

import { Button } from 'modules/cxi/components/button';

import { RHP } from 'providers';
import { useStartupCXI } from '../../useStartupCXI';
import { Container } from '../container';
import * as Styled from './StyledMentoringOverdue';

export const MentoringOverdue = () => {
    const { t, vars, modal, setModal, putMatchById } = useStartupCXI();
    const { startupId } = vars;
    const { match } = modal;

    const handleClick = async () => {
        await putMatchById(match.matchId, { active: true });
        RHP.push(`/startup/${startupId}/100-10-1/messages/${match.matchId}`);
    };

    return (
        <Styled.MentoringOverdue as={Container.Slim}>
            <Container.Head image={match.evaluator.photoUrl} />

            <Container.Body>
                <Styled.Title>{t('modal.MentoringOverdue.title')}</Styled.Title>
                <Styled.Descr>{t('modal.MentoringOverdue.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={handleClick}>
                    <span className="text">{t('modal.MentoringOverdue.chat')}</span>
                </Button>
                <Button variant="link" onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.MentoringOverdue>
    );
};
