import styled from 'styled-components';
import { positions } from 'utils/styles';

export const NavbarProfile = styled.div`
    margin-left: auto;
    position: relative;
`;

export const NavbarProfileToggle = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    display: flex;
    gap: 1rem;
    padding: 0;

    .user-avatar {
        width: 4rem;
    }
`;

export const NavbarProfileDropdown = styled.ul.attrs(props => ({
    className: props.isOpen ? 'is-open' : '',
}))`
    background: ${({ theme }) => theme.colors.deepBlue};
    border-radius: 1rem;
    color: ${({ theme }) => theme.colors.white};
    display: none;
    flex-direction: column;
    gap: 1.2rem;
    list-style: none;
    padding: 1.2rem;
    position: absolute;
    ${positions('4.5rem', '-1.6rem', 'auto', 'auto')}
    width: 18.5rem;
    z-index: 900;

    &::before {
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.deepBlue} transparent;
        border-width: 0 6px 6px 6px;
        content: '';
        position: absolute;
        ${positions('-6px', '1rem', 'auto', 'auto')}
        transform: translateX(-50%);
    }

    &.is-open {
        display: flex;
        transform: translateX(0);
    }
`;

export const Button = styled.button`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-weight: 600;
    gap: 0.8rem;
    padding: 0;
`;
