import Button from 'components/button';
import React from 'react';
import { Header } from 'modules/cxi/components/header/Header';
import { useCXIDashboard } from '../useCXIDashboard';
import * as Styled from '../components/StyledDashboardPage';
import { Startups } from './components/startups/Startups';
import { FilterTag } from './components/filter-tag/FilterTag';


export const CXIDashboardStartups = () => {
    const { t, vars,downloadDashboardStartups } = useCXIDashboard();
    const { startups, company } = vars;

    const [filter, setFilter] = React.useState('');
    const [filterStage, setFilterStage] = React.useState([]);
    const [activePage, setActivePage] = React.useState('Todas');
    const [viewFilter, setViewFilter] = React.useState('todos');

    const hasFilterStage = value => {
        return !!filterStage.includes(value);
    };

    const updFilterStage = value => {
        if (hasFilterStage(value)) {
            setFilterStage(f => f.filter(i => i !== value));
        } else {
            setFilterStage(f => [...f, value]);
        }
    };
    
    const handleChange = e => {
        const value = e.target.value.toLowerCase();
        setFilter(value);
        setViewFilter('Todos');
    };
    
    const handleViewFilter = filter => {
        setViewFilter(filter);
    };
    
    const filteredByStage = startups.filter(i => {
        let shouldShow = true;
    
        const hasMatches = i.name.toLowerCase().includes(filter.toLowerCase());
        if (filter && !hasMatches) shouldShow = false;
    
        if (hasFilterStage(100)) {
            if (!i.stage1Feedbacks) shouldShow = false;
        }
        if (hasFilterStage(10)) {
            if (!i.stage2Feedbacks) shouldShow = false;
        }
        if (hasFilterStage(1)) {
            if (!i.stage3Feedbacks) shouldShow = false;
        }
    
        return shouldShow;
    });
    
    const filtered = viewFilter === 'all'
        ? filteredByStage
        : filteredByStage.filter(i => {
            if (hasFilterStage('all')) {
                return true; 
            }
            if (hasFilterStage(100)) {
                return (
                    (viewFilter === 'w-evaluations' && i.stage1Feedbacks > 0) ||
                    (viewFilter === 'w-speeddating' && i.stage2Feedbacks > 0) ||
                    (viewFilter === 'w-mentoring' && i.stage3Feedbacks > 0)
                );
            }

            if (hasFilterStage(10)) {
                return (
                    (viewFilter === 'w-evaluations' && i.stage1Feedbacks > 0) ||
                    (viewFilter === 'w-speeddating' && i.stage2Feedbacks > 0) ||
                    (viewFilter === 'w-mentoring' && i.stage3Feedbacks > 0)
                );
            }

            if (hasFilterStage(1)) {
                return (
                    (viewFilter === 'w-evaluations' && i.stage1Feedbacks > 0) ||
                    (viewFilter === 'w-speeddating' && i.stage2Feedbacks > 0) ||
                    (viewFilter === 'w-mentoring' && i.stage3Feedbacks > 0)
                );
            }

            return true;
        });

    const field = {
        className: 'c-header-input',
        placeholder: t('dashboard.startups.search'),
        type: 'search',
        value: filter,
        onChange: handleChange,
    };

    const navigationSection = (filterStage.includes(100) || filterStage.includes(10) || filterStage.includes(1)) ? (
        <>
            <br />
            <Styled.Navigation>
                <Styled.NavigationPage
                    className={activePage === 'Todas' ? 'active' : ''}
                    onClick={() => {
                        setActivePage('Todas');
                        handleViewFilter('all');
                    }}
                >
                    Todas
                </Styled.NavigationPage>
                <Styled.NavigationPage
                    className={activePage === 'Avaliações Online' ? 'active' : ''}
                    onClick={() => {
                        setActivePage('Avaliações Online');
                        handleViewFilter('w-evaluations');
                    }}
                >
                    Avaliações Online
                </Styled.NavigationPage>
                <Styled.NavigationPage
                    className={activePage === 'Speed-Dating' ? 'active' : ''}
                    onClick={() => {
                        setActivePage('Speed-Dating');
                        handleViewFilter('w-speeddating');
                    }}
                >
                    Speed-Dating
                </Styled.NavigationPage>
                <Styled.NavigationPage
                    className={activePage === 'Mentoria' ? 'active' : ''}
                    onClick={() => {
                        setActivePage('Mentoria');
                        handleViewFilter('w-mentoring');
                    }}
                >
                    Mentoria
                </Styled.NavigationPage>
            </Styled.Navigation>
        </>
    ) : null;
    

    return (
        <Styled.DashboardPage>
            <Styled.DashboardPageHead>
                <h2>
                    {t('dashboard.startups.title')} <span className="blue-text">{company.name}</span> {t('dashboard.startups.title2')}
                </h2>
            </Styled.DashboardPageHead>

            <Header className="filter">
                <input name="search" {...field} />
            </Header>

            <Header className="filter">
                {t('dashboard.startups.filterStage')}
                <FilterTag active={hasFilterStage(100)} onClick={() => updFilterStage(100)}>
                    <span className="text">Avaliações Online</span>
                </FilterTag>
                <FilterTag active={hasFilterStage(10)} onClick={() => updFilterStage(10)}>
                    <span className="text">Speed-Dating</span>
                </FilterTag>
                <FilterTag active={hasFilterStage(1)} onClick={() => updFilterStage(1)}>
                    <span className="text">Mentoria</span>
                </FilterTag>
            </Header>

            <Styled.DashboardPageWhite>
                <div className='header'>
                    <div>
                        <h2>
                            <span>STARTUPS QUE A </span><span className="blue-text">{company.name} </span><span>CONHECEU NO PROGRAMA</span>
                        </h2>
                    </div>
                    <div>
                        <Button style={{ marginBottom: '0.5rem' }} onClick={() => downloadDashboardStartups()}>
                            Baixar em CSV
                        </Button>
                    </div>
                </div>

                {navigationSection}

                <Styled.DashboardPageBody>
                    <Startups startups={filtered} t={t} />
                </Styled.DashboardPageBody>
            </Styled.DashboardPageWhite>
        </Styled.DashboardPage>
    );
};
