import { useCXI } from 'modules/cxi/useCXI';
import { useEffectOnce } from 'react-use';

export const SpeedDating = () => {
    const { vars, modal, setModal } = useCXI();
    const { match } = modal;

    useEffectOnce(() => {
        // if (vars.cxi.currentStage === 1) {
        //     setModal({ match, modal: 'SpeedDatingCheck' });
        // } else {
        setModal({ match, modal: 'SpeedDatingForm' });
        // }
    }, []);

    return null;
};
