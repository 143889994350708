import React, { useCallback, useState } from 'react';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { Container, Title, ActionsContainer, FeedbackActionsContainer } from './styles';

interface ActionCardProps {
    type: 'classification' | 'feedback' | 'speed-dating' | 'mentoring';
    onChange(value: string): void;
    onChangeQuestion?: (value: boolean) => void;
}

export const ActionCard: React.FC<ActionCardProps> = ({ type, onChange, onChangeQuestion }) => {
    const [questionSelected, setQuestionSelected] = useState('');
    const [relevance, setRelevance] = useState('');
    const { t } = useTranslation('cxi-old');

    const handleSelect = useCallback(
        (value: string) => {
            onChange(value);
            setRelevance(value);
        },
        [onChange]
    );

    const handleQuestion = useCallback(
        (value: boolean) => {
            if (onChangeQuestion) {
                onChangeQuestion(value);
                setQuestionSelected(value ? 'yes' : 'no');
            }
        },
        [onChangeQuestion]
    );

    const renderActionCard = useCallback(() => {
        switch (type) {
            case 'classification':
                return (
                    <>
                        <Title>{t('StandAloneContent.ActionCard.question1')}</Title>
                        <ActionsContainer>
                            <Button
                                onClick={() => handleSelect('positive')}
                                variant={relevance === 'positive' ? 'success' : 'outline'}
                            >
                                <span className="icon fal fa-grin-hearts" />
                                {t('StandAloneContent.ActionCard.one')}
                            </Button>
                            <Button
                                onClick={() => handleSelect('neutral')}
                                variant={relevance === 'neutral' ? 'yellow' : 'outline'}
                            >
                                <span className="icon fal fa-meh" />
                                {t('StandAloneContent.ActionCard.two')}
                            </Button>
                            <Button
                                onClick={() => handleSelect('negative')}
                                variant={relevance === 'negative' ? 'toggle' : 'outline'}
                            >
                                <span className="icon fal fa-frown" />
                                {t('StandAloneContent.ActionCard.three')}
                            </Button>
                        </ActionsContainer>
                    </>
                );

            case 'feedback':
                return (
                    <>
                        <Title>{t('StandAloneContent.ActionCard.question1')}</Title>
                        <FeedbackActionsContainer>
                            <Button
                                onClick={() => handleSelect('positive')}
                                variant={relevance === 'positive' ? 'success' : 'outline'}
                            >
                                <span className="icon fal fa-grin-hearts" />
                                {t('StandAloneContent.ActionCard.one')}
                            </Button>
                            <Button
                                onClick={() => handleSelect('neutral')}
                                variant={relevance === 'neutral' ? 'yellow' : 'outline'}
                            >
                                <span className="icon fal fa-meh" />
                                {t('StandAloneContent.ActionCard.two')}
                            </Button>
                            <Button
                                onClick={() => handleSelect('negative')}
                                variant={relevance === 'negative' ? 'toggle' : 'outline'}
                            >
                                <span className="icon fal fa-frown" />
                                {t('StandAloneContent.ActionCard.three')}
                            </Button>
                        </FeedbackActionsContainer>
                        <Title>{t('StandAloneContent.ActionCard.question2')}</Title>
                        <ActionsContainer>
                            <Button
                                variant={questionSelected === 'no' ? 'toggle' : 'outline'}
                                onClick={() => handleQuestion(false)}
                            >
                                {t('StandAloneContent.no')}
                            </Button>
                            <Button
                                variant={questionSelected === 'yes' ? 'success' : 'outline'}
                                onClick={() => handleQuestion(true)}
                            >
                                {t('StandAloneContent.yes')}
                            </Button>
                        </ActionsContainer>
                    </>
                );

            case 'speed-dating':
                return (
                    <>
                        <Title>{t('StandAloneContent.ActionCard.question3')}</Title>
                        <ActionsContainer>
                            <Button
                                variant={questionSelected === 'no' ? 'toggle' : 'outline'}
                                onClick={() => handleQuestion(false)}
                            >
                                {t('StandAloneContent.no')}
                            </Button>
                            <Button
                                variant={questionSelected === 'yes' ? 'success' : 'outline'}
                                onClick={() => handleQuestion(true)}
                            >
                                {t('StandAloneContent.yes')}
                            </Button>
                        </ActionsContainer>
                    </>
                );

            case 'mentoring':
                return (
                    <>
                        <Title>{t('StandAloneContent.ActionCard.question1')}</Title>
                        <FeedbackActionsContainer>
                            <Button
                                onClick={() => handleSelect('positive')}
                                variant={relevance === 'positive' ? 'success' : 'outline'}
                            >
                                <span className="icon fal fa-grin-hearts" />
                                {t('StandAloneContent.ActionCard.one')}
                            </Button>
                            <Button
                                onClick={() => handleSelect('neutral')}
                                variant={relevance === 'neutral' ? 'yellow' : 'outline'}
                            >
                                <span className="icon fal fa-meh" />
                                {t('StandAloneContent.ActionCard.two')}
                            </Button>
                            <Button
                                onClick={() => handleSelect('negative')}
                                variant={relevance === 'negative' ? 'toggle' : 'outline'}
                            >
                                <span className="icon fal fa-frown" />
                                {t('StandAloneContent.ActionCard.three')}
                            </Button>
                        </FeedbackActionsContainer>
                        <Title>{t('StandAloneContent.ActionCard.question4')}</Title>
                        <ActionsContainer>
                            <Button
                                variant={questionSelected === 'no' ? 'toggle' : 'outline'}
                                onClick={() => handleQuestion(false)}
                            >
                                {t('StandAloneContent.no')}
                            </Button>
                            <Button
                                variant={questionSelected === 'yes' ? 'success' : 'outline'}
                                onClick={() => handleQuestion(true)}
                            >
                                {t('StandAloneContent.yes')}
                            </Button>
                        </ActionsContainer>
                    </>
                );
            default:
                return null;
        }
    }, [type, questionSelected, relevance, handleQuestion, handleSelect, t]);

    return <Container>{renderActionCard()}</Container>;
};
