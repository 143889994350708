import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';
import * as Styled from './StyledChecklist';

export const ChecklistStage3 = () => {
    const { t, vars, setModal } = useCXI();
    const data = vars.cxi.metrics?.stage3;

    const item1 = Number(data.mentoringSessions || 0);
    const item2 = Number(data.feedbacksPositive || 0);

    return (
        <Styled.Checklist>
            <Styled.ChecklistTitle>
                {t('home.Checklist.progress')}{' '}
                <Button variant="link" onClick={() => setModal({ modal: 'About1' })}>
                    {t('home.Checklist.stage3.title')}
                    <i className="ttip-icon far fa-info-circle" aria-hidden="true" />
                </Button>
            </Styled.ChecklistTitle>

            <Styled.ChecklistItems>
                <Styled.ChecklistItem className={item1 >= 5 ? 'checked' : 'started'}>
                    <span className="icon" aria-hidden="true" />
                    <span className="text">{t('home.Checklist.stage3.step1')}</span>
                    <span className="numb">{`${String(item1).padStart(1, '0')} / 5`}</span>
                </Styled.ChecklistItem>

                <Styled.ChecklistItem className={item2 >= 10 ? 'checked' : 'started'}>
                    <span className="icon" aria-hidden="true" />
                    <span className="text">{t('home.Checklist.stage3.step2')}</span>
                    <span className="numb">{`${String(item2).padStart(2, '0')} / 10`}</span>
                </Styled.ChecklistItem>
            </Styled.ChecklistItems>
        </Styled.Checklist>
    );
};
