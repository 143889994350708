import React from 'react';

import { Message } from '../message/Message';
import * as Styled from './StyledMessages';

export const Messages = ({ match, messages }) => (
    <Styled.Messages>
        {messages.map(message => (
            <Message key={message.messageid} match={match} message={message} />
        ))}
    </Styled.Messages>
);
