import { Button } from 'modules/cxi/components/button';
import React, { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useCXI } from '../useCXI';
import * as Styled from './StyledCXIShareEvaluatorProfile';
import QRCode from './components/QRCode';

export function CXIShareEvaluatorProfile() {
    const { getEvaluatorShareLink, t } = useCXI();
    const [link, setLink] = useState(null);
    const [copy, setCopy] = useCopyToClipboard();
    const [qrCode, setQrCode] = React.useState(null);

    useEffect(() => {
        async function getLink() {
            const linkResponse = await getEvaluatorShareLink();
            setLink(linkResponse);
        }
        getLink();
    }, []);

    return (
        <Styled.CXIProfile>
            <Styled.Title>{t('shareMyProfile.title')}</Styled.Title>
            <Styled.Descr>{t('shareMyProfile.description')}</Styled.Descr>
            {!link && <Styled.Link>{t('shareMyProfile.loading')}</Styled.Link>}
            {link && (
                <Styled.LinkWrapper>
                    <Styled.Link>{t('shareMyProfile.generatedLink')}</Styled.Link>
                    <Styled.Link className="link"> {link}</Styled.Link>
                    <Button variant="share" onClick={() => setCopy(link)}>
                        {!copy.value && (
                            <span className="text">{t('modal.StartupShare.copy')}</span>
                        )}
                        {!!copy.value && (
                            <span className="text">{t('modal.StartupShare.copied')}</span>
                        )}
                    </Button>
                </Styled.LinkWrapper>
            )}
            <QRCode qrCode={qrCode} setQrCode={setQrCode} link={link} />
        </Styled.CXIProfile>
    );
}
