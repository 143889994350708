import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

const initialState = {
    isMenuOpen: false,
    updateMatch: 0,
    challengerForm: null
};

export const CXIContext = createContext();
export const CXIContextProvider = ({ children }) => {
    const { t } = useTranslation('cxi');

    const isMobile = useMedia('(max-width: 991px)');

    // Define variables holder to be used on the module
    const [modal, setModal] = React.useState(null);
    const [vars, setVars] = React.useState({
        ...initialState,
        isMenuOpen: !isMobile
    });
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    const stateValue = {
        t,
        vars,
        addVars,
        setVars,
        modal,
        setModal
    };
    return <CXIContext.Provider value={stateValue}>{children}</CXIContext.Provider>;
};
