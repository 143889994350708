import React from 'react';

import { Header } from 'modules/cxi/components/header/Header';

import { useStartupCXI } from '../../useStartupCXI';

import { Matches } from '../components/matches/Matches';
import * as Styled from '../components/StyledNetworkPage';

export const CXIMentoringIndex = () => {
    const { t, vars } = useStartupCXI();
    const { matches } = vars;

    const mentories = matches.filter(m => m.mentoring && m.mentoring !== 'none');

    const [filter, setFilter] = React.useState('');

    const handleChange = e => {
        return setFilter(e.target.value);
    };

    let filtered = mentories;

    if (filter) {
        filtered = filtered.filter(
            i =>
                i.company?.name?.toLowerCase().includes(filter.toLowerCase()) ||
                i.evaluator?.fullName.toLowerCase().includes(filter.toLowerCase())
        );
    }

    const field = {
        className: 'c-header-input',
        placeholder: t('mentoring.index.search'),
        type: 'search',
        value: filter,
        onChange: handleChange,
    };

    return (
        <Styled.NetworkPage>
            <Styled.NetworkPageHead>
                <h2>{t('mentoring.index.title')}</h2>
                <h3>
                    ({mentories.length} mentorias de {matches.length} matches)
                </h3>
            </Styled.NetworkPageHead>

            <Header className="filter">
                <input name="search" {...field} />
            </Header>

            <Styled.NetworkPageBody>
                <Matches matches={filtered} t={t} />
            </Styled.NetworkPageBody>
        </Styled.NetworkPage>
    );
};
