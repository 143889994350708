import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const PageSubtitle = styled.h2.attrs({
    className: 'c-page-subtitle',
})`
    align-items: center;
    color: #444;
    display: flex;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;
    height: 1.8rem;
    margin: 0 0 0.5rem;
    justify-content: space-between;
    text-align: left;
    text-transform: uppercase;

    .text {
        flex: 1 1 auto;
        ${truncate(1, 1.4)}
    }
`;

export const PageSubtitleButton = styled.span.attrs({
    className: 'c-page-subtitle-button',
})`
    color: var(--primary);
    font-size: 1.2rem;
    flex: 0 0 auto;
    line-height: 1.4rem;
    margin-left: auto;

    .icon {
        font-size: 1em;
        line-height: 1em;
        margin-left: 0.5rem;
    }
`;
