import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const UserCompany = styled.div.attrs({
    className: 'user-company',
})`
    margin-top: 2rem;
    position: relative;

    .c-page-links-with-text {
        margin-top: 1rem;
    }
`;

export const UserCompanyCard = styled.div.attrs({
    className: 'user-company-card',
})`
    align-items: center;
    background: #fff;
    color: inherit;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
`;

export const CardImage = styled.div.attrs({
    className: 'user-company-card-image',
})`
    background: #eee no-repeat 50% 50%;
    background-image: url(${props => props.img});
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    flex: 0 0 6.5rem;
    height: 6.5rem;
    padding: 0.5rem;
`;

export const CardInfos = styled.div.attrs({
    className: 'user-company-card-infos',
})`
    flex: 1 1 calc(100% - 6.5rem);
    font-size: 1.4rem;
    margin-left: 1.5rem;
    text-align: left;

    .name {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.4;
        ${truncate(1)}
    }

    .bold {
        display: block;
        font-weight: 600;
        margin-top: 0;
        ${truncate(1)}
    }

    .text {
        display: block;
        line-height: 1.2;
        margin-top: 0.5rem;
        ${truncate(1, 1.2)}
    }
`;
