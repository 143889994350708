import styled from 'styled-components';

export const Card = styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
`;

export const Container = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;
// GRAPHICS CONTAINER
export const GraphicsContainer = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
`;

// LEFT SESSION
export const LeftSession = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 70%;
    height: 99%;
    padding: 5px 5px;
    margin: 0;
`;

export const GraphicsCaption = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 5%;
    justify-content: left;
    text-align: left;
`;

/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// RIGHT SESSION

export const RightSession = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 5px 5px;
    gap: 5px;
    margin: 0;
`;

export const RightSessionContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 49%;
    align-items: center;
`;

export const Title = styled.div`
    display: flex;
    padding: 5px 10px 0 10px;
    flex-wrap: nowrap;
    flex-direction: column;

    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.3rem;
        flex-wrap: nowrap;
    }

    h2 {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
    }
`;
export const LegendContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    bottom: 3rem;
    right: 3rem;
    gap: 15px;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;
export const LegendText = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
`;

export const Circle = styled.div`
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid black;

    &.blue {
        background-color: #80d6e9e8;
    }
    &.green {
        background-color: #62ab62ec;
    }
`;

export const ExecutiveTableList = styled.table`
    width: 100%;
    justify-content: center;
    align-items: baseline;
    empty-cells: show;
    tr {
        text-align: left;
        color: #0085f2;
        border-bottom: 3px solid #0085f2;
        height: 36px;
        color: black;
        font-weight: normal;
        height: 53px;
        max-height: 53px;
        &:first-child {
            height: 18px;

            th {
                padding-left: 20px;
                color: #0085f2;
                font-weight: normal;
                :nth-child(3) {
                    text-align: right;
                    padding-right: 20px;
                }
            }
        }
        &:last-child {
            border: none;
        }

        td {
            height: 60px;
            max-height: 60px;
            :nth-child(2) {
                text-align: left;
                font-weight: bold;
                padding-left: 20px;
            }
            :nth-child(3) {
                text-align: right;
                font-weight: bold;
                padding-right: 40px;
            }
        }
    }
`;

export const TableContainer = styled(Card)`
    display: flex;
    width: 100%;
    height: 90%;
`;
