import React from 'react';
import * as Styled from './StyledPageImage';

export const PageImage = props => {
    let childrenArray = [];
    const children = props.children || [];

    if (children.props) {
        childrenArray.push(children);
    } else {
        childrenArray = children;
    }

    return (
        <Styled.PageImage {...props}>
            {childrenArray.map(child => {
                return child.props.src ? (
                    <img
                        width={child.props.width}
                        src={child.props.src}
                        alt={child.props.alt}
                        key={child.props.src}
                    />
                ) : (
                    child
                );
            })}
        </Styled.PageImage>
    );
};
export const Image = PageImage;
export default PageImage;
