import styled from 'styled-components';

export const HomeListNews = styled.div.attrs({
    className: 'home-list-news',
})`
    margin-top: 4rem;

    .list-vertical {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: -1rem 0 0 -2rem;
    }
    .list-vertical-item {
        padding: 2rem 0 0 2rem;
    }
    @media (min-width: 567px) {
        .list-vertical-item {
            flex: 0 0 50%;
        }
    }
    @media (min-width: 992px) {
        .list-vertical-item {
            flex: 0 0 33.333%;
        }
    }
`;
