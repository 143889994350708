import styled from 'styled-components';

export const EvaluateForm = styled.div``;

export const EvaluateFormField = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin-top: 0.8rem;
    padding: 1.2rem 1.6rem;

    .component-form-field {
        margin: 0;

        .fake-control {
            min-height: auto;
        }
        .form-control {
            border: 0;
            padding: 0;
        }
        .form-control-label {
            color: #0094f9;
            font-weight: 600;
        }
    }
`;

export const EvaluateFormOptions = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.2rem;
    justify-content: center;
    margin-top: 1.6rem;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    .cxi-c-button {
        width: 100%;
        @media (min-width: 768px) {
            width: auto;
        }

        .icon {
            display: none;
        }
        &.checked .icon {
            display: inline-block;
        }
    }

    .cxi-c-button.btn-0 {
        border-color: #9a8bbc;
        color: #9a8bbc;
        &.checked {
            background: #9a8bbc;
            color: #fff;
        }
    }
    .cxi-c-button.btn-1 {
        border-color: #cccccc;
        color: #999;
        &.checked {
            background: #cccccc;
            color: #000;
        }
    }
    .cxi-c-button.btn-2 {
        border-color: #4db5c4;
        color: #4db5c4;
        &.checked {
            background: #4db5c4;
            color: #fff;
        }
    }
`;

export const Space = styled.div`
    height: 1.6rem;
    @media (min-width: 992px) {
        height: 3.2rem;
    }
`;

export const Descr = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    gap: 1rem;
    line-height: 1.4;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.3;

    .smll {
        color: ${({ theme }) => theme.colors.neutralDark};
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 600;
        margin-left: 0.8rem;
    }

    &.form-title {
        margin: 0 auto;
        max-width: 48rem;
        text-align: center;
        @media (min-width: 992px) {
            margin-top: -1.2rem;
        }
    }
`;
