import React from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from 'hooks';

import { FormAutocomplete } from '../autocomplete/FormAutocomplete';
import { FormCustomStartupItem } from './FormCustomStartupItem';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {}
};

function companyValue(value) {
    return value.name;
}

export function FormCustomStartup(props) {
    const { t } = useTranslation('glossary');
    const api = useApi();

    // Manage field information states
    const [items, setItems] = React.useState([]);
    const [value, setValue] = React.useState(props.value);

    // Handles the click on an item of the autocomplete
    function handleClick(val) {
        setValue(val);
        // Create a fake event to pass trough
        const event = { ...fakeEvent, target: { name: props.name, value: val } };
        return props.onChange(event);
    }

    function handleItems(item) {
        return {
            id: item._id,
            full: item,
            // Default item link fields
            text: companyValue(item),
            arrw: 'far fa-dot-circle',
            onClick: handleClick
        };
    }

    // Handles the call to the api for the results
    function itemsFetch(val) {
        if (val.length > 2) {
            api.get(`/startups/?name=${val}`).then(res => setItems(res.map(handleItems)));
        }
    }

    // Clear some input variables to pass trough
    const field = {
        ...props,
        icon: 'far fa-rocket',
        type: 'text',
        value: ''
    };
    // Create the field value
    if (value && value.name) {
        field.value = companyValue(value);
    }

    // Create the arguments to build the Autocomplete
    const propsAutocomplete = {
        field,
        // Props to search
        items,
        itemKey: 'id',
        itemComponent: FormCustomStartupItem,
        itemsFetch,
        // Props to customize the modal
        title: field.label,
        search: t('startupSearch'),
        required: field.required
    };
    return (
        <FormAutocomplete {...propsAutocomplete} initialValue={value}>
            {props.children}
        </FormAutocomplete>
    );
}
export const Startup = FormCustomStartup;
export default FormCustomStartup;
