import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';
import * as Styled from './StyledChecklist';

export const ChecklistStage2 = () => {
    const { t, vars, setModal } = useCXI();
    const data = vars.cxi.metrics?.stage2;

    const item1 = Number(data.feedbacksPositive || 0);

    return (
        <Styled.Checklist>
            <Styled.ChecklistTitle>
                {t('home.Checklist.progress')}{' '}
                <Button variant="link" onClick={() => setModal({ modal: 'About10' })}>
                    {t('home.Checklist.stage2.title')}
                    <i className="ttip-icon far fa-info-circle" aria-hidden="true" />
                </Button>
            </Styled.ChecklistTitle>
            <Styled.ChecklistItems>
                <Styled.ChecklistItem className={item1 >= 10 ? 'checked' : 'started'}>
                    <span className="icon" aria-hidden="true" />
                    <span className="text">{t('home.Checklist.stage2.step1')}</span>
                    <span className="numb">{`${String(item1).padStart(2, '0')} / 10`}</span>
                </Styled.ChecklistItem>
            </Styled.ChecklistItems>
        </Styled.Checklist>
    );
};
