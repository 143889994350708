import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMatchAction';

export const MatchActionOptions = ({ match }) => {
    const { setModal } = useCXI();

    return (
        <Styled.MatchAction
            className="icon-only options"
            onClick={() => setModal({ match, modal: 'StartupOptions' })}
        >
            <span className="icon fas fa-fw fa-ellipsis-v" aria-hidden="true" />
        </Styled.MatchAction>
    );
};
