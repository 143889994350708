import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const PageTitle = styled.h1.attrs({
    className: `c-page-title`,
})`
    align-items: center;
    display: flex;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.25;
    margin: 1rem 0 0;

    .text {
        ${truncate(2, 1.25)}
        width: 100%;
    }
`;
