import React from 'react';
import { useEffectOnce } from 'react-use';

import { CA, CG } from 'contexts';
import { useApi, useApiLocation, useLangDetection } from 'hooks';

export function AppAsync() {
    const api = useApi();
    const apiLocation = useApiLocation();
    const detectLanguage = useLangDetection();

    const { state, dispatch } = React.useContext(CA);
    const { globalDispatch } = React.useContext(CG);

    // Start to detect the language

    function updateLocation() {
        // Saves the location to the context
        const saveLocation = res => {
            const location = res || {};
            globalDispatch({ type: 'LOCATION', location });
        };
        // Saves the geolocation to the context
        const saveGeolocation = res => {
            const geolocation = res || {};
            globalDispatch({ type: 'GEOLOCATION', geolocation });
        };

        // Get the position
        const pos = resp => {
            const { latitude, longitude } = resp.coords;
            const coords = { lat: latitude, lng: longitude };
            saveGeolocation(coords);
            // console.log('AppAsync', 'handlesLocation', { coords });
            apiLocation.near(coords).then(res => saveLocation(res[0]));
        };
        // Get the position if error
        const err = () => {
            // console.log('AppAsync', 'handlesLocation', err);
            apiLocation
                .nearFromIp()
                .then(res => saveLocation(res[0]))
                .catch(err => null);
        };

        // Get the current postion from navigator
        navigator.geolocation.getCurrentPosition(pos, err);
    }

    function updateUser() {
        const { userid } = state.user;
        api.get(`/users/${userid}`).then(user => {
            if (user) dispatch({ type: 'UPDATE_USER_INFO', user });
        });
    }

    useEffectOnce(() => {
        // Get the user location and saves to the context
        updateLocation();
        // Start to the detect the device language
        detectLanguage.detect();
        // Update the user information if authenticated
        if (state.authenticated) updateUser();
    });

    return null;
}
export default AppAsync;
