import styled from 'styled-components';

export const StartupShare = styled.div`
    text-align: center;
    gap: 1.2rem 1rem;

    .m-container-feet {
        flex-flow: inherit;
        display: flex;
    }

    .shareLink {
        background: #e2fcff;
        border-radius: 4.2px;
        padding: 8px;
        font-color: #388089;
        font-size: 11px;
        width: 400px;
    }
`;

export const Descr = styled.div`
    font-size: 1.4rem;
    line-height: 1.4;
`;

export const Title = styled.div`
    color: #005995;
    font-size: 20px;
    font-weight: 700;
`;

export const SubTitle = styled.div`
    color: #005995;
    font-size: 16px;
    font-weight: 700;
`;
