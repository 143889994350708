import styled from 'styled-components';
import progress from 'assets/images/progress.svg';

export const CXIProgress = styled.div.attrs({
    className: 'styled-cxi-progress',
})`
    background: #003866;
    color: #fff;
    margin: 2.5rem 0 0 0;
    padding: 1rem;
    position: relative;
`;

export const Head = styled.div.attrs({
    className: 'cxi-progress-head',
})`
    display: flex;
    justify-content: space-between;

    .name {
        font-size: 1.4rem;
        font-weight: 700;
        text-transform: uppercase;
    }
`;

export const Body = styled.div.attrs({
    className: 'cxi-progress-body',
})`
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
`;

export const Module = styled.div.attrs(props => ({
    className: `cxi-progress-module ${props.status}`,
}))`
    background: #dddddd;
    border: 1px solid #003866;
    color: #a8a8a8;
    font-size: 1rem;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 33%;
    .text {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
    }
    .icon {
        background: #fff;
        border-radius: 50%;
        height: 15px;
        margin: 0 0 0 7px;
        padding: 1px 2px 2px 2px;
        width: 15px;
        i {
            font-size: 1rem;
        }
    }
    .bar {
        background: #45aaf2;
        height: 23px;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 0;
    }
    &.finalized,
    &.in-progress {
        color: #003866;
        i {
            color: #45aaf2;
        }
    }
    &.in-progress {
        .icon {
            display: none;
        }
        .bar::before {
            background: repeat-x 0 3px;
            background-image: url(${progress});
            content: '';
            height: 23px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
`;
