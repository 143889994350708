import React from 'react';

import * as Match from 'modules/cxi/components/match';
import { useCXI } from 'modules/cxi/useCXI';
import { MatchActionOptionsExtended } from 'modules/cxi/components/match/action/MatchActionOptionsExtended';

import * as Styled from './StyledMatches';

export const Matches = ({ matches, t }) => {
    const { vars } = useCXI();
    const { cxi } = vars;

    return (
        <Styled.Matches>
            <Styled.MatchesTbody>
                {matches.map(match => (
                    <Styled.MatchesTr key={match.matchid}>
                        <Styled.MatchesTd>
                            <Match.MatchStartup match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <Match.MatchEvaluation match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <Match.MatchFeedback match={match} />
                        </Styled.MatchesTd>

                        {cxi.currentStage > 1 && (
                            <Styled.MatchesTd>
                                <Match.MatchSpeedDating match={match} />
                            </Styled.MatchesTd>
                        )}

                        {cxi.currentStage > 2 && (
                            <Styled.MatchesTd>
                                <Match.MatchMentoring match={match} />
                            </Styled.MatchesTd>
                        )}

                        <Styled.MatchesTd>
                            <Match.MatchPoints match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <MatchActionOptionsExtended match={match} />
                        </Styled.MatchesTd>
                    </Styled.MatchesTr>
                ))}
            </Styled.MatchesTbody>
        </Styled.Matches>
    );
};
