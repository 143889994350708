import React from 'react';
import { useConversionTable } from 'hooks';
import * as Styled from './StyledTags';

export const Tags = ({ tags }) => {
    const conv = useConversionTable(['challenge', 'community', 'industry', 'trend']);

    const tagsTeste = tags
        ?.map(c => {
            let name;
            if (c.challenge && conv && conv.challenge) {
                name = conv.challenge[c.challenge];
            } else if (c.community && conv && conv.community) {
                name = conv.community[c.community];
            } else if (c.industry && conv && conv.industry) {
                name = conv.industry[c.industry];
            } else if (c.trend && conv && conv.trend) {
                name = conv.trend[c.trend];
            }
            return { name, isCommon: c.isCommon };
        })
        .filter(tag => !!tag)
        .sort((a, b) => (a.isCommon ? -1 : 1));

    return (
        !!tags?.length && (
            <Styled.Tags className="tags">
                {tagsTeste.map(
                    tag =>
                        !!tag && (
                            <li
                                className={`${tag.isCommon ? 'active' : 'inactive'}`}
                                key={tag.name}
                            >
                                {tag.name}
                            </li>
                        )
                )}
            </Styled.Tags>
        )
    );
};
