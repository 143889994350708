import React from 'react';

import { MatchActionEvaluate } from './MatchActionEvaluate';
import { MatchActionReevaluate } from './MatchActionReevaluate';

export const MatchAction = ({ match }) => {
    if (match.calculatedStage === 'evaluated') {
        return <MatchActionReevaluate match={match} />;
    }

    return <MatchActionEvaluate match={match} />;
};
