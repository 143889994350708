import { IonActionSheet } from '@ionic/react';
import { FileCopy } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt-br';

import { Button, Descr, Ruler, Subtitle } from 'components/page';
import { ContextGlobal as CG } from 'contexts';
import { useConversionTable } from 'hooks';

import * as Table from 'components/table';

import { ContractCard } from './ContractCard';
import { ContractReaudit } from './ContractReaudit';
import * as Styled from './styles';

export function Contract(props) {
    const { emitAlert, state } = React.useContext(CG);

    const { t } = useTranslation('ranking');
    const [copy, setCopy] = useCopyToClipboard();
    const conv = useConversionTable(['contractType', 'yesNo']);

    const [showAS, setShowAS] = React.useState(false);
    const [reauditing, setReauditing] = React.useState(false);
    const [startToEndDate, setStartToEndDate] = React.useState();

    const {
        deleteStartupContract,
        postStartupContractValidation,
        postStartupContractValidationEmail,
        vars
    } = props.useRankingStartup;

    const data = props.data || props.relationship || undefined;
    // Moment language
    let lang = state.language;

    if (state.language === 'pt') {
        lang = 'pt-br';
    }

    const contractStartDate = moment(data.startDate)
        .utc()
        .locale(lang)
        .format('MMM YYYY');

    const contractEndDate = moment(data.endDate)
        .utc()
        .locale(lang)
        .format('MMM YYYY');

    const regDate = moment(data.regDate)
        .utc()
        .locale(lang)
        .format('DD MMM YYYY');

    const formalizedOption = data.contractInfo.formalized || false;

    useEffect(() => {
        if (!data.startDate && !data.endDate) {
            setStartToEndDate('Vigência não declarada');
        } else {
            setStartToEndDate(
                t('dateRange', {
                    startDate: data.startDate ? contractStartDate : 'Data não declarada',
                    endDate: data.endDate ? contractEndDate : 'data não declarada'
                })
            );
        }
    }, [data.startDate, data.endDate]);

    let contract = {};

    if (data?.companyContact && data?.companyContact.contactName) {
        contract.contactName = data.companyContact.name;
    }

    if (data?.companyContact && data?.companyContact.email) {
        contract.contactEmail = data.companyContact.email;
    }

    contract.contractType = conv?.contractType[data?.contractInfo.contractType];

    if (data.type === 'acquisition') {
        contract.contractType = `${t('StartupStep8.typeOldAcquisition')}`;
    }

    if (data.type === 'investment') {
        contract.contractType = `${t('StartupStep8.typeOldInvestment')}`;
    }

    contract = {
        ...contract,
        valueRange: data?.contractInfo.valueRangeText || 'N/A',
        registrationDate: regDate,
        startToEndDate,
        formalized: conv.yesNo[String(formalizedOption)]
    };

    // Properties for the table
    const propsTable = {
        object: {
            data: { ...contract },
            name: 'Contracts',
            dataConv: {}
        }
    };

    if (data.companyStatus !== 'confirmed') {
        propsTable.href = undefined;
        propsTable.subtitle = {
            link: '',
            icon: ''
        };
    }

    const closeModal = () => {
        if (props.closeModal) props.closeModal();
        return true;
    };

    function handleConfirm() {
        const payload = {
            _id: data._id,
            status: 'confirmed',
            lastUpdated: data.lastUpdated,
            entity: 'startup'
        };
        return postStartupContractValidation(payload).then(closeModal);
    }

    function handleDelete() {
        const payload = { _id: data._id };
        return deleteStartupContract(payload).then(res => {
            closeModal();
            emitAlert(t('contractDeleted'));
            return res;
        });
    }

    function handleReject() {
        const payload = {
            _id: data._id,
            status: 'rejected',
            entity: 'startup',
            lastUpdated: data.lastUpdated
        };
        return postStartupContractValidation(payload).then(closeModal);
    }

    function handleResend() {
        const payload = { _id: data._id };
        return postStartupContractValidationEmail(payload).then(res => {
            emitAlert(t('contractResent'));
            return res;
        });
    }

    function clickReaudit() {
        setReauditing(true);
    }

    const buttons = [];
    // if (data.startupStatus === 'pending') {
    //     const l = { type: 'link', onClick: () => setShowAS(true) };
    //     buttons.push(() => (
    //         <Styled.ContractButtons>
    //             <Button type="dark" onClick={handleResend}>
    //                 <span className="text">{t('glossary:resend')}</span>
    //                 <span className="icon far fa-long-arrow-right" aria-hidden="true" />
    //             </Button>
    //             <Button {...l}>{t('glossary:deleteContract')}</Button>
    //         </Styled.ContractButtons>
    //     ));
    // }

    if (data.status === 'pending' && data.companyStatus === 'pending') {
        const r = { type: 'dark', onClick: handleResend };

        buttons.push(() => (
            <Styled.ContractButtons>
                <Button {...r}>{t('glossary:resend')}</Button>
            </Styled.ContractButtons>
        ));
    }

    if (data.status === 'pending' && data.startupStatus === 'pending') {
        const s = { type: 'success', onClick: handleConfirm };
        const d = { type: 'danger', onClick: handleReject };

        buttons.push(() => (
            <Styled.ContractButtons>
                <Button {...s}>{t('glossary:accept')}</Button>
                <Button {...d}>{t('glossary:reject')}</Button>
            </Styled.ContractButtons>
        ));
    }

    if (data?.status === 'pending' && data.companyStatus === 'pending') {
        const l = { type: 'link', onClick: () => setShowAS(true) };

        buttons.push(() => <Button {...l}>{t('contractDelete')}</Button>);
    }

    if (
        !reauditing &&
        data.audited === 'rejected' &&
        data.displayAudit &&
        !data.reconsiderationResult
    ) {
        buttons.push(() => (
            <Button type="dark" onClick={clickReaudit}>
                <span className="text">{t('glossary:reaudit')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Button>
        ));
    }

    return (
        <Styled.Contract className={props.className} style={props.style}>
            <ContractCard data={data} fromContract />

            <Table.Table {...propsTable} />

            <Styled.ContractIdContainer>
                <span>
                    {data._id}
                    <Styled.CopyButton
                        type="dark"
                        variant="neutral"
                        onClick={() => setCopy(data._id)}
                    >
                        <FileCopy> </FileCopy>
                        {!copy.value && <span className="text"> </span>}
                        {!!copy.value && <span className="text"> </span>}
                    </Styled.CopyButton>
                </span>
                <p>ID</p>
            </Styled.ContractIdContainer>

            {data?.contractInfo.description && <Descr>{data?.contractInfo.description}</Descr>}

            {data.displayAudit && data.auditComment && (
                <>
                    <Ruler />
                    <Subtitle text={t('auditComment')} />
                    <Descr>{data.auditComment}</Descr>
                </>
            )}

            <ContractReaudit
                data={data}
                lang={lang}
                reauditing={reauditing}
                setReauditing={setReauditing}
                useRankingStartup={props.useRankingStartup}
            />
            {buttons.map((Btn, k) => (
                // eslint-disable-next-line react/no-array-index-key
                <Btn key={`button-${k}`} />
            ))}
            <IonActionSheet
                isOpen={showAS}
                onDidDismiss={() => setShowAS(false)}
                buttons={[
                    {
                        text: t('contractDelete'),
                        role: 'destructive',
                        handler: handleDelete
                    },
                    {
                        text: t('glossary:cancel'),
                        role: 'cancel'
                    }
                ]}
            />
        </Styled.Contract>
    );
}
export default Contract;
