import styled from 'styled-components';

export const CorporateHubIndex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #3b3e45;
    font-family: 'Kanit', sans-serif;
`;

export const BackgroundLayer = styled.div`
    display: flex;
    flex-direction: column;
    width: 110vw;
    height: 110vh;
    z-index: -1;
    background-color: #3b3e45;
    position: absolute;
    top: 0;
    left: 0;
`;
