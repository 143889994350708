import React from 'react';
import axios from 'axios';

import { CONFIG } from 'config';
import { CA, CG } from 'contexts';

export function useApiOmnize() {
    const { state } = React.useContext(CA);
    const { emitAlert } = React.useContext(CG);

    const api = axios.create({
        baseURL: 'https://core.omnize.com.br/api/v1',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        transformRequest: [
            (data, headers) => {
                const newData = { token: CONFIG.omnize.token, ...data };
                return JSON.stringify(newData);
            },
        ],
    });

    // axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';

    // Setup response interceptor to handle errors
    api.interceptors.response.use(
        response => response.data,
        error => {
            // Get the response with the error
            const { response } = error;
            // Throw error and show alert message!
            let message = 'Unidentified error message';
            if (response && response.data && response.data.error) {
                message = response.data.error.message;
            }
            emitAlert(message);
            return Promise.reject(error);
        }
    );

    function postOffContact(values) {
        // Build the payload to be sent
        const payload = {
            department_id: CONFIG.omnize.department_id,
            content: values.message,
            customer: { email: values.email || state.user.email },
        };
        return api.post('offcontact', payload);
    }

    return {
        postOffContact,
    };
}
export default useApiOmnize;
