import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Belts = styled.div`
    margin-top: 4.8rem;
`;

export const BeltsHead = styled.div`
    align-items: center;
    display: flex;
`;

export const BeltsBody = styled.div`
    display: grid;
    gap: 3.2rem;
    margin-top: 3.2rem;

    @media (min-width: 568px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

export const BeltsCard = styled.div.attrs(props => ({
    role: 'button',
    'aria-label': props.title,
}))`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 0.8rem;
    padding: 1.6rem;
    position: relative;

    &:focus .icon,
    &:hover .icon {
        border-color: ${({ theme }) => theme.colors.deepBlue};
        transition: 0.2s;
    }

    img {
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        width: 12rem;
    }

    .text {
        font-size: 1.6rem;
        font-weight: 700;
    }
    .date {
        display: block;
        margin-top: -0.8rem;
        opacity: 0.8;
        text-align: center;
    }

    .icon {
        align-items: center;
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid #c3c6d0;
        border-radius: 0.8rem;
        color: ${({ theme }) => theme.colors.deepBlue};
        display: flex;
        height: 4.4rem;
        justify-content: center;
        position: absolute;
        ${positions('1.6rem', '1.6rem', 'auto', 'auto')}
        width: 4.4rem;
    }
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 22px;
    margin: 0;
`;
export const Button = styled.button`
    display: none;
    margin-left: auto;
    @media (min-width: 480px) {
        display: inline-flex;
    }
`;
