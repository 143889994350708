import styled from 'styled-components';

export const ComponentList = styled.div.attrs(props => ({
    className: `component-list ln-${props.name} lf-${props.format}`,
}))`
    margin-top: 2.5rem;
`;

export const NoResults = styled.div.attrs({
    className: 'componen-list-noresults',
})``;
