import React from 'react';

import { useConversionTable } from 'hooks';

import { useCXIDashboardStartup } from '../useCXIDashboardStartup';

import * as Styled from './StyledCXIDashboardStartupSummaries';

export const CXIDashboardStartupSummariesTechnology = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    const conv = useConversionTable(['yesNo']);
    const data = startup.demographics.techStatus;

    return (
        <>
            <Styled.CXIDashboardStartupSummariesTechnology>
                {!!data.institution && (
                    <>
                        <dt>{t('startup.institution')}</dt>
                        <dd>{data.institution}</dd>
                    </>
                )}

                {!!data.hasPatent && (
                    <>
                        <dt>{t('startup.hasPatent')}</dt>
                        <dd>{conv.yesNo[data.hasPatent === 1]}</dd>
                    </>
                )}
            </Styled.CXIDashboardStartupSummariesTechnology>

            <Styled.CXIDashboardStartupSummariesTechnology>
                {!!data.hasPatent && data.patentWhere && (
                    <>
                        <dt>{t('startup.patentWhere')}</dt>
                        <dd>{data.patentWhere}</dd>
                    </>
                )}

                {!!data.hasPatent && data.patentWho && (
                    <>
                        <dt>{t('startup.patentWho')}</dt>
                        <dd>{data.patentWho}</dd>
                    </>
                )}

                {!!data.hasPatent && data.patentHowMuch && (
                    <>
                        <dt>{t('startup.patentHowMuch')}</dt>
                        <dd>{data.patentHowMuch}</dd>
                    </>
                )}
            </Styled.CXIDashboardStartupSummariesTechnology>
        </>
    );
};
