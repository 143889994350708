import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { routes } from 'core/pages/PagesRoutes';
import * as List from 'components/list';

import { MenuListPagesItem } from './MenuListPagesItem';
import * as Styled from './StyledMenuListPages';

export function MenuListPages(props) {
    const { globalState } = React.useContext(CG);
    const { authenticated } = globalState;

    // Filter the routes to be shown to the user
    const filter = r => r.name && (r.private === false || r.private === authenticated);

    const listProps = {
        items: routes.filter(filter),
        itemKey: 'path',
        itemComponent: MenuListPagesItem,
    };
    return (
        <Styled.MenuListPages as={List.List} name="MenuListPages">
            <List.Vertical {...listProps} />
        </Styled.MenuListPages>
    );
}
export default MenuListPages;
