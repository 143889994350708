import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { Browser } from '@capacitor/browser';
import { IonContent, isPlatform } from '@ionic/react';

import { ContextGlobal as CG } from 'contexts';
import { RHP, LSP } from 'providers';

import { whitelist, useIframe } from './useIframe';
import * as Styled from './StyledIframe';

const StyledIframeContent = props => (
    <IonContent>
        <Styled.Iframe>{props.children}</Styled.Iframe>
    </IonContent>
);

export function Iframe(props) {
    const { url } = useParams() || props;
    const exitRoute = LSP.get('100os-iframeExitRoute') || '/home';

    // Build the iframe using the props
    const iframe = useIframe({ url, exitRoute });

    // Dispatch the context to hide the menu
    const { dispatch } = React.useContext(CG);

    const messageReducer = message => {
        if (!message.type) {
            return;
        }
        if (message.type === 'meeting') {
            LSP.set('100os-meetIframeExitRoute', message.back);
            RHP.push(`/meet/${message.room}`);
            return;
        }
        if (!isPlatform('capacitor')) {
            // console.log('##### App is running on web');
            // console.log('##### Open on another tab');
            Browser.open({ url: message.url, toolbarColor: '#45aaf2' });
            return;
        }
        // console.log('##### App is running on native');
        if (message.type === 'redirect') {
            // console.log('##### Open on external browser');
            window.open(message.url, '_blank');
        }
        if (message.type === 'inappbrowser') {
            // console.log('##### Open on in-app browser');
            Browser.open({ url: message.url, toolbarColor: '#45aaf2' });
        }
    };

    function handleLoad(ev) {
        try {
            // console.log('handleIframeLoad', ev.target.contentWindow.location.href);
            const objUrl = new URL(ev.target.contentWindow.location.href);
            // If was allowed to get iframe location, it means thatit is using the same
            // domain as the main app,so we need to move to the route and exit the iframe component
            iframe.removeEventListener('load', handleLoad);
            console.log('handleLoad', 'oks', objUrl.href);
            const avaliableLocation = ev.target.contentWindow.location;
            RHP.push(avaliableLocation.pathname);
        } catch (err) {
            console.log('handleLoad', 'err', ev, err);
            // Not logging because it is expected behavior
        }
    }

    function checkOrigin(origin) {
        return whitelist.includes(origin.replace(/(^\w+:|^)\/\//, ''));
    }

    function handleMessage(ev) {
        // console.log('Message from iframe received');
        // console.log(ev);
        // console.log('exitRoute', exitRoute);
        if (ev.data && ev.data.type) {
            if (ev.data.type === 'exit') {
                // console.log('Messaged to close iframe, redirect back to exit');
                LSP.del('100os-iframeExitRoute');
                RHP.push(ev.data.url || exitRoute);
            } else if (checkOrigin(ev.origin)) {
                // console.log('Other message, reduce it');
                messageReducer(ev.data);
            } else {
                // console.log('Origin not on whitelist');
                // console.log(ev.origin);
            }
        } else {
            // console.log('No data type');
        }
    }

    useEffectOnce(() => {
        if (!iframe) {
            RHP.replace(exitRoute);
            return undefined;
        }
        document.getElementById('iframe').appendChild(iframe);
        window.addEventListener('message', handleMessage);
        iframe.addEventListener('load', handleLoad);
        // Hide the menu on the iframe page
        dispatch({ type: 'MENU_SHOW', menu: false });

        return () => {
            // Remove the event listeners
            window.removeEventListener('message', handleMessage);
            iframe.removeEventListener('load', handleLoad);
            dispatch({ type: 'MENU_SHOW', menu: true });
        };
    });

    return !iframe ? null : (
        <StyledIframeContent>
            <div className="iframe" id="iframe" />
        </StyledIframeContent>
    );
}
export default Iframe;
