import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { LinksWithText, Subtitle } from 'components/page';
import { CONFIG } from 'config';
import { CA, CG } from 'contexts';
import { iframeLink, iframePush } from 'core/iframe';
import { useApi } from 'hooks';
import { Button } from 'modules/cxi/components/button';
import * as Styled from './StyledUserStartups';
import { UserStartupsCard } from './UserStartupsCard';
import { UserStartupsList } from './UserStartupsList';

const Lwtxt = props =>
    !props.text && !props.link ? null : (
        <LinksWithText to={props.href}>
            {props.text && <span className="text">{props.text}</span>}
            {props.link && <span className="link">{props.link}</span>}
        </LinksWithText>
    );

const Title = props => (!props.text ? null : <Subtitle {...props} />);

export function UserStartups(props) {
    const { state } = React.useContext(CA);
    const stateGlobal = React.useContext(CG)?.state;
    const { language } = stateGlobal;

    const { t } = useTranslation('components');
    const api = useApi();
    // Get the startup data source
    const [data, setData] = React.useState([]);

    const hasTechs = data?.filter(startup => {
        return startup.typeOfProject === 'tech';
    });

    const handleRegisterNewTech = () => {
        const url = encodeURI(
            `${CONFIG.appLinks.startup}/registerstartup/?typeOfProject=tech&lang=${language}`
        );
        iframePush(url)();
    };

    // Get the startup from the api
    let mounted = false;
    useEffectOnce(() => {
        mounted = true;
        const { userid } = state.user;
        if (!data.length || data.length === 0) {
            api.get(`users/${userid}/startups`).then(res => {
                // console.log(res, 'response');
                return mounted && setData(res);
            });
        }
        return () => {
            mounted = false;
        };
    });
    // Continue only after data is set
    if (!data || !data.length || data.length === 0) {
        return false;
    }

    // const hrefStartup = CONFIG.appLinks.startup;

    // Decide witch information to show
    let Component = UserStartupsList;

    // Create a userStartups props object
    let propsData = {
        title: {
            icon: 'far fa-pencil',
            link: t('glossary:edit'),
            text:
                hasTechs?.length > 0 && hasTechs?.length === data?.length
                    ? t('UserStartups.singleTechTitle')
                    : t('UserStartups.profile')
        },
        multiple: {
            title: {
                text:
                    hasTechs?.length > 0 && hasTechs?.length === data?.length
                        ? t('UserStartups.techsTitle')
                        : t('UserStartups.list'),
                link: false
            }
        },
        data
    };

    // Check the list if it has a single one
    if (data.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        propsData.data = propsData.data[0];

        propsData.data.href =
            propsData?.data?.startupStatus === 'registered'
                ? `startup/${propsData.data._id}`
                : iframeLink(
                      `${CONFIG.appLinks.startup}/registerstartup/?startupId=${propsData.data._id}&lang=pt&back=/home/?startupId=${propsData.data._id}`
                  );

        propsData.title.href = propsData.data.href;
        Component = UserStartupsCard;
    } else {
        propsData = {
            ...propsData.multiple,
            data: data.map(d => {
                return {
                    ...d,
                    href:
                        d.startupStatus === 'registered'
                            ? `startup/${d._id}`
                            : iframeLink(
                                  `${CONFIG.appLinks.startup}/registerstartup/?startupId=${d._id}&lang=pt&back=/home/?startupId=${d._id}`
                              )
                }; // http://localhost:3006/iframe/http%3A%2F%2Fstartup.openstartups.net%2Fregisterstartup%2F%3FstartupId=5a1db6eab96b57f4f95c74ae&lang=pt&back=/home/?startupId=5a1db6eab96b57f4f95c74ae
            })
        };
        propsData.title.href = '/startup';
    }
    return !data.length ? null : (
        <Styled.UserStartups multiple={data.length}>
            <Title {...propsData.title} />
            <Component {...propsData} />
            {hasTechs?.length > 0 && (
                <>
                    <hr />
                    <Button onClick={handleRegisterNewTech}>
                        {t('startup:StartupIndex.registerStartup')}{' '}
                    </Button>
                </>
            )}
            <Lwtxt {...propsData.lwtxt} />
        </Styled.UserStartups>
    );
}
export default UserStartups;
