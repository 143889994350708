import React from 'react';
import { useTranslation } from 'react-i18next';

import { MatchesCXIRoutes } from './MatchesCXIRoutes';

export function MatchesCXI(props) {
    const { t } = useTranslation('matches');
    return <MatchesCXIRoutes t={t} />;
}
export default MatchesCXI;
