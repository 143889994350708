import React from 'react';
import { Link } from 'react-router-dom';

import { iframeLink } from 'core/iframe';

import * as Page from 'components/page';

import { OiweekBack } from '../components';

import * as Styled from './StyledOiweekSuccess';

const Button = props => (
    <Styled.OiweekButton as={Link} to={props.to}>
        {props.children}
        {props.name && <span className="name">{props.name}</span>}
        {props.text && <span className="text">{props.text}</span>}
        {props.numb && <span className="numb">{props.numb}</span>}
        {props.link && <span className="bttn btn btn-sm btn-primary btn-round">{props.link}</span>}
    </Styled.OiweekButton>
);

export const OiweekSuccessSDFree = props => {
    const { vars } = props.useOiweek;
    const { oiweek, oiweekProducts } = vars;

    let price = '';
    if (oiweek.payPerUsePrice && oiweek.payPerUsePrice.currency) {
        const { currency, amount } = oiweek.payPerUsePrice;
        price = new Intl.NumberFormat('pt-br', { style: 'currency', currency }).format(amount);
    }

    // Button: PayPerUse
    const propsPayPerUse = {
        to: '/oiweek/payperuse',
        name: props.t('OiweekSuccess.singleName'),
        text: props.t('OiweekSuccess.singleDesc'),
        numb: props.t('OiweekSuccess.singlePrice', { price }),
        link: props.t('OiweekSuccess.productButton'),
    };

    const products = oiweekProducts.map(p => ({
        id: p.id,
        to: iframeLink(p.link, '/oiweek/success'),
        name: p.acf.product_name,
        text: p.acf.product_description,
        numb: p.acf.product_value,
        link: props.t('OiweekSuccess.productButton'),
    }));

    return (
        <Page.Page name="OiweekSuccessSDFree">
            <Page.Title>{props.t('OiweekSuccess.title')}</Page.Title>
            <Page.Descr>{props.t('OiweekSuccess.descr-SD-free1')}</Page.Descr>
            <Page.Descr>{props.t('OiweekSuccess.descr-SD-free2')}</Page.Descr>

            <Styled.OiweekSuccessBody>
                <Page.Title>{props.t('OiweekSuccess.body-title')}</Page.Title>
                <Page.Descr>{props.t('OiweekSuccess.body-descr')}</Page.Descr>

                {oiweek.payPerUse && <Button {...propsPayPerUse} />}

                {products.map(p => (
                    <Button key={`product-${p.id}`} {...p} />
                ))}

                <Page.Discl>{props.t('OiweekSuccess.body-discl')}</Page.Discl>

                <OiweekBack oiweek={oiweek} />
            </Styled.OiweekSuccessBody>
        </Page.Page>
    );
};
export default OiweekSuccessSDFree;
