import React from 'react';

import { CA, CG } from 'contexts';
import { RHP } from 'providers';
import { useStartupCXI } from 'modules/startup/_startupId/cxi/useStartupCXI';
import * as Styled from './StyledMatchConnection';

export const MatchConnectionSD = ({ match }) => {
    const { state: auth } = React.useContext(CA);
    const { state: global } = React.useContext(CG);
    const { t, vars, postMatchMessage } = useStartupCXI();
    const { startup, startupId } = vars;

    const { user } = auth;

    const handleClick = async () => {
        const message = t('match.connection.speeddating-chat', {
            evaluatorName: match.evaluator?.firstName ?? 'Avaliador',
            userName: user.firstName,
            startupName: startup.name
        });
        const payload = { language: global.language, text: message };
        try {
            await postMatchMessage(match.matchId, payload);
        } catch (e) {
            console.error(e);
        }
        RHP.push(`/startup/${startupId}/100-10-1/messages/${match.matchId}`);
    };

    return (
        <Styled.MatchConnection className="sd" onClick={handleClick}>
            <span className="icon far fa-fw fa-hands-helping" aria-hidden="true" />
            <span className="text">{t('match.connection.speeddating')}</span>
        </Styled.MatchConnection>
    );
};
