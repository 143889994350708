import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { ProfilePasswordForm } from './ProfilePasswordForm';

export function ProfilePassword(props) {
    const { emitToast } = React.useContext(CG);
    const { vars, postNewPassword } = props.useProfile;

    // Initialize a new form handler
    const form = useForm({
        email: vars.email,
    });

    // Custom onChange events
    const onChange = {
        newpassword: ev => {
            const error = props.t('glossary:passwordCheckError');
            const name = 'newpasswordCheck';
            form.hasEqualValues(ev, 'newpassword', { name, error });
        },
        newpasswordCheck: ev => {
            const error = props.t('glossary:passwordCheckError');
            form.hasEqualValues(ev, 'newpassword', { error });
        },
    };

    // Handle form submition
    const onSubmit = values => {
        const { email, lastpassword, newpassword } = values;
        // Do the submition api request
        return postNewPassword({ email, lastpassword, newpassword })
            .then(() => {
                emitToast(props.t('ProfilePassword.saveSuccess'));
                RHP.push('/profile');
            })
            .finally(() => form.submited());
    };

    const propsForm = { ...props, form, onChange, onSubmit };
    return (
        <Page.Page name="ProfilePassword" back="/profile">
            <Page.Title>{props.t('ProfilePassword.title')}</Page.Title>
            <Page.Descr>{props.t('ProfilePassword.descr')}</Page.Descr>

            <ProfilePasswordForm {...propsForm} />
        </Page.Page>
    );
}
export default ProfilePassword;
