import { UserAvatar } from 'core/components/user/avatar/UserAvatar';
import React from 'react';
import * as Styled from './pocs-item-styles';

export function PocsItem({ data }) {
    const { email, photoUrl, poc, fullName, evaluatorProfile } = data;

    const localName = data?.name || fullName;
    const localDepartment = data?.department || evaluatorProfile?.department || 'N/A';
    const localPosition = data?.position || evaluatorProfile?.position || 'N/A';

    return (
        <Styled.RelationshipCard>
            <UserAvatar user={{ name: localName, photoUrl }} />
            <Styled.CardInfos>
                <span className="name">{localName}</span>
                <span className="bold">{email}</span>
                {localDepartment && (
                    <span className="text">
                        {localDepartment} / {localPosition}
                    </span>
                )}
            </Styled.CardInfos>
        </Styled.RelationshipCard>
    );
}
