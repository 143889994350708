import React from 'react';

import * as Styled from './StyledFilterTag';

export const FilterTag = ({ active, children, onClick }) => (
    <Styled.FilterTag active={active} onClick={onClick}>
        {children}
        {active && <span className="icon fas fa-times-circle" aria-hidden="true" />}
    </Styled.FilterTag>
);
