import React, { useState } from 'react';
import tooltip from 'assets/images/icons/tooltipRanking.png';
import * as Styled from './StyledStartupPosition';
import PositionCard from './PositionCard';

export function CompanyPosition(props) {
    const { data, t } = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const { declared, confirmed, audited } = data || {};
    return !data ? null : (
        <Styled.Container>
            <Styled.Title>
                {t('CorporateStep4.positionCardTitle')}
                <span
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <img src={tooltip} alt="tooltip icon" />
                </span>
                {showTooltip && (
                    <Styled.TitleTooltip>
                        {t('CorporateStep4.positionCardTitleTooltip')}
                    </Styled.TitleTooltip>
                )}
            </Styled.Title>
            <Styled.CardContainer>
                <PositionCard
                    contracts={declared}
                    title={t('CorporateStep4.declaredContracts')}
                    tooltipText={t('CorporateStep4.declaredTooltipText')}
                    {...props}
                />
                <PositionCard
                    contracts={confirmed}
                    title={t('CorporateStep4.confirmedContracts')}
                    tooltipText={t('CorporateStep4.confirmedTooltipText')}
                    {...props}
                />
                <PositionCard
                    contracts={audited}
                    title={t('CorporateStep4.auditedContracts')}
                    tooltipText={t('CorporateStep4.auditedTooltipText')}
                    {...props}
                />
            </Styled.CardContainer>
            {(confirmed?.upper === 0 || declared?.upper === 0) && (
                <Styled.ZeroPointsText>
                    {t('CorporateStep4.positionCardZeroPointsText')}
                </Styled.ZeroPointsText>
            )}
        </Styled.Container>
    );
}
export default CompanyPosition;
