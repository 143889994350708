import React from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'components/form';
import * as Page from 'components/page';
import { CA, CG } from 'contexts';
import { RHP } from 'providers';

import { cnpj } from 'cpf-cnpj-validator';
import { ContractTypesModal } from './contract-types-modal';
// eslint-disable-next-line import/named
import { CorporateStep4AddForm } from './add-form';

export function CorporateStep4Add(props) {
    const { t } = useTranslation('ranking');
    const ContextGlobal = React.useContext(CG);
    const { emitAlert, state } = ContextGlobal;
    const { language } = state;

    const {
        state: { user }
    } = React.useContext(CA);

    const { vars, postUserContract, getCompanyByEmail } = props.useRankingCorporate;
    const { ranking, companyDetails } = vars;
    const currDate = new Date();

    const [skip, setSkip] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [dateError, setDateError] = React.useState(false);

    // Initialize a new form handler
    const form = useForm({
        taxId: companyDetails?.taxId || '',
        corporateContractCountry: companyDetails?.country || '',
        year: currDate.getUTCFullYear(),
        otherContact: false
    });

    const onBlur = {
        isValidCNPJ: ev => {
            const { name, value } = ev.target;
            // Clear the errors and warnings before validating
            form.clearMessages({ name });
            // Only check if the value is filled
            if (!value || form.values.corporateContractCountry !== 'BR') return false;
            // Check if the CPF is a valid one
            const cleanValue = value.replace(/[^0-9]/g, '');

            if (!cnpj.isValid(cleanValue)) {
                // Emit error message for registered email
                const error = t('StartupStep2.warningTextCnpj');
                return form.emitError({ name, error });
            }
            return true;
        }
    };

    // Custom onChange events
    const onChange = {
        startup: () => {
            setSkip(false);
            form.emitError({ name: 'startup', error: undefined });
        },

        date: event => {
            const { name, value } = event.target;
            if (name === 'endDate')
                setDateError(form.values?.startDate?.substring(0, 7) > value.substring(0, 7));
            if (name === 'startDate')
                setDateError(form.values?.endDate?.substring(0, 7) < value.substring(0, 7));
        }
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // Handle form submitions
    const onSubmit = async values => {
        // Make sure the date fields are not empty
        if (!values.startDate || !values.endDate) {
            emitAlert(t('contractNoStartEndDate'));
            form.submited();
            return;
        }

        const startDate = `${values?.startDate?.substring(0, 7)}-01T00:00:00.000+0000`;
        const endDate = `${values?.endDate?.substring(0, 7)}-01T00:00:00.000+0000`;

        const currentCompany = await getCompanyByEmail(values.executiveResponsibleEmail);

        const isValidEmail = currentCompany.company?.id === companyDetails._id;

        if (!isValidEmail) {
            emitAlert(
                `O email ${values.executiveResponsibleEmail} do executivo deve pertencer a mesma empresa`
            );
            console.log('currentCompany', currentCompany);
            console.log('companyDetails', currentCompany);
            form.submited();
            return;
        }

        const payload = {
            contractInfo: {
                contractType: values.contractType,
                description: values.contractDescription,
                startDate,
                endDate,
                disclosureAllowed: !values.isSecret,
                formalized: values.formalized === 'true',
                valueRangeCurrency: 'USD',
                countries: values.contractExecutionCountries
            },

            companyExecutive: {
                email: values.executiveResponsibleEmail
            },
            companyTaxId: values.taxId,
            companyCountry: values.corporateContractCountry,
            emailLanguage: values.emailLanguage,

            reportedBy: 'Company',
            ranking,
            startup: {}
        };

        if (values.valueRange?.length) {
            payload.contractInfo.valueRange = values.valueRange;
        }

        if (values.startup && values.startup._id) {
            payload.startup._id = values.startup._id;
            // delete payload.startup;
        } else if (values.startupName && values.startupCountry) {
            // Add startup contact information
            payload.startup = {
                name: values.startupName,
                location: {
                    country: values.startupCountry
                }
            };
        }

        if (values.contactName && values.contactEmail) {
            payload.startupContact = {
                name: values.contactName,
                email: values.contactEmail
            };
        }

        // eslint-disable-next-line consistent-return
        return (
            postUserContract(payload).then(() => RHP.push('/ranking/latam/corporate/step4')),
            () => RHP.push('/ranking/latam/corporate/step4').catch(() => form.submited())
        );
    };

    const propsForm = {
        ...props,
        form,
        skip,
        setSkip,
        onChange,
        onBlur,
        onSubmit,
        openModal,
        dateError,
        language
    };
    return (
        <Page.Page name="CorporateStep4Add">
            <Page.Title>{props.t('CorporateStep4Add.title')}</Page.Title>
            <Page.LWTXT onClick={openModal}>
                {props.t('CorporateStep4Add.descr')}{' '}
                <span className="link d-inline">{props.t('CorporateStep4Add.knowMore')}</span>
            </Page.LWTXT>

            <CorporateStep4AddForm {...propsForm} />

            <ContractTypesModal
                title={props.t('CorporateStep4Add.modalTitle')}
                isOpen={isOpen}
                close={closeModal}
            />
        </Page.Page>
    );
}
