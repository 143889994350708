import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Page from './index';

export const routes = [
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/mentoring',
        component: Page.CXIMentoringIndex,
    },
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/mentoring/accepted',
        component: Page.CXIMentoringAccepted,
    },
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/mentoring/rejected',
        component: Page.CXIMentoringRejected,
    },
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/mentoring/requested',
        component: Page.CXIMentoringRequested,
    },
];

export const CXIMentoringRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default CXIMentoringRoutes;
