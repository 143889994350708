import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { useParams } from 'react-router';
import { InvestorStep3EditForm } from './InvestorStep3EditForm';

export function InvestorStep3Edit(props) {
    const { id } = useParams();
    const conv = useConversionOptions(['investmentType']);
    const { vars, putUserInvestment } = props.useRankingInvestor;
    const { ranking } = vars;

    const investment = vars.investments.filter(i => i._id === id)[0];
    if (!investment) RHP.goBack();

    // Initialize a new form handler
    const form = useForm({
        ...investment,
        companyName:
            investment.investor && investment.investor.companyName
                ? investment.investor.companyName
                : '',
        isConfirmed: investment.status === 'confirmed',
    });

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            _id: values._id,
            date: values.date,
            type: values.type,
            round: values.round,
            value: values.value,
            ranking,
            lastUpdated: values.lastUpdated,
        };
        // CompanyName is present?
        if (values.companyName) {
            payload.investor = {};
            payload.investor.companyName = values.companyName;
        }
        if (values.value && !values.value.amount) {
            payload.value = undefined;
        }
        return putUserInvestment(payload)
            .then(() => {
                RHP.push('/ranking/latam/investor/step3');
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="InvestorStep3Edit">
            <Page.Title>{props.t('InvestorStep3Edit.title')}</Page.Title>
            <Page.Descr>{props.t('InvestorStep3Edit.descr')}</Page.Descr>

            <InvestorStep3EditForm {...propsForm} />
        </Page.Page>
    );
}
export default InvestorStep3Edit;
