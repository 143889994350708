import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import * as CXI from './index';

export const routes = [
    {
        exact: true,
        path: '/100-10-1',
        component: CXI.CXIIndex
    },
    {
        exact: true,
        path: '/100-10-1/contents',
        component: CXI.CXIContents
    },

    {
        exact: false,
        path: '/100-10-1/dashboard/:companyId',
        component: CXI.CXIDashboard
    },
    {
        exact: true,
        path: '/100-10-1/home',
        component: CXI.CXIHome
    },
    {
        exact: true,
        path: '/100-10-1/matches',
        component: CXI.CXIMatches
    },
    {
        exact: false,
        path: '/100-10-1/mentoring/',
        component: CXI.CXIMentoring
    },
    {
        exact: true,
        path: '/100-10-1/messages/:matchId',
        component: CXI.CXIMessages
    },
    {
        exact: true,
        private: true,
        path: '/100-10-1/challenges',
        component: CXI.CXIChallenges
    },
    {
        exact: true,
        private: true,
        path: '/100-10-1/challenges/:challengeId',
        component: CXI.CXIChallengeProfile
    },
    {
        exact: true,
        path: '/100-10-1/challenges/:challengeId/startups/:startupId',
        component: CXI.CXIStartupPage
    },
    {
        exact: false,
        path: '/100-10-1/form',
        component: CXI.CXIFormChallenges,
    },
    {
        exact: false,
        path: '/100-10-1/messages',
        component: CXI.CXIMessages
    },
    {
        exact: false,
        path: '/100-10-1/networks',
        component: CXI.CXINetworks
    },
    {
        exact: true,
        path: '/100-10-1/profile',
        component: CXI.CXIProfile
    },
    {
        exact: false,
        path: '/100-10-1/setup',
        component: CXI.CXISetup
    },
    {
        exact: true,
        path: '/100-10-1/startup/:id',
        component: CXI.CXIStartup
    },
    {
        exact: true,
        path: '/100-10-1/support',
        component: CXI.CXISupport
    },
    {
        exact: true,
        path: '/100-10-1/sharemyprofile',
        component: CXI.CXIShareEvaluatorProfile
    },
    {
        exact: true,
        path: '/100-10-1/opportunities',
        component: CXI.CXIOpportunities
    },
    // redirect if url doesn't match any route
    {
        exact: false,
        icon: 'far fa-users',
        path: '*',
        component: () => <Redirect to="/100-10-1/home" />
    }
];

export function CXIRoutes() {
    return (
        <Switch>
            {routes.map(route => {
                const Component = route.component;
                const routeProp = { ...route, component: undefined };
                return <Route key={route.path} {...routeProp} render={() => <Component />} />;
            })}
        </Switch>
    );
}
export default CXIRoutes;
