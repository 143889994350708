import styled from 'styled-components';

export const BottomPositionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding-top: 11px;
    padding-bottom: 9px;
    gap: 30px;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #d3d3d3;
        border-radius: 3px;
        padding: 5px 10px;
    }
`;
export const BottomPosition = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    position: relative;
`;

export const Tooltip = styled.p`
    position: absolute;
    top: 30px;
    left: -150px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: black;
    width: 400px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #45aaf2;
    padding: 15px 17px;
    z-index: 100;
    b {
        color: #45aaf2;
    }
`;
