import React from 'react';

import { MatchActionMentoringContribution } from 'modules/cxi/components/match/action/MatchActionMentoringContribution';
import { MatchActionMentoringSessionAdd } from 'modules/cxi/components/match/action/MatchActionMentoringSessionAdd';
import { MatchActionMentoringSession } from 'modules/cxi/components/match/action/MatchActionMentoringSession';
import { formatDate } from 'utils/date';

import { useCXIMentoring } from '../../useCXIMentoring';

import * as Styled from './StyledSessions';

export const SessionsTable = ({ match, sessions, t }) => {
    const { vars } = useCXIMentoring();
    const { evaluations } = vars;

    return !sessions.length ? (
        <Styled.SessionsTableEmpty>
            <span className="icon fa-2x far fa-empty-set" aria-hidden="true" />
            <Styled.SessionsTableEmptyTitle>
                {t('mentoring.SessionsTable.empty')}
            </Styled.SessionsTableEmptyTitle>
            <Styled.SessionsTableEmptyDescr>
                {t('mentoring.SessionsTable.emptyDescr')}
            </Styled.SessionsTableEmptyDescr>
            <MatchActionMentoringSession match={match} />
        </Styled.SessionsTableEmpty>
    ) : (
        <Styled.SessionsTable>
            <Styled.SessionsTableThead>
                <Styled.SessionsTableTr>
                    <Styled.SessionsTableTh>
                        {t('mentoring.SessionsTable.id')}
                    </Styled.SessionsTableTh>
                    <Styled.SessionsTableTh>
                        {t('mentoring.SessionsTable.date')}
                    </Styled.SessionsTableTh>
                    <Styled.SessionsTableTh>
                        {t('mentoring.SessionsTable.contributions')}
                    </Styled.SessionsTableTh>
                    <Styled.SessionsTableTh />
                </Styled.SessionsTableTr>
            </Styled.SessionsTableThead>

            <Styled.SessionsTableTbody>
                {sessions.map((session, idx) => {
                    const sessionEvaluations = evaluations.filter(
                        e => e.mentoringSessionId === session._id
                    );
                    return session.fake ? (
                        <Styled.SessionsTableTr key={session._id}>
                            <Styled.SessionsTableTd>
                                {String(idx + 1).padStart(2, '0')}
                            </Styled.SessionsTableTd>
                            <Styled.SessionsTableTd>
                                {t('mentoring.SessionsTable.notRegistered')}
                            </Styled.SessionsTableTd>
                            <Styled.SessionsTableTd>-</Styled.SessionsTableTd>
                            <Styled.SessionsTableTd>
                                <MatchActionMentoringSessionAdd match={match} />
                            </Styled.SessionsTableTd>
                        </Styled.SessionsTableTr>
                    ) : (
                        <Styled.SessionsTableTr key={session._id}>
                            <Styled.SessionsTableTd>
                                {String(idx + 1).padStart(2, '0')}
                            </Styled.SessionsTableTd>
                            <Styled.SessionsTableTd className="dated">
                                <strong>
                                    {formatDate(
                                        new Date(session.meetingDate || ''),
                                        `iiii, dd 'de' LLLL, yyyy`
                                    )}
                                </strong>
                                <span className="participants">{session.meetingParticipants}</span>
                            </Styled.SessionsTableTd>
                            <Styled.SessionsTableTd>
                                {sessionEvaluations.length}{' '}
                                {t(
                                    `mentoring.SessionsTable.${
                                        sessionEvaluations.length === 1
                                            ? 'contribution'
                                            : 'contributions'
                                    }`
                                )}
                            </Styled.SessionsTableTd>
                            <Styled.SessionsTableTd>
                                <MatchActionMentoringContribution
                                    match={{ ...match, mentoringSessionId: session._id }}
                                />
                            </Styled.SessionsTableTd>
                        </Styled.SessionsTableTr>
                    );
                })}
            </Styled.SessionsTableTbody>
        </Styled.SessionsTable>
    );
};
