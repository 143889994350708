import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 2.4rem;
    height: 100%;
    width: 100%;

    @media (min-width: 992px) {
        &.banner {
            margin-top: 5.4rem;
        }
    }
`;

export const UserProfile = styled.span`
    font-weight: bold;
    margin-right: auto;
`;

export const About = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    height: 100%;
    width: 100%;
    align-items: center;

    @media (min-width: 992px) {
        flex-direction: row;
    }
`;

export const AboutBody = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #d6d6d6;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 2.3rem;
    text-align: center;
    width: 100%;

    .pocs-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }

    .pocs-item {
        width: 100%; /* Alterado para 100% para exibir apenas um por linha em dispositivos móveis */
        overflow: hidden;
    }

    @media (min-width: 768px) {
        .pocs-item {
            width: calc(50% - 20px); /* Mantido para exibir dois por linha em telas maiores */
        }
    }

    .user-avatar {
        flex: 0 0 6.4rem;
        width: 6.4rem;
        border-radius: 50%;
        overflow: hidden;
    }

    .user {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 1.6rem;
        text-align: center;
    }

    .infos {
        align-items: flex-start;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        gap: 0.4rem;
    }

    .infos span {
        font-weight: 400;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }

    .infos .name {
        font-size: 1.6rem;
        font-weight: 600;
    }

    .infos .comp {
        font-size: 1.4rem;
    }

    .infos .post {
        font-size: 1.2rem;
        opacity: 0.6;
        text-align: left;
    }

    .about-button {
        flex: 1 1 auto;
        white-space: nowrap;
        width: 100%;
    }
`;
