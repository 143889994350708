import React from 'react';

import logoRanking from 'assets/images/logo-ranking.svg';

import { Area } from '../components/area/Area';
import { useCXIDashboardStartup } from '../useCXIDashboardStartup';

import { CXIDashboardStartupGeneralRankingItem } from './StyledCXIDashboardStartupGeneral';

export const CXIDashboardStartupGeneralRanking = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    return !startup.badges?.length ? null : (
        <Area className="ranking" title={t('startup.ranking')}>
            {startup.badges.map(b => (
                <CXIDashboardStartupGeneralRankingItem key={b.name}>
                    <img src={logoRanking} alt="Logo Ranking Open Startups" />
                    <span className="text">{b.name}</span>
                </CXIDashboardStartupGeneralRankingItem>
            ))}
        </Area>
    );
};
