import styled from 'styled-components';

export const CXIDashboardStartupProfile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) {
        gap: 3.2rem;
        -white-space: pre-line;
}
    }
`;
