import React from 'react';

import { ListScrollsHold } from './hold/ListScrollsHold';
import { ListScrollsItem } from './ListScrollsItem';
import * as Styled from './StyledListScrolls';

export const ListScrolls = props =>
    !props.items.length ? null : (
        <Styled.ListScrolls as={ListScrollsHold}>
            {props.items.map((i, k) => {
                const kk = i[props.itemKey] || `${props.name}-${props.itemKey}-${k}`;
                return <ListScrollsItem key={kk} data={i} {...props} />;
            })}
        </Styled.ListScrolls>
    );
export const Scrolls = ListScrolls;
export default ListScrolls;
