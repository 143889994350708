import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContextGlobal as CG } from 'contexts';
import { currencyList } from 'utils/data';

import { FormRow } from 'components/form/row/FormRow';
import { FormField } from 'components/form/field/FormField';

import * as Styled from './StyledFormCustomCurrencyAmount';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {},
};

export function FormCustomCurrencyAmount(props) {
    const { state } = React.useContext(CG);
    const { t } = useTranslation('glossary');
    const currencies = currencyList().map(c => ({ name: c.text, value: c.code }));

    const currencyByLang = {
        pt: 'BRL',
        en: 'USD',
        es: 'USD',
    };
    const initialValue = { ...props.value };
    if (!initialValue.currency) {
        initialValue.currency = currencyByLang[state.language];
    }

    // Variables to control the values of the fields
    const [values, setValues] = React.useState(initialValue);

    // Set currency to USD when Corporate Investment Type is selected
    if (props.lockSelectCurrency) {
        values.currency = 'USD';
    }

    // Easier handler for value addition
    const addValues = val => setValues(v => ({ ...v, ...val }));

    // Handle everytime the input changes
    function handleChange(ev) {
        ev.preventDefault();
        const { name, value } = ev.target;
        // Create a fake component to pass trough
        const event = { ...fakeEvent };
        event.target = {
            name: props.name,
            value: {
                currency: values.currency,
                amount: values.amount,
            },
        };
        event.target.value[name] = value.replace(/\./g, '');
        addValues({ [name]: value });
        return props.onChange(event);
    }

    // Create form fields object
    const fields = {
        empty: {
            ...props,
            label: null,
            required: true,
            type: 'empty',
        },
        currency: {
            ...props,

            label: t('currency'),
            name: 'currency',
            optgroups: [
                {
                    label: t('currencyMostUsed'),
                    options: [...currencies.filter(c => ['BRL', 'COP', 'USD'].includes(c.value))],
                },
                {
                    label: t('currencyOthers'),
                    options: currencies,
                },
            ],
            type: 'select',
            value: values.currency,
            onChange: handleChange,
            readOnly: props.lockSelectCurrency,
        },
        amount: {
            ...props,
            label: props.label || t('amount'),
            name: 'amount',
            related: { currency: values.currency },
            type: 'money',
            value: values.amount,
            onChange: handleChange,
        },
    };

    return (
        <Styled.FormCustomCurrencyAmount as="fieldset">
            <FormField {...fields.empty} />
            <FormRow>
                <FormField size="4" {...fields.currency} />
                <FormField size="8" {...fields.amount} />
            </FormRow>
        </Styled.FormCustomCurrencyAmount>
    );
}
export const CurrencyAmount = FormCustomCurrencyAmount;
export default FormCustomCurrencyAmount;
