import React from 'react';
import { useClickAway } from 'react-use';

import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledAbout10';

export const About10 = () => {
    const { setModal } = useCXI();
    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
        setModal(null);
    });

    return (
        <Styled.About10 ref={containerRef}>
            <Styled.Close onClick={() => setModal(null)}>
                <span className="icon far fa-times" aria-hidden="true" />
            </Styled.Close>
            <Container.Head>
                <span className="text">Etapa de Speed-Dating</span>
            </Container.Head>

            <Container.Body>
                <p>
                    Nesta etapa você continuará a receber sugestões de startups, acumulando
                    conhecimento e ampliando possibilidades de conexão.
                </p>
                <p>
                    Além disso, agora você poderá agendar reuniões de SPEED-DATINGS, ou seja,
                    reuniões de 30 minutos com as startups para entender mais sobre a solução
                    proposta, e com isso buscar mais formas de contribuir a partir dos seus
                    conhecimentos e rede de contatos.
                </p>
                <p>Para agendar uma reunião é muito simples!</p>
                <p>
                    Vá para o seu PAINEL, e selecione dentre as startups avaliadas as que você
                    gostaria de pedir um speed-dating. Clique no botão PEDIR SPEED-DATING e indique
                    o motivo pelo qual você está interessado neste agendamento.
                </p>
                <p>
                    Ao confirmar, uma mensagem é enviada automaticamente para a startup. Em MINHAS
                    CONVERSAS você poderá acompanhar a conversa com a startup e combinar o melhor
                    formato para a reunião de vocês.
                </p>
                <p>
                    Assim que finalizar, clique em AVALIAR SPEED-DATING e deixe suas contribuições
                    quanto a time, solução, modelo de negócio, tamanho de mercado e escalabilidade.
                </p>
                <p>
                    Você passará de etapa quando atingir 10 speed-datings avaliados como “Muito
                    Relevante” pelas startups.
                </p>
                <p>Boas conexões!</p>
            </Container.Body>
        </Styled.About10>
    );
};
