import styled from 'styled-components';

export const CXIChallengeStartupProfile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    font-size: 1.4rem;

    @media (min-width: 992px) {
        gap: 3.2rem;
    }
`;
