import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const ButtonAction = styled.div`
    .dropdown {
        display: flex;
        flex-wrap: nowrap;
    }

    .dropdown-button:first-child {
        flex-grow: 1;
        width: 170px;
    }

    .dropdown-button {
        background-color: #0094f9;
        color: #ffffff;
        border: none;
        border-radius: 4px;
        padding: 10px;
        font-size: 16px;
        width: 30px;
        cursor: pointer;
        position: relative;
    }

    .dropdown-menu {
        position: absolute;
        padding: 0.5rem;
        display: block;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        width: 250px;
        border: 1px solid #cccccc;
        border-radius: 4px;
        border-color: #0094f9;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    button {
        background-color: white;
        color: #0094f9;
        border: none;
        border-radius: 4px;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
    }

    hr {
        background-color: aliceblue;
        margin: 1px;
    }
`;

export const Infos = styled.span`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .name {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.3;
    }
    .description,
    .position {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.3;
        ${truncate(1, 1.3)}
    }

    .company {
        color: ${({ theme }) => theme.colors.black};
        font-weight: 500;
    }
`;

export const Buttn = styled.span``;
