import React from 'react';

import * as Step from '../components/step';
import { Trends } from '../forms/Trends';

export const CXIStep3 = () => (
    <Step.Step currentStep={3}>
        <Trends />
    </Step.Step>
);
