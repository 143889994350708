import React from 'react';
import { Link } from 'react-router-dom';

import * as Styled from './StyledListSteps';

export const ListStepsItemLink = ({ data }) => {
    const item = { ...data };
    // Check if the item is a link
    if (item.href !== undefined) {
        item.as = Link;
        item.to = item.href;
    }
    // Check if the item has a custom arrow
    item.icon = item.icon || 'far fa-long-arrow-right';

    // Check if the step is completed
    if (data.isCompleted) {
        item.type = 'completed';
        item.icon = 'far fa-check-circle';
    }

    // Check if the step is blocked
    if (data.isBlocked) {
        item.type = 'blocked';
        // Clear the link properties
        item.as = undefined;
        item.to = undefined;
        // Change the icon
        item.icon = 'far fa-lock';
        // Handle onClick to nothing
        item.onClick = ev => ev.preventDefault();
    }

    return (
        <Styled.ListStepsItemLink as={item.as} to={item.to} type={item.type}>
            {item.step && <span className="step">{item.step}</span>}
            {item.text && <span className="text">{item.text}</span>}
            {item.icon && <span className={`icon fa-fw ${item.icon}`} aria-hidden="true" />}
        </Styled.ListStepsItemLink>
    );
};
export const StepsItemLink = ListStepsItemLink;
export default ListStepsItemLink;
