import React from 'react';
import { RHP } from 'providers';
import * as Styled from './StyledNavigation';

export const NavigationPage = ({ children, path, handleComponent, component }) => {
    const route = path;
    // eslint-disable-next-line prefer-const
    let { activeComponent, handlePageChange } = handleComponent;
    let active = activeComponent === component ? 'active' : '';
    if (component === undefined) {
        active = '';
    }
    if (path) {
        handlePageChange = () => RHP.push(route);
    }

    return (
        <Styled.NavigationPage className={active} onClick={() => handlePageChange(component)}>
            {children}
        </Styled.NavigationPage>
    );
};

export const Page = NavigationPage;
