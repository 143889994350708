import { useEffectOnce } from 'react-use';

import { getDateXDaysAgo } from 'utils/date';
import { useStartupCXI } from '../../useStartupCXI';

export const Mentoring = () => {
    const { modal, setModal } = useStartupCXI();
    const { match } = modal;
    const { history, mentoring } = match;

    // Check if there is older than 5 days ago
    const daysAgo = getDateXDaysAgo(5, new Date());
    const dateMatch = history.mentoringRequestedDate
        ? new Date(history.mentoringRequestedDate)
        : new Date(match.regDate);

    console.log(dateMatch, daysAgo);

    const isOverdue = mentoring === 'requested' && dateMatch && dateMatch < daysAgo;

    useEffectOnce(() => {
        if (isOverdue) {
            setModal({ match, modal: 'MentoringOverdue' });
            return;
        }
        setModal({ match, modal: 'MentoringForm' });
    }, []);

    return null;
};
