import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary } from 'components/error-boundary';

import { StartupCXILayout } from './layout/StartupCXILayout';
import { StartupCXIModal } from './modal/StartupCXIModal';

import { StartupCXIRoutes } from './StartupCXIRoutes';
import { useStartupCXI } from './useStartupCXI';
import { StartupCXIContextProvider } from './StartupCXIContext';
import { Loading } from './components/loading';

const StartupCXIModule = () => {
    const { t } = useTranslation('startup-cxi');
    const hookd = useStartupCXI({ bootstrap: true });
    return !hookd.ready ? (
        <Loading>{t('loading')}</Loading>
    ) : (
        <StartupCXILayout>
            <StartupCXIRoutes t={t} useStartupCXI={hookd} />
            {!!hookd.modal && <StartupCXIModal />}
        </StartupCXILayout>
    );
};

export const StartupCXI = props => (
    <ErrorBoundary>
        <StartupCXIContextProvider useStartupId={props.useStartupId}>
            <StartupCXIModule />
        </StartupCXIContextProvider>
    </ErrorBoundary>
);
export default StartupCXI;
