import styled from 'styled-components';

export const Matches = styled.table``;

export const MatchesThead = styled.thead``;
export const MatchesTbody = styled.tbody``;

export const MatchesTd = styled.td`
    background: white;
    padding: 1.6rem;
    text-align: center;

    &:first-child {
        text-align: left;
    }
`;

export const MatchesTh = styled.th`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    padding: 0 0 0.6rem 1.2rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    width: auto;

    @media (min-width: 992px) {
        padding: 0 0 1.2rem 1.2rem;
    }

    &:first-child {
        padding-left: 0 !important;
        text-align: left;
        width: 100%;
    }
    &:last-child {
        padding-left: 1.2rem;
        padding-right: 0;
        text-align: left;
    }
`;

export const MatchesTr = styled.tr`
    border-top: 1px solid ${({ theme }) => theme.colors.whiteDarker};

    &:first-child {
        border-top: 0;

        td:first-child {
            border-top-left-radius: 0.8rem;
        }
        td:last-child {
            border-top-right-radius: 0.8rem;
        }
    }

    &:last-child {
        td:first-child {
            border-bottom-left-radius: 0.8rem;
        }
        td:last-child {
            border-bottom-right-radius: 0.8rem;
        }
    }
`;

export const MatchesEmpty = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 3.2rem;
    text-align: center;
`;
export const MatchesEmptyTitle = styled.h2`
    color: rgba(0, 0, 0, 0.9);
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 1.6rem;
`;
export const MatchesEmptyDescr = styled.h2`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.3rem;
    font-weight: 400;
    margin: 0.8rem 0 1.2rem;
`;
