import styled from 'styled-components';

export const Cxi = styled.div`
    display: block;

    img {
        height: auto;
        max-width: 100%;
        width: 100%;
    }
`;
