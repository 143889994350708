import React from 'react';

import { UserAvatar } from 'core/components/user';
import { RHP } from 'providers';

import { useCXIDashboard } from 'modules/cxi/dashboard/useCXIDashboard';
import * as Styled from './StyledUsersUser';

export const UsersUser = ({ user }) => {
    const { companyId } = useCXIDashboard();
    const url = `/100-10-1/dashboard/${companyId}/users/${user._id}`;
    return (
        <Styled.UsersUser onClick={() => RHP.push(url)}>
            <Styled.Image>
                <UserAvatar user={user} />
            </Styled.Image>
            <Styled.Infos>
                <Styled.Title>{`${user.firstName} ${user.lastName}`}</Styled.Title>
                <Styled.Descr>{user.position}</Styled.Descr>
                <Styled.Email>{user.email}</Styled.Email>
            </Styled.Infos>
        </Styled.UsersUser>
    );
};
