import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { getDateXDaysAgo } from 'utils/date';
import * as Styled from './StyledMatchMentoring';

export const MatchMentoring = ({ match }) => {
    const { t } = useCXI();
    const { mentoring } = match;

    // Check if there is older than 5 days ago
    const daysAgo = getDateXDaysAgo(5, new Date());
    const dateMatch = match.mentoringRequestedDate
        ? new Date(match.mentoringRequestedDate)
        : undefined;

    let ttip = '';
    let icon = '';
    if (mentoring === 'requested' && (!dateMatch || dateMatch < daysAgo)) {
        ttip = 'Este pedido não é mais válido, excedeu 5 dias';
        icon = 'fa-history';
    } else if (mentoring === 'requested') {
        ttip = 'Aguardando retorno da startup...';
        icon = 'fa-stopwatch';
    } else if (mentoring === 'rejected') {
        ttip = t('match.mentoring.rejected');
        icon = 'fa-times-circle';
    } else if (mentoring === 'accepted') {
        ttip = 'Mentoria aceita';
        icon = 'fa-check-circle';
    }

    return (
        <Styled.MatchMentoring className={mentoring}>
            {!icon ? (
                <span className="text">-</span>
            ) : (
                <span className={`icon far ${icon}`} aria-hidden="true" />
            )}
            {!!ttip && <span className="ttip">{ttip}</span>}
        </Styled.MatchMentoring>
    );
};
