import React from 'react';
import { Redirect } from 'react-router';
import * as Styled from './StyledChallengeIndex';

export function ChallengeIndex({ useCXI, t }) {
    const { vars } = useCXI;

    const steps = [
        {
            step: 1,
            href: '/100-10-1/form/1',
            text: t('ChallengeIndex.step1'),
            isBlocked: false,
            isCompleted: false
        },
        {
            step: 2,
            href: '/100-10-1/form/2',
            text: t('ChallengeIndex.step2'),
            isBlocked: false,
            isCompleted: true
        },
        {
            step: 3,
            href: '/100-10-1/form/3',
            text: t('ChallengeIndex.step3'),
            isBlocked: false,
            isCompleted: true
        },
        {
            step: 4,
            href: '/100-10-1/form/4',
            text: t('ChallengeIndex.step4'),
            isBlocked: false,
            isCompleted: true
        },
        {
            step: 5,
            href: '/100-10-1/form/5',
            text: t('ChallengeIndex.step5'),
            isBlocked: false,
            isCompleted: true
        }
    ];

    const currentStep = steps.filter(step => !step.isCompleted);

    return (
        <Styled.ChallengeIndex>
            <Redirect to={currentStep[0].href} />
        </Styled.ChallengeIndex>
    );
}
