import React from 'react';

import { CONFIG } from 'config';
import { CA, CN } from 'contexts';
import { useApi } from 'hooks';
import { queryParam } from 'utils/query';

export function useLogin() {
    const { dispatch } = React.useContext(CA);
    const { notifyState } = React.useContext(CN);
    const api = useApi();

    // Initial values for the state
    const initial = {
        email: queryParam('email') || '',
        baseUrl: `${CONFIG.baseUrl}/login/validate`,
        referralcode: queryParam('referralcode'),
        resetcode: queryParam('resetcode'),
        returnUrl: queryParam('returnUrl'),
        pincode: queryParam('pincode'),
    };

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * HANDLER FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const handleAuth = (auth, user) => {
        const data = { auth, user };
        dispatch({ type: 'AUTHENTICATE', ...data });
        return user;
    };

    const handleLogin = auth => {
        // Build headers with authentication token
        const headers = { headers: { api_key: auth.token } };
        // Get user informations and authenticate the user
        return api.get(`/users/${auth.userid}`, headers).then(user => {
            // Check if notification is avaliable
            const { notificationToken } = notifyState;
            if (!notificationToken) return handleAuth(auth, user);
            // Update the notificationToken on user authentication
            return api
                .put(`/users/${auth.userid}/devices`, { notificationToken }, headers)
                .finally(handleAuth(auth, user));
        });
    };

    const handlePrelogin = res => {
        const data = { prelogin: true, redirect: '' };
        if (!res.password && !res.user) {
            data.redirect = '/register';
        } else if (!res.password) {
            data.redirect = '/login/reset';
        }
        return data;
    };

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function postLogin(payload) {
        return api.post(`/login`, payload).then(res => {
            return handleLogin(res);
        });
    }
    function postNewPassword(payload) {
        return api.post(`/newpassword`, payload).then(res => {
            return handleLogin(res);
        });
    }
    function postPrelogin(payload) {
        return api.post(`/prelogin`, payload).then(res => {
            addVars(payload);
            return handlePrelogin(res);
        });
    }
    function postResetPassword(payload) {
        return api.post(`/resetpassword`, payload).then(res => {
            addVars(payload);
            return res;
        });
    }
    function postValidateCode(payload) {
        return api.post(`/validatecode`, payload).then(res => {
            addVars(payload);
            return res;
        });
    }

    return {
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Custom functions
        postLogin,
        postNewPassword,
        postPrelogin,
        postResetPassword,
        postValidateCode,
    };
}
export default useLogin;
