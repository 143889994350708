import React from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useConversionTable } from 'hooks';
import { UserAvatar } from 'core/components/user/avatar/UserAvatar';

import * as Styled from './StyledInvestment';

export function InvestmentCard(props) {
    const { t } = useTranslation('ranking');
    const conv = useConversionTable(['investmentType']);
    const data = props.data || props.investment || undefined;

    const investor = data?.investor;

    let bold = t('glossary:valueNotDeclared');
    if (data?.value) {
        bold = Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: data.value.currency,
        }).format(data.value.amount);
    }

    let text = '';
    if (data?.type) {
        text += conv.investmentType[data.type];
    }
    if (data?.date) {
        const momentDate = Moment(data.date).utcOffset(data.date);
        const formatDate = momentDate.format('MM/YYYY');
        text += `${text ? ' - ' : ''}${formatDate}`;
    }

    const propsCard = {
        as: data.onClick ? 'button' : undefined,
        onClick: data.onClick,
    };
    const badges = [];
    if (data?.startup && data?.startup?.status) {
        if (data?.startup.status === 'confirmed') {
            badges.push(() => <div className="badge badge-success">{t('startupConfirmed')}</div>);
        }
        // if (data?.startup?.status === 'pending') {
        //     badges.push(() => <div className="badge badge-warning">{t('startupPending')}</div>);
        // }
        if (data?.startup?.status === 'rejected') {
            badges.push(() => <div className="badge badge-danger">{t('startupRejected')}</div>);
        }
    }
    if (data?.investor && data.investor?.status) {
        if (data?.investor.status === 'confirmed') {
            badges.push(() => <div className="badge badge-success">{t('investorConfirmed')}</div>);
        }
        // if (data?.investor?.status === 'pending') {
        //     badges.push(() => <div className="badge badge-warning">{t('investorPending')}</div>);
        // }
        if (data?.investor?.status === 'rejected') {
            badges.push(() => <div className="badge badge-danger">{t('investorRejected')}</div>);
        }
    }
    if (
        data?.audited &&
        data?.status &&
        data?.status === 'confirmed' &&
        data?.displayAudit === true
    ) {
        if (data?.audited === 'confirmed') {
            const cn = 'badge badge-success';
            badges.push(() => <div className={cn}>{t('auditConfirmed')}</div>);
        }
        // if (data?.audited === 'pending') {
        //     const cn = 'badge badge-warning';
        //     badges.push(() => <div className={cn}>{t('auditPending')}</div>);
        // }
        if (data?.audited === 'rejected') {
            const cn = 'badge badge-danger';
            badges.push(() => <div className={cn}>{t('auditRejected')}</div>);
        }
    }
    // else if (data?.status && data?.status === 'confirmed' && !data?.displayAudit) {
    //     if (data?.audited === 'pending') {
    //         const cn = 'badge badge-warning';
    //         badges.push(() => <div className={cn}>{t('auditPending')}</div>);
    //     }
    // }
    return (
        <Styled.InvestmentCard {...propsCard}>
            <UserAvatar user={data.investor.data} />
            <Styled.CardInfos>
                <span className="name">{investor.name}</span>
                <span className="bold">{bold}</span>
                <span className="text">{text}</span>

                {badges.map((Badge, k) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Badge key={`badge-${k}`} />
                ))}
            </Styled.CardInfos>
        </Styled.InvestmentCard>
    );
}
export default InvestmentCard;
