import React from 'react';

// import { useForm } from 'components/form';

import * as Page from 'components/page';

export const BasicEdit = props => {
    // const { vars, putStartupAdvanced, putStartup } = props.useStartupId;
    // const { startup } = vars;

    // console.log('vars', vars.advancedForm);

    // // Handle form submitions
    // const onSubmit = values => {
    //     const startupPayload = {
    // name: values.startupName,
    // nameChanges: values.startupNameObs,
    // startupStatus: values.isStartupActive === 'true' ? 'registered' : 'inactive',
    // location: {
    //     country: values.location.country.id,
    //     state: values.location.state.id,
    //     city: values.location.city.id,
    // },
    // demographics: {
    //     status: {
    //         cnpj: values.cnpj,
    //         noTaxId: values.noCnpj,
    //         founding: new Date(String(values.foundingYear)).getFullYear(),
    //     },
    //     financial: {
    //         invested: values.externalInvestment === 'true' ? 1 : 2,
    //         seekingInvestment: values.seekingInvestment === 'true' ? 1 : 2,
    //         seekingHowMuch: values.seekingHowMuch,
    //     },
    // },
    // scaleup: {
    //     financial: values.financial
    //         .filter(elem => elem.revenues || elem.employees)
    //         .sort((a, b) => a.year - b.year),
    // },
    // language: startup.language,
    // };

    // console.log('values', values);

    // const advancedFormPayload = {
    //     language: startup.language,
    //     isActive: values.isStartupActive,
    //     percentageCorporate: Number(values.corpsIncomePercentage),
    //     wasAcquired: values.wasAcquired,
    //     acquisitions: values.aquisitions,
    //     interests: values.interests,
    //     causes: values.causes,
    //     otherCause: values.otherCause,
    //     howCanWeHelp: values.howCanWeHelp,
    //     howCanYouHelp: values.howCanYouHelp,
    // };

    // putStartup(startupPayload).then(() => {
    //     putStartupAdvanced(advancedFormPayload);
    //     // .then(() => RHP.push('/'));
    // });
    // };

    return (
        <Page.Page name="BasicEdit">
            <p>loading...</p>
        </Page.Page>
    );
};

export default BasicEdit;
