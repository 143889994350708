import React from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { RankingTermForm } from './term-form';
import * as Styled from './styles';

export function RankingTerm(props) {
    const { vars, getTerm, postStartupTerm } = props.useRankingStartup;

    // Initialize a new form handler
    const form = useForm({
        termId: vars.startupTerm.termId || ''
    });

    const { addValues } = form;

    // Term should be loaded to proceed
    React.useEffect(() => {
        // Get the term to be signed
        if (vars.startupTerm.termId && !vars.term._id) {
            getTerm(vars.startupTerm.termId);
            addValues({ termId: vars.startupTerm.termId });
        }
    }, [vars.startupTerm.termId, vars.term._id, getTerm, addValues]);

    // Handle form submition
    const onSubmit = values => {
        const { termId } = values;

        // Do the submition api request
        return postStartupTerm({ termId, signed: true })
            .then(() => RHP.push(vars.redirectTo || '/ranking/latam/startup'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Styled.RankingTerm as={Page.Page} name="RankingTerm" loading={!vars.term._id}>
            <Page.Title>{props.t('RankingTerm.title')}</Page.Title>
            <Page.Descr>{props.t('RankingTerm.descr')}</Page.Descr>
            {vars.term._id && <Page.BoxedHTML html={vars.term.htmlDescription} />}
            {vars.term._id && <RankingTermForm {...propsForm} />}
        </Styled.RankingTerm>
    );
}
