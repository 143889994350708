import styled from 'styled-components';

export const Card = styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
`;

// GRAPHICS CONTAINER
export const GraphicsContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

// LEFT SESSION
export const LeftSession = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    gap: 10px;
    padding: 5px 5px;

    justify-content: space-between;
`;

export const LeftBigContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 100%;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 20%;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
`;

export const RowCard = styled(Card)`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 50%;
    justify-content: center;
    align-items: center;
`;
/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// RIGHT SESSION

export const RightSession = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 30%;
    max-width: 30%;
    height: 100%;
    gap: 5px;
    padding: 5px 5px;
    justify-content: space-between;
    box-sizing: border-box;
`;
export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: 100%;
`;

export const Title = styled(Card)`
    display: flex;
    padding: 10px;
    padding-top: 0;
    align-items: center;
    height: 10vh;
    width: 100%;
    gap: 10px;

    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 4rem;
    }
    h2 {
        font-size: 1.9rem;
        text-transform: uppercase;
        font-weight: bold;
    }
`;

export const ExecutiveTableList = styled.table`
    margin-left: 20px;
    empty-cells: show;

    tr {
        text-align: left;
        color: #0085f2;
        border-bottom: 3px solid #0085f2;
        max-height: 62px;
        height: 61px;
        color: black;

        &:first-child {
            height: 20px;

            th {
                padding-left: 20px;
                color: #0085f2;
                font-weight: normal;
                :nth-child(3) {
                    text-align: right;
                    padding-right: 20px;
                }
            }
        }

        &:last-child {
            border: none;
        }

        td {
            white-space: nowrap;
            overflow: hidden;
            box-sizing: border-box;

            :nth-child(2) {
                text-align: left;
                padding-left: 20px;
                max-width: 16vw;
                white-space: nowrap;
                overflow: hidden;
                font-weight: bold;
                box-sizing: border-box;
            }
            :nth-child(3) {
                text-align: right;
                font-weight: bold;
                padding-right: 40px;
            }
        }
    }
`;

export const BottomTitle = styled(Card)`
    display: flex;
    padding: 10px;
    padding-top: 0;
    align-items: center;
    height: 10vh;
    width: 100%;
    gap: 10px;

    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 3.6rem;
    }
    h2 {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bold;
    }
`;
