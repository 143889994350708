import React from 'react';

import { useNewsApi } from './useNewsApi';

export function useNews() {
    const api = useNewsApi();

    // Initial values for the state
    const initial = { posts: [], postById: {} };

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getPosts(term) {
        return api.get('/posts/?_embed=true&per_page=13').then(posts => {
            addVars({ posts });
            return posts;
        });
    }
    function getPostsById(id) {
        return api.get(`/posts/${id}?_embed=true`).then(post => {
            addVars({ postById: post });
            return post;
        });
    }

    return {
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Custom functions
        getPosts,
        getPostsById,
    };
}
export default useNews;
