import React from 'react';

import { RHP } from 'providers';
import { useStartupCXI } from 'modules/startup/_startupId/cxi/useStartupCXI';
import * as Styled from './StyledMatchConnection';

export const MatchConnectionConfirm = ({ match }) => {
    const { t, vars, putMatchById } = useStartupCXI();
    const { startupId } = vars;

    const handleClick = async () => {
        await putMatchById(match.matchId, { active: true });
        RHP.push(`/startup/${startupId}/100-10-1/messages/${match.matchId}`);
    };

    return (
        <Styled.MatchConnection onClick={handleClick}>
            <span className="icon far fa-fw fa-comments-alt" aria-hidden="true" />
            <span className="text">{t('match.connection.confirm')}</span>
        </Styled.MatchConnection>
    );
};
