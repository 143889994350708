import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Alert from 'components/alert';

export function NextStep(props) {
    const { steps, useRankingInvestor } = props;
    const { application, investments } = useRankingInvestor.vars;
    const { t } = useTranslation('ranking');

    // Create the alert element props
    const alert = {
        // Show texts informations
        title: t('NextStep.title'),
        // Build the button
        buttn: {
            href: '/ranking',
            text: '',
        },
        // Setup color and presentation
        type: 'primary',
    };

    // Get the first false step
    const step = steps.filter(s => {
        return s.isCompleted === false;
    })[0];

    if (step === undefined) {
        if (!application.applied) {
            alert.type = 'success';
            alert.icons = 'far fa-check-circle';
            alert.title = t('NextStep.completeInvestor.title');
            alert.descr = t('NextStep.completeInvestor.descr');
            alert.buttn = [
                {
                    href: '/ranking/latam/investor/step1',
                    type: 'light',
                    text: t('NextStep.completeInvestor.apply'),
                },
                {
                    href: '/ranking/latam/investor/step3',
                    text: t('NextStep.moreInvestments'),
                },
            ];
        } else {
            alert.type = 'success';
            alert.icons = 'far fa-check-circle';
            alert.title = t('NextStep.success.title');
            alert.descr = t('NextStep.success.descr');
            alert.buttn = {
                href: '/ranking/latam/investor/step3',
                text: t('NextStep.moreInvestments'),
            };
        }
    } else {
        alert.buttn = {
            href: step.href,
            text: step.text,
        };
    }

    // Filter only pending investments
    const pending = investments.filter(i => i.investor.status === 'pending');

    // Check for investments
    if (steps[2].isCompleted && pending && pending.length > 0) {
        alert.buttn = {
            href: '/ranking/latam/investor/step3',
            text: steps[2].text,
        };
        alert.title = t('NextStep.pendingInvestments.title');
        alert.descr = t('NextStep.pendingInvestments.descr');
        alert.icons = 'far fa-exclamation-circle';
        alert.type = 'warning';
    }

    return <Alert.Alert {...alert} />;
}
export default NextStep;
