import React from 'react';

import { useForm } from 'components/form';
import * as Page from 'components/page';

import { RHP } from 'providers';
import { InvestmentAddForm } from './InvestmentAddForm';

export function InvestmentAdd(props) {
    const { vars, postInvestment } = props.useStartupId;

    // Initialize a new form handler
    const form = useForm({
        investmentValue: { currency: '', amount: '' },
        otherContact: false,
        rankingIndication: true,
    });

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            date: values.date,
            type: values.investmentType,
            round: values.round,
            value: values.investmentValue,
            investor: {
                name: values.investorName,
                email: values.investorEmail,
            },
        };
        // Add CompanyName to investor
        if (values.companyName) {
            payload.investor = { ...payload.investor, companyName: values.companyName };
        }
        // Remove value if the amount is empty
        if (!values.investmentValue.amount) {
            payload.value = undefined;
        }

        return postInvestment(payload)
            .then(() => RHP.push(`/startup/${vars.startupId}/advanced-edit`))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="InvestmentAdd">
            <Page.Title>{props.t('InvestmentAdd.title')}</Page.Title>

            <InvestmentAddForm {...propsForm} />
        </Page.Page>
    );
}
export default InvestmentAdd;
