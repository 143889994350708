import React from 'react';
import { useEffectOnce } from 'react-use';
import { useApi } from 'hooks';
import { ContextAuth as CA } from 'contexts';
import { StartupRegistrationInvite } from './StartupRegistrationInvite';

export function Startup(props) {
    const { state } = React.useContext(CA);
    const [data, setData] = React.useState([]);
    const api = useApi();

    useEffectOnce(() => {
        const { userid } = state.user;
        if (!data.length || data.length === 0) {
            api.get(`users/${userid}/startups`).then(res => setData(res));
        }
    });
    if (!data || !data.length || data.length === 0) {
        return false;
    }

    return (
        !!data &&
        data.map(startup => {
            if (startup.startupStatus === 'none') {
                return <StartupRegistrationInvite key={startup._id} data={startup} />;
            }
            return null;
        })
    );
}
