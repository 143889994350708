import React from 'react';

import { useTabs } from './useTabs';
import * as Styled from './StyledComponentTabs';

export const TabsTab = ({ children, name }) => {
    const { tab } = useTabs();
    return tab !== name ? null : <Styled.Tab active={tab === name}>{children}</Styled.Tab>;
};

export const Tab = TabsTab;
