import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Page from './index';

export const routes = [
    {
        exact: true,
        path: '/100-10-1/setup/welcome/1',
        component: Page.CXIWelcomeStep1,
    },
    {
        exact: true,
        path: '/100-10-1/setup/welcome/2',
        component: Page.CXIWelcomeStep2,
    },
    {
        exact: true,
        path: '/100-10-1/setup/welcome/3',
        component: Page.CXIWelcomeStep3,
    },
    {
        exact: true,
        path: '/100-10-1/setup/welcome/4',
        component: Page.CXIWelcomeStep4,
    },
    {
        exact: true,
        path: '/100-10-1/setup/welcome/5',
        component: Page.CXIWelcomeStep5,
    },
    {
        exact: true,
        path: '/100-10-1/setup/1',
        component: Page.CXIStep1,
    },
    {
        exact: true,
        path: '/100-10-1/setup/2',
        component: Page.CXIStep2,
    },
    {
        exact: true,
        path: '/100-10-1/setup/3',
        component: Page.CXIStep3,
    },
    {
        exact: true,
        path: '/100-10-1/setup/4',
        component: Page.CXIStep4,
    },
    {
        exact: true,
        path: '/100-10-1/setup/5',
        component: Page.CXIStep5,
    },
    {
        exact: true,
        path: '/100-10-1/setup/6',
        component: Page.CXIStep6,
    },
    {
        exact: true,
        path: '/100-10-1/setup/7',
        component: Page.CXIStep7,
    },
    {
        exact: true,
        path: '/100-10-1/setup/8',
        component: Page.CXIStep8,
    },
    {
        exact: true,
        path: '/100-10-1/setup/9',
        component: Page.CXIStep9,
    },
    {
        exact: true,
        path: '/100-10-1/setup/completed',
        component: Page.CXISetupCompleted,
    },
];

export const CXISetupRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default CXISetupRoutes;
