import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';

import { Matches } from '../components/matches/Matches';
import * as Styled from '../components/StyledNetworkPage';

export const CXINetworksMonitoring = () => {
    const { t, vars } = useCXI();

    const matches = vars.matches.filter(match => match.calculatedStage === 'monitoring');

    return (
        <Styled.NetworkPage>
            <Styled.NetworkPageHead>
                <h2>{t('networks.monitoring.title')}</h2>
                <h3>({matches.length} Startups)</h3>
            </Styled.NetworkPageHead>

            <Styled.NetworkPageBody>
                <Matches matches={matches} t={t} />
            </Styled.NetworkPageBody>
        </Styled.NetworkPage>
    );
};
