import styled from 'styled-components';

export const FilterTag = styled.button.attrs(props => ({
    className: `filter-tag ${props.active ? 'active' : ''}`,
}))`
    align-items: center;
    background: transparent;
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    gap: 0.6rem;
    line-height: 1.2;
    justify-content: center;
    padding: 1rem 1.6rem;
    border: 0.867854px solid #0094f9;
    border-radius: 26.0356px;
    font-weight: 700;
    background-color: white;
    color: #0094f9;

    &.active {
        background: ${({ theme }) => theme.colors.energyBlue};
        color: ${({ theme }) => theme.colors.white};
        justify-content: space-between;
    }
`;
