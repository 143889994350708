import styled from 'styled-components';

export const FormContent = styled.div.attrs({
    className: 'form-content',
})`
    .c-page-descr {
        color: var(--primary);
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
    }
`;

export const Startup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
    margin-top: 4rem;
    align-items: center;
    gap: 15px;

    img {
        object-fit: contain;
        background: #fff no-repeat 50% 50%;
        background-image: url('${props => props.img}');
        background-size: contain;
        background-origin: content-box;
        border: 2px solid #ddd;
        border-radius: 50%;
        height: 14.8rem;
        padding: 0.8rem;
        position: relative;
        width: 14.8rem;
    }

    span {
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
    }
`;

export const Error = styled.p`
    font-size: 2rem;
    color: red;
    font-weight: 700;
    margin-top: 1rem;
    text-align: center;
`;
export const Success = styled.p`
    font-size: 2rem;
    font-weight: 700;
    margin-top: 1rem;
    text-align: center;
`;
