import React from 'react';
import * as Styled from './StyledFormControl';

export const FormControlError = props =>
    // Don't show this element if it is empty
    !props.text ? null : (
        <Styled.FormControlAlert type="danger" id={props.id} htmlFor={props.htmlFor}>
            <span className="text">{props.text}</span>
        </Styled.FormControlAlert>
    );
export default FormControlError;
