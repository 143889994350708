import React from 'react';

import { CG } from 'contexts';
import { LSP } from 'providers';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';

import { Container } from '../container';
import * as Styled from './StyledMentoringSessionForm';

export const MentoringSessionForm = () => {
    const { state: global } = React.useContext(CG);
    const { t, modal, setModal, postMatchMentoringSession } = useCXI();
    const { match } = modal;

    const form = useForm({
        meetingDate: LSP.get(`${match.matchid}_MENTORING_SESSION_FORM_DATE`) ?? '',
        meetingParticipants: LSP.get(`${match.matchid}_MENTORING_SESSION_FORM_PARTICIPANTS`) ?? '',
        contribute: true,
    });

    const fields = {
        meetingDate: {
            error: form.errors.meetingDate,
            name: 'meetingDate',
            type: 'date',
            label: t('modal.MentoringSessionForm.meetingDate'),
            required: true,
            value: form.values.meetingDate,
            onChange: ev => {
                LSP.set(`${match.matchid}_MENTORING_SESSION_FORM_DATE`, ev.target.value);
                return form.handleChange(ev);
            },
        },
        meetingParticipants: {
            error: form.errors.meetingParticipants,
            name: 'meetingParticipants',
            type: 'generic',
            label: t('modal.MentoringSessionForm.meetingParticipants'),
            required: true,
            value: form.values.meetingParticipants,
            onChange: ev => {
                LSP.set(`${match.matchid}_MENTORING_SESSION_FORM_PARTICIPANTS`, ev.target.value);
                return form.handleChange(ev);
            },
        },
        contribute: {
            error: form.errors.contribute,
            label: t('modal.MentoringSessionForm.contribute'),
            name: 'contribute',
            type: 'YesNo',
            value: form.values.contribute,
            onChange: form.handleChange,
            required: true,
        },
    };

    const onSubmit = async ({ contribute, ...values }) => {
        const { language } = global;
        try {
            // Set the evaluation
            const commons = { language, isCxi: true, cxiStage: 3, status: 'held' };
            const EvaluationPayload = { ...commons, ...values };
            const session = await postMatchMentoringSession(match.matchid, EvaluationPayload);

            LSP.del(`${match.matchid}_MENTORING_SESSION_FORM_DATE`);
            LSP.del(`${match.matchid}_MENTORING_SESSION_FORM_PARTICIPANTS`);

            const newModal = contribute === 'false' ? 'MentoringSessionSent' : 'Contribution';
            const newMatch = { ...match, mentoringSessionId: session._id };
            setModal({ match: newMatch, modal: newModal });
        } catch (e) {
            form.setSubmit(false);
        }
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    const isSubmitDisabled =
        form.submit || !form.values.meetingDate || !form.values.meetingParticipants;

    return (
        <Styled.MentoringSessionForm as={Container}>
            <Form.Form onSubmit={evSubmit}>
                <Container.Head image={match.startup.logoUrl}>
                    <span className="text">{t('modal.MentoringSessionForm.title')}</span>
                </Container.Head>

                <Container.Body>
                    <Styled.Descr>
                        <span className="text">{t('modal.MentoringSessionForm.description')}</span>
                    </Styled.Descr>
                    <Form.Field {...fields.meetingDate} />
                    <Form.Field {...fields.meetingParticipants} />
                    <Form.Field {...fields.contribute} />
                </Container.Body>

                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.MentoringSessionForm.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.MentoringSessionForm>
    );
};
