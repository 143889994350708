import React from 'react';

import { useConversionTable } from 'hooks';

import { Area } from '../components/area/Area';
import { useCXIStartup } from '../useCXIStartup';
import { CXIStartupFinancialFinances } from './Finances';

import * as Styled from './StyledCXIStartupFinancial';

export const CXIStartupFinancial = () => {
    const { t, vars } = useCXIStartup();
    const { startup } = vars;

    const conv = useConversionTable(['whoInvested']);

    return (
        <Styled.CXIStartupFinancial>
            {!!startup.demographics?.financial && (
                <Area className="finances" title={t('startup.finances')}>
                    <CXIStartupFinancialFinances />
                </Area>
            )}

            {!!startup.demographics.financial?.whoInvested?.length && (
                <Area className="invested" title={t('startup.invested')}>
                    {startup.demographics.financial.whoInvested.map(item => (
                        <p key={item}>{conv.whoInvested[item]}</p>
                    ))}
                </Area>
            )}
        </Styled.CXIStartupFinancial>
    );
};
