import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIG } from 'config';
import { iframeLink } from 'core/iframe';

import * as Alert from 'components/alert';

import { EventDate } from '../../utils/EventDate';
import * as Styled from './StyledOiweekCall2Action';

export function C2AOpened(props) {
    const { oiweek } = props;
    const { t } = useTranslation('oiweek');

    // Create the alert element props
    const propsAlert = {
        title: t('C2AOpened.title'),
        descr: t('C2AOpened.descr'),
        buttn: {
            href: iframeLink(`${CONFIG.appLinks.events}?autocheckin=${props.event}`),
            text: t('C2AOpened.button')
        },
        type: 'primary'
    };
    if (
        oiweek.user &&
        oiweek.user.eventStatus !== 'registered' &&
        oiweek.user.eventStatus !== 'attended'
    ) {
        propsAlert.buttn.href = '/oiweek/subscribe';
    }

    return (
        <Styled.C2AOpened as={Alert.Alert} {...propsAlert}>
            <EventDate {...props} />
        </Styled.C2AOpened>
    );
}
export default C2AOpened;
