import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';

import { Step1Form } from './Step1Form';

export function Step1(props) {
    const { vars, putUser, t } = props.useCXI;

    const conv = useConversionOptions(['companySize']);

    // Initialize a new form handler
    const form = useForm({
        name: `${vars.fullProfile.firstName} ${vars.fullProfile.lastName}`,
        email: vars.fullProfile.email,
        position: vars.fullProfile.position,
        department: vars.fullProfile.department,
        companyName: vars.fullProfile.company.name,
        phone: vars.fullProfile.phone
    });

    // Handle form submition PUT users/:userid
    const onSubmit = values => {
        // Build the company variable
        const payload = {
            name: values.fullname,
            position: values.position,
            department: values.department,
            phone: values.phone
        };

        // Do the submition api request
        return putUser(payload)
            .then(() => RHP.push('/100-10-1/form/2'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };

    return <Step1Form {...propsForm} />;
}
