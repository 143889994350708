import styled from 'styled-components';

export const StartupStep5 = styled.span.attrs({
    classname: 'startup-step-5',
})`
    #seekingHowMuch-label {
        margin-top: 1.5rem;
    }

    .c-page-links-with-text {
        display: inline;
    }

    .link {
        display: inline;
    }

    .descr2 {
        margin: 2rem 0 0 0;
    }

    .text-and-image {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;
    }

    .ranking-logo {
        width: 80;
        height: 80;
        margin-left: 2rem;
    }
`;

export const OpenAngelsLink = styled.span.attrs({
    classname: 'open-angels-link',
})`
    font-size: 1.6rem;
`;

export const StartupStep5Form = styled.div.attrs({
    classname: 'startup-step5-form',
})`
    h2 {
        margin: 0;
        font-size: 1.6rem;
    }

    h3 {
        margin: 2rem 0 1.5rem 0;
        font-size: 1.6rem;
        color: var(--primary);
    }

    p {
        margin: 0 0 1rem 0;
    }

    .form-control-label {
        margin-bottom: 0;
    }

    #label-empty-label {
        font-weight: bold;
    }

    .component-form-field {
        margin-top: 0;
    }

    .form-row {
        margin-bottom: 1rem;
    }
`;
