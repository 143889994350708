import React, { useState } from 'react';
import { useCXI } from 'modules/cxi/useCXI';
import { UserAvatar } from 'core/components/user';
import { Button } from 'modules/cxi/components/button';
import { Container } from '../container';

import * as Styled from './StyledChallengeConnection';

export const ChallengeConnection = () => {
    const { modal, setModal, putChallengeStartupStatus, postMatchChallenge, vars } = useCXI();
    const { challengeId, startup, filters } = modal;
    const [simClicked, setSimClicked] = useState(false);
    const { matches } = vars;

    const hasMatch = matches.some(m => m?.startup?.startupId === startup?._id);
    const putOrConnect = async e => {
        e.preventDefault();

        const payload = {
            eventStatus: 'inprogress',
        };

        if (hasMatch) {
            return putChallengeStartupStatus(challengeId, startup._id, payload, filters).then(
                res => {
                    setModal(null);
                    setSimClicked(true);
                }
            );
        }

        await postMatchChallenge(startup._id, challengeId);

        putChallengeStartupStatus(challengeId, startup._id, payload, filters).then(res => {
            setModal(null);
            setSimClicked(true);
        });
    };

    return (
        <Styled.ChallengeConnection as={Container.Slim}>
            <Styled.Avatar>
                <UserAvatar user={{ firstName: startup?.name, photoUrl: startup?.logoUrl }} />
            </Styled.Avatar>

            <Container.Body>
                <Styled.Title>Deseja registrar negócio em andamento com essa startup?</Styled.Title>
                <br />
                <Styled.SubTitle>
                    Ao confirmar, você registra que já está com um negócio em andamento com essa
                    startup. Se a startup ainda não estiver em sua rede, ela será adicionada
                    automaticamente.
                </Styled.SubTitle>
            </Container.Body>

            <Container.Feet>
                <Button variant="blue" onClick={e => putOrConnect(e)} disabled={simClicked}>
                    {hasMatch ? 'Sim' : 'Conectar'}
                </Button>

                <Button variant="outline" onClick={() => setModal(null)}>
                    Não
                </Button>
            </Container.Feet>
        </Styled.ChallengeConnection>
    );
};
