import styled from 'styled-components';

export const EvaluateCheck = styled.div`
    text-align: center;

    .m-container-feet {
        flex-direction: column;
        gap: 1.2rem;
    }
`;

export const Descr = styled.div`
    font-size: 1.4rem;
    line-height: 1.4;
    margin-top: 0.8rem;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.2rem;
    font-weight: 700;
`;
