import React from 'react';
import { useEffectOnce } from 'react-use';

import { CONFIG } from 'config';
import { CG } from 'contexts';
import { useApi } from 'hooks';
import { RHP } from 'providers';
import { queryParam } from 'utils/query';
import { RankingStartupContext } from './RankingStartupContext';

const hrefStartup = CONFIG.appLinks.startup;

export function useRankingStartup() {
    const { emitAlert } = React.useContext(CG);
    const { vars, addVars, setVars, t } = React.useContext(RankingStartupContext);
    const api = useApi();

    // Check for queryStrings
    if (
        queryParam('investmentId') &&
        queryParam('investmentResponse') &&
        queryParam('lastUpdated')
    ) {
        vars.validate = {
            _id: queryParam('investmentId'),
            status: queryParam('investmentResponse'),
            lastUpdated: queryParam('lastUpdated')
        };
    }

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const type2Points = React.useMemo(
        () => ({
            none: 0,
            match: 0,
            mentoring: 1,
            connection: 0,
            award: 1,
            coworking: 1,
            voucher: 1,
            outlicensing: 5,
            resources: 5,
            employee: 5,
            channels: 5,
            acceleration: 5,
            'r&d': 10,
            inlicensing: 10,
            pilot: 10,
            supplying: 10,
            accelerationLarge: 5,
            investmentLarge: 0,
            investment: 0,
            acquisition: 0,
            investment200k: 20,
            acquisition200k: 20
        }),
        []
    );

    const calculateContractPoints = React.useCallback(
        contract => {
            const startDate = new Date(contract.startDate);
            let endDate = new Date(contract.endDate);

            if (contract.startDate && !contract.endDate) {
                endDate = new Date(contract.startDate);
                endDate.setFullYear(startDate.getFullYear() + 1);
            }

            // Check the dates
            if (
                endDate >= startDate &&
                endDate >= new Date('2024-07-01') &&
                startDate < new Date('2025-07-01')
            ) {
                if (contract.formalized !== 'true') {
                    addVars({ contractPoints: 0 });
                } else if (
                    contract.valueRange &&
                    Array.isArray(contract.valueRange) &&
                    contract.valueRange[0] >= 200000 &&
                    contract.type !== 'connection'
                ) {
                    addVars({ contractPoints: 20 });
                } else {
                    addVars({ contractPoints: type2Points[contract.type] || 0 });
                }
            } else {
                addVars({ contractPoints: 0 });
            }
        },
        [type2Points]
    );

    function setReturnTo(href) {
        addVars({ redirectTo: href });
    }

    // //////////////////////
    //  RANKING FUNCTIONS  //
    // //////////////////////
    const currentRanking = vars.ranking.id;
    function getRanking(rankingId = currentRanking) {
        return api.get(`/rankings`).then(res => {
            const filtered = res.filter(r => r?.id === rankingId);
            addVars({ ranking: filtered[0] });
            return filtered[0];
        });
    }

    // ///////////////////
    //  TERM FUNCTIONS  //
    // ///////////////////

    const getTerm = React.useCallback(
        termId => {
            return api.get(`/terms/${termId}`).then(res => {
                addVars({ term: res });
                return res;
            });
        },
        [api]
    );

    function getStartupTermByType(type) {
        return api.get(`/startups/${vars.startupId}/terms/${type}`).then(res => {
            addVars({ startupTerm: res });
            return res;
        });
    }

    function postStartupTerm(payload) {
        // payload = { termId, signed: true }
        return api.post(`/startups/${vars.startupId}/terms`, payload).then(res => {
            addVars({ startupTerm: res });
            return res;
        });
    }

    // //////////////////////
    //  STARTUP FUNCTIONS  //
    // //////////////////////

    function getStartupDetails(startupId, addResponseToVars = true) {
        return api.get(`/startups/${startupId}`).then(res => {
            if (res.startupStatus === 'none') {
                const href = `${hrefStartup}/registerstartup/?startupId=${res._id}&lang=pt&back=/home/?startupId=${res._id}`;
                RHP.push(`/iframe/${encodeURIComponent(href)}`);
            }
            if (addResponseToVars) {
                addVars({ startupDetails: res });
            }
            return res;
        });
    }

    function getStartups() {
        return api.get(`/startups`).then(async res => {
            addVars({ startups: res });

            if (!vars.startupId && res.length === 1) {
                const startup = await getStartupDetails(res[0]._id);
                addVars({ startupId: startup._id });
            }
            return res;
        });
    }

    function putStartupDetails(payload) {
        return api.put(`/startups/${vars.startupId}`, payload).then(res => {
            return getStartupDetails(vars.startupId);
        });
    }

    // /////////////////////////////////
    // RANKING APPLICATION FUNCTIONS  //
    // /////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function getStartupRankingApplication() {
        const application = await api.get(
            `/startups/${vars?.startupId}/rankingapplications/${vars?.ranking?.id}`
        );

        if (Object.keys(application).length) {
            addVars({ application });
            return application;
        }

        return null;
    }
    function putStartupRankingApplication(payload, updateVars = true) {
        return api
            .put(`/startups/${vars.startupId}/rankingapplications/${vars.ranking?.id}`, payload)
            .then(res => {
                getStartupDetails(vars.startupId);
                if (updateVars) addVars({ application: res });
                return res;
            });
    }

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const updateSteps = update => {
        // Get the current application variable
        const { application } = vars;
        application.steps = { ...application.steps, ...update };
        addVars({ application });
        return putStartupRankingApplication({ ...application }, false);
    };

    // ///////////////////////
    // ECOSYSTEM FUNCTIONS  //
    // ///////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getStartupRelationships() {
        return api.get(`/startups/${vars.startupId}/ecosystemrelationships`).then(res => {
            addVars({ relationships: res });
            return res;
        });
    }

    function postStartupRelationship(payload) {
        return api.post(`/startups/${vars.startupId}/ecosystemrelationships`, payload).then(res => {
            return getStartupRelationships();
        });
    }

    function postStartupRelationshipValidation(payload) {
        // {
        //     "status": "confirmed",
        //     "lastUpdated": "2020-03-09T20:32:44.701Z"
        // }
        const { _id } = payload;
        return api
            .post(`/startups/${vars.startupId}/ecosystemrelationships/${_id}/validation`, payload)
            .then(res => {
                return getStartupRelationships();
            });
    }

    function postStartupRelationshipValidationEmail(payload) {
        const { _id } = payload;
        return api.post(
            `/startups/${vars.startupId}/ecosystemrelationships/${_id}/sendvalidationemail`
        );
    }

    function putStartupRelationship(payload) {
        // {
        //     "date": "2019-07-01",
        //     "type": "Angel",
        //     "round": "Seed 2",
        //     "vaue": {"currency": "USD", "amount": 20000 },
        //     "linkUrl": "https://linkao.com.br",
        //     "rankings": { "2020": false }
        // }
        const { _id } = payload;
        return api
            .put(`/startups/${vars.startupId}/ecosystemrelationships/${_id}`, payload)
            .then(res => {
                return getStartupRelationships();
            });
    }

    function deleteStartupRelationship(payload) {
        const { _id } = payload;
        return api.delete(`/startups/${vars.startupId}/ecosystemrelationships/${_id}`).then(res => {
            return getStartupRelationships();
        });
    }

    // ///////////////////////////////////
    // INVESTMENT VALIDATION FUNCTIONS  //
    // ///////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getStartupInvestments() {
        return api
            .get(`/startups/${vars?.startupId}/investments?ranking=${vars?.ranking?.id}`)
            .then(res => {
                addVars({ investments: res });
                return res;
            });
    }

    function getStartupInvestmentById(investmentId) {
        return api.get(`/startups/${vars.startupId}/investments/${investmentId}`).then(res => {
            return res;
        });
    }

    function postStartupInvestment(payload) {
        return api.post(`/startups/${vars.startupId}/investments`, payload).then(res => {
            return getStartupInvestments();
        });
    }

    function putStartupInvestment(payload) {
        const { _id } = payload;
        return api.put(`/startups/${vars.startupId}/investments/${_id}`, payload).then(res => {
            return getStartupInvestments();
        });
    }

    function deleteStartupInvestment(payload) {
        const { _id } = payload;
        return api.delete(`/startups/${vars.startupId}/investments/${_id}`).then(res => {
            return getStartupInvestments();
        });
    }

    function postStartupInvestmentValidation(payload) {
        // {
        //     "status": "confirmed",
        //     "lastUpdated": "2020-03-09T20:32:44.701Z"
        // }
        const { _id } = payload;
        return api
            .post(`/startups/${vars.startupId}/investments/${_id}/validation`, payload)
            .then(res => {
                return getStartupInvestments();
            });
    }

    function postStartupInvestmentValidationEmail(payload) {
        const { _id } = payload;
        return api
            .post(`/startups/${vars.startupId}/investments/${_id}/sendvalidationemail`)
            .then(res => getStartupInvestments());
    }

    // ///////////////////////
    // CONTRACT FUNCTIONS   //
    // ///////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function getCompanyByEmail(companyEmail) {
        const response = await api.get(`/companyfromemail/${companyEmail}`);
        addVars({ searchedCompany: response });
        return response;
    }

    function getCompanyDetails(companyId) {
        return api.get(`/companies/${companyId}`).then(res => {
            return res;
        });
    }

    async function getStartupContracts() {
        const contracts = await api.get(`/startups/${vars.startupId}/contracts`);

        addVars({ contracts });

        return contracts;
    }

    function postStartupContract(payload) {
        return api.post(`/startups/${vars.startupId}/contracts`, payload).then(res => {
            return getStartupContracts();
        });
    }

    function deleteStartupContract(payload) {
        const { _id } = payload;
        return api.delete(`/startups/${vars.startupId}/contracts/${_id}`).then(res => {
            return getStartupContracts();
        });
    }

    function postStartupContractValidation(payload) {
        // {
        //     "status": "confirmed",
        //     "lastUpdated": "2020-03-09T20:32:44.701Z"
        // }
        const { _id } = payload;
        return api
            .post(`/startups/${vars.startupId}/contracts/${_id}/validation`, payload)
            .then(res => {
                return getStartupContracts();
            });
    }

    function postStartupContractValidationEmail(payload) {
        const { _id } = payload;
        return api.post(`/startups/${vars.startupId}/contracts/${_id}/sendemail`).then(res => {
            return getStartupContracts();
        });
    }

    function postStartupContractReconsideration(payload) {
        const { _id } = payload;
        return api
            .post(`/startups/${vars.startupId}/contracts/${_id}/reconsideration`, payload)
            .then(() => {
                return getStartupContracts();
            });
    }

    function getStartupRankingPosition() {
        return api
            .get(`/startups/${vars.startupId}/rankingapplications/${vars.ranking.year}/estimation`)
            .then(res => {
                addVars({ startupEstimatedPosition: res });
            });
    }

    // async function getProgramsCompany() {
    //     console.log(vars);
    //     const { companyId } = vars;

    //     const response = await api.get(`/companies/${companyId}/programs`);
    //     if (response) {
    //         addVars({ companyPrograms: response });
    //     }
    // }

    async function getProgramsByCompanyId(companyId) {
        if (!companyId) {
            return null;
        }

        const response = await api.get(`/companies/${companyId}/programs`);
        return response;
    }

    async function postProgramByCompanyId(programName, companyId) {
        const payload = { name: programName };

        const response = await api.post(`/companies/${companyId}/programs`, payload);

        return response;
    }

    React.useEffect(() => {
        if (vars.startupId) {
            Promise.all([
                getStartupDetails(vars.startupId, false),
                getStartupRankingApplication(),
                getStartupInvestments(),
                getStartupContracts(),
                getStartupRelationships(),
                getStartupTermByType('ranking2025'),
                getStartupRankingPosition()
            ]).then(res => addVars({ startupDetails: res[0] }));
        }
        // eslint-disable-next-line
    }, [vars.startupId]);

    useEffectOnce(() => {
        // Validate investiment if avaliable
        if (vars.validate) {
            emitAlert({
                header: t('investmentValidated.title', { context: vars.validate.status }),
                message: t('investmentValidated.message', { context: vars.validate.status })
            });
        }

        // Initialize the module's service
        getRanking().finally(() => {
            Promise.all([getStartups()]).then(res => {
                setReady(true);
            });
        });
    }, []);

    return {
        t,
        // Ready to load?
        ready,
        // Variables handlers
        vars,

        addVars,
        calculateContractPoints,
        deleteStartupContract,
        deleteStartupInvestment,
        deleteStartupRelationship,
        getCompanyByEmail,
        getCompanyDetails,
        getProgramsByCompanyId,
        // getProgramsCompany,
        getRanking,
        getStartupContracts,
        getStartupDetails,
        getStartupInvestmentById,
        getStartupInvestments,
        getStartupRankingApplication,
        getStartupRelationships,
        getStartupTermByType,
        getStartups,
        getTerm,
        postProgramByCompanyId,
        postStartupContract,
        postStartupContractReconsideration,
        postStartupContractValidation,
        postStartupContractValidationEmail,
        postStartupInvestment,
        postStartupInvestmentValidation,
        postStartupInvestmentValidationEmail,
        postStartupRelationship,
        postStartupRelationshipValidation,
        postStartupRelationshipValidationEmail,
        postStartupTerm,
        putStartupDetails,
        putStartupInvestment,
        putStartupRankingApplication,
        putStartupRelationship,
        setReturnTo,
        setVars,
        updateSteps
    };
}
export default useRankingStartup;
