import React, { useContext, useEffect, useState } from 'react';

import * as Alert from 'components/alert';
import * as Form from 'components/form';
import * as Page from 'components/page';
import { useApiLocation } from 'hooks';

import CreatableSelect from 'react-select/creatable';

import { CA } from 'contexts';
import * as Styled from './styledEcosystemStep3';

import { StartupCard } from '../components';

export function EcosystemStep3AddForm({
    form,
    onChange,
    onSubmit,
    setSkip,
    skip,
    t,
    useRankingEcosystem
}) {
    const {
        state: { user }
    } = useContext(CA);

    const { getProgramsByCompanyId } = useRankingEcosystem;
    const apiLocation = useApiLocation();

    const [countries, setCountries] = useState([]);
    const [requiredCountryWarning, setRequiredCountryWarning] = useState();
    const [renderRequiredCountryWarning, setRenderRequiredCountryWarning] = useState(false);
    const [companyPrograms, setCompanyPrograms] = useState(null);

    useEffect(() => {
        if (user?.company.id) {
            const getProgramsOptions = async () => {
                const allCompanyPrograms = await getProgramsByCompanyId(user?.company.id);

                setCompanyPrograms(allCompanyPrograms);
            };

            getProgramsOptions();
        }
    }, [form.values.startup]);

    useEffect(() => {
        apiLocation.geo('').then(res => {
            const names = res.map(country => {
                return { name: country.name, value: country.id };
            });
            setCountries(names);
        });
    }, []);

    // Create form fields object
    const fields = {
        startup: {
            // disabled: skip,
            error: form.errors.startup,
            label: t('glossary:startup'),
            name: 'startup',
            type: 'Startup',
            value: form.values.startup,
            onChange: ev => form.handleChange(ev, onChange.startup),
            required: !form.values.startupName
        },
        otherContact: {
            error: form.errors.otherContact,
            label: t('glossary:otherContact'),
            name: 'otherContact',
            type: 'YesNo',
            value: form.values.otherContact,
            onChange: form.handleChange
        },
        startupName: {
            error: form.errors.startupName,
            label: t('glossary:startupName'),
            name: 'startupName',
            type: 'text',
            value: form.values.startupName,
            onChange: form.handleChange
        },
        contactName: {
            error: form.errors.contactName,
            label: t('glossary:contactName'),
            name: 'contactName',
            type: 'text',
            value: form.values.contactName,
            onChange: form.handleChange
        },
        contactEmail: {
            error: form.errors.contactEmail,
            label: t('glossary:contactEmail'),
            name: 'contactEmail',
            type: 'email',
            value: form.values.contactEmail,
            onChange: form.handleChange
        },
        startupCountry: {
            error: form.errors.startupCountry,
            label: t('glossary:startupCountry'),
            name: 'startupCountry',
            type: 'select',
            value: form.values.startupCountry,
            onChange: form.handleChange,
            options: [{ name: t('glossary:select'), value: countries.id }, ...countries]
        },
        companyName: {
            error: form.errors.companyName,
            label: t('ecossystemCompanyName'),
            name: 'companyName',
            type: 'text',
            value: form.values.companyName,
            onChange: form.handleChange
        },
        programName: {
            required: true,
            error: form.errors.programName,
            label: t('glossary:programName'),
            name: 'programName',
            type: 'generic',
            options: companyPrograms?.map(item => {
                return { label: item.name, value: item._id };
            }),
            value: form.values.programName,
            onChange: ev => form.setValues(prev => ({ ...prev, programName: ev }))
        },
        description: {
            error: form.errors.description,
            label: t('glossary:description'),
            name: 'description',
            required: false,
            type: 'textarea',
            value: form.values.description,
            onChange: form.handleChange
        },
        year: {
            displayFormat: 'YYYY',
            error: form.errors.year,
            label: t('glossary:year'),
            maxLength: 4,
            name: 'year',
            type: 'text',
            value: form.values.year,
            onChange: form.handleChange
        },
        linkUrl: {
            error: form.errors.linkUrl,
            label: t('glossary:linkUrl'),
            name: 'linkUrl',
            type: 'text',
            required: false,
            value: form.values.linkUrl,
            onChange: form.handleChange
        }
    };

    const onClick = (ev, currValue) => {
        setSkip(true);
        form.addValues({ startup: {}, startupName: currValue });
        return true;
    };

    useEffect(() => {
        if (
            skip &&
            (fields.startupCountry.value === undefined ||
                fields.startupCountry.value === 'Selecionar')
        ) {
            setRequiredCountryWarning(true);
        } else {
            setRequiredCountryWarning(false);
            setRenderRequiredCountryWarning(false);
        }
    }, [fields.startupCountry.value, skip]);

    const propsRenderRequiredCountryWarningError = {
        descr: t('ranking:requiredCountryError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };

    // Handle form submition
    const evSubmit = ev => {
        if (skip && requiredCountryWarning) {
            ev.persist();
            ev.preventDefault();
            setRenderRequiredCountryWarning(true);
            return false;
        }
        setRenderRequiredCountryWarning(false);

        if (!skip && !form.values.startup) {
            ev.persist();
            ev.preventDefault();
            form.emitError({ name: 'startup', error: t('glossary:startupSelect') });
            return false;
        }
        return form.handleSubmit(ev, onSubmit);
    };

    return (
        <Form.Form name="EcosystemStep3Add" onSubmit={evSubmit}>
            <Form.Field {...fields.startup}>
                <Page.LWTXT onClick={onClick}>
                    <span className="link">{t('EcosystemStep2Add.skipStartup')}</span>
                </Page.LWTXT>
            </Form.Field>

            {(skip || form.values.startup) && (
                <Page.Boxed style={{ marginTop: '0' }}>
                    {skip && (
                        <>
                            <Page.Texts style={{ marginTop: '0' }}>
                                {t('EcosystemStep2Add.skipStartupText')}
                            </Page.Texts>
                            <Form.Field {...fields.startupName} />
                            <Form.Field {...fields.startupCountry} />
                        </>
                    )}
                    {!!Object.keys(form.values.startup).length && (
                        <>
                            <StartupCard {...form.values} />
                            {/* <Form.Field {...fields.otherContact} /> */}
                        </>
                    )}
                    {(skip || form.values.otherContact === 'true') && (
                        <>
                            <Form.Field {...fields.contactName} />
                            <Form.Field {...fields.contactEmail} />
                        </>
                    )}
                </Page.Boxed>
            )}

            <Page.Ruler />

            <Styled.ProgramLabel>Nome do programa</Styled.ProgramLabel>
            <CreatableSelect
                placeholder="Selecione ou digite um nome para criar"
                options={fields.programName.options}
                styles={{
                    control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: '#ddd'
                    }),
                    menu: baseStyles => ({
                        ...baseStyles,
                        zIndex: 91
                    })
                }}
                onChange={fields.programName.onChange}
                value={fields.programName.value}
            />

            <Form.Row>
                <Form.Field size="4" {...fields.year} />
                <Form.Field size="8" {...fields.linkUrl} />
            </Form.Row>

            <Form.Field {...fields.description} />
            {renderRequiredCountryWarning && (
                <Alert.AlertRow {...propsRenderRequiredCountryWarningError} />
            )}
            <Form.Submt {...form} text={t('glossary:continue')} />
        </Form.Form>
    );
}
export default EcosystemStep3AddForm;
