import React from 'react';

import { CompanyCard } from 'core/components/company';

import * as Form from 'components/form';
import * as Page from 'components/page';

export function RegisterProfileForm(props) {
    const { conv, form, onSubmit } = props;

    // Create a variable to decide what to show
    let editCompany = true;
    if (form.values.company && form.values.company.id) {
        editCompany = false;
    }

    // Create form fields object
    const fields = {
        companyName: {
            error: form.errors.companyName,
            label: props.t('glossary:companyName'),
            name: 'companyName',
            onlyIf: editCompany,
            type: 'text',
            value: form.values.companyName,
            onChange: form.handleChange,
        },
        companySize: {
            error: form.errors.companySize,
            label: props.t('glossary:companySize'),
            name: 'companySize',
            onlyIf: editCompany,
            options: [
                { name: props.t('glossary:companySizeSelect'), value: '' },
                ...conv.companySize,
            ],
            type: 'select',
            value: form.values.companySize,
            onChange: form.handleChange,
        },

        department: {
            error: form.errors.department,
            label: props.t('glossary:department'),
            name: 'department',
            type: 'text',
            value: form.values.department,
            onChange: form.handleChange,
        },
        position: {
            error: form.errors.position,
            label: props.t('glossary:position'),
            name: 'position',
            type: 'text',
            value: form.values.position,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="RegisterProfileForm" onSubmit={evSubmit}>
            {!editCompany && <CompanyCard />}

            <Form.Field {...fields.companyName} />
            <Form.Field {...fields.companySize} />

            <Page.Ruler />

            <Form.Field {...fields.position} />
            <Form.Field {...fields.department} />
            <Form.Submt {...form} text={props.t('glossary:continue')} />
        </Form.Form>
    );
}
export default RegisterProfileForm;
