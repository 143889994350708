import React from 'react';

import * as Styled from './StyledComponentTabs';

export function ComponentTabs(props) {
    const [current, setCurrent] = React.useState(0);

    function handleTabs(t, indx) {
        const tab = React.cloneElement(t, {
            active: indx === current,
            onClick: () => setCurrent(indx),
        });
        return tab;
    }

    const childrens = React.Children.map(props.children, (child, k) => {
        // Add active to the current tab
        if (k === 0) {
            const children = React.Children.map(child.props.children, handleTabs);
            return React.cloneElement(child, { children });
        }
        if (current !== k - 1) {
            return null;
        }
        return child;
    });
    return <Styled.ComponentTabs {...props}>{childrens}</Styled.ComponentTabs>;
}
export const TabsContainer = ComponentTabs;
export default ComponentTabs;
