import React from 'react';

import { ContextAuth as CA } from 'contexts';
import { ASCIIToDecimal } from 'utils/conversion';

import useRankingCorporate from 'modules/ranking/ranking-latam/_corporate/useRankingCorporate';
import * as Styled from './StyledCompanyLogo';

const CompanyLogoLetters = ({ fullName, initials }) => {
    return (
        <Styled.Letters title={fullName}>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <foreignObject width="100%" height="100%">
                    <span className="text">{initials}</span>
                </foreignObject>
            </svg>
        </Styled.Letters>
    );
};

const CompanyLogoHolder = props => (
    <Styled.CompanyLogo {...props}>
        <Styled.Holder {...props}>{props.children}</Styled.Holder>
    </Styled.CompanyLogo>
);

export function UserCompanyLogo({ photoUrl }) {
    const { state } = React.useContext(CA);
    const { user } = state;
    const { profile } = user;
    const { vars } = useRankingCorporate();

    const logoUrl = photoUrl || vars.companyDetails.logourl;
    const data = profile.company;

    let initials = '';

    if (data.name) {
        data.firstName = data.name
            .split(' ')
            .slice(0, -1)
            .join(' ');
        data.lastName = data.name
            .split(' ')
            .slice(-1)
            .join(' ');
    }
    if (data.firstName) initials += data.firstName.substring(0, 1).toUpperCase();
    if (data.lastName) initials += data.lastName.substring(0, 1).toUpperCase();
    if (!data.lastName && data.firstName) initials += data.firstName.substring(1, 2).toUpperCase();

    const numbersHUE = ASCIIToDecimal(initials) % 360;
    data.initials = initials;

    // Add some information to the user component
    data.fullName = '';
    if (data.firstName) data.fullName += data.firstName;
    if (data.fullName && data.lastName) data.fullName += ' ';
    if (data.lastName) data.fullName += data.lastName;

    return (
        <CompanyLogoHolder numbersHUE={numbersHUE} user={data}>
            {!logoUrl && <CompanyLogoLetters {...data} />}
            {logoUrl && <Styled.Image src={logoUrl} alt={data.fullName} />}
        </CompanyLogoHolder>
    );
}
export default UserCompanyLogo;
