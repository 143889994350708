import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Guide = styled.div`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    max-width: 56rem;
    min-width: calc(32rem - 6.4rem);
    padding: 0 !important;
    position: relative;
    height: auto;

    .m-container-body {
        background: #000;
        padding: 56% 0 0 0 !important;
        position: relative;

        iframe {
            border: 0;
            height: 100%;
            position: absolute;
            ${positions(0, 0, 0, 0)}
            width: 100%;
        }
    }

    .m-container-feet {
        align-items: stretch;
        flex-direction: column;
        gap: 1rem;
        padding: 0.6rem !important;

        @media (min-width: 768px) {
            padding: 2.4rem !important;
        }

        p {
            margin: 0;
            font-weight: 400;
        }
        span {
            padding-top: 2rem;
            margin: 0 5px;
            font-weight: 800;
        }
        hr {
            height: 1px;
            color: 0094f9;
            width: 100%;
        }
    }
`;

export const Close = styled.button`
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.black};
    font-size: 2.4rem;
    line-height: 1;
    margin: 1.2rem 1.6rem 0 0;
    padding: 0;
    position: absolute;
    ${positions(0, 0, 'auto', 'auto')}
    z-index: 10;
`;
