import React, { useContext } from 'react';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CONFIG } from 'config';

import { iframeLink } from 'core/iframe';
import { ContextGlobal as CG } from 'contexts';
import { useConversionTable } from 'hooks';
import * as Styled from './StyledEventCard';

function EventCardDate(props) {
    const conv = useConversionTable(['months']);
    const { endDate, startDate } = props.data;

    // Don't show the date if no startDate is set
    if (!startDate) return null;

    // Create the initial and final date
    const ini = Moment(startDate).utc();
    const end = Moment(endDate).utc();

    // Build the date parameters
    const buildDate = date => ({
        dd: String(date.get('date')).padStart(2, '0'),
        mm: String(conv.months[date.get('month') + 1]).substring(0, 3)
    });

    const Dated = date => (
        <span className="dated">
            <span className="dd">{date.dd}</span>
            <span className="mm">{date.mm}</span>
        </span>
    );
    const Split = () => <span className="split"> - </span>;

    return (
        <Styled.CardDated>
            {ini && <Dated {...buildDate(ini)} />}
            {end && <Split />}
            {end && <Dated {...buildDate(end)} />}
        </Styled.CardDated>
    );
}

export function EventCard(props) {
    const { t } = useTranslation('events');
    const data = props.data || props.item || props.event;

    const { state } = useContext(CG);
    const { oiweek } = state;

    // Build a href to the iframe
    let href = iframeLink(`${CONFIG.appLinks.events}?autocheckin=${data._id}`);
    if (
        oiweek &&
        data._id === oiweek &&
        data.user.eventStatus !== 'registered' &&
        data.user.eventStatus !== 'attended'
    ) {
        href = '/oiweek/subscribe';
    }

    // TODO: Gambiarra para não mostrar cidade da oiweek digital
    if (data._id === 761) data.locationString = 'Digital';

    return !data ? null : (
        <Styled.EventCard as={Link} to={href}>
            <Styled.CardImage img={data.logoUrl} />
            <Styled.CardInfos>
                <EventCardDate data={data} />
                <div className="bold">{data.locationString}</div>
                <div className="name">{data.name}</div>
                <div className="bttn btn btn-linkd">
                    <span className="text">{t('event.checkin')}</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </div>
            </Styled.CardInfos>
        </Styled.EventCard>
    );
}
export default EventCard;
