import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Guide = styled.div`
    .m-container-body {
        background: #000;
        padding: 56% 0 0 0 !important;
        position: relative;

        iframe {
            border: 0;
            height: 100%;
            position: absolute;
            ${positions(0, 0, 0, 0)}
            width: 100%;
        }
    }

    .m-container-feet {
        align-items: stretch;
        flex-direction: column;
        gap: 1rem;
        padding: 1.6rem !important;

        @media (min-width: 768px) {
            padding: 2.4rem !important;
        }

        p {
            margin: 0;
        }
    }
`;
