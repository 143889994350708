import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const ContactCard = styled.div.attrs({
    className: 'contact-card',
})`
    align-items: center;
    background: #fff;
    border: 0;
    color: inherit;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
    -webkit-appearance: none;
    z-index: 10;
    position: relative;
`;

export const CardInfos = styled.div.attrs({
    className: 'founder-card-infos',
})`
    flex: 1 1 calc(100% - 6.5rem);
    font-size: 1.4rem;
    margin-left: 1.5rem;
    text-align: left;

    .card-header {
        padding: 0;
        border-bottom: 0;
        background-color: white;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dedede;
        .icon {
            font-size: 1.6rem;
            color: var(--primary);
        }
    }

    .name {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.4;
        /* ${truncate(1)} */
    }

    .bold {
        display: block;
        font-weight: 600;
        margin-top: 0;
        ${truncate(1)}
    }

    .text {
        display: block;
        margin-top: 0.1rem;
        ${truncate(1)}
    }
`;
