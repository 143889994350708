import React from 'react';
// import axios from 'axios';
import { useEffectOnce } from 'react-use';

import { CA, CG } from 'contexts';
import { useApi } from 'hooks';
// import { RHP } from 'providers';
import { queryParam } from 'utils/query';

export function useOiweek(skipRelated) {
    const api = useApi();
    const { state } = React.useContext(CA);
    const { dispatch } = React.useContext(CG);

    const forceOiweek = queryParam('forceOiweek');

    // Initial values for the state
    const initial = {
        oiweek: {},
        oiweekEvents: [],
        oiweekPayPerUse: {},
        oiweekPayPerUseTerm: {},
        oiweekProducts: [],
        oiweekProfile: {},
        startups: [],
    };

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    /**
     * HANDLER FUNCTIONS
     * ------------------------------------------------------------------------
     */

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getOiweekEvent() {
        const { oiweek } = vars;
        return api.get(`/events/${oiweek._id}`).then(res => {
            addVars({ oiweek: res });
            return res;
        });
    }

    function getOiweekEvents(oiweek) {
        return api.get(`/events/${oiweek._id}/events`).then(res => {
            addVars({ oiweekEvents: res });
            return res;
        });
    }

    function getOiweekPayPerUse(oiweek) {
        return api.get(`/events/${oiweek._id}/payperuse`).then(res => {
            addVars({ oiweekPayPerUse: res });
            if (!res || (res && !res.termId)) {
                return Promise.reject();
            }
            return api.get(`/terms/${res.termId}`).then(term => {
                addVars({ oiweekPayPerUseTerm: term });
                return { ...res, ...term };
            });
        });
    }

    function getOiweekProducts(oiweek) {
        // const extUrl = 'https://store.openstartups.net/wp-json/wp/v2';
        // return axios
        //     .get(`${extUrl}/product?filter[product_tag]=oiweek-07-2020`)
        //     .then(res => res.data)
        //     .then(res => {
        //         const filter = res.filter(r => r.acf && r.acf.product_app);
        //         addVars({ oiweekProducts: filter });
        //         return res;
        //     });
        return api.get(`/events/${oiweek._id}/storeproducts`).then(res => {
            const filter = res.filter(r => r.acf && r.acf.product_app);
            addVars({ oiweekProducts: filter });
            return res;
        });
    }

    function getOiweekProfile(oiweek) {
        return api.get(`/events/${oiweek._id}/profile`).then(res => {
            addVars({ oiweekProfile: res });
            return res;
        });
    }

    async function getOiweek() {
        const params = `isOiweek=true&registration=true&hiddenUser=false`;
        return api.get(`/events/?${params}`).then(res => {
            let filtered = res.filter(r => {
                if (!r.endDate) return true;
                const nowDate = new Date();
                const endDate = new Date(r.endDate);
                nowDate.setUTCHours(0, 0, 0, 0);
                endDate.setUTCHours(0, 0, 0, 0);
                return nowDate <= endDate;
            });

            if (forceOiweek) {
                const forced = filtered.filter(r => Number(forceOiweek) === r._id);
                filtered = Array.isArray(forced) && forced.length > 0 ? forced : filtered;
            }

            if (filtered.length > 0 && filtered[0]) {
                if (skipRelated !== true) {
                    getOiweekEvents(filtered[0]);
                    getOiweekProducts(filtered[0]);
                    getOiweekProfile(filtered[0]);
                    if (
                        filtered[0].user.eventStatus === 'registered' ||
                        filtered[0].user.eventStatus === 'attended'
                    ) {
                        getOiweekPayPerUse(filtered[0]);
                    }
                }
                dispatch({ type: 'OIWEEK', oiweek: filtered[0]._id });
                addVars({ oiweek: filtered[0] });
                return filtered[0];
            }
            return Promise.reject('Oiweek not found!');
        });
    }

    async function getStartups() {
        const { userid } = state.user;
        return api
            .get(`users/${userid}/startups`)
            .then(res => {
                addVars({ startups: res });
            })
            .catch(err => Promise.resolve('Error getting startups'));
    }

    function postOiweekPayPerUse(payload, eventId) {
        const { oiweek, selectedEvents } = vars;
        const oiweekId = eventId || oiweek._id;
        return api.post(`/events/${oiweekId}/payperuse`, payload).then(res => {
            // Atualizar o payperuse nos eventos filhos
            if (Array.isArray(selectedEvents)) {
                selectedEvents.map(e => {
                    return api.post(`/events/${e}/payperuse`, payload);
                });
            }
            return res;
        });
    }

    function putOiweekProfile(payload, eventId) {
        const { oiweek } = vars;
        const oiweekId = eventId || oiweek._id;
        return api.put(`/events/${oiweekId}/profile`, payload).then(res => {
            getOiweekPayPerUse(oiweek);
            // Fazer inscrição nos eventos filhos com o mesmo endpoint\
            if (Array.isArray(payload.events)) {
                payload.events.map(e => {
                    const childPayload = { ...payload };
                    delete childPayload.events;
                    return api.put(`/events/${e}/profile`, childPayload);
                });
                addVars({ selectedEvents: payload.events });
            }
            // Reload oiweekEvent to update user variables
            return getOiweekEvent();
        });
    }

    useEffectOnce(() => {
        // Initialize the module's service
        Promise.all([getOiweek(), getStartups()])
            .then(res => setReady(true))
            .catch(err => {
                console.error('useOiweek', err);
                // RHP.replace('/home')
            });
    });

    return {
        // Ready to load?
        ready,
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Include vars functions
        // Custom functions
        getOiweekEvent,
        getOiweekEvents,
        getOiweekPayPerUse,
        getOiweekProducts,
        getOiweekProfile,
        getOiweek,
        getStartups,
        postOiweekPayPerUse,
        putOiweekProfile,
    };
}
export default useOiweek;
