import React from 'react';

import * as Styled from './StyledStartupCard';

export function StartupCard(props) {
    const data = props.data || props.startup || undefined;

    const info = {};
    if (data.data) {
        info.image = { img: encodeURI(data.data.logoUrl), alt: data.name };
        info.title = data.data.name;
        if (data.contact) {
            info.name = data.contact.name;
            info.mail = data.contact.email;
        }
    }
    if (data.startupStatus) {
        info.image = { img: encodeURI(data.logoUrl), alt: data.name };
        info.title = data.name;
        info.name = data.entrepreneur.firstName;
        info.mail = data.entrepreneur.email;
    }

    return !data ? null : (
        <Styled.StartupCard>
            <Styled.CardImage {...info.image} />
            <Styled.CardInfos>
                <span className="name">{info.title}</span>
                <span className="text">{info.name}</span>
                <span className="text">{info.mail}</span>
            </Styled.CardInfos>
        </Styled.StartupCard>
    );
}
export default StartupCard;
