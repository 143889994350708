import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { MetricItem } from './MetricItem';
import { MetricStage3Startup } from './MetricStage3Startup';

import * as Styled from './StyledMetrics';

export const MetricsStage3 = () => {
    const { t, vars } = useCXI();
    const { stage3 } = vars.cxi.metrics;

    const metric1 = Number(stage3.mentoringSessions || 0);
    const metric2 = Number(stage3.feedbacksPositive || 0);

    return (
        <Styled.MetricsItems className="stage-3">
            <MetricStage3Startup
                icon="fa-history"
                numb={t('home.Metrics.mentoringStartup.number')}
                text={t('home.Metrics.mentoringStartup.text')}
                tooltip={t('home.Metrics.mentoringStartup.tooltip')}
            />
            <MetricItem
                icon="fa-users-class"
                numb={t('home.Metrics.mentoringSessions.number', { number: metric1 })}
                text={t('home.Metrics.mentoringSessions.text')}
                tooltip={t('home.Metrics.mentoringSessions.tooltip')}
            />
            <MetricItem
                icon="fa-user-chart"
                numb={t('home.Metrics.mentoringFeedbacks.number', { number: metric2 })}
                text={t('home.Metrics.mentoringFeedbacks.text')}
                tooltip={t('home.Metrics.mentoringFeedbacks.tooltip')}
            />
        </Styled.MetricsItems>
    );
};
