import React from 'react';
import { jwtDecode } from 'jwt-decode';

import { ContextAuth as CA } from 'contexts';
import { useApi } from 'hooks';
import { queryParam } from 'utils/query';

import * as Templates from './templates';

const initial = {
    clientSecret: queryParam('clientSecret'),
    email: queryParam('email'),
    eventId: queryParam('eventId'),
    error: false,
    loginCode: queryParam('loginCode'),
    token: queryParam('token'),
    verificationCode: queryParam('verificationcode')
};

export function AppLogin(props) {
    const { authState, dispatch } = React.useContext(CA);
    const api = useApi();

    // Manage the state for app autologin control
    // Get the component that will return after load
    const [state, setState] = React.useState(initial);

    // Authenticate the user on the app
    function authenticate({ auth, user }) {
        setState({ token: false, error: false });
        dispatch({ type: 'AUTHENTICATE', auth, user });
    }
    function unauthenticate() {
        setState({ token: false, error: false });
        dispatch({ type: 'UNAUTHENTICATE' });
    }

    // User authentication from query parameters
    function authenticateFromQuery() {
        try {
            // Tries to decode the token and get user information
            const decoded = jwtDecode(state.token);
            if (authState.auth.userid && authState.auth.userid !== decoded._id) {
                // If user is authenticate with a different user than received
                return dispatch({ type: 'UNAUTHENTICATE' });
            }
            // If no user authenticated or same user
            const auth = {
                userid: decoded._id,
                // replace with new query token:
                token: state.token,
                // replace with new query refresh_token if existing, if not keep the same one:
                refresh_token: state.refresh_token ?? authState.refresh_token
            };
            // Build headers with authentication token
            const headers = { headers: { api_key: state.token } };
            // Get the user infos to try to validate the user
            return api
                .get(`/users/${auth.userid}`, headers)
                .then(user => authenticate({ auth, user }))
                .catch(err => unauthenticate());
            // Got the user information? Alrighty then.
        } catch (err) {
            return unauthenticate();
        }
        // Hope that everything runs fine! Fingers-crossed!
    }

    function verifyEmail() {
        const { email, verificationCode } = state;
        const payload = { email, verificationCode };
        api.post(`/verifyemail`, payload).finally(() => setState({ error: false }));
    }

    function singleSignOn() {
        const { clientSecret, email, eventId, loginCode } = state;
        const payload = { clientSecret, email, eventId, loginCode };
        api.post(`/eventlogin`, payload)
            .then(res => setState({ error: false, email, ...res }))
            .catch(() => setState({ error: false }));
    }

    // Show the loading screen until login returns
    // Once everything loads check for errors
    if (state.token) {
        authenticateFromQuery();
        return <Templates.AppTemplateLogin error={state.error} />;
    }

    // Show the loading screen until login returns
    // Once everything loads check for errors
    if (state.email && state.verificationCode) {
        verifyEmail();
        return <Templates.AppTemplateLogin error={state.error} />;
    }

    // Show the loading screen until login returns
    // Once everything loads check for errors
    if (
        !authState.authenticated &&
        state.email &&
        state.eventId &&
        state.clientSecret &&
        state.loginCode
    ) {
        singleSignOn();
        return <Templates.AppTemplateLogin error={state.error} />;
    }

    // console.log('AppLogin', 'The build ran smoothlly');

    // Get the build the component to be shown
    const Component = props.then;
    return <Component>{props.children}</Component>;
}
export default AppLogin;
