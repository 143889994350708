import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const NetworkPage = styled.div`
    .c-header {
        margin-top: 3.2rem;
    }
    .c-header-input {
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
        border-radius: 0.8rem;
        padding: 0.8rem 1.4rem;
        width: 100%;
    }

    .c-header.filter {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
    }
    .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        gap: 0.8rem;
    }
`;

export const NetworkPageHead = styled.div`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    padding: 0 0 1.6rem;

    h2 {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.4;
    }
    h3 {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.4;
        ${truncate(1, 1.4)}
    }
`;

export const NetworkPageBody = styled.div`
    margin-top: 4rem;
`;
