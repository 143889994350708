import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const ChecklistFixed = styled.div`
    height: 100%;
`;

export const ChecklistItems = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    height: 100%;
    margin-top: 1.2rem;
    width: 100%;
`;
export const ChecklistItem = styled.div`
    align-items: flex-start;
    color: #8f8f8f;
    display: flex;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    gap: 1.2rem;
    position: relative;

    .text {
        line-height: 1.4;
        padding-top: 0.25rem;
        /* ${truncate(1, 1.4)} */
    }

    .numb {
        color: #8f8f8f;
        flex: 0 0 auto;
        margin-left: auto;
        padding-top: 0.25rem;
    }
    .numb.small {
        font-size: 1.2rem;
        font-weight: 400;
    }
    .numb strong {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-weight: 400;
    }

    &.checked {
        color: ${({ theme }) => theme.colors.darkBlue};

        .icon {
            background: transparent
                linear-gradient(
                    89deg,
                    ${({ theme }) => theme.colors.energyBlue} 0%,
                    ${({ theme }) => theme.colors.deepBlue} 100%
                );
            border-color: ${({ theme }) => theme.colors.energyBlue};
            color: ${({ theme }) => theme.colors.white};
        }
        .icon::before {
            background: transparent;
            content: '\f00c';
            font-family: 'Font Awesome 5 Pro';
            font-size: 1.2rem;
            font-weight: 700;
            display: block;
            transform: translate(0, 1px);
        }
    }

    &.started {
        color: ${({ theme }) => theme.colors.darkBlue};

        .icon {
            border-color: ${({ theme }) => theme.colors.energyBlue};
        }
        .icon::before {
            background: transparent
                linear-gradient(
                    89deg,
                    ${({ theme }) => theme.colors.energyBlue} 0%,
                    ${({ theme }) => theme.colors.deepBlue} 100%
                );
            display: block;
            height: 1.2rem;
            transform: translate(0, 0);
            width: 1.2rem;
        }
    }
`;

export const ChecklistTitle = styled.div`
    strong {
        color: ${({ theme }) => theme.colors.deepBlue};
    }
`;
