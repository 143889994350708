import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { ProfileLocationForm } from './ProfileLocationForm';

export function ProfileLocation(props) {
    const { emitToast } = React.useContext(CG);
    const { vars, putUser } = props.useProfile;

    // Initialize a new form handler
    const form = useForm({
        location: vars.location || {},
    });

    // Handle form submition
    const onSubmit = values => {
        const { location } = values;
        // Do the submition api request
        return putUser({ location })
            .then(() => {
                emitToast(props.t('ProfileLocation.saveSuccess'));
                if (vars.returnTo) {
                    RHP.push(vars.returnTo);
                    return;
                }
                RHP.goBack();
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="ProfileLocation" back={vars.returnTo || true}>
            <Page.Title>{props.t('ProfileLocation.title')}</Page.Title>
            <Page.Descr>{props.t('ProfileLocation.descr')}</Page.Descr>

            <ProfileLocationForm {...propsForm} />
        </Page.Page>
    );
}
export default ProfileLocation;
