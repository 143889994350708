import styled from 'styled-components';
import { positions } from 'utils/styles';

export const ListScrollsHold = styled.div.attrs({
    className: 'list-scrolls-hold',
})`
    margin: 0 -1.5rem;
    position: relative;
`;

export const ListScrollsHoldAbsolute = styled.div.attrs({
    className: 'list-scrolls-hold-absolute',
})`
    /* background: rgba(0, 0, 0, 0.2); */
    overflow-y: scroll;
    overflow-x: scroll;
    position: relative;
    ${positions(0, 'auto', 'auto', '50%')}
    transform: translateX(-50%);
    width: 100vw;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ListScrollsHoldContainer = styled.div.attrs({
    className: 'list-scrolls-hold-container container',
})``;

export const ListScrollsHoldControls = styled.div.attrs({
    className: 'list-scrolls-hold-controls',
})`
    display: none;
`;

export const ListScrollsHoldInside = styled.div.attrs({
    className: 'list-scrolls-hold-inside',
})`
    display: flex;
    padding: 0;
    transition: all 0.5s ease;
`;

export const Overflow = styled.ul.attrs({
    className: 'list-scrolls-hold-inside-overflow',
})`
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin: 0 -0.75rem;
    padding: 0;
    position: relative;
    white-space: nowrap;
`;

export const ScrollArrow = styled.div.attrs(props => ({
    className: `list-scrolls-hold-controls-scroll-arrow ${props.side}`,
}))`
    display: none;
`;
