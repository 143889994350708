import React from 'react';

import { Belts } from './components/belts';
import { ProfileCard } from './components/profile-card';
import { Interests } from './components/interests';
import { CompanySubscription } from './components/company-subscription';

import * as Styled from './StyledCXIProfile';
// import { BeltsAvailable } from './components/belts-available';

export const CXIProfile = () => (
    <Styled.CXIProfile>
        <ProfileCard />
        {/* <BeltsAvailable /> */}
        <Belts />
        <Interests />
        <CompanySubscription />
    </Styled.CXIProfile>
);
