import React from 'react';
import { Link } from 'react-router-dom';

import { ContextAuth as CA } from 'contexts';
import { LinksWithText, Subtitle } from 'components/page';
import { useConversionTable } from 'hooks';

import * as Styled from './StyledCompanyCard';

const CompanyC = ({ data, user }) => {
    const conv = useConversionTable(['companySize']);
    const size = conv.companySize[data.size];
    return (
        <Styled.CardInfos>
            <span className="name">{data.name}</span>
            {size && <span className="text">{size}</span>}
        </Styled.CardInfos>
    );
};

const Lwtxt = props =>
    !props.text && !props.link ? null : (
        <LinksWithText to={props.href}>
            {props.text && <span className="text">{props.text}</span>}
            {props.link && <span className="link">{props.link}</span>}
        </LinksWithText>
    );

const Title = props => (!props.text ? null : <Subtitle {...props} />);

export function CompanyCard(props) {
    const { state } = React.useContext(CA);
    const { user } = state;
    // Get the company data source
    const data = props.data || props.company || user.company;

    // Decide wicth information to show
    const Infos = CompanyC;

    // Check if it has a link prop and pass parameters
    const propsLink = {
        as: props.href ? Link : undefined,
        to: props.href
    };
    return !data ? null : (
        <Styled.Company>
            <Title href={props.href} {...props.title} />
            <Styled.CompanyCard {...propsLink}>
                <Styled.CardImage img={data.logo} />
                <Infos data={data} user={user} />
            </Styled.CompanyCard>
            <Lwtxt href={props.href} {...props.lwtxt} />
        </Styled.Company>
    );
}
export default CompanyCard;
