import styled from 'styled-components';

export const PageBoxed = styled.div.attrs(props => ({
    className: `c-page-boxed ${props.html ? 'html' : ''}`,
}))`
    background: #f2f2f2;
    flex: 1 1 auto;
    font-size: 1.2rem;
    margin-top: 2rem;
    padding: 1.5rem;

    &.html {
        height: 100px;
        overflow-y: hidden;
        overflow-y: scroll;
        p {
            margin: 0;
        }
        p + p {
            margin-top: 1.4em;
        }
    }
`;
