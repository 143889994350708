import React from 'react';

import * as List from 'components/list';
import { useStartupCXI } from '../../useStartupCXI';

import { Container } from '../container';
import * as Styled from './StyledSupport';

export const Support = () => {
    const { t } = useStartupCXI();

    // Create the secondary actions options
    const actions = [
        {
            as: 'a',
            icon: 'fab fa-whatsapp',
            href: 'https://wa.me/551135005001',
            text: t('support.whatsapp'),
        },
        {
            as: 'a',
            icon: 'fab fa-facebook-messenger',
            href: 'http://www.facebook.com/messages/t/1616610695217236',
            text: t('support.messenger'),
        },
        {
            as: 'a',
            icon: `far fa-envelope`,
            href: 'mailto:helpme@openstartups.net',
            text: t('support.email'),
        },
    ];

    return (
        <Styled.Support as={Container.Slim}>
            <Container.Head>
                <span className="text">{t('menu.support')}</span>
            </Container.Head>

            <Container.Body>
                Entre em contato caso tenha dúvidas ou problemas ao usar a plataforma do Programa
                100-10-1 Startups.
            </Container.Body>

            <Container.Feet>
                <List.List name="SupportList">
                    <List.Vertical items={actions} itemKey="icon" />
                </List.List>
            </Container.Feet>
        </Styled.Support>
    );
};
