import React from 'react';
import { Redirect, Route as ReactRoute, Router as ReactRouter, Switch } from 'react-router-dom';

import { ContextGlobal as CG } from 'contexts';
import { RouterHistoryProvider as RHP } from 'providers';

// Get routes from the proper places
import { routes as core } from 'core/routes';
import { routes as modules } from 'modules/routes';

import { RoutePrivate } from './RoutePrivate';
import { RoutesDeeplinks } from './RoutesDeeplinks';
import { RoutesStructure } from './RoutesStructure';

const routes = [
    // See /src/modules/routes for modules routes
    // They are imported here for structure reasons
    ...modules,

    // See /src/core/routes for core routes
    // They are imported here for structure reasons
    ...core,

    // Inside the 'pages' core module there is an error handler
    // This is here only for fallback purposes
    {
        exact: false,
        path: '/',
        private: false,
        component: ({ location }) => <Redirect to={{ ...location, pathname: '/404' }} />,
    },
];

const RouteSwitchs = props => {
    const { language } = React.useContext(CG).state;
    const filtered = routes.filter(r => !r.lang || (r.lang && r.lang.includes[language]));
    return (
        <Switch>
            {filtered.map(r => {
                const Route = r.private ? RoutePrivate : ReactRoute;
                return <Route key={r.path} {...r} />;
            })}
        </Switch>
    );
};

export const Routes = props => (
    <ReactRouter history={RHP}>
        <RoutesDeeplinks />
        <RoutesStructure then={RouteSwitchs} />
    </ReactRouter>
);
export default Routes;
