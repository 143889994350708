import React from 'react';

import * as Step from '../components/step';
import { Challenges } from '../forms/Challenges';

export const CXIStep1 = () => (
    <Step.Step currentStep={1}>
        <Challenges />
    </Step.Step>
);
