import styled from 'styled-components';

import background from 'assets/images/bg-cxi-startup.png';

import { positions } from 'utils/styles';

export const CXIMentoring = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    & > .c-header .c-header-body {
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
        padding: 0;
    }

    hr {
        border-top: 2px solid rgba(0, 0, 0, 0.15);
        margin: 4.8rem -100vh;
    }
`;
export const CXIMentoringLayout = styled.div`
    min-height: 100%;
    position: relative;

    &::before {
        background: transparent no-repeat bottom center;
        background-image: url(${background});
        background-size: cover;
        content: ' ';
        height: 28rem;
        position: absolute;
        ${positions('0', '0', 'auto', '0')}
        width: 100%;
        z-index: -1;
    }
`;
export const CXIMentoringSpacer = styled.div`
    margin-top: 1.6rem;
    @media (min-width: 768px) {
        margin-top: 3.2rem;
    }
`;

export const CXIMentoringCard = styled.div``;

export const NetworkPageHead = styled.div`
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    padding: 0 0 1.6rem;

    h2 {
        color: ${({ theme }) => theme.colors.white};
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.4;
    }
`;
export const HeaderHeadButton = styled.button.attrs({
    className: 'c-header-bttn',
})`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.blue};
    display: flex;
    font-size: 1.4rem;
    font-weight: 600;
    gap: 0.8rem;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;

    @media (min-width: 768px) {
        margin-left: auto;
    }
`;
