import styled from 'styled-components';

export const Card = styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 0;
`;

//GRAPHICS CONTAINER
export const GraphicsContainer = styled(Card)`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: baseline;
`;

export const BubbleChartContainer = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: transparent !important;
    transition: ease all 0.5s;

    .node:hover {
        font-size: 60px;
        transition: 0.5s ease all;

        circle:hover {
            transition: 0.5s ease all;
        }

        text:hover {
            transition: 0.5s ease all;
        }
    }

    .legend {
        font-weight: regular;
        transform: translate(70%, 10%);
    }
`;

// LEFT SESSION
export const LeftSession = styled.div`
    display: flex;
    flex-direction: column;
    width: 55%;
    height: 100%;
    padding: 5px 5px;
    justify-content: space-between;
    margin: 0;
`;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//RIGHT SESSION

export const RightSession = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 100%;
    padding: 5px 5px;
    justify-content: space-between;
    margin: 0;
    position: relative;
`;

export const Container = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;

    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2rem;
    }
    h2 {
        font-size: 1.7rem;
        margin-left: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
`;
export const ScaleContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 15px;
    left: 2.5rem;

    h3 {
        margin: 0;
        font-size: 1.9rem;
        padding: 0 10px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
`;

export const ScaleRow = styled.div`
    display: flex;
    align-items: baseline;
    gap: 35px;
    padding: 0px 10px;
    justify-content: baseline;
    margin-left: 18px;
    font-weight: bold;
`;
export const ScaleItem = styled.span`
    display: flex;
    width: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 100%;
`;
export const CircledSpanListIcon = styled.div`
    background-color: ${props => props.BgColor};
    width: ${props => props.scale};
    height: ${props => props.scale};
    border-radius: 100%;
    border: 1px solid #202297;
`;

export const SquareSpanListIcon = styled.div`
    background-color: ${props => props.BgColor};
    width: 10px;
    height: 10px;
    margin-right: 5px;
`;
