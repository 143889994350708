import React from 'react';
import { useParams } from 'react-router';

import { Header } from 'modules/cxi/components/header/Header';
// import { MatchAction } from 'modules/cxi/components/match';
import * as Tabs from 'modules/cxi/components/tabs';

import { StartupCard } from './components/startup-card';
import { StartupMatch } from './components/startup-match/StartupMatch';

import { useCXIStartup } from './useCXIStartup';
import { CXIStartupContextProvider } from './CXIStartupContext';

import { CXIStartupGeneral } from './general/CXIStartupGeneral';

import * as Styled from './StyledCXIStartup';
import { CXIStartupProfile } from './profile/CXIStartupProfile';
import { CXIStartupFinancial } from './financial/CXIStartupFinancial';
import { CXIStartupSummaries } from './summaries/CXIStartupSummaries';

export const CXIStartupModule = () => {
    const { vars } = useCXIStartup();
    const { match, startup, showMatch } = vars;

    return (
        <Styled.CXIStartupLayout className={startup.typeOfProject === 'tech' ? 'is-tech' : ''}>
            <Styled.CXIStartup>
                <Header className="main" back />

                <Styled.CXIStartupCard>
                    <StartupCard startup={startup} />
                    {showMatch && <StartupMatch match={match} />}
                </Styled.CXIStartupCard>

                <Tabs.TabsContainer initial="general">
                    {/* <Header
                        className="tabs"
                        button={<MatchAction match={match} />}
                        title={t('startup.title')}
                        tooltip={t('startup.tooltip')}
                    >
                        <Tabs.TabsButtons>
                            <Tabs.TabsButton name="general">
                                <span className="text">{t('startup.tab.general')}</span>
                            </Tabs.TabsButton>

                            <Tabs.TabsButton name="profile">
                                <span className="text">{t('startup.tab.profile')}</span>
                            </Tabs.TabsButton>

                            <Tabs.TabsButton name="financial">
                                <span className="text">{t('startup.tab.financial')}</span>
                            </Tabs.TabsButton>

                            {startup.typeOfProject === 'tech' && (
                                <Tabs.TabsButton name="summaries">
                                    <span className="text">{t('startup.tab.summaries')}</span>
                                </Tabs.TabsButton>
                            )}
                        </Tabs.TabsButtons>
                    </Header> */}

                    <Styled.CXIStartupTabs>
                        <Tabs.Tab name="general">
                            <CXIStartupGeneral />
                        </Tabs.Tab>

                        <Tabs.Tab name="profile">
                            <CXIStartupProfile />
                        </Tabs.Tab>

                        <Tabs.Tab name="financial">
                            <CXIStartupFinancial />
                        </Tabs.Tab>

                        {startup.typeOfProject === 'tech' && (
                            <Tabs.Tab name="summaries">
                                <CXIStartupSummaries />
                            </Tabs.Tab>
                        )}
                    </Styled.CXIStartupTabs>
                </Tabs.TabsContainer>
            </Styled.CXIStartup>
        </Styled.CXIStartupLayout>
    );
};

export const CXIStartup = () => {
    const { id } = useParams();
    return (
        <CXIStartupContextProvider matchId={id}>
            <CXIStartupModule />
        </CXIStartupContextProvider>
    );
};
export default CXIStartup;
