import React, { useCallback, useMemo } from 'react';

import { format } from 'date-fns/esm';
import { Container, UserContainer, Body, MessageChat, Avatar } from './styles';

interface MessageProps {
    username: string;
    userid: string;
    description: string;
    position: string;
    time: Date;
    comment: string;
    avatar: string;
}

export const Message: React.FC<MessageProps> = ({
    comment,
    description,
    userid,
    time,
    username,
    position,
    avatar,
}) => {
    const formatDate = useCallback(() => {
        const date = format(time, "dd/MM/yyyy - HH:mm'h'");
        return date;
    }, [time]);

    const getInitials = useMemo(() => {
        if (username !== '') {
            const nameSplit = username?.toUpperCase().split(' ');
            return `${nameSplit[0]?.charAt(0)}${nameSplit[1]?.charAt(0)}`;
        }
        return '';
    }, [username]);

    return (
        <Container>
            <UserContainer>
                <Avatar>
                    {avatar !== undefined && avatar !== '' ? (
                        <img src={avatar} alt={username} />
                    ) : (
                        <span>{getInitials}</span>
                    )}
                </Avatar>
                <strong>{username}</strong>
                <span>{description}</span>
                <span>{position}</span>
            </UserContainer>
            <Body>
                <strong>{formatDate()}</strong>
                <MessageChat>
                    <p>{comment}</p>
                </MessageChat>
            </Body>
        </Container>
    );
};
