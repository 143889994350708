import styled from 'styled-components';

export const ChallengeFeedback = styled.div`
    text-align: center;
    gap: 1.2rem 1rem;

    .m-container-feet {
        flex-flow: inherit;
        display: flex;
    }
    textarea::placeholder {
        font-size: 14px;
    }
`;

export const Descr = styled.div`
    font-size: 1.4rem;
    line-height: 1.4;
`;

export const FeedbackFormField = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 1.2rem 1.6rem;

    &:focus-within {
        border-color: ${({ theme }) => theme.colors.energyBlue};
    }

    .component-form-field {
        margin: 0;

        .fake-control {
            min-height: auto;
        }
        .form-control {
            border: 0;
            padding: 0;
        }
        .form-control::placeholder {
            font-style: italic;
        }
        .form-control-label {
            color: #0094f9;
            font-weight: 600;
        }
    }
`;

export const Title = styled.div`
    color: #56c1d6;
    font-size: 20px;
    font-weight: 700;
    margin-top: 70px;
    margin-left: 10px;
`;

export const SubTitle = styled.div`
    color: #252830;
    font-size: 14px;
    margin-bottom: -30px;
    font-weight: 400;
    text-align: left;
`;

export const Avatar = styled.div`
    display: flex;
    justify-content: left;
    margin-top: -5rem;
    margin-left: 3rem;

    .user-avatar-holder {
        border: 3px solid #fff;
    }
    .feedback {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #fff;
    }
`;
