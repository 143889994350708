import React from 'react';
import { useParams, useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';

export function MatchFromReferral(props) {
    const urlParams = useParams();
    const location = useLocation();
    const shareLink = location.pathname;
    const { referralCode } = urlParams;
    return (
        <Redirect
            to={{
                pathname: `/startup`,
                state: { referralCode, shareLink },
            }}
        />
    );
}

export default MatchFromReferral;
