import { useContext } from 'react';
import { CXIDashboardStartupContext } from './CXIDashboardStartupContext';

export const useCXIDashboardStartup = () => {
    const context = useContext(CXIDashboardStartupContext);
    if (context === undefined) {
        throw new Error(
            'useCXIDashboardStartup must be used within a CXIDashboardStartupContextProvider'
        );
    }
    return context;
};
