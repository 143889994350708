import styled from 'styled-components';

export const PageImage = styled.figure.attrs(props => ({
    className: 'c-page-image',
    style: { maxWidth: props.width ? props.width : '' },
}))`
    display: inline-block;
    margin: 0;
    max-width: 100%;
    position: relative;

    img,
    svg {
        height: auto;
        max-width: 100%;
    }
`;
