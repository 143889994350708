import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppTemplate } from './AppTemplate';
import * as Styled from './StyledAppTemplate';

export const AppTemplateNeedUpdate = props => {
    const { t } = useTranslation('app');
    return (
        <AppTemplate name="AppTemplateNeedUpdate">
            <Styled.Icons>
                <span className="icon fad fa-mobile" aria-hidden="true" />
                <span className="icon fad fa-mobile-android" aria-hidden="true" />
            </Styled.Icons>

            <Styled.Title>{t('AppTemplateNeedUpdate.title')}</Styled.Title>
            <Styled.Descr>{t('AppTemplateNeedUpdate.descr')}</Styled.Descr>

            <Styled.Buttn>
                <span className="text">{t('AppTemplateNeedUpdate.button')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Styled.Buttn>
            {/* Remember that the user should donwload the app again */}
        </AppTemplate>
    );
};
export default AppTemplateNeedUpdate;
