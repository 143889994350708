import React from 'react';
import * as Styled from './StyledProgressBar';

function ProgressBar({ current }) {
    return (
        <Styled.ProgressBar>
            <Styled.ProgressItem className={current === 1 ? 'active' : ''} />
            <Styled.ProgressItem className={current === 2 ? 'active' : ''} />
            <Styled.ProgressItem className={current === 3 ? 'active' : ''} />
            <Styled.ProgressItem className={current === 4 ? 'active' : ''} />
            <Styled.ProgressItem className={current === 5 ? 'active' : ''} />
        </Styled.ProgressBar>
    );
}

export default ProgressBar;
