import React, { useState } from 'react';
import tooltip from 'assets/images/icons/tooltipRanking.png';
import BottomCardPosition from './bottomPosition/BottomPosition';
import * as Styled from './StyledStartupPosition';

export function PositionCard(props) {
    const { contracts, title, tooltipText, t } = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const bottomCardData = {
        upper: contracts?.upper,
        lower: contracts?.lower,
    };
    return (
        <Styled.Card>
            <Styled.CardTitle>
                {title}
                <span
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <img src={tooltip} alt="tooltip icon" />
                </span>
            </Styled.CardTitle>

            {showTooltip && (
                <Styled.CardTitleTooltip>
                    {tooltipText} {t('StartupStep8.lastUpdatedDate')}{' '}
                    {contracts?.lastUpdated
                        ? new Date(contracts?.lastUpdated)?.toLocaleString()
                        : '--/--/----'}
                </Styled.CardTitleTooltip>
            )}
            <Styled.Hr backgroundColor="#45aaf2" height="2px" />

            <Styled.Position>{`${contracts?.rank ? `${contracts?.rank}º` : '-'}`}</Styled.Position>
            <Styled.Hr />
            <BottomCardPosition position={bottomCardData} {...props} />
        </Styled.Card>
    );
}
export default PositionCard;
