import styled from 'styled-components';
import { positions } from 'utils/styles';

export const Step = styled.div`
    background: ${({ theme }) => theme.colors.white};
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
`;

export const StepHead = styled.div`
    background: rgb(240, 240, 240);
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGray};
    height: 8rem;
    max-height: 8rem;
    padding: 1.6rem 2.4rem;
    text-align: center;

    img {
        height: 100%;
    }
`;

export const StepBody = styled.div.attrs({ className: 'container' })`
    padding: 2.4rem;
`;

export const StepFeet = styled.div.attrs({ className: 'container' })`
    align-items: center;
    display: flex;
    gap: 0.8rem;
    position: relative;
    padding: 2.4rem;

    .steps-of {
        font-weight: bold;
        opacity: 0.5;
        position: absolute;
        ${positions('50%', 'auto', 'auto', '50%')}
        transform: translate(-50%, -50%);
    }

    .steps-spacer {
        margin: 0 auto;
    }
`;
