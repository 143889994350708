import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const Feedback = styled.div`
    background: #f2f2f2;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.6rem;

    @media (min-width: 992px) {
        flex-direction: row;
        gap: 3.2rem;
    }
`;

export const FeedbackUser = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 992px) {
        flex: 0 0 24rem;
    }

    .user-avatar {
        width: 6.4rem;
    }
`;

export const FeedbackText = styled.div`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.black};
    display: inline-flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    position: relative;

    @media (min-width: 992px) {
        text-align: left;
    }

    .theme {
        color: ${({ theme }) => theme.colors.blue};
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
    }
    .question {
        font-size: 1.4rem;
        font-weight: 700;7
    }

    .date {
        color: ${({ theme }) => theme.colors.deepBlue};
        font-size: 1.2rem;
        font-weight: 600;
    }
    .text {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.6rem;
        font-weight: 400;
    }

    .caret {
        color: ${({ theme }) => theme.colors.white};
        font-size: 2rem;
        position: absolute;
        ${positions('auto', 'auto', '100%', '50%')}
        transform: rotate(90deg) translate(6px, -50%);

        @media (min-width: 992px) {
            ${positions('1rem', '100%', 'auto', 'auto')}
            transform: none;
        }
    }
`;

export const Name = styled.div`
    color: ${({ theme }) => theme.colors.deepBlue};
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 1.6rem;
    text-align: center;
    ${truncate(1, 1.2)}
`;

export const Desc = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 0.4rem;
    ${truncate(2, 1.4)}
`;
