import React from 'react';

import { useApi } from 'hooks';

export function useRegisterContact(skipRelated) {
    const api = useApi();

    // Initial values for the state
    const initial = {
        startups: [],
    };

    // Controls if the module is ready
    // eslint-disable-next-line no-unused-vars
    const [ready, setReady] = React.useState(true);

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = React.useState();

    async function getStartupDataByStartupSecret(startupSecret) {
        return api
            .get(`startupSecret/${startupSecret}`)
            .then(res => {
                addVars({ startupBySecret: res });
                return res;
            })
            .catch(err => {
                return setError(err.message);
            });
    }
    async function postContact(payload) {
        try {
            const response = await api.post(
                `/startups/${payload.startupId}/registercontact`,
                payload
            );
            return response;
        } catch (err) {
            console.log(err, 'err');
            return null;
        }
    }
    return {
        // Ready to load?
        ready,
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Include vars functions
        // Custom functions
        postContact,
        getStartupDataByStartupSecret,
        // contacts
    };
}
export default useRegisterContact;
