import React from 'react';
import { useData } from 'modules/corporatehub/context/dataContext';
import * as Styled from './StyledCorporateHubGroup';
import LeftSession from './components/LeftSession';
import RightSession from './components/RightSession';

export default function CorporateHubGroupView(props) {
    const dataContext = useData();
    const { groupsPageVars } = dataContext.state;
    console.log(groupsPageVars, 'group vars');
    return (
        <Styled.SessionContainer>
            <LeftSession data={groupsPageVars} />
            <RightSession data={groupsPageVars} />
        </Styled.SessionContainer>
    );
}
