import styled from 'styled-components';

export const OiweekSubscribe = styled.div.attrs({
    className: 'oiweek-subscribe',
})`
    .component-page-container {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const OiweekSubscribeForm = styled.div.attrs({
    className: 'oiweek-subscribe-form',
})`
    background: #fff;
    color: #444;
    flex: 1 1 auto;
    margin-top: 3rem;
    position: relative;

    &::before {
        background: inherit;
        content: '';
        position: absolute;
        top: 0;
        bottom: calc(-5.9rem - var(--ion-safe-area-bottom));
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
    }

    .component-form {
        position: relative;
    }
`;
