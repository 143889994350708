import styled from 'styled-components';
import { positions } from 'utils/styles';

export const FormInputCheckbox = styled.div.attrs({
    className: 'form-input-checkbox',
})`
    position: relative;

    & + .form-input-checkbox {
        margin-top: 0.5rem;
    }

    .form-check-label {
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 1.4;
        padding: 0 0 0 2.8rem;
    }
    .form-check-label::before {
        color: #ddd;
        content: '\f0c8';
        font-family: 'Font Awesome 5 Pro';
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.2;
        position: absolute;
        ${positions(0, 'auto', 'auto', 0)}
        transform: translateY(0);
    }

    .form-check {
        height: 1.8rem;
        opacity: 0;
        position: absolute;
        ${positions(0, 'auto', 'auto', 0)}
        width: 1.8rem;
    }

    .form-check[disabled] ~ .form-check-label {
        color: #ddd;
        &::before {
            font-weight: 600;
        }
    }

    .form-check:checked ~ .form-check-label::before {
        color: var(--primary);
        content: '\f14a';
    }
`;
