import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const Opportunity = styled.div`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 6.2px 0px 6.2px 0px;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease;
    padding: 23px;
    background: white;
    width: 600px;
    height: fit-content;
    background: linear-gradient(#0094f9 70px, white 70px) no-repeat;
`;

export const OpportunityHead = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 7px;
    gap: 8px;
    color: white;

    .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        min-width: 75px;
        padding: 5px;
        border-radius: 50%;
        background: #ffffff;
        border: 3px solid #dcd8d8;
    }
    img {
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        z-index: 10;
        height: auto;
        width: 50px;
        height: 100%;
    }
`;

export const OpportunityBody = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    padding: 1.6rem;
    padding: 11px 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.3;
        ${truncate(3, 1.3)}
    }

    .descr {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.4;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
    }
`;

export const OpportunityFeet = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 11px 18px;

    iframe {
        width: 100%;
        height: 100%;
    }

    .videoWrapper {
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 300px;
    }
    .badge {
        background: #0094f9;
        border-radius: 1.6rem;
        color: ${({ theme }) => theme.colors.white};
        display: inline-block;
        font-size: 0.9rem;
        line-height: 1.2rem;
        padding: 0.6rem 0.8rem;
        width: fit-content;
    }
`;

export const Button = styled.a`
    align-items: center;
    background: transparent;
    border: 1px solid #0094f9;
    border-radius: 0.4rem;
    color: #0094f9;
    display: flex;
    font-size: 1.3rem;
    font-weight: 600;
    gap: 0.6rem;
    line-height: 1.2;
    justify-content: center;
    padding: 1.3rem 1.6rem;
    width: 100%;
    color: white;
    background-color: #0094f9;
    transition: all 0.5s ease;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
        color: white;
    }
`;

export const BadgesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;
