import React, { useEffect } from 'react';

import * as Form from 'components/form';
import { useForm } from 'components/form';
import * as Page from 'components/page';
import { RHP } from 'providers';

export function StartupStep9({ useRankingStartup, t }) {
    const { vars, updateSteps, putStartupRankingApplication } = useRankingStartup;
    const { steps, interests } = vars.application;

    // Update the step has completed if relationships exist
    useEffect(() => {
        if (steps.step8 && !steps.step9) {
            updateSteps({ step9: true });
        }
    }, []);

    const form = useForm({
        ...interests
    });

    const fields = {
        trophy: {
            // @ts-ignore
            error: form.errors.trophy,
            label: t('StartupStep9.topic1Check1'),
            name: 'trophy',
            type: 'checkbox',
            required: false,
            value: form.values.trophy,
            onChange: form.handleChange
        },
        institutionalPresence: {
            // @ts-ignore
            error: form.errors.institutionalPresence,
            label: t('StartupStep9.topic2Check1'),
            // helper:
            //     'pacote incluindo acesso de 2 a 3 convidados',
            name: 'institutionalPresence',
            type: 'checkbox',
            required: false,
            value: form.values.institutionalPresence,
            onChange: form.handleChange
        },
        // singleTicket: {
        //     // @ts-ignore
        //     error: form.errors.singleTicket,
        //     label: 'Lista de Espera Convite Avulso',
        //     helper:
        //         'limitado a um convite por empresa, exclusivo para C-Level e disponibilizado apenas em caso de vagas remanescentes',
        //     name: 'singleTicket',
        //     type: 'checkbox',
        //     required: false,
        //     value: form.values.singleTicket,
        //     onChange: form.handleChange
        // },
        // mediaKit: {
        //     // @ts-ignore
        //     error: form.errors.mediaKit,
        //     label: 'Recebimento Mídia Kit Patrocínio Noite de Gala',
        //     name: 'mediaKit',
        //     type: 'checkbox',
        //     required: false,
        //     value: form.values.mediaKit,
        //     onChange: form.handleChange
        // },
        // previousSectorialReport: {
        //     // @ts-ignore
        //     error: form.errors.previousSectorialReport,
        //     label: 'Relatório 2023',
        //     name: 'previousSectorialReport',
        //     type: 'checkbox',
        //     required: false,
        //     value: form.values.previousSectorialReport,
        //     onChange: form.handleChange
        // },
        nextSectorialReport: {
            // @ts-ignore
            error: form.errors.nextSectorialReport,
            label: t('StartupStep9.topic3Check1'),
            name: 'nextSectorialReport',
            type: 'checkbox',
            required: false,
            value: form.values.nextSectorialReport,
            onChange: form.handleChange
        }
    };

    // Check for previous step conclusion
    if (!steps || steps.step1 !== true) {
        RHP.replace('/ranking/latam/corporate/');
        return null;
    }

    const onSubmit = async (values: any) => {
        try {
            const payload = {
                ...vars.application,
                interests: { ...values }
            };

            payload.steps.step9 = true;

            const response = await putStartupRankingApplication(payload);

            RHP.push('/ranking/latam/startup');
            return response;
        } catch (err) {
            console.error(err);
        } finally {
            form.submited();
        }
    };

    // @ts-ignore
    const evSubmit = (ev: any) => form.handleSubmit(ev, onSubmit);

    return (
        <Page.Page name="CorporateStep9" customBackText={t('glossary:backToRanking')}>
            <Page.Title>{t('StartupStep9.title')}</Page.Title>
            <Page.Descr>{t('StartupStep9.description')}</Page.Descr>

            <br />
            <Page.Ruler />

            <Form.Form name="CorporateStep9Form" onSubmit={evSubmit}>
                <Page.Title>{t('StartupStep9.topic1Title')}</Page.Title>
                <Page.Descr>{t('StartupStep9.topic1Description')}</Page.Descr>
                <Form.Field {...fields.trophy} />

                <Page.Ruler />

                <Page.Title>{t('StartupStep9.topic2Title')}</Page.Title>
                <Page.Descr>{t('StartupStep9.topic2Description')}</Page.Descr>
                <Form.Field {...fields.institutionalPresence} />
                {/* <Form.Field {...fields.singleTicket} />
                <Form.Field {...fields.mediaKit} /> */}

                <Page.Ruler />

                <Page.Title>{t('StartupStep9.topic3Title')}</Page.Title>
                <Page.Descr>{t('StartupStep9.topic3Description')}</Page.Descr>
                {/* <Form.Field {...fields.previousSectorialReport} /> */}
                <Form.Field {...fields.nextSectorialReport} />

                <Page.Ruler />

                <div style={{ display: 'flex', gap: 10 }}>
                    <Page.Buttn
                        style={{ width: '20%' }}
                        // @ts-ignore
                        to="/ranking/latam/startup/step8"
                    >
                        <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                        <span className="text">{t('glossary:back')}</span>
                    </Page.Buttn>

                    <div style={{ width: '80%' }}>
                        <Form.Submt text={t('glossary:save')}>
                            <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                        </Form.Submt>
                    </div>
                </div>
            </Form.Form>
        </Page.Page>
    );
}
