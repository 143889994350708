import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserStartupsList } from 'core/components/user';
import { iframePush } from 'core/iframe';
import { CONFIG } from 'config';
import { RHP } from 'providers';
import * as Page from 'components/page';
import { useLocation } from 'react-router';
import { Button } from 'modules/cxi/components/button';
import { ContextGlobal as CG } from 'contexts';

export const StartupIndex = props => {
    const { t } = useTranslation('ranking');
    const { state } = React.useContext(CG);
    const { language } = state;
    const { vars, getMatchId } = props.useStartup;
    const { startups, startupId } = vars;
    const location = useLocation();
    const referralCode = location?.state?.referralCode;
    const shareLink = location?.state?.shareLink;

    async function generateMatch(startupId) {
        const matchId = await getMatchId(referralCode, startupId);
        return matchId;
    }

    const hasTechs = startups?.filter(startup => {
        return startup.typeOfProject === 'tech';
    });
    useEffect(() => {
        if (startups.length === 1 && referralCode && referralCode !== undefined) {
            generateMatch(startups[0]._id).then(matchIdResponse => {
                const matchUrl = `${startups[0]._id}/100-10-1/feedbacks`;
                return RHP.push({
                    pathname: matchUrl,
                    state: { matchIdResponse }
                });
            });
            RHP.push(`startup/${startups[0]._id}`);
        }
    }, [referralCode, startups.length]);

    if (startups.length === 0) {
        if (location.search === '?rankingRedirect=true') {
            iframePush(`${CONFIG.appLinks.startup}?rankingRedirect=true`, '/startup')();
            return null;
        }
        if (referralCode) {
            iframePush(
                `${CONFIG.appLinks.startup}?&redirectAfterRegisterUrl=${shareLink}`,
                '/startup'
            )();
            return null;
        }
        iframePush(`${CONFIG.appLinks.startup}`, '/startup')();
        return null;
    }

    const handleClick = startup => {
        if (referralCode) {
            generateMatch(startup._id).then(matchIdResponse => {
                const matchUrl = `${startup._id}/100-10-1/feedbacks`;
                return RHP.push({
                    pathname: matchUrl,
                    state: { matchIdResponse }
                });
            });
        }

        return RHP.push(`startup/${startup._id}`);
    };

    const handleRegisterNewTech = () => {
        const url = encodeURI(
            `${CONFIG.appLinks.startup}/registerstartup/?typeOfProject=tech&lang=${language}`
        );
        iframePush(url)();
    };
    return (
        <Page.Page name="StartupIndex" loading={!startups.length} back="/home">
            <Page.Title>
                {hasTechs?.length > 0 && hasTechs?.length === startups?.length
                    ? t('startup:StartupIndex.techsTitle')
                    : t('StartupIndex.title')}
            </Page.Title>
            <Page.Descr>{props.t('StartupIndex.descr')}</Page.Descr>

            <UserStartupsList
                data={startups.map(startup => ({
                    ...startup,
                    name: startupId === startup._id ? t('loadingStartupData') : startup.name,
                    onClick: () => handleClick(startup)
                }))}
            />

            {hasTechs?.length > 0 && (
                <>
                    <hr />
                    <Button onClick={handleRegisterNewTech}>
                        {t('startup:StartupIndex.registerStartup')}{' '}
                    </Button>
                </>
            )}
        </Page.Page>
    );
};

export default StartupIndex;
