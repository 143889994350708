import React from 'react';

import * as Match from 'modules/cxi/components/match';
import useCXI from 'modules/cxi/useCXI';
import * as Styled from './StyledMatches';

export const Matches = ({ matches, t }) => {
    const { vars } = useCXI();
    const { cxi } = vars;

    return !matches.length ? (
        <Styled.MatchesEmpty>
            <span className="icon fa-2x far fa-empty-set" aria-hidden="true" />
            <Styled.MatchesEmptyTitle>{t('networks.Matches.empty')}</Styled.MatchesEmptyTitle>
            <Styled.MatchesEmptyDescr>{t('networks.Matches.emptyDescr')}</Styled.MatchesEmptyDescr>
        </Styled.MatchesEmpty>
    ) : (
        <Styled.Matches>
            <Styled.MatchesThead>
                <Styled.MatchesTr>
                    <Styled.MatchesTh>{t('networks.Matches.startup')}</Styled.MatchesTh>
                    <Styled.MatchesTh>{t('networks.Matches.evaluation')}</Styled.MatchesTh>
                    <Styled.MatchesTh>{t('networks.Matches.feedback')}</Styled.MatchesTh>
                    <Styled.MatchesTh>{t('networks.Matches.speed-dating')}</Styled.MatchesTh>
                    <Styled.MatchesTh>{t('networks.Matches.mentoring')}</Styled.MatchesTh>
                    <Styled.MatchesTh>{t('networks.Matches.points')}</Styled.MatchesTh>
                    <Styled.MatchesTh>{t('networks.Matches.action')}</Styled.MatchesTh>
                </Styled.MatchesTr>
            </Styled.MatchesThead>

            <Styled.MatchesTbody>
                {matches.map(match => (
                    <Styled.MatchesTr key={match.matchid}>
                        <Styled.MatchesTd>
                            <Match.MatchStartup match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <Match.MatchEvaluation match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <Match.MatchFeedback match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <Match.MatchSpeedDating match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <Match.MatchMentoring match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <Match.MatchPoints match={match} />
                        </Styled.MatchesTd>

                        <Styled.MatchesTd>
                            <Match.MatchAction match={match} />
                        </Styled.MatchesTd>
                    </Styled.MatchesTr>
                ))}
            </Styled.MatchesTbody>
        </Styled.Matches>
    );
};
