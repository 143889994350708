import React from 'react';

import * as Form from 'components/form';

export function FounderAddForm(props) {
    const { form, onSubmit } = props;

    const fields = {
        founderEmail: {
            error: form.errors.founderEmail,
            label: props.t('glossary:email'),
            name: 'founderEmail',
            type: 'email',
            value: form.values.founderEmail,
            onChange: form.handleChange,
        },
        founderName: {
            error: form.errors.founderName,
            label: props.t('FounderAdd.founderName'),
            name: 'founderName',
            type: 'text',
            value: form.values.founderName,
            onChange: form.handleChange,
        },
        phone: {
            error: form.errors.phone,
            label: props.t('glossary:phone'),
            name: 'phone',
            type: 'tel',
            required: false,
            value: form.values.phone,
            onChange: form.handleChange,
        },
        linkedin: {
            error: form.errors.linkedin,
            topLabel: '',
            label: props.t('glossary:linkedin'),
            name: 'linkedin',
            type: 'text',
            required: false,
            value: form.values.linkedin,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();
        return form.handleSubmit(ev, onSubmit);
    };
    return (
        <Form.Form name="FounderAddForm" onSubmit={evSubmit}>
            <Form.Field {...fields.founderName} />
            <Form.Field {...fields.founderEmail} />
            <Form.Row>
                <Form.Field size="6" {...fields.phone} />
                <Form.Field size="6" {...fields.linkedin} />
            </Form.Row>

            <Form.Submt {...form} text={props.t('glossary:save')} />
        </Form.Form>
    );
}
export default FounderAddForm;
