import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ContextAuth as CA } from 'contexts';

export function RoutePrivate(props) {
    // Get the authentication state
    const { pathname, search } = window.location;

    const { state } = React.useContext(CA);
    const pathnameUrl = state.pathnameUrl || '/login';
    const returnUrl = state.returnUrl || encodeURIComponent(pathname + search);

    // The user is authenticated correctly?
    if (state.authenticated !== true) {
        // console.log('RoutePrivate', 'User is not authenticated...');
        const propsRedir = { pathname: pathnameUrl, search: `?returnUrl=${returnUrl}` };
        return <Route component={() => <Redirect to={propsRedir} />} />;
    }
    // Everything is fine, then keep going!
    return <Route {...props} />;
}
export default RoutePrivate;
