import styled from 'styled-components';

export const Option = styled.button`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.neutralDark};
    border-radius: 6px;
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: 2.4rem;

    &:focus,
    &:hover {
        background: #f7f9fc;
        border-color: ${({ theme }) => theme.colors.energyBlue};

        .icon::before {
            content: '\f192';
        }
    }

    .icon {
        font-size: 1.8rem;
    }
    .text {
        font-size: 1.2rem;
        font-weight: 600;
    }

    &.checked {
        background: #f7f9fc;
        border-color: ${({ theme }) => theme.colors.green};

        .icon {
            color: ${({ theme }) => theme.colors.energyBlue};
        }
        .icon::before {
            content: '\f058';
        }
    }
`;
