import React from 'react';

import { iframeLink } from 'core/iframe';

import { InvestorIndexView } from './InvestorIndexView';
import * as Styled from './StyledInvestorIndex';

export function InvestorIndex(props) {
    const { vars } = props.useRankingInvestor;
    const { application } = vars;

    // Create the steps for this index
    const steps = [
        {
            step: 1,
            href: '/ranking/latam/investor/step1',
            text: props.t('InvestorIndex.step1'),
            isBlocked: false,
            isCompleted: application.steps.step1 || false,
        },
        {
            step: 2,
            href: '/ranking/latam/investor/step2',
            text: props.t('InvestorIndex.step2'),
            isBlocked: !application.steps.step1,
            isCompleted: application.steps.step2 || false,
        },
        {
            step: 3,
            href: '/ranking/latam/investor/step3',
            text: props.t('InvestorIndex.step3'),
            isBlocked: !application.steps.step2,
            isCompleted: application.steps.step3 || false,
        },
    ];

    // Create a list for the support links
    const actions = [
        {
            id: 1,
            href: iframeLink('https://100os.net/ranking-sobre'),
            text: props.t('InvestorIndex.RankingAbout'),
        },
        {
            id: 2,
            href: '/support',
            text: props.t('InvestorIndex.HelpAndSupport'),
        },
    ];

    const propsView = { ...props, actions, steps };
    return (
        <Styled.InvestorIndex>
            <InvestorIndexView {...propsView} />
        </Styled.InvestorIndex>
    );
}
export default InvestorIndex;
