import React from 'react';

import * as Form from 'components/form';
import { useConversionOptions } from 'hooks';

import { GrowingBusinessData } from '../components';
import * as Styled from './styles';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {}
};

export const StartupStep5Form = props => {
    const conv = useConversionOptions(['seekingHowMuch'], { namespace: 'currency' });
    const { form } = props;
    let { demographics } = form.values;

    const onChangeFundingRoundOpen = ev => {
        const event = fakeEvent;
        const { value } = ev.target;
        event.target.name = 'demographics';

        if (!demographics) {
            demographics = {};
            demographics.financial = {};
        }

        if (value === 'true') {
            demographics.financial.seekingInvestment = 1;
        } else {
            demographics.financial.seekingInvestment = 2;
        }

        event.target.value = demographics;
        form.values.isFundingRoundOpen = value;

        return form.handleChange(event);
    };

    const fields = {
        isFundingRoundOpen: {
            error: form.errors.isFundingRoundOpen,
            name: `isFundingRoundOpen`,
            type: 'YesNo',
            value: form.values.isFundingRoundOpen,
            onChange: onChangeFundingRoundOpen
        },
        seekingHowMuch: {
            error: form.errors.seekingHowMuch,
            label: props.t('StartupStep5Form.seekingHowMuch'),
            name: `seekingHowMuch`,
            type: 'select',
            options: conv.seekingHowMuch,
            value: form.values.seekingHowMuch,
            onChange: form.handleChange,
            onlyIf: form.values.isFundingRoundOpen === 'true'
        }
    };

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const lastFiveYears = [];

    for (let i = currentYear; i > currentYear - 5; i -= 1) {
        lastFiveYears.push(i);
    }

    const formProps = { ...props, form };
    return (
        <Styled.StartupStep5Form>
            <h2>{props.t('StartupStep5Form.subtitle')}</h2>
            <h3>{props.t('StartupStep5Form.investmentDataUpdate')}</h3>
            <div className="data">
                <p>{props.t('StartupStep5Form.openFundingRound')}</p>
                <Form.Field {...fields.isFundingRoundOpen} />
                <Form.Field {...fields.seekingHowMuch} />
            </div>
            <div className="data">
                <h3>{props.t('StartupStep5Form.growingDataUpdate')}</h3>
                {lastFiveYears.map(year => {
                    return <GrowingBusinessData key={year} {...formProps} year={year} />;
                })}
            </div>
        </Styled.StartupStep5Form>
    );
};
