import styled from 'styled-components';
import { positions } from 'utils/styles';

export const CXISupport = styled.div.attrs({
    className: 'container',
})`
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;
    z-index: 10;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .component-page-container {
        max-width: 768px !important;
    }

    .component-list {
        margin-top: 1.5rem;
    }

    .tab-view {
        padding: 2.4rem 0 0;
    }
    .tab-view[name='video'] {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        p {
            margin: 0;
        }
    }

    .iframe-video {
        position: relative;
        padding-top: 56%;

        iframe {
            border: 0;
            position: absolute;
            ${positions(0, 0, 0, 0)}
            height: 100%;
            width: 100%;
        }
    }

    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444;

        .icon.fa-whatsapp {
            color: #25d366;
        }
        .icon.fa-facebook-messenger {
            color: #0084ff;
        }
        .icon.fa-envelope-open,
        .icon.fa-envelope-open ~ * {
            color: var(--primary);
        }
    }
`;
