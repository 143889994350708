import styled from 'styled-components';

export const Button = styled.div.attrs(props => {
    const active = props.active ? 'active' : '';
    return { className: `c-tabs-button ${active}` };
})`
    align-itens: center;
    border-radius: 0.6rem;
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    font-size: 1.2rem;
    font-weight: 600;
    justify-content: flex-start;
    padding: 1rem 1.6rem;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: 768px) {
        justify-content: center;
    }

    &.active {
        background: ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const Tab = styled.div.attrs(props => {
    const active = props.active ? 'active' : '';
    return { className: `c-tabs-tab ${active}` };
})``;
