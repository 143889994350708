import React from 'react';

import * as Styled from './StyledPageBoxed';

export const PageBoxedHTML = props => (
    <Styled.PageBoxed {...props}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: props.html }} />
    </Styled.PageBoxed>
);
export const BoxedHTML = PageBoxedHTML;
export default PageBoxedHTML;
