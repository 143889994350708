import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { MetricItem } from './MetricItem';

import * as Styled from './StyledMetrics';

export const MetricsStage1 = () => {
    const { t, vars } = useCXI();
    const { cxi: data, stats } = vars;
    const { stage1 } = vars.cxi.metrics;

    const metric1 = String(stats?.suggestions || 0).padStart(2, '0');
    const metric2 = String(data.metrics.matches || 0).padStart(2, '0');
    const metric3 = String(stage1.feedbacksPositive || 0).padStart(2, '0');

    return (
        <Styled.MetricsItems className="stage-1">
            <MetricItem
                icon="fa-comment-alt-smile"
                numb={t('home.Metrics.suggestions.number', { number: metric1 })}
                text={t('home.Metrics.suggestions.text')}
                tooltip={t('home.Metrics.suggestions.tooltip')}
            />
            <MetricItem
                icon="fa-star"
                numb={t('home.Metrics.matches.number', { number: metric2 })}
                text={t('home.Metrics.matches.text')}
                tooltip={t('home.Metrics.matches.tooltip')}
            />
            <MetricItem
                icon="fa-thumbs-up"
                numb={t('home.Metrics.feedbacksPositive.number', { number: metric3 })}
                text={t('home.Metrics.feedbacksPositive.text')}
                tooltip={t('home.Metrics.feedbacksPositive.tooltip')}
            />
        </Styled.MetricsItems>
    );
};
