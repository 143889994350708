import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const MatchStartup = styled.button.attrs({
    className: 'c-match-startup c-match-item',
})`
    align-items: center;
    background: transparent;
    border: 0;
    display: flex;
    gap: 1.6rem;
    padding: 0;
    text-align: left;
`;

export const Image = styled.div.attrs({
    className: 'conversation-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 2px solid #ddd;
    border-radius: 50%;
    flex: 0 0 6.8rem;
    height: 6.8rem;
    padding: 0.8rem;
    position: relative;
    width: 6.8rem;
`;

export const Title = styled.span`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.3;
    ${truncate(2, 1.3)}

    small {
        display: block;
    }
`;
