import React from 'react';
import { useLocation, useParams } from 'react-router';

import { RHP } from 'providers';

import * as Styled from './StyledNavigation';

export const NavigationPage = ({ children, path }) => {
    const { companyId } = useParams();
    const location = useLocation();

    const route = `/100-10-1/dashboard/${companyId}${path}`;
    const active = location.pathname.includes(route) ? 'active' : '';

    const handleClick = () => RHP.push(route);

    return (
        <Styled.NavigationPage className={active} onClick={handleClick}>
            {children}
        </Styled.NavigationPage>
    );
};

export const Page = NavigationPage;
