import styled from 'styled-components';

export const CXIMessagesMatch = styled.div`
    flex-direction: column;
    display: flex;
    height: 100%;
    padding-bottom: 3.4rem;
    // height: calc(100vh - 80px - 3.2rem);

    // @media (min-width: 992px) {
    //     height: calc(100vh - 80px - 6.4rem);
    // }
`;

export const CXIMessagesMatchForm = styled.div`
    align-items: stretch;
    background: #fff;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    gap: 1.6rem;
    padding: 1.6rem;
    position: sticky;
    bottom: calc(5.2rem + var(--ion-safe-area-bottom));

    @media (min-width: 992px) {
        bottom: 0;
    }

    .component-form-field {
        flex: 1 1 auto;
        margin: 0;

        .form-control {
            border-radius: 0.8rem;
            height: 4.4rem;
            padding: 0.7rem 1rem !important;
        }
    }
    .form-submit-holder {
        padding: 0;
        margin-top: -10px;
    }
    .form-submit {
        border-radius: 0.8rem;
        min-width: 4.4rem;
        justify-content: center;
        .text {
            display: none;
        }
        .icon {
            margin-left: 0;
        }
    }
`;

export const CXIMessagesMatchScroll = styled.div`
    // align-items: stretch;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    // justify-content: flex-end;
    overflow-y: scroll;
`;

export const CXIMessagesMatchScrollEnd = styled.div``;
