import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const ProfileCard = styled.div`
    align-items: stretch;
    background: #fff;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    padding: 3.2rem;
    position: relative;

    @media (min-width: 992px) {
        flex-direction: row;
        gap: 3.2rem;
    }

    .user-avatar {
        flex: 0 0 auto;
        width: 13rem;
    }

    .st,
    .nd {
        display: flex;
        flex: 1 1 auto;
        align-items: baseline;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        @media (min-width: 992px) {
            margin-top: 0;
            .spacer {
                margin-top: auto;
            }
        }
    }
    .nd {
        @media (min-width: 992px) {
            align-self: flex-end;
            // margin-top: 48px;
        }
    }
    .nd button {
        margin-top: 1.6rem;
        margin-left: auto;

        @media (min-width: 578px) {
            margin-top: 0;
            position: absolute;
            ${positions('2.4rem', '2.4rem', 'auto', 'auto')}
        }
    }
    .nameBadges {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .profileColumn {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;
    }

    .badges-pt {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px auto 10px auto;
    }
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.6rem;
    line-height: 1;
    margin: 1.6rem 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (min-width: 992px) {
        margin: 0;
    }
`;

export const Descr = styled.h2`
    color: ${({ theme }) => theme.colors.darkBlue};
    opacity: 0.5;
    margin: -0.3rem 0 1rem;
    font-size: 1.6rem;
`;

export const IconWithText = styled.p`
    align-items: center;
    color: ${({ theme }) => theme.colors.darkGray};
    display: inline-flex;
    gap: 0.8rem;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0;

    .icon {
        color: ${({ theme }) => theme.colors.energyBlue};
    }
    .text {
        line-height: 1.3;
        ${truncate(1, 1.3)}
    }
`;

export const PocBadge = styled.span`
    border-radius: 0px 7.26837px;
    padding: 3px 0 3px 0;
    color: white;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    display: flex;
    font-size: 12px;
    line-height: 13px;
    flex-wrap: nowrap;
    padding: 4px 8px;

    &.hubPoc {
        background-color: #0094f9;
    }
    &.rankingPoc {
        background-color: #290493;
    }
    &.cxiPoc {
        background-color: #279db4;
    }
`;
