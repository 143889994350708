import styled from 'styled-components';

export const Container = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGray};
`;
