import React from 'react';
import * as Styled from './StyledFormInput';

export const FormInputTextarea = props =>
    // Don't show this element if it is empty
    !props.input ? null : (
        <Styled.FormInput name="textarea">
            {props.children}
            <div className="fake-control" aria-hidden="true">
                <pre className="text-control form-control">
                    {props.input.value || props.input.placeholder}
                </pre>
                <textarea className="form-control" {...props.input} />
            </div>
        </Styled.FormInput>
    );
export const textarea = FormInputTextarea;
export default FormInputTextarea;
