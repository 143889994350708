import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as CXI from './index';

export const routes = [
    {
        exact: false,
        path: '/startup/:startupId/cxi/feedback',
        component: CXI.CXIFeedback
    },

    {
        exact: true,
        path: '/startup/:startupId/100-10-1',
        component: CXI.CXIIndex
    },
    // ------------------------- VERIFICAR ------------------------------
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/dashboard',
        component: () => <>Dashboard</>
    },
    // ------------------------- VERIFICAR ------------------------------

    {
        exact: false,
        path: '/startup/:startupId/100-10-1/feedback',
        component: CXI.CXIFeedback
    },
    {
        exact: false,
        path: '/startup/:startupId/100-10-1/feedbacks',
        component: CXI.CXIFeedbacks
    },
    {
        exact: false,
        path: '/startup/:startupId/100-10-1/mentoring',
        component: CXI.CXIMentoring
    },
    {
        exact: true,
        path: '/startup/:startupId/100-10-1/messages/:matchId',
        component: CXI.CXIMessages
    },
    {
        exact: false,
        path: '/startup/:startupId/100-10-1/messages',
        component: CXI.CXIMessages
    }
];

export const StartupCXIRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default StartupCXIRoutes;
