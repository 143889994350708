import styled from 'styled-components';

export const Step4 = styled.div`
    min-height: 100%;
    position: relative;

    input {
        height: 20px;
        align-items: center;
        border-radius: 8px;
        margin-top: 4px;
        margin-right: 10px;
        border: 1px solid #ccc;
        padding: 0.5rem;
    }

    label {
        display: flex;
        flex-direction: row;
        font-weight: 500;
        width: 100%;
        align-items: center;
        font-size: 12px;
    }

    .legend-text {
        font-size: 10px;
        font-weight: normal;
        color: #66696e;
    }

    .legend-link {
        font-weight: normal;
        color: #0094f9;
    }
`;

export const FieldPart = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    display: flex;
    color: #0094f9;
`;

export const Project = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    display: flex;
    color: #fff;
    background-color: #0094f9;
    padding: 5px;
    border-radius: 5px;
    margin-left: 50px;
`;

export const Subtitle = styled.h1`
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    p {
        color: #0094f9;
        font-weight: 600;
        font-size: 1.4rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;

export const Block = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(37, 40, 48, 0.24);
    border-radius: 8px;
    width: 100%;
    padding: 14px;
    font-size: 12px;
    margin-bottom: 10px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
`;

export const Questions = styled.h1`
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;
