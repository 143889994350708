import styled from 'styled-components';

export const InvestorIndex = styled.div.attrs({
    className: 'styled-investor-index',
})`
    .component-page-container {
        text-align: center;
    }

    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444444;
    }
`;
