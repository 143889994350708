import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONFIG } from 'config';

import * as Alert from 'components/alert';

export function StartupRegistrationInvite(props) {
    const { t } = useTranslation('home');

    const { data } = props;
    const propsAlert = {
        // Show texts informations
        title: t('StartupsStatusNone.title', { startup: data.name }),
        descr: t('StartupsStatusNone.descr'),
        // Build the button
        buttn: {
            href: `/iframe/${encodeURIComponent(
                `${CONFIG.appLinks.startup}/registerstartup/?startupId=${data._id}&lang=pt&back=/home/?startupId=${data._id}`
            )}`,
            text: t('StartupsStatusNone.button'),
        },
        // Setup color and presentation
        type: 'warning',
    };

    return <Alert.Alert {...propsAlert} />;
}

export default StartupRegistrationInvite;
