import React from 'react';

import { formatDate } from 'utils/date';

import { useCXIDashboardStartup } from '../useCXIDashboardStartup';

import * as Styled from './StyledCXIDashboardStartupSummaries';

export const CXIDashboardStartupSummariesResearcher = () => {
    const { t, vars } = useCXIDashboardStartup();
    const { startup } = vars;

    const data = startup.demographics.researcher;
    const birthdate = data?.birthdate ? formatDate(new Date(data?.birthdate), 'dd/MM/yyyy') : '-';

    return (
        <Styled.CXIDashboardStartupSummariesResearcher>
            {!!data.name && (
                <>
                    <dt>{t('startup.name')}</dt>
                    <dd>{data.name}</dd>
                </>
            )}

            {!!data.birthdate && (
                <>
                    <dt>{t('startup.birthdate')}</dt>
                    <dd>{birthdate}</dd>
                </>
            )}

            {!!data.profile && (
                <>
                    <dt>{t('startup.profile')}</dt>
                    <dd>
                        <a href={data.profile} target="_blank" rel="noopener noreferrer">
                            {data.profile}
                        </a>
                    </dd>
                </>
            )}
        </Styled.CXIDashboardStartupSummariesResearcher>
    );
};
