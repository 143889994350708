import styled from 'styled-components';

export const SpeedDatingForm = styled.div`
    .form-control-top-label {
        color: #0094f9;
        font-weight: 700;
    }
`;

export const SpeedDatingFormField = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin-top: -0.8rem;
    padding: 1.2rem 1.6rem;

    &:focus-within {
        border-color: ${({ theme }) => theme.colors.energyBlue};
    }

    .component-form-field {
        margin: 0;

        .fake-control {
            min-height: auto;
        }
        .form-control {
            border: 0;
            padding: 0;
        }
        .form-control::placeholder {
            font-style: italic;
        }
        .form-control-label {
            color: #0094f9;
            font-weight: 600;
        }
    }
`;

export const Space = styled.div`
    height: 1.6rem;
    @media (min-width: 992px) {
        height: 2.8rem;
    }
`;

export const Descr = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    gap: 1rem;
    line-height: 1.4;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.2rem;
    font-weight: 700;

    .smll {
        color: ${({ theme }) => theme.colors.neutralDark};
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 600;
        margin-left: 0.8rem;
    }

    & + & {
        margin-top: 3.2rem;
    }
`;
