import styled from 'styled-components';

export const MatchAction = styled.button.attrs({
    className: 'c-match-action c-match-item',
})`
    background: ${({ theme }) => theme.colors.deepBlue};
    border: 0;
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.white};
    padding: 1rem 1.6rem;
    flex: 0 0 auto;
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;

    &.reevaluate {
        background: ${({ theme }) => theme.colors.energyBlue};
        color: ${({ theme }) => theme.colors.white};
    }

    &[disabled] {
        background: ${({ theme }) => theme.colors.lightGray};
        color: ${({ theme }) => theme.colors.black};
        opacity: 0.5;
    }
`;

export const MatchActions = styled.div.attrs({
    className: 'c-match-actions',
})`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: 100%;
`;
