import styled from 'styled-components';

export const CXINetworks = styled.div.attrs({
    className: 'container',
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .is-open & {
        width: calc(100vw - 32rem);
    }
`;

export const CXINetworksRoutes = styled.div`
    margin-top: 3.2rem;
`;
