import styled from 'styled-components';

export const DashboardPageAreas = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin-top: 3.2rem;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`;

export const DashboardPageStage3 = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin-top: 3.2rem;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            text-align: center;
        }
    }
    @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
    }
`;
