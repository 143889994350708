import React from 'react';
import { useTranslation } from 'react-i18next';

import { RankingRoutes } from './RankingRoutes';
import { useRanking } from './useRanking';
import * as Styled from './StyledRanking';

export function Ranking(props) {
    const { t } = useTranslation('ranking');
    const hookd = useRanking();
    return !hookd.ready ? null : (
        <Styled.RankingModule>
            <RankingRoutes t={t} useRanking={hookd} />
        </Styled.RankingModule>
    );
}
export default Ranking;
