import React from 'react';

import { CorporateHubWidget } from 'modules/corporatehub/components/components/call2action/CorporateHubWidget';
import { Cxi } from './cxi/Cxi';
import { Entrepreneur } from './entrepreneur/Entrepreneur';
import { Evaluator } from './evaluator/Evaluator';
import { Investor } from './investor/Investor';
import { Ranking } from './ranking/Ranking';
import { Startup } from './startupRegistrationPending/Startup';
import { UserRedirect } from './userredirect/UserRedirect';

import * as Styled from './StyledCall2Actions';

export const Call2Actions = () => (
    <Styled.Call2Actions>
        <UserRedirect />
        <Cxi />
        <Startup />
        <Ranking />
        <CorporateHubWidget />
        <Investor />
        <Entrepreneur />
        <Evaluator />
    </Styled.Call2Actions>
);
