import React from 'react';

import * as Page from 'components/page';
import * as Form from 'components/form';

import { useConversionOptions } from 'hooks/useConversionTable';

import { GrowingBusinessData } from './growingbusinessdata/GrowingBusinessData';

import * as Styled from './StyledComponents';

export const IncomeSection = props => {
    const { form } = props;

    const convIncome = useConversionOptions(['corpsIncomePercentage']);
    const convSeekingHowMuch = useConversionOptions(['seekingHowMuch'], {
        namespace: 'currencyBRL',
    });

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const lastFiveYears = [];

    for (let i = currentYear; i > currentYear - 5; i -= 1) {
        lastFiveYears.push(i);
    }

    const fields = {
        isStartupActive: {
            error: form.errors.isStartupActive,
            label: props.t('IncomeSection.isStartupActive'),
            name: 'isStartupActive',
            type: 'YesNo',
            value: form.values.isStartupActive,
            onChange: form.handleChange,
        },
        corpsIncomePercentage: {
            error: form.errors.corpsIncomePercentage,
            label: props.t('IncomeSection.corpsIncomePercentage'),
            name: 'corpsIncomePercentage',
            type: 'radio',
            options: convIncome.corpsIncomePercentage,
            value: form.values.corpsIncomePercentage,
            onChange: form.handleChange,
        },
        seekingInvestment: {
            error: form.errors.seekingInvestment,
            label: props.t('IncomeSection.seekingInvestment'),
            name: 'seekingInvestment',
            type: 'YesNo',
            value: form.values.seekingInvestment,
            onChange: form.handleChange,
        },
        seekingHowMuch: {
            error: form.errors.seekingHowMuch,
            label: props.t('IncomeSection.seekingHowMuch'),
            name: `seekingHowMuch`,
            type: 'select',
            options: convSeekingHowMuch.seekingHowMuch,
            value: form.values.seekingHowMuch,
            onlyIf: form.values.seekingInvestment === 'true',
            onChange: form.handleChange,
        },
    };

    const formProps = { ...props, form };

    return (
        <>
            <Page.Descr>{props.t('IncomeSection.title')}</Page.Descr>
            <Form.Field {...fields.isStartupActive} />
            <Styled.Data className="data">
                {lastFiveYears.map(year => {
                    return <GrowingBusinessData key={year} {...formProps} year={year} />;
                })}
            </Styled.Data>
            <Form.Field {...fields.corpsIncomePercentage} />
            <Form.Field {...fields.seekingInvestment} />
            <Form.Field {...fields.seekingHowMuch} />
        </>
    );
};

export default IncomeSection;
