import React from 'react';

import { UserStartups } from 'core/components/user';
import { OiweekWidget } from 'modules/oiweek/components';
import * as Page from 'components/page';

import * as Components from '../components';

export const HomeIndex = props => {
    return (
        <Page.Page name="HomeIndex" back={false}>
            <Components.HomeListBanners />

            <OiweekWidget />

            <Components.Call2Actions />

            <UserStartups />

            <Page.Ruler />

            <Components.HomeListEvents />
            {/* <Components.HomeListNews /> */}

            <Page.Ruler />

            <Components.HomeListActions style={{ marginTop: 0 }} />
        </Page.Page>
    );
};
export default HomeIndex;
