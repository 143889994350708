import React from 'react';
import { useTranslation } from 'react-i18next';

import { CA } from 'contexts';
import * as Alert from 'components/alert';

import { routes } from 'modules/ranking/RankingRoutes';
import * as List from 'components/list';

import * as Styled from './StyledRanking';

export function Ranking() {
    const { scopeAllows } = React.useContext(CA);
    const { t } = useTranslation('home');

    if (!scopeAllows('ranking')) {
        return null;
    }

    // Create the alert element props
    const propsAlert = {
        // Set the image to be shown
        // image: {
        //     src: logoRanking,
        //     alt: t('glossary:Ranking 100 Open Startups'),
        // },
        // Show texts informations
        title: t('Ranking.title'),
        descr: t('Ranking.descr'),
        // Build the button
        // buttn: {
        //     href: '/ranking',
        //     text: t('Ranking.button'),
        // },
        // Setup color and presentation
        type: 'primary'
    };

    const actions = routes
        .filter(r => r.name)
        .map(r => ({
            icon: r.icon,
            text: t(`ranking:menu.${r.name}`),
            href: r.path
        }));

    return (
        <Styled.Ranking as={Alert.Alert} {...propsAlert}>
            <List.List name="RankingIndexList">
                <List.Vertical items={actions} itemKey="href" />
            </List.List>
        </Styled.Ranking>
    );
}
export default Ranking;
