/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { useTranslation } from 'react-i18next';

const conversionTable = {
    // The Array(X) is mapping the number of items in each key
    // see the translation to understard what each key means
    months: [...Array(12).keys()].map(x => ++x),
    currency: [...Array(12).keys()].map(x => ++x),

    // User
    age: [...Array(10).keys()].map(x => ++x),
    gender: [...Array(3).keys()].map(x => ++x),
    ecosystemRole: [...Array(17).keys()].map(x => ++x),

    // Company
    companySize: [...Array(5).keys()].map(x => ++x),
    companyType: [...Array(9).keys()].map(x => ++x),
    // companySector: [...Array(40).keys()].map(x => ++x),
    countries: [...Array(244).keys()].map(x => ++x),

    challenge: [...Array(25).keys()].map(x => ++x),
    community: [...Array(21).keys()].map(x => ++x),
    expertise: [...Array(22).keys()].map(x => ++x),
    industry: [...Array(28).keys()].map(x => ++x),
    innovationType: [...Array(20).keys()].map(x => ++x),
    interestArea: [...Array(76).keys()].map(x => ++x),
    trend: [...Array(71).keys()].map(x => ++x),

    // Startup
    stages: [...Array(4).keys()].map(x => ++x),
    businessType: [...Array(3).keys()].map(x => ++x),
    technologyBase: [...Array(3).keys()].map(x => ++x),
    interestInStartup: [...Array(4).keys()].map(x => ++x),
    xpEvaluating: [...Array(4).keys()].map(x => ++x),
    region: [...Array(7).keys()].map(x => ++x),
    revenues: [...Array(6).keys()].map(x => ++x),
    investedHowMuch: [...Array(7).keys()].map(x => ++x),
    seekingHowMuch: [...Array(7).keys()].map(x => ++x),
    whoInvested: [...Array(8).keys()].map(x => ++x),
    team: [...Array(5).keys()].map(x => ++x),

    language: ['pt', 'en', 'es'],
    internationalStartup: [false, true],

    displayTime: [...Array(5).keys()].map(x => ++x),

    // Challenges

    eventType: [
        'none',
        'Demoday',
        'Speed-Dating',
        'Ranking',
        'Conference',
        'Corporate Challenge',
        'Top Category',
        'Accelerator',
        'Open Angels',
        'Top Companies'
    ],

    // Investor
    investorType: [
        'angel',
        'angelnetwork',
        'accelerator',
        'familyoffice',
        'venturecapital',
        'corporateventure'
    ],
    howCanContribute: [
        'experienceinvestor',
        'experiencesector',
        'experienceentrepreneur',
        'contactnetwork'
    ],

    // Investments
    fundingRange: [...Array(7).keys()].map(x => ++x),

    investmentType: ['Angel', 'Seed', 'Venture', 'Corporate', 'Grant', 'Debt', 'Other'],

    yesNo: [true, false],

    contractType: [
        'none',
        'mentoring',
        'connection',
        'award',
        'coworking',
        'voucher',
        'outlicensing',
        'resources',
        'employee',
        'channels',
        'acceleration',
        'r&d',
        'inlicensing',
        'pilot',
        'supplying',
        'investment200k',
        'acquisition200k'
    ],

    contractValue: [
        'range-0',
        'range-0,0',
        'range-0,2000',
        'range-2000,20000',
        'range-20000,100000',
        'range-100000,200000',
        'range-200000,500000',
        'range-500000,1000000',
        'range-1000000,2000000',
        'range-2000000,5000000',
        'range-5000000'
    ],

    investmentOrRevenues: [
        'range-0,0',
        'range-0,10000',
        'range-10000,30000',
        'range-30000,100000',
        'range-100000,300000',
        'range-300000,1000000',
        'range-1000000,2500000',
        'range-2500000,10000000',
        'range-10000000,20000000',
        'range-20000000'
    ],

    corpInvestmentOrRevenues: [
        'range-0',
        'range-0,20000000',
        'range-20000000,100000000',
        'range-100000000,200000000',
        'range-200000000,1000000000',
        'range-1000000000'
    ],

    // Startup Advanced Form
    startupInterests: [
        'corps',
        'investors',
        'startups',
        'ecosystem',
        'suppliers',
        'talents',
        'cofounders',
        'media'
    ],

    causes: [
        'black',
        'woman',
        'disability',
        'mother',
        'senior',
        'lgbtqia+',
        'diversity',
        'social',
        'environment',
        'mentalhealth',
        'ethics',
        'governance',
        'other'
    ],
    companySector: [
        'Aerospace',
        'Agriculture',
        'Automotive',
        'Biotechnology',
        'Chemical',
        'Civic and Social organization',
        'Construction',
        'Consumer Goods',
        'Cosmetics',
        'Digital',
        'Education',
        'Electricity',
        'Electroelectronics',
        'Entertainment and Arts',
        'Financial Services',
        'Food and Drinks',
        'Health',
        'Industrial Automation',
        'Information Technology and Services',
        'Insurance',
        'Leisure, Travel and Tourism',
        'Logistics and Transportation',
        'Mechanical or industrial engineering',
        'Mining and Metals',
        'Non profit organization',
        'Oil and Gas',
        'Packaging and Containers',
        'Paper and Cellulose',
        'Pharmaceutical',
        'Professional and Commercial Services',
        'Public services',
        'Real Estate',
        'Renewables',
        'Retail, Trade and Distribution Services',
        'Sanitation',
        'Software',
        'Telecommunications',
        'Textile',
        'Others'
    ],

    corpsIncomePercentage: [10, 25, 50, 75, 100],
    corporateHubDisplayTime: [10, 40, 60, 120, 180],

    mergerType: ['merger', 'takeover', 'acquisition'],

    contributionTheme: [
        'team',
        'vision',
        'value',
        'product',
        'market',
        'bussiness',
        'scale',
        'exit',
        'other'
    ],

    startupEngagement: [
        'operationalEfficiency',
        'businessPartnerships',
        'corporateDevelopment',
        'ecosystemDevelopment'
    ],
    objOpportunities: [
        'development',
        'processInnovation',
        'entrepreneurialCulture',
        'strategicInsights',
        'businessSolutions',
        'talentAttraction',
        'newTechnologies',
        'innovationReputation',
        'financialReturn',
        'productInnovation',
        'employeeInnovation'
    ]
};

export function useConversionTable(
    items,
    { namespace = 'conversionTable', params } = { namespace: 'conversionTable' }
) {
    const { t } = useTranslation(namespace);
    const returnTable = {};

    // Find the conversionTable required by function
    items.map(i => {
        returnTable[i] = {};
        const keys = conversionTable[i];
        return keys.map(k => {
            // i18next-extract-disable-next-line
            returnTable[i][k.toString()] = t(`${i}.${k}`, params);
            return returnTable[i][k];
        });
    });

    return returnTable;
}

export function useConversionOptions(
    items,
    { namespace = 'conversionTable', params } = { namespace: 'conversionTable' }
) {
    const convertedTable = useConversionTable(items, { namespace, params });

    const returnTable = {};

    // Format the array to name:value format
    Object.keys(convertedTable).map(i => {
        returnTable[i] = [];
        const keys = Object.keys(convertedTable[i]);
        // Push the formated objects to the array
        return keys.map(k => {
            // return returnTable[i][k];
            return returnTable[i].push({ name: convertedTable[i][k], value: k });
        });
    });

    return returnTable;
}

export function useNewConversionOptions(
    items,
    { namespace = 'conversionTable', params } = { namespace: 'conversionTable' }
) {
    const convertedTable = useConversionTable(items, { namespace, params });

    const returnTable = {};

    // Format the array to name:value format
    Object.keys(convertedTable).map(i => {
        returnTable[i] = [];
        const keys = Object.keys(convertedTable[i]);
        // Push the formated objects to the array
        return keys.map(k => {
            // return returnTable[i][k];
            return returnTable[i].push({ name: convertedTable[i][k], value: `${i}-${k}` });
        });
    });

    return returnTable;
}
