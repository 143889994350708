import React from 'react';
import * as Styled from './StyledSessions';

function RightSession(props) {
    const { data, companyName } = props;

    const subscribersAmountArray = data?.latestChallenges?.map(challenge => {
        return challenge.startups;
    });
    // const orderedData = data?.latestChallenges?.sort((a, b) => b.startups - a.startups);

    const maxValue = subscribersAmountArray ? Math.max(...subscribersAmountArray) : [];
    function barChartWidth(startups) {
        if (startups === 0) {
            return 0;
        }

        return (startups / maxValue) * 100;
    }
    const uniqueStartups = Array.from(
        new Map(data?.latestApplications?.map(item => [item.name, item])).values()
    );
    return (
        <Styled.RightSession>
            <Styled.RightContainer>
                <Styled.Title>
                    <div>
                        <h1>{data?.startupsAppliedTotal}</h1>
                        <h3>Startups Inscritas nos Desafios</h3>
                    </div>
                </Styled.Title>
                <Styled.GraphicsContainer>
                    <Styled.TitleContainer>
                        <Styled.Title>
                            <h2>últimos desafios lançados</h2>
                        </Styled.Title>
                    </Styled.TitleContainer>

                    <Styled.ChallengesContainer>
                        {data?.latestChallenges?.slice(0, 6).map(challenge => {
                            return (
                                <Styled.BarChartContainer
                                    key={challenge.name + challenge.value}
                                    isActive={challenge.eventStatus === 'application'}
                                    chartWidth={`${barChartWidth(challenge.startups)}%`}
                                >
                                    <h1>
                                        {challenge.name.length >= 25
                                            ? `${challenge.name.slice(0, 25)}...`
                                            : `${challenge.name}`}
                                    </h1>
                                    <p>
                                        {challenge.startups > 0 ? challenge.startups : 0}{' '}
                                        inscrito(s)
                                    </p>
                                </Styled.BarChartContainer>
                            );
                        })}
                    </Styled.ChallengesContainer>
                </Styled.GraphicsContainer>
            </Styled.RightContainer>

            <Styled.RightContainer>
                <Styled.Title>
                    <div>
                        <h1>{data?.oiweeksTotal}</h1>
                        <h3>Oiweeks com presença {companyName}</h3>
                    </div>
                </Styled.Title>

                <Styled.GraphicsContainer>
                    <Styled.NewChallengesTitle>
                        <h2>Novas candidaturas a desafios</h2>
                    </Styled.NewChallengesTitle>
                    <Styled.ChallengesApplicationsLogosContainer>
                        <Styled.IndividualLogoContainer>
                            {uniqueStartups?.slice(0, 5).map(startup => {
                                if (!startup.logoUrl || startup.logoUrl.length <= 0) {
                                    return (
                                        <div>
                                            <p key={startup._id}>{startup.alt}</p>;
                                        </div>
                                    );
                                }
                                return (
                                    <div key={startup.logoUrl}>
                                        <img
                                            src={startup.logoUrl}
                                            alt={startup.alt}
                                            key={startup._id}
                                        />
                                    </div>
                                );
                            })}
                        </Styled.IndividualLogoContainer>
                    </Styled.ChallengesApplicationsLogosContainer>
                </Styled.GraphicsContainer>
            </Styled.RightContainer>
        </Styled.RightSession>
    );
}

export default RightSession;
