import React from 'react';

import logo100os from 'assets/images/logo-100os.svg';

import { RHP } from 'providers';

import * as Page from 'components/page';
import * as Styled from './StyledRegisterFinished';

export const RegisterFinished = props => {
    const { vars } = props.useRegister;

    React.useEffect(() => {
        if (vars.returnUrl && vars.returnUrl !== null) {
            RHP.replace(vars.returnUrl);
        }
    }, [vars]);

    return (
        <Styled.RegisterFinished as={Page.Page} name="RegisterFinished" back={false}>
            <Page.Image className="rippled mb-3" width="10rem">
                <img src={logo100os} alt="100 Open Startups" />
            </Page.Image>

            <Page.Title>{props.t('RegisterFinished.title')}</Page.Title>
            <Page.Descr>{props.t('RegisterFinished.descr')}</Page.Descr>

            <Page.Buttn to="/home">
                <span className="text">{props.t('RegisterFinished.editProfileLater')}</span>
                <span className="icon fas fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Page.LWTXT to="/profile">
                <span className="link">{props.t('RegisterFinished.editProfile')}</span>
            </Page.LWTXT>
        </Styled.RegisterFinished>
    );
};
export default RegisterFinished;
