import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const Header = styled.div.attrs({
    className: 'c-header',
})`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.whiteDarker};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 0.2rem;
    // overflow: hidden;
    position: relative;
`;

export const HeaderHead = styled.div.attrs({
    className: 'c-header-head',
})`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    gap: 1.6rem;
    padding: 1.6rem;

    & > .text {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.4;
        ${truncate(1, 1.4)}
    }

    & > .ttip {
        cursor: help;
        font-size: 1.4rem;
        margin-left: -0.8rem;
        position: relative;

        .ttip-icon {
            color: ${({ theme }) => theme.colors.energyBlue};
        }
        .ttip-text {
            background: ${({ theme }) => theme.colors.darkGray};
            border-radius: 0.8rem;
            box-shadow: 0px 2px 12px #34286812;
            color: ${({ theme }) => theme.colors.white};
            display: none;
            font-size: 1.2rem;
            font-style: normal;
            line-height: 1.2;
            padding: 0.8rem 1rem;
            position: absolute;
            ${positions(0, 'auto', 'auto', 0)}
            width: 22rem;
            z-index: 100;
        }

        &:focus,
        &:hover {
            .ttip-text {
                display: block;
            }
        }
    }

    & > .bttn {
        display: flex;
        font-size: 1.2rem;
        gap: 0.8rem;

        .bttn-text {
            font-style: normal;
        }
    }
`;

export const HeaderBody = styled.div.attrs({
    className: 'c-header-body',
})`
    background: ${({ theme }) => theme.colors.white};
    flex: 1 1 auto;
    padding: 1.6rem;
`;

export const HeaderBack = styled.button.attrs({
    className: 'c-header-back',
})`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.deepBlue};
    display: flex;
    flex: 0 0 auto;
    font-size: 1.8rem;
    gap: 0.8rem;
    padding: 0;
    text-align: left;
    transform: translateY(1px);
    -webkit-appearance: none;

    .text {
        font-size: 1.4rem;
        line-height: 1.2;
    }

    @media (min-width: 1200px) {
        display: none;
    }
`;
export const HeaderClose = styled(HeaderBack)`
    display: none;
    // @media (min-width: 1200px) {
    //     display: flex;
    // }
`;

export const HeaderHeadButton = styled.button.attrs({
    className: 'c-header-bttn',
})`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.blue};
    display: flex;
    font-size: 1.4rem;
    font-weight: 600;
    gap: 0.8rem;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;

    @media (min-width: 768px) {
        margin-left: auto;
    }
`;

export const HeaderHeadGeneric = styled.div.attrs({
    className: 'c-header-generic',
})``;
