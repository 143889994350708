import styled from 'styled-components';

export const RankingIndex = styled.div.attrs({
    className: 'styled-ranking-index',
})`
    .component-page-container {
        text-align: center;
    }

    .c-page-image {
        margin-top: 2.2rem;
    }

    .c-page-title {
        font-size: 2.2rem;
    }
    .c-page-descr {
        color: var(--primary);
        font-size: 1.6rem;
        font-weight: 500;
        margin-top: 0;
    }
    .c-page-texts {
        margin-top: 3rem;
    }

    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444;
    }
    .list-vertical-item-link .icon {
        font-size: 1.8rem;
    }
`;
