import styled from 'styled-components';
import { positions } from 'utils/styles';

export const MatchMentoring = styled.div.attrs({
    className: 'c-match-mentoring c-match-item',
})`
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: 3.2rem;
    position: relative;

    .text {
        font-weight: 700;
    }

    .ttip {
        background: ${({ theme }) => theme.colors.darkGray};
        border-radius: 0.8rem;
        box-shadow: 0px 2px 12px #34286812;
        color: ${({ theme }) => theme.colors.white};
        display: none;
        font-size: 1.2rem;
        font-style: normal;
        line-height: 1.2;
        padding: 0.8rem 1rem;
        position: absolute;
        ${positions('100%', 'auto', 'auto', '50%')}
        transform: translate(-50%, 0.8rem);
        width: 16rem;
        z-index: 100;
    }

    &:focus,
    &:hover {
        .ttip {
            display: block;
        }
    }

    &.rejected,
    &.held {
        .icon {
            color: ${({ theme }) => theme.colors.darkBlue};
        }
    }
`;
