import React from 'react';

import * as Form from 'components/form';

export function RankingValidateForm(props) {
    const { form, onSubmit } = props;

    // Create form fields object
    const fields = {
        email: {
            disabled: true,
            error: form.errors.email,
            label: props.t('glossary:workEmail'),
            name: 'email',
            type: 'email',
            value: form.values.email,
            onChange: form.handleChange,
        },
        verificationCode: {
            error: form.errors.verificationCode,
            label: props.t('glossary:verificationCode'),
            name: 'verificationCode',
            type: 'verificationCode',
            value: form.values.verificationCode,
            onChange: form.handleChange,
        },
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="RankingValidateForm" onSubmit={evSubmit}>
            <Form.Field {...fields.email} />
            <Form.Field {...fields.verificationCode} />
            <Form.Submt {...form} text={props.t('glossary:continue')} />
        </Form.Form>
    );
}
export default RankingValidateForm;
