import React from 'react';
import * as Styled from './StyledFormInput';

export function FormInputText(props) {
    // Don't show this element if it is empty
    return !props.input ? null : (
        <Styled.FormInputAbsolute name="text">
            <input className="form-control" {...props.input} />
            {props.children}
        </Styled.FormInputAbsolute>
    );
}
export const text = FormInputText;
export default FormInputText;
