import React from 'react';

import { CG } from 'contexts';
import UserCompanyLogo from 'core/components/company/companyLogo';
import useRankingCorporate from 'modules/ranking/ranking-latam/_corporate/useRankingCorporate';
import * as Styled from './styles';

export function FormCustomCompanyLogo({ readOnly }) {
    const { emitAlert } = React.useContext(CG);
    const { putCompanyLogo } = useRankingCorporate();

    // Manage the image submition state
    const [image, setImage] = React.useState();

    // Create the fileReader
    let fileReader;

    // Update the local state
    function onLoaded(file) {
        const content = file.result || fileReader.result;
        return setImage(content);
    }

    // Once the user selects an image to submit
    async function onChange(file) {
        // Check if the file is an image
        if (file && !file.type.match('image.*')) {
            emitAlert('File is not an image!');
            return null;
        }

        try {
            // Include load listeners on the fileReader
            fileReader = new FileReader();
            fileReader.onloadend = onLoaded;
            fileReader.readAsDataURL(file);
            const formData = new FormData();
            formData.append('logo', file);

            const response = await putCompanyLogo(formData);
            setImage(response.url);
            onLoaded(file);
            // return sendToServer(file);
        } catch (err) {
            return emitAlert(err);
        }
    }

    // Create the input for edition
    const propsInput = {
        name: 'logo',
        accept: 'image/*',
        id: 'form-custom-edit-avatar-input',
        type: 'file',
        onChange: ev => onChange(ev.target.files[0])
    };

    return (
        <Styled.FormCustomEditLogo htmlFor={propsInput.id}>
            <input {...propsInput} disabled={readOnly} />
            <UserCompanyLogo photoUrl={image || undefined} />
            <Styled.IconCamera />
        </Styled.FormCustomEditLogo>
    );
}
export const CompanyLogo = FormCustomCompanyLogo;
export default FormCustomCompanyLogo;
