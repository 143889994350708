import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppTemplate } from './AppTemplate';
import * as Styled from './StyledAppTemplate';

export const AppTemplateError = props => {
    const { t } = useTranslation('app');
    return (
        <AppTemplate name="AppTemplateError">
            <Styled.Icons>
                <span className="icon fad fa-wifi-slash" aria-hidden="true" />
            </Styled.Icons>

            <Styled.Title>{t('AppTemplateError.title')}</Styled.Title>
            <Styled.Descr>{t('AppTemplateError.descr')}</Styled.Descr>

            <Styled.Buttn as="a" href="./">
                <span className="text">{t('AppTemplateError.button')}</span>
                <span className="icon far fa-sync" aria-hidden="true" />
            </Styled.Buttn>
            {/* Remember to allow the user to reload */}
        </AppTemplate>
    );
};
export default AppTemplateError;
