import React from 'react';
import * as Styled from './StyledSessions';
import { NetworkChart } from './NetworkChart';

function LeftSession(props) {
    const { data } = props;

    return (
        <Styled.LeftSession>
            <Styled.Container>
                <Styled.GraphicsContainer id="fluid">
                    <NetworkChart data={data} />
                </Styled.GraphicsContainer>
                <Styled.GraphicsCaption>
                    <Styled.Title>
                        <h1>Conexão CORPNAME - Startups</h1>
                        <h2>(Ranking 2021)</h2>
                    </Styled.Title>
                </Styled.GraphicsCaption>
                <Styled.LegendContainer>
                    <span>
                        <Styled.Circle className="blue" />
                        <Styled.LegendText>Corporação</Styled.LegendText>
                    </span>
                    <span>
                        <Styled.Circle className="green" />
                        <Styled.LegendText>Startup</Styled.LegendText>
                    </span>
                </Styled.LegendContainer>
            </Styled.Container>
        </Styled.LeftSession>
    );
}

export default LeftSession;
