import React from 'react';

import * as Form from 'components/form';

export function LoginEmailForm({ form, state, onChange, onSubmit, t }) {
    // Create form fields object
    const fields = {
        email: {
            autoComplete: 'email',
            autoFocus: true,
            error: form.errors.email,
            label: t('glossary:workEmail'),
            name: 'email',
            type: 'email',
            value: form.values.email,
            onChange: ev => form.handleChange(ev, onChange.email)
        },
        password: {
            autoComplete: 'current-password',
            autoFocus: state.prelogin,
            error: form.errors.password,
            label: t('glossary:password'),
            name: 'password',
            hiddenIf: !state.prelogin,
            required: state.prelogin,
            type: 'password',
            value: form.values.password,
            onChange: form.handleChange
        }
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="LoginEmailForm" onSubmit={evSubmit}>
            <Form.Field {...fields.email} />
            <Form.Field {...fields.password} />
            <Form.Submt {...form} text={t('glossary:continue')} />
        </Form.Form>
    );
}
export default LoginEmailForm;
