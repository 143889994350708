import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { ProfileCompanySelectForm } from './ProfileCompanySelectForm';

export function ProfileCompanySelect(props) {
    const { emitToast } = React.useContext(CG);
    const { vars, putUser } = props.useProfile;

    // Initialize a new form handler
    const form = useForm({
        company: vars.company || {},
    });

    // Handle form submition
    const onSubmit = values => {
        const { company } = values;
        // Do the submition api request
        return putUser({ company })
            .then(() => {
                emitToast(props.t('ProfileCompanySelect.saveSuccess'));
                RHP.push('/profile/company');
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="ProfileCompanySelect" back="/profile/company">
            <Page.Title>{props.t('ProfileCompanySelect.title')}</Page.Title>
            <Page.Descr>{props.t('ProfileCompanySelect.descr')}</Page.Descr>

            <ProfileCompanySelectForm {...propsForm} />
        </Page.Page>
    );
}
export default ProfileCompanySelect;
