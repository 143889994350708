import React from 'react';

import logoOiweek from 'assets/images/logo-oiweek-digital.png';

import * as Page from 'components/page';

import { OiweekCall2Action } from '../components';
import * as Styled from './StyledOiweekIndex';

export const OiweekIndex = props => (
    <Styled.OiweekIndex>
        <Page.Page name="OiweekIndex" back="/home">
            <Page.Image src={logoOiweek} alt="Logotipo Oiweek Digital" />
            <OiweekCall2Action useOiweek={props.useOiweek} />
        </Page.Page>
    </Styled.OiweekIndex>
);

export default OiweekIndex;
