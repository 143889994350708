import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIG } from 'config';
import { iframeLink } from 'core/iframe';

import * as Page from 'components/page';

export const SubscriptionBack = ({ event }) => {
    const { t } = useTranslation('subscription');

    const props = {
        icon: 'long-arrow-left',
        href: '/home',
        text: t('SubscriptionBack.back'),
    };
    if (event && event.stages && event.stages.app) {
        props.icon = 'long-arrow-right';
        props.href = iframeLink(`${CONFIG.appLinks.events}?autocheckin=${event._id}`);
        props.text = t('SubscriptionBack.toEvent');
    }
    return (
        <Page.LWTXT to={props.href}>
            <span className="link">
                {props.href === '/home' && (
                    <span className="ico far fa-long-arrow-left mr-2" aria-hidden="true" />
                )}

                <span className="txt">{props.text}</span>

                {props.href !== '/home' && (
                    <span className="ico far fa-long-arrow-right ml-2" aria-hidden="true" />
                )}
            </span>
        </Page.LWTXT>
    );
};
export default SubscriptionBack;
