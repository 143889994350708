import React from 'react';
import * as Styled from './StyledSessions';
import { GeoChart } from './GeoChart';

function RightSession(props) {
    const relationshipsPageVars = props.data;
    const markers = relationshipsPageVars?.geoChartData.slice();

    const clonedMarkers = markers.map(marker => {
        if (marker.radius < 10) {
            marker.radius = 10;
        }
        return marker.radius;
    });
    const maxRadius = Math.max(...clonedMarkers);
    const minRadius = Math.min(...clonedMarkers);
    const range = maxRadius - minRadius;
    const step = range / 4;
    const firstQuartile = minRadius + step;
    const secondQuartile = minRadius + 2 * step;
    const thirdQuartile = minRadius + 3 * step;
    const scaleRadius = [minRadius, firstQuartile, secondQuartile, thirdQuartile, maxRadius];
    return (
        <Styled.RightSession>
            <Styled.Container>
                <Styled.Title>
                    <h1>Distribuição geográfica das startups contratadas</h1>
                </Styled.Title>
                <Styled.GraphicsContainer>
                    <GeoChart data={markers} />
                </Styled.GraphicsContainer>
                {markers.length > 0 ? (
                    <Styled.ScaleContainer>
                        <h3>Escala</h3>
                        <Styled.ScaleRow>
                            <Styled.ScaleItem>
                                <Styled.CircledSpanListIcon
                                    BgColor="#45aaf2"
                                    // scale={`${scaleRadius[4] + 10}px`}
                                    scale={
                                        scaleRadius[4] < 10
                                            ? `${scaleRadius[4] + 20}px`
                                            : `${scaleRadius[4] + 10}px`
                                    }
                                />
                                <span>
                                    {scaleRadius[4] < 10 ? ('> ', scaleRadius[4]) : scaleRadius[4]}
                                </span>
                            </Styled.ScaleItem>

                            <Styled.ScaleItem>
                                {scaleRadius[3] !== scaleRadius[2] && (
                                    <>
                                        <Styled.CircledSpanListIcon
                                            BgColor="#45aaf2"
                                            scale={`${scaleRadius[3] + 10}px`}
                                        />
                                        {scaleRadius[3]}
                                    </>
                                )}
                            </Styled.ScaleItem>

                            <Styled.ScaleItem>
                                {scaleRadius[2] !== scaleRadius[1] && (
                                    <>
                                        <Styled.CircledSpanListIcon
                                            BgColor="#45aaf2"
                                            scale={`${scaleRadius[2] + 10}px`}
                                        />
                                        {scaleRadius[2]}
                                    </>
                                )}
                            </Styled.ScaleItem>

                            <Styled.ScaleItem>
                                {scaleRadius[0] !== scaleRadius[4] && (
                                    <>
                                        <Styled.CircledSpanListIcon
                                            BgColor="#45aaf2"
                                            scale={`${scaleRadius[0] + 10}px`}
                                        />
                                        {scaleRadius[0]}
                                    </>
                                )}
                            </Styled.ScaleItem>
                        </Styled.ScaleRow>
                    </Styled.ScaleContainer>
                ) : null}
            </Styled.Container>
        </Styled.RightSession>
    );
}

export default RightSession;
