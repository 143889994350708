import React from 'react';

import { CA, CN } from 'contexts';
import { useApi } from 'hooks';
import { RouterHistoryProvider as RHP } from 'providers';
import { useEffectOnce } from 'react-use';

export function Logout() {
    const { state, dispatch } = React.useContext(CA);
    const { notifyState } = React.useContext(CN);
    const api = useApi();

    // Get the user information
    const { refresh_token } = state.auth;
    const { notificationToken } = notifyState;

    useEffectOnce(() => {
        // Only run this if the user is authenticated and has refreshToken
        if (state.authenticated && refresh_token) {
            // Call the api and logout the user
            const { userid } = state.user;
            const payload = { refresh_token, notificationToken };
            api.post(`/users/${userid}/logout`, payload).finally(() => {
                dispatch({ type: 'UNAUTHENTICATE' });
                RHP.push('/login');
            });
        } else {
            // Run the unauthentication dispatch
            dispatch({ type: 'UNAUTHENTICATE' });
        }
    });

    // If the authentication information is not set
    // This can mean the use is not authenticate
    if (!state.authenticated) {
        RHP.push('/login');
        return null;
    }

    // Don't return nothing in this case
    return null;
}
export default Logout;
