import React from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { RegisterCompanyValidateForm } from './RegisterCompanyValidateForm';

export function RegisterCompanyValidate(props) {
    const { vars, putUser } = props.useRegister;

    // Initialize a new form handler
    const form = useForm({
        company: vars.company || {},
    });

    // Handle form submition
    const onSubmit = values => {
        const { company } = values;
        // Do the submition api request
        return putUser({ company })
            .then(() => RHP.push('/register/company'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="RegisterCompanyValidate" back="/register/company/select">
            <Page.Title>{props.t('RegisterCompanyValidate.title')}</Page.Title>
            <Page.Descr>{props.t('RegisterCompanyValidate.descr')}</Page.Descr>

            <RegisterCompanyValidateForm {...propsForm} />
        </Page.Page>
    );
}
export default RegisterCompanyValidate;
