import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as Styled from './StyledPageSubtitle';

export function PageSubtitle(props) {
    const { t } = useTranslation('components');
    const icond = props.icon || 'far fa-plus';

    // Create props to show/hide link
    const propsSubtitle = {
        as: props.href ? Link : undefined,
        to: props.href || undefined,
        className: props.className
    };
    return (
        <Styled.PageSubtitle {...propsSubtitle}>
            {props.text && <span className="text">{props.text}</span>}
            {props.link && (
                <Styled.PageSubtitleButton>
                    <span className="link">{props.link || t('more')}</span>
                    <span className={`icon fa-fw ${icond}`} aria-hidden="true" />
                </Styled.PageSubtitleButton>
            )}
        </Styled.PageSubtitle>
    );
}
export const Subtitle = PageSubtitle;
export default PageSubtitle;
