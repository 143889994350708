import React from 'react';

import { iframeLink } from 'core/iframe';
import logoRanking from 'assets/images/logo-ranking-2022.svg';

import * as List from 'components/list';
import * as Page from 'components/page';

import * as Styled from './StyledRankingIndex';

export const RankingIndexView = props => (
    <Styled.RankingIndex as={Page.Page} name="RankingIndex" back="/home">
        <Page.Image>
            <img src={logoRanking} alt="Logo Ranking 100 Open Startups" width="40%" />
        </Page.Image>

        <Page.Title>{props.t('RankingIndex.defaultTitle')}</Page.Title>
        <Page.Descr>{props.t('RankingIndex.descr')}</Page.Descr>

        <List.List name="RankingIndexList">
            <List.Vertical
                items={props.actions}
                itemKey="href"
             
            />
        </List.List>

        <Page.Texts>{props.t('RankingIndex.about')}</Page.Texts>

        <Page.Buttn
            type="round btn-sm btn-primary"
            to={iframeLink('https://100os.net/ranking-sobre')}
        >
            <span className="text">{props.t('RankingIndex.aboutButton')}</span>
        </Page.Buttn>
    </Styled.RankingIndex>
);
export default RankingIndexView;
