import React from 'react';

import { ContextAuth as CA } from 'contexts';

import { UserAvatar } from '../avatar/UserAvatar';
import * as Styled from './StyledUserProfile';

export function UserProfile(props) {
    const { state } = React.useContext(CA);
    const data = props.data || props.user || state.user;

    // If the user is not authenticated, or not present, return nothing.
    if (!data.userid) return null;

    // Add some information to the user component
    // Pull the job description from the company
    data.fullName = `${data.firstName} ${data.lastName ? data.lastName : ''}`;
    data.description = undefined || data.email;
    return (
        <Styled.UserProfile>
            <UserAvatar user={data} />
            <span className="name">{data.fullName}</span>
            <span className="desc">{data.description}</span>
        </Styled.UserProfile>
    );
}
export default UserProfile;
