import React from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIG } from 'config';
import * as Alert from 'components/alert';
import { useLocation } from 'react-router';
import { iframeLink } from 'core/iframe/IframeLink';

export function RegisterTech() {
    const { t } = useTranslation('subscription');

    const location = useLocation();

    const href = iframeLink(`${CONFIG.appLinks.startup}?typeOfProject=tech`, location.pathname);

    // Create the alert element props
    const propsAlert = {
        // Show texts informations
        title: t('RegisterTech.title'),
        descr: t('RegisterTech.descr'),
        // Build the button
        buttn: {
            href,
            text: t('RegisterTech.button'),
        },
        // Setup color and presentation
        type: 'warning',
    };
    return <Alert.Alert {...propsAlert} />;
}
export default RegisterTech;
