import countryCodes from 'assets/data/country-codes.json';

// Generate the currency list
export function currencyList() {
    const countryArr = Object.keys(countryCodes); // Array of countries
    const currencyArr = [];
    const currencyObj = {};

    // Get the main currency codes from the coutry list
    countryArr.forEach(code => {
        const country = countryCodes[code]; // Object of country
        if (country && country.currencyCode) {
            currencyObj[country.currencyCode] = country.currencyName;
        }
    });
    // Convert the object with the currencies with an sorted array
    const buildArray = currency => {
        const obj = {
            code: currency,
            name: currencyObj[currency],
            text: `${currency}`,
        };
        currencyArr.push(obj);
    };
    const compareNames = (a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    };
    Object.keys(currencyObj)
        .sort(compareNames)
        .forEach(buildArray);

    return currencyArr;
}
