import React from 'react';
import { Redirect, Route as ReactRoute, Switch } from 'react-router-dom';

import { CA, CG } from 'contexts';

import { useTranslation } from 'react-i18next';
import * as Ranking from './index';

export const routes = [
    {
        exact: true,
        path: '/ranking/latam/corporate',
        component: Ranking.CorporateIndex
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/editPocs',
        component: Ranking.EditPocs
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/editPocs/add',
        component: Ranking.EditPocsAdd
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/step1',
        component: Ranking.CorporateStep1
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/step2',
        component: Ranking.CorporateStep2
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/step3',
        component: Ranking.CorporateStep3
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/step3/add',
        component: Ranking.CorporateStep3Add
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/step3/edit/:id',
        component: Ranking.CorporateStep3Edit
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/step4',
        component: Ranking.CorporateStep4
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/step4/add',
        component: Ranking.CorporateStep4Add
    },
    {
        exact: true,
        path: '/ranking/latam/corporate/step5',
        component: Ranking.CorporateStep5
    }
];

const RouteHasCompany = props => {
    const { t } = useTranslation('ranking');
    const { user } = React.useContext(CA).state;

    const { emitAlert } = React.useContext(CG);
    const isCompanyUser = user?.profile?.company.id;

    if (!user.company || (!user?.company?._id && user?.company?.type === 3) || !isCompanyUser) {
        const propsRedir = { pathname: '/ranking' };
        const alertText = isCompanyUser ? 'corporateNotAllowed' : 'corporateEmailOnly';
        emitAlert(t(alertText));
        return <ReactRoute component={() => <Redirect to={propsRedir} />} />;
    }
    return <ReactRoute {...props} />;
};

export const RankingCorporateRoutes = props => (
    <Switch>
        {routes.map(route => {
            const Component = route.component;
            const routeProp = { ...route, component: undefined };
            const Route = RouteHasCompany;
            return (
                <Route key={route.path} {...routeProp} render={() => <Component {...props} />} />
            );
        })}
    </Switch>
);
export default RankingCorporateRoutes;
