import React from 'react';

import * as Match from '../match';
import * as Styled from './StyledMatches';

export const Matches = ({ matches, t }) => (
    <Styled.Matches>
        <Styled.MatchesThead>
            <Styled.MatchesTr>
                <Styled.MatchesTh>{t('mentoring.Matches.evaluator')}</Styled.MatchesTh>
                <Styled.MatchesTh>{t('mentoring.Matches.received')}</Styled.MatchesTh>
                <Styled.MatchesTh>{t('mentoring.Matches.status')}</Styled.MatchesTh>
                <Styled.MatchesTh>{t('mentoring.Matches.action')}</Styled.MatchesTh>
            </Styled.MatchesTr>
        </Styled.MatchesThead>

        <Styled.MatchesTbody>
            {matches.map(match => (
                <Styled.MatchesTr key={match.matchid}>
                    <Styled.MatchesTd>
                        <Match.MatchEvaluator match={match} />
                    </Styled.MatchesTd>

                    <Styled.MatchesTd>
                        <Match.MatchReceived match={match} />
                    </Styled.MatchesTd>

                    <Styled.MatchesTd>
                        <Match.MatchStatus match={match} />
                    </Styled.MatchesTd>

                    <Styled.MatchesTd>
                        <Match.MatchAction match={match} />
                    </Styled.MatchesTd>
                </Styled.MatchesTr>
            ))}
        </Styled.MatchesTbody>
    </Styled.Matches>
);
