import React from 'react';
import { useTranslation } from 'react-i18next';

import { CA, CG } from 'contexts';
import { useApi } from 'hooks';
import * as Styled from './StyledMenuLang';

const Button = props => (
    <Styled.MenuLangButton onClick={props.onClick}>
        <span className="lang">{props.lang}</span>
    </Styled.MenuLangButton>
);

export function MenuLang(props) {
    const { i18n } = useTranslation();
    const { language } = i18n;
    const api = useApi();

    // Get the global state for language handling
    const { user } = React.useContext(CA).state;
    const { dispatch } = React.useContext(CG);

    // Function to change the language
    const change = lang => {
        if (user && user.userid) {
            api.put(`/users/${user.userid}`, { languages: [language] });
        }
        i18n.changeLanguage(lang);
        dispatch({ type: 'LANGUAGE', language: lang });
    };

    return (
        <Styled.MenuLang>
            {language !== 'en' && <Button lang="en" onClick={() => change('en')} />}
            {language !== 'pt' && <Button lang="pt" onClick={() => change('pt')} />}
            {language !== 'es' && <Button lang="es" onClick={() => change('es')} />}
        </Styled.MenuLang>
    );
}
export default MenuLang;
