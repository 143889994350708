import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'modules/cxi/components/button';
import { useStartupCXI } from '../useStartupCXI';

import { Container } from './container';
import * as Styled from './StyledStartupCXIModal';

export const StartupCXIModalError = () => {
    const { t } = useTranslation('cxi');
    const { setModal } = useStartupCXI();

    return (
        <Container.Slim>
            <Container.Body>
                <Styled.StartupCXIModalErrorTitle>
                    {t('modal.CXIModalError.title')}
                </Styled.StartupCXIModalErrorTitle>
                <Styled.StartupCXIModalErrorDescr>
                    {t('modal.CXIModalError.description')}
                </Styled.StartupCXIModalErrorDescr>
            </Container.Body>

            <Container.Feet>
                <Button variant="link" onClick={() => setModal(null)}>
                    <span className="text">{t('modal.close')}</span>
                </Button>
            </Container.Feet>
        </Container.Slim>
    );
};
