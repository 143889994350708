import React from 'react';

import * as Step from '../components/step';
import { BusinessTypes } from '../forms/BusinessTypes';
import { Stages } from '../forms/Stages';
import { TechnologyBases } from '../forms/TechnologyBases';

export const CXIStep5 = () => (
    <Step.Step currentStep={5}>
        <Stages />
        <BusinessTypes />
        <TechnologyBases />
    </Step.Step>
);
