import React from 'react';

import { formatDate } from 'utils/date';

import logo from 'assets/images/logo-100-10-1.png';
import signature from '../assets/signature.png';
import * as Styled from './StyledCXICertificationCertificate';

type TBelt = {
    color: string;
    img: string;
    name: string;
    text: string;
    certifiedAt: Date;
};

interface ICertificationPage {
    belt: TBelt;
    userName: string;
    t: any;
    certificateRef: React.MutableRefObject<any>;
}

export const CXICertificationCertificate = ({
    belt,
    userName,
    t,
    certificateRef
}: ICertificationPage) => {
    return (
        <Styled.CXICertificationCertificate className={belt.color} ref={certificateRef}>
            <Styled.Logo>
                <img src={logo} alt="Logo 100-10-1" />
            </Styled.Logo>

            <Styled.BeltName>{belt.name}</Styled.BeltName>

            <Styled.Text>{t('CXICertification.certificate')}</Styled.Text>

            <Styled.UserName>{userName}</Styled.UserName>
            <Styled.UserText>{belt.text}</Styled.UserText>

            <Styled.BeltSeal>
                <img src={belt.img} alt={belt.name} />
            </Styled.BeltSeal>

            <Styled.BeltDate>
                <span className="val">
                    {formatDate(new Date(belt.certifiedAt ?? ''), 'dd/MM/yyyy')}
                </span>
                <span className="lbl">{t('CXICertification.date')}</span>
                <span className="lbl-sm">&nbsp;</span>
            </Styled.BeltDate>

            <Styled.Signature>
                <img className="val" src={signature} alt="assinatura" width={200} />
                <span className="lbl notranslate">Bruno Rondani</span>
                <span className="lbl-sm notranslate">CEO 100 Open Startups</span>
            </Styled.Signature>

            <svg
                className="el-1"
                width="144"
                height="141"
                viewBox="0 0 144 141"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M144 141L144 70L72 70L144 141Z" fill="#DADADA" />
                <path d="M72 71L72 0L3.10351e-06 -3.14616e-06L72 71Z" fill="#DADADA" />
                <path d="M144 71L144 0L72 -3.14616e-06L144 71Z" fill="#DADADA" />
            </svg>

            <svg
                className="el-2"
                width="144"
                height="141"
                viewBox="0 0 144 141"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M144 141L144 70L72 70L144 141Z" fill="#DADADA" />
                <path d="M72 71L72 0L3.10351e-06 -3.14616e-06L72 71Z" fill="#DADADA" />
                <path d="M144 71L144 0L72 -3.14616e-06L144 71Z" fill="#DADADA" />
            </svg>
        </Styled.CXICertificationCertificate>
    );
};
