import { LSP, RHP } from 'providers';

export const iframeLink = (href, exit) => {
    // const { pathname, search, hash } = window.location;
    // const exitRoute = exit || pathname + search + hash;
    LSP.set('100os-iframeExitRoute', exit);
    return { pathname: `/iframe/${encodeURIComponent(href)}`, state: { exit } };
};

export const iframePush = (href, exit = '') => () => {
    // eslint-disable-next-line no-console
    console.log('iframePush', href);
    RHP.push(iframeLink(href, exit));
};

export const iframeReplace = (href, exit = '') => () => {
    // eslint-disable-next-line no-console
    console.log('iframeReplace', href);
    RHP.replace(iframeLink(href, exit));
};
