import React from 'react';

import { useTranslation } from 'react-i18next';
import * as Styled from './StyledComponentTable';

export function ComponentTableRow(props) {
    const { t } = useTranslation('glossary');
    const data = props.data || props.item || undefined;
    return (
        <Styled.ComponentTableRow disabled={data.disabled}>
            <span className="value">{data.value}</span>
            <span className="label">{t(`${data.label}`)}</span>
        </Styled.ComponentTableRow>
    );
}
export const Row = ComponentTableRow;
export const TableRow = ComponentTableRow;
export default ComponentTableRow;
