import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';
import { Header } from 'modules/cxi/components/header/Header';

import { useCXIMentoring } from '../../useCXIMentoring';
import { FilterTag } from '../filter-tag/FilterTag';

import * as Styled from './StyledEvaluations';
import { EvaluationsEvaluation } from './EvaluationsEvaluation';

export const Evaluations = ({ match }) => {
    const { setModal } = useCXI();
    const { t, vars } = useCXIMentoring();
    const { evaluations } = vars;

    const [filterFeedback, setFilterFeedback] = React.useState('');

    const hasFilterFeedback = value => filterFeedback === value;

    const updFilterFeedback = value => {
        if (hasFilterFeedback(value)) {
            return setFilterFeedback('');
        }
        return setFilterFeedback(value);
    };

    const filtered = !filterFeedback
        ? evaluations
        : evaluations.filter(evaluation => {
              if (filterFeedback === evaluation?.startupFeedback) {
                  return true;
              }
              if (filterFeedback === 'pending' && !evaluation?.startupFeedback) {
                  return true;
              }
              return false;
          });

    const buttonContribution = {
        label: t('mentoring.Evaluations.newContribution'),
        onClick: () => setModal({ match, modal: 'Contribution' }),
    };

    return (
        <Styled.Evaluations>
            <Header
                title={t('mentoring.Evaluations.title')}
                tooltip={t('mentoring.Evaluations.tooltip')}
                button={buttonContribution}
            />
            <Header className="filter">
                {t('mentoring.Evaluations.filterFeedback')}
                <FilterTag
                    active={hasFilterFeedback('positive')}
                    onClick={() => updFilterFeedback('positive')}
                >
                    <span className="text">
                        {t('mentoring.Evaluations.startupFeedback.positive')}
                    </span>
                </FilterTag>
                <FilterTag
                    active={hasFilterFeedback('neutral')}
                    onClick={() => updFilterFeedback('neutral')}
                >
                    <span className="text">
                        {t('mentoring.Evaluations.startupFeedback.neutral')}
                    </span>
                </FilterTag>
                <FilterTag
                    active={hasFilterFeedback('negative')}
                    onClick={() => updFilterFeedback('negative')}
                >
                    <span className="text">
                        {t('mentoring.Evaluations.startupFeedback.negative')}
                    </span>
                </FilterTag>
                <FilterTag
                    active={hasFilterFeedback('pending')}
                    onClick={() => updFilterFeedback('pending')}
                >
                    <span className="text">
                        {t('mentoring.Evaluations.startupFeedback.pending')}
                    </span>
                </FilterTag>
            </Header>

            <Styled.EvaluationsList>
                {filtered.length > 0 &&
                    filtered.map(evaluation => (
                        <EvaluationsEvaluation key={evaluation._id} evaluation={evaluation} />
                    ))}
            </Styled.EvaluationsList>
        </Styled.Evaluations>
    );
};
