import styled from 'styled-components';
import { positions } from 'utils/styles';

export const FormFieldset = styled.fieldset.attrs(props => {
    const border = props.border === false ? 'noborder' : '';
    const legend = props.legend === false ? 'nolegend' : '';
    return { className: `form-fieldset ${border} ${legend}` };
})`
    margin: 0;
    position: relative;

    &::before {
        border-top: 1px dashed #ddd;
        border-bottom: 1px dashed #fff;
        content: '';
        display: block;
        margin: 3rem 0;
        position: relative;
        ${positions(0, 'auto', 'auto', '50%')}
        transform: translateX(-50%);
        width: 200%;
    }

    &.noborder {
        margin-top: 0;
        padding-top: 0;

        legend {
            padding-top: 0;
        }
        &::before {
            display: none;
        }
    }
`;

export const FormFieldsetLabel = styled.p.attrs({
    className: 'form-fieldset-label',
})`
    font-size: 1.4rem;
    font-weight: 400;
`;

export const FormFieldsetLegend = styled.legend.attrs({
    className: 'form-fieldset-legend',
})`
    color: var(--secondary);
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    padding-top: 3rem;
    text-transform: uppercase;

    & + .form-field {
        margin-top: 0;
    }
`;
