import React, { createContext } from 'react';

import { useCXIDashboard } from '../useCXIDashboard';

export const CXIDashboardUserContext = createContext();
export const CXIDashboardUserContextProvider = ({ children, userId }) => {
    const { t, vars: dashboard, getUserById, getUserEvaluations } = useCXIDashboard();
    const { users } = dashboard;

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState({ loading: true, userId });
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    React.useEffect(() => {
        if (userId) {
            Promise.all([getUserById(userId), getUserEvaluations(userId)]).then(
                ([user, evaluations]) => {
                    addVars({ loading: false, user, evaluations });
                }
            );
        }
    }, [userId, users]);

    const stateValue = { t, vars, addVars, setVars };
    return vars.loading ? null : (
        <CXIDashboardUserContext.Provider value={stateValue}>
            {children}
        </CXIDashboardUserContext.Provider>
    );
};
