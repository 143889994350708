import styled from 'styled-components';

export const Interests = styled.div`
    margin-top: 4.8rem;
`;

export const InterestsHead = styled.div`
    align-items: center;
    display: flex;
`;

export const InterestsBody = styled.div`
    display: grid;
    gap: 3.2rem;
    margin-top: 3.2rem;

    @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr;
    }
`;

export const InterestsCard = styled.div.attrs(props => ({
    role: 'button',
    'aria-label': props.title,
}))`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    cursor: pointer;
    transition: 0.2s;

    &:focus,
    &:hover {
        transform: scale(1.02);
    }

    h2 {
        align-items: center;
        border-bottom: 1px solid ${({ theme }) => theme.colors.whiteDarker};
        display: flex;
        padding: 1.6rem 2.4rem;

        .icon {
            font-size: 75%;
            margin-left: auto;
            opacity: 0.5;
        }
    }

    ul {
        display: flex;
        gap: 1.2rem;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 1.6rem 2.4rem;
    }
    ul li {
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
        border-radius: 2rem;
        color: ${({ theme }) => theme.colors.lightGray};
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.2;
        padding: 0.6rem 1.2rem;
        width: fit-content;
    }
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 22px;
    margin: 0;
`;
export const Button = styled.button`
    display: none;
    margin-left: auto;
    @media (min-width: 480px) {
        display: inline-flex;
    }
`;
