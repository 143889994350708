import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Home from './index';

export const routes = [
    {
        exact: true,
        path: '/home',
        component: Home.HomeIndex,
    },
];

export const HomeRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default HomeRoutes;
