import { useEffectOnce } from 'react-use';
import { useCXI } from 'modules/cxi/useCXI';

export const Evaluate = () => {
    const { modal, setModal } = useCXI();
    const { match } = modal;

    useEffectOnce(() => {
        // if (match.evaluation && !match.evaluation?.startupFeedback) {
        //     setModal({ match, modal: 'EvaluateCheck' });
        // } else {
        setModal({ match, modal: 'EvaluateForm' });
        // }
    }, []);

    return null;
};
