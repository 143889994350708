import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEvents } from './useEvents';
import { EventsRoutes } from './EventsRoutes';

export function Events(props) {
    const { t } = useTranslation('events');
    const hookd = useEvents();
    return <EventsRoutes t={t} useEvents={hookd} />;
}
export default Events;
