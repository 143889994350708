import React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router';

import { CG } from 'contexts';
import { UserAvatar } from 'core/components/user';
import { RHP } from 'providers';

import { useStartupCXI } from '../../../useStartupCXI';
import * as Styled from './StyledConversation';

export const Conversation = ({ match }) => {
    const { state: global } = React.useContext(CG);
    const location = useLocation();

    const { vars } = useStartupCXI();
    const { startupId } = vars;

    const matchId = location.pathname
        .replace(`/startup/${startupId}/100-10-1/messages`, '')
        .replace('/', '');

    // Moment language
    let lang = global.language;
    if (global.language === 'pt') {
        lang = 'pt-br';
    }
    moment.locale(lang);

    const momentDate = moment(match.lastMessage?.regDate || match.lastUpdated);
    const formatDate = momentDate.startOf('hour').fromNow();

    const handleClick = () => {
        RHP.push(`/startup/${startupId}/100-10-1/messages/${match.matchid}`);
    };

    return (
        <Styled.Conversation
            className={matchId === match.matchid ? 'active' : ''}
            onClick={handleClick}
        >
            <Styled.ConversationImage>
                <UserAvatar user={match.evaluator} />
                {!!match.unreadMessages && <span className="badge">{match.unreadMessages}</span>}
            </Styled.ConversationImage>

            {match.lastMessage?.messageid !== undefined ? (
                <Styled.ConversationTexts>
                    <span className="startup">{match.evaluator.name}</span>
                    <span className="company">{match.evaluator.company}</span>
                    <Styled.ConversationInfos>
                        <span className="date">{formatDate}</span>
                        <span className="icon fas fa-arrow-right" aria-hidden="true" />
                    </Styled.ConversationInfos>
                </Styled.ConversationTexts>
            ) : (
                <Styled.ConversationTexts>
                    <span className="startup multiline">{match.evaluator.name}</span>
                    <span className="company">{match.evaluator.company}</span>
                </Styled.ConversationTexts>
            )}
        </Styled.Conversation>
    );
};
