import React from 'react';

import * as Styled from './StyledMatchEvaluation';

export const MatchEvaluation = ({ match }) => {
    const { group } = match?.evaluation || {};
    return (
        <Styled.MatchEvaluation className={group}>
            <span className="icon" translate="no">
                {group || '-'}
            </span>
        </Styled.MatchEvaluation>
    );
};
