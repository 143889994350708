import React from 'react';
import UserAvatar from 'core/components/user/avatar/UserAvatar';
import * as Styled from './StyledContactCard';

export const ContactCard = ({ contact, t }) => {
    function formatDate(date) {
        const dataString = date;
        const data = new Date(dataString);
        const dia = data.getDate();
        const mes = data.getMonth() + 1;
        const ano = data.getFullYear();
        const formattedDate = `${dia}-${mes}-${ano}`;
        return formattedDate;
    }

    return (
        <Styled.ContactCard>
            <UserAvatar
                user={{
                    photoUrl: contact?.photoUrl,
                    firstName: contact?.name || contact?.company
                }}
            />
            <Styled.CardInfos>
                <div className="card-header">
                    <span className="name">{contact?.name}</span>
                </div>
                <span className="text">{contact?.email}</span>
                <span className="text">{contact?.company}</span>
                <span className="text">
                    {t('GrowUpContactsCard.registerIn')} {formatDate(contact.regDate)}
                </span>
                {contact.email && contact.emailSent && (
                    <span className="text">
                        {t('GrowUpContactsCard.emailSendIn')} {formatDate(contact?.emailSent)}
                    </span>
                )}
            </Styled.CardInfos>
        </Styled.ContactCard>
    );
};

export default ContactCard;
