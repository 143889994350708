import React from 'react';

import * as List from 'components/list';
import * as Tabs from 'components/tabs';

import { useCXI } from '../useCXI';
import * as Styled from './StyledCXISupport';

export const CXISupport = () => {
    const { t } = useCXI();

    // Create the secondary actions options
    const actions = [
        {
            as: 'a',
            icon: 'fab fa-whatsapp',
            href: 'https://wa.me/551135005001',
            text: t('support.whatsapp')
        },
        {
            as: 'a',
            icon: 'fab fa-facebook-messenger',
            href: 'http://www.facebook.com/messages/t/1616610695217236',
            text: t('support.messenger')
        },
        {
            as: 'a',
            icon: `far fa-envelope`,
            href: 'mailto:helpme@openstartups.net',
            text: t('support.email')
        }
    ];

    return (
        <Styled.CXISupport name="CXISupport" back={false}>
            <Tabs.TabsContainer>
                <Tabs.TabsButtons>
                    <Tabs.Tab name="steps">{t('support.tab.steps')}</Tabs.Tab>
                    <Tabs.Tab name="video">{t('support.tab.video')}</Tabs.Tab>
                </Tabs.TabsButtons>

                <div className="tab-view" name="steps">
                    <p>{t('guide.title')}</p>
                    <p>{t('guide.part5')}</p>
                    <p>{t('guide.part6')}</p>
                    <p>{t('guide.part7')}</p>
                    <p>{t('guide.part8')}</p>
                </div>

                <div className="tab-view" name="video">
                    <div className="iframe-video">
                        <iframe
                            src="https://www.youtube-nocookie.com/embed/_Tlp8FWGC9M?controls=0"
                            title="Como começar a contribuir com Startups"
                        />
                    </div>
                    <p>{t('guide.tutorial')}</p>
                </div>
            </Tabs.TabsContainer>

            <List.List name="PagesSupportList">
                <List.Vertical items={actions} itemKey="icon" />
            </List.List>
        </Styled.CXISupport>
    );
};
