import styled, { css } from 'styled-components';
import { positions } from 'utils/styles';

export const ProgressSteps = styled.div`
    align-items: stretch;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    overflow: hidden;
    position: relative;
`;

export const ProgressStepsBars = styled.div`
    align-items: center;
    border-radius: 1.6rem;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    gap: 0.4rem;
    height: 1.6rem;
    overflow: hidden;
`;

export const ProgressStepsBar = styled.div`
    background: ${({ theme }) => theme.colors.lighterGray};

    display: block;
    flex: 1 1 auto;
    height: 1.6rem;
    // margin-top: -0.8rem;
    position: relative;

    &::before {
        content: " ";
        position: absolute;
        ${positions(0, 'auto', 0, 0)}
        ${({ theme }) => css`
            background: transparent
                linear-gradient(89deg, ${theme.colors.energyBlue} 0%, ${theme.colors.deepBlue} 100%);
        `}
        width: ${props => props.width};
    }

    &.cs-0::before {
        background: ${({ theme }) => theme.colors.stage0};
    }
    &.cs-1::before {
        background: ${({ theme }) => theme.colors.stage1};
    }
    &.cs-2::before {
        background: ${({ theme }) => theme.colors.stage2};
    }
    &.cs-3::before {
        background: ${({ theme }) => theme.colors.stage3};
    }
`;

export const ProgressStepsHead = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1.6rem;

    .text {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1;
    }
`;

export const ProgressStepsProg = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    display: flex;
    gap: 1rem;
    margin-top: -0.2rem;
    padding: 0 1.6rem 1.6rem;

    .perc {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1;
        margin-left: auto;
    }
`;

export const ProgressStepsBody = styled.div`
    background: ${({ theme }) => theme.colors.white};
    padding: 1.6rem;
`;
export const ProgressStepsFeet = styled.div`
    background: ${({ theme }) => theme.colors.white};
    padding: 1.6rem;

    .cxi-c-button {
        width: 100%;
    }

    .stage-1 & > .cxi-c-button {
        color: ${({ theme }) => theme.colors.stage1};
    }
    .stage-2 & > .cxi-c-button {
        color: ${({ theme }) => theme.colors.stage2};
    }
    .stage-3 & > .cxi-c-button {
        color: ${({ theme }) => theme.colors.stage3};
    }
`;
