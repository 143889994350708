import React from 'react';
import { useEffectOnce } from 'react-use';

import { RHP } from 'providers';
import * as Alert from 'components/alert';
import * as List from 'components/list';
import * as Modal from 'components/modal';
import * as Page from 'components/page';
import * as Tabs from 'components/tabs';

import { Investment, InvestmentCard } from '../components';

export function StartupStep6(props) {
    const { vars, updateSteps } = props.useRankingStartup;
    const { startupDetails, ranking } = vars;
    const { steps } = vars.application;

    const [modalData, setModalData] = React.useState(false);

    const investments = vars.investments.map(i => {
        const item = { ...i };
        item.onClick = () => setModalData(i);
        return item;
    });

    // Update the step has completed if investments exist
    useEffectOnce(() => {
        // if (vars.investments && vars.investments.length > 0 && steps.step5) {
        if (steps.step5 && !steps.step6) {
            updateSteps({ step6: true });
        }
    });

    // Check for previous step conclusion
    if (!steps || !steps.step5) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    const propsAlert = {
        title: props.t('StartupStep6.noInvestment'),
        descr: props.t('StartupStep6.noInvestmentDescr'),
        icons: 'far fa-exclamation-circle',
        buttn: {
            href: '/ranking/latam/startup/step6/add',
            text: props.t('StartupStep6.addInvestment')
        },
        type: 'warning'
    };

    const pending = investments
        .filter(i => i.status === 'pending')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const rejected = investments
        .filter(i => i.status === 'rejected')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const confirmed = investments
        .filter(i => i.status === 'confirmed')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    return (
        <Page.Page
            name="StartupStep6"
            back="/ranking/latam/startup"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Page.Title>{props.t('StartupStep6.title')}</Page.Title>
            <Page.Descr>
                {startupDetails.investmentProfile.applied
                    ? props.t('StartupStep6.descr2', { rankingYear: ranking.year })
                    : props.t('StartupStep6.descr1', { rankingYear: ranking.year })}
            </Page.Descr>

            {investments
                .filter(i => i.status === 'pending' && i.startup.status === 'pending')
                .slice(0, 1)
                .map(i => {
                    const item = { ...i };
                    item.onClick = undefined;
                    return (
                        <Investment
                            key={`pending-${i._id}`}
                            data={item}
                            useRankingStartup={props.useRankingStartup}
                        />
                    );
                })}
            {!vars.investments.length && <Alert.Alert {...propsAlert} />}

            <Page.Buttn to="/ranking/latam/startup/step6/add" type="linkd btn-block">
                <span className="icon far fa-plus-circle" aria-hidden="true" />
                <span className="text">{props.t('StartupStep6.addInvestment')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Tabs.TabsContainer>
                <Tabs.TabsButtons>
                    <Tabs.Tab name="IS3C">
                        {props.t('glossary:confirmedBy.investor')}{' '}
                        {confirmed.length > 0 && <span>({confirmed.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="IS3P">
                        {props.t('glossary:pendingConfirmation')}{' '}
                        {pending.length > 0 && <span>({pending.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="IS3R">
                        {props.t('glossary:rejectedBy.investor')}{' '}
                        {rejected.length > 0 && <span>({rejected.length})</span>}
                    </Tabs.Tab>
                </Tabs.TabsButtons>

                <List.List name="IS3C">
                    <List.Vertical items={confirmed} itemKey="_id" itemComponent={InvestmentCard} />
                </List.List>
                <List.List name="IS3P">
                    <List.Vertical items={pending} itemKey="_id" itemComponent={InvestmentCard} />
                </List.List>
                <List.List name="IS3R">
                    <List.Vertical items={rejected} itemKey="_id" itemComponent={InvestmentCard} />
                </List.List>
            </Tabs.TabsContainer>

            <div style={{ display: 'flex', margin: '10px 0' }}>
                <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/startup/step5">
                    <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                    <span className="text" style={{ margin: '0 0 0 auto' }}>
                        {props.t('glossary:back')}
                    </span>
                </Page.Buttn>
                <Page.Buttn style={{ marginLeft: '10px' }} to="/ranking/latam/startup/step7">
                    <span className="text">{props.t('glossary:continue')}</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </Page.Buttn>
            </div>

            <Modal.Modal
                close={() => setModalData(false)}
                title={props.t('StartupStep6.investment')}
                isOpen={!!modalData}
            >
                {modalData && (
                    <Investment
                        closeModal={() => setModalData(false)}
                        data={modalData}
                        style={{ marginTop: '1rem' }}
                        useRankingStartup={props.useRankingStartup}
                    />
                )}
            </Modal.Modal>
        </Page.Page>
    );
}
