import styled from 'styled-components';

export const PageDescr = styled.div.attrs({
    className: 'c-page-descr',
})`
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 2rem 0 0;

    .c-page-title + & {
        margin-top: 1rem;
    }
`;

export const PageDiscl = styled.div.attrs({
    className: 'c-page-discl',
})`
    color: #999;
    font-size: 1.2rem;
    line-height: 1.4;
    margin: 2rem 0 0;
`;

export const PageTexts = styled.div.attrs({
    className: 'c-page-texts',
})`
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 2rem 0 0;
`;
