import React from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useConversionTable } from 'hooks';

export function EventDate(props) {
    const { t } = useTranslation('glossary');
    const conv = useConversionTable(['months']);
    const { endDate, startDate } = props;

    // Don't show the date if no startDate is set
    if (!startDate) return null;

    // Create the initial and final date
    const ini = Moment(startDate).utc();
    const end = Moment(endDate).utc();

    const dateObj = {
        dd: String(ini.get('date')).padStart(2, '0'),
        mm: conv.months[ini.get('month') + 1],
    };
    if (end && ini !== end) {
        const iniD = String(ini.get('date')).padStart(2, '0');
        const iniM = String(conv.months[ini.get('month') + 1]);
        const endD = String(end.get('date')).padStart(2, '0');
        const endM = String(conv.months[end.get('month') + 1]);
        if (ini.get('date') !== end.get('date')) {
            dateObj.dd = t('eventDateTo', { date1: iniD, date2: endD });
        }
        if (ini.get('month') !== end.get('month')) {
            dateObj.dd = t('eventDate', { dd: iniD, mm: iniM });
            dateObj.dd = t('eventDateTo', { date1: dateObj.dd, date2: endD });
            dateObj.mm = endM;
        }
    }

    const dateStr = t('eventDate', { dd: dateObj.dd, mm: dateObj.mm });
    return <span className="date">{dateStr}</span>;
}
export default EventDate;
