import React from 'react';

import { useStartupCXI } from 'modules/startup/_startupId/cxi/useStartupCXI';
import * as Styled from './StyledMatchAction';

export const MatchAction = ({ match }) => {
    const { t, setModal } = useStartupCXI();

    const handleClick = () => {
        setModal({ match, modal: 'Mentoring' });
    };

    return (
        <Styled.MatchAction onClick={handleClick}>
            <span className="text">{t('match.action.mentoring')}</span>
        </Styled.MatchAction>
    );
};
