import React from 'react';

import { LoginIndexView } from './LoginIndexView';

export function LoginIndex(props) {
    const { vars } = props.useLogin;

    // Create the secondary actions options
    const actions = [
        {
            icon: 'far fa-plus-circle',
            href: '/register',
            text: props.t('LoginIndex.register'),
        },
        {
            icon: 'far fa-life-ring',
            href: '/support',
            text: props.t('LoginIndex.support'),
        },
    ];

    if (vars.returnUrl) {
        actions[0].href = `/register?returnUrl=${vars.returnUrl}`;
    }

    const propsView = { ...props, actions };
    return <LoginIndexView {...propsView} />;
}
export default LoginIndex;
