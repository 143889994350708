import React from 'react';

import * as List from 'components/list';

import { CXIVideosLink } from './CXIVideosLink';
import * as Styled from './StyledCXIVideos';

export const CXIVideos = () => {
    const videos = [
        {
            id: 0,
            text: 'VA1: Inicio da Jornada',
            href: '',
        },
        {
            id: 1,
            text: 'Video Aula 2',
        },
        {
            id: 2,
            text: 'Video Aula 3',
        },
        {
            id: 3,
            text: 'Video Aula 4',
        },
    ];

    return (
        <Styled.CXIVideos as={List.List}>
            <List.Subtitle text="Calendário de aulas" />
            <List.Vertical items={videos} itemKey="id" itemComponent={CXIVideosLink} />
        </Styled.CXIVideos>
    );
};
export default CXIVideos;
