import React from 'react';

import { UserAvatar } from 'core/components/user';
import * as Styled from './StyledLoading';

export const Loading = ({ children }) => (
    <Styled.Loading>
        <UserAvatar />
        <div>{children}</div>
    </Styled.Loading>
);
