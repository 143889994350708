import styled from 'styled-components';

export const Card = styled.div`
    background-color: white;
    padding: 5px;
    border-radius: 5px;
`;

export const Header = styled(Card)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #3b3e45;
    width: 99%;
    gap: 10px;
    margin: 0 auto 0 auto;
`;

export const CXINetworks = styled.div`
    width: 100%;
    height: 100%;

    .is-open & {
        width: calc(100vw - 32rem);
    }
`;

export const CXINetworksRoutes = styled.div`
    margin-top: 3.2rem;
`;

export const CompanyLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 65px;
    height: auto;
    max-height: 50px;
    margin: 0 10px;

    svg {
        width: 100%;
        margin-left: 10px;
    }

    img {
        width: 100%;
        margin-left: 10px;
    }
`;
