import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px auto;
`;

export const Title = styled.h1`
    position: relative;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #45aaf2;
    border-bottom: 2px solid #45aaf2;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    span {
        display: flex;
        justify-content: right;
        width: 40px;
    }

    img {
        width: 14px;
        height: 14px;
    }
`;
export const TitleTooltip = styled.p`
    position: absolute;
    top: 0;
    right: 20px;
    text-align: justify;
    color: black;
    width: 400px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #45aaf2;
    padding: 15px 17px;
    z-index: 100;
    b {
        color: #45aaf2;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 27px;
`;

// CARD

export const Card = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    padding: 13px 0;

    h1 {
        color: #45aaf2;
    }
`;
export const Hr = styled.hr`
    width: 100%;
    background: ${props => props.backgroundColor || 'rgba(0, 0, 0, 0.15)'};
    height: ${props => props.height || '1px'};
`;
export const CardTitle = styled.h1`
    margin: auto;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    color: #45aaf2;

    span {
        display: flex;
        padding-right: 10px;
    }
`;

export const CardTitleTooltip = styled.p`
    position: absolute;
    top: 40px;
    right: 0;
    text-align: justify;
    color: black;
    width: 300px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #45aaf2;
    padding: 15px 17px;
    z-index: 100;
    b {
        color: #45aaf2;
    }
`;
export const Position = styled.div`
    margin: 19px auto;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #444444;
`;

export const ZeroPointsText = styled.p`
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #444444;
    margin-top: 10px;
`;
