import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const RankingItem = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    overflow: hidden;
    padding: 2.4rem;
    transition: all 0.3s ease;

    &.user .user-avatar {
        width: 6.4rem;
    }
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;

    ${truncate(1, 1.2)}
`;

export const Avatar = styled.div`
    position: relative;

    .rank {
        align-items: center;
        background: ${({ theme }) => theme.colors.energyBlue};
        border-radius: 2.4rem;
        color: ${({ theme }) => theme.colors.darkerstBlue};
        display: flex;
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: -0.2px;
        height: 2.4rem;
        justify-content: center;
        min-width: 2.4rem;
        padding: 0 0.6rem;
        position: absolute;
        ${positions('auto', 0, 0, 'auto')}
    }

    .rank-1 & .rank {
        background: #ffb825;
        color: #ca8a07;
    }
    .rank-2 & .rank {
        background: #b2c9d8;
        color: #9ba0a4;
    }
    .rank-3 & .rank {
        background: #ce6900;
        color: #935617;
    }
`;

export const Name = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.2;

    ${truncate(1, 1.2)}
`;

export const Desc = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: -1rem;
    opacity: 0.6;

    ${truncate(1, 1.2)}
`;

export const Score = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.energyBlue};
    border-radius: 1.4rem;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-size: 1.4rem;
    font-weight: 600;
    gap: 0.6rem;
    line-height: 1.2;
    justify-content: center;
    margin-top: auto;
    padding: 0.6rem 1.2rem;
`;
