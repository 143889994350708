import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

import { CA, CG } from 'contexts';

import * as Components from './components';
import * as Styled from './StyledMenu';

const MenuContentArea = props => (
    <Styled.MenuContentArea area={props.area}>
        <Styled.MenuContainer>{props.children}</Styled.MenuContainer>
    </Styled.MenuContentArea>
);

const MenuContentList = props => (
    <MenuContentArea area="list">
        <Components.MenuLang />
        <Components.MenuLogo />
        <Components.MenuListModules />
        <Components.MenuListPages />
    </MenuContentArea>
);

const MenuContentUser = props =>
    !props.auth ? null : (
        <MenuContentArea area="user">
            <Components.MenuUserProfile />
            <Components.MenuUserLogout />
        </MenuContentArea>
    );

const MenuContentWrap = props => {
    const { state } = React.useContext(CG);
    const { menuOpen } = state;
    const contentRef = React.useRef(null);

    const scrollToTop = () => {
        contentRef.current.scrollToTop();
    };
    React.useEffect(() => {
        scrollToTop();
    }, [menuOpen]);

    return (
        <Styled.Menu as={IonPage}>
            <Styled.MenuContent as={IonContent} ref={contentRef} scrollEvents>
                <Styled.MenuContentWrap>{props.children}</Styled.MenuContentWrap>
            </Styled.MenuContent>
        </Styled.Menu>
    );
};

export function Menu() {
    const { state } = React.useContext(CA);
    const auth = state.authenticated;
    return (
        <MenuContentWrap>
            <MenuContentList auth={auth} />
            <MenuContentUser auth={auth} />
        </MenuContentWrap>
    );
}
export default Menu;
