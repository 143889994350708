import React from 'react';
import * as Form from '../../../../components/form';
import { ButtonsForm } from '../components/buttons-form';
import { ProgressBar } from '../components/progress-bar';
import * as Styled from './StyledStep3';

export function Step3Form({ form, onSubmit, t }) {
    const CURRENT_STEP = 3;

    const currentDate = new Date();
    const currentYear = currentDate.getUTCFullYear();

    const currentMonth =
        String(currentDate.getUTCMonth() + 1).length === 1
            ? `0${currentDate.getUTCMonth() + 1}`
            : String(currentDate.getUTCMonth() + 1);

    const currentDay =
        String(currentDate.getUTCDate()).length === 1
            ? `0${currentDate.getUTCDate()}`
            : String(currentDate.getUTCDate());

    const fields = {
        startDate: {
            displayFormat: 'MM-YYYY',
            error: form.errors.startDate,
            maxLength: 10,
            name: 'startDate',
            min: `${String(currentYear)}-${currentMonth}-${currentDay}`,
            max: form.values.endDate || `${String(currentYear + 1)}-${currentMonth}-${currentDay}`,
            required: true,
            type: 'date',
            value: form.values.startDate,
            onChange: form.handleChange
        },
        endDate: {
            displayFormat: 'MM-YYYY',
            error: form.errors.endDate,
            maxLength: 10,
            name: 'endDate',
            min:
                form.values.startDate || `${String(currentYear - 2)}-${currentMonth}-${currentDay}`,
            max: `${String(currentYear + 10)}-${currentMonth}-${currentDay}`,
            required: true,
            type: 'date',
            value: form.values.endDate,
            onChange: form.handleChange
        },
        // references: {
        //     name: 'references',
        //     type: 'generic',
        //     value: form.values.references,
        //     onChange: form.handleChange,
        //     placeholder: t('form.step3.placeholder1')
        // },
        videoUrl: {
            name: 'videoUrl',
            type: 'generic',
            value: form.values.videoUrl,
            onChange: form.handleChange,
            required: false,
            placeholder: t('form.step3.placeholder2')
        },
        // structure: {
        //     name: 'structure',
        //     type: 'YesNo',
        //     value: form.values.structure,
        //     onChange: form.handleChange
        // },
        external_reference: {
            name: 'external_reference',
            type: 'generic',
            value: form.values.external_reference,
            required: false,
            onChange: form.handleChange,
            placeholder: t('form.step3.placeholder3')
        },
        termsUrl: {
            name: 'termsUrl',
            type: 'generic',
            value: form.values.termsUrl,
            required: false,
            onChange: form.handleChange,
            placeholder: t('form.step3.placeholder3')
        }
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Styled.Step3>
            <ProgressBar current={CURRENT_STEP} />

            <Styled.Title>{t('form.step3.title')}</Styled.Title>
            <Styled.Subtitle>{t('form.step3.descr')}</Styled.Subtitle>

            <Form.Form onSubmit={evSubmit}>
                <Styled.Dates>
                    <div className="datesOpened">
                        <Styled.Questions>{t('form.step3.question1')}</Styled.Questions>
                        <Form.Field size="10" {...fields.startDate} />
                    </div>

                    <div className="datesOpened">
                        <Styled.Questions>{t('form.step3.question2')}</Styled.Questions>
                        <Form.Field size="10" {...fields.endDate} />
                    </div>
                </Styled.Dates>

                {/*
                    <Styled.Questions>{t('form.step3.question3')}</Styled.Questions>
                    <Form.Field {...fields.references} />
                */}

                <Styled.Questions>{t('form.step3.question4')}</Styled.Questions>
                <p className="legend-text">{t('form.step3.legend1')}</p>
                <Form.Field {...fields.videoUrl} />

                {/*
                    <Styled.Questions>{t('form.step3.question5')}</Styled.Questions>
                    <p className="legend-text">{t('form.step3.legend2')}</p>
                    <Form.Field {...fields.structure} />
                */}

                <Styled.Questions>{t('form.step3.question6')}</Styled.Questions>
                <Form.Field {...fields.external_reference} />

                <Styled.Questions>{t('form.step3.question7')}</Styled.Questions>
                <Form.Field {...fields.termsUrl} />

                <br />
                {/* BOTÕES FINAIS */}
                <ButtonsForm currentStep={CURRENT_STEP} />
            </Form.Form>
        </Styled.Step3>
    );
}
