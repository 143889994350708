import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const DashboardPage = styled.div.attrs({
    className: 'container'
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header {
        margin-top: 3.2rem;
    }
    .c-header-input {
        border: 1px solid ${({ theme }) => theme.colors.lightGray};
        border-radius: 0.8rem;
        padding: 0.8rem 1.4rem;
        width: 100%;
    }
`;

export const DashboardPageHead = styled.div`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    padding: 0 0 1.6rem;
    justify-content: space-between;

    .blue-text {
        color: #0094f9;
    }

    .wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    h2 {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.4;
    }
    h3 {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.4;
        ${truncate(1, 1.4)}
    }

    & ~ .c-header.filter {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
    }
    & ~ .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
    }
    & ~ .c-header.filter + .c-header.filter {
        margin-top: 0.4rem;
    }
`;

export const DashboardPageSubHead = styled.div`
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-top: 4.8rem;
    padding: 0 0 1.6rem;

    h3 {
        color: ${({ theme }) => theme.colors.darkGray};
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.4;
    }
    h4 {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.4;
        ${truncate(1, 1.4)}
    }
`;
export const DashboardPageWhite = styled.div`
    background-color: white;
    margin-top: 1rem;
    padding: 1.6rem;
    border-radius: 0.8rem;

    .blue-text {
        text-transform: uppercase;
        color: #0094f9;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const DashboardPageBody = styled.div`
    margin-top: 4rem;
    overflow: auto;
`;

export const DashboardPagePhrase = styled.div`
    margin: 6rem auto;
    position: relative;

    @media (min-width: 992px) {
        padding-left: 16rem;
    }

    &::before {
        color: ${({ theme }) => theme.colors.energyBlue};
        content: '“';
        font-size: 12rem;
        font-weight: 700;
        line-height: 1;

        @media (min-width: 992px) {
            position: absolute;
            ${positions('-2rem', 'auto', 'auto', 0)}
            font-size: 24rem;
            top: -2rem;
        }
    }

    p {
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 1.4;
        margin-top: -4.8rem;

        @media (min-width: 992px) {
            font-size: 3.2rem;
            margin-top: 0;
        }
    }
`;

export const Navigation = styled.div`
    background: ${({ theme }) => theme.colors.lighterGray};
    border-radius: 0.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 0.4rem;
    overflow-x: scroll;
    max-width: 100%;
    width: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        gap: 0;
    }

    ::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
`;

export const NavigationPage = styled.div`
    align-itens: center;
    border-radius: 0.6rem;
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    font-size: 1.2rem;
    font-weight: 600;
    justify-content: flex-start;
    padding: 1rem 1.6rem;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: 768px) {
        justify-content: center;
    }

    &.active {
        background: ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
    }
`;
