import styled from 'styled-components';

export const Container = styled.div`
    padding: 2rem 4rem;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    box-shadow: 0px 2px 12px #34286812;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.darkerBlue};
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin-bottom: 24px;
`;

export const FeedbackActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 40px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;
