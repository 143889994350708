import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSubscription } from './useSubscription';
import { SubscriptionRoutes } from './SubscriptionRoutes';

export function Subscription(props) {
    const { t } = useTranslation('subscription');
    const hookd = useSubscription();
    return <SubscriptionRoutes t={t} useSubscription={hookd} />;
}
export default Subscription;
