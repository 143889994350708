import React from 'react';

import { CG } from 'contexts';
import { LSP } from 'providers';
import { useForm } from 'components/form';
import * as Form from 'components/form';

import { useCXI } from 'modules/cxi/useCXI';
import { Button } from 'modules/cxi/components/button';
import { Option } from 'modules/cxi/components/option/Option';

import { Container } from '../container';
import * as Styled from './StyledEvaluateForm';

export const EvaluateForm = () => {
    const { state: global } = React.useContext(CG);
    const { t, modal, setModal, putMatchEvaluation } = useCXI();
    const { match } = modal;

    const form = useForm({
        comment: LSP.get(`${match.matchid}_EVALUATE_FORM_COMMENT`) ?? '',
        group: LSP.get(`${match.matchid}_EVALUATE_FORM_GROUP`) ?? '',
    });

    const fields = {
        comment: {
            error: form.errors.comment,
            name: 'comment',
            type: 'textarea',
            label: t('modal.EvaluateForm.comment.field'),
            placeholder: t('modal.EvaluateForm.comment.placeholder'),
            required: true,
            value: form.values.comment,
            onChange: ev => {
                LSP.set(`${match.matchid}_EVALUATE_FORM_COMMENT`, ev.target.value);
                return form.handleChange(ev);
            },
        },
    };

    const onChangeGroup = group => {
        LSP.set(`${match.matchid}_EVALUATE_FORM_GROUP`, group);
        form.addValues({ group });
    };

    const onSubmit = async values => {
        const { language } = global;
        const commons = { language, isCxi: true, cxiStage: 1 };
        const payload = { ...commons, ...values };
        try {
            await putMatchEvaluation(match.matchid, payload);
            LSP.del(`${match.matchid}_EVALUATE_FORM_COMMENT`);
            LSP.del(`${match.matchid}_EVALUATE_FORM_GROUP`);
            setModal({ match, modal: 'EvaluateSent' });
        } catch (e) {
            form.setSubmit(false);
        }
    };
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    const isSubmitDisabled = form.submit || !form.values.comment || !form.values.group;

    return (
        <Styled.EvaluateForm as={Container}>
            <Form.Form onSubmit={evSubmit}>
                <Container.Head image={match.startup.logoUrl}>
                    <span className="text">{t('modal.EvaluateForm.title')}</span>
                </Container.Head>

                <Container.Body>
                    <Styled.Descr>
                        <span className="text">{t('modal.EvaluateForm.description')}</span>
                    </Styled.Descr>

                    <Styled.Space />

                    <Styled.Title className="form-title">
                        <span className="text">{t('modal.EvaluateForm.group.title')}</span>
                        <span className="smll">{t('modal.EvaluateForm.group.small')}</span>
                    </Styled.Title>

                    <Styled.EvaluateFormOptions>
                        <Option
                            checked={form.values.group === 'A'}
                            label={t('modal.EvaluateForm.group.A')}
                            text="A"
                            onChange={() => onChangeGroup('A')}
                        />
                        <Option
                            checked={form.values.group === 'B'}
                            label={t('modal.EvaluateForm.group.B')}
                            text="B"
                            onChange={() => onChangeGroup('B')}
                        />
                        <Option
                            checked={form.values.group === 'C'}
                            label={t('modal.EvaluateForm.group.C')}
                            text="C"
                            onChange={() => onChangeGroup('C')}
                        />
                        <Option
                            checked={form.values.group === 'D'}
                            label={t('modal.EvaluateForm.group.D')}
                            text="D"
                            onChange={() => onChangeGroup('D')}
                        />
                    </Styled.EvaluateFormOptions>

                    <Styled.Space />

                    <Styled.Title>
                        <span className="text">{t('modal.EvaluateForm.comment.title')}</span>
                    </Styled.Title>

                    <Styled.EvaluateFormField>
                        <Form.Field {...fields.comment} />
                    </Styled.EvaluateFormField>
                </Container.Body>

                <Container.Feet>
                    <Button variant="outline" onClick={() => setModal(null)}>
                        {t('modal.cancel')}
                    </Button>
                    <Button disabled={isSubmitDisabled} type="submit">
                        {t('modal.EvaluateForm.submit')}
                    </Button>
                </Container.Feet>
            </Form.Form>
        </Styled.EvaluateForm>
    );
};
