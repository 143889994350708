import React from 'react';
import { useTranslation } from 'react-i18next';

import { iframeLink } from 'core/iframe';
import * as Alert from 'components/alert';

import * as Styled from './StyledOiweekCall2Action';

export function C2AClosed(props) {
    const { t } = useTranslation('oiweek');

    // Create the alert element props
    const propsAlert = {
        title: t('C2AClosed.title'),
    };
    if (props.knowMoreUrl) {
        propsAlert.buttn = {
            href: iframeLink(props.knowMoreUrl),
            type: 'light',
            text: t('C2AInvite.knowMore'),
        };
    }

    const propsHighlights = {
        descr: t('C2AClosed.descr'),
        buttn: {
            href: iframeLink('https://100os.net/youtube'),
            text: t('C2AClosed.button'),
        },
        type: 'danger',
    };

    return (
        <Styled.C2AClosed as={Alert.Alert} {...propsAlert}>
            <Styled.C2AClosedHighlight as={Alert.Alert} {...propsHighlights} />
        </Styled.C2AClosed>
    );
}
export default C2AClosed;
