import React, { useState } from 'react';
import { stringToKebabCase } from 'utils/conversion';
import useCXI from 'modules/cxi/useCXI';
import * as Styled from './StyledContent';

export const Content = ({ data }) => {
    const { postCxiReadContent } = useCXI();
    const [read, setRead] = useState(data.read);

    const handleClick = () => {
        if (!read) {
            const postReadContent = postCxiReadContent(data?.fields?.Type, data.id);
            setRead(true);
            return postReadContent;
        }
    };

    return (
        <Styled.Content
            className={stringToKebabCase(data.fields.Category)}
            href={data?.fields?.Link?.trim()}
            target="_blank"
            rel="noreferrer noopener"
            onClick={handleClick}
        >
            <Styled.ContentHead>
                <img
                    src={data?.fields?.Image?.[0]?.url}
                    alt=""
                    height={data?.fields?.Image?.[0]?.height}
                    width={data?.fields?.Image?.[0]?.width}
                />
            </Styled.ContentHead>

            <Styled.ContentBody>
                <span className="title">{data.fields?.Title}</span>
                <span className="descr">{data.fields?.Description}</span>
            </Styled.ContentBody>

            <Styled.ContentFeet>
                <span className="badge">{data.fields?.Category}</span>
                {read && (
                    <span className="state">
                        <span className="text">Visualizado</span>
                        <span className="icon far fa-check" />
                    </span>
                )}
            </Styled.ContentFeet>
        </Styled.Content>
    );
};
