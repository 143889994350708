import styled from 'styled-components';

export const MatchConnection = styled.button.attrs({
    className: 'c-match-action c-match-item',
})`
    align-items: center;
    background: ${({ theme }) => theme.colors.deepBlue};
    border: 0;
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    gap: 0.6rem;
    justify-content: center;
    padding: 1rem 1.6rem;
    width: 100%;
    white-space: nowrap;

    &.chat {
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.deepBlue};
    }
    &.sd {
        background: ${({ theme }) => theme.colors.stage2};
        color: ${({ theme }) => theme.colors.white};
    }

    &[disabled] {
        background: ${({ theme }) => theme.colors.lightGray};
        color: ${({ theme }) => theme.colors.black};
        opacity: 0.5;
    }
`;

export const MatchConnections = styled.div.attrs({
    className: 'c-match-actions',
})`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: 100%;
`;
