import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const UserStartups = styled.div.attrs(props => ({
    className: `user-startups ${props.multiple > 1 ? 'multiple' : ''}`,
}))`
    margin-top: 3rem;
    position: relative;

    .c-page-links-with-text {
        margin-top: 1rem;
    }
`;

export const UserStartupsList = styled.div.attrs({
    className: 'user-startups-list',
})`
    background: #fff;
    color: inherit;
    margin-top: 1.6rem;
    padding: 0.5rem 1rem 1rem;
`;
export const UserStartupsListItem = styled.div.attrs({
    className: 'user-startups-list-item',
})`
    cursor: pointer;
    align-items: center;
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(68, 68, 68, 0.2);
    color: #444;
    display: flex;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 1rem 0;
    -webkit-appearance: none;

    &.startup .code {
        color: var(--os-startups);
    }
    &.tech .code {
        color: var(--os-tech);
    }

    &.none {
        opacity: 0.8;
    }
    &.none .code {
        color: var(--ion-color-medium);
    }

    .name {
        margin-left: 1rem;
        margin-right: auto;
    }
    .code {
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        transform: translateY(0.1rem);
    }
`;

export const UserStartupsCard = styled.div.attrs({
    className: 'user-startups-card',
})`
    align-items: center;
    background: #fff;
    border-radius: 8.5rem;
    color: inherit;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
`;

export const CardImage = styled.div.attrs({
    className: 'user-startups-card-image',
})`
    background: #eee no-repeat 50% 50%;
    background-image: url(${props => props.img});
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 6.5rem;
    flex: 0 0 6.5rem;
    height: 6.5rem;
    padding: 0.5rem;
`;

export const CardInfos = styled.div.attrs({
    className: 'user-startups-card-infos',
})`
    flex: 1 1 calc(100% - 6.5rem);
    font-size: 1.4rem;
    margin-left: 1.5rem;
    text-align: left;

    .name {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.4;
        ${truncate(1)}
    }

    .bold {
        display: block;
        font-weight: 600;
        margin-top: 0.5rem;
        ${truncate(1)}
    }
    .code {
        color: var(--primary);
        font-weight: 700;
    }

    .text {
        display: block;
        line-height: 1.2;
        margin-top: 0.5rem;
    }
    .bold + .text {
        margin-top: 0;
        ${truncate(1, 1.2)}
    }
`;

export const ListItemImage = styled.div.attrs({
    className: 'user-startups-list-item-image',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url(${props => props.img});
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 3rem;
    flex: 0 0 3rem;
    height: 3rem;
    padding: 0.25rem;
`;
