import React from 'react';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

import { ContextGlobal as CG } from 'contexts';

import { RegisterRoutes } from './RegisterRoutes';
import { useRegister } from './useRegister';

export function Register(props) {
    const { t } = useTranslation('register');
    const hookd = useRegister();

    // Use contexts for specific functionalities
    const { dispatch } = React.useContext(CG);

    // Run some structural changes
    useEffectOnce(() => {
        dispatch({ type: 'MENU_SHOW', menu: false });
        return () => dispatch({ type: 'MENU_SHOW', menu: true });
    });

    return <RegisterRoutes t={t} useRegister={hookd} />;
}
export default Register;
