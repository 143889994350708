import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { ProfileCompanyValidateForm } from './ProfileCompanyValidateForm';

export function ProfileCompanyValidate(props) {
    const { emitToast } = React.useContext(CG);
    const { vars, putUser } = props.useProfile;

    // Initialize a new form handler
    const form = useForm({
        company: vars.company || {},
    });

    // Handle form submition
    const onSubmit = values => {
        const { company } = values;
        // Do the submition api request
        return putUser({ company })
            .then(() => {
                emitToast(props.t('ProfileCompanyValidate.saveSuccess'));
                RHP.push('/profile/company');
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, form, onSubmit };
    return (
        <Page.Page name="ProfileCompanyValidate" back="/profile/company/select">
            <Page.Title>{props.t('ProfileCompanyValidate.title')}</Page.Title>
            <Page.Descr>{props.t('ProfileCompanyValidate.descr')}</Page.Descr>

            <ProfileCompanyValidateForm {...propsForm} />
        </Page.Page>
    );
}
export default ProfileCompanyValidate;
