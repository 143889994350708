import React from 'react';
import { createRoot } from 'react-dom/client';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import TagManager from 'react-gtm-module';

import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

import 'bootstrap/dist/css/bootstrap.min.css';

// Initialize the translations configuration
import 'config/i18n';
import { App } from 'app/App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

TagManager.initialize({ gtmId: 'GTM-5K6597W' });
ChartJS.register(ArcElement, Tooltip);

createRoot(document.getElementById('root')).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
