import React from 'react';
import * as Styled from './StyledSessions';

function RightSession(props) {
    const { data } = props;
    function formatTable(array) {
        // eslint-disable-next-line prefer-spread
        const tableData = Array.apply(null, Array(10));

        for (let i = 0; i < tableData.length; i++) {
            if (typeof tableData[i] === 'undefined') {
                tableData[i] = array[i];
            }
        }
        return tableData;
    }
    const executives = formatTable(data.cxiRanking);
    const { companyName } = props;

    return (
        <Styled.RightSession>
            <Styled.RightContainer>
                <Styled.GraphicsContainer>
                    <Styled.Title>
                        <h2>
                            <h2>Executivos {companyName} Melhor Avaliados no Programa 100-10-1</h2>
                        </h2>
                    </Styled.Title>
                    <Styled.ExecutiveTableList>
                        <tbody>
                            <tr>
                                <th />
                                <th>Nome</th>
                                <th>Pontos</th>
                            </tr>
                            {executives?.slice(0, 10).map((executive, index) => {
                                const fullname = `${executive?.firstName} ${executive?.lastName}`;
                                return executive !== undefined ? (
                                    <tr key={executive?._id || index}>
                                        <td> {index + 1}º</td>
                                        <td>
                                            <span>
                                                {fullname?.length >= 40
                                                    ? `${fullname?.slice(0, 40)}...`
                                                    : `${fullname}`}
                                            </span>
                                        </td>
                                        <td>{executive?.score}</td>
                                    </tr>
                                ) : (
                                    <tr key={executive?._id || index}>
                                        <td> {index + 1}º</td>
                                        <td>
                                            <span />
                                        </td>
                                        <td>{executive?.score}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Styled.ExecutiveTableList>
                </Styled.GraphicsContainer>
            </Styled.RightContainer>
        </Styled.RightSession>
    );
}

export default RightSession;
