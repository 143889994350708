import React from 'react';
import { getDateXDaysAgo } from 'utils/date';

import * as Styled from './StyledMatchStatus';

export const MatchStatus = ({ match }) => {
    const { history, mentoring } = match;

    // Check if there is older than 5 days ago
    const daysAgo = getDateXDaysAgo(5, new Date());
    const dateMatch = history.mentoringRequestedDate
        ? new Date(history.mentoringRequestedDate)
        : new Date(match.regDate);

    let ttip = '';
    let icon = '';
    if (mentoring === 'requested' && (!dateMatch || dateMatch < daysAgo)) {
        ttip = 'Este pedido não é mais válido, excedeu 5 dias';
        icon = 'fa-history';
    } else if (mentoring === 'requested') {
        ttip = 'Aguardando retorno da startup...';
        icon = 'fa-stopwatch';
    } else if (mentoring === 'rejected') {
        icon = 'fa-times-circle';
    } else if (mentoring === 'accepted') {
        icon = 'fa-check-circle';
    }

    return (
        <Styled.MatchStatus className={mentoring}>
            {!icon ? (
                <span className="text">-</span>
            ) : (
                <span className={`icon far ${icon}`} aria-hidden="true" />
            )}
            {!!ttip && <span className="ttip">{ttip}</span>}
        </Styled.MatchStatus>
    );
};
