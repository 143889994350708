import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const MentoringForm = styled.div`
    text-align: center;

    .m-container-feet {
        align-items: stretch;
        flex-direction: column;
        gap: 1.2rem;
    }
    .m-container-feet .cxi-c-button {
        width: 100%;
    }
`;

export const MentoringInfo = styled.div`
    display: flex;
    font-size: 1.4rem;
    flex-direction: column;
    gap: 0.4rem;

    .name {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-weight: 600;
        line-height: 1.3;
    }
    .company,
    .position {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-weight: 400;
        line-height: 1.3;
        ${truncate(1, 1.3)}
    }
    .company {
        color: ${({ theme }) => theme.colors.black};
        font-weight: 500;
    }

    .date {
        margin-top: 1.2rem;
    }
`;

export const Descr = styled.div`
    color: ${({ theme }) => theme.colors.neutralDark};
    font-size: 1.8rem;
    margin-top: 0.8rem;
`;
