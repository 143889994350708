import { useContext } from 'react';
import { CXIMentoringContext } from './CXIMentoringContext';

export const useCXIMentoring = () => {
    const context = useContext(CXIMentoringContext);
    if (context === undefined) {
        throw new Error('useCXIMentoring must be used within a CXIMentoringContextProvider');
    }
    return context;
};
