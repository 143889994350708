import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const StartupCard = styled.div.attrs({
    className: 'c-startup-card',
})`
    align-items: center;
    background: #fff;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 2.4rem;
    position: sticky;
    text-align: center;

    @media (min-width: 992px) {
        display: grid;
        grid-template:
            'a b' auto
            'c c' auto / 14rem 1fr;
        gap: 3.2rem;
        text-align: left;
    }

    @media (min-width: 1200px) {
        display: grid;
        grid-template: 'a b c' auto / 14rem 1fr auto;
        gap: 3.2rem;
        text-align: left;
    }
`;

export const StartupCardActions = styled.div.attrs({
    className: 'c-startup-actions',
})`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    @media (min-width: 1200px) {
        height: 100%;
    }

    .cxi-c-button {
        position: relative;
        .icon {
            font-size: 120%;
        }
    }
    .cxi-c-button .badge {
        align-items: center;
        background: ${({ theme }) => theme.colors.red};
        border-radius: 9999px;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        font-size: 1rem;
        justify-content: center;
        line-height: 1;
        margin: 0;
        min-width: 1.65rem;
        padding: 0.4rem 0.6rem 0.25rem;
    }
`;

export const StartupCardSocials = styled.div.attrs({
    className: 'c-startup-socials',
})`
    align-items: center;
    display: inline-flex;
    font-size: 2.4rem;
    gap: 1.6rem;
    margin-top: 1.6rem;

    a {
        color: ${({ theme }) => theme.colors.deepBlue};
        text-decoration: none;
    }
    a:focus,
    a:hover {
        color: ${({ theme }) => theme.colors.darkBlue};
    }

    a[title='Website'] {
        align-items: center;
        display: inline-flex;
        font-size: 2.2rem;
        gap: 0.8rem;
        .text {
            display: none;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 1.2;
        }
    }
`;

export const StartupCardColumn = styled.div.attrs({
    className: 'c-startup-card-column',
})`
    &.image {
        grid-area: a;
        @media (min-width: 1200px) {
            align-self: flex-start;
        }
    }
    &.texts {
        grid-area: b;
    }

    &.actions {
        align-self: stretch;
        grid-area: c;

        .cxi-c-button {
            height: 100%;
            width: 100%;
        }
    }

    &.eval {
        grid-area: st;
    }
    &.btns {
        display: flex;
        gap: 0.8rem;
        grid-area: nd;

        .cxi-c-button {
            justify-content: center;
            padding: 1.2rem 1.6rem;
            flex: 1 1 auto;
            width: auto;
        }
    }
    &.link {
        grid-area: rd;
        margin-top: auto;
        padding-top: 0.8rem;
        text-align: right;

        .cxi-c-button {
            font-size: 1.4rem;
            width: auto;
        }
    }

    .c-match-action {
        border-radius: 0.8rem;
        font-size: 1.4rem;
        font-weight: 600;
    }
    .c-match-action:not(.icon-only) {
        padding: 1.2rem 2.4rem;
    }
    .c-match-action.options {
        display: none;
    }
`;

export const Button = styled.div``;

export const Image = styled.div.attrs({
    className: 'user-company-card-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 4px solid #ddd;
    border-radius: 50%;
    margin-top: -5.2rem;
    flex: 0 0 14rem;
    height: 14rem;
    padding: 1.6rem;
    width: 14rem;

    @media (min-width: 1200px) {
        align-self: flex-start;
        margin-top: 0;
    }
`;

export const Title = styled.strong`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.2;
    ${truncate(2, 1.2)}
`;

export const Descr = styled.strong`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 0.8rem 0 0;
    ${truncate(3, 1.4)}
`;

export const IconWithText = styled.p`
    align-items: center;
    color: ${({ theme }) => theme.colors.darkGray};
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    gap: 0.8rem;
    justify-content: center;
    margin: 1.6rem 0 0;

    @media (min-width: 992px) {
        justify-content: flex-start;
    }

    .icon {
        color: ${({ theme }) => theme.colors.energyBlue};
    }
    & + & {
        margin-top: 0.4rem;
    }
`;

export const IconWithBttn = styled.button`
    align-items: center;
    background: transparent;
    border: 2px solid #c3c6d0;
    border-radius: 0.8rem;
    color: #0094f9;
    display: inline-flex;
    gap: 0.8rem;
    font-size: 1.8rem;
    font-weight: 400;
    padding: 1rem;
    position: relative;

    .badge {
        align-items: center;
        background: ${({ theme }) => theme.colors.red};
        border-radius: 9999px;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        font-size: 1rem;
        justify-content: center;
        line-height: 1;
        margin: -0.8rem 0 0 0.4rem;
        min-width: 1.65rem;
        padding: 0.4rem 0.6rem 0.25rem;
        position: absolute;
        ${positions('50%', 'auto', 'auto', '1.6rem')}
        transform: translateY(-50%);
    }

    &:focus,
    &:hover {
        background: transparent;
        color: #3fa9f5;
        border-color: #3fa9f5;
    }
`;
