import React from 'react';

import logo from 'assets/images/logo-100-10-1.png';
import menuIcon from 'assets/images/icons/menu-white.svg';

import { RHP } from 'providers';
import { useStartupCXI } from '../../useStartupCXI';

import { NavbarProfile } from './NavbarProfile';
import * as Styled from './StyledNavbar';

export const Navbar = ({ menuToggle }) => {
    const { t, setModal, vars } = useStartupCXI();
    const { startupId } = vars;

    const cxiPath = `/startup/${startupId}/100-10-1`;

    const backHome = () => {
        RHP.push(`${cxiPath}/`);
    };

    const profileItems = [
        {
            icon: 'far fa-question-circle',
            title: t('menu.support'),
            action: () => setModal({ modal: 'Support' }),
        },
        {
            icon: 'far fa-sign-out',
            title: t('menu.leave'),
            action: () => RHP.push(`/startup/${startupId}`),
        },
    ];

    return (
        <Styled.Container>
            <div className="navbar-element" />
            <div className="navbar-content">
                <Styled.MenuButton onClick={menuToggle}>
                    <img src={menuIcon} alt="Menu Icon" />
                </Styled.MenuButton>
                <Styled.Logo src={logo} alt="Logo 100-10-1" onClick={backHome} />
            </div>
            <NavbarProfile items={profileItems} />
        </Styled.Container>
    );
};
