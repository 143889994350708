import styled from 'styled-components';
import { positions, truncate } from 'utils/styles';

export const Message = styled.div.attrs(props => ({
    className: `${props.from} ${props.slim ? 'slim' : ''}`,
}))`
    align-items: flex-start;
    display: flex;
    gap: 1.6rem;
    text-align: left;
    padding-right: 2.4rem;

    @media (min-width: 768px) {
        padding-right: 20%;
    }

    .user-avatar,
    .message-image {
        flex: 0 0 4.4rem;
        height: 4.4rem;
        width: 4.4rem;
    }
    .startup-image {
        flex: 0 0 4.4rem;
        padding-top: 4.4rem;
        width: 4.4rem;
    }

    &.me {
        align-self: flex-end;
        padding-right: 0;
        padding-left: 2.4rem;

        @media (min-width: 768px) {
            padding-right: 0;
            padding-left: 20%;
        }

        .startup-image {
            order: 3;
        }
        .message-texts {
            background: ${({ theme }) => theme.colors.lightGray};
            color: ${({ theme }) => theme.colors.black};
            text-align: left;
        }
        .message-texts .caret {
            color: ${({ theme }) => theme.colors.lightGray};
            ${positions('1rem', 'auto', 'auto', '100%')}
            transform: scaleX(-1);
        }
    }

    &.slim {
        margin-top: -1.6rem;
    }
`;

export const MessageImage = styled.div.attrs({
    className: 'message-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 0.4rem;
    position: relative;
`;

export const MessageTexts = styled.div.attrs({
    className: 'message-texts',
})`
    align-items: stretch;
    background: ${({ theme }) => theme.colors.white};
    border: 0 solid ${({ theme }) => theme.colors.energyBlue};
    border-radius: 0.4rem;
    color: ${({ theme }) => theme.colors.black};
    display: inline-flex;
    // flex: 1 1 auto;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    position: relative;

    .name {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.3;
        ${truncate(1, 1.3)}
    }
    .text {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
        white-space: pre-line;
    }
    .time {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }

    .caret {
        color: ${({ theme }) => theme.colors.white};
        font-size: 2rem;
        position: absolute;
        ${positions('1rem', '100%', 'auto', 'auto')}
    }
`;
