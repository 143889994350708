import React from 'react';

import { useCXIStartup } from '../../useCXIStartup';
import * as Styled from './StyledStartupCard';

export const StartupCardSocials = () => {
    const { vars } = useCXIStartup();
    const { startup } = vars;

    const { website } = startup.links;
    function formatUrl(url) {
        // Regular expression pattern to validate URL
        const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{1,5})?(\/.*)?$/i;

        // Test the URL against the pattern
        if (!urlPattern.test(url)) {
            // Add 'https://' prefix if missing
            url = `https://${url}`;
        } else if (!url.startsWith('http://') && !url.startsWith('https://')) {
            // Add 'https://' prefix if missing http:// or https://
            url = `https://${url}`;
        }

        // Add 'www.' prefix if missing
        if (!url.includes('www.')) {
            const domain = url.replace(/^(https?:\/\/)?/, '');
            url = `https://www.${domain}`;
        }

        return url;
    }
    return (
        <Styled.StartupCardSocials>
            {!!website && (
                <a
                    href={formatUrl(website)}
                    title="Website"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="icon fas fa-globe" aria-hidden="true" />
                    <span className="text">{website || ' - '}</span>
                </a>
            )}
        </Styled.StartupCardSocials>
    );
};
