import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { schemeCategory10 } from 'd3-scale-chromatic';

export default function BubbleChartComponent(props) {
    const svgRef = useRef(null);

    useEffect(() => {
        const { data } = props;
        const container = svgRef.current.parentNode;
        const { width, height } = container.getBoundingClientRect();

        const svg = d3
            .select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .attr('viewBox', `0 0 ${width} ${height}`);

        // Filter the data (optional)
        const filteredData = data.filter(d => d.contracts > 0 && d.points > 0);

        // Set the color scale
        const color = d3
            .scaleOrdinal()
            .domain(d3.extent(filteredData, d => d.points))
            .range(schemeCategory10);

        // Set the size scale
        const size = d3
            .scaleLinear()
            .domain(d3.extent(filteredData, d => d.contracts))
            .range([2, Math.min(width, height) / 10]);

        // Create a tooltip
        const tooltip = d3
            .select('body')
            .append('div')
            .style('opacity', 0)
            .attr('class', 'tooltip')
            .style('background-color', 'white')
            .style('border', 'solid')
            .style('border-width', '2px')
            .style('border-radius', '5px')
            .style('padding', '5px')
            .style('font-size', '2rem');

        // Function to show tooltip on mouseover
        const showTooltip = (event, d) => {
            tooltip
                .style('opacity', 1)
                .html(`<u>${d.name}</u><br>${d.contracts} contracts<br>${d.points} points`)
                .style('position', 'absolute')
                .style('left', `${event.pageX}px`)
                .style('top', `${event.pageY}px`);
        };

        // Function to hide tooltip on mouseout
        const hideTooltip = () => {
            tooltip.style('opacity', 0);
        };

        const node = svg
            .append('g')
            .selectAll('circle')
            .data(filteredData)
            .enter()
            .append('circle')
            .attr('class', 'node')
            .attr('r', d => size(d.contracts))
            .attr('cx', width / 2)
            .attr('cy', height / 2)
            .style('fill', d => color(d.points))
            .style('fill-opacity', 0.8)
            .attr('stroke', 'black')
            .style('stroke-width', 1)
            .on('mouseover', showTooltip)
            .on('mouseout', hideTooltip);

        const text = svg
            .selectAll('.label')
            .data(filteredData)
            .enter()
            .append('text')
            .attr('class', 'label')
            .style('text-anchor', 'middle')
            .style('alignment-baseline', 'middle')
            .style('fill', 'white')
            .text(d => {
                const truncatedText = truncateText(d.name, size(d.contracts));
                return truncatedText === '...' ? '' : truncatedText;
            })
            .attr('x', d => d.x)
            .attr('y', d => d.y)
            .on('mouseover', showTooltip)
            .on('mouseout', hideTooltip);

        node.attr('r', d => size(d.contracts));

        // Initialize the simulation
        const simulation = d3
            .forceSimulation(filteredData)
            .force(
                'center',
                d3
                    .forceCenter()
                    .x(width / 2)
                    .y(height / 2)
            )
            .force('charge', d3.forceManyBody().strength(0.3))
            .force(
                'collide',
                d3
                    .forceCollide()
                    .strength(0.3)
                    .radius(d => size(d.contracts + 0.5))
                    .iterations(1)
            )
            .on('tick', () => {
                node.attr('cx', d => d.x).attr('cy', d => d.y);
                text.attr('x', d => d.x).attr('y', d => d.y);
            });

        // Clean up function to remove the tooltip
        return () => {
            tooltip.remove();
        };
    }, [props.data]);

    // Function to truncate text if it overflows the given width
    const truncateText = (text, width) => {
        const ellipsis = '...';
        let truncatedText = text;
        let currentWidth = getTextWidth(truncatedText);

        if (currentWidth > width) {
            while (currentWidth > width && truncatedText.length > 0) {
                truncatedText = truncatedText.slice(0, -1);
                currentWidth = getTextWidth(truncatedText + ellipsis);
            }
            truncatedText += ellipsis;
        }

        return truncatedText;
    };

    // Function to calculate the width of the text element
    const getTextWidth = text => {
        const dummyText = document.createElement('span');
        dummyText.style.font = '12px sans-serif';
        dummyText.textContent = text;
        document.body.appendChild(dummyText);
        const width = dummyText.offsetWidth;
        document.body.removeChild(dummyText);
        return width;
    };

    return <svg ref={svgRef} />;
}
