import useCXI from 'modules/cxi/useCXI';
import React from 'react';
import { useEffectOnce } from 'react-use';
import * as Styled from './StyledCXIChallengesFilters';

export const CXIChallengesFilters = props => {
    const { t, vars, getCXIChallengeStatistics } = useCXI();
    useEffectOnce(() => {
        if (!vars.challengesStatistics) {
            getCXIChallengeStatistics();
        }
    }, []);

    const { challengesStatistics } = vars;

    const activeChallengesCount = challengesStatistics?.challenges ?? 0;

    const activeChallengesInscriptions = challengesStatistics?.applications ?? 0;

    const activeChallengesFeedbacks = challengesStatistics?.feedbacksPending ?? 0;

    return (
        <Styled.CXIChallengesFilters>
            <Styled.Filter className="challenges">
                <div>
                    <Styled.Icon>
                        <span className="icon far fa-thin fa-comments-alt" aria-hidden="true" />
                    </Styled.Icon>
                </div>
                <div>
                    <p> {t('challenge.general.filter1')} </p>
                    <strong>
                        {activeChallengesCount} {t('challenge.general.sub1')}
                    </strong>
                </div>
            </Styled.Filter>

            <Styled.Filter className="challenges">
                <div>
                    <Styled.Icon>
                        <span className="icon far fa-fw fa-users" aria-hidden="true" />
                    </Styled.Icon>
                </div>
                <div>
                    <p>{t('challenge.general.filter2')} </p>
                    <strong>
                        {activeChallengesInscriptions} {t('challenge.general.sub2')}
                    </strong>
                </div>
            </Styled.Filter>

            <Styled.Filter className="challenges">
                <div>
                    <Styled.Icon>
                        <span className="icon far fa-fw fa-check-circle" aria-hidden="true" />
                    </Styled.Icon>
                </div>
                <div>
                    <p> {t('challenge.general.filter1')} </p>
                    <strong>
                        {activeChallengesFeedbacks} {t('challenge.general.sub3')}
                    </strong>
                </div>
            </Styled.Filter>
        </Styled.CXIChallengesFilters>
    );
};

export default CXIChallengesFilters;
