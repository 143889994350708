import * as Alert from 'components/alert';
import * as Form from 'components/form';
import { useForm } from 'components/form';
import * as Page from 'components/page';
import { cnpj } from 'cpf-cnpj-validator';
import { useApiLocation, useConversionOptions } from 'hooks';
import { RHP } from 'providers';
import React, { useEffect, useState } from 'react';

export function StartupStep2({ useRankingStartup }) {
    const { vars, putStartupRankingApplication, putStartupDetails, t } = useRankingStartup;
    const { startupDetails, ranking } = vars;

    const apiLocation = useApiLocation();
    const conv = useConversionOptions(['investmentOrRevenues'], { namespace: 'currency' });

    const [countries, setCountries] = useState([]);

    // const pastYear = new Date().getFullYear() - 1;
    const pastYear = ranking.year - 1;

    if (!startupDetails.rankingData) {
        startupDetails.rankingData = {};
    }

    useEffect(() => {
        apiLocation.geo('').then(res => {
            const names = res.map(country => {
                return { name: country.name, value: country.id };
            });
            setCountries(names);
        });
    }, []);

    if (!startupDetails.rankingData?.[ranking.id]) {
        startupDetails.rankingData[ranking.id] = {
            revenuesRange: [],
            investmentRange: [],
            categories: []
        };
    }

    // Initialize a new form handler
    const form = useForm({
        ...vars.application,
        startupName: startupDetails.name,
        country: startupDetails.location?.country?.id,
        cnpj: startupDetails.demographics.status.cnpj,
        isControlledByCorp: startupDetails.isControlledByCorp,
        controllingCorpName: startupDetails.controllingCorpName,
        shortDescription: startupDetails.description,
        pocName: startupDetails.rankingData[ranking.id]?.pocName,
        pocEmail: startupDetails.rankingData[ranking.id]?.pocEmail,
        pocConfirm: false,
        revenuesRange: startupDetails.rankingData[ranking.id]?.revenuesRange,
        investmentRange: startupDetails.rankingData[ranking.id]?.investmentRange,
        isUpToDate: !!vars.application.appliedAt
    });

    const onBlur = {
        isValidCNPJ: ev => {
            const { name, value } = ev.target;
            // Clear the errors and warnings before validating
            form.clearMessages({ name });
            // Only check if the value is filled
            if (!value) return false;
            // Check if the CPF is a valid one
            const cleanValue = value.replace(/[^0-9]/g, '');

            if (!cnpj.isValid(cleanValue)) {
                // Emit error message for registered email
                const error = t('StartupStep2.warningTextCnpj');
                return form.emitError({ name, error });
            }
            return true;
        }
    };

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step1 !== true) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    let scaleUpValueWarning = false;

    if (
        (Number(form.values.investmentRange[0]) === 0 &&
            Number(form.values.investmentRange[1]) >= 20000000) ||
        (Number(form.values.revenuesRange[0]) === 0 &&
            Number(form.values.revenuesRange[1]) >= 20000000)
    ) {
        scaleUpValueWarning = true;
    }

    if (form.values.revenuesRange[0] >= 2500000 || form.values.investmentRange[0] >= 2500000) {
        scaleUpValueWarning = true;
    }

    // Handle form submissions
    const onSubmit = values => {
        const rankingPayload = {
            ...vars.application,
            ...values,
            investmentRange: values.investmentRange,
            revenuesRange: values.revenuesRange,
            scaleupConfirmed: values.scaleupConfirmed
        };

        // Set the current step to true;
        if (!rankingPayload.steps) {
            rankingPayload.steps = {};
        }

        rankingPayload.steps.step2 = true;

        const startupPayload = {
            description: values.shortDescription,
            name: values.startupName,
            demographics: {
                status: {
                    cnpj: values.cnpj
                }
            },
            isControlledByCorp: values.isControlledByCorp,
            location: {
                country: values.country
            },
            controllingCorpName: values.controllingCorpName,
            language: startupDetails.language,
            pocName: values.pocName,
            pocEmail: values.pocEmail
        };

        return putStartupDetails(startupPayload).then(
            putStartupRankingApplication(rankingPayload)
                .then(() => RHP.push('/ranking/latam/startup/step3'))
                .catch(() => form.submit())
        );
    };

    const shortDescriptionLabel = {
        pt: `${t('glossary:shortDescription')} ${t('glossary:inPortuguese')}`,
        en: `${t('glossary:shortDescription')} ${t('glossary:inEnglish')}`,
        es: `${t('glossary:shortDescription')} ${t('glossary:inSpanish')}`
    };

    const fields = {
        startupName: {
            error: form.errors.startupName,
            label: t('glossary:startupName'),
            name: 'startupName',
            type: 'generic',
            value: form.values.startupName,
            onChange: form.handleChange
        },
        country: {
            error: form.errors.country,
            label: t('glossary:startupCountry'),
            name: 'country',
            type: 'select',
            value: form.values.country,
            onChange: form.handleChange,
            options: [{ name: t('glossary:select'), value: countries.id }, ...countries]
        },
        cnpj: {
            error: form.errors.cnpj,
            label: t('glossary:cnpj'),
            name: 'cnpj',
            type: 'generic',
            value: form.values.cnpj,
            onChange: form.handleChange,
            required: false
        },
        pocEmail: {
            error: form.errors.pocEmail,
            label: t('StartupStep2.pocEmail'),
            name: 'pocEmail',
            type: 'generic',
            value: form.values.pocEmail,
            onChange: form.handleChange
        },
        pocName: {
            error: form.errors.pocName,
            label: t('StartupStep2.pocName'),
            name: 'pocName',
            type: 'generic',
            value: form.values.pocName,
            onChange: form.handleChange
        },
        pocConfirm: {
            error: form.errors.pocConfirm,
            label: t('StartupStep2.pocConfirm'),
            name: 'pocConfirm',
            type: 'checkbox',
            required: true,
            value: form.values.pocConfirm,
            onChange: form.handleChange
        },
        isControlledByCorp: {
            error: form.errors.isControlledByCorp,
            label: `${t('StartupStep2.isControlledByCorp')} `,
            name: 'isControlledByCorp',
            required: false,
            type: 'checkbox',
            value: form.values.isControlledByCorp,
            onChange: form.handleChange
        },
        controllingCorpName: {
            error: form.errors.controllingCorpName,
            label: t('StartupStep2.controllerCorpName'),
            name: 'controllingCorpName',
            type: 'generic',
            required: form.values.isControlledByCorp,
            value: form.values.controllingCorpName,
            onChange: form.handleChange
        },
        shortDescription: {
            error: form.errors.shortDescription,
            label: shortDescriptionLabel[startupDetails.language],
            name: 'shortDescription',
            type: 'textarea',
            value: form.values.shortDescription,
            onChange: form.handleChange
        },
        revenuesRange: {
            error: form.errors.revenuesRange,
            label: `${t('StartupStep2.revenuesRange')} ${pastYear} (${t('glossary:inDollars')})`,
            name: 'revenuesRange',
            type: 'SelectRangeArray',
            options: [
                { name: t('glossary:selectOption'), value: '' },
                ...conv.investmentOrRevenues
            ],
            value: form.values.revenuesRange,
            onChange: form.handleChange
        },
        investmentRange: {
            error: form.errors.investmentRange,
            label: `${t('glossary:investmentRange')} 31/12/${pastYear} (${t(
                'glossary:inDollars'
            )})`,
            name: 'investmentRange',
            type: 'SelectRangeArray',
            options: [
                { name: t('glossary:selectOption'), value: '' },
                ...conv.investmentOrRevenues
            ],
            value: form.values.investmentRange,
            onChange: form.handleChange
        },
        applied: {
            error: form.errors.applied,
            label: scaleUpValueWarning
                ? `${t('glossary:appliedScaleUp')} `
                : `${t('glossary:appliedStartup')} `,
            name: 'applied',
            required: false,
            type: 'checkbox',
            value: form.values.applied,
            onChange: form.handleChange
        },
        scaleupConfirmed: {
            // error: form.errors.scaleupConfirmed,
            label: `${t('glossary:scaleupConfirmed')}`,
            name: 'scaleupConfirmed',
            required: false,
            type: 'checkbox',
            value: form.values.scaleupConfirmed,
            onChange: form.handleChange
        }
    };

    if (form.values.country === 'BR') {
        fields.cnpj.displayFormat = '00.000.000/0000-00';
        fields.cnpj.maxLength = 18;
        fields.cnpj.onBlur = ev => form.handleBlur(ev, onBlur.isValidCNPJ);
        fields.cnpj.onChange = e => {
            e.target.value = cnpj.format(e.target.value);
            form.handleChange(e);
        };
    }

    const propsScaleUpWarning = {
        descr: '',
        icons: 'far fa-info-circle',
        type: 'warning'
    };

    if (scaleUpValueWarning) {
        propsScaleUpWarning.descr = t('StartupStep2.scaleUpWarning', {
            startupId: startupDetails._id
        });

        if (
            Number(form.values.investmentRange[0]) === 0 &&
            Number(form.values.investmentRange[1]) >= 20000000
        ) {
            propsScaleUpWarning.bgColor = '#f44336';
            propsScaleUpWarning.color = 'white';
            propsScaleUpWarning.descr = t('StartupStep2.openCorpWarning');
        }
    }

    const propsNotEligibleForRanking = {
        descr: t('StartupStep2.notEligibleForRanking'),
        icons: 'far fa-info-circle',
        type: 'warning'
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Page.Page
            name="StartupStep2"
            back="/ranking/latam/startup"
            customBackText={t('glossary:backToRanking')}
        >
            <Page.Title>{t('StartupStep2.title')}</Page.Title>
            <Page.Descr>{t('StartupStep2.descr', { rankingYear: ranking.year })}</Page.Descr>

            <Form.Form name="StartupStep2Form" onSubmit={evSubmit}>
                <Form.Field {...fields.startupName} />
                <Form.Field {...fields.country} />
                <Form.Field {...fields.cnpj} />
                <Form.Field {...fields.isControlledByCorp} />

                {fields.isControlledByCorp.value && <Form.Field {...fields.controllingCorpName} />}
                {fields.isControlledByCorp.value && (
                    <Alert.AlertRow {...propsNotEligibleForRanking} />
                )}

                <Form.Field {...fields.shortDescription} />
                <Form.Field {...fields.pocName} />
                <Form.Field {...fields.pocEmail} />
                <Form.Field {...fields.pocConfirm} />
                <Form.Field {...fields.revenuesRange} />
                <Form.Field {...fields.investmentRange} />
                <Form.Field {...fields.applied} />

                {scaleUpValueWarning && (
                    <Form.Field
                        label={`${t('glossary:scaleupConfirmed')}`}
                        name="scaleupConfirmed"
                        required={false}
                        type="checkbox"
                        value={form.values.scaleupConfirmed}
                        onChange={form.handleChange}
                    />
                )}

                {scaleUpValueWarning && <Alert.AlertRow {...propsScaleUpWarning} />}

                <div style={{ display: 'flex', gap: '10px' }}>
                    <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/startup/step1">
                        <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                        <span className="text" style={{ margin: '0 0 0 auto' }}>
                            {t('glossary:back')}
                        </span>
                    </Page.Buttn>

                    <div style={{ width: '80%' }}>
                        <Form.Submt paddingTop="10rem" {...form} text={t('glossary:save')} />
                    </div>
                </div>
            </Form.Form>
        </Page.Page>
    );
}
