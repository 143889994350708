import React from 'react';
import { useClickAway } from 'react-use';

import { useCXI } from 'modules/cxi/useCXI';

import { ContainerBody } from './ContainerBody';
import { ContainerHead } from './ContainerHead';
import { ContainerFeet } from './ContainerFeet';
import { ContainerSlim } from './ContainerSlim';

import * as Styled from './StyledContainer';

const Container = ({ children, ...props }) => {
    const { setModal } = useCXI();

    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
        // setModal(null);
    });

    return (
        <Styled.Container {...props} ref={containerRef}>
            <Styled.Close onClick={() => setModal(null)}>
                <span className="icon far fa-times" aria-hidden="true" />
            </Styled.Close>

            {children}
        </Styled.Container>
    );
};

Container.Body = ContainerBody;
Container.Head = ContainerHead;
Container.Feet = ContainerFeet;
Container.Slim = ContainerSlim;

export { Container };
