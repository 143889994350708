import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as Page from './index';
import { useCXIDashboard } from './useCXIDashboard';

const CXIDashboardRedirect = () => {
    const { companyId } = useCXIDashboard();
    return <Redirect to={`/100-10-1/dashboard/${companyId}/index`} />;
};

export const routes = [
    {
        exact: true,
        path: '/100-10-1/dashboard/:companyId/index',
        component: Page.CXIDashboardIndex,
    },
    {
        exact: true,
        path: '/100-10-1/dashboard/:companyId/stages',
        component: Page.CXIDashboardStages,
    },
    {
        exact: true,
        path: '/100-10-1/dashboard/:companyId/users/:userId',
        component: Page.CXIDashboardUser,
        // This is also in /modules/cxi/CXIRoutes.jsx
    },
    {
        exact: true,
        path: '/100-10-1/dashboard/:companyId/users',
        component: Page.CXIDashboardUsers,
    },
    {
        exact: true,
        path: '/100-10-1/dashboard/:companyId/startups/:startupId',
        component: Page.CXIDashboardStartup,
    },
    {
        exact: true,
        path: '/100-10-1/dashboard/:companyId/startups',
        component: Page.CXIDashboardStartups,
    },

    // redirect if url doesn't match any route
    {
        exact: false,
        path: '/100-10-1/dashboard/:companyId',
        component: CXIDashboardRedirect,
    },
];

export const CXIDashboardRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default CXIDashboardRoutes;
