import React from 'react';

import { CONFIG } from 'config';
import { iframePush } from 'core/iframe';
import { StartupImage } from 'core/components/startup';
import { useUser } from 'hooks';

import { useStartupCXI } from '../../useStartupCXI';
import * as Styled from './StyledMenu';
import { MenuItem } from './MenuItem';

const hrefStartup = CONFIG.appLinks.startup;

export const Menu = props => {
    const { t, vars, setModal } = useStartupCXI();
    const { startup, startupId, unreadMessages } = vars;
    const user = useUser();

    const cxiPath = `/startup/${startupId}/100-10-1`;

    const editProfile = () => {
        iframePush(`${hrefStartup}/home/?startupId=${startupId}`);
    };

    return (
        <Styled.Menu {...props}>
            <Styled.MenuAvatar onClick={editProfile}>
                <StartupImage data={startup} />
            </Styled.MenuAvatar>

            <Styled.Name>{startup.name}</Styled.Name>
            <Styled.Desc>{user.fullname}</Styled.Desc>

            <MenuItem className="is-highlight" to={`${cxiPath}/feedbacks`}>
                <span className="icon far fa-fw fa-comment-alt-smile" aria-hidden="true" />
                <span className="text">{t('menu.feedbacks')}</span>
            </MenuItem>

            <Styled.MenuItems>
                <MenuItem to={`${cxiPath}/mentoring`} badge={unreadMessages}>
                    <span className="icon far fa-fw fa-user-chart" aria-hidden="true" />
                    <span className="text">{t('menu.mentoring')}</span>
                </MenuItem>
                <MenuItem to={`${cxiPath}/messages`} badge={unreadMessages}>
                    <span className="icon far fa-fw fa-comments-alt" aria-hidden="true" />
                    <span className="text">{t('menu.messages')}</span>
                </MenuItem>
                <MenuItem onClick={() => setModal({ modal: 'StartupPreview' })}>
                    <span className="icon far fa-fw fa-clone" aria-hidden="true" />
                    <span className="text">{t('menu.startupCard')}</span>
                </MenuItem>
                <MenuItem onClick={() => setModal({ modal: 'Support' })}>
                    <span className="icon far fa-fw fa-question-circle" aria-hidden="true" />
                    <span className="text">{t('menu.support')}</span>
                </MenuItem>
            </Styled.MenuItems>
        </Styled.Menu>
    );
};
