import React from 'react';

import { Button } from 'modules/cxi/components/button';
import { useCXI } from 'modules/cxi/useCXI';

import { Container } from '../container';
import * as Styled from './StyledSpeedDatingCheck';

export const SpeedDatingCheck = () => {
    const { t, modal, setModal } = useCXI();
    const { match } = modal;

    return (
        <Styled.SpeedDatingCheck as={Container.Slim}>
            <Container.Head image={match.startup.logoUrl} />

            <Container.Body>
                <Styled.Title>{t('modal.SpeedDatingCheck.title')}</Styled.Title>
                <Styled.Descr>{t('modal.SpeedDatingCheck.description')}</Styled.Descr>
            </Container.Body>

            <Container.Feet>
                <Button onClick={() => setModal(null)}>
                    <span className="text">{t('modal.SpeedDatingCheck.close')}</span>
                </Button>
            </Container.Feet>
        </Styled.SpeedDatingCheck>
    );
};
