import React from 'react';
import axios from 'axios';

import { ContextGlobal as CG } from 'contexts';

export function useNewsApi() {
    const { emitAlert } = React.useContext(CG);

    // Create the api handler with axios
    // const api = axios.create({ baseURL: 'https://www.whow.com.br/wp-json/wp/v2' });
    const api = axios.create({ baseURL: 'https://blog.openstartups.net/wp-json/wp/v2' });

    api.interceptors.request.use(config => {
        if (config.method === 'get') {
            const url = `${config.url}&timestamp=${new Date().toISOString()}`;
            return { ...config, url };
        }
        return config;
    });

    // Setup interceptors with error handling
    api.interceptors.response.use(
        res => res.data,
        err => {
            // Get the response with the error
            const { response } = err;
            try {
                // Build the error message
                let message = 'Undentified error';
                if (response.data && response.data.error.message) {
                    message = response.data.error.message;
                }
                emitAlert(message);
                return Promise.reject(err);
            } catch (e) {
                emitAlert(e);
                return Promise.reject(err);
            }
            // Ok, errors handled.
        }
    );

    return api;
}
export default useNewsApi;
