import styled from 'styled-components';

export const ChallengeIndex = styled.div`
    .component-page-container {
        text-align: center;
    }

    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444444;
    }
`;

export const ChallengeDetails = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;

    .startup-name {
        font-weight: bold;
    }
`;
