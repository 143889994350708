import useCXI from 'modules/cxi/useCXI';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import * as Styled from './StyledButton';

const statusMap = {
    active: 'Candidata',
    applied: 'Candidata',
    selected: 'Quero Conversar',
    registered: 'Quero Conversar',
    attended: 'Quero Conversar',
    inprogress: 'Negócio em Andamento',
    rejected: 'Arquivada'
};

export const ButtonAction = ({ startup }) => {
    const { setModal } = useCXI();
    const { challengeId } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const clickEvent = useMemo(() => startup?.event, [startup]);

    const status = useMemo(() => {
        const eventStatus = clickEvent?.eventStatus;
        return statusMap[eventStatus];
    }, [clickEvent]);

    useEffect(() => {
        const handleClickOutside = e => {
            if (buttonRef.current && !buttonRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [buttonRef]);

    return (
        <Styled.ButtonAction>
            <div className="dropdown" ref={buttonRef}>
                <button
                    type="button"
                    className="dropdown-button"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {status}
                </button>
                <div style={{ borderLeft: '1px solid white', height: '100%' }} />
                <button
                    type="button"
                    className="dropdown-button"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <i className="fa fa-angle-down" aria-hidden="true" />
                </button>

                {isOpen && (
                    <div className="dropdown-menu">
                        {status !== 'Candidata' && (
                            <Styled.Buttn
                                onClick={() =>
                                    setModal({
                                        challengeId,
                                        startup,
                                        modal: 'ChallengeRestart'
                                    })
                                }
                            >
                                <button type="button"> Candidata </button>
                                <hr />
                            </Styled.Buttn>
                        )}
                        {status !== 'Quero Conversar' && (
                            <Styled.Buttn
                                onClick={() =>
                                    setModal({
                                        challengeId,
                                        startup,
                                        modal: 'ChallengeChat'
                                    })
                                }
                            >
                                <button type="button"> Quero Conversar </button>
                                <hr />
                            </Styled.Buttn>
                        )}
                        {status !== 'Negócio em Andamento' && (
                            <Styled.Buttn
                                onClick={() =>
                                    setModal({
                                        challengeId,
                                        startup,
                                        modal: 'ChallengeConnection'
                                    })
                                }
                            >
                                <button type="button"> Negócio em Andamento </button>
                                <hr />
                            </Styled.Buttn>
                        )}
                        {status !== 'Arquivada' && (
                            <Styled.Buttn
                                onClick={() =>
                                    setModal({
                                        challengeId,
                                        startup,
                                        modal: 'ChallengeFeedback'
                                    })
                                }
                            >
                                <button type="button"> Arquivar </button>
                            </Styled.Buttn>
                        )}
                    </div>
                )}
            </div>
        </Styled.ButtonAction>
    );
};

export default React.memo(ButtonAction);
