import React from 'react';
import { stringToKebabCase } from 'utils/conversion';
import { useEffectOnce } from 'react-use';
import { useCXI } from '../useCXI';
import { Content } from './components/content/Content';
import { FilterTag } from './components/filter-tag/FilterTag';

import * as Styled from './StyledCXIContents';

export const CXIContents = props => {
    const { t, vars, getCXIContents, getUserReadCXIContents } = useCXI();
    const { readUserContents } = vars;
    const [filter, setFilter] = React.useState('');
    const hasFilter = value => filter === value;

    const updFilter = value => {
        if (hasFilter(value)) {
            return setFilter('');
        }
        return setFilter(value);
    };

    useEffectOnce(() => {
        if (!vars.contents) getCXIContents();
        if (!readUserContents) getUserReadCXIContents();
    }, []);
    const readContentIds = Object.keys(readUserContents);

    let filtered = vars.contents.map(i => {
        i.read = readContentIds.includes(i.id);
        return i;
    });

    if (filter) {
        filtered = vars.contents.filter(i => stringToKebabCase(i.fields.Category) === filter);
    }

    return (
        <Styled.CXIContents>
            <Styled.Title>{t('contents.title')}</Styled.Title>
            <Styled.Descr>{t('contents.description')}</Styled.Descr>

            <Styled.CXIContentsFilters>
                <Styled.Filter className="open-innovation">
                    <p>
                        <span>
                            {t('cxiContents.trail')}
                            <strong>{t('cxiContents.openInno')}</strong>
                        </span>
                        {t('cxiContents.trail1Descr')}
                    </p>
                    <FilterTag
                        active={hasFilter('open-innovation')}
                        onClick={() => updFilter('open-innovation')}
                    >
                        <span className="text">{t('cxiContents.button1')}</span>
                    </FilterTag>
                </Styled.Filter>

                <Styled.Filter className="open-startups">
                    <p>
                        <span>
                            {t('cxiContents.trail')}
                            <strong>{t('cxiContents.openStar')}</strong>
                        </span>
                        {t('cxiContents.trail2Descr')}
                    </p>
                    <FilterTag
                        active={hasFilter('open-startups')}
                        onClick={() => updFilter('open-startups')}
                    >
                        <span className="text">{t('cxiContents.button2')}</span>
                    </FilterTag>
                </Styled.Filter>

                <Styled.Filter className="investimentos">
                    <p>
                        <span>
                            {t('cxiContents.trail')}
                            <strong>{t('cxiContents.invest')}</strong>
                        </span>
                        {t('cxiContents.trail3Descr')}
                    </p>
                    <FilterTag
                        active={hasFilter('investimentos')}
                        onClick={() => updFilter('investimentos')}
                    >
                        <span className="text">{t('cxiContents.button3')}</span>
                    </FilterTag>
                </Styled.Filter>
            </Styled.CXIContentsFilters>

            <Styled.Descr>{t('contents.subtitle')}</Styled.Descr>

            <Styled.CXIContentsList>
                {filtered &&
                    filtered.length > 0 &&
                    filtered.map(content => <Content key={content.id} data={content} />)}
            </Styled.CXIContentsList>
        </Styled.CXIContents>
    );
};
