import React from 'react';
import { useClickAway, useMedia } from 'react-use';
import { Menu } from '../components/menu/Menu';

import { Navbar } from '../components/navbar/Navbar';
import { useStartupCXI } from '../useStartupCXI';

import * as Styled from './StyledStartupCXILayout';

export const StartupCXILayout = props => {
    const { vars, addVars, modal } = useStartupCXI();
    const { isMenuOpen } = vars;

    const isMobile = useMedia('(max-width: 991px)');
    const menuRef = React.useRef(null);

    function setMenuOpen(state) {
        addVars({ isMenuOpen: state });
    }

    useClickAway(menuRef, () => {
        if (isMobile) setMenuOpen(false);
    });

    if (vars.simpleLayout === true) {
        return <Styled.Container>{props.children}</Styled.Container>;
    }

    let className = isMenuOpen ? 'is-open' : '';
    if (modal !== null) {
        className = `${className} has-modal`;
    }

    return (
        <Styled.Container className={className}>
            <Navbar menuToggle={() => setMenuOpen(!isMenuOpen)} />
            <Styled.Content>
                <Styled.Menu ref={menuRef} menuOpen={isMenuOpen}>
                    <Menu setMenuOpen={setMenuOpen} />
                </Styled.Menu>
                <Styled.Main>{props.children}</Styled.Main>
            </Styled.Content>
        </Styled.Container>
    );
};
