import React from 'react';
import { useLocation } from 'react-router';

import { CA, CG } from 'contexts';
import { useApi } from 'hooks';
import { RHP } from 'providers';
import { queryParam } from 'utils/query';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

export function useRanking() {
    const { t } = useTranslation('ranking');
    const { pathname } = useLocation();
    const api = useApi();

    const { state, dispatch, scopeAllows } = React.useContext(CA);
    const { emitAlert } = React.useContext(CG);

    // Initial values for the state
    const initial = {
        codeFromQuery: queryParam('verificationcode'),
        redirectTo: queryParam('returnUrl'),
        startupMatch: queryParam('startupId'),
        startups: [],
        startupDetails: {},
        ...state.user
    };

    if (queryParam('contractId') && queryParam('contractResponse')) {
        initial.validateContract = {
            _id: queryParam('contractId'),
            status: queryParam('contractResponse')
        };
        if (queryParam('startupId')) {
            initial.validateContract.startupId = queryParam('startupId');
        }
    }

    if (
        queryParam('investmentId') &&
        queryParam('investmentResponse') &&
        queryParam('lastUpdated')
    ) {
        initial.validateInvestment = {
            _id: queryParam('investmentId'),
            status: queryParam('investmentResponse'),
            lastUpdated: queryParam('lastUpdated')
        };
        if (queryParam('startupId')) {
            initial.validateInvestment.startupId = queryParam('startupId');
        }
    }

    // Check for queryStrings
    if (
        queryParam('relationshipId') &&
        queryParam('relationshipResponse') &&
        queryParam('lastUpdated')
    ) {
        initial.validateRelationship = {
            _id: queryParam('relationshipId'),
            status: queryParam('relationshipResponse'),
            lastUpdated: queryParam('lastUpdated')
        };
        if (queryParam('startupId')) {
            initial.validateRelationship.startupId = queryParam('startupId');
        }
    }

    // Check for queryStrings
    if (
        queryParam('corpRelationshipId') &&
        queryParam('relationshipResponse') &&
        queryParam('lastUpdated')
    ) {
        initial.validateCorpRelationship = {
            _id: queryParam('corpRelationshipId'),
            entity: pathname.includes('ecosystem') ? 'ecosystem' : 'company',
            status: queryParam('relationshipResponse'),
            lastUpdated: queryParam('lastUpdated')
        };
    }

    // Controls if the module is ready
    // const [ready, setReady] = React.useState(false);

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function setReturnTo(href) {
        addVars({ redirectTo: href });
    }

    /**
     * HANDLER FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const handleReauthentication = res => {
        const { userid } = state.auth;
        // Get user informations and reauthenticate the user
        return api.get(`/users/${userid}`).then(user => {
            dispatch({ type: 'UPDATE_USER_INFO', user });
            return res;
        });
    };

    // /**
    //  * CUSTOM FUNCTIONS
    //  * ------------------------------------------------------------------------
    //  */

    function getStartupDetails(startupId, addResponseToVars = true) {
        return api.get(`/startups/${startupId}`).then(res => {
            if (res.startupStatus === 'none') {
                RHP.push('/startup');
            }

            if (addResponseToVars) {
                addVars({ startupDetails: res });
            }
            return res;
        });
    }

    async function redirectToStartup(startup) {
        const { _id } = startup;
        RHP.replace(`/ranking/latam/startup?startupId=${_id}`);
    }

    function getStartups() {
        return api.get(`/startups`).then(async res => {
            addVars({ startups: res });

            if (res.length === 1 && res[0].startupStatus === 'none') {
                RHP.push('/startup');
                return res;
            }

            if (res.length === 1) {
                redirectToStartup(res[0]);
            }

            if (vars.startupMatch && vars.startupMatch !== undefined) {
                const index = [...res].map(item => item._id).indexOf(vars.startupMatch);
                redirectToStartup(res[index]);
            }

            return res;
        });
    }

    function postStartupContractValidation(payload) {
        const { startupId, ...contract } = payload;
        const { _id } = payload;
        return api.post(`/startups/${startupId}/contracts/${_id}/validation`, contract);
    }

    function postStartupInvestmentValidation(payload) {
        const { startupId, ...data } = payload;
        const { _id } = payload;
        return api.post(`/startups/${startupId}/investments/${_id}/validation`, data);
    }

    function postStartupRelationshipValidation(payload) {
        const { startupId, ...data } = payload;
        const { _id } = payload;
        return api.post(`/startups/${startupId}/ecosystemrelationships/${_id}/validation`, data);
    }

    function postUserSendEmail(payload) {
        const { userid } = state.auth;
        return api.post(`/users/${userid}/sendemailverification`, payload);
    }

    function postVerifyEmail(payload) {
        return api.post(`/verifyemail/`, payload).then(res => {
            return handleReauthentication(res);
        });
    }

    function postUserContractValidation(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api.post(`/users/${userid}/contracts/${_id}/validation`, payload);
    }

    function postUserInvestmentValidation(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api.post(`/users/${userid}/investments/${_id}/validation`, payload);
    }

    function postUserRelationshipValidation(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api.post(`/users/${userid}/ecosystemrelationships/${_id}/validation`, payload);
    }

    function postUserCorpRelationshipValidation(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api.post(`/users/${userid}/corpecosystemrelationships/${_id}/validation`, payload);
    }

    async function getCompanyByEmail(companyEmail) {
        const response = await api.get(`/companyfromemail/${companyEmail}`);

        addVars({ searchedCompany: response });
        return response;
    }

    useEffectOnce(() => {
        if (vars.validateContract) {
            if (vars.validateContract.startupId) {
                postStartupContractValidation({ ...vars.validateContract }).then(res => {
                    emitAlert({
                        header: t('contractValidated.title', {
                            context: vars.validateContract.status
                        }),
                        message: t('contractValidated.messageForStartups', {
                            context: vars.validateContract.status
                        })
                    });
                    addVars({ validateContract: undefined });
                });
            } else {
                postUserContractValidation({ ...vars.validateContract }).then(res => {
                    emitAlert({
                        header: t('contractValidated.title', {
                            context: vars.validateContract.status
                        }),
                        message: t('contractValidated.message', {
                            context: vars.validateContract.status
                        })
                    });
                    addVars({ validateContract: undefined });
                });
            }
        }

        if (vars.validateInvestment) {
            if (vars.validateInvestment.startupId) {
                postStartupInvestmentValidation({ ...vars.validateInvestment }).then(res => {
                    emitAlert({
                        header: t('investmentValidated.title', {
                            context: vars.validateInvestment.status
                        }),
                        message: t('investmentValidated.messageForStartups', {
                            context: vars.validateInvestment.status
                        })
                    });
                    addVars({ validateInvestment: false });
                });
            } else {
                postUserInvestmentValidation({ ...vars.validateInvestment }).then(res => {
                    emitAlert({
                        header: t('investmentValidated.title', {
                            context: vars.validateInvestment.status
                        }),
                        message: t('investmentValidated.message', {
                            context: vars.validateInvestment.status
                        })
                    });
                    addVars({ validateInvestment: false });
                });
            }
        }

        if (vars.validateRelationship) {
            if (vars.validateRelationship.startupId) {
                postStartupRelationshipValidation({ ...vars.validateRelationship }).then(res => {
                    emitAlert({
                        header: t('relationshipValidated.title', {
                            context: vars.validateRelationship.status
                        }),
                        message: t('relationshipValidated.messageForStartups', {
                            context: vars.validateRelationship.status
                        })
                    });
                    addVars({ validateRelationship: false });
                });
            } else {
                postUserRelationshipValidation({ ...vars.validateRelationship }).then(res => {
                    emitAlert({
                        header: t('relationshipValidated.title', {
                            context: vars.validateRelationship.status
                        }),
                        message: t('relationshipValidated.message', {
                            context: vars.validateRelationship.status
                        })
                    });
                    addVars({ validateRelationship: false });
                });
            }
        }

        if (vars.validateCorpRelationship) {
            postUserCorpRelationshipValidation({ ...vars.validateCorpRelationship }).then(res => {
                emitAlert({
                    header: t('relationshipValidated.title', {
                        context: vars.validateCorpRelationship.status
                    }),
                    message: t('relationshipValidated.message', {
                        context: vars.validateCorpRelationship.status
                    })
                });
                addVars({ validateCorpRelationship: false });
            });
        }

        if (!scopeAllows('ranking')) {
            RHP.replace('/home');
        }

        // Promise.all([getStartups()]).then(() => setReady(true));
    });

    return {
        // Ready to load?
        ready: true,
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Include vars functions
        setReturnTo,
        // Custom functions
        redirectToStartup,
        getCompanyByEmail,
        getStartups,
        getStartupDetails,
        postUserSendEmail,
        postVerifyEmail
    };
}
export default useRanking;
