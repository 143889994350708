import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CorporateHubRoutes } from './CorporateHubRoutes';
import { DataContextProvider } from './context/dataContext';

export function CorporateHub(props) {
    const { t } = useTranslation();
    const { search } = useLocation();
    let configObj = {};
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    if (window.location.href.includes('play', 'startups')) {
        const [startDate] = query.getAll('startDate');
        const [endDate] = query.getAll('endDate');
        const displayHeader = query.getAll('displayHeader')[0] === 'true';
        const [displayMode] = query.getAll('displayMode');
        const [displayTime] = query.getAll('displayTime');
        const [companyId] = query.getAll('companyId');
        const [includeGroup] = query.getAll('includeGroup');
        const viewOptions = query.getAll('viewOptions')[0]?.split(' ') || ['startup'];

        const formattedViewOptions = viewOptions
            .filter(view => view && view !== 'shareLink')
            .map(view => ({ name: view, display: true }));
        configObj = {
            startDate,
            endDate,
            displayHeader,
            displayMode,
            displayTime: displayTime * 1000,
            viewOptions: formattedViewOptions,
            includeGroup,
            configUrl: `play/startups${search}`,
            companyId
        };
    }

    return (
        <DataContextProvider>
            <CorporateHubRoutes t={t} configObjFromURL={configObj} />
        </DataContextProvider>
    );
}
export default CorporateHub;
