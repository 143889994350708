import React from 'react';
import { IonItem } from '@ionic/react';

import { ListVerticalItemLink } from './ListVerticalItemLink';
import * as Styled from './StyledListVertical';

export function ListVerticalItem(props) {
    const data = props.data || props.item || undefined;
    const Component = props.itemComponent || ListVerticalItemLink;
    return (
        <Styled.ListVerticalItem as={IonItem}>
            <Component data={data} />
        </Styled.ListVerticalItem>
    );
}
export const VerticalItem = ListVerticalItem;
export default ListVerticalItem;
