import React from 'react';

import { iframeLink } from 'core/iframe';

import { StartupIndexView } from './page';
import * as Styled from './styles';

export function StartupIndex(props) {
    const { vars } = props.useRankingStartup;
    const { application } = vars;

    // Create the steps for this index
    const steps = [
        {
            step: 1,
            href: '/ranking/latam/startup/step1',
            text: props.t('StartupIndex.step1'),
            isBlocked: false,
            isCompleted: application.steps.step1 || false
        },
        {
            step: 2,
            href: '/ranking/latam/startup/step2',
            text: props.t('StartupIndex.step2'),
            isBlocked: !application.steps.step1,
            isCompleted: application.steps.step2 || false
        },
        {
            step: 3,
            href: '/ranking/latam/startup/step3',
            text: props.t('StartupIndex.step3'),
            isBlocked: !application.steps.step2,
            isCompleted: application.steps.step3 || false
        },
        {
            step: 4,
            href: '/ranking/latam/startup/step4',
            text: props.t('StartupIndex.step4'),
            isBlocked: !application.steps.step3,
            isCompleted: application.steps.step4 || false
        },
        {
            step: 5,
            href: '/ranking/latam/startup/step5',
            text: props.t('StartupIndex.step5'),
            isBlocked: !application.steps.step4,
            isCompleted: application.steps.step5 || false
        },
        {
            step: 6,
            href: '/ranking/latam/startup/step6',
            text: props.t('StartupIndex.step6'),
            isBlocked: !application.steps.step5,
            isCompleted: application.steps.step6 || false
        },
        {
            step: 7,
            href: '/ranking/latam/startup/step7',
            text: props.t('StartupIndex.step7'),
            isBlocked: !application.steps.step6,
            isCompleted: application.steps.step7 || false
        },
        {
            step: 8,
            href: '/ranking/latam/startup/step8',
            text: props.t('StartupIndex.step8'),
            isBlocked: !application.steps.step7,
            isCompleted: application.steps.step8 || false
        },
        {
            step: 9,
            href: '/ranking/latam/startup/step9',
            text: props.t('StartupIndex.step9'),
            isBlocked: !application.steps.step8,
            isCompleted: application.steps.step9 || false
        }
    ];

    // Create a list for the support links
    const actions = [
        {
            id: 1,
            href: iframeLink('http://www.openstartups.net/site/ranking/sobre.html'),
            text: props.t('StartupIndex.RankingAbout')
        },
        {
            id: 2,
            href: '/support',
            text: props.t('StartupIndex.HelpAndSupport')
        }
    ];

    const propsView = { ...props, actions, steps };
    return (
        <Styled.StartupIndex>
            <StartupIndexView {...propsView} />
        </Styled.StartupIndex>
    );
}
