import styled from 'styled-components';
import { hslFromNumber, positions } from 'utils/styles';

import iconCXI from 'assets/images/icon-100-10-1.svg';

export const CompanyLogo = styled.div.attrs(props => ({
    className: 'user-avatar'
}))`
    position: relative;
    width: 10rem;
`;

export const Holder = styled.div.attrs({
    className: 'user-avatar-holder'
})`
    background-color: ${props =>
        !props.user.photoUrl ? hslFromNumber(props.numbersHUE) : 'transparent'};
    border-radius: 50%;
    display: block;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;

    .user-avatar-letters {
        background-color: ${props => hslFromNumber(props.numbersHUE)};
    }
`;

export const Image = styled.div.attrs(props => ({
    className: 'user-avatar-image',
    role: 'img',
    'aria-label': props.alt
}))`
    background: transparent no-repeat 50% 50%;
    background-image: url('${props => props.src}');
    background-size: cover;
    border: 2px solid #8ee2ed;
    border-radius: 50%;
    display: block;
    position: absolute;
    ${positions('2px', '2px', '2px', '2px')}

    img {
        display: none;
    }
`;

export const Letters = styled.div.attrs(props => ({
    className: 'user-avatar-letters'
}))`
    display: block;

    svg {
        position: absolute;
        ${positions(0, 0, 0, 0)}
    }
    .text {
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 2.5em;
        font-weight: 400;
        justify-content: center;
        padding-top: 0.1em;
        position: absolute;
        ${positions(0, 0, 0, 0)}
        text-transform: uppercase;
    }
`;

export const CXIIcon = styled.span.attrs({})`
    background-image: url(${iconCXI});
    bottom: 0;
    border-radius: 50%;
    content: '';
    height: 25px;
    position: absolute;
    right: 0;
    width: 25px;
`;
