import React from 'react';

import { useEffectOnce } from 'react-use';
import { useParams } from 'react-router';

import { SubscriptionSuccessSDFree } from './SubscriptionSuccessSDFree';
import { SubscriptionSuccessSDNone } from './SubscriptionSuccessSDNone';
import { SubscriptionSuccessSDPaid } from './SubscriptionSuccessSDPaid';

import * as Styled from './StyledSubscriptionSuccess';

export function SubscriptionSuccess(props) {
    const { eventId } = useParams();

    const { vars, getEventById } = props.useSubscription;

    const { user } = vars.eventById;

    useEffectOnce(() => {
        getEventById(eventId);
    });

    // Check if the user selected no speeddating?
    if (user && !user.speeddating) {
        user.speeddating = false;
    }

    return !user ? null : (
        <Styled.SubscriptionSuccess>
            {user.speeddating === false && <SubscriptionSuccessSDNone {...props} />}
            {user.speeddating === true && user.paid && <SubscriptionSuccessSDPaid {...props} />}
            {user.speeddating === true && !user.paid && <SubscriptionSuccessSDFree {...props} />}
        </Styled.SubscriptionSuccess>
    );
}
export default SubscriptionSuccess;
