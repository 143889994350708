import styled from 'styled-components';

export const TabsButtons = styled.div.attrs({
    className: 'c-tabs-buttons',
})`
    background: ${({ theme }) => theme.colors.lighterGray};
    border-radius: 0.8rem;
    display: flex;
    padding: 0.4rem;
    overflow-x: scroll;
    max-width: 100%;
    width: 100%;

    ::-webkit-scrollbar {
        display: none;
        height: 0;
        width: 0;
    }
`;
