import styled from 'styled-components';

export const SessionContainer = styled.div`
    display: flex;
    width: 99vw;
    height: 100%;
    margin: 0 auto 1rem auto;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        overflow: auto;
    }
`;
