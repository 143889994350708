import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { RegisterCompanyCreateForm } from './RegisterCompanyCreateForm';

export function RegisterCompanyCreate(props) {
    const conv = useConversionOptions(['companySize']);
    const { postCompany } = props.useRegister;

    // Initialize a new form handler
    const form = useForm();

    // Handle form submition
    const onSubmit = values => {
        const payload = values;
        // Do the submition api request
        return postCompany(payload)
            .then(() => RHP.push('/register/company'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="RegisterCompanyCreate" back="/register/company/select">
            <Page.Title>{props.t('RegisterCompanyCreate.title')}</Page.Title>
            <Page.Descr>{props.t('RegisterCompanyCreate.descr')}</Page.Descr>

            <RegisterCompanyCreateForm {...propsForm} />
        </Page.Page>
    );
}
export default RegisterCompanyCreate;
