import styled from 'styled-components';

export const MenuListPages = styled.div.attrs({
    className: 'menu-list-pages',
})`
    color: inherit;
    margin-top: 3rem;
`;

export const MenuListPagesItem = styled.a.attrs({
    className: 'menu-list-pages-item',
})`
    align-items: center;
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    color: inherit;
    display: flex;
    font-size: 1.4rem;
    font-weight: 500;
    min-height: 4.4rem;
    padding: 0;
    -webkit-appearance: none;

    .text {
        margin-right: auto;
        transform: translateY(0.1rem);
    }
    .text + .icon,
    .icon + .text {
        margin-left: 1rem;
    }
`;
