import React from 'react';

import { Browser } from '@capacitor/browser';

import { CA, CG } from 'contexts';
import { RHP, LSP } from 'providers';
import { isValidUrl } from 'utils/validation';

export const whitelist = [
    'localhost:4000',
    // '192.168.1.160:4000',
    // 'localhost:8100',
    // '100os.app',
    '100os.events',
    'event.openstartups.net',
    // 'openstartups.net',
    // 'app.openstartups.net',
    'matchmaking.openstartups.net',
    'event3.openstartups.net',
    // 'ranking.openstartups.net',
    'startup.openstartups.net',
    'startupranking.openstartups.net',

    // Test enviroments
    // 'app2.openstartups.net',
    'event2.openstartups.net',
    'startup2.openstartups.net',
];

export function useIframe(props) {
    const { state } = React.useContext(CA);
    const { language } = React.useContext(CG).state;
    const { url, exitRoute } = props;

    // Decode the url that came trough the params
    const decodedURI = decodeURIComponent(url);

    // Check if the url is valid
    if (!isValidUrl(decodedURI)) {
        RHP.replace(exitRoute);
        return null;
    }

    // Check if the user is authenticated
    if (!state.authenticated) {
        // console.error('Redirect', 'User is not authenticated');
        // Save the exitRoute so the iframe can use it again
        LSP.set('100os-iframeExitRoute', exitRoute);
        RHP.replace({ pathname: '/login', search: `?returnUrl=/iframe/${url}` });
        return null;
    }

    // Create a new URL variable do be handled
    const destURL = new URL(decodedURI);

    // Check if the URL is whitelisted
    // If not, then go back to the exit page
    if (!whitelist.includes(destURL.host)) {
        // console.error('Redirect', 'URL passed is not allowed to be opened', destURL.host);
        destURL.searchParams.append('utm_source', '100os.app');
        destURL.searchParams.append('utm_medium', 'app');
        destURL.searchParams.append('utm_campaign', 'fromIframe');
        RHP.replace(exitRoute);
        Browser.open({ url: destURL.href, toolbarColor: '#45aaf2' }); // Using In-App Browser
        return null;
    }

    // Get information from the authentication
    const { token, refresh_token } = state.auth;

    // Add parameters to the URL
    destURL.searchParams.append('fromIframe', true);
    destURL.searchParams.append('lang', language);
    destURL.searchParams.append('token', token);
    destURL.searchParams.append('refresh_token', refresh_token);

    // Creates the iframe element
    const iframe = document.createElement('iframe');
    // Set iframe attributes accordingly
    iframe.setAttribute('src', destURL.href);
    iframe.setAttribute('title', '100OS Iframe App');

    return iframe;
}
export default useIframe;
