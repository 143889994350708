import styled from 'styled-components';

export const HomeListActions = styled.div.attrs({
    className: 'home-list-actions',
})`
    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444;
    }
`;
