import React from 'react';

import { useCXI } from 'modules/cxi/useCXI';

import { MetricsStage1 } from './MetricsStage1';
import { MetricsStage2 } from './MetricsStage2';
import { MetricsStage3 } from './MetricsStage3';

import * as Styled from './StyledMetrics';

export const Metrics = () => {
    const { vars } = useCXI();
    const { cxi } = vars;

    const { currentStage } = cxi;

    return (
        !!cxi.metrics && (
            <Styled.Metrics>
                <MetricsStage3 />
                <MetricsStage2 />
                <MetricsStage1 />
            </Styled.Metrics>
        )
    );
};
