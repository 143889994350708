import React from 'react';
import * as Styled from './StyledContext';

export const Context = props => {
    const { startup } = props;

    let truncatedText = '';
    if (startup?.event?.answers) {
        const targetAnswer = startup?.event?.answers.find(
            answer => answer.questionId === '609c254ef48f957ca3f8f30b'
        );
        if (targetAnswer) {
            truncatedText = targetAnswer.answer[0];
            if (truncatedText.length > 120) {
                truncatedText = `${truncatedText.substring(0, 120)}...`;
            }
        }
    }

    return (
        <Styled.Context>
            <Styled.Infos>
                <div key={startup?.id}>
                    <span className="text">{truncatedText}</span>
                </div>
            </Styled.Infos>
        </Styled.Context>
    );
};

export default Context;
