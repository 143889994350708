import React from 'react';

import { useForm } from 'components/form';
import * as Page from 'components/page';
import { CA, CG } from 'contexts';
import { RHP } from 'providers';

import { cnpj } from 'cpf-cnpj-validator';
import { ContractTypesModal } from './contract-types-modal';
import { StartupStep8AddForm } from './add-form';

export function StartupStep8Add({ useRankingStartup }) {
    const { vars, addVars, postStartupContract, t } = useRankingStartup;
    const { ranking, startupDetails } = vars;

    const { emitAlert } = React.useContext(CG);

    const {
        state: { user }
    } = React.useContext(CA);

    const currDate = new Date();

    const [isOpen, setIsOpen] = React.useState(false);
    // Initialize a new form handler
    const form = useForm({
        executiveResponsibleEmail: user.email,
        year: currDate.getUTCFullYear(),
        otherContact: false,
        corporateContractCountry: startupDetails.location.country.id
    });

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step5 !== true) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    const onBlur = {
        isValidCNPJ: ev => {
            const { name, value } = ev.target;
            // Clear the errors and warnings before validating
            form.clearMessages({ name });
            // Only check if the value is filled
            if (!value || form.values.corporateContractCountry !== 'BR') return false;
            // Check if the CPF is a valid one
            const cleanValue = value.replace(/[^0-9]/g, '');

            if (!cnpj.isValid(cleanValue)) {
                // Emit error message for registered email
                const error = t('StartupStep2.warningTextCnpj');
                return form.emitError({ name, error });
            }
            return true;
        }
    };

    // Custom onChange events
    const onChange = {
        company: () => {
            form.emitError({ name: 'company', error: undefined });
        }
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // Handle form submitions
    const onSubmit = values => {
        // Make sure the date fields are not empty
        if (!values.startDate || !values.endDate) {
            emitAlert(t('contractNoStartEndDate'));
            form.submited();
            return false;
        }

        const startDate = `${values?.startDate?.substring(0, 7)}-01T00:00:00.000+0000`;
        const endDate = `${values?.endDate?.substring(0, 7)}-01T00:00:00.000+0000`;

        const payload = {
            contractInfo: {
                contractType: values.contractType,
                description: values.contractDescription,
                startDate,
                endDate,
                formalized: values.formalized === 'true',
                valueRange: values.valueRange,
                valueRangeCurrency: 'USD',
                countries: values.contractExecutionCountries,
                reportedBy: 'Startup'
            },
            companyContact: { name: values?.contactName, email: values?.contactEmail },
            ranking,

            companyTaxId: values.taxId,
            companyCountry: values.corporateContractCountry,

            companyExecutive: {
                email: values.executiveResponsibleEmail
            }
        };

        if (values.company) {
            payload.company = { name: values.company, taxId: values.taxId };
        }

        // Add startup contact information
        return postStartupContract(payload)
            .then(res => {
                addVars({ searchedCompany: {} });
                RHP.push('/ranking/latam/startup/step8');
                return res;
            })
            .catch(() => form.submited());
    };

    const propsForm = { form, onChange, onBlur, onSubmit, openModal, useRankingStartup };

    return (
        <Page.Page name="StartupStep8Add">
            <Page.Title>{t('StartupStep8Add.title')}</Page.Title>
            <Page.LWTXT onClick={openModal}>
                {t('StartupStep8Add.descr')}{' '}
                <span className="link d-inline">{t('StartupStep8Add.knowMore')}</span>
            </Page.LWTXT>

            <StartupStep8AddForm {...propsForm} useRankingStartup={useRankingStartup} />

            <ContractTypesModal
                title={t('StartupStep8Add.modalTitle')}
                isOpen={isOpen}
                close={closeModal}
            />
        </Page.Page>
    );
}
