import styled from 'styled-components';

export const StartupOptions = styled.div`
    max-width: 42rem;

    .m-container-body {
        color: ${({ theme }) => theme.colors.black}
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
    }
    .m-container-feet {
        flex-direction: column;
        gap: 1.2rem;
    }

    .component-list {
        margin-top: -0.8rem;
        width: 100%;

        @media (min-width: 768px) {
            margin-top: -1.2rem;
        }
    }
    .list-vertical-item-link {
        border-color: rgba(68, 68, 68, 0.2);
        color: #444;
    }

    // .ln-StartupOptionsMatchList .list-vertical-item:last-child {
    //     .list-vertical-item-link {
    //         color: ${({ theme }) => theme.colors.red};
    //     }
    // }
`;

export const Descr = styled.div`
    font-size: 1.4rem;
    line-height: 1.4;
    margin: 0 0 1.6rem;
    opacity: 0.75;

    .component-list + & {
        margin-top: 2.4rem;
    }
`;
