import React, { useState } from 'react';
import moment from 'moment';

import { CG } from 'contexts';
import { UserAvatar } from 'core/components/user';
import { useCXI } from 'modules/cxi/useCXI';
import { useClickAway } from 'react-use';
import * as Styled from './StyledMessage';

export const Message = ({ match, message }) => {
    const { state: global } = React.useContext(CG);
    const { setModal } = useCXI();
    const [showOptions, setShowOptions] = useState(false);
    const dropdownRef = React.useRef(null);

    let lang = global.language;

    if (global.language === 'pt') {
        lang = 'pt-br';
    }
    const momentDate = moment(message.regDate);
    const formatDate = momentDate.format('lll');
    moment.locale(lang);

    useClickAway(dropdownRef, () => {
        setShowOptions(false);
    });

    const from = message.from === 'startup' ? 'them' : 'me';

    return (
        <Styled.Message from={from} slim={message.slim} ref={dropdownRef}>
            {from === 'me' ? <UserAvatar /> : <Styled.MessageImage img={match.startup.logoUrl} />}
            <Styled.MessageTexts>
                <div>
                    {!message.slim && from !== 'me' && <span className="name">{message.name}</span>}
                    {from !== 'me' ? (
                        <span
                            className="icon far fa-ellipsis-v optionsIcon"
                            aria-hidden="true"
                            onClick={() => setShowOptions(!showOptions)}
                        />
                    ) : null}
                </div>

                {showOptions && (
                    <Styled.Options>
                        <Styled.Option
                            onClick={() =>
                                setModal({ modal: 'Report', match, message: message.text })
                            }
                        >
                            <span className="icon far fa-exclamation-circle" aria-hidden="true" />{' '}
                            Denunciar
                        </Styled.Option>
                    </Styled.Options>
                )}
                <span className="text">{message.text}</span>
                <span className="time">{formatDate}</span>
                <span className="caret fas fa-caret-left" aria-hidden="true" />
            </Styled.MessageTexts>
        </Styled.Message>
    );
};
