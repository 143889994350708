import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';

import { Descr, Ruler, Subtitle } from 'components/page';
import { ContractReauditForm } from './ContractReauditForm';

export function ContractReaudit(props) {
    const { t } = useTranslation('ranking');
    const data = props.data || props.relationship || undefined;

    return (
        <>
            {!data.reconsiderations || data.reconsiderations?.length <= 0 ? null : (
                <>
                    <Ruler />
                    <Subtitle text={t('reconsideration.title')} />

                    {data.reconsiderations.map(rec => {
                        const recDate = moment(rec.regDate)
                            .utc()
                            .locale(props.lang)
                            .format('lll');
                        const recUser = rec.fullName || '(anon)';
                        return (
                            <div key={rec.regDate}>
                                <strong
                                    style={{ fontWeight: 500 }}
                                >{`[${recDate}] ${recUser}:`}</strong>
                                <br /> {rec.message}
                                <br />
                            </div>
                        );
                    })}
                </>
            )}

            {!!data.reconsiderationResult && (
                <>
                    <div style={{ marginTop: 30 }} />
                    <Subtitle text={t('reconsideration.titleResult')} />
                    <Descr>{data.reconsiderationResult}</Descr>
                </>
            )}

            {props.reauditing && <ContractReauditForm {...props} />}
        </>
    );
}
