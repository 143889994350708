import { useContext } from 'react';
import { CA } from 'contexts';

export const useAuthContext = () => {
    const context = useContext(CA);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};
