import React from 'react';

import * as Page from 'components/page';

import { OiweekBack } from '../components';

// import * as Styled from './StyledOiweekSuccess';

export const OiweekSuccessSDNone = props => {
    const { vars } = props.useOiweek;
    const { oiweek } = vars;
    return (
        <Page.Page name="OiweekSuccessSDNone">
            <Page.Title>{props.t('OiweekSuccess.title')}</Page.Title>
            <Page.Descr>{props.t('OiweekSuccess.descr-SD-none')}</Page.Descr>

            <OiweekBack oiweek={oiweek} />
        </Page.Page>
    );
};
export default OiweekSuccessSDNone;
