import React from 'react';
import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledOpportunityCard';

export const OpportunityCard = ({ data, t }) => {
    const { setModal } = useCXI();
    const types = data?.fields?.ChallengeType?.split('|');
    return (
        <Styled.Opportunity
            onClick={() => setModal({ modal: 'Opportunity', opportunity: data?.fields })}
        >
            <Styled.OpportunityHead>
                <span className="imgWrapper">
                    <img src={data?.fields?.LogoURL} alt="Logo do desafio" />
                </span>
                {data?.fields?.StartupName && (
                    <span className="startupName">
                        {t('opportunities.challengeBy')} <b>{data?.fields?.StartupName}</b>
                    </span>
                )}
            </Styled.OpportunityHead>

            <Styled.OpportunityBody>
                <span className="title">{data?.fields?.ChallengeName}</span>
                <span className="descr">{data?.fields?.ChallengeDescription}</span>
            </Styled.OpportunityBody>

            <Styled.OpportunityFeet>
                <Styled.BadgesWrapper>
                    {types &&
                        types?.map(type => (
                            <span key={type} className="badge">
                                {type}
                            </span>
                        ))}
                </Styled.BadgesWrapper>
                <Styled.Button>{t('opportunities.wantToHelpButton')}</Styled.Button>
            </Styled.OpportunityFeet>
        </Styled.Opportunity>
    );
};
