import React from 'react';

import * as Page from 'components/page';
import { useEffectOnce } from 'react-use';
import { useParams } from 'react-router';

import { SubscriptionBack } from '../components';

export const SubscriptionSuccessSDPaid = props => {
    const { eventId } = useParams();

    const { vars, getEventById } = props.useSubscription;
    const { eventById } = vars;

    useEffectOnce(() => {
        getEventById(eventId);
    });

    return (
        <Page.Page name="SubscriptionSuccessSDPaid">
            <Page.Title>{props.t('SubscriptionSuccess.title')}</Page.Title>
            <Page.Descr>{props.t('SubscriptionSuccess.descr-SD-paid')}</Page.Descr>

            <SubscriptionBack events={eventById} />
        </Page.Page>
    );
};
export default SubscriptionSuccessSDPaid;
